import { Chip } from '@material-ui/core';
import { FunctionsRounded as FunctionsRoundedIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

import { licensed, numAssigned, totalActiveUsers } from '../../users/Index';
export default function ChipHeader({ status = 'succeed', data }) {
    const tableState = { data };
    return status === 'loading' ? (
        <>
            <div style={{ paddingRight: 15, height: 20, display: 'inline' }}>
                <Chip
                    variant="outlined"
                    size="small"
                    icon={
                        <Skeleton
                            variant="circle"
                            width={16}
                            style={{
                                marginLeft: 4,
                            }}
                        />
                    }
                    label={'Licensed: ? Users'}
                />
            </div>
            <div style={{ padding: 15, height: 20, display: 'inline' }}>
                <Chip
                    icon={
                        <Skeleton
                            variant="circle"
                            width={16}
                            style={{
                                marginLeft: 4,
                            }}
                        />
                    }
                    variant="outlined"
                    size="small"
                    label={'Numbers assigned: ? Users'}
                />
            </div>
            <div style={{ padding: 15, height: 20, display: 'inline' }}>
                <Chip
                    icon={
                        <Skeleton
                            variant="circle"
                            width={16}
                            style={{
                                marginLeft: 4,
                            }}
                        />
                    }
                    variant="outlined"
                    size="small"
                    label={'Total: ? Users'}
                />
            </div>
        </>
    ) : (
        <>
            <div
                style={{
                    paddingRight: 15,
                    display: 'inline',
                }}>
                <Chip
                    variant="outlined"
                    size="small"
                    label={'Licensed: ' + licensed(tableState) + ' Users'}
                />
            </div>
            <div
                style={{
                    padding: 15,
                    display: 'inline',
                }}>
                <Chip
                    variant="outlined"
                    size="small"
                    label={
                        'Numbers assigned: ' +
                        numAssigned(tableState) +
                        ' Users'
                    }
                />
            </div>
            <div
                style={{
                    padding: 15,
                    display: 'inline',
                }}>
                <Chip
                    icon={<FunctionsRoundedIcon />}
                    variant="outlined"
                    size="small"
                    label={totalActiveUsers(tableState) + ' Users'}
                />
            </div>
        </>
    );
}
