import { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within the AuthProvider');
    }

    return context;
};

export function AuthProvider({ children }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [token, setToken] = useState('');
    const [formState, setFormState] = useState('idle');
    const [adminData, setAdminData] = useState();

    const value = {
        errorMessage,
        setErrorMessage,
        token,
        setToken,
        formState,
        setFormState,
        adminData,
        setAdminData,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}
