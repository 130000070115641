import {useSelector} from 'react-redux';
import {COMPANY, PARTNER, WHOLESALER} from './BillingForm';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {validate} from '@material-ui/pickers';
import CheckRounded from '@material-ui/icons/CheckRounded';

export default function CouldNotDeletePartnerWS({mode}) {
    const {state: modalState} = useSelector((state) => state.modal);
    const isPartner = mode === PARTNER;
    const entity = isPartner ? 'Partner' : 'Wholesaler';
    const entitySub = isPartner ? 'Companies' : 'Partners';

    return (
        <>
            {modalState?.isNextButtonDisabled
                ? `Can not delete this ${entity}, as it is still in use.`
                : `You can delete this ${entity}.`}
            <ul className="couldNotDelete">
                {modalState?.issues?.numberBlocks?.message ? (
                    <li>
                        <CloseRoundedIcon
                            fontSize="small"
                            style={{
                                color: 'red',
                            }}
                        />
                        {modalState?.issues?.numberBlocks?.message}
                        {modalState?.issues?.numberBlocks?.response && (
                            <ul className="couldNotDelete">
                                {modalState?.issues?.numberBlocks?.response.map(
                                    (v) => (
                                        <CouldNotDeleteListItem>
                                            {`+${v.first} to +${v.last}`}
                                        </CouldNotDeleteListItem>
                                    ),
                                )}
                            </ul>
                        )}
                    </li>
                ) : (
                    <li>
                        <CheckRounded
                            fontSize="small"
                            style={{
                                color: 'green',
                            }}
                        />
                        {`There are no Number Blocks found under this ${entity}.`}
                    </li>
                )}
                {modalState?.issues?.sub?.message ? (
                    <li>
                        <CloseRoundedIcon
                            fontSize="small"
                            style={{
                                color: 'red',
                            }}
                        />
                        {modalState?.issues?.sub?.message}
                        {modalState?.issues?.sub?.response && (
                            <ul className="couldNotDelete">
                                {modalState?.issues?.sub?.response.map((v) => (
                                    <CouldNotDeleteListItem>
                                        {v.name}
                                    </CouldNotDeleteListItem>
                                ))}
                            </ul>
                        )}
                    </li>
                ) : (
                    <li>
                        <CheckRounded
                            fontSize="small"
                            style={{
                                color: 'green',
                            }}
                        />
                        {`There are no ${entitySub} found under this ${entity}.`}
                    </li>
                )}
            </ul>
        </>
    );
}

const CouldNotDeleteListItem = ({children}) => (
    <li>
        <CloseRoundedIcon
            fontSize="small"
            style={{
                color: 'red',
            }}
        />
        {children}
    </li>
);
