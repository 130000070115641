//Checker for before redux acitons are done
//making sure you dont have unsaved modal states
//check for logouts too
import { hashObject } from '../../utils/utils';

export const checkHash = ({ originalHash = '', currentObject = {} }) => {
    var hashNow = hashObject(currentObject);
    const isSame = hashNow === originalHash;
    return isSame;
};

export const cannotNavigate = ({
    message = `Are you sure you would like to switch? All unsaved changes will be lost.`,
    hash = '',
    state = {},
    show = false,
}) => {
    if (state?.noNeedPrompt) {
        return false;
    }
    if (show && !checkHash({ originalHash: hash, currentObject: state })) {
        const value = window.confirm(message);
        if (!value) return true;
        return false;
    }

    return false;
};

//NOTE: THIS MIDDLEWARE DOESN'T WORK AT ALL, IT SHOWS THE ALERT BUT THE RESPONSE IS NOT USED/SAVED (note added on 10th May 2025)
//TODO: Refactor to one if possible
export const modalChangesMiddleware = (store) => {
    return function wrapDispatch(next) {
        return function handleAction(action) {
            // Do anything here: pass the action onwards with next(action),
            // or restart the pipeline with storeAPI.dispatch(action)
            // Can also use storeAPI.getState() here
            const { modal, table, webWorker } = store.getState();

            switch (action.type) {
                case 'CHANGE_TABLE_STATE_WEB_WORKER':
                    return next({
                        type: 'CHANGE_TABLE_STATE',
                        payload: {
                            data: [...webWorker.tableData],
                        },
                    });
                case 'SWITCH_CHILD_TABLE':
                    if (
                        cannotNavigate({
                            ...modal,
                            message: `Are you sure you would like to switch to another tab? All unsaved changes will be lost.`,
                        })
                    ) {
                        return next({ type: '' });
                    }
                    break;
                case 'CHANGE_CHILD_TABLE':
                    if (
                        cannotNavigate({
                            ...modal,
                            message: `Are you sure you would like to switch? All unsaved changes will be lost.`,
                        })
                    ) {
                        return next({ type: '' });
                    }
                    break;
                case 'CHANGE_TAB_VIEW':
                    if (
                        cannotNavigate({
                            ...modal,
                            message: `Are you sure you would like to switch to another tab? All unsaved changes will be lost.`,
                        })
                    ) {
                        return next({ type: '' });
                    }
                    break;
                // even after clicking on cancel it still redirects
                // case 'REDIRECT':
                //     if (
                //         cannotNavigate({
                //             ...modal,
                //             message: `Are you sure you would like to switch to another menu? All unsaved changes will be lost.`,
                //         })
                //     ) {
                //         return next({type: ''});
                //     }
                //     break;
                case 'CHANGE_CURRENT_SCOPE':
                    if (
                        cannotNavigate({
                            ...modal,
                            message: `Are you sure you would like to change to a different scope? All unsaved changes will be lost.`,
                        })
                    ) {
                        return next({ type: '' });
                    }
                    break;
                case 'RESET_TABLE_MULTICHILD':
                    if (
                        cannotNavigate({
                            ...modal,
                            message: `Are you sure you would like to return to previous page? All unsaved changes will be lost.`,
                        })
                    ) {
                        return next({ type: '' });
                    }
                    break;
                case 'RESET_TABLE':
                    if (
                        cannotNavigate({
                            ...modal,
                            message: `Are you sure you would like to switch? All unsaved changes will be lost.`,
                        })
                    ) {
                        return next({ type: '' });
                    }
                    break;
                // case 'LOGOUT':
                //     if (
                //         cannotNavigate({
                //             ...modal,
                //             message: `Are you sure you would like to logout? All unsaved changes will be lost.`,
                //         })
                //     ) {
                //         return next({ type: '' });
                //     }
                //     break;
            }
            return next(action);
        };
    };
};
