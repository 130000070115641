// external imports
import React from 'react';
import {Typography, styled} from '@mui/material';

const StyledType = styled(Typography)(({theme}) => ({}));

/*
 * Skeleton of what will be Operator Consents page
 */
const OperatorConsents = (props) => {
    return (
        <>
            <StyledType> OperatorConsents </StyledType>
        </>
    );
};

export default OperatorConsents;
