//used for background tasks and pagenation
const initWebWorkerState = {
    totalNumberOfPages: 0,
    loading: false,
    attribute: 'invoiceLines',
    limit: 10000,
    visibility: false,
    tableData: [],
    error: false,
    fetchURL: `https://teamsdev.pingco.com.au/api/ui/invoicelines/b354e39a-c216-4d15-84c2-08d814736f26`,
};

export const webWorkerReducer = (state = initWebWorkerState, action) => {
    switch (action.type) {
        case 'INIT_WEB_WORKER_STATE':
            return {
                ...initWebWorkerState,
                ...action.payload,
                loading: true,
                visibility: true,
            };
        case 'ADD_WEB_WORKER_TABLE_DATA':
            return {
                ...state,
                tableData: [...state.tableData, ...action.payload],
            };
        case 'CHANGE_WEB_WORKER_STATE':
            return {
                ...state,
                ...action.payload,
            };
        case 'RESET_WEB_WORKER_STATE':
            return {
                ...initWebWorkerState,
            };
        default:
            return state;
    }
};
