import { InfoIcon } from '@chakra-ui/icons';
import {
    Badge,
    Box,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Tooltip,
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import { isEqual } from 'lodash';
import { useServices } from '../ServicesContext';
import style from './styles';
import useServicesAttributesValidation from './useServicesAttributesValidation';
export default function IntAttribute({
    attribute, // original attribute data
    index,
    serviceIndex,
    requiredScope,
    isBusy,
}) {
    const validation = JSON.parse(attribute.definedAttributeValidationType);
    const { attributesErrors, services } = useServices();
    const error = attributesErrors.find(
        (obj) => obj.id === index && obj.serviceIndex === serviceIndex,
    );

    const updateAttribute = services[serviceIndex].attributes[index];

    const {
        handleAttributeChange,
        handleAttributeError,
        removeAttributeError,
    } = useServicesAttributesValidation();

    return (
        <Box sx={style.fieldContainer}>
            <FormControl isRequired={validation?.Required} isInvalid={error}>
                <FormLabel sx={style.label}>
                    {attribute.name}{' '}
                    {attribute.toolTip && (
                        <Tooltip
                            label={attribute.toolTip}
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </FormLabel>

                <Box display="flex" flexDirection={'row'}>
                    <Box w={'100%'} mr={1}>
                        <Input
                            onKeyDown={(evt) =>
                                ['e', 'E'].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            type="number"
                            placeholder="Please enter a value"
                            value={updateAttribute?.value}
                            onChange={(e) => {
                                removeAttributeError(index, serviceIndex);
                                if (
                                    (parseInt(validation.Minimum) > 0 &&
                                        parseInt(validation.Maximum) > 0 &&
                                        parseInt(e.target.value) >
                                            validation.Maximum) ||
                                    parseInt(e.target.value) <
                                        validation.Minimum
                                ) {
                                    handleAttributeError(
                                        index,
                                        `Only numeric values between ${validation.Minimum} and ${validation.Maximum} are allowed.`,
                                        serviceIndex,
                                    );
                                }
                                handleAttributeChange(
                                    e.target.value,
                                    index,
                                    serviceIndex,
                                );
                            }}
                            isDisabled={
                                requiredScope < attribute.updateScope || isBusy
                            }
                        />
                    </Box>
                    {!isEqual(updateAttribute?.value, attribute.value) && (
                        <Box alignContent={'center'} ml={1}>
                            <Badge colorScheme="orange">Unsaved</Badge>
                        </Box>
                    )}
                </Box>

                {error ? (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : attribute?.description ? (
                    <FormHelperText>{attribute?.description}</FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    );
}
