import { Box, Text, Spinner, HStack, Image, Tooltip } from '@chakra-ui/react';

import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardImage from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardImage';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';

import AzureLogo from 'assets/images/Azure.png';
import msTeamsLogo from 'assets/images/ms_teams_logo.png';
import callLogo from 'assets/images/call.png';

import walkthroughIds from 'pages/services/walkthroughIds';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import path from '@/routes/constants';

function AcsService({ serviceWizard, id }) {
    const navigate = useNavigate();
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;

    const { currentPartner } = useSelector((state) => state.navigationLists);
    const acsPath = `${path.SERVICES_AZURE_CONNECT_SERVICE}/${path.CREATE}`;

    return (
        <ServiceProfileCard
            pointerEvents={serviceWizard.isCreatingService ? 'none' : 'auto'}
            key={id}
            onClick={(e) => {
                navigate(acsPath);
            }}
            data-walkthroughid={`${ufNewServiceProfileCard}/${id}`}>
            {serviceWizard.isCreatingService && (
                <Box
                    pointerEvents={
                        serviceWizard.isCreatingService ? 'none' : 'auto'
                    }
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: '0',
                        top: '0',
                        background: 'white',
                        opacity: '0.7',
                    }}>
                    {/* Azure Communication Service */}
                </Box>
            )}
            <ServiceProfileCardImage src={AzureLogo} />
            <ServiceProfileCardTitle>
                Azure Communication Services
            </ServiceProfileCardTitle>
            <Text>
                Authorize TCAP to connect to your Azure tenancy to manage or
                setup an Azure Communication Services instance.
            </Text>
            {currentPartner == '100d5296-0c38-4192-1c43-08dbec03eb47' && (
                <HStack mt={'auto'}>
                    <Tooltip label="Microsoft">
                        <Image boxSize={'5'} src={msTeamsLogo} />
                    </Tooltip>
                    <Tooltip label="Fixed Line Calling">
                        <Image boxSize={'5'} src={callLogo} />
                    </Tooltip>
                </HStack>
            )}
        </ServiceProfileCard>
    );
}

export default AcsService;
