import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../components/v4/Button';
import Heading from '../../../components/v4/Heading';
import AddressFields from './AddressFields';
import useAddress from '../../../hooks/useAddress';
import { Box } from '@chakra-ui/react';
import { axios } from '../../../services/axios';
import Alert from '../../../components/v4/Alert';
import AlertDescription from '../../../components/v4/AlertDescription';
import AlertIcon from '../../../components/v4/AlertIcon';
import Text from '../../../components/v4/Text';
import Input from '../../../components/v4/Input';
import InputGroup from '../../../components/v4/InputGroup';
import InputRightElement from '../../../components/v4/InputRightElement';

import { usePlacesWidget } from 'react-google-autocomplete';

import { SearchIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import secret from '../../../secret.json';

import walkthroughIds from '../walkthroughIds';
import { toast } from 'react-toastify';

export default function AddressesCreate({ setAddressAction }) {
    const { addressFields, setAddressField, transformAddress } = useAddress();

    const companyID = useSelector(
        (state) => state.navigationLists.currentCompanyData?.id,
    );
    const { darkMode } = useSelector((state) => state.settings);

    const { addresses } = walkthroughIds;

    const [countries, setCountries] = useState([]);
    const countriesRef = useRef([]);

    const { ref } = usePlacesWidget({
        apiKey: secret.googleAutocomplete.testKey,
        onPlaceSelected: (place) =>
            transformAddress(place, countriesRef.current),
        options: {
            types: [], // this will screw the results, this lib sets types by default, which are actually optional on the maps SDK
        },
    });

    useEffect(async () => {
        try {
            const res = await axios.get('/countries/list');
            setCountries(res.data);
            countriesRef.current = res.data;
        } catch (e) {
            toast.error('Unable to retrieve countries');
        }
    }, []);

    function onAddressCreate(e) {
        e.preventDefault();

        axios
            .post(`/address`, {
                companyID: companyID,
                ...addressFields,
            })
            .then((response) => {
                setAddressAction('Get');
            })
            .catch((error) => {
                toast.error(
                    error?.response?.data?.message ||
                        'There was an error creaing an address. Please make sure you have filled in "Street House Number 1".',
                );
            });
    }

    return (
        <Box as="form" onSubmit={onAddressCreate}>
            <Heading as="h2" sx={{ marginBottom: '25px' }}>
                Create Address
            </Heading>
            {/* Search address */}
            <Text
                fontSize="lg"
                sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                Search address
            </Text>
            <InputGroup data-walkthroughid={addresses.ufSearchAddress}>
                <InputRightElement
                    pointerEvents="none"
                    children={<SearchIcon color="#0000003b" />}
                />
                <Input
                    placeholder=""
                    customRef={ref}
                    sx={{
                        color: darkMode ? 'white' : '#000000de',
                        borderColor: darkMode
                            ? 'dark.tcap-borderGray'
                            : '#0000003b',
                        // borderRadius: '4px',
                        width: '100%',
                    }}
                />
            </InputGroup>

            <Alert status="info" sx={{ margin: '25px 0' }}>
                <AlertIcon />
                <AlertDescription>
                    Address search will populate each field below, please ensure
                    you check each field before submitting.
                </AlertDescription>
            </Alert>

            <Box
                sx={{
                    height: '1px',
                    width: '100%',
                    margin: '25px 0',
                    background: 'gray.400',
                }}></Box>
            <Box sx={{ marginBottom: '25px' }}></Box>
            <AddressFields
                fields={addressFields}
                fieldHandler={setAddressField}
                countries={countries}
            />

            <Box
                sx={{
                    height: '1px',
                    width: '100%',
                    marginBottom: '25px',
                    background: 'gray.400',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxW: '140px',
                    margin: '0 auto',
                }}>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    onClick={() => setAddressAction('Get')}
                    data-walkthroughid={addresses.ufBackButton}>
                    Back
                </Button>
                <Button
                    ml={4}
                    type="submit"
                    data-walkthroughid={addresses.ufSaveButton}>
                    Create
                </Button>
            </Box>
        </Box>
    );
}
