import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';

import { useDeepMerge } from 'hooks/useDeepMerge';

import ResourceAccount from 'pages/accounts/resourceAccounts/Index';
import ResourceAccEdit from 'pages/accounts/resourceAccounts/edit/ResourceAccEdit';
import ResourceAccBuilder from 'pages/accounts/resourceAccounts/builder/ResourceAccBuilder';
import ResourceAccCSV from '@/pages/accounts/resourceAccounts/csv';

import Users from '@/pages/accounts/users/new/Index.jsx';
import UserEdit from 'pages/accounts/users/new/UserEdit';
import UserCSV from 'pages/accounts/users/csv';

import Fof from '@/pages/fof';

import path from '@/routes/constants.js';

function ResourceAccountsRoutes() {
    const { EDIT, BUILDER, CSVRESOURCEACCOUNTS } = path;
    const { theme } = useDeepMerge();
    return (
        <ChakraProvider resetCSS theme={theme}>
            <Routes>
                <Route index element={<ResourceAccount />} />
                <Route path={EDIT} element={<ResourceAccEdit />} />
                <Route path={BUILDER} element={<ResourceAccBuilder />} />
                <Route
                    path={CSVRESOURCEACCOUNTS}
                    element={<ResourceAccCSV />}
                />
                <Route path="*" element={<Fof />} />
            </Routes>
        </ChakraProvider>
    );
}

function UsersRoutes() {
    const { EDIT, CSVUSERS } = path;
    const { theme } = useDeepMerge();

    return (
        <ChakraProvider resetCSS theme={theme}>
            <Routes>
                <Route index element={<Users />} />
                <Route path={EDIT} element={<UserEdit />} />
                <Route path={CSVUSERS} element={<UserCSV />} hidden />
                <Route path="*" element={<Fof />} />
            </Routes>
        </ChakraProvider>
    );
}

export { ResourceAccountsRoutes, UsersRoutes };
