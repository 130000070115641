import { chakra, forwardRef } from '@chakra-ui/react';

import { classNames as cx } from '@/utils/classNames';
import { useCardStyles } from './card-context';

/**
 * @typedef {import('@chakra-ui/react').HTMLChakraProps<"div">} CardBodyProps
 * @type React.ForwardRefRenderFunction<HTMLDivElement, CardBodyProps>
 * @returns {JSX.Element}
 */
export const CardBody = forwardRef(function CardBody(props, ref) {
    const { className, ...rest } = props;
    const styles = useCardStyles();
    return (
        <chakra.div
            ref={ref}
            className={cx('chakra-card__body', className)}
            __css={styles.body}
            {...rest}
        />
    );
});
