import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import {
    Button,
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

import tableIcons from 'utils/MaterialTableIcons';
import validator from 'validator';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import walkthroughIds from '../walkthroughIds';
import { axios } from '@/services/axios';
import {
    ChakraProvider,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
} from '@chakra-ui/react';
import chakratheme from '@/chakratheme';
import { Input } from 'components/v4';

/*
 * Component for colection hosts
 * Rendering the Table and the modal form to add another one
 */
const EditSipCollectionHost = ({ trunk, setTrunk }) => {
    const muiTheme = useMemo(
        () =>
            createTheme({
                head: {
                    backgroundColor: null,
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#212121',
                        },
                    },
                    MuiTable: {
                        root: {
                            WebkitTextFillColor:
                                'rgba(255, 255, 255, 0.7) !important',
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTableHead: {
                        root: {
                            backgroundColor: '#212121 !important',
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            backgroundColor: 'white',
                        },
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: null,
                        },
                    },
                    MuiIconButton: {
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                    },
                },
            }),
        [],
    );
    const [host, setHost] = useState();
    const [errors, setErrors] = useState();
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { darkMode } = useSelector((state) => state.settings);

    const dispatch = useDispatch();
    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // columns for the table
    const columns = [
        {
            title: 'Host',
            field: 'host',
            width: '100%',
            editComponent: (props) => {
                return (
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <Input
                            size="small"
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                            className="w-full"
                            name="host"
                            placeholder="Enter Host"
                        />
                    </ChakraProvider>
                    // <TextField
                    //     size="small"
                    //     variant="standard"
                    //     value={props.value}
                    //     onChange={(e) => {
                    //         props.onChange(e.target.value);
                    //     }}
                    // />
                );
            },
        },
    ];

    // logic for open and close
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setHost(null);
        setErrors(null);
        setOpen(false);
    };

    /*
     * Function that takes in row data and deletes it
     * The state value of colletion hosts is checked and the row passed into the function is removed
     * This new array is dispatched to state
     * The deletedHostIDs array is then deleted in Trunk.jsx
     */
    const deleteHandler = (row) => {
        let result = [];
        state.trunkCollectionHosts.forEach((v) => {
            if (v.host !== row.host) {
                const temp = {
                    id: v.id,
                    trunkID: state.id,
                    host: v.host,
                };
                result.push(temp);
            } else {
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        deletedHostIDs: [
                            {
                                id: v.id,
                            },
                            ...state.deletedHostIDs,
                        ],
                    },
                });
            }
        });
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkCollectionHosts: result,
            },
        });
    };

    /*
     * Submit Handler for API calls
     * Checks the state values have all the required fields for submission to the API
     * A host needs a trunk id which is got from the state
     * It also needs a embedded host object which we pass in from this component
     */
    const onSubmitHandler = () => {
        let tempHosts;
        if (state.trunkCollectionHosts) {
            tempHosts = [
                ...state.trunkCollectionHosts,
                {
                    trunkID: state.id,
                    host: host,
                },
            ];
        } else {
            tempHosts = [
                {
                    trunkID: state.id,
                    host: host,
                },
            ];
        }
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkCollectionHosts: tempHosts,
            },
        });
        setHost(null);
        handleClose();
    };

    /*
     * If there is a state value of collection hosts ->
     * it is extracted and pushed to row state
     */
    useEffect(() => {
        if (state.trunkCollectionHosts && loading === false) {
            let result = [];
            state.trunkCollectionHosts.forEach((v) => {
                const temp = {
                    trunkID: state.id,
                    host: v.host,
                };
                result.push(temp);
            });
            setRows([...result]);
        }
    }, [state.trunkCollectionHosts, loading]);

    /*
     * When the page first loads, run a API call and set the state/rows based on the return
     * If the mode is edit and collection hosts is empty(ie no hosts exist)
     * For each value, extract it to the format needed to display it and push to the new result
     * this new result gets dispatched to state
     */
    useEffect(() => {
        setLoading(true);
        if (state.trunkCollectionHosts?.length === 0) {
            axios.get('/trunk/' + state.id).then((res) => {
                let result = [];
                res.data.trunkCollectionHosts.forEach((value) => {
                    const temp = {
                        trunkID: state.id,
                        id: value.id,
                        host: value.host,
                    };
                    result.push(temp);
                });
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        trunkCollectionHosts: result,
                    },
                });
                setRows(result);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    /*
     * Spread operators needed empty arrays or else they would break
     * This just sets some empty state values for the deleted/edited list
     */
    useEffect(() => {
        if (!state.deletedHostIDs) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    deletedHostIDs: [],
                },
            });
        }
        if (!state.editedHostIDs) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    editedHostIDs: [],
                },
            });
        }
    }, []);

    /*
     * Material Table with a dialogue to add a host and editible fields
     * Host field to add a host, IP address or FQDN name
     * This is based on material table documentation
     */
    return (
        <ThemeProvider theme={darkMode ? muiTheme : null}>
            <Box
                sx={{
                    th: {
                        height: '80.5px',
                        backgroundColor: darkMode
                            ? '#212121 !important'
                            : 'white !important',
                    },
                    td: {
                        color: darkMode ? 'black !important' : null,
                    },
                    boxShadow: darkMode ? 1 : 0,
                    borderWidth: darkMode ? 1 : 0,
                    borderStyle: darkMode ? 'solid' : null,
                    borderColor: darkMode ? '#DDD' : null,
                }}>
                <MaterialTable
                    style={{ height: '100%' }}
                    title="Collection Hosts"
                    icons={tableIcons}
                    columns={columns}
                    data={rows}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        sorting: true,
                        pageSize: 3,
                        pageSizeOptions: [],
                        draggable: false,
                    }}
                    actions={[
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Delete',
                            onClick: (e, rowData) => {
                                deleteHandler(rowData);
                            },
                        },
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: () => {
                                handleOpen();
                            },
                        },
                    ]}
                    // Edit logic for the inline editing of hosts
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...rows];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setRows([...dataUpdate]);
                                    let result = [];
                                    state.trunkCollectionHosts.forEach(
                                        (host) => {
                                            if (host.host === oldData.host) {
                                                // if one state value matches the old data - update this value
                                                const temp = {
                                                    ...newData,
                                                    id: host.id,
                                                };
                                                result.push(temp);
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        editedHostIDs: [
                                                            {
                                                                ...temp,
                                                            },
                                                            ...state.editedHostIDs,
                                                        ],
                                                    },
                                                });
                                            } else {
                                                // make sure to grab that host and keep it in state
                                                const temp = { ...host };
                                                result.push(temp);
                                            }
                                        },
                                    );
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trunkCollectionHosts: result,
                                        },
                                    });
                                    resolve();
                                }, 1000);
                            }),
                    }}
                    localization={{
                        header: {
                            actions: '',
                        },
                    }}
                />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: darkMode ? '#212121' : null,
                        color: darkMode ? 'white' : null,
                    },
                }}>
                <DialogTitle>Add Collection Host</DialogTitle>
                <DialogContent
                    sx={{
                        width: '40vh',
                    }}>
                    {/* Textfield for the host with some regex checking of ip/fqdn name */}
                    <ChakraProvider theme={chakratheme} resetCSS>
                        <FormControl isInvalid={errors?.host} isRequired>
                            <FormLabel color={darkMode ? 'white' : null}>
                                Host
                            </FormLabel>
                            <Box
                                sx={{
                                    // marginBottom: '10px',
                                    display: 'flex',
                                    width: 'full',
                                    alignItems: 'flex-start',
                                }}
                                data-walkthroughid={
                                    walkthroughIds.endpoints.modal
                                        .ufHostInput
                                }>
                                <Input
                                    className="w-full"
                                    size={'sm'}
                                    textColor={darkMode ? 'white' : null}
                                    value={host}
                                    placeholder="Please enter a collection host"
                                    
                                    sx={{ width: '100%' }}
                                    onChange={(e) => {
                                        setErrors({ host: '' });
                                        setHost(e.target.value);

                                        // Collection Host Validation
                                        const isDomain = validator.isFQDN(
                                            e.target.value,
                                        );
                                        const isIp = validator.isIP(
                                            e.target.value,
                                        );

                                        if (!isDomain && !isIp) {
                                            setErrors({
                                                host: 'Only IP addresses or URLs are permitted',
                                            });
                                        } else {
                                            setErrors(null);
                                        }
                                    }}
                                />
                            </Box>
                            {errors?.host ? (
                                <FormErrorMessage>
                                    {errors.host}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    eg. 10.1.1.1 or cdn.example.com
                                </FormHelperText>
                            )}
                        </FormControl>
                    </ChakraProvider>
                    {/* <TextField
                        sx={{ width: '100%' }}
                        label="Host"
                        placeholder="10.1.1.1 or cdn.example.com"
                        onChange={(e) => {
                            setErrors({ host: '' });
                            setHost(e.target.value);

                            // Collection Host Validation
                            const isDomain = validator.isFQDN(e.target.value);
                            const isIp = validator.isIP(e.target.value);

                            if (!isDomain && !isIp) {
                                setErrors({
                                    host: 'Only IP addresses or URLs are permitted',
                                });
                            } else {
                                setErrors(null);
                            }
                        }}
                        error={errors?.host}
                        helperText={errors?.host}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufHostInput
                        }
                    /> */}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        sx={{ backgroundcolor: '#0d6efd', color: '#ffffff' }}
                        variant="contained"
                        disabled={errors || !host}
                        style={{
                            color: darkMode ? 'white' : null,
                            backgroundColor: darkMode ? '#1976d2' : null,
                            opacity:
                                darkMode && (errors || !host) ? '0.4' : null,
                        }}
                        onClick={() => {
                            onSubmitHandler();
                        }}
                        data-walkthroughid={
                            walkthroughIds.endpoints.modal.ufAddButton
                        }>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default EditSipCollectionHost;
