import { InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Tooltip,
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import style from '../../edit/styles';
import { useDefinedAttributes } from '../DefinedAttributesContext';
import useDefinedAttributeValidation from './useDefinedAttributeValidation';
import { isEmpty } from 'lodash';

export default function GuidAttribute({
    attribute,
    index,
    requiredScope,
}) {
    const validation = JSON.parse(attribute.definedAttributeValidationType);

    const { attributesErrors } = useDefinedAttributes();
    const {
        handleAttributeChange,
        handleAttributeError,
        removeAttributeError,
    } = useDefinedAttributeValidation();

    const error = attributesErrors.find((obj) => obj.id === index);

    const guidPattern =
        /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/i;

    return (
        <Box sx={style.fieldContainer}>
            <FormControl isRequired={validation.Required} isInvalid={error}>
                <FormLabel sx={style.label}>
                    {attribute.name}{' '}
                    {attribute.toolTip && (
                        <Tooltip
                            label={attribute.toolTip}
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </FormLabel>
                <Input
                    type="text"
                    placeholder="Please enter a value"
                    value={attribute.value}
                    onChange={(e) => {
                        removeAttributeError(index);
                        if (
                            !isEmpty(e.target.value) &&
                            !guidPattern.test(e.target.value)
                        ) {
                            handleAttributeError(index, 'Invalid GUID format.');
                        }
                        handleAttributeChange(e.target.value, index);
                    }}
                    isDisabled={requiredScope < attribute.updateScope}
                />

                {error ? (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : attribute?.description ? (
                    <FormHelperText>{attribute?.description}</FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    );
}
