import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ChakraButtonProps
 * @property {import('react').ReactNode} children
 * @param {ChakraButtonProps & import('@chakra-ui/react').ButtonProps} props
 * @param {import('@chakra-ui/react').ButtonProps} props.rest
 * @returns
 */
export default function ChakraButton({ children, ...props }) {
    const { colorScheme } = useSelector((state) => state.settings);
    const isSecondary =
        props.className?.includes('secondary') || props.variant === 'outline';
    let bgColor;

    if (props && isSecondary) {
        bgColor = 'white';
    } else if (colorScheme === 'gray') {
        bgColor = 'gray.500';
    } else {
        bgColor = null;
    }
    return (
        <Button colorScheme={colorScheme} bg={bgColor} {...props}>
            {children}
        </Button>
    );
}
