import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, useServicesReducer } from './useServicesReducer';

export const ServicesContext = createContext(null);

export const ServicesProvider = (props) => {
    const [state, dispatch] = useReducer(
        useServicesReducer,
        initialState,
    );
    const { services, originalData, attributesErrors } = state;

    const value = useMemo(
        () => ({
            dispatch,
            originalData,
            services,
            attributesErrors,
        }),
        [dispatch, services, originalData, attributesErrors],
    );

    return <ServicesContext.Provider value={value} {...props} />;
};

export const useServices = () => {
    const context = useContext(ServicesContext);

    if (context === undefined) {
        throw new Error(
            `useServices must be used within a ServicesProvider`,
        );
    }

    return context;
};
