import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from '@/components/v4';
import {
    ChakraProvider,
    Box,
    useDisclosure,
    Spinner,
    // Button as ChakraButton,
    forwardRef,
} from '@chakra-ui/react';

import { Button as ChakraButton } from 'components/v4';

import Error from '../maintenance/Error';
import walkthroughIds from './walkthroughIds';
import chakratheme from '@/chakratheme';
import { useLocation } from 'react-router-dom';

export const CANNOT_DELETE_MODE = 'Can not Delete';
export const LOADING_DELETE_MODE = 'Verifying for Deleting';

const Button = forwardRef((props, ref) => (
    <ChakraButton
        ref={ref}
        {...props}
        borderStyle="none"
        type="submit"
        fontWeight={500}
    />
));

/*
 * Modal redux actions opens a pop up unlike edit form that opens
 * a new page, refactor into functional component with conditional rendering
 */
const ReduxModal = (props) => {
    const { modalSize, modalFooter, modalContent, modalTitle, modalButtons } =
        props;

    const { loading, restError, uploading, show, mode } = useSelector(
        (state) => {
            return { ...state.modal };
        },
    );

    const { darkMode } = useSelector((state) => state.settings);
    const { modal } = walkthroughIds;
    const dispatch = useDispatch();
    const { onClose } = useDisclosure();
    const onModalSubmit = () => {
        dispatch({ type: 'ON_MODAL_SUBMIT', payload: true });
    };
    const onHide = () => {
        dispatch({
            type: 'RESET_MODAL',
        });
    };
    const handleClose = () => {
        onClose();
        onHide();
    };
    const submitButton = () => {
        if (modalButtons) {
            return { ...modalButtons };
        }
        switch (mode) {
            case 'Delete':
                return (
                    <Button
                        colorScheme="red"
                        data-walkthroughid={modal.ufDeleteBtn}
                    >
                        Delete
                    </Button>
                );
            case 'Copy':
                return (
                    <Button data-walkthroughid={modal.ufCopyBtn}>Copy</Button>
                );
            case 'Add':
                return (
                    <Button data-walkthroughid={modal.ufCreateBtn}>
                        Create
                    </Button>
                );
            case 'Convert':
                return (
                    <Button data-walkthroughid={modal.ufConvertBtn}>
                        Convert
                    </Button>
                );
            case 'View':
                return null;
            case CANNOT_DELETE_MODE:
                return null;
            case LOADING_DELETE_MODE:
                return null;
            case 'Info':
                return null;
            default:
                return (
                    <Button data-walkthroughid={modal.ufSaveBtn}>Update</Button>
                );
        }
    };
    return (
        <ChakraProvider resetCSS={false} theme={chakratheme}>
            <Modal
                isOpen={show}
                size={modalSize || '3xl'}
                onClose={handleClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Box
                            as="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                onModalSubmit();
                            }}
                        >
                            {restError ? (
                                <Error
                                    error={restError}
                                    style={{ marginBottom: 10 }}
                                />
                            ) : null}
                            {!loading &&
                            !uploading &&
                            mode !== LOADING_DELETE_MODE ? (
                                <>
                                    {' '}
                                    {modalContent}
                                    {modalFooter}
                                    <ModalFooter
                                        style={{
                                            border: 'none',
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ChakraButton
                                            mr={4}
                                            variant="outline"
                                            className="secondary"
                                            fontWeight={500}
                                            onClick={onHide}
                                            data-walkthroughid={
                                                modal.ufCloseBtn
                                            }
                                        >
                                            Close
                                        </ChakraButton>
                                        {submitButton()}
                                    </ModalFooter>
                                </>
                            ) : (
                                <Spinner
                                    speed="0.65s"
                                    thickness="2px"
                                    color={
                                        darkMode ? 'primary.400' : 'primary.500'
                                    }
                                    style={{
                                        marginTop: '2.5%',
                                        marginLeft: '50%',
                                    }}
                                />
                            )}
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    );
};

export default ReduxModal;
