import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { axios } from '@/services/axios';

function useDeleteAAccount(id) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (selectedRow) => {
            try {
                const payload = selectedRow;
                return axios.delete(
                    `/Services/TeamsPhoneMobile/${id}/Association`,
                    {
                        data: payload,
                    },
                );
            } catch (error) {
                console.log('error now: ', error.message);
                throw new Error(error?.message || 'Failed to delete account');
            }
        },
        onError: (error) => {
            let errorMessage = 'Failed to delete associated account';
            if (error?.response.status === 401) {
                errorMessage =
                    'Endpoint not accessible with current permissions';
            } else {
                errorMessage = error?.response?.data?.message;
            }
            toast.error(errorMessage);
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: ['getAssociatedAccounts', id],
            });
        },
    });
}

export { useDeleteAAccount };
