import { isEmpty } from 'lodash';
import { useDefinedAttributes } from '../DefinedAttributesContext';

export default function useDefinedAttributeValidation() {
    const { dispatch } = useDefinedAttributes();

    const handleAttributeError = (index, message) => {
        dispatch({
            type: 'ADD_ATT_ERROR',
            index: index,
            payload: {
                id: index,
                isError: true,
                message: message,
            },
        });
    };

    const removeAttributeError = (index) => {
        dispatch({
            type: 'UPDATE_ATT_ERROR',
            index: index,
        });
    };

    const handleAttributeChange = (value, index) => {
        dispatch({
            type: 'UPDATE_ATT_VALUE',
            index: index,
            payload: isEmpty(value) ? null : value,
        });
    };

    return {
        handleAttributeError,
        removeAttributeError,
        handleAttributeChange,
    };
}
