import { Box, Skeleton } from '@chakra-ui/react';
import { Button, Heading } from 'components/v4';
import { useNavigate, useParams } from 'react-router-dom';

import { axios } from '@/services/axios';
import { useQuery } from '@tanstack/react-query';
import { differenceBy } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ServiceStatus from './ServiceStatus';
import VirtualizedServiceUserTable from './VirtualizedServiceUserTable';
import { VirtualizedServiceUserTableProvider } from './VirtualizedServiceUserTableProvider';

function BadgeWithCount({ count }) {
    return (
        <div className="absolute -right-2 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-brand-400 text-xs font-bold text-white dark:border-gray-900">
            {count}
        </div>
    );
}

function MoveButton({
    children,
    isDisabled,
    handleOnClick,
    dataWalkthroughId,
}) {
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });

    return (
        <Button
            type="button"
            class="focus:ring-blue-30 relative inline-flex h-8 w-full items-center justify-center rounded-lg border-[1px] border-brand-500 p-3 text-center font-medium leading-7 text-brand-500 focus:outline-none focus:ring-4 disabled:cursor-not-allowed disabled:opacity-40"
            disabled={isDisabled}
            bg={darkMode ? '#263238 !important' : 'inherit'}
            onClick={handleOnClick}
            data-walkthroughid={dataWalkthroughId}>
            {children}
        </Button>
    );
}

export default function ActivateServiceUsers() {
    const { id: serviceId } = useParams();
    const navigate = useNavigate();

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // left side table (users pool)
    const [usersAvailable, setUsersAvailable] = useState([]);
    // right side table (activated/yet to be activated users)
    const [usersChosen, setUsersChosen] = useState([]);
    // service users that are already activated
    const [activatedUsers, setActivatedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [checkedAvailable, setCheckedAvailable] = useState([]);
    const [checkedChosen, setCheckedChosen] = useState([]);

    // service status
    const [success, setSuccess] = useState(0);
    const [failed, setFailed] = useState(0);
    const [activateError, setActivateError] = useState({
        count: 0,
        message: '',
    });
    const [deactivateError, setDeactivateError] = useState({
        count: 0,
        message: '',
    });
    const [total, setTotal] = useState(0);

    // get users available to be activated for the service
    const { data: service, isFetching: serviceLoading } = useQuery({
        queryKey: ['service', serviceId],
        queryFn: async () => {
            const res = await axios.get(`service/${serviceId}`);
            return res.data;
        },
        enabled: Boolean(serviceId),
        refetchOnWindowFocus: false,
    });

    // get users available to be activated for the service
    const { data: companyUsers, isFetching: companyUsersLoading } = useQuery({
        queryKey: ['companyUsers', currentCompany],
        queryFn: async () => {
            const res = await axios.get(`users/${currentCompany}`);
            return res.data;
        },
        enabled: Boolean(currentCompany),
        onSuccess: async (data) => {
            // users avaialble to be activated
            setUsersAvailable(data);
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
    });

    // get activated users for the service
    const { isFetching: serviceUsersLoading } = useQuery({
        queryKey: ['serviceUsers', serviceId],
        queryFn: async () => {
            const res = await axios.get(
                `service/vendordefined/${serviceId}/users`,
            );
            return res.data;
        },
        // service id, company users is not loading and company users is greater than 0, then fetch service users
        enabled:
            Boolean(serviceId) &&
            !companyUsersLoading &&
            companyUsers?.length > 0,
        onSuccess: async (data) => {
            if (usersAvailable) {
                let ua = [...usersAvailable];
                let usersChosen = [];

                ua = ua.filter((user) => {
                    if (data.includes(user.id)) {
                        usersChosen.push(user);
                        return false; // Exclude this user from usersAvailable
                    }
                    return true; // Keep this user in usersAvailable
                });

                // Push filtered available users
                setUsersAvailable(ua);
                // Push chosen users into usersChosen
                setUsersChosen(usersChosen);
                // users that are already activated
                setActivatedUsers(usersChosen);
            }
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
    });

    // from chosen to available
    const handleMoveToAvailable = () => {
        setUsersAvailable((prev) => [...prev, ...checkedChosen]);
        setUsersChosen((all) => differenceBy(all, checkedChosen, 'id'));
        setCheckedChosen([]);
    };
    // from avaialble to chosen
    const handleMoveToChosen = () => {
        setUsersChosen((prev) => [...prev, ...checkedAvailable]);
        setUsersAvailable((all) => differenceBy(all, checkedAvailable, 'id'));
        setCheckedAvailable([]);
    };
    const handleCheckAvailableRow = (data) => {
        const rowSelected = checkedAvailable.some((a) => a.id == data.id);
        if (rowSelected) {
            setCheckedAvailable((prev) => prev.filter((v) => v.id !== data.id));
            return;
        }
        setCheckedAvailable((prev) => [...prev, data]);
    };

    const handleCheckChosenRow = (data) => {
        const rowSelected = checkedChosen.some((a) => a.id == data.id);
        if (rowSelected) {
            setCheckedChosen((prev) => prev.filter((v) => v.id !== data.id));
            return;
        }
        setCheckedChosen((prev) => [...prev, data]);
    };

    // all from chosen to available
    const handleMoveAllToAvailable = () => {
        setUsersAvailable((prev) => [...prev, ...usersChosen]);
        setUsersChosen((all) => differenceBy(all, usersChosen, 'id'));
        setCheckedChosen([]);
    };
    // all from available to chosen
    const handleMoveAllToChosen = () => {
        setUsersChosen((prev) => [...prev, ...usersAvailable]);
        setUsersAvailable((all) => differenceBy(all, usersAvailable, 'id'));
        setCheckedAvailable([]);
    };

    const handleBackButton = () => {
        setIsLoading(false);
        setActivateError({
            count: 0,
            message: '',
        });
        setDeactivateError({
            count: 0,
            message: '',
        });
        setTotal(0);
        setSuccess(0);
        setFailed(0);
        navigate('/dashboard-services');
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        let failedDeactivateUsers = [];
        let failedActivateUsers = [];
        try {
            let activateUsers = differenceBy(usersChosen, activatedUsers, 'id');
            let deactivateUsers = differenceBy(
                activatedUsers,
                usersChosen,
                'id',
            );

            setTotal(activateUsers.length + deactivateUsers.length);

            // activate service users
            for (const user of activateUsers) {
                try {
                    await axios.post(
                        `/service/vendordefined/${serviceId}/user/${user.id}/activate`,
                    );
                    setSuccess((prev) => prev + 1);
                } catch (e) {
                    setFailed((prev) => prev + 1);
                    failedActivateUsers.push(user);
                }
            }

            // deactivate service users
            for (const user of deactivateUsers) {
                try {
                    await axios.post(
                        `/service/vendordefined/${serviceId}/user/${user.id}/deactivate`,
                    );
                    setSuccess((prev) => prev + 1);
                } catch (e) {
                    setFailed((prev) => prev + 1);
                    failedDeactivateUsers.push(user);
                }
            }
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            // setIsLoading(false);
            if (failedActivateUsers.length > 0) {
                let message = '';
                for (const [index, user] of failedActivateUsers.entries()) {
                    if (index !== failedActivateUsers.length - 1) {
                        message = message.concat(
                            user.o365UserPrincipalName + ', ',
                        );
                    } else {
                        message = message.concat(
                            user.o365UserPrincipalName + '.',
                        );
                    }
                }
                setActivateError((prevState) => ({
                    ...prevState,
                    count: failedActivateUsers.length,
                    message: message,
                }));
            }

            if (failedDeactivateUsers.length > 0) {
                let message = '';
                for (const [index, user] of failedDeactivateUsers.entries()) {
                    if (index !== failedDeactivateUsers.length - 1) {
                        message = message.concat(
                            user.o365UserPrincipalName + ', ',
                        );
                    } else {
                        message = message.concat(
                            user.o365UserPrincipalName + '.',
                        );
                    }
                }
                setDeactivateError((prevState) => ({
                    ...prevState,
                    count: failedDeactivateUsers.length,
                    message: message,
                }));
            }
        }
    };

    return (
        <>
            <Heading fontSize="3xl" as="h2" mb={8}>
                <Box display={'flex'} flexDirection={'row'}>
                    Activate Service Users:
                    {serviceLoading ||
                    companyUsersLoading ||
                    serviceUsersLoading ? (
                        <Skeleton mt={1} w={200} h={8} ml={1} />
                    ) : (
                        <> {service?.name || 'Vendor Defined'}</>
                    )}
                </Box>
            </Heading>

            {isLoading ? (
                <ServiceStatus
                    title={'Users'}
                    failedCount={failed}
                    activateError={activateError}
                    deactivateError={deactivateError}
                    successCount={success}
                    totalCount={total}
                    handleBack={handleBackButton}
                />
            ) : (
                <>
                    <Box className="flex flex-row justify-center">
                        <VirtualizedServiceUserTableProvider
                            data={usersAvailable}
                            field={'o365UserPrincipalName'}>
                            <VirtualizedServiceUserTable
                                checkedArray={checkedAvailable}
                                onClick={handleCheckAvailableRow}
                                data={usersAvailable}
                                captionName="Available Users"
                                placeholder="Search available users"
                                // dependant on service users, so track status, else it will load then list may change because of activated service users
                                isLoading={
                                    companyUsersLoading || serviceUsersLoading
                                }
                                isDisabled={isLoading}
                            />
                            <Box className="flex flex-col justify-center gap-4 p-4">
                                <MoveButton
                                    isDisabled={
                                        checkedAvailable.length === 0 ||
                                        isLoading
                                    }
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/chosen'
                                    }
                                    handleOnClick={handleMoveToChosen}>
                                    &gt;
                                    <BadgeWithCount
                                        count={checkedAvailable.length}
                                    />
                                </MoveButton>

                                <MoveButton
                                    isDisabled={
                                        checkedChosen.length === 0 || isLoading
                                    }
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/available'
                                    }
                                    handleOnClick={handleMoveToAvailable}>
                                    &lt;
                                    <BadgeWithCount
                                        count={checkedChosen.length}
                                    />
                                </MoveButton>

                                <MoveButton
                                    isDisabled={isLoading}
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/chosen/all'
                                    }
                                    handleOnClick={handleMoveAllToChosen}>
                                    &gt;&gt;
                                </MoveButton>

                                <MoveButton
                                    isDisabled={isLoading}
                                    dataWalkthroughId={
                                        '/service-profiles/button/move/available/all'
                                    }
                                    handleOnClick={handleMoveAllToAvailable}>
                                    &lt;&lt;
                                </MoveButton>
                            </Box>

                            <VirtualizedServiceUserTable
                                checkedArray={checkedChosen}
                                onClick={handleCheckChosenRow}
                                data={usersChosen}
                                captionName="Chosen Users"
                                placeholder="Search chosen users"
                                // dependant on company users, so track status, else it will not show skeleton
                                isLoading={
                                    serviceUsersLoading || companyUsersLoading
                                }
                                isDisabled={isLoading}
                            />
                        </VirtualizedServiceUserTableProvider>
                    </Box>

                    <Box className="mt-12 flex justify-center">
                        <Button
                            className="mr-14"
                            variant="outline"
                            w="84px"
                            isDisabled={isLoading}
                            onClick={handleBackButton}
                            data-walkthroughid={
                                '/service-profiles/button/back'
                            }>
                            Back
                        </Button>
                        <Button
                            className="ml-14"
                            w="84px"
                            isLoading={isLoading}
                            onClick={handleSubmit}
                            data-walkthroughid={
                                '/service-profiles/button/save'
                            }>
                            Save
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
}
