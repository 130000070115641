import { useState, useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from '@/services/axios';
// import { Button } from 'react-bootstrap';
import SplitJoinStatus from './SplitJoinStatus';
import { getDarkToggleBtnStyles } from './constants';
import walkthroughIds from '../walkthroughIds';
import {
    Box,
    ChakraProvider,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    ListItem,
    Skeleton,
    Stack,
    Text,
    UnorderedList,
    extendTheme,
} from '@chakra-ui/react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import { useDeepMerge } from 'hooks/useDeepMerge';

const SplitJoinBlock = ({ isOpen, onClose, id, state, setId }) => {
    const dispatch = useDispatch();
    const { theme } = useDeepMerge();
    const [isLoading, setIsLoading] = useState(true);
    const [splitOptions, setSplitOptions] = useState([]);
    const [joinOptions, setJoinOptions] = useState([]);

    const [numberBlock, setNumberBlock] = useState(null);
    const [bSizeWithIds, setBSizeWithIds] = useState(new Map());
    const [submitState, setSubmitState] = useState(state);

    const [errorMessage, setErrorMessage] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [successCount, setSuccessCount] = useState(0);

    const [selectedSplitOption, setSelectedSplitOption] = useState(null);
    const [selectedJoinOption, setSelectedJoinOption] = useState(null);

    const { darkMode } = useSelector((state) => state.settings);
    const darkToggleBtnStyle = getDarkToggleBtnStyles(darkMode);

    const { requiredScope } = useSelector((state) => state.login);
    const {
        ufSplitBlocksizeButton,
        ufJoinBlocksizeButton,
        ufCloseButton,
        ufBackButton,
        ufSubmitButton,
    } = walkthroughIds;

    useEffect(() => {
        if (numberBlock) {
            setErrorMessage(null);
            // if pending update then do not allow to split as backend will send back the same
            if (!numberBlock.pendingUpdate) {
                let tempSplitOptions = [];
                // value corresponds to the times it
                // would iterate to create the new number block
                if (numberBlock.blockSize === 5) {
                    tempSplitOptions.push({ value: '1' });
                } else if (numberBlock.blockSize === 10) {
                    tempSplitOptions.push({ value: '1' }, { value: '5' });
                } else if (numberBlock.blockSize === 100) {
                    tempSplitOptions.push(
                        { value: '1' },
                        { value: '5' },
                        { value: '10' },
                    );
                }
                setSplitOptions(tempSplitOptions);
            }

            // if number block if less than 100, then call to get options
            if (numberBlock.blockSize != 100) {
                axios
                    .get('/numberblock/join/' + id, {
                        headers: {
                            'X-RequestScope': requiredScope,
                        },
                    })
                    .then((res) => {
                        const bSizeWithIds = new Map();
                        const tempJoin = [];
                        for (const key in res.data.options) {
                            tempJoin.push({ value: key });
                            if (res.data.options.hasOwnProperty(key)) {
                                bSizeWithIds.set(key, res.data.options[key]);
                            }
                        }
                        setJoinOptions(tempJoin);
                        setBSizeWithIds(bSizeWithIds);
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setErrorMessage(err.response.data.message);
                        setSubmitState('idle');
                        setIsLoading(false);
                    });
            } else setIsLoading(false);
        } else {
            setSubmitState('idle');
            setIsLoading(false);
        }
    }, [numberBlock]);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            setErrorMessage(null);
            axios
                .get('/numberblock/' + id, {
                    headers: {
                        'X-RequestScope': requiredScope,
                    },
                })
                .then((res) => {
                    if (res.data.pendingUpdate) {
                        setErrorMessage(
                            'The Number Block still has changes pending. Please wait for the sync process and try again.',
                        );
                        setSubmitState('idle');
                        setIsLoading(false);
                        return;
                    }
                    setNumberBlock(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.message);
                    reset();
                    setSubmitState('idle');
                    setIsLoading(false);
                });
        }
    }, [id]);

    const handleSplitChange = (event) => {
        setSubmitState('idle');
        if (!isEmpty(selectedJoinOption)) {
            setSelectedJoinOption('');
        }
        if (event.target.value === selectedSplitOption) {
            setSelectedSplitOption('');
        } else {
            setSelectedSplitOption(event.target.value);
        }
    };

    const handleJoinChange = (event) => {
        setSubmitState('idle');
        if (!isEmpty(selectedSplitOption)) {
            setSelectedSplitOption('');
        }
        if (event.target.value === selectedJoinOption) {
            setSelectedJoinOption('');
        } else {
            setSelectedJoinOption(event.target.value);
        }
    };

    // create new number block after delete old number blocks
    const processBlocks = async (bOpt, processType) => {
        // join process
        if (processType) {
            let newNumberBlock = {
                companyID: numberBlock.companyID,
                partnerID: numberBlock.partnerID,
                wholesalerID: numberBlock.wholesalerID,
                carrierID: numberBlock.carrierID,
                inboundCarrierID: numberBlock.inboundCarrierID,
                outboundCarrierID: numberBlock.outboundCarrierID,
                blockSize: bOpt,
                publicPool: numberBlock.publicPool,
                // need to add first and last number
                first: numberBlock.first,
                last: (
                    parseInt(numberBlock.first) + parseInt(bOpt - 1)
                ).toString(),
            };

            // create new single number block
            try {
                await axios.post('/numberblock', newNumberBlock);
                setSuccessCount((successCount) => successCount + 1);
            } catch (err) {
                setFailedCount((failedCount) => failedCount + 1);
                setErrorMessage(err.response.data.message);
                reset();
                setSubmitState('idle');
            }
        } else {
            const iterateSize = parseInt(numberBlock.blockSize / bOpt);

            for (var i = 0; i < iterateSize; i++) {
                let firstInc = i * parseInt(bOpt);
                let lastInc = firstInc + (parseInt(bOpt) - 1);
                let newNumberBlock = {
                    companyID: numberBlock?.companyID,
                    partnerID: numberBlock?.partnerID,
                    wholesalerID: numberBlock?.wholesalerID,
                    carrierID: numberBlock?.carrierID,
                    inboundCarrierID: numberBlock?.inboundCarrierID,
                    outboundCarrierID: numberBlock?.outboundCarrierID,
                    blockSize: bOpt,
                    publicPool: numberBlock?.publicPool,
                    first: (parseInt(numberBlock.first) + firstInc).toString(),
                    last: (parseInt(numberBlock.first) + lastInc).toString(),
                };

                // create new individual number block of selected block size
                await axios
                    .post('/numberblock', newNumberBlock)
                    .then((result) => {
                        setSuccessCount((successCount) => successCount + 1);
                    })
                    .catch((error) => {
                        setFailedCount(i + 1);
                        setErrorMessage(error.response.data.message);
                        reset();
                        setSubmitState('idle');
                        return;
                    });
            }
        }

        return;
    };

    // submit button
    const handleSubmit = async (e) => {
        // prevent refreshing the page
        e.preventDefault();
        // restrict from sending empty block size
        setErrorMessage(null);
        setSubmitState('idle');
        setTotalCount(0);
        setSuccessCount(0);
        setFailedCount(0);

        const selectedBlockSize = parseInt(
            selectedJoinOption || selectedSplitOption,
        );
        const processType = selectedJoinOption ? true : false;
        const deleteBlockIds = bSizeWithIds.get(selectedBlockSize.toString());

        // calculate total count option based on blocksize
        // + 1 is for the the new number block
        if (selectedJoinOption) {
            setTotalCount(deleteBlockIds.length + 1);

            setSubmitState('processing');

            for (let i = 0; i < deleteBlockIds.length; i++) {
                try {
                    await axios.delete(`/numberblock/${deleteBlockIds[i]}`);
                    setSuccessCount(i + 1);
                } catch (err) {
                    setErrorMessage(err.response?.data?.message);
                    reset();
                    setSubmitState('idle');
                    return;
                }
                setSuccessCount(i + 1);
            }
        } else {
            // total request count
            setTotalCount(
                parseInt(numberBlock.blockSize / selectedBlockSize) + 1,
            );

            setSubmitState('processing');

            try {
                await axios.delete(`/numberblock/${numberBlock.id}`);
                setSuccessCount(1);
            } catch (err) {
                setErrorMessage(err.response?.data?.message);
                reset();
                setSubmitState('idle');
                return;
            }
        }
        await processBlocks(selectedBlockSize, processType);
    };

    // back button
    const closeModal = () => {
        setSubmitState('idle');
        setId(null);
        setIsLoading(true);
        setErrorMessage(null);
        reset();
        onClose();
    };

    // reset close
    const handleClose = () => {
        closeModal();
        dispatch({
            type: 'RESET_TABLE',
        });
    };

    const reset = () => {
        setNumberBlock(null);
        setSelectedJoinOption(null);
        setSelectedSplitOption(null);
        setJoinOptions([]);
        setSplitOptions([]);
    };

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <Modal
                closeOnOverlayClick={false}
                isCentered
                autoFocus={false}
                isOpen={isOpen}
                onClose={() => closeModal()}
                size={'auto'}>
                <ModalOverlay />
                <ModalContent bg={'blue'} height={'xl'} width={'4xl'}>
                    <ModalHeader display={'flex'}>
                        Number Block
                        {isLoading ? (
                            <Skeleton
                                marginLeft={2}
                                width={'15% !important'}
                                h={8}
                            />
                        ) : numberBlock ? (
                            `: +${numberBlock?.first}`
                        ) : undefined}
                    </ModalHeader>
                    <ModalBody>
                        {errorMessage && (
                            <Alert status="error" mb={4}>
                                <AlertIcon />
                                <AlertDescription>
                                    {errorMessage}
                                </AlertDescription>
                            </Alert>
                        )}
                        {submitState === 'idle' && (
                            <>
                                <Grid templateColumns={'10fr 1fr 10fr'} gap={4}>
                                    {/* Split */}
                                    <Box>
                                        {isLoading ? (
                                            Array(3)
                                                .fill(1)
                                                .map((_, index) => (
                                                    <Skeleton
                                                        key={index}
                                                        flex={4}
                                                        h={8}
                                                        mt={5}
                                                    />
                                                ))
                                        ) : (
                                            <>
                                                <Heading
                                                    fontSize="3xl"
                                                    as="h2"
                                                    mb={2}>
                                                    <span className="text-2xl font-semibold">
                                                        Split
                                                    </span>
                                                </Heading>

                                                <Box>
                                                    {/* Block size */}
                                                    <Stack>
                                                        <FormControl
                                                            sx={
                                                                darkToggleBtnStyle
                                                            }>
                                                            {splitOptions.length >
                                                            0 ? (
                                                                <>
                                                                    <FormLabel>
                                                                        Block
                                                                        size
                                                                    </FormLabel>
                                                                    <ToggleButtonGroup
                                                                        data-walkthroughid={
                                                                            ufSplitBlocksizeButton
                                                                        }
                                                                        className="toggleButton"
                                                                        color="primary"
                                                                        exclusive
                                                                        value={
                                                                            selectedSplitOption
                                                                        }
                                                                        aria-label="Platform"
                                                                        style={{
                                                                            height: '48px',
                                                                            alignItems:
                                                                                'center',
                                                                        }}
                                                                        onChange={(
                                                                            e,
                                                                            value,
                                                                        ) =>
                                                                            handleSplitChange(
                                                                                e,
                                                                                value,
                                                                            )
                                                                        }>
                                                                        {splitOptions.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <ToggleButton
                                                                                    size="medium"
                                                                                    style={{
                                                                                        width: '60px',
                                                                                    }}
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    value={
                                                                                        item.value
                                                                                    }>
                                                                                    {
                                                                                        item.value
                                                                                    }
                                                                                </ToggleButton>
                                                                            ),
                                                                        )}
                                                                    </ToggleButtonGroup>
                                                                </>
                                                            ) : (
                                                                <Text>
                                                                    No adjoining
                                                                    number block
                                                                    to split
                                                                </Text>
                                                            )}
                                                        </FormControl>
                                                        {selectedSplitOption &&
                                                            numberBlock && (
                                                                <Text>
                                                                    This will
                                                                    create{' '}
                                                                    {parseInt(
                                                                        numberBlock?.blockSize /
                                                                            selectedSplitOption,
                                                                    )}{' '}
                                                                    individual{' '}
                                                                    {
                                                                        selectedSplitOption
                                                                    }{' '}
                                                                    number block
                                                                    starting at
                                                                    +
                                                                    {
                                                                        numberBlock?.first
                                                                    }{' '}
                                                                    and ending
                                                                    at +
                                                                    {
                                                                        numberBlock?.last
                                                                    }
                                                                </Text>
                                                            )}
                                                    </Stack>
                                                </Box>
                                            </>
                                        )}
                                    </Box>

                                    <Divider
                                        marginY={0}
                                        orientation="vertical"
                                        color={'black'}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    />

                                    {/* Join */}
                                    <Box>
                                        {isLoading ? (
                                            Array(3)
                                                .fill(1)
                                                .map((_, index) => (
                                                    <Skeleton
                                                        key={index}
                                                        flex={4}
                                                        h={8}
                                                        mt={5}
                                                    />
                                                ))
                                        ) : (
                                            <>
                                                <Heading
                                                    fontSize="3xl"
                                                    as="h2"
                                                    mb={2}>
                                                    <span className="text-2xl font-semibold">
                                                        Join
                                                    </span>
                                                </Heading>

                                                <Box>
                                                    {/* Block size */}
                                                    <Stack>
                                                        <FormControl
                                                            sx={
                                                                darkToggleBtnStyle
                                                            }>
                                                            {joinOptions.length >
                                                            0 ? (
                                                                <>
                                                                    <FormLabel>
                                                                        Block
                                                                        size
                                                                    </FormLabel>
                                                                    <ToggleButtonGroup
                                                                        data-walkthorughid={
                                                                            ufJoinBlocksizeButton
                                                                        }
                                                                        className="toggleButton"
                                                                        color="primary"
                                                                        exclusive
                                                                        value={
                                                                            selectedJoinOption
                                                                        }
                                                                        aria-label="Platform"
                                                                        style={{
                                                                            height: '48px',
                                                                            alignItems:
                                                                                'center',
                                                                        }}
                                                                        onChange={(
                                                                            e,
                                                                            value,
                                                                        ) =>
                                                                            handleJoinChange(
                                                                                e,
                                                                                value,
                                                                            )
                                                                        }>
                                                                        {joinOptions.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <ToggleButton
                                                                                    size="medium"
                                                                                    style={{
                                                                                        width: '60px',
                                                                                    }}
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    value={
                                                                                        item.value
                                                                                    }>
                                                                                    {
                                                                                        item.value
                                                                                    }
                                                                                </ToggleButton>
                                                                            ),
                                                                        )}
                                                                    </ToggleButtonGroup>
                                                                </>
                                                            ) : (
                                                                <Text>
                                                                    No adjoining
                                                                    number block
                                                                    to join
                                                                </Text>
                                                            )}
                                                        </FormControl>
                                                        {selectedJoinOption &&
                                                            numberBlock && (
                                                                <Text>
                                                                    This will
                                                                    create a new
                                                                    block +
                                                                    {
                                                                        numberBlock?.first
                                                                    }{' '}
                                                                    - +
                                                                    {parseInt(
                                                                        numberBlock?.first,
                                                                    ) +
                                                                        parseInt(
                                                                            selectedJoinOption -
                                                                                1,
                                                                        )}{' '}
                                                                </Text>
                                                            )}
                                                    </Stack>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid mt={4}>
                                    {isLoading ? (
                                        Array(4)
                                            .fill(1)
                                            .map((_, index) => (
                                                <Skeleton
                                                    key={index}
                                                    flex={4}
                                                    h={8}
                                                    mt={5}
                                                />
                                            ))
                                    ) : (
                                        // Disclaimer
                                        <Stack
                                            spacing="6"
                                            sx={{
                                                margin: '0 auto',
                                            }}>
                                            {(selectedSplitOption ||
                                                selectedJoinOption) && (
                                                <>
                                                    <Text fontSize="md">
                                                        Please note:{' '}
                                                    </Text>
                                                    <UnorderedList>
                                                        <ListItem fontSize="sm">
                                                            {selectedSplitOption
                                                                ? 'Splitting'
                                                                : 'Joining'}{' '}
                                                            a number block
                                                            requires a valid
                                                            number block rate
                                                            for the selected
                                                            block size.
                                                        </ListItem>
                                                        <ListItem fontSize="sm">
                                                            {selectedSplitOption
                                                                ? 'Splitting'
                                                                : 'Joining'}{' '}
                                                            a number block may
                                                            incur charges/fees.
                                                        </ListItem>
                                                        <ListItem fontSize="sm">
                                                            You are responsible
                                                            for the changes you
                                                            are about to make
                                                            through{' '}
                                                            {selectedSplitOption
                                                                ? 'splitting'
                                                                : 'joining'}{' '}
                                                            number block.
                                                        </ListItem>
                                                    </UnorderedList>
                                                </>
                                            )}
                                        </Stack>
                                    )}
                                </Grid>
                            </>
                        )}
                        {submitState === 'processing' && (
                            <SplitJoinStatus
                                totalCount={totalCount}
                                successCount={successCount}
                                failedCount={failedCount}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {submitState === 'idle' && (
                            <Stack
                                spacing="6"
                                px={{ base: '4', md: '6' }}
                                pt={{ base: '5', md: '6' }}
                                pb="2px"
                                sx={{
                                    justifyContent: 'center',
                                    width: '170px',
                                    margin: '0 auto',
                                }}
                                direction={{ base: 'column', md: 'row' }}>
                                <Box
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}>
                                    <Button
                                        data-walkthroughid={ufCloseButton}
                                        variant="outline"
                                        type="submit"
                                        onClick={() => closeModal()}>
                                        Close
                                    </Button>
                                </Box>
                                <Box
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: 10,
                                        marginLeft: 10,
                                        marginBottom: 10,
                                    }}>
                                    <Button
                                        data-walkthroughid={ufSubmitButton}
                                        type="submit"
                                        className="disabled:cursor-not-allowed"
                                        disabled={
                                            isEmpty(selectedJoinOption) &&
                                            isEmpty(selectedSplitOption)
                                        }
                                        onClick={(e) => handleSubmit(e)}
                                        minW={['100%', '80px']}>
                                        Submit
                                    </Button>
                                </Box>
                            </Stack>
                        )}

                        {submitState === 'processing' && (
                            <Stack
                                spacing="6"
                                px={{ base: '4', md: '6' }}
                                pt={{ base: '5', md: '6' }}
                                pb="2px"
                                sx={{
                                    justifyContent: 'center',
                                    width: '170px',
                                    margin: '0 auto',
                                }}
                                direction={{ base: 'column', md: 'row' }}>
                                <Button
                                    data-walkthroughid={ufBackButton}
                                    variant="secondary"
                                    type="submit"
                                    disabled={
                                        !(
                                            successCount + failedCount ===
                                            totalCount
                                        )
                                    }
                                    minW={['100%', '160px']}
                                    onClick={handleClose}>
                                    Back
                                </Button>
                            </Stack>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    );
};

export default SplitJoinBlock;
