export default function useNormaliseQueryStrings() {
    /**
     * Pass in params to normalise, or use the window's by default.
     */
    function normalise(params = window.location.search) {
        const paramsToNormalise = new URLSearchParams(params);
        const newParams = new URLSearchParams();
        for (const [name, value] of paramsToNormalise) {
            newParams.append(name.toLowerCase(), value);
        }
        return newParams;
    }

    return normalise;
}
