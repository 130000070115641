import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import config from '@/config.json';
import { axios } from '@/services/axios';
import {
    ChakraProvider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useDeepMerge } from 'hooks/useDeepMerge';
import walkthroughIds from './walkthroughIds';

import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import APIUsers from './apiUsers/index';
import Permissions from '../permissions/Index';
import AdminAccounts from './adminUsers/Index';

// Component for Table of the 'Admins' Page in the sidebar
const Admins = () => {
    const dispatch = useDispatch();
    const { theme } = useDeepMerge();
    const location = useLocation();
    const { state } = location;

    const [tabIndex, setTabIndex] = useState(
        state?.index != null ? state.index : 0,
    );

    const { darkMode } = useSelector((state) => state.settings);

    useEffect(() => {
        if (state?.index != null) {
            setTabIndex(state.index);
            return;
        }
        // Flag myself when hitting the admin page
        // make note of whether MFA is enabled,
        // so when we get a 200 from PUTing to admin,
        // we know to logout this admin user or not
        axios.get('/adminuser/me').then((response) => {
            dispatch({
                type: 'SET_IS_CURRENT_USER_MFA_ENABLED',
                payload: response.data.mfaEnabled,
            });
        });
    }, [dispatch, state]);

    const getTitle = (tabIndex) => {
        switch (tabIndex) {
            case 1:
                return config.apiUsers.tableTitle;
            case 2:
                return config.permissions.tableTitle;
            default:
                return config.adminUsers.tableTitle;
        }
    };

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <h1 className="mb-2 text-[2.5rem] font-medium">
                {getTitle(tabIndex)}
            </h1>

            <Tabs
                index={tabIndex}
                isLazy
                onChange={(index) => {
                    dispatch({
                        type: 'RESET_MODAL',
                    });
                    dispatch({
                        type: 'RESET_TABLE',
                    });
                    setTabIndex(index);
                }}>
                <TabList borderBottom={'none'} mb={2}>
                    {config.adminUsers.tabsList.map((tab, index) => (
                        <Tab
                            data-walkthroughid={
                                walkthroughIds.ufTabButton + tab
                            }
                            key={index}
                            onMouseDown={(e) =>
                                e.button === 2 && e.preventDefault()
                            }
                            sx={{
                                ...customTabStylesCombineTheme,
                                color: darkMode
                                    ? 'rgba(255, 255, 255)'
                                    : 'inherit',
                            }}
                            _selected={{
                                ...selectedTabStylesCombineTheme,
                            }}>
                            {tab}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    <TabPanel padding={0}>
                        {tabIndex == 0 && <AdminAccounts />}
                    </TabPanel>
                    <TabPanel padding={0}>
                        {tabIndex == 1 && <APIUsers />}
                    </TabPanel>
                    <TabPanel padding={0}>
                        {tabIndex == 2 && <Permissions />}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </ChakraProvider>
    );
};

export default Admins;
