import React from 'react';
import Alert from '@material-ui/lab/Alert';

/*
 * Component to show a sucess message
 */
const Succeed = (props) => {
    return <Alert style={{marginBottom: 10}}>{props.message}</Alert>;
};

export default Succeed;
