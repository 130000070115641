/* eslint-disable no-unused-vars */
//source code from https://github.com/mbrn/material-table/blob/master/src/components/m-table-filter-row.js

import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem, OutlinedInput } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { MTableFilterRow } from 'material-table';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class CustomMTableFilterRow extends MTableFilterRow {
    LookupFilter = ({ columnDef }) => {
        const [selectedFilter, setSelectedFilter] = React.useState(
            columnDef.tableData.filterValue || [],
        );

        React.useEffect(() => {
            setSelectedFilter(columnDef.tableData.filterValue || []);
        }, [columnDef.tableData.filterValue]);

        const { darkMode } = useSelector((state) => state.settings);

        return (
            <FormControl
                style={{ width: '100%' }}
                sx={{
                    '& .MuiInputBase-root': {
                        height: '40px',
                    },
                }}>
                <InputLabel
                    htmlFor={
                        'select-multiple-checkbox' + columnDef.tableData.id
                    }
                    style={{ marginTop: -16 }}>
                    {this.getLocalizedFilterPlaceHolder(columnDef)}
                </InputLabel>
                <Select
                    sx={{
                        color: darkMode ? '#fff' : '#000',
                    }}
                    multiple
                    value={selectedFilter}
                    onClose={() => {
                        if (columnDef.filterOnItemSelect !== true)
                            this.props.onFilterChanged(
                                columnDef.tableData.id,
                                selectedFilter,
                            );
                    }}
                    onChange={(event) => {
                        setSelectedFilter(event.target.value);
                        if (columnDef.filterOnItemSelect === true)
                            this.props.onFilterChanged(
                                columnDef.tableData.id,
                                event.target.value,
                            );
                    }}
                    input={
                        <OutlinedInput
                            id={
                                'select-multiple-checkbox' +
                                columnDef.tableData.id
                            }
                        />
                    }
                    renderValue={(selecteds) => {
                        const result = selecteds.map(
                            (selected) => columnDef.lookup[selected],
                        );
                        if (result.every((v) => typeof v === 'string')) {
                            return result.join(', ');
                        }
                        return result;
                    }}
                    MenuProps={MenuProps}
                    style={{ marginTop: 0 }}>
                    {Object.keys(columnDef.lookup).map((key) => (
                        <MenuItem key={key} value={key}>
                            <Checkbox
                                checked={
                                    selectedFilter.indexOf(key.toString()) > -1
                                }
                            />
                            {columnDef.lookupDescription ? (
                                <>
                                    {columnDef.lookup[key]}
                                    <div style={{ marginLeft: 10 }}>
                                        {columnDef.lookupDescription &&
                                            columnDef.lookupDescription[key]}
                                    </div>
                                </>
                            ) : (
                                <ListItemText primary={columnDef.lookup[key]} />
                            )}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };
}

export default CustomMTableFilterRow;
