import {
    Box,
    FormErrorMessage,
    FormHelperText,
    IconButton,
    InputGroup,
    InputRightElement,
    Tag,
    Tooltip,
} from '@chakra-ui/react';
import {
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from 'components/v4';
import ChakraButton from 'components/v4/Button';
import { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import walkthroughIds from '../../../accounts/admins/walkthroughIds';

export default function TenantLookup(props) {
    const { isOpenLookup, onCloseLookup, setTenantError, addTenants, tenants } =
        props;

    const { darkMode } = useSelector((state) => state.settings);

    const initialLookupState = {
        lookupValue: '',
        tenantValue: '',
        isError: false,
        message: '',
        isLoading: false,
    };
    const [lookupState, setLookupState] = useState(initialLookupState);

    const {
        ufLookupSearchBtn,
        ufEmailLookupInput,
        ufLookupCloseBtn,
        ufLookupAddBtn,
    } = walkthroughIds.adminSSO;

    const handleTenantLookup = async () => {
        try {
            setLookupState((prevState) => ({
                ...prevState,
                isLoading: true,
            }));

            if (lookupState.lookupValue) {
                const response = await fetch(
                    'https://login.microsoftonline.com/' +
                        lookupState.lookupValue +
                        '/.well-known/openid-configuration',
                );
                const result = await response.json();

                if (response.ok) {
                    const tokenEndpoint = result.token_endpoint;
                    const match = tokenEndpoint.match(/\/([^/]+)\/oauth2/);
                    if (match) {
                        setLookupState((prevState) => ({
                            ...prevState,
                            tenantValue: match[1],
                        }));
                    } else {
                        setLookupState((prevState) => ({
                            ...prevState,
                            isError: true,
                            message: 'Unable to find your Tenant ID.',
                        }));
                    }
                } else {
                    setLookupState((prevState) => ({
                        ...prevState,
                        isError: true,
                        message: 'Tenant not found.',
                    }));
                }
            } else {
                setLookupState((prevState) => ({
                    ...prevState,
                    isError: true,
                    message: 'Field is required.',
                }));
            }
        } finally {
            setLookupState((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        }
    };

    const handleTenantAdd = () => {
        setTenantError({ isError: false, message: '', duplicates: '' });

        if (tenants.includes(lookupState.tenantValue)) {
            setTenantError((prevState) => ({
                ...prevState,
                isError: true,
                duplicates: `Tenant ID already exists ${lookupState.tenantValue}`,
            }));
            handleCloseLookup();
            return;
        }

        addTenants([lookupState.tenantValue]);
        handleCloseLookup();
    };

    const handleCloseLookup = () => {
        onCloseLookup();
        setLookupState(initialLookupState);
    };

    return (
        <Modal isOpen={isOpenLookup} autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <FormControl
                        isRequired
                        mb={2}
                        isInvalid={lookupState.isError}>
                        <FormLabel>Email Domain</FormLabel>
                        <InputGroup>
                            <Input
                                data-walkthroughid={ufEmailLookupInput}
                                value={lookupState.lookupValue}
                                onChange={(event) =>
                                    setLookupState((prevState) => ({
                                        ...prevState,
                                        tenantValue: '',
                                        isError: false,
                                        message: '',
                                        lookupValue: event.target.value.trim(),
                                    }))
                                }
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleTenantLookup();
                                    }
                                }}
                                placeholder="Enter your Email Domain"
                            />
                            <InputRightElement>
                                <Tooltip
                                    label={'Search'}
                                    hasArrow
                                    placement="bottom">
                                    <IconButton
                                        data-walkthroughid={ufLookupSearchBtn}
                                        isLoading={lookupState.isLoading}
                                        color={darkMode ? 'white' : 'inherit'}
                                        bg={'none'}
                                        _hover={{
                                            bg: 'none',
                                            color: darkMode
                                                ? 'white'
                                                : 'inherit',
                                        }}
                                        onClick={() => handleTenantLookup()}
                                        className="btn"
                                        icon={<SearchIcon />}
                                    />
                                </Tooltip>
                            </InputRightElement>
                        </InputGroup>

                        {!lookupState.isError && !lookupState.tenantValue && (
                            <FormHelperText h={'24px'}>
                                eg. contoso.onmicrosoft.com
                            </FormHelperText>
                        )}
                        {lookupState.isError && (
                            <FormErrorMessage h={'24px'}>
                                {lookupState.message}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    {lookupState.tenantValue && (
                        <Tag
                            mt={5}
                            justifyItems={'center'}
                            background={'gray.300'}
                            display={'flex'}>
                            Your Tenant ID is {lookupState.tenantValue}
                        </Tag>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Box
                        style={{
                            border: 'none',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}>
                        <ChakraButton
                            data-walkthroughid={ufLookupCloseBtn}
                            variant="secondary"
                            className="btn btn-secondary btn-sm disabled:cursor-not-allowed"
                            onClick={() => handleCloseLookup()}>
                            Close
                        </ChakraButton>
                    </Box>
                    <Box
                        style={{
                            border: 'none',
                            textAlign: 'end',
                            justifyContent: 'end',
                            marginLeft: 10,
                        }}>
                        <ChakraButton
                            data-walkthroughid={ufLookupAddBtn}
                            isDisabled={!lookupState.tenantValue}
                            type="submit"
                            className="btn btn-primary btn-sm disabled:cursor-not-allowed"
                            onClick={() => handleTenantAdd()}>
                            Add
                        </ChakraButton>
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
