import { useState } from 'react';
import {
    FormControl,
    Stack,
    FormLabel,
    Switch,
    Text,
    Tooltip,
    Icon,
} from '@chakra-ui/react';
import { Button } from '@/components/v4';
import {
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';
import { HiOutlineKey } from 'react-icons/hi';
import { FiTrash } from 'react-icons/fi';

const AdminUsersManageAccess = ({
    adminUser,
    handlFormSwitchChange,
    darkMode,
    ssoEnforced,
    isLoadingResetPassword,
    succeed,
    resetPassword,
    onOpen,
    adminId,
}) => {
    const [isActiveHovered, setActiveIsHovered] = useState(false);
    const [isPasswordHovered, setPasswordIsHovered] = useState(false);
    const [isDeleteHovered, setDeleteIsHovered] = useState(false);
    const isDarkmodeColor = darkMode ? '#FFFFFF' : '#000000';

    return adminId ? (
        <FormControl sx={{ margin: '0 !important' }}>
            <Stack direction={'row'} height={'35px'} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>
                    Manage Admin User Access
                </FormLabel>
                <Stack direction={'row'} spacing={2} paddingTop={4}>
                    <Stack
                        direction={'row'}
                        sx={{
                            paddingRight: 8,
                            paddingTop: 1,
                            paddingBottom: 6,
                            paddingLeft: '64px',
                        }}
                        onMouseEnter={() => setActiveIsHovered(true)}
                        onMouseLeave={() => setActiveIsHovered(false)}>
                        <Stack direction={'row'} spacing={0}>
                            <Switch
                                paddingLeft={1}
                                paddingRight={'7px'}
                                name="isActive"
                                onChange={handlFormSwitchChange}
                                isChecked={adminUser?.isActive}
                                checked={adminUser?.isActive}
                                walkthroughIds={
                                    walkthroughIds.adminsGeneral.ufIsActive
                                }
                            />
                            <Text
                                fontSize="12px"
                                fontWeight={500}
                                color={
                                    !adminUser?.isActive
                                        ? '#737373'
                                        : isDarkmodeColor
                                }>
                                Active
                            </Text>
                        </Stack>
                        {isActiveHovered && (
                            <Tooltip
                                hasArrow
                                label="Activates/Deactivates Admin User. Inactive users are unable to login"
                                aria-label="inputGroupSelect">
                                <Icon
                                    color={darkMode ? 'white' : undefined}
                                    position="relative"
                                    bottom="2px"
                                    marginLeft={'-14px !important'}
                                    left="20px"
                                />
                            </Tooltip>
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        fontSize={14}
                        sx={{ paddingRight: 8, paddingBottom: 6 }}
                        onMouseEnter={() => setPasswordIsHovered(true)}
                        onMouseLeave={() => setPasswordIsHovered(false)}>
                        <Button
                            data-walkthroughid={
                                walkthroughIds.adminsGeneral.ufResetPassword
                            }
                            color={isDarkmodeColor}
                            leftIcon={
                                <HiOutlineKey
                                    fontSize={24}
                                    color={isDarkmodeColor}
                                    style={{ transform: 'scaleX(-1)' }}
                                />
                            }
                            variant="link"
                            disabled={
                                ssoEnforced || isLoadingResetPassword || succeed
                            }
                            loading={isLoadingResetPassword}
                            fontSize="12px"
                            paddingTop="6px"
                            onClick={resetPassword}>
                            {succeed ? 'Reset Link Sent!' : 'Reset Password'}
                        </Button>
                        {isPasswordHovered && (
                            <Tooltip
                                hasArrow
                                label="Sends password reset link to Admin User"
                                aria-label="inputGroupSelect">
                                <Icon
                                    color={darkMode ? 'white' : undefined}
                                    position="relative"
                                    top="2px"
                                    marginLeft={'-14px !important'}
                                    left="22px"
                                />
                            </Tooltip>
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        onMouseEnter={() => setDeleteIsHovered(true)}
                        onMouseLeave={() => setDeleteIsHovered(false)}
                        sx={{ paddingRight: 10, paddingBottom: 6 }}>
                        <Button
                            leftIcon={
                                <FiTrash
                                    fontSize={24}
                                    color={isDarkmodeColor}
                                />
                            }
                            variant="link"
                            fontSize="12px"
                            paddingTop="6px"
                            data-walkthroughid={
                                walkthroughIds.adminsGeneral.ufAdminDelete
                            }
                            colorScheme={isDarkmodeColor}
                            onClick={onOpen}>
                            Delete
                        </Button>
                        {isDeleteHovered && (
                            <Tooltip
                                hasArrow
                                label="Deletes Admin User"
                                aria-label="inputGroupSelect">
                                <Icon
                                    color={darkMode ? 'white' : undefined}
                                    position="relative"
                                    top="2px"
                                    marginLeft={'-14px !important'}
                                    left="22px"
                                />
                            </Tooltip>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </FormControl>
    ) : null;
};

export default AdminUsersManageAccess;
