import {
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    ModalFooter,
} from '@chakra-ui/react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import { useState } from 'react';

export default function APIKeyModal({
    apiKey,
    setApiKey,
    isOpen,
    onClose,
    handleBack,
}) {
    const [copyButton, setCopyButton] = useState({
        text: 'Copy',
        isDisabled: false,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent minW={'30%'}>
                <ModalHeader>Copy your API key</ModalHeader>
                <ModalBody>
                    <Alert status="warning" mb={2}>
                        <AlertIcon />
                        <AlertDescription>
                            Make sure to copy this API key now. You won't see it
                            again.
                        </AlertDescription>
                    </Alert>
                    <FormControl isReadOnly>
                        <FormLabel>API key</FormLabel>

                        <InputGroup>
                            <Input
                                pr="4.5rem"
                                name="apiKey"
                                isReadOnly
                                value={apiKey}
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    isDisabled={copyButton.isDisabled}
                                    onClick={() => {
                                        navigator.clipboard.writeText(apiKey);
                                        setCopyButton({
                                            text: 'Copied',
                                            isDisabled: true,
                                        });
                                        setTimeout(() => {
                                            setCopyButton({
                                                text: 'Copy',
                                                isDisabled: false,
                                            });
                                        }, 2000);
                                    }}>
                                    {copyButton.text}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        className="btn btn-primary btn-sm disabled:cursor-not-allowed"
                        onClick={() => {
                            onClose();
                            setApiKey('');
                            handleBack();
                        }}>
                        Done
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
