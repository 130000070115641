import { Box, Flex } from '@chakra-ui/react';

import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@/components/v4';
import ModalCloseButton from '@/components/v4/ModalCloseButton';
import { axios } from '@/services/axios';
import { useSingleToast } from 'hooks/useSingleToast';
import { useState } from 'react';

function DeallocateCompaniesVgModal({ isOpen, onClose, onDeleteOpen, vg }) {
    const { errorToast } = useSingleToast();

    const [isDeallocating, setIsDeallocating] = useState(false);

    const deallocateFromCompanies = async () => {
        setIsDeallocating(true);
        try {
            await axios.post(
                `/visibilitygroup/${vg.id}/deallocatefromcompanies`,
            );
            onClose();
            onDeleteOpen();
        } catch (e) {
            errorToast(
                e?.response?.data?.message ||
                    'Unable to deallocate companies from visibility group.',
            );
        } finally {
            setIsDeallocating(false);
        }
    };
    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent
                as="form"
                data-walkthroughid={
                    'visibilityGroupModal.deleteconfirmationModal'
                }>
                <ModalHeader>Remove Visibility Group</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justifyContent="space-between">
                        <Flex align="center">
                            <Text mx="2">
                                Are you sure you would like to remove this
                                Visibility Group from all associated companies?
                                <Box as="br" />
                                <Box mt="10px">
                                    This will make all companies using this
                                    Visibility Group visible to all admins.
                                </Box>
                            </Text>
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        colorScheme="red"
                        minW="100px"
                        isLoading={isDeallocating}
                        onClick={(e) => {
                            e.preventDefault();
                            deallocateFromCompanies();
                        }}>
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default DeallocateCompaniesVgModal;
