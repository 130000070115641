import { useSelector } from 'react-redux';
import {
    SimpleGrid,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    InputRightAddon,
} from '@chakra-ui/react';
import { Select as ChakraSelect } from 'chakra-react-select';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { Input, InputGroup } from '@/components/v4';

import walkthroughIds from '../../users/walkthroughIds';
import {
    inputGroupStyle,
    inputBorderStyle,
    inputFocusStyle,
    inputHoverStyle,
} from './constants';
export default function BuilderPageForm({
    initialDomain,
    builder,
    errors,
    timezones,
    languages,
    templates,
    handleInputChange,
    handleSelectTimezone,
    handleSelectLanguage,
    handleSelectTemplate,
}) {
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    const { autoAttendant } = walkthroughIds;
    return (
        <SimpleGrid columns={2} spacing={4} mb={6}>
            <FormControl
                isRequired
                isInvalid={Boolean(errors.name)}
                sx={inputGroupStyle}>
                <FormLabel>Account Name</FormLabel>
                <InputGroup className="group">
                    <Input
                        type="text"
                        name="name"
                        value={builder.name}
                        placeholder="abc123"
                        onChange={handleInputChange}
                        data-walkthroughid={autoAttendant.ufAccountName}
                        overflow="hidden"
                        sx={inputBorderStyle}
                        _focus={inputFocusStyle}
                        _hover={inputHoverStyle}
                    />
                    <InputRightAddon
                        backgroundColor={darkMode ? 'dark.bgDark' : 'white'}
                        borderColor={
                            darkMode ? 'dark.tcap-borderGray' : 'inherit'
                        }>
                        @{initialDomain}
                    </InputRightAddon>
                </InputGroup>
                {errors.name && (
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
                {!builder.name && (
                    <FormHelperText>Name of the Auto Attendant.</FormHelperText>
                )}
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Display Name</FormLabel>
                <Input
                    type="text"
                    name="displayName"
                    value={builder.displayName}
                    onChange={handleInputChange}
                    data-walkthroughid={autoAttendant.ufDisplayName}
                />
                {!builder.displayName && (
                    <FormHelperText>
                        Display Name of the Auto Attendant.
                    </FormHelperText>
                )}
            </FormControl>

            <FormControl
                isRequired
                data-walkthroughid={autoAttendant.ufTimezone}>
                <FormLabel>Timezone</FormLabel>
                <ChakraSelect
                    name="timezone"
                    options={timezones}
                    value={timezones.find(
                        (item) => item.id === builder.timezone,
                    )}
                    onChange={handleSelectTimezone}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    placeholder="- Not Selected -"
                    chakraStyles={chakraStyles}
                    selectedOptionColor="brand"
                />
                {!builder.timezone && (
                    <FormHelperText>
                        Timezone for creating Auto Attendant.
                    </FormHelperText>
                )}
            </FormControl>

            <FormControl
                isRequired
                data-walkthroughId={autoAttendant.ufLanguage}>
                <FormLabel>Language</FormLabel>
                <ChakraSelect
                    name="language"
                    options={languages}
                    value={languages.find(
                        (item) => item.id === builder.language,
                    )}
                    onChange={handleSelectLanguage}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    placeholder="- Not Selected -"
                    chakraStyles={chakraStyles}
                    selectedOptionColor="brand"
                />
                {!builder.language && (
                    <FormHelperText>
                        Language for creating Auto Attendant.
                    </FormHelperText>
                )}
            </FormControl>
            <FormControl data-walkthroughid={autoAttendant.ufTemplate}>
                <FormLabel>Template</FormLabel>
                <ChakraSelect
                    name="template"
                    options={templates}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    placeholder="- Not Selected -"
                    onChange={handleSelectTemplate}
                    chakraStyles={chakraStyles}
                    selectedOptionColor="brand"
                />
            </FormControl>
        </SimpleGrid>
    );
}
