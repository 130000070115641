import { useState } from 'react';

export function useEmail(setProviderState) {
    const emailDomainPattern = /^[a-zA-Z0-9.-]+$/;
    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [emailError, setEmailError] = useState({
        isError: false,
        message: '',
        duplicates: '',
    });

    //#region Email Domains
    const addEmails = (emailsToAdd) => {
        const newEmails = [...emails, ...emailsToAdd];

        setEmails(newEmails);
        setEmailInput('');
    };

    const removeEmail = (email) => {
        const index = emails.findIndex((e) => e === email);
        if (index !== -1) {
            const newEmails = [...emails];
            newEmails.splice(index, 1);
            setEmails(newEmails);
            if (newEmails.length == 0) {
                setProviderState((prevState) => ({
                    ...prevState,
                    isBoth: '0',
                }));
            }
        }
    };

    const handleEmailChange = (e) => {
        setEmailError({ isError: false, message: '', duplicates: '' });
        setEmailInput(e.target.value.trim());
    };

    const handleEmailKeyDown = (e) => {
        if (['Enter', 'Tab', ','].includes(e.key)) {
            setEmailError({ isError: false, message: '', duplicates: '' });
            e.preventDefault();

            // check for duplicates
            if (emails.includes(emailInput)) {
                setEmailError((prevState) => ({
                    ...prevState,
                    isError: true,
                    duplicates: `Email domain already exists ${emailInput}.`,
                }));
                return;
            }
            // check for format
            if (!emailDomainPattern.test(emailInput)) {
                setEmailError((prevState) => ({
                    ...prevState,
                    isError: true,
                    message: `Invalid email domain ${emailInput}.`,
                }));
                return;
            }
            // add value to list
            addEmails([emailInput]);
        }
    };

    const handleEmailPaste = (e) => {
        e.preventDefault();
        setEmailError({ isError: false, message: '', duplicates: '' });

        const pastedData = e.clipboardData.getData('text');
        // remove return and new line, split by comma and filter by empty string if exist
        const pastedEmails = pastedData
            .trim()
            .replace(/\s+/g, '')
            .split(',')
            .filter((email) => email !== '');

        let invalidIds = [];
        let duplicateIds = [];
        let allowedEmails = [];
        pastedEmails.map((email) => {
            // email already exist, add to duplicate error message
            if (emails.includes(email)) {
                duplicateIds.push(email);
                return;
            }
            // email does not match format, add to invalid ids error message
            if (!emailDomainPattern.test(email)) {
                invalidIds.push(email);
                return;
            }
            allowedEmails.push(email);
        });

        addEmails(allowedEmails);

        let duplicateString = '';
        duplicateIds?.map((dup) => {
            // if last index, add it with a fullstop, else comma
            if (duplicateIds.indexOf(dup) === duplicateIds.length - 1) {
                duplicateString += dup + '.';
            } else {
                duplicateString += dup + ', ';
            }
        });
        if (duplicateString) {
            setEmailError((prevState) => ({
                ...prevState,
                isError: true,
                duplicates: 'Email domain already exists ' + duplicateString,
            }));
        }

        let invalidString = '';
        invalidIds?.map((inv) => {
            // if last index, add it with a fullstop, else comma
            if (invalidIds.indexOf(inv) === invalidIds.length - 1) {
                invalidString += inv + '.';
            } else {
                invalidString += inv + ', ';
            }
        });

        if (invalidString) {
            setEmailError((prevState) => ({
                ...prevState,
                isError: true,
                message: 'Invalid email domain ' + invalidString,
            }));
        }
    };

    const handleEmailCloseClick = (email) => {
        removeEmail(email);
    };
    //#endregion

    return {
        emails,
        setEmails,
        emailInput,
        emailError,
        setEmailError,
        handleEmailChange,
        handleEmailKeyDown,
        handleEmailPaste,
        handleEmailCloseClick,
    };
}
