//external imports
import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {IconButton} from '@material-ui/core';

// Icon button that when you hover you get a tooltip
const CustomIconButton = ({
    tooltip,
    Icon,
    placement,
    onClick,
    style,
    className,
}) => {
    return (
        <>
            <OverlayTrigger
                placement={placement || 'auto'}
                overlay={<Tooltip>{tooltip}</Tooltip>}>
                {onClick ? (
                    <IconButton
                        className={className}
                        style={style}
                        size="small"
                        onClick={onClick}>
                        {Icon}
                    </IconButton>
                ) : (
                    <>{Icon}</>
                )}
            </OverlayTrigger>
        </>
    );
};

export default CustomIconButton;
