import { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RESET } from '@/store/constants';
import { initialState } from './constants';

export const TpmContext = createContext();

export const TpmProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    const [addressAction, setAddressAction] = useState('1');

    const [telephoneNumbers, setTelephoneNumbers] = useState([
        { number: '', status: 'IDLE' },
    ]);

    const [deactivateNumbers, setDeactivateNumbers] = useState([
        { number: '', status: 'IDLE' },
    ]);

    const value = useMemo(
        () => ({
            tpmNumber: [state, setState],
            tpmAddress: [addressAction, setAddressAction],
            tpmActivatedNumber: [telephoneNumbers, setTelephoneNumbers],
            tpmDeactivateNumber: [deactivateNumbers, setDeactivateNumbers],
        }),
        [
            state,
            setState,
            addressAction,
            setAddressAction,
            telephoneNumbers,
            setTelephoneNumbers,
            deactivateNumbers,
            setDeactivateNumbers,
        ],
    );

    const dispatch = useDispatch();
    const { number: mpn, lastUpdate } = useSelector(
        (state) => state.service.tpm,
    );

    useEffect(() => {
        const currentTime = Date.now();

        const EXPIRED_TIME = 30 * 60 * 1000;

        if (mpn && lastUpdate) {
            const timeElapsed = currentTime - lastUpdate;

            if (timeElapsed > EXPIRED_TIME) {
                dispatch({ type: RESET });
            }
        }
    }, [mpn, lastUpdate, dispatch]);

    return <TpmContext.Provider value={value}>{children}</TpmContext.Provider>;
};
