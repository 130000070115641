import React, { useState, useMemo, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
// import {Row, Col, Button} from 'react-bootstrap';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    ChakraProvider,
    Heading,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
// import {Grid} from '@material-ui/core';
import { ResponsiveBarCanvas } from '@nivo/bar';

import { LoadingFieldDropdown } from 'components';

import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import Grid from 'components/v4/Grid';
import Button from 'components/v4/Button';
import Select from 'components/v4/Select';

import walkthroughIds from 'pages/dashboards/walkthroughIds';
import { chakraDatepicker, chakraDatepickerDarkMode } from '@/constants';
import chakratheme, {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';

// theme from material ui
const theme = {
    textColor: '#ffffff',
    fontSize: 11,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 3,
            },
        },
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 1,
        },
    },
};

/*
 * Component for the Call Stats page of the dashboard
 */
const CallDashboard = () => {
    const today = new Date();
    const dispatch = useDispatch();
    const [from, setFrom] = useState(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            .toISOString()
            .substring(0, 10),
    );
    const [to, setTo] = useState(new Date().toISOString().substring(0, 10));
    const [mode, setMode] = useState('Calls');
    const [grouping, setGrouping] = useState('Daily');
    const [selectedDates, setSelectedDates] = useState([
        new Date(),
        new Date(),
    ]);

    const {
        datePicker,
        searchButton,
        groupingSelect,
        metricsSelect,
        callStatsChart,
    } = walkthroughIds.dashboardsCallStats;

    // table data from state
    const { state: tableState, loading: tableLoading } = useSelector(
        (state) => {
            return { ...state.table };
        },
    );

    // settings data from state
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });

    // navigation data from state
    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // colors for the graph
    const colors = {
        'Inbound (calls)': '#E0F9B5',
        'Outbound (calls)': '#A5DEE5',
        'Inbound (mins)': '#E0F9B5',
        'Outbound (mins)': '#A5DEE5',
    };

    // function to get the color for each bar
    const getColor = (bar) => colors[bar.id];

    // useMemo to get the data from the table as its a large data set
    const data = useMemo(() => {
        const init = [
            {
                'Outbound (mins)': 0,
                'Inbound (mins)': 0,
                date: '',
                'Outbound (calls)': 0,
                'Inbound (calls)': 0,
            },
        ];
        if (tableState?.data?.length < 1) {
            return init;
        }

        return (
            tableState?.data
                ?.reduce((result, current) => {
                    const currentDate =
                        grouping === 'Daily'
                            ? current.dateTime?.substring(0, 10)
                            : current.dateTime?.substring(0, 7);
                    const index = result.findIndex((v) => {
                        return v.date === currentDate;
                    });
                    const currentDuration =
                        Number(current.duration?.substring(0, 2)) * 3600 +
                        Number(current.duration?.substring(3, 5)) * 60 +
                        Number(current.duration?.substring(6)); //seconds
                    const fieldDuration = `${current.direction} (mins)`;
                    const fieldNum = `${current.direction} (calls)`;

                    if (index === -1) {
                        result.push({
                            'Inbound (mins)': 0,
                            'Outbound (mins)': 0,
                            'Inbound (calls)': 0,
                            'Outbound (calls)': 0,
                            InboundColor: 'hsl(216, 70%, 50%)',
                            OutboundColor: 'hsl(216, 70%, 50%)',

                            date: currentDate,
                            [fieldDuration]: currentDuration,
                            [fieldNum]: 1,
                        });
                        return result;
                    } else {
                        result[index] = {
                            ...result[index],
                            [fieldDuration]:
                                result[index][fieldDuration] + currentDuration,
                            [fieldNum]: result[index][fieldNum] + 1,
                        };
                        return result;
                    }
                }, [])
                .map((v) => ({
                    ...v,
                    'Inbound (mins)': Number(v['Inbound (mins)'] / 60).toFixed(
                        2,
                    ),
                    'Outbound (mins)': Number(
                        v['Outbound (mins)'] / 60,
                    ).toFixed(2), //mins
                }))
                .sort((left, right) => {
                    return new Date(left.date) - new Date(right.date);
                }) ?? init
        );
    }, [tableState?.data, grouping]);

    useEffect(() => {
        if (selectedDates.length > 1) {
            setFrom(new Date(selectedDates[0]).toISOString().substring(0, 10));
            setTo(new Date(selectedDates[1]).toISOString().substring(0, 10));
        }
    }, [selectedDates]);

    // useEffect to refresh the dashboard
    useEffect(() => {
        if (!currentCompany) {
            return;
        } else if (tableLoading) {
            dispatch({
                type: 'INIT_WEB_WORKER_STATE',
                payload: {
                    fetchURL: `/calldetailrecords/${currentCompany}/${from}/${to}`,
                    attribute: 'callDetailRecords',
                },
            });
        }
    }, [currentCompany, tableLoading]);

    // onClick to start the search for call records
    const onClick = () => {
        dispatch({
            type: 'INIT_WEB_WORKER_STATE',
            payload: {
                fetchURL: `/calldetailrecords/${currentCompany}/${from}/${to}`,
                attribute: 'callDetailRecords',
            },
        });
    };

    // if no company is selected, return a alert
    if (!currentCompany) {
        return (
            <Alert severity="info">
                Please select a partner or a company to view dashboard.
            </Alert>
        );
    }

    // presentational aspect of the dashboard
    return (
        <>
            <ChakraProvider theme={chakratheme}>
                <Heading
                    as="h5"
                    fontSize="xl"
                    fontWeight="medium"
                    className="mb-2 leading-5">
                    Calls Statistics
                </Heading>
                <Grid columns={2} gap={4} className="w-[600px]">
                    <Box
                        className="h-10"
                        sx={
                            darkMode
                                ? chakraDatepickerDarkMode
                                : chakraDatepicker
                        }
                        data-walkthroughid={datePicker}>
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                            configs={{
                                dateFormat: ' ccc, dd MMM, yyyy',
                            }}
                            propsConfigs={
                                darkMode
                                    ? {
                                          ...ChakraDatePickerDarkModeStyles,
                                          inputProps: {
                                              size: 'sm',
                                              borderRadius: '4px',
                                              width: '100%',
                                              height: '40px',
                                          },
                                      }
                                    : {
                                          ...ChakraDatePickerStyles,
                                          inputProps: {
                                              size: 'sm',
                                              borderRadius: '4px',
                                              width: '100%',
                                              height: '40px',
                                          },
                                      }
                            }
                        />
                    </Box>

                    <Box>
                        <Button
                            onClick={onClick}
                            data-walkthroughid={searchButton}>
                            Search
                        </Button>
                    </Box>
                    <Box>
                        <FormControl data-walkthroughid={groupingSelect}>
                            <FormLabel>Grouping</FormLabel>
                            <Select
                                options={[
                                    { label: 'Daily', value: 'Daily' },
                                    { label: 'Monthly', value: 'Monthly' },
                                ]}
                                onChange={(v) => {
                                    setGrouping(v.target.value);
                                }}
                                value={grouping}
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl data-walkthroughid={metricsSelect}>
                            <FormLabel>Metrics</FormLabel>
                            <Select
                                key={mode}
                                options={[
                                    { label: 'Calls', value: 'Calls' },
                                    { label: 'Duration', value: 'Duration' },
                                ]}
                                value={mode}
                                onChange={(v) => {
                                    setMode(v.target.value);
                                }}
                            />
                        </FormControl>
                    </Box>

                    <div style={{ maxWidth: 100, display: 'inline' }}></div>
                    <div style={{ width: 10, display: 'inline' }}></div>
                </Grid>
                <div
                    style={{ width: '100%', height: '75%' }}
                    walkthroughId={callStatsChart}>
                    <ResponsiveBarCanvas
                        data={data}
                        keys={
                            mode === 'Calls'
                                ? ['Inbound (calls)', 'Outbound (calls)']
                                : ['Inbound (mins)', 'Outbound (mins)']
                        }
                        indexBy="date"
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        valueFormat={{ format: '', enabled: false }}
                        theme={darkMode ? theme : null}
                        tooltip={({ id, value, color }) => (
                            <div
                                style={{
                                    padding: 12,
                                    color,
                                    background: '#222222',
                                }}>
                                {id}: {value}
                            </div>
                        )}
                        fill={[
                            {
                                match: {
                                    id: 'Inbound (mins)',
                                },
                                id: 'Inbound (mins)',
                            },
                            {
                                match: {
                                    id: 'Inbound (calls)',
                                },
                                id: 'Inbound (calls)',
                            },
                            {
                                match: {
                                    id: 'Outbound (mins)',
                                },
                                id: 'Outbound (mins)',
                            },
                            {
                                match: {
                                    id: 'Outbound (calls)',
                                },
                                id: 'Outbound (calls)',
                            },
                        ]}
                        defs={[
                            {
                                id: 'Inbound (calls)',
                                color: '#42ff21',
                            },
                            {
                                id: 'Outbound (calls)',
                                color: '#2596be',
                            },
                            {
                                id: 'Inbound (mins)',
                                background: '#42ff21',
                            },
                            {
                                id: 'Outbound (mins)',
                                color: '#2596be',
                            },
                        ]}
                        colors={getColor}
                        borderColor={{
                            from: 'color',
                            modifiers: [['darker', 3.0]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 45,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [['darker', 3.0]],
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'top-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
            </ChakraProvider>
        </>
    );
};

export default CallDashboard;
