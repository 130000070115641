//https://localhost:3000/Partner/Verify/miansc9407@gmail.com/NXgzWV8JztsooWjnZBKf8mg6GBlW0CC6qmgRVR8fsWRu9d1eQSNRLlzl2ltUtlJx
import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import config from '../../../../config.json';
import Image from '../../../../components/v4/Image';
import { useState } from 'react';

import FormLabel from '../../../../components/v4/FormLabel';
import Input from '../../../../components/v4/Input';
import Button from '../../../../components/v4/Button';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';

import useDetectFileType from '../../../../hooks/useDetectFileType';

export default function ActivateAccount() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState();
    const [email, setEmail] = useState('');
    const [hasError, setHasError] = useState(null);

    /**
     * Pingco Logo.
     */
    const { imagePathLightMode } = useSelector((state) => {
        return { ...state.login };
    });
    const fileType = useDetectFileType(
        import.meta.env.VITE_APP_IMAGE_BASE + imagePathLightMode,
    );

    useEffect(() => {
        searchParams.get('token') && setToken(searchParams.get('token'));
        searchParams.get('email') && setEmail(searchParams.get('email'));
        // Autosubmit if we have email and token
        if (searchParams.get('token') && searchParams.get('email')) {
            activateAccount(
                searchParams.get('email'),
                searchParams.get('token'),
            );
        }
    }, []);

    function onActivationSubmit(e) {
        e.preventDefault();
        activateAccount();
    }

    function activateAccount(emailFromQuery, tokenFromQuery) {
        fetch(`${import.meta.env.VITE_APP_API_URL}/ValidateRegistration`, {
            headers: {
                accept: 'application/json, text/plain, */*',
                'content-type': 'application/json;charset=UTF-8',
                'x-requestscope': '20',
            },
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({
                emailAddress: emailFromQuery || email,
                verify: tokenFromQuery || token,
            }),
        }).then(async (response) => {
            if (response.status === 200) {
                setHasError(null);
                navigate('/?action=activated');
            } else {
                const data = await response.json();
                setHasError(data.message);
            }
        });
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 60px',
                background: 'white',
                boxShadow: '2xl',
                width: '600px',
                margin: '0 auto',
            }}>
            <Box>
                <form onSubmit={onActivationSubmit}>
                    {fileType === 'svg' && (
                        <object
                            alt="Domain Logo"
                            style={{
                                display: 'block',
                                maxWidth: '300px',
                                maxHeight: '150px',
                                margin: '0 auto',
                            }}
                            data={
                                import.meta.env.VITE_APP_IMAGE_BASE +
                                imagePathLightMode
                            }
                            type="image/svg+xml"></object>
                    )}
                    {fileType === 'png' && (
                        <Image
                            alt="Domain Logo"
                            style={{
                                display: 'block',
                                maxWidth: '300px',
                                maxHeight: '150px',
                                margin: '0 auto',
                            }}
                            src={
                                import.meta.env.VITE_APP_IMAGE_BASE +
                                imagePathLightMode
                            }
                        />
                    )}

                    <FormLabel sx={{ margin: 0 }} for="email">
                        EMAIL
                    </FormLabel>
                    <Input
                        borderColor="gray"
                        variant="flushed"
                        size="sm"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Box sx={{ display: 'flex', margin: '15px 0' }} />

                    <FormLabel sx={{ margin: 0 }} for="token">
                        TOKEN
                    </FormLabel>
                    <Input
                        borderColor="gray"
                        variant="flushed"
                        size="sm"
                        id="token"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                    />

                    <Box sx={{ display: 'flex', margin: '15px 0' }} />

                    {hasError && (
                        <>
                            <Alert status="error" sx={{ marginBottom: '25px' }}>
                                <AlertIcon />
                                <AlertDescription>{hasError}</AlertDescription>
                            </Alert>
                            <Box
                                sx={{ display: 'flex', margin: '0  0 15px 0' }}
                            />
                        </>
                    )}

                    <Button
                        type="submit"
                        sx={{
                            textAlign: 'center',
                            display: 'block',
                            width: '100%',
                        }}>
                        Activate account
                    </Button>
                </form>
            </Box>
        </Box>
    );
}
