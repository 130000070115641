import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { modalTitle } from '../../../components/tables/TableAndModal';
import config from '../../../config.json';
import Item from './edit/DialEdit';
import { MultiChild, ModalCopy } from '../../../components';

import walkthroughIds from './walkthroughIds';

/*
 * Table For dial plans with the edit or modal copy functionality
 */
const Dial = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // modal data from state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // default columns
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
        },
    ];

    // handler for change
    const nameChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    // renders the table, add and edit pages - with Dial Edit inside it as a tab
    return (
        <MultiChild
            tableRef={tableRef}
            modalCopy={`/dialplan/${modalData?.id}/copy`}
            tableFetch={'/dialplans/'}
            modalFetch={'/dialplan/' + modalData?.id}
            size={'md'}
            modalAdd="/dialplan/"
            modalUpdate={'/dialplan/'}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title={'Dial Plans'}
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            rowActions={{
                copiable: true,
                multiChildTable: { name: 'Edit Dial Plans and Rules' },
            }}
            tabs={[{ details: true }, { component: <Item />, label: 'Rules' }]}
            maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            modalTitle={modalTitle(
                modalData,
                'name',
                ' Dial Plans ',
                modalMode,
            )}
            modalValidate={() => {
                var newErrorState = null;
                if (modalMode === 'Copy') {
                    newErrorState = {
                        copyName:
                            (!modalState?.copyName ||
                                modalState?.copyName?.length < 1) &&
                            'Field is required.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !newErrorState.copyName;
                }
                newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !newErrorState.name;
            }}
            modalContent={
                modalMode === 'Copy' ? (
                    <ModalCopy
                        modalData={modalData}
                        modalState={modalState}
                        modalStateError={modalStateError}
                        setModalState={(payload) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload,
                            });
                        }}
                    />
                ) : (
                    <>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label={'Name'}
                                    type="text"
                                    error={Boolean(modalStateError?.name)}
                                    helperText={modalStateError?.name}
                                    value={modalState?.name}
                                    onChange={nameChangeHandler}
                                    data-walkthroughid={
                                        walkthroughIds.ufModalNameInput
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )
            }
        />
    );
};

export default Dial;
