import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import { loadingColumnsConstructor } from '@/components/tables/ReduxTable';
import MaterialTable from '@/components/v4/MaterialTable';
import { getJobsQuery } from '@tcap/data-router/loaders/jobLoader';
import config from '@/config.json';

/*
 * Component for the Jobs tab in the Sidebar that used the TableAndModal component
 * This table has no Read or Update/Delete and is just for viewing what jobs are active currently
 */
const Jobs = () => {
    const tableRef = useRef(null);
    // scope from state
    const { currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // current comapny, wholeasler and partner from state
    const { currentCompany, currentWholesaler, currentPartner } = useSelector(
        (state) => {
            return { ...state.navigationLists };
        },
    );

    // default columns for the table
    const defaultColumns = useMemo(() => {
        return [
            {
                title: 'Name',
                field: 'description',
            },

            {
                title: 'Status',
                field: 'jobStatus',

                width: '5%',
                cellStyle: {
                    textAlign: 'center',
                },
                lookupDescription: config.jobs.statusJSON,
                lookup: {
                    0: (
                        <Tooltip title="Open">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'purple',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    1: (
                        <Tooltip title="In Progress">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: '#c1c92a',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    2: (
                        <Tooltip title="Completed">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'green',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    3: (
                        <Tooltip title="Cancelled">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'red',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                    20: (
                        <Tooltip title="Error">
                            <FiberManualRecordRoundedIcon
                                style={{
                                    color: 'red',
                                    fontSize: 'small',
                                    textAlign: 'center',
                                }}
                            />
                        </Tooltip>
                    ),
                },
                customSort: (a, b) => a.jobStatus - b.jobStatus,
            },

            {
                title: 'Worker Type',
                field: 'workerType',
                lookup: config.jobs.workersJSON,
            },
            {
                title: 'Worker ID',
                field: 'workerID',
                hidden: currentScope !== 80,
                hiddenByColumnsButton: true,
            },

            { title: 'Comments', field: 'comments' },
            {
                title: 'Last Updated At',
                field: 'lastUpdatedTS',
                type: 'datetime',
            },
            {
                title: 'Created At',
                field: 'createTS',
                type: 'datetime',
                defaultSort: 'desc',
            },
            {
                title: 'Detail Type',
                field: 'detailType',
                lookup: config.jobs.detailsJSON,
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Details',
                field: 'details',
                hidden: true,
                hiddenByColumnsButton: true,
            },

            {
                title: 'Owner ID',
                field: 'ownerID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Wholesaler ID',
                field: 'wholesalerID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Partner ID',
                field: 'partnerID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
            {
                title: 'Company ID',
                field: 'companyID',
                hidden: true,
                hiddenByColumnsButton: true,
            },
        ];
    }, [currentScope]);

    const {
        data: jobs,
        isRefetching,
        refetch,
        status,
    } = useQuery(
        getJobsQuery({
            wholesalerID: currentScope === 60 ? currentWholesaler : null,
            partnerID: currentScope === 40 ? currentPartner : null,
            companyID: currentScope === 20 ? currentCompany : null,
        }),
        { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 2 },
    );

    const dispatch = useDispatch();

    const refreshFn = () => {
        dispatch({
            type: 'CHANGE_TABLE_FILTERING',
        });
        tableRef.current &&
            tableRef.current.dataManager.columns.forEach((item) => {
                tableRef.current.onFilterChange(item.tableData.id, '');
            });
        refetch();
    };

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(defaultColumns),
        [defaultColumns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;
    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    const componentLoadingState = status === 'loading' || isRefetching;
    const renderedColumns = componentLoadingState
        ? loadingColumns
        : defaultColumns;
    const renderedTableData = componentLoadingState ? dummyTable : jobs;

    return (
        <MaterialTable
            title={<h1 className="mb-2 text-[40px] font-medium">Jobs</h1>}
            tableRef={tableRef}
            titleName="Jobs"
            columns={renderedColumns}
            refreshFn={refreshFn}
            refreshTable={refreshFn}
            data={renderedTableData}
        />
    );
};

export default Jobs;
