import { ACTIONS } from './constant';

export const serviceReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.GET_SERVICE_ACTIONS:
        case ACTIONS.UPDATE_SERVICE_ACTIONS:
            return {
                ...state,
                actions: action.payload,
            };

        case ACTIONS.UPDATE_SERVICE: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default:
            return state;
    }
};
