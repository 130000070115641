import ACS from '../edit/ACS';
import DirectRouting from '../edit/DirectRouting';
import OperatorConnect from '../edit/OperatorConnect';
import SBCaaS from '../edit/SBCaaS';
import TPM from '../edit/TPM';
import SelectProfile from './SelectProfile';
import OCC from '../edit/OCC';
import AddVendorDefined from '../vendorDefined/AddVendorDefined';
import EditVendorDefined from '../vendorDefined/EditVendorDefined';
import { DefinedAttributesProvider } from '../vendorDefined/DefinedAttributesContext';

export default function Form({
    mode = 'ADD',
    data,
    handleBackButton,
    level,
    handleSelectServiceProfileType,
    scopeList,
}) {
    const visibleScopeOptions = scopeList();

    const serviceTypeSelected = data?.serviceType.includes('VendorDefined')
        ? data?.serviceType.split('|')[0]
        : data?.serviceType;

    switch (serviceTypeSelected) {
        case 'TeamsPhoneMobile':
            return (
                <TPM
                    mode={mode}
                    data={mode === 'EDIT' ? data : null}
                    level={level}
                    handleBackButton={handleBackButton}
                    visibleScopeOptions={visibleScopeOptions}
                />
            );
        case 'SBCaaS':
            return (
                <SBCaaS
                    mode={mode}
                    data={mode === 'EDIT' ? data : null}
                    level={level}
                    handleBackButton={handleBackButton}
                    visibleScopeOptions={visibleScopeOptions}
                />
            );
        case 'OperatorConnect':
            return (
                <OperatorConnect
                    mode={mode}
                    level={level}
                    data={mode === 'EDIT' ? data : null}
                    handleBackButton={handleBackButton}
                    visibleScopeOptions={visibleScopeOptions}
                />
            );
        case 'DirectRouting':
            return (
                <DirectRouting
                    mode={mode}
                    data={mode === 'EDIT' ? data : null}
                    level={level}
                    handleBackButton={handleBackButton}
                    visibleScopeOptions={visibleScopeOptions}
                />
            );
        case 'ACSDirectRouting':
            return (
                <ACS
                    mode={mode}
                    data={mode === 'EDIT' ? data : null}
                    level={level}
                    handleBackButton={handleBackButton}
                    visibleScopeOptions={visibleScopeOptions}
                />
            );
        case 'OperatorConnectConferencing':
            return (
                <OCC
                    mode={mode}
                    data={mode === 'EDIT' ? data : null}
                    level={level}
                    handleBackButton={handleBackButton}
                    visibleScopeOptions={visibleScopeOptions}
                />
            );
        case 'VendorDefined':
            return (
                <DefinedAttributesProvider>
                    {mode === 'EDIT' ? (
                        <EditVendorDefined
                            data={data}
                            level={level}
                            handleBackButton={handleBackButton}
                            visibleScopeOptions={visibleScopeOptions}
                        />
                    ) : (
                        <AddVendorDefined
                            data={data}
                            level={level}
                            handleBackButton={handleBackButton}
                            visibleScopeOptions={visibleScopeOptions}
                        />
                    )}
                </DefinedAttributesProvider>
            );

        default: //default: ADD mode
            return (
                <SelectProfile
                    level={level}
                    handleBackButton={handleBackButton}
                    handleSelectServiceProfileType={
                        handleSelectServiceProfileType
                    }
                />
            );
    }
}
