export default {
    internationalRates: {
        ufCopyBtn: '/international-rates/button/copy',
        ufEditBtn: '/international-rates/button/edit',
        ufNameInput: '/international-rates/input/name',
        ufPublicCheckbox: '/international-rates/checkbox/public',
        ufActiveCheckbox: '/international-rates/checkbox/active',
        ufWholesalerSelect: '/international-rates/select/wholesaler',
        ufPartnerSelect: '/international-rates/select/partner',
    },
};
