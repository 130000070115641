import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { ArrowForwardIcon, CheckIcon, ArrowBackIcon } from '@chakra-ui/icons';
import Heading from '../../../../components/v4/Heading';
import Button from '../../../../components/v4/Button';
import ProgressBarView from './ProgressBarView';
import Alert from '../../../../components/v4/Alert';
import AlertIcon from '../../../../components/v4/AlertIcon';

const Step = ({ label, check, current, completionCounter, total }) => (
    <React.Fragment>
        {current && (
            <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {check ? (
                        <CheckIcon
                            sx={{
                                marginRight: '6px',
                                color: 'green.400',
                            }}
                        />
                    ) : (
                        <Spinner sx={{ marginRight: '6px' }} size="sm" />
                    )}
                    <Text margin={0}>{label}</Text>
                </Box>
                {!check && (
                    <ProgressBarView
                        completionCounter={completionCounter}
                        current={Math.min(completionCounter + 1, total)}
                        total={total}
                    />
                )}
            </React.Fragment>
        )}
    </React.Fragment>
);

export default function VerifyCSVView(props) {
    const {
        verificationChecks,
        handleViewVerificationResults,
        errors,
        handleStartOver,
    } = props;
    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Box display={'flex'} justifyContent="center">
            <Box
                width={400}
                display={'flex'}
                flexDirection={'column'}
                gap={'1px'}
                fontFamily={'Helvetica'}
                justifyContent={'center'}
                boxShadow="base"
                p="6"
                bg={darkMode ? 'dark.tcap' : 'white'}
                rounded="md">
                <Heading size="xl">{'CSV Validation'}</Heading>
                {errors ? (
                    <React.Fragment>
                        <Box id="validationContainer">
                            <Alert status="success">
                                <AlertIcon />

                                <Text size="sm" whiteSpace="pre-line">
                                    {errors}
                                </Text>
                            </Alert>
                        </Box>
                        <Box display={'flex'} justifyContent={'left'} mt={5}>
                            <Button
                                leftIcon={<ArrowBackIcon />}
                                size="sm"
                                onClick={handleStartOver}>
                                {'Start Over'}
                            </Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'10px'}
                            mt={'10px'}>
                            {verificationChecks.map((v, i) => {
                                return <Step key={i} {...v} />;
                            })}
                        </Box>
                        {verificationChecks.every((v) => v.check) && (
                            <Box display={'flex'} justifyContent={'right'}>
                                <Button
                                    rightIcon={<ArrowForwardIcon />}
                                    size="sm"
                                    onClick={handleViewVerificationResults}>
                                    {'View Results'}
                                </Button>
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </Box>
        </Box>
    );
}
