import { Box, Text } from '@chakra-ui/react';

import {
    MsalProvider,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';

import { AuthProvider, useAuth } from '@sso/context/authContext';
import { MicrosoftButton } from '@sso/components/button/TwButtons';

import { msalInstance } from '@sso/index';
import { useMsData } from '@sso/utils/useMsData';
import { ThreeDots } from '@sso/components';

const LoginSsoLoader = () => {
    return (
        <MsalProvider instance={msalInstance}>
            <AuthProvider>
                <LoginSSO />
            </AuthProvider>
        </MsalProvider>
    );
};

export const ErrorMessage = () => {
    const { errorMessage } = useAuth();

    if (errorMessage) return <Text color="red.500">{errorMessage}</Text>;
    return null;
};

const AuthenticatedContent = () => {
    const { formState, errorMessage } = useMsData();
    return (
        <>
            {!errorMessage && formState === 'pending' ? (
                <div className="flex h-6 items-center">
                    <ThreeDots />
                </div>
            ) : (
                <MicrosoftButton />
            )}
        </>
    );
};

export const LoginSSO = () => {
    return (
        <>
            <div className="mb-2">
                <Box className="flex w-full justify-center">
                    <AuthenticatedTemplate>
                        <AuthenticatedContent />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <MicrosoftButton />
                    </UnauthenticatedTemplate>
                </Box>
            </div>
            <ErrorMessage />
        </>
    );
};

export default LoginSsoLoader;
