import { useSelector } from 'react-redux';
import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';

import chakratheme from '@/chakratheme';

/**
 * @description Custom hook to merge chakra theme with mui theme
 * @returns {import('@mui/material/styles').Theme} theme
 */
export function useDeepMerge() {
    const { darkMode } = useSelector((state) => state.settings);
    const theme = createTheme(
        deepmerge(chakratheme, {
            palette: { mode: darkMode ? 'dark' : 'light' },
            shadows: Array(25).fill('none'),
        }),
    );

    return { theme, chakratheme };
}
