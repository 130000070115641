import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import {
    TextField,
    // Box,
    Grid,
    MenuItem,
    Alert,
    AlertTitle,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingFieldDropdown } from '../../../../components';
import { CircularProgress, Typography } from '@material-ui/core';
import { axios } from '../../../../services/axios';
import { NonNegativeInteger } from '../../../../components/maintenance/NumberFormatCustom';
import { classNames } from 'utils/classNames';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabContext, TabPanel } from '@material-ui/lab';
import { getInputDarkStyle } from '@/constants';

// external id
const trunkTypeCondition = 'f4de8d22-bf78-4c05-a677-4692dfc5444f';

// Basic form when you Add for trunks in SBCaaS tile
const AddTrunk = () => {
    const navigate = useNavigate();
    const [trunkCapacity, setTrunkCapacity] = useState(0);
    const [nCapacity, setNCapacity] = useState(0);

    // state access
    const [modalState, setModalState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tabView, setTabView] = useState(0);
    const dispatch = useDispatch();

    // access to the current company selected
    const { currentCompany: currCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const { darkMode } = useSelector((state) => state.settings);
    const inputDarkStyle = getInputDarkStyle(darkMode);

    // state access
    const { stateError } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const handleBackbutton = () => {
        navigate('/trunks');
    };

    const handleTabChange = (e, value) => {
        setTabView(value);
    };

    // function to set state with a given payload
    const setState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    /*
     * changes n-capacity and trunk capacity whenevnery the redundancy level or channel value changes
     * capacity is redundancy level multiplied by channel value
     * n-capacity redundancy level -1 multipled by channel value
     */
    useEffect(() => {
        if (modalState?.redundancyLevel && modalState?.channels) {
            setTrunkCapacity(modalState.redundancyLevel * modalState.channels);
            setNCapacity(
                (modalState.redundancyLevel - 1) * modalState.channels,
            );
        }
    }, [modalState?.redundancyLevel, modalState?.channels]);
    // static enum for the value and label of the redundancy level drop down
    const redundancy = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
    ];
    const [availableRegions, setAvailableRegions] = useState();
    const [maxChannels, setMaxChannels] = useState(0);
    // Checks if the current company has SBC as a service as a service type when component renders
    useEffect(() => {
        axios.get(`/services/company/${currCompany}`).then((res) => {
            res.data.forEach((service) => {
                if (service.serviceType === 'SBCaaS') {
                    axios.get(`/Service/${service.id}`).then((response) => {
                        setAvailableRegions(response.data.regions);
                        setMaxChannels(response.data.maxChannel);
                    });
                }
            });
        });
    }, []);

    /**
     * Validate inputs
     * @returns
     */
    const validateModal = () => {
        const newErrorState = {
            name:
                modalState?.name &&
                !RegExp(`^[a-zA-Z0-9- ]+$`).test(modalState?.name) &&
                `Must be alphanumeric characters`,
            channels:
                (parseInt(modalState?.channels) > maxChannels &&
                    `Max channels limit is ${maxChannels}`) ||
                (modalState?.channels <= 0 &&
                    'Channels must be positive and greater than zero'),
            regionID:
                !modalState?.regionID &&
                modalState?.trunkTypeID !==
                    'f4de8d22-bf78-4c05-a677-4692dfc5444f' &&
                'Region must be selected',
            redundancyLevel:
                !modalState?.redundancyLevel &&
                modalState?.trunkTypeID !==
                    'f4de8d22-bf78-4c05-a677-4692dfc5444f' &&
                'Redundancy level is required',
        };
        dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: newErrorState,
        });
        return !(
            newErrorState.name ||
            newErrorState.channels ||
            newErrorState.trunkTypeID ||
            newErrorState.regionID ||
            newErrorState.redundancyLevel
        );
    };

    async function generatePassword(id) {
        return await axios.get(`/Trunk/${id}/NewSipPassword`);
    }

    /*
     * upload handler for when a trunk is added
     * data is transfomred, if redundancy level isnt listed it defaults to 2
     * on success of the creation, redirect to the edit page
     */
    const submitTrunk = () => {
        setIsLoading(true);
        if (!validateModal()) {
            setIsLoading(false);
            return;
        }
        let redundancy;
        if (!modalState.redundancyLevel) {
            redundancy = 2;
        } else {
            redundancy = modalState.redundancyLevel;
        }
        axios
            .post(
                '/trunk/create',
                {
                    trunkTypeID: modalState?.trunkTypeID,
                    companyID: currCompany,
                    regionID: modalState?.regionID,
                    redundancyLevel: redundancy,
                    channels: modalState?.channels,
                    name: modalState?.name,
                    authType: '1',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then(async (res) => {
                if (res.status === 200) {
                    // If it's an external trunk, we don't need to redirect to the edit screen
                    const externalTrunkId =
                        'f4de8d22-bf78-4c05-a677-4692dfc5444f';
                    if (res.data.trunkTypeID === externalTrunkId) {
                        navigate('/trunks');
                        dispatch({
                            type: 'RESET_TABLE',
                        });
                        return;
                    }
                    // for sip registration by default
                    var result = await generatePassword(res.data.id);
                    navigate(`/trunks/edit/sip/${res.data.id}`, {
                        state: result.data,
                    });
                } else {
                    toast.error(res.data);
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                toast.error(e.response.data.message);
                setIsLoading(false);
            });
    };

    return (
        <>
            <Modal.Title>Add Trunk</Modal.Title>
            <TabContext value={tabView}>
                <Box
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'brand.500',
                        },
                    }}>
                    <Tabs
                        value={tabView}
                        centered
                        textColor="primary"
                        onChange={handleTabChange}>
                        <Tab label="General" key="General" />
                    </Tabs>
                    <TabPanel className="MuiTypography-body1" value={tabView}>
                        <Container fluid>
                            <Form
                                style={{
                                    paddingTop: '1%',
                                    paddingBottom: '1%',
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    submitTrunk();
                                }}>
                                {isLoading ? (
                                    <CircularProgress
                                        style={{
                                            marginTop: '2.5%',
                                            marginLeft: '50%',
                                        }}
                                    />
                                ) : (
                                    <>
                                        {/* Grid with the fields required to create or edit a trunk */}
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classNames(
                                                modalState?.trunkTypeID &&
                                                    modalState?.trunkTypeID !==
                                                        trunkTypeCondition
                                                    ? 'justify-start'
                                                    : 'justify-center',
                                            )}>
                                            <Grid item xs={6}>
                                                <LoadingFieldDropdown
                                                    fieldFetch="/trunktypes/available"
                                                    init={true}
                                                    fieldName="Trunk Type"
                                                    dropDownValue="id"
                                                    displayField="name"
                                                    noEmptyOption
                                                    // empty space rather than showing not allocated
                                                    noneFieldDisplay=" "
                                                    errorText={
                                                        stateError?.trunkTypeID
                                                    }
                                                    onChange={(v) => {
                                                        setState({
                                                            trunkTypeID: v,
                                                            channels: 0,
                                                            regionID: '',
                                                            redundancyLevel: '',
                                                            name: '',
                                                        });
                                                    }}
                                                    fieldValue={
                                                        modalState?.trunkTypeID
                                                    }
                                                />
                                            </Grid>

                                            {modalState?.trunkTypeID && (
                                                <>
                                                    {/* Regions */}
                                                    {modalState?.trunkTypeID &&
                                                        modalState?.trunkTypeID !==
                                                            trunkTypeCondition && (
                                                            <Grid item xs={6}>
                                                                <LoadingFieldDropdown
                                                                    staticEnum={
                                                                        availableRegions
                                                                    }
                                                                    fieldName="Region"
                                                                    dropDownValue="id"
                                                                    displayField="name"
                                                                    onChange={(
                                                                        v,
                                                                    ) => {
                                                                        setState(
                                                                            {
                                                                                regionID:
                                                                                    v,
                                                                            },
                                                                        );
                                                                    }}
                                                                    fieldValue={
                                                                        modalState?.regionID
                                                                    }
                                                                    toggleLimit={
                                                                        6
                                                                    }
                                                                    errorText={
                                                                        stateError?.regionID
                                                                    }
                                                                    error={Boolean(
                                                                        stateError?.regionID,
                                                                    )}
                                                                    helperText={
                                                                        stateError?.regionID
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}

                                                    {/* Trunk Name */}
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={inputDarkStyle}>
                                                        <TextField
                                                            sx={{
                                                                paddingTop: 0.6,
                                                            }}
                                                            fullWidth
                                                            id="TrunkName"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            label="Trunk Name"
                                                            size="small"
                                                            value={
                                                                modalState?.name
                                                            }
                                                            onChange={(e) => {
                                                                setState({
                                                                    name: e
                                                                        .target
                                                                        .value,
                                                                });
                                                            }}
                                                            error={Boolean(
                                                                stateError?.name,
                                                            )}
                                                            helperText={
                                                                stateError?.name
                                                            }
                                                        />
                                                    </Grid>

                                                    {/* Channels */}
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={inputDarkStyle}>
                                                        <TextField
                                                            sx={{
                                                                paddingTop: 0.6,
                                                            }}
                                                            fullWidth
                                                            id="Channels"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            label="Channels"
                                                            size="small"
                                                            value={
                                                                modalState?.channels
                                                            }
                                                            onChange={(e) => {
                                                                setState({
                                                                    channels:
                                                                        e.target
                                                                            .value,
                                                                });
                                                            }}
                                                            InputProps={{
                                                                inputComponent:
                                                                    NonNegativeInteger,
                                                            }}
                                                            error={Boolean(
                                                                stateError?.channels,
                                                            )}
                                                            helperText={
                                                                stateError?.channels
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* Redundancy Level */}
                                                    {modalState?.trunkTypeID &&
                                                        modalState?.trunkTypeID !==
                                                            trunkTypeCondition &&
                                                        modalState?.regionID && (
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                sx={{
                                                                    ...inputDarkStyle,
                                                                    'div.MuiInputBase-input.MuiOutlinedInput-input':
                                                                        {
                                                                            color: darkMode
                                                                                ? '#FFFFFF'
                                                                                : null,
                                                                        },
                                                                }}>
                                                                <TextField
                                                                    sx={{
                                                                        paddingTop: 0.6,
                                                                    }}
                                                                    fullWidth
                                                                    id="Redundancy"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    select
                                                                    label="Redundancy Level"
                                                                    size="small"
                                                                    value={
                                                                        modalState?.redundancyLevel
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        setState(
                                                                            {
                                                                                redundancyLevel:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            },
                                                                        );
                                                                    }}
                                                                    error={Boolean(
                                                                        stateError?.redundancyLevel,
                                                                    )}
                                                                    helperText={
                                                                        stateError?.redundancyLevel
                                                                    }>
                                                                    {redundancy.map(
                                                                        (
                                                                            option,
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    option.label
                                                                                }
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </MenuItem>
                                                                        ),
                                                                    )}
                                                                </TextField>
                                                            </Grid>
                                                        )}
                                                    {/* Grid with the display of the capacitiy and n-1 capacity that is updated based on state */}
                                                    {modalState?.trunkTypeID &&
                                                        modalState?.trunkTypeID !==
                                                            trunkTypeCondition && (
                                                            <Grid item xs={3}>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}>
                                                                    <Typography>
                                                                        <Box>
                                                                            Total
                                                                            Trunk
                                                                            Capacity:{' '}
                                                                            {
                                                                                trunkCapacity
                                                                            }
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'flex-end',
                                                                            }}>
                                                                            n-1
                                                                            Capacity:{' '}
                                                                            {
                                                                                nCapacity
                                                                            }
                                                                        </Box>
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                </>
                                            )}
                                        </Grid>

                                        {/* Back/Create Button */}
                                        <div
                                            style={{
                                                border: 'none',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}>
                                            <Button
                                                variant="secondary"
                                                onClick={handleBackbutton}>
                                                Back
                                            </Button>
                                            <div
                                                style={{
                                                    marginLeft: 10,
                                                    display: 'inline',
                                                }}>
                                                <Button
                                                    variant="primary"
                                                    type="submit">
                                                    Create
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>
                        </Container>
                    </TabPanel>
                </Box>
            </TabContext>
        </>
    );
};

export default AddTrunk;
