import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { getAssociations } from 'API/TPMTrial';

function useAssociatedAccounts(serviceID) {
    const dispatch = useDispatch();
    return useQuery({
        queryKey: ['getAssociatedAccounts', serviceID],
        queryFn: async () => {
            try {
                const res = await getAssociations({
                    serviceID,
                });
                return res.data;
            } catch (error) {
                let errorMessage = 'Error fetching data';
                if (error.response.status === 401) {
                    errorMessage =
                        'Unauthorized. Login does not have the required access.';
                } else if (error.response.status === 403) {
                    errorMessage =
                        'Forbidden. Provided credentials do not have access to the requested resource.';
                } else {
                    errorMessage =
                        error?.response?.data?.message ||
                        error?.response?.data?.title;
                }

                dispatch({
                    type: 'CHANGE_MODAL_BACKEND_ERROR',
                    payload: errorMessage,
                });
            }
        },
        useErrorBoundary: (error) => error.response?.status >= 500,
        retry: 3,
        enabled: !!serviceID,
        cacheTime: 1,
    });
}

export default useAssociatedAccounts;
