import { Box, Stack } from '@chakra-ui/react';
import Skeleton from '../../Skeleton';
import Grid from '../../Grid';
import styles from './styles';
import DashboardServiceCard from '../DashboardServiceCard/DashboardServiceCard';
import { SkeletonCircle } from '@chakra-ui/react';

export default function DashboardServiceCardPlaceholder({
    skeletons,
    rows,
    columns,
    items,
    ...props
}) {
    return (
        <Grid rows={rows} columns={columns} gap={4} {...props}>
            {Array.from(Array(items)).map((v, index) => (
                <DashboardServiceCard key={`Skeleton number ${index + 1}`}>
                    <Stack sx={{ padding: '10px' }}>
                        <SkeletonCircle size="10" sx={styles.skeletonCircle} />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Stack>
                </DashboardServiceCard>
            ))}
        </Grid>
    );
}
