import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

/*
 * Card for Operator Connect/Powershell/Direct Routing auth methods in the 'Provide Access' Screen
 */
const ImgMediaCard = ({title, image, description, cardOnClick, divStyle}) => {
    return (
        <Card style={{width: 300}}>
            <CardActionArea onClick={cardOnClick}>
                <div style={{height: 200, ...divStyle}}>
                    <img
                        alt=""
                        src={image.image}
                        style={{
                            ...image.style,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    />
                </div>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ImgMediaCard;
