import { createContext, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

// TODO: sort by search field

export default function useVirtualizedTableFilter({ data }) {
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [search, setSearch] = useState('');
    const [searchField, setSearchField] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeSearch = (value) => {
        setSearch(value);
        const newFilteredData =
            value.length === 0
                ? tableData
                : tableData.filter((v) => v[searchField].includes(value));
        setFilteredData(newFilteredData);
    };
    const handleChangeSearchDB = useCallback(
        debounce(handleChangeSearch, 500),
        [filteredData, tableData, searchField],
    );

    const handleInitTableData = (newTableData, newSearch, newSearchField) => {
        setIsLoading(true);
        setTableData(newTableData);
        const newFilteredData =
            newSearch.length === 0
                ? newTableData
                : newTableData.filter((v) =>
                    v[newSearchField].includes(newSearch),
                );
        setFilteredData(newFilteredData);
        setSearch(newSearch);
        setSearchField(newSearchField);
        setIsLoading(false);
    };

    useEffect(() => {
        handleInitTableData(data, search, searchField);
    }, [data, search, searchField]);

    return {
        setTableData,
        filteredData,
        setSearch,
        setSearchField,
        handleChangeSearch: handleChangeSearchDB,
        isLoading,
        tableData,
    };
}
