/* eslint-disable no-unused-vars */
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Stack,
    Divider,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Formik, Field } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from '@/components/v4';

import { postVg, updateVg } from '@/API/VisibilityGroups';

import { Card, LoadingIndicator, Text } from '@/components/v4';
import { ACTIONS, useVisibilityGroup } from 'context/VisibilityGroupsContext';
import { VgMembersTable, initMember } from '../table';
import { BreadCrumbHeader } from './BreadCrumbHeader';
import { axios } from '@/services/axios';
import { useSingleToast } from 'hooks/useSingleToast';
import moment from 'moment';

export default function VgEdit() {
    const navigate = useNavigate();
    const [vg, setVg] = useState(null);
    const [members, setMembers] = useState([initMember]);
    const [, dispatch] = useVisibilityGroup();
    const { darkMode } = useSelector((state) => state.settings);

    const { errorToast } = useSingleToast();

    const { vgId } = useParams();
    const tablePath = '/visibility-groups';

    // const { currentPartner } = useSelector((state) => state.navigationLists);
    const queryClient = useQueryClient();

    const { mutate: editVg, error } = useMutation(
        async (newGroup) => {
            const response = await updateVg(newGroup);
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // dispatch({
                //     type: ACTIONS.UPDATED,
                //     payload: {
                //         vg: data,
                //     },
                // });
                // setVg(data);

                queryClient.invalidateQueries({
                    queryKey: ['visibilityGroup'],
                });
                toast.success('Visibility Group updated successfully');
                navigate(tablePath);
            },
            onError: (error) => {
                errorToast(error?.response?.data?.message);
            },
        },
    );

    const {
        data: visibilityGroup,
        isFetching: visibilityGroupLoading,
        isLoading,
        isRefetching,
    } = useQuery({
        queryKey: ['visibilityGroup', vgId],
        queryFn: async () => {
            const res = await axios.get(`/visibilitygroup/${vgId}`);
            return res.data;
        },
        onSuccess: async (data) => {
            dispatch({
                type: ACTIONS.FETCH,
                payload: data,
            });
            setVg(data);
            setMembers(
                (data.visibilityGroupMembers || []).map(
                    ({ email, isOwner, validFrom, validTo }) => ({
                        email,
                        isOwner,
                        validFrom,
                        validTo,
                    }),
                ),
            );

            queryClient.invalidateQueries({
                queryKey: ['visibilityGroups'],
            });
        },
        enabled: !!vgId,
        staleTime: 500,
        refetchOnWindowFocus: false,
    });

    // const { isLoading, isRefetching } = useQuery({
    //     ...vgMembersQuery(vgId),
    //     onSuccess: async (data) => {
    //         console.log('members query');
    //         console.log(data);
    //         dispatch({
    //             type: ACTIONS.FETCH,
    //             payload: data,
    //         });
    //         setVg(data);
    //         setMembers(
    //             (data.visibilityGroupMembers || []).map(
    //                 ({ email, isOwner }) => ({
    //                     email,
    //                     isOwner,
    //                 }),
    //             ),
    //         );

    //         queryClient.invalidateQueries({
    //             queryKey: ['visibilityGroups'],
    //         });
    //     },
    //     staleTime: 500,
    //     initialData,
    // });

    const initialValues = visibilityGroup;

    const handleSubmit = (value) => {
        const apiModelView = [];
        var date = new Date();
        var validTo = new Date('9999-12-31T23:59:59.9999999');
        var validFrom = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');

        members?.map((member) => {
            if (member?.email) {
                const newItem = {
                    email: member.email,
                    isOwner: member.isOwner,
                    validFrom: member?.validFrom || validFrom,
                    validTo: member?.validTo || validTo,
                };
                apiModelView.push(newItem);
            }
        });

        const finalSubmit = {
            ...vg,
            ...value,
            visibilityGroupMembers: apiModelView,
        };

        if (vgId) {
            // update vg
            editVg(finalSubmit);
        }
        if (error) {
            errorToast(error?.message);
        }
    };

    const memberTableProps = {
        members,
        setMembers,
    };

    if (isLoading || isRefetching) {
        return <LoadingIndicator />;
    }

    return (
        <section>
            <BreadCrumbHeader />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            mt="30px"
                            maxW="895px"
                            bg={darkMode ? 'dark.tcap' : null}
                            border
                            borderColor="gray.200">
                            <Stack spacing="5" py="1.25rem">
                                <Stack spacing="5" ps="3.5rem" pe="4rem">
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            !!errors.name && touched.name
                                        }>
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            spacing={{ base: '1.5', md: '8' }}
                                            justify="space-between">
                                            <FormLabel
                                                variant="inline"
                                                htmlFor="name"
                                                minW="100px"
                                                fontSize="sm">
                                                Name
                                            </FormLabel>
                                            <Field
                                                as={Input}
                                                type="text"
                                                id="name"
                                                name="name"
                                                maxW={{ md: '3xl' }}
                                                fontSize="sm"
                                                validate={(value) => {
                                                    let error;
                                                    if (value.length < 1) {
                                                        error =
                                                            'Name is required';
                                                    }
                                                    return error;
                                                }}
                                            />
                                        </Stack>

                                        <Flex
                                            justifyContent="flex-start"
                                            ms={8}>
                                            <Flex minW="100px" aria-hidden />

                                            <FormErrorMessage>
                                                {errors.name}
                                            </FormErrorMessage>
                                        </Flex>
                                    </FormControl>
                                </Stack>

                                <Stack
                                    spacing="5"
                                    ps="3.5rem"
                                    pe="4rem"
                                    className="mt-0">
                                    <Divider
                                        borderColor="#F5F5F5"
                                        className="my-[20px]"
                                    />
                                </Stack>

                                <Stack
                                    spacing="5"
                                    ps="3.5rem"
                                    pe="4rem"
                                    className="mt-0">
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            !!errors.description &&
                                            touched.description
                                        }>
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            spacing={{ base: '1.5', md: '8' }}
                                            justify="space-between">
                                            <FormLabel
                                                variant="inline"
                                                htmlFor="description"
                                                minW="100px"
                                                fontSize="sm">
                                                Description
                                            </FormLabel>
                                            <Field
                                                as={Input}
                                                type="text"
                                                id="description"
                                                name="description"
                                                fontSize="sm"
                                                maxW={{ md: '3xl' }}
                                                validate={(value) => {
                                                    let error;
                                                    if (value.length < 1) {
                                                        error =
                                                            'Description is required';
                                                    }
                                                    return error;
                                                }}
                                            />
                                        </Stack>

                                        <Flex
                                            justifyContent="flex-start"
                                            ms={8}>
                                            <Flex minW="100px" aria-hidden />

                                            <FormErrorMessage>
                                                {errors.description}
                                            </FormErrorMessage>
                                        </Flex>
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </Card>

                        <h1 className="mt-4 text-base font-medium">Members</h1>
                        <Text color="gray">
                            Owners can manage other Owners and Members.
                        </Text>
                        <VgMembersTable {...memberTableProps} />

                        <Flex className="edit-form-buttons" mt="30px" mb={2}>
                            <Button
                                type="submit"
                                className="h-10 min-w-[5rem] font-semibold disabled:cursor-not-allowed"
                                sx={{ width: 142 }}>
                                Save
                            </Button>
                            <div
                                style={{
                                    marginLeft: 30,
                                    display: 'inline',
                                }}>
                                <Button
                                    className="secondary h-10 min-w-[5rem] font-semibold"
                                    variant="outline"
                                    sx={{ width: 142 }}
                                    onClick={() => navigate(tablePath)}>
                                    Cancel
                                </Button>
                            </div>
                        </Flex>
                    </form>
                )}
            </Formik>
        </section>
    );
}
