import {Box} from '@chakra-ui/react';
import Heading from '../../components/v4/Heading';
import Text from '../../components/v4/Text';
import {guidErrorStyles, guidErrorHeadingStyles} from './guidErrorStyles';
import {logoStyles} from './partnerTrialStyles';
import {useSelector} from 'react-redux';
import config from '../../config.json';
import ImageLoader from '../../components/v4/ImageLoader';

export default function InvalidGuid() {
    const imagePathLightMode = useSelector(
        (state) => state.login.imagePathLightMode,
    );
    return (
        <Box sx={guidErrorStyles}>
            <ImageLoader
                image={config.imageBase + imagePathLightMode}
                objectProps={{
                    alt: 'Domain Logo',
                    style: logoStyles,
                    type: 'image/svg+xml',
                }}
                imageProps={{
                    sx: {
                        maxHeight: '300px',
                        maxWidth: '300px',
                        margin: '0 auto 25px auto',
                    },
                }}
            />
            <Heading as="h2" fontSize="2xl" sx={guidErrorHeadingStyles}>
                Invalid registration ID
            </Heading>
            <Text>
                The partner registration ID is not valid. Please contact
                sales@tcap.cloud for assistance.
            </Text>
        </Box>
    );
}
