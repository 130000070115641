import React from 'react';
import {
    Box,
    Heading,
    Stack,
    Divider,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Card } from 'components/v4/Card';
import {
    breadCrumbStyling,
    headingStyling,
    stackStyling,
    stackSpacing,
} from './formStyles';
import { useNavigate } from 'react-router-dom';

const FormBase = (props) => {
    const { darkMode } = useSelector((state) => state.settings);
    const navigate = useNavigate();
    return (
        <Box>
            <Breadcrumb sx={breadCrumbStyling}>
                {props.breadCrumbTrail?.map((crumb, index) => (
                    <BreadcrumbItem key={index}>
                        <BreadcrumbLink href={crumb.path}>
                            {crumb.label}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                ))}
            </Breadcrumb>
            <Heading sx={headingStyling}>{props.header}</Heading>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize
                onSubmit={(value) => props.finalFormSubmit(value)}
                validateOnChange>
                {({ errors, touched, handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            borderRadius={15}
                            padding={'0px 30px'}
                            maxWidth={895}
                            ml={0}
                            bg={darkMode ? 'dark.tcap' : null}>
                            <Stack sx={{ margin: '0 !important' }}>
                                {React.Children.map(
                                    props.children({ errors, touched, values }),
                                    (child, index) => (
                                        <React.Fragment key={index}>
                                            {child}
                                            {index <
                                                props.children({
                                                    errors,
                                                    touched,
                                                    values,
                                                }).length -
                                                    1 && (
                                                <Divider
                                                    borderColor="#F5F5F5"
                                                    borderWidth="1.4px"
                                                    sx={{
                                                        margin: '0 !important',
                                                    }}
                                                />
                                            )}
                                        </React.Fragment>
                                    ),
                                )}
                            </Stack>
                        </Card>
                        <Stack>
                            <Stack
                                direction={'row'}
                                spacing={stackSpacing}
                                sx={stackStyling()}>
                                <div
                                    className="edit-form-buttons"
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: 16,
                                        marginBottom: 10,
                                    }}>
                                    <Button
                                        type="submit"
                                        data-walkthroughid={props.ufSaveBtn}
                                        className="h-10 min-w-[5rem] font-semibold disabled:cursor-not-allowed"
                                        sx={{ width: 142 }}
                                        onClick={handleSubmit}>
                                        Save
                                    </Button>
                                    <div
                                        style={{
                                            marginLeft: 30,
                                            display: 'inline',
                                        }}>
                                        <Button
                                            className="secondary h-10 min-w-[5rem] font-semibold"
                                            variant="outline"
                                            sx={{ width: 142 }}
                                            data-walkthroughid={props.ufBackBtn}
                                            onClick={() =>
                                                navigate(props.backLink)
                                            }>
                                            Back
                                        </Button>
                                    </div>
                                </div>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default FormBase;
