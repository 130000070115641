import { Box, Progress, Text } from "@chakra-ui/react";
import { Alert, AlertDescription, AlertIcon } from "components/v4";
// import { Text } from "components/v4";

export default function SplitJoinStatus(props) {
  const {successCount, failedCount, totalCount} = props;

  return (
    <Box sx={{
        padding: '10px 0',
        maxWidth: '2xl',
        minWidth: 'xl',
        margin: '0 auto',
      }}>
      
        {(successCount + failedCount) >= totalCount ? (
          <>
          <Alert status="success" mt={5}>
              <AlertIcon />
              <AlertDescription>
              Completed
              </AlertDescription>
          </Alert>
          </>
        ) : (
          <Box
            mt={5}
            mb={10}
            shadow={'md'}
            rounded={'md'}
            bg={'blue.100'}
            padding={'10px'}>
            <Text color={'black'} size="md">{`Working on Request ${successCount + failedCount} of ${totalCount}`}</Text>
            <Progress
              rounded={'md'}
              mt={2}
              hasStripe
              isAnimated
              value={successCount + failedCount}
              max={totalCount} />
          </Box>
        )}

      <Box mt={5} padding={'10px'}>
          <Text sx={{color: 'green.500'}} size="md">
              Successful : {successCount}
          </Text>
          <Text sx={{color: 'red.500'}} mt={1} size="md">
              Failed : {failedCount}
          </Text>
      </Box>

      {(successCount + failedCount) !== totalCount && <Box mt={5} padding={'10px'}>
          <Text size="md">Do not close this window or navigate away. This may take a few minutes.</Text>
      </Box>}

    </Box>
  )

}
