import React, { useRef } from 'react';
import {TextField} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

import TableAndModal, {
    modalTitle,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';

// default columns for table
const defaultColumns = [
    {
        title: 'ID',
        field: 'id',
        classNameFn: (rowData) => {
            return 'red';
        },
    },
    {
        title: 'Name',
        field: 'name',
        defaultSort: 'asc',
    },
    {
        title: 'Last Heartbeat',
        field: 'lastActive',
        type: 'datetime',
    },
    {
        title: 'Last Process Identifier',
        field: 'lastProcessIdentifier',
    },
    {
        title: 'Last Process Type',
        field: 'lastProcessType',
    },
    {
        title: 'Details',
        field: 'details',
    },
    {
        title: 'Active',
        field: 'isActive',
        type: 'boolean',
        width: '5%',
    },
];

/*
 * Engine Component that presents the table and the Add/View functionality for the 'Engines' tab in the GA section of TCAP
 */
const Engines = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // modal State
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // field handlers
    const nameChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    // table and modal for engines
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/engines/'}
            modalFetch={'/engine/' + modalData?.id}
            modalAdd="/engine/"
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Engines"
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(modalData, 'name', ' Engine ', modalMode)}
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !newErrorState.name;
            }}
            modalInitState={{
                isActive: true,
            }}
            additionalActions={[
                (rowData) => ({
                    isFreeAction: false,
                    onClick: () =>
                        dispatch({
                            type: 'OPEN_MODAL',
                            payload: {
                                mode: 'View',
                                data: rowData,
                            },
                        }),
                    icon: () => <VisibilityRoundedIcon fontSize="small" />,
                    tooltip: 'View Engine',
                }),
            ]}
            modalContent={
                <>
                    <Row style={{minHeight: 60}}>
                        <Col>
                            <TextField
                                fullWidth
                                label="Name"
                                type="text"
                                value={modalState?.name}
                                onChange={nameChangeHandler}
                                disabled={modalMode !== 'Add'}
                            />
                        </Col>
                    </Row>
                    {modalMode === 'View' && (
                        <>
                            <Row style={{minHeight: 60}}>
                                <Col>
                                    <TextField
                                        fullWidth
                                        label="ID"
                                        type="text"
                                        value={modalState?.id}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row style={{minHeight: 60}}>
                                <Col>
                                    <TextField
                                        fullWidth
                                        label="Access Key"
                                        type="text"
                                        value={modalState?.accessKey}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            }
        />
    );
};

export default Engines;
