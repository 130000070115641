export default {
  modal: {
    ufModalProgress: 'modal/input/progress',
    ufModalCancelBtn: 'modal/button/cancel',
  },
  table: {
    ufAddActionBtn: 'table/button/addAction',
    ufDeleteActionBtn: 'table/button/deleteAction',
    ufEditActionBtn: 'table/button/editAction',
  }
}