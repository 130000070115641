import { useState } from 'react';
import {
    FormControl,
    Stack,
    FormLabel,
    Switch,
    Icon,
    Tooltip,
    Box,
    Text,
} from '@chakra-ui/react';
import { Button } from '@/components/v4';
import {
    formLabelStyling,
    stackStyling,
    descriptionStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';
import { FiLock } from 'react-icons/fi';

const AdminUsersResetMFA = ({
    adminUser,
    handlFormSwitchChange,
    ssoEnforced,
    resetMFA,
    darkMode,
    isCreate,
    disableMFA,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack direction={'row'} sx={stackStyling(true)}>
                <Box>
                    <FormLabel sx={formLabelStyling(darkMode)}>
                        Configure Multifactor Authentication
                    </FormLabel>
                    <Text sx={descriptionStyling} maxWidth={250}>
                        For security purposes, it is recommended MFA remains
                        enabled for all Admin Users.
                    </Text>
                </Box>
                <Stack direction={'row'} paddingTop="20px" spacing="56px">
                    <Stack direction={'row'} spacing={2}>
                        <Switch
                            name="mfaEnabled"
                            data-walkthroughid={
                                walkthroughIds.adminsGeneral.ufMfaSelect
                            }
                            paddingLeft={'66px'}
                            onChange={handlFormSwitchChange}
                            isChecked={adminUser?.mfaEnabled}
                            checked={adminUser?.mfaEnabled}
                            disabled={disableMFA}
                        />
                        <Text
                            fontSize="12px"
                            fontWeight={500}
                            color={darkMode ? 'white' : 'black'}>
                            {adminUser?.mfaEnabled ? 'On' : 'Off'}
                        </Text>
                    </Stack>
                    {isHovered && (
                        <Tooltip
                            hasArrow
                            label="Enables/Disables Multi-Factor Authentication"
                            aria-label="inputGroupSelect">
                            <Icon
                                color={darkMode ? 'white' : undefined}
                                position="relative"
                                bottom="2px"
                                marginLeft={'-14px !important'}
                                left="20px"
                            />
                        </Tooltip>
                    )}
                    {adminUser?.mfaEnabled && !isCreate && (
                        <Button
                            data-walkthroughid={
                                walkthroughIds.adminsGeneral.ufResetMfaButton
                            }
                            height={5}
                            leftIcon={
                                <FiLock
                                    fontSize={24}
                                    color={darkMode ? 'white' : 'black'}
                                />
                            }
                            variant="link"
                            colorScheme={
                                !adminUser.mfatotpConfirmed || ssoEnforced
                                    ? 'gray'
                                    : '#000000'
                            }
                            fontSize="12px"
                            disabled={
                                !adminUser.mfatotpConfirmed || ssoEnforced
                            }
                            onClick={resetMFA}>
                            Reset MFA
                        </Button>
                    )}
                </Stack>
            </Stack>
        </FormControl>
    );
};

export default AdminUsersResetMFA;
