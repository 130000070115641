import {axios} from '../../services/axios';

export default async function createMicrosoftAppAuth(params, scope) {
    return axios
        .post(`/AppAuth/Microsoft`, params, {
            headers: {
                'X-RequestScope': scope
            }
        })
        .then((res) => res)
        .catch((e) => e);
}
