import React, {useState, useEffect} from 'react';
import {Stage, Layer} from 'react-konva';
import {useRef} from 'react';

import Picture from './Picture';
import Button from './Button';

/*
 * Picture Viewer component using react-konva
 */
const PictureViewer = ({
    imageURL = 'https://teamsdev.pingco.com.au/images/uploads/0%20-%20Basic%20with%20queue@2x.png',
}) => {
    const [scale, setScale] = useState(100);
    const stageRef = useRef(null);

    const size = {
        width: window.innerWidth - 318,
        height: window.innerHeight * 0.5,
    };

    const initialImageScale = 0.4;
    const buttonStep = 0.9; //(must be between 0 to 1)
    const wheelStep = 0.9; //the bigger it is, the slower you zoom

    let stageWidth = size.width % 2 !== 0 ? size.width - 1 : size.width;
    let stageHeight = size.height % 2 !== 0 ? size.height - 1 : size.height;

    function zoomStage(event, scaleBy) {
        event.evt.preventDefault();
        if (stageRef.current !== null) {
            const stage = stageRef.current;
            const oldScale = stage.scaleX();
            const {x: pointerX, y: pointerY} = stage.getPointerPosition();
            const mousePointTo = {
                x: (pointerX - stage.x()) / oldScale,
                y: (pointerY - stage.y()) / oldScale,
            };
            const newScale =
                event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
            stage.scale({x: newScale, y: newScale});
            const newPos = {
                x: pointerX - mousePointTo.x * newScale,
                y: pointerY - mousePointTo.y * newScale,
            };
            stage.position(newPos);
            stage.batchDraw();
            setScale(Math.round(newScale * 100));
        }
    }

    function zoom(event, scaleBy) {
        event.preventDefault();
        if (stageRef.current !== null) {
            const stage = stageRef.current;
            const oldScale = stage.scaleX();
            var center = {
                x: stage.width() / 2,
                y: stage.height() / 2,
            };

            var relatedTo = {
                x: (center.x - stage.x()) / oldScale,
                y: (center.y - stage.y()) / oldScale,
            };

            const newScale =
                event.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
            stage.scale({x: newScale, y: newScale});
            const newPos = {
                x: center.x - relatedTo.x * newScale,
                y: center.y - relatedTo.y * newScale,
            };
            stage.position(newPos);
            stage.batchDraw();
            setScale(Math.round((newScale ?? 1) * 100));
        }
    }

    function reset(event) {
        if (event) {
            event.preventDefault();
        }

        if (stageRef.current !== null) {
            const stage = stageRef.current;

            stage.scale({x: 1, y: 1});
            const newPos = {
                x: 0,
                y: 0,
            };
            stage.position(newPos);
            stage.batchDraw();
            setScale(100);
        }
    }

    const onAdd = (event) => {
        zoom(event, buttonStep);
    };

    const onSubtract = (event) => {
        zoom(event, 1 / buttonStep);
    };
    const onReset = (event) => {
        reset(event);
    };

    const onWheel = (event) => {
        zoomStage(event, wheelStep);
    };

    useEffect(() => {
        onReset();
    }, [imageURL]);

    return (
        <div id="stage-parent" style={{position: 'relative'}}>
            <Button
                onAdd={onAdd}
                onSubtract={onSubtract}
                onReset={onReset}
                text={Math.round(scale * initialImageScale) + '%'}
            />
            <Stage
                style={{textAlign: 'center'}}
                width={stageWidth}
                height={stageHeight}
                onWheel={onWheel}
                draggable
                ref={stageRef}
                className="img">
                <Layer x={stageWidth / 2} y={0}>
                    <Picture
                        initialScale={initialImageScale}
                        imageURL={imageURL}
                    />
                </Layer>
            </Stage>
        </div>
    );
};

export default PictureViewer;
