import {
    APP_AUTH,
    changed_status,
    DIRECT_ROUTING,
    initServiceData,
    loaded_status,
    menuAppAuthMapping,
    OPERATOR_CONNECT,
    statusDisplayExtendMapping,
} from './constants';

/**
 * @param {Object} state
 * @param {CardStatusType} state.cardStatus
 * @param {{type: string; payload: {[k: string]: any; statusDisplay: {statusDisplayLevel: number}}}} action
 * */

function getStatusLabel(
    statusOptions,
    status,
    statusDisplay,
    serviceType,
    isCallingDisabled,
    isServiceAccountError,
    appAuthService,
) {
    const statusDisplayLevel = statusDisplay?.statusDisplayLevel;
    const statusDisplayDetail = statusDisplay?.statusDisplayDetail;

    const lastModifiedTS = appAuthService?.lastModifiedTS;
    // Has is been less than 5 mins since creating the auth?
    let isAuthlessThanFive = false;
    if (new Date() - new Date(lastModifiedTS + 'Z') < 5 * 60 * 1000) {
        isAuthlessThanFive = true;
    }

    if (!statusOptions) {
        return null;
    }

    if (serviceType === APP_AUTH && status === 5) {
        return 'Reinitializing';
    }

    if (isCallingDisabled) {
        return 'Calling Disabled';
    }

    if (serviceType === APP_AUTH) {
        if (isServiceAccountError && !isCallingDisabled) {
            return 'Service Account Error';
        }
        if (status === 20 || status === 21) {
            return statusDisplayLevel === 2 ? 'Error' : 'Degraded';
        }
    }

    if (serviceType === DIRECT_ROUTING) {
        if (status === 2) {
            if (appAuthService?.graphAuth) {
                if (!appAuthService?.company?.approved) {
                    return 'Approval Required';
                } else {
                    if (isAuthlessThanFive) {
                        return 'Confirming Access';
                    } else {
                        return 'Access Required';
                    }
                }
            } else {
                return 'Access Required';
            }
        }

        if (status === 4) {
            return 'Initializing';
        }

        if (status === 10) {
            if (statusDisplayDetail?.includes('Missing Regions')) {
                return 'Region not set';
            }
            return 'Provisioning DNS';
        }

        if (status === 11 || status === 12 || status === 13) {
            if (statusDisplayDetail?.includes('Missing Voice Policies')) {
                return 'No Voice Policies';
            } else if (statusDisplayDetail?.includes('Missing Dial Plans')) {
                return 'No Dial Plans';
            } else if (status === 11) {
                return 'Provisioning SBC';
            } else if (status === 12) {
                return 'Activating Teams Phone';
            }
            return 'Provisioning Teams Phone';
        }
    }

    return statusOptions.statusLabels[0];
}

function getStatus(
    statusOptions,
    status,
    statusDisplay,
    statusLabel,
    serviceType,
    appAuthService,
    service,
) {
    if (!statusOptions) {
        return null;
    }

    if (
        serviceType === APP_AUTH &&
        appAuthService?.serviceAccountMessage &&
        !appAuthService?.linkedServicesDisabled
    ) {
        return {
            statusLabel: 'Service Account Error',
            ...initServiceData.services[APP_AUTH]['statusId']['-1'][
                'Service Account Error'
            ],
        };
    }

    if (
        serviceType === APP_AUTH &&
        status === 20 &&
        statusLabel === 'Degraded'
    ) {
        return {
            statusLabel: 'Degraded',
            ...statusOptions['Degraded'],
            ...statusDisplayExtendMapping[statusDisplay?.statusDisplayExtended],
        };
    }

    if (
        serviceType === APP_AUTH &&
        !appAuthService?.userSync &&
        appAuthService?.tenantID
    ) {
        if (
            service[OPERATOR_CONNECT] &&
            Object.keys(service[DIRECT_ROUTING]).length === 0
        ) {
            return {
                statusLabel: 'Healthy',
                ...initServiceData.services[APP_AUTH]['statusId'][0]['Healthy'],
            };
        }
    }

    if (
        serviceType === OPERATOR_CONNECT &&
        status === 0 &&
        statusLabel === 'Healthy'
    ) {
        return {
            statusLabel: 'Healthy',
            ...statusOptions['Healthy'],
            ...menuAppAuthMapping[appAuthService ? 'HasAppAuth' : 'NoAppAuth'],
        };
    }

    return {
        statusLabel,
        ...statusOptions[statusLabel],
    };
}

export const statusReducer = (state, action) => {
    const { serviceType } = action.payload;
    switch (action.type) {
        case `${serviceType}/${loaded_status}`: {
            const { service } = action.payload;
            if (!service) return state;
            const { status, statusDisplay } = service;
            const serviceStatuses = initServiceData.services[serviceType];
            const isCallingDisabled =
                state.service[APP_AUTH]?.linkedServicesDisabled;
            const isServiceAccountError =
                state.service[APP_AUTH]?.serviceAccountMessage;

            const serviceStatus = isCallingDisabled ? -1 : status;
            const statusOptions = serviceStatuses['statusId'][serviceStatus];

            const appAuthService = state.service[APP_AUTH];

            const statusLabel = getStatusLabel(
                statusOptions,
                status,
                statusDisplay,
                serviceType,
                isCallingDisabled,
                isServiceAccountError,
                appAuthService,
            );
            const cardStatus = getStatus(
                statusOptions,
                status,
                statusDisplay,
                statusLabel,
                serviceType,
                appAuthService,
                state?.service,
            );

            return {
                ...state,
                [serviceType]: {
                    ...state[serviceType],
                    status,
                    statusDisplay,
                    ...cardStatus,
                },
                service: {
                    ...state.service,
                    [serviceType]: service,
                },
            };
        }

        case changed_status: {
            return {};
        }
        default:
            throw new Error('Unknown action: ', +action.type);
    }
};
