//SIDEBAR reducer
//TODO: refactor

const initMenuState = {
    menu: [],
    error: null,
    collapse: false,
};
export const menuReducer = (state = initMenuState, action) => {
    switch (action.type) {
        case 'MENU_UNAUTHORISED_SUCCESS':
            return {
                ...state,
                unauthorised: action.payload, //  unauthorised area
            };
        case 'ACCESS_LIST_SUCCESS':
            return {
                ...state,
                accessList: action.payload, //backend's access list retrieved
            };
        case 'RESET_MENU':
            return { ...initMenuState };
        case 'CHANGE_MENU_COLLAPSE':
            return {
                ...state,
                collapse: action.payload,
            };
        case 'CHANGE_PARENT_PATH':
            return {
                ...state,
                ...action.payload,
            };
        case 'LOGOUT':
            return { ...initMenuState };
        case 'CLEAR_TOKEN':
            return {
                menu: [],
                error: null,
            };
        default:
            return state;
    }
};
