import { useState } from 'react';

import { ADDRESS_SCHEMA } from './consts';

export default function useAddress(initialAddressFields = ADDRESS_SCHEMA) {
    const [addressFields, setAddressFields] = useState(initialAddressFields);

    function reset() {
        setAddressFields(ADDRESS_SCHEMA);
    }

    function setAddressField(field, value) {
        setAddressFields((p) => ({ ...p, [field]: value }));
    }

    function transformAddress(data, countries) {
        // reset all fields after each search
        reset();

        // Set Google places ID
        setAddressField('googlePlaceID', data.place_id);

        // Set description
        setAddressField('description', data.formatted_address);

        // Pull the easy stuff
        data.address_components.map((addressComponent) => {
            const { types } = addressComponent;

            // Street Number
            if (types.includes('street_number')) {
                setAddressField(
                    'streetHouseNumber1',
                    addressComponent.long_name,
                );
            }

            // Street Name 1
            if (types.includes('route')) {
                setAddressField(
                    'streetName1',
                    addressComponent.long_name
                        .split(' ')
                        .slice(0, -1)
                        .toString()
                        .replace(',', ' '), // Remove the last word
                );

                // Street type 1
                setAddressField(
                    'streetType1',
                    addressComponent.long_name.split(' ').pop(), // Last word of street name 1
                );
            }

            // Locality
            if (types.includes('locality')) {
                setAddressField('locality', addressComponent.long_name);
            }
            // District
            if (types.includes('administrative_area_level_2')) {
                setAddressField('district', addressComponent.long_name);
            }
            // State
            if (types.includes('administrative_area_level_1')) {
                setAddressField('state', addressComponent.long_name);
            }
            // Post code
            if (types.includes('postal_code')) {
                setAddressField('postcode', addressComponent.long_name);
            }
            // Country
            if (types.includes('country')) {
                const country = countries?.find(
                    (a) =>
                        a.name.toLowerCase() ===
                        addressComponent.long_name.toLowerCase(),
                );
                if (country) {
                    setAddressField('countryID', country.id);
                }
            }
        });
    }

    function concatAddressFields(address) {
        // Merge with default, so every prop has a "" fallback
        const addressMerged = Object.assign({}, ADDRESS_SCHEMA, address);

        // Units 6A - 8C
        function concatRowOne(_address) {
            const {
                buildingType, // Units
                building1stNumber, // 6
                building1stSuffix, // A
                building2ndNumber, // 8
                building2ndSuffix, // C
            } = _address;

            const blockOne = [building1stNumber, building1stSuffix]
                .toString()
                .replaceAll(',', '');
            const blockTwo = [building2ndNumber, building2ndSuffix]
                .toString()
                .replaceAll(',', '');

            return [buildingType, blockOne, blockTwo]
                .filter(Boolean)
                .toString()
                .replaceAll(',', ' ');
        }

        // Floor 3A, Treasury Building Rear
        function concatRowTwo(_address) {
            const {
                buildingFloorType, // Floor
                buildingFloorNumber, // 3
                buildingFloorNumberSuffix, // A
                buildingProperty, //Treasury Building
                buildingLocation, // Rear
            } = _address;

            const blockOne = [buildingFloorNumber, buildingFloorNumberSuffix]
                .toString()
                .replaceAll(',', '');

            return [
                buildingFloorType,
                blockOne,
                buildingProperty,
                buildingLocation,
            ]
                .filter(Boolean)
                .toString()
                .replaceAll(',', ' ');
        }

        // 15A - 19C,Church Street South
        function concatRowThree(_address) {
            const {
                streetHouseNumber1, // 15
                streetHouseNumber1Suffix, // A
                streetHouseNumber2, // 19
                streetHouseNumber2Suffix, // C
                streetName1, // Church
                streetType1, // Street
                streetSuffix1, // South
            } = _address;

            const blockOne = [streetHouseNumber1, streetHouseNumber1Suffix]
                .toString()
                .replaceAll(',', '');

            const blockTwo = [streetHouseNumber2, streetHouseNumber2Suffix]
                .toString()
                .replaceAll(',', '');

            return [blockOne, blockTwo, streetName1, streetType1, streetSuffix1]
                .filter(Boolean)
                .toString()
                .replaceAll(',', ' ');
        }

        // Ashburton
        function concatRowFour(_address) {
            const { locality } = _address;
            return [locality].filter(Boolean).toString().replaceAll(',', ' ');
        }

        // Vic 3147
        function concatRowFive(_address) {
            const { state, postcode } = _address;
            return [state, postcode]
                .filter(Boolean)
                .toString()
                .replaceAll(',', ' ');
        }

        const row1 = concatRowOne(addressMerged);
        const row2 = concatRowTwo(addressMerged);
        const row3 = concatRowThree(addressMerged);
        const row4 = concatRowFour(addressMerged);
        const row5 = concatRowFive(addressMerged);

        let description = '';

        if (row1) description += row1 + ', ';
        if (row2) description += row2 + ', ';
        if (row3) description += row3 + ', ';
        if (row4) description += row4 + ', ';
        if (row5) description += row5;

        return description;
    }

    return {
        addressFields,
        setAddressField,
        transformAddress,
        concatAddressFields,
    };
}
