import { Heading } from '@chakra-ui/react';

export default function ChakraHeading({
    children,
    fontSize,
    fontWeight,
    mb,
    lineHeight,
    fontFamily,
    as,
    size,
    ...rest
}) {
    return (
        <Heading
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            mb={mb}
            lineHeight={lineHeight}
            as={as}
            size={size}
            {...rest}>
            {children}
        </Heading>
    );
}
