import { useQuery } from '@tanstack/react-query';

import getCompanyServices from 'API/getCompanySevices';

/**
 * @typedef {Object} Service
 * @property {string} id
 * @property {string} name
 **/

/**
 * @typedef {Object} UseDashboardServicesParams
 * @property {string} currentCompany - The current company identifier
 * @property {string[]} [queryKey] - Optional query key for the useQuery hook
 * @property {Omit<import('@tanstack/react-query').UseQueryOptions<any, unknown, any, string[]>, 'queryKey' | 'queryFn'>} [options] - Optional react-query options
 **/

/**
 * Custom hook to fetch company services with react-query.
 * @param {UseDashboardServicesParams} params - Parameters for the hook
 * @returns {import('@tanstack/react-query').UseQueryResult<Service[], unknown>} The result of the react-query hook
 **/
function useDashboardServices({
    currentCompany,
    queryKey = ['services', `company: ${currentCompany}`],
    options = {},
}) {
    return useQuery(
        queryKey,
        async () => {
            const res = await getCompanyServices(currentCompany);
            if (res.status !== 200) {
                throw new Error(
                    res.message || 'Error fetching company services',
                );
            }
            return res.data;
        },
        {
            ...options,
        },
    );
}
export default useDashboardServices;
