// external imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { NonNegativeInteger } from 'components/maintenance/NumberFormatCustom';
import { isEmpty } from 'lodash';

/*
 * Edit Page for Trunk Types in the GA tab of TCAP
 */
const TrunkEdit = (props) => {
    const dispatch = useDispatch();

    // state access
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return {
                ...state.modal,
            };
        },
    );

    // change handler for form inputs
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    // form return
    return (
        <>
            <Row style={{ minHeight: 80, paddingTop: 10 }}>
                <Col>
                    <TextField
                        fullWidth
                        id="Name"
                        label="Name"
                        type="text"
                        value={modalState?.name}
                        error={Boolean(modalStateError?.name)}
                        helperText={modalStateError?.name}
                        onChange={(e) => {
                            setState({
                                name: e.target.value,
                            });
                        }}
                    />
                </Col>
                <Col>
                    <TextField
                        fullWidth
                        id="Header Value"
                        label="Header Value"
                        type="text"
                        error={Boolean(modalStateError?.headerValue)}
                        helperText={modalStateError?.headerValue}
                        value={modalState?.headerValue}
                        onChange={(e) => {
                            setState({
                                headerValue: e.target.value,
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ minHeight: 80, paddingTop: 10 }}>
                <Col>
                    <TextField
                        fullWidth
                        id="Minimum Channels"
                        label="Minimum Channels"
                        value={modalState?.minChannels}
                        error={Boolean(modalStateError?.minChannels)}
                        helperText={modalStateError?.minChannels}
                        onChange={(e) => {
                            setState({
                                minChannels: e.target.value,
                            });
                        }}
                        InputProps={{
                            inputComponent: NonNegativeInteger,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Col>
                <Col>
                    <TextField
                        fullWidth
                        id="Increment Size"
                        label="Increment Size"
                        value={modalState?.incSize}
                        error={Boolean(modalStateError?.incSize)}
                        helperText={modalStateError?.incSize}
                        onChange={(e) => {
                            setState({
                                incSize:
                                    e.target.value != 0 ||
                                    isEmpty(e.target.value)
                                        ? e.target.value
                                        : 1,
                            });
                        }}
                        InputProps={{
                            inputComponent: NonNegativeInteger,
                            inputProps: { min: 1 },
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <FormControlLabel
                        label="Teams Enabled"
                        control={
                            <Checkbox
                                size="small"
                                checked={modalState?.teamsEnabled}
                                onChange={(e) => {
                                    setState({
                                        teamsEnabled: e.target.checked,
                                    });
                                }}
                            />
                        }
                    />
                </Col>
                <Col style={{ textAlign: 'center' }}>
                    <FormControlLabel
                        label="SIP Enabled"
                        control={
                            <Checkbox
                                size="small"
                                checked={modalState?.sipEnabled}
                                onChange={(e) => {
                                    setState({
                                        sipEnabled: e.target.checked,
                                    });
                                }}
                            />
                        }
                    />
                </Col>
                <Col style={{ textAlign: 'center' }}>
                    <FormControlLabel
                        label="Shared"
                        control={
                            <Checkbox
                                size="small"
                                checked={modalState?.shared}
                                onChange={(e) => {
                                    setState({
                                        shared: e.target.checked,
                                    });
                                }}
                            />
                        }
                    />
                </Col>
            </Row>
        </>
    );
};

export default TrunkEdit;
