import { FormLabel, forwardRef } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 * @typedef {import('@chakra-ui/react').FormLabelProps} LabelProps
 * @type React.ForwardRefRenderFunction<HTMLLabelElement, LabelProps>
 * @returns {JSX.Element}
 */
export default forwardRef(function ChakraInput(props, ref) {
    const { darkMode } = useSelector((state) => state.settings);
    const textColor = darkMode ? 'white' : 'black';
    return (
        <FormLabel ref={ref} color={textColor} {...props}>
            {props.children}
        </FormLabel>
    );
});
