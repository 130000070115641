import React, { useEffect, useState } from 'react';
import {
    TextField,
    Box,
    Grid,
    MenuItem,
    Alert,
    AlertTitle,
} from '@mui/material';
import { Error, LoadingFieldDropdown, TabView } from '../../../../components';
import { CircularProgress, Typography } from '@material-ui/core';

import { Button, Container, Form, Modal } from 'react-bootstrap';
import { axios } from '@/services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { NonNegativeInteger } from 'components/maintenance/NumberFormatCustom';
import EditSipAudioCodecs from './EditSipAudioCodecs';
import EditSipSbc from './EditSipSbc';

const EditSipGeneral = ({ trunkValid, warning, setWarning }) => {
    const { currentCompany: currCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const dispatch = useDispatch();
    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });
    const { darkMode } = useSelector((state) => state.settings);
    const inputDarkStyle = {
        label: {
            color: darkMode ? '#FFFFFF80' : null,
        },
        '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: darkMode ? '#FFFFFF' : null,
        },
        fieldset: {
            borderColor: darkMode ? '#545454' : null,
            color: darkMode ? '#FFFFFF80' : null,
        },

        '.MuiSelect-nativeInput, input': {
            color: darkMode ? '#FFFFFF' : null,
            '-webkit-text-fill-color': darkMode ? 'white' : 'inherit',
        },
        '.sip-trunk-disabled': {
            label: {
                color: darkMode ? '#FFFFFF80' : null,
            },
        },
        '& .Mui-disabled': {
            '-webkit-text-fill-color': darkMode ? '#FFFFFF80 !important' : null,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            '& > fieldset': {
                borderColor: darkMode ? '#545454' : null,
            },
        },
        '& .MuiFormHelperText-root': {
            color: darkMode ? '#FFFFFF80' : null,
        },
        '.MuiSvgIcon-root': {
            color: darkMode ? '#FFFFFF' : null,
        },
    };
    const [availableRegions, setAvailableRegions] = useState();
    const [minChannels, setMinChannels] = useState(0);
    const [incSize, setIncSize] = useState(1);
    const [trunkCapacity, setTrunkCapacity] = useState(0);
    const [nCapacity, setNCapacity] = useState(0);
    const redundancy = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
    ];

    // Checks if the current company has SBC as a service as a service type when component renders
    useEffect(() => {
        if (!availableRegions?.length > 0) {
            axios.get(`/services/company/${currCompany}`).then((res) => {
                res.data.forEach((service) => {
                    if (service.serviceType === 'SBCaaS') {
                        axios.get(`/Service/${service.id}`).then((response) => {
                            setAvailableRegions(response.data.regions);
                        });
                    }
                });
            });
        }
    }, [currCompany]);

    // trunk capacity and ncapacity calculate
    useEffect(() => {
        if (state?.redundancyLevel && state?.channels) {
            setTrunkCapacity(state.redundancyLevel * state.channels);
            setNCapacity((state.redundancyLevel - 1) * state.channels);
        }
    }, [state?.redundancyLevel, state?.channels]);

    // inc size and min channels
    useEffect(() => {
        if (state?.trunkType?.incSize && state?.trunkType?.minChannels) {
            setIncSize(state?.trunkType?.incSize);
            setMinChannels(state?.trunkType?.minChannels);
        }
    }, [state?.trunkType?.incSize, state?.trunkType?.minChannels]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
            }}>
            {!trunkValid && (
                <Alert
                    severity="warning"
                    variant="filled"
                    style={{ marginTop: 5, marginBottom: 15 }}>
                    <AlertTitle>Incomplete Trunk</AlertTitle>
                    You must add Collection Ports and Termination IPs before
                    trunk is operational.
                </Alert>
            )}
            {/* Grid with the fields required to create or edit a trunk */}
            {state && (
                <Grid container spacing={2} className={'justify-start'}>
                    {/* Trunk Type */}
                    <Grid
                        item
                        xs={6}
                        className="sip-trunk-disabled"
                        sx={inputDarkStyle}>
                        <TextField
                            sx={{
                                paddingTop: 0.6,
                                input: {
                                    cursor: 'not-allowed',
                                },
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Trunk Type"
                            size="small"
                            value={state?.trunkType?.name}
                            disabled
                        />
                    </Grid>

                    {/* Regions */}
                    <Grid
                        item
                        xs={6}
                        className="sip-trunk-disabled"
                        sx={inputDarkStyle}>
                        <TextField
                            sx={{
                                paddingTop: 0.6,
                                input: {
                                    cursor: 'not-allowed',
                                },
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Region"
                            size="small"
                            value={
                                availableRegions?.find(
                                    (a) => a.id == state?.regionID,
                                )?.name
                            }
                            disabled
                        />
                    </Grid>

                    {/* Trunk Name */}
                    <Grid item xs={3} sx={inputDarkStyle}>
                        <TextField
                            sx={{
                                paddingTop: 0.6,
                            }}
                            fullWidth
                            id="TrunkName"
                            InputLabelProps={{ shrink: true }}
                            label="Trunk Name"
                            size="small"
                            value={state.name}
                            onChange={(e) => {
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        name: e.target.value,
                                    },
                                });
                            }}
                        />
                    </Grid>

                    {/* Channels */}
                    <Grid item xs={3} sx={inputDarkStyle}>
                        <TextField
                            sx={{
                                paddingTop: 0.6,
                            }}
                            fullWidth
                            id="Channels"
                            InputLabelProps={{ shrink: true }}
                            label="Channels"
                            size="small"
                            value={state?.channels}
                            onChange={(e) => {
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        channels: e.target.value,
                                    },
                                });
                            }}
                            InputProps={{
                                inputComponent: NonNegativeInteger,
                            }}
                            helperText={`Minimum channels is ${minChannels} in increments of ${incSize}`}
                        />
                    </Grid>

                    {/* Redundancy Level */}
                    <Grid
                        item
                        xs={3}
                        sx={{
                            ...inputDarkStyle,
                            'div.MuiInputBase-input.MuiOutlinedInput-input': {
                                color: darkMode
                                    ? warning
                                        ? 'red'
                                        : '#FFFFFF'
                                    : null,
                            },
                        }}>
                        <TextField
                            sx={{
                                paddingTop: 0.6,
                            }}
                            fullWidth
                            id="Redundancy"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: darkMode
                                                ? '#545454'
                                                : null,
                                            color: darkMode
                                                ? 'white'
                                                : 'inherit',
                                            '& .Mui-selected': {
                                                backgroundColor:
                                                    'var(--chakra-colors-brand-500)',
                                            },
                                        },
                                    },
                                },
                            }}
                            select
                            color={warning && 'warning'}
                            focused
                            label="Redundancy Level"
                            size="small"
                            value={state.redundancyLevel}
                            onChange={(e) => {
                                setWarning(true);
                                dispatch({
                                    type: 'CHANGE_MODAL_STATE',
                                    payload: {
                                        redundancyLevel: e.target.value,
                                    },
                                });
                            }}
                            FormHelperTextProps={
                                warning && {
                                    style: {
                                        color: '#f57c00',
                                    },
                                }
                            }
                            helperText={
                                warning
                                    ? 'Changing redundancy level will cause trunk to be re-provisioned causing all current sessions to drop'
                                    : ''
                            }>
                            {redundancy.map((option) => (
                                <MenuItem
                                    key={option.label}
                                    value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* Grid with the display of the capacitiy and n-1 capacity that is updated based on state */}
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                display: 'flex',
                            }}>
                            <Typography>
                                <Box>Total Trunk Capacity: {trunkCapacity}</Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}>
                                    n-1 Capacity: {nCapacity}
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <EditSipSbc trunkValid={trunkValid} />
                    </Grid>
                    <Grid item xs={6}>
                        <EditSipAudioCodecs />
                    </Grid>
                </Grid>
            )}
        </Form>
    );
};

export default EditSipGeneral;
