import React from 'react';
import {Image} from 'react-konva';
import useImage from 'use-image';

/*
 * A component to present the picture from the react-konva docs
 */
const Picture = ({initialScale, imageURL}) => {
    const [image] = useImage(imageURL);
    return (
        <Image
            offsetX={(initialScale * image?.width) / 2}
            style={{cursor: 'grab'}}
            image={image}
            scale={initialScale}
            width={initialScale * image?.width}
            height={initialScale * image?.height}
        />
    );
};

export default Picture;
