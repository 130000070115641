import { Icon, Tooltip, IconButton, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
    FaCircleCheck,
    FaCircleQuestion,
    FaCircleXmark,
} from 'react-icons/fa6';
import { MdOutlineDelete } from 'react-icons/md';
import { actionButtons } from './helper';
import { AddIcon } from '@chakra-ui/icons';

const StatusMap = {
    IDLE: {
        ariaLabel: 'IDLE',
        statusColor: 'transparent',
        statusIcon: null,
    },
    LOADING: {
        ariaLabel: 'LOADING',
        statusColor: 'gray.500',
        statusIcon: FaCircleQuestion,
        pulseClass: 'animate-pulse',
    },
    PASS: {
        ariaLabel: 'PASS',
        statusColor: 'green.500',
        statusIcon: FaCircleCheck,
    },
    FAIL: {
        ariaLabel: 'FAIL',
        statusColor: 'red.500',
        statusIcon: FaCircleXmark,
    },
};

function Status({
    info,
    verifiedStatus,
    type,
    row,
    table,
    column,
    addRow = true,
}) {
    const value = info.getValue();
    const isUnassign = info.row.original?.action === 'UNASSIGN';
    const isPass = value === 'PASS';
    const isIdle = value === 'IDLE' || value === '';
    const isLoading = verifiedStatus === 'loading';
    let status = StatusMap.IDLE;

    const { setFocusedRow, deleteRow, updateData } = table.options.meta;
    const { darkMode } = useSelector((state) => state.settings);

    if (isLoading) {
        status = StatusMap.LOADING;
    } else if (isPass) {
        status = StatusMap.PASS;
    } else if (!isIdle) {
        status = StatusMap.FAIL;
    }

    const shouldShowAddButton =
        (value || row.index === 0) &&
        row.index === table.options.data.length - 1;

    const addNewRow = () => {
        updateData(row.index, column.id, value, addRow);
        setFocusedRow((fr) => fr + 1);
    };
    return (
        <HStack>
            <Icon
                bg="transparent"
                boxSize="18px"
                ml={3}
                className={status.pulseClass || ''}
                aria-label={status.ariaLabel}
                color={status.statusColor}
                as={status.statusIcon}
            />
            {type === 'deactivate' && isUnassign && (
                <span className="ml-2">Assigned to User</span>
            )}
            <HStack justifyContent="center" flex="1">
                {shouldShowAddButton ? (
                    <Tooltip label={actionButtons[0].label} hasArrow>
                        <IconButton
                            alignItems="center"
                            justifyContent="center"
                            id={`${actionButtons[0]['aria-label']}-${row.index}`}
                            icon={value && <AddIcon size={18} />}
                            rounded="full"
                            variant="ghost"
                            isDisabled={!value}
                            height={8}
                            minW={8}
                            _hover={{
                                bg: darkMode ? 'dark.hoverGray' : null,
                                color: 'brand.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                            }}
                            onClick={() => {
                                addNewRow();
                            }}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip label={actionButtons[1].label} hasArrow>
                        <IconButton
                            alignItems="center"
                            justifyContent="center"
                            id={`${actionButtons[1]['aria-label']}-${row.index}`}
                            icon={<MdOutlineDelete size={18} />}
                            rounded="full"
                            variant="ghost"
                            height={8}
                            minW={8}
                            _hover={{
                                bg: darkMode ? 'dark.hoverGray' : null,
                                color: 'red.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                            }}
                            onClick={() => {
                                const newIndex =
                                    row.index > 0 ? row.index - 1 : 0;
                                deleteRow(row.index);
                                setFocusedRow(newIndex);
                            }}
                        />
                    </Tooltip>
                )}
            </HStack>
        </HStack>
    );
}

export default Status;
