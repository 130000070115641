import { axios } from '../../services/axios';

export default async function getACSProfile({ scope, level }) {
    try {
        const result = await axios.get(
            `/serviceprofile/ACSDirectRouting/${level}`,
            {
                headers: {
                    'X-RequestScope': scope,
                },
            },
        );
        return result;
    } catch (e) {
        throw e;
    }
}
