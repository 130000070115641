import { useQuery, useMutation } from '@tanstack/react-query';
import {
    getAllAdminUsers,
    getAdminPermission,
    createAdminUser,
    getAdminUser,
    deleteAdminUser,
    changeAdminUser,
    postForgotPassword,
    postMfaReset,
    getSSO,
} from 'API/AdminUser';
import { ACTIONS } from '../constants';
import { toast } from 'react-toastify';

export const useGetAllAdminUsers = (currentCompany, setTableData, dispatch) => {
    return useQuery({
        queryKey: ['adminUsers', currentCompany],
        queryFn: async () => {
            const data = await getAllAdminUsers(currentCompany);
            return data;
        },
        onSuccess: async (data) => {
            const structuredData = data.map((item) => {
                return {
                    ...item,
                    fullName: `${item.firstName} ${item.lastName}`,
                };
            });

            setTableData((prev) => ({
                ...prev,
                data: structuredData,
            }));
            dispatch({
                type: 'CHANGE_TABLE_LOADING',
            });
        },
        refetchOnWindowFocus: false,
    });
};

export const useGetSSO = (currentCompany, setSSOEnforced) => {
    return useQuery({
        queryKey: ['sso', currentCompany],
        queryFn: () => getSSO(currentCompany),
        enabled: Boolean(currentCompany),
        onSuccess: (data) => {
            setSSOEnforced(data?.isEnforced);
        },
        refetchOnWindowFocus: false,
    });
};

export const useGetAdminUserById = (
    adminId,
    token,
    setAdminUser,
    setOldAdmin,
) => {
    return useQuery({
        queryKey: ['admin', { adminId }],
        queryFn: () => getAdminUser({ token, id: adminId }),
        enabled: Boolean(adminId),
        onError: (error) => {
            console.log('error now: ', error.message);
            throw new Error(error);
        },
        onSuccess: (data) => {
            setAdminUser(data);
            setOldAdmin(data);
        },
        refetchOnWindowFocus: false,
    });
};

export const useGetAdminPermission = (currentCompany, accountScope) => {
    return useQuery({
        queryKey: ['permissionSets', currentCompany],
        queryFn: () => getAdminPermission({ accountScope, currentCompany }),
        enabled: Boolean(currentCompany),
        onSuccess: (data) => {
            return data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        },
        refetchOnWindowFocus: false,
    });
};

export const useCreateNewAdminUser = (
    adminUser,
    navigate,
    resetPassword,
    currentCompany,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                createAdminUser(adminUser)
                    .then((res) => {
                        getSSO(currentCompany).then((data) => {
                            if (!data?.isEnforced) {
                                resetPassword();
                            }
                        });

                        toast.success('Account Create Success!');
                        navigate('/admins');
                        return res;
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.message ||
                                'Failed to create account',
                        );
                        throw new Error(
                            error.response?.data?.message ||
                                'Failed to create account',
                        );
                    });
            } catch (error) {
                toast.error(error.message);
                throw new Error(error?.message || 'Failed to create account');
            }
        },
    });
};

export const useEditAdminUser = (
    data,
    accountScope,
    oldAdmin,
    email,
    dispatch,
    navigate,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                changeAdminUser({
                    formData: data,
                    scope: accountScope,
                });
            } catch (error) {
                toast.error(error?.message || 'Failed to update account');
            }
        },
        onError: (error) => {
            toast.error(error?.message || 'Failed to update account');
        },
        onSuccess: () => {
            if (
                data.mfaEnabled &&
                !oldAdmin.mfaEnabled &&
                email === data.email
            ) {
                sessionStorage.clear();
                localStorage.clear();
                dispatch({ type: ACTIONS.LOGOUT });
                dispatch({ type: ACTIONS.CLEAR_TOKEN });
                navigate('/');
            }
            navigate('/admins');
            dispatch({ type: 'RESET_MODAL' });
            dispatch({ type: 'RESET_TABLE' });

            toast.success('Account Update Success!');
        },
    });
};

export const useResetMfa = (adminId, isEditingThemself, dispatch, navigate) => {
    return useMutation({
        mutationFn: async () => {
            try {
                postMfaReset(adminId);
            } catch (error) {
                toast.error(
                    'You do not have permission to reset this MFA token.',
                );
                throw new Error(error.message);
            }
        },
        onError: (error) => {
            toast.error('You do not have permission to reset this MFA token.');
            throw new Error(error.message);
        },
        onSuccess: () => {
            toast.success('MFA Reset');
            if (isEditingThemself) {
                dispatch({ type: ACTIONS.LOGOUT });
                navigate('?action=mfaTokenReset');
            }
        },
    });
};

export const useDeleteAdminUser = (
    adminId,
    token,
    accountScope,
    dispatch,
    navigate,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                deleteAdminUser({ id: adminId, token, scope: accountScope });
            } catch (error) {
                toast.error(
                    error?.response?.data?.message ||
                        'Failed to delete account',
                );
                throw new Error(error.message);
            }
        },
        onError: (error) => {
            toast.error(
                error?.response?.data?.message || 'Failed to delete account',
            );
            throw new Error(error.message);
        },
        onSuccess: () => {
            dispatch({ type: 'RESET_MODAL' });
            dispatch({ type: 'RESET_TABLE' });
            navigate('/admins');
            toast.success('Account Deleted');
        },
    });
};

export const useForgotPassword = (
    adminUser,
    handleIsLoadingResetPasswordTrue,
    handleIsLoadingResetPasswordFalse,
    handleSetSucceedTrue,
) => {
    const host =
        import.meta.env.MODE === 'development'
            ? 'teamsdev.tcap.dev'
            : window.location.host;

    return useMutation({
        mutationFn: async () => {
            try {
                handleIsLoadingResetPasswordTrue();
                postForgotPassword({
                    username: adminUser?.email,
                    host: `${host}/ui/change-password?token=[TOKEN]`,
                });
            } catch (error) {
                handleIsLoadingResetPasswordFalse();
                toast.error(
                    'Unable to process request. Please contact your administrator.',
                );
                throw new Error(error.message);
            }
        },
        onSuccess: () => {
            handleIsLoadingResetPasswordFalse();
            handleSetSucceedTrue();

            toast.success('Account Create Success!');
        },
    });
};

export const useResetPassword = (adminUser, handleSuccess, onModalError) => {
    return useMutation({
        mutationFn: async () => {
            try {
                const host =
                    import.meta.env.MODE === 'development'
                        ? 'teamsdev.tcap.dev'
                        : window.location.host;
                postForgotPassword({
                    username: adminUser?.email,
                    host: `${host}/ui/change-password?token=[TOKEN]`,
                })
                    .then(() => {
                        handleSuccess();
                    })
                    .catch((e) => {
                        onModalError(e);
                    });
            } catch (error) {
                toast.error(
                    'Unable to process request. Please contact your administrator.',
                );
                throw new Error(error.message);
            }
        },
    });
};
