import { hashObject } from '../../utils/utils';

const initModalState = {
    show: false,
    loading: false,
    state: {},
    data: null,
    error: null,
    uploading: false,
    unsavedChanges: false,
    originalData: {},
    stateError: {},
};
const initFormState = {
    show: false,
    loading: true,
    state: {},
    data: null,
    error: null,
    uploading: false,
    unsavedChanges: false,
    originalData: {},
    stateError: {},
};

export const modalReducerTypes = (formName = '') => {
    var init = initModalState;
    if (formName !== '') {
        init = initFormState;
    }
    return function modalReducer(modal = init, action) {
        switch (action.type) {
            case `${formName}CHANGE_MODAL_LOADING`:
                return {
                    ...modal,
                    loading: action.payload,
                };
            case `${formName}CHANGE_MODAL_LOADING_FIELDS`:
                return {
                    ...modal,
                    loadFields: !modal.loadFields,
                };
            case `${formName}ON_MODAL_SUBMIT`:
                return {
                    ...modal,
                    uploading: action.payload,
                    stateError: null,
                    restError: null,
                };
            case `${formName}OPEN_MODAL`:
                return {
                    show: true,
                    state: {},
                    mode: 'Edit',
                    loading: true,
                    loadFields: true,
                    data: null,
                    ...action.payload,
                };
            case `${formName}RESET_MODAL`:
                return {...init};
            case `${formName}CHANGE_MODAL_STATE`:
                return {
                    ...modal,
                    state: {
                        ...modal.state,
                        ...action.payload,
                    },
                };
            case `${formName}CHANGE_MODAL_HASH`:
                return {
                    ...modal,
                    hash: hashObject(modal.state),
                };
            case `${formName}ON_MODAL_SUBMIT_SUCCEED`:
                return {
                    ...init,
                    loading: true,
                    uploading: false,
                };
            case `${formName}CHANGE_MODAL_BACKEND_ERROR`:
                return {
                    ...modal,
                    restError: action.payload,
                    uploading: false,
                    loading: false,
                };
            case `${formName}CHANGE_MODAL_STATE_ERROR`:
                return {
                    ...modal,
                    stateError: {
                        ...modal.stateError,
                        ...action.payload,
                    },
                };
            case 'CHANGE_CURRENT_SCOPE':
                return {...init};
            case 'RESET_TABLE':
                return {...init};
            case 'CHANGE_CHILD_TABLE':
                return {...init};
            case 'SWITCH_CHILD_TABLE':
                return {...init};
            case 'CHANGE_TAB_VIEW':
                return {...init};
            case `REDIRECT`:
                return {...init};
            case 'LOGOUT':
                return {...init};
            case 'RESET_TABLE_MULTICHILD':
                return {...init};
            default:
                return modal;
        }
    };
};
