import {useEffect, useState} from 'react';

export default function useDetectFileType(file) {
    const [fileType, setFileType] = useState(null);

    useEffect(() => {
        if (!file || !file.includes) return;
        const isPng = file.includes('.PNG') || file.includes('.png');
        const isSvg = file.includes('.SVG') || file.includes('.svg');
        const isBase64Svg = file.includes('data:image/svg');
        const isBase64Png = file.includes('data:image/png');

        if (isPng || isBase64Png) setFileType('png');
        if (isSvg || isBase64Svg) setFileType('svg');
    }, [file]);

    return fileType;
}
