import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { exit, inputVariants, buttonVariants } from '../constants.js';
import { AnimatePresence, motion } from 'framer-motion';

// import config from '../../../../config.json';

import Image from '../../../../components/v4/Image';
import FormLabel from '../../../../components/v4/FormLabel';
import Input from '../../../../components/v4/Input';
import Button from '../../../../components/v4/Button';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';

import { Link } from 'react-router-dom';

import useTempPasswordChecker, { PasswordFieldValidation } from '../Passwords';
import useDetectFileType from '../../../../hooks/useDetectFileType';

export default function ChangePassword() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [token, setToken] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const passwordErrors = useTempPasswordChecker(password);

    useEffect(() => {
        setToken(searchParams.get('token'));
        setUsername(searchParams.get('username'));
    }, []);

    useEffect(() => {
        if (hasSuccess) {
            navigate('/?changePassword=success');
        }
    }, [hasSuccess]);
    /**
     * Pingco Logo.
     */
    const { imagePathLightMode } = useSelector((state) => {
        return { ...state.login };
    });
    const fileType = useDetectFileType(
        import.meta.env.VITE_APP_IMAGE_BASE + imagePathLightMode,
    );

    /**
     * Password change handler.
     */
    function onPasswordChangeHandler(e) {
        e.preventDefault();

        if (passwordErrors) return;
        setLoading(true);
        setHasError(null);
        setHasSuccess(false);

        fetch(`${import.meta.env.VITE_APP_API_URL}/resetpassword/`, {
            headers: {
                accept: 'application/json, text/plain, */*',
                'content-type': 'application/json;charset=UTF-8',
                'x-requestscope': '20',
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                username,
                password,
                token,
            }),
        })
            .then(async (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setHasError(null);
                    setHasSuccess(true);
                } else {
                    const data = await response.json();
                    setHasError(data.message);
                    setHasSuccess(false);
                }
            })
            .catch((e) => {
                setLoading(false);
                //setHasError(e?.message);
                setHasSuccess(false);
            });
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 60px',
                background: 'white',
                boxShadow: '2xl',
                width: '600px',
                margin: '0 auto',
                borderRadius: '10px',
            }}>
            <Box as="form" onSubmit={onPasswordChangeHandler}>
                {fileType === 'svg' && (
                    <object
                        alt="Domain Logo"
                        style={{
                            display: 'block',
                            maxWidth: '300px',
                            maxHeight: '150px',
                            margin: '0 auto',
                        }}
                        data={
                            import.meta.env.VITE_APP_IMAGE_BASE +
                            imagePathLightMode
                        }
                        type="image/svg+xml"></object>
                )}
                {fileType === 'png' && (
                    <Image
                        alt="Domain Logo"
                        style={{
                            display: 'block',
                            maxWidth: '300px',
                            maxHeight: '150px',
                            margin: '0 auto',
                        }}
                        src={
                            import.meta.env.VITE_APP_IMAGE_BASE +
                            imagePathLightMode
                        }
                    />
                )}

                <Box sx={{ margin: '15px 0', display: 'flex' }} />

                <AnimatePresence>
                    <motion.div
                        key="change-password-box"
                        initial="hidden"
                        whileInView="visible"
                        animate="visible"
                        exit={exit}>
                        <motion.div variants={inputVariants}>
                            {/* New password */}
                            <Box sx={{ position: 'relative' }}>
                                <FormLabel
                                    sx={{ margin: 0 }}
                                    for="change-password"
                                    className="!text-black">
                                    NEW PASSWORD
                                </FormLabel>
                                <Input
                                    type="password"
                                    borderColor="gray"
                                    className="!text-gray-700"
                                    variant="flushed"
                                    size="sm"
                                    id="change-password"
                                    value={password}
                                    onFocus={() => {
                                        setShowPasswordModal(true);
                                    }}
                                    onBlur={() => {
                                        setShowPasswordModal(false);
                                    }}
                                    onChange={(e) => {
                                        e.persist();
                                        setPassword(e.target.value);
                                    }}
                                />
                                <PasswordFieldValidation
                                    show={showPasswordModal}
                                    errors={passwordErrors}
                                />
                            </Box>
                            <Box sx={{ margin: '15px 0', display: 'flex' }} />
                        </motion.div>
                        <motion.div variants={buttonVariants}>
                            <Button
                                colorScheme="messenger"
                                isDisabled={loading}
                                type="submit"
                                sx={{
                                    textAlign: 'center',
                                    display: 'block',
                                    width: '100%',
                                }}>
                                Change my password
                            </Button>

                            {hasError && (
                                <>
                                    <Box
                                        sx={{
                                            margin: '15px 0',
                                            display: 'flex',
                                        }}
                                    />

                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertDescription className="text-black">
                                            {hasError}
                                        </AlertDescription>
                                    </Alert>
                                </>
                            )}
                            {hasSuccess && (
                                <>
                                    <Box
                                        sx={{
                                            margin: '15px 0',
                                            display: 'flex',
                                        }}
                                    />

                                    <Alert status="success">
                                        <AlertIcon />
                                        <AlertDescription className="text-black">
                                            Successfully changed your password.
                                        </AlertDescription>
                                    </Alert>
                                </>
                            )}
                            <Box sx={{ margin: '15px 0', display: 'flex' }} />

                            <Link to="/">
                                <Button
                                    variant="link"
                                    sx={{
                                        textAlign: 'center',
                                        margin: '10px auto 0 auto',
                                        display: 'block',
                                    }}>
                                    Back to login
                                </Button>
                            </Link>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            </Box>
        </Box>
    );
}
