import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function MSRedirect() {
    const location = useLocation();

    useEffect(async () => {
        if (location?.hash) {
            try {
                const reference = location.hash.substring(1);
                const requestHeaders = new Headers();
                const redirect = await fetch(
                    `${import.meta.env.VITE_APP_API_URL}/sso/ms/redirect`,
                    {
                        method: 'POST',
                        headers: {
                            ...requestHeaders,
                            ...{
                                'Content-Type': 'application/json',
                                'X-RequestScope': 20,
                            },
                        },
                        mode: 'cors',
                        credentials: 'omit',
                        body: JSON.stringify(reference),
                    },
                );

                if (process.env.NODE_ENV === 'development') {
                    window.location.href = `${window.location.origin}#${reference}`;
                } else {
                    let host = await redirect.json();
                    window.location.href = `https://${host}#${reference}`;
                }
            } catch (e) {
                toast.error(
                    e?.response?.data?.message ||
                        'Unrecognized domain reference.',
                );
                window.close();
            }
        }
    }, [location]);

    return null;
}
