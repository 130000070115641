import { chakra, forwardRef } from '@chakra-ui/react';

import { classNames as cx } from '@/utils/classNames';
import { useCardStyles } from './card-context';

/**
 * @typedef {import('@chakra-ui/react').HTMLChakraProps<"div">} CardHeaderProps
 * @type React.ForwardRefRenderFunction<HTMLDivElement, CardHeaderProps>
 * @returns {JSX.Element}
 */
export const CardHeader = forwardRef(function CardHeader(props, ref) {
    const { className, ...rest } = props;
    const styles = useCardStyles();
    return (
        <chakra.div
            ref={ref}
            className={cx('chakra-card__header', className)}
            __css={styles.header}
            {...rest}
        />
    );
});
