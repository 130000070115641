/* eslint-disable react/display-name */
import { useEffect, useMemo, useState, forwardRef } from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import MaterialEdit from '@material-ui/icons/Edit';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import chakratheme from '../../../../chakratheme';
import MaterialTable from '../../../../components/v4/MaterialTable';
import Select from '../../../../components/v4/Select';
import FormLabel from '../../../../components/v4/FormLabel';
import Form from './Form';
import style from '../edit/styles';
import {
    getAllWholesalerServiceProfiles,
    getWholesalerServiceProfiles,
    getAllPartnerServiceProfiles,
    getPartnerServiceProfiles,
} from '../../../../API/ServiceProfiles/getAllServiceProfiles';

import walkthroughIds from '../../walkthroughIds';
import { loadingColumnsConstructor } from 'components/tables/ReduxTable';
import { Error } from 'components/index';
import { nullError } from 'components/error';
import config from '@/config.json';
import { serviceTypeRender } from './constants';

const { serviceProfiles } = walkthroughIds;

const Edit = forwardRef((props, ref) => (
    <MaterialEdit
        {...props}
        ref={ref}
        data-walkthroughid={serviceProfiles.ufEditButton}
    />
));

function Header({ handleCreate, isEditable }) {
    return (
        <div>
            {isEditable && (
                <Button
                    className="btn btn-primary btn-sm"
                    style={{ marginBottom: '5%' }}
                    onClick={handleCreate}
                    data-walkthroughid={serviceProfiles.ufAddServiceProfile}>
                    {' '}
                    <AddRoundedIcon
                        fontSize="small"
                        style={{ marginBottom: '2%' }}
                    />{' '}
                    Service Profile
                </Button>
            )}
        </div>
    );
}

export default function ServiceProfiles() {
    const [action, setAction] = useState('GET'); // GET | EDIT | ADD

    const [data, setData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    const { currentWholesaler: wholesalerID, currentPartner: partnerID } =
        useSelector((state) => state.navigationLists);

    const { currentScope, requiredScope } = useSelector((state) => state.login);

    const columns = useMemo(
        () => [
            {
                title: 'Service Name',
                field: 'name',
            },
            {
                title: 'Service Type',
                field: 'serviceType',
                render: (rowData) => {
                    const render = serviceTypeRender(rowData);
                    return render;
                },
            },
            {
                title: 'Visible Scope',
                field: 'visibleScope',
                render: (rowData) => {
                    return config.scope.json[rowData.visibleScope];
                },
                customFilterAndSearch: (v, rowData) => {
                    return config.scope.json[rowData.visibleScope]
                        ?.toLowerCase()
                        .includes(v.toLowerCase());
                },
            },
        ],
        [],
    );
    const levelDropdown = useMemo(() => {
        if (requiredScope >= 60) {
            return [
                {
                    label: 'Partner',
                    value: 'partner',
                },
                {
                    label: 'Wholesaler',
                    value: 'wholesaler',
                },
            ];
        }
        return [
            {
                label: 'Partner',
                value: 'partner',
            },
        ];
    }, [requiredScope]);

    const scopeList = () => {
        let scopeEnum = [];
        for (
            let value = level === 'partner' ? 20 : 60;
            value <= requiredScope;
            value += 20
        ) {
            scopeEnum.push({ value, label: config.scope.json[value] });
        }
        return scopeEnum;
    };

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(columns),
        [columns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;

    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    const [level, setLevel] = useState('partner'); // partner | wholesaler

    const viewOnly =
        level === 'partner' ? requiredScope < 60 : requiredScope < 80;
    const handleGetData = async () => {
        let result = {};
        setData([]);
        setIsLoading(true);
        if (level === 'partner') {
            if (currentScope === 40) {
                result = getPartnerServiceProfiles({ partnerID });
            } else {
                result = getAllPartnerServiceProfiles({ wholesalerID });
            }
        } else {
            if (currentScope === 60) {
                result = getWholesalerServiceProfiles({ wholesalerID });
            } else {
                result = getAllWholesalerServiceProfiles();
            }
        }
        result
            .then((res) => {
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const handleChangeLevel = (e) => {
        if (e.target.value === 'wholesaler') {
            //raise min scope
            dispatch({
                type: 'CHANGE_MINIMUM_SCOPE',
                payload: 60,
            });
        } else {
            dispatch({
                type: 'CHANGE_MINIMUM_SCOPE',
                payload: 40,
            });
        }
        setLevel(e.target.value);
    };

    // TODO: need to change this to useQuery
    useEffect(() => {
        if (action === 'GET') {
            handleGetData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [level, action, currentScope]);

    if (!partnerID || !wholesalerID) {
        return <Error error={nullError} />;
    }

    if (action !== 'GET') {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <Form
                    mode={action}
                    data={editData}
                    level={level}
                    handleBackButton={() => setAction('GET')}
                    handleSelectServiceProfileType={(v, displayName) => {
                        setEditData({
                            serviceType: v,
                            displayName: displayName,
                        });
                    }}
                    scopeList={scopeList}
                />
            </ChakraProvider>
        );
    }
    const titleName = 'Service Profiles';

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <h1 className="text-[2.5rem] font-medium">{titleName}</h1>
            <MaterialTable
                title={
                    <Header
                        isEditable={!viewOnly}
                        handleCreate={() => {
                            setAction('ADD');
                            setEditData(null);
                        }}
                    />
                }
                titleName={titleName}
                refreshTable={handleGetData}
                refreshFn={handleGetData}
                columns={isLoading ? loadingColumns : columns}
                data={isLoading ? dummyTable : data}
                actions={[
                    {
                        icon: () => (
                            <Box sx={style.fieldContainer}>
                                <FormLabel sx={style.label}>
                                    Service Profile Type
                                </FormLabel>
                                <Select
                                    value={level}
                                    onChange={handleChangeLevel}
                                    options={levelDropdown}
                                    data-walkthroughid={
                                        serviceProfiles.ufSelectProfileType
                                    }
                                />
                            </Box>
                        ),
                        tooltip: 'Select level',
                        onClick: () => {},
                        position: 'toolbar',
                    },
                    {
                        icon: Edit,
                        tooltip: 'Edit',
                        isFreeAction: false,
                        hidden: viewOnly,
                        onClick: (e, rowData) => {
                            setAction('EDIT');
                            setEditData(rowData);
                        },
                    },
                ]}></MaterialTable>
        </ChakraProvider>
    );
}
