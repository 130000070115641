import { Box } from '@chakra-ui/react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TabPanel from '../presentational/TabPanel';

/*
 * Tabs component taken from material ui
 * Returns modal tab or regular tab
 */
const TabView = ({
    tabLabels,
    contents,
    insideModal,
    value,
    parentLabel,
    isChild,
    isLink = false,
    ...rest
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { tab, modalTab } = useSelector((state) => {
        return { ...state.view };
    });

    const filteredLabelsAndContents = tabLabels.reduce(
        (acc, label, index) => {
            if (label !== null) {
                acc.labels.push(label);
                acc.contents.push(contents[index]);
            }
            return acc;
        },
        { labels: [], contents: [] },
    );
    const changeModalTabView = (e, v) => {
        dispatch({
            type: 'CHANGE_MODAL_TAB_VIEW',
            payload: v,
        });
    };

    const changeTabView = (e, v) => {
        dispatch({
            type: 'CHANGE_TAB_VIEW',
            payload: v,
        });
    };

    if (insideModal) {
        return (
            <Box
                sx={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'brand.500',
                    },
                }}>
                <Tabs
                    value={value ?? modalTab}
                    indicatorColor="brand"
                    onChange={changeModalTabView}
                    {...rest}>
                    {filteredLabelsAndContents.labels.map((label) => (
                        <Tab
                            label={label}
                            key={label}
                            data-walkthroughid={`/tab/${label}`}
                        />
                    ))}
                </Tabs>
                {filteredLabelsAndContents.contents.map((content, index) => (
                    <TabPanel
                        value={value ?? modalTab}
                        index={index}
                        key={index}
                        dir={theme.direction}>
                        {content}
                    </TabPanel>
                ))}
            </Box>
        );
    }
    return (
        <Box
            sx={{
                '& .MuiTabs-indicator': {
                    backgroundColor: 'brand.500',
                },
            }}>
            <Tabs
                value={value ?? tab}
                indicatorColor="brand"
                onChange={changeTabView}
                {...rest}>
                {tabLabels.map((label, index) =>
                    isLink ? (
                        <Tab
                            label={label}
                            key={label}
                            data-walkthroughid={
                                isChild
                                    ? `/tab/${parentLabel}/${label}`
                                    : `/tab/${label}`
                            }
                            component={Link}
                            to={rest?.to(index)}
                            className={
                                'hover:text-[inherit] hover:no-underline'
                            }
                        />
                    ) : (
                        <Tab
                            label={label}
                            key={label}
                            data-walkthroughid={
                                isChild
                                    ? `/tab/${parentLabel}/${label}`
                                    : `/tab/${label}`
                            }
                        />
                    ),
                )}
            </Tabs>
            {contents.map((content, index) => (
                <TabPanel
                    value={value ?? tab}
                    index={index}
                    key={index}
                    dir={theme.direction}>
                    {content}
                </TabPanel>
            ))}
        </Box>
    );
};

export default TabView;
