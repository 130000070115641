import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {EditForm} from '../../../../components/forms/MultiChild';
import CustomFieldsContent from '../../../organisations/sharedComponents/CustomFieldsForm';

/*
 * Custom Fields Form for number blocks edit tab
 */
const CustomFieldsForm = (props) => {
    const dispatch = useDispatch();

    // state access
    const {data: modalData, state: modalState} = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // return form for the tab
    return (
        <EditForm
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            modalValidate={() => {
                var newErrorState = {};
                newErrorState = {
                    attribute1:
                        modalState?.attribute1?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                    attribute2:
                        modalState?.attribute2?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                    attribute3:
                        modalState?.attribute3?.length > 100 &&
                        'Maximum field length is 100 characters. ',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.attribute1 ||
                    newErrorState.attribute2 ||
                    newErrorState.attribute3
                );
            }}
            modalContent={<CustomFieldsContent />}
            modalFetch={'/numberblock/' + modalData?.id}
            modalUpdate="/numberblock/"
        />
    );
};

export default CustomFieldsForm;
