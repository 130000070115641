import React from 'react';
import {
    ChakraProvider,
    Stack,
    Box,
    Button,
    Heading,
    Text,
} from '@chakra-ui/react';
import chakratheme from '@/chakratheme';

/**
 * Box for delete actions on pages that require it
 * inputs are a onClick function and warning text to show in the box
 * @note this has been changed to a Chakra Component, need to check in other pages
 */
const DeleteBox = ({
    warningText,
    deleteText,
    buttonText,
    isLoading = false,
    isDisabled = false,
    onClick,
    walkthroughId,
}) => {
    return (
        <ChakraProvider resetCSS theme={chakratheme}>
            <Box
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
                }}>
                <Heading
                    as="h2"
                    fontSize="1.5rem"
                    lineHeight={'1.2'}
                    fontWeight={'500'}
                    mb={2}>
                    Danger Zone
                </Heading>
                <Stack
                    border="2px solid red"
                    minHeight={'60px'}
                    borderRadius="5px"
                    justifyContent="center">
                    <Stack
                        direction="row"
                        paddingInline={3.5}
                        fontSize="1rem"
                        minW={'60%'}>
                        <Box minW="80%" display="flex" flexDirection="column">
                            <Text fontWeight="bold" fontSize={'17'}>
                                {deleteText ?? 'Delete'}
                            </Text>

                            <Text>{warningText}</Text>
                        </Box>
                        <Box
                            display="flex"
                            w="20%"
                            alignItems="center"
                            justifyContent="flex-end"
                            marginTop={10}>
                            <Button
                                borderRadius={'5px'}
                                isLoading={isLoading}
                                isDisabled={isDisabled}
                                colorScheme="red"
                                // variant="danger"
                                onClick={onClick}
                                data-walkthroughid={walkthroughId}>
                                {buttonText ?? 'Delete'}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </ChakraProvider>
    );
};

export default DeleteBox;
