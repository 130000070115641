import React, { useState, useEffect, useRef } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    IconButton,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import TableAndModal, {
    modalTitle,
    floatSearch,
} from '../../../../components/tables/TableAndModal';
import {
    NumberFormatCustom,
    LoadingFieldDropdown,
} from '../../../../components';
import config from '../../../../config.json';
import DeleteConfirmation, { DeleteButton } from './DeleteConfirmation';

import walkthroughIds from '../walkthroughIds';

/**
 * Component for The number block tab inside Plans page
 */
const Block = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [warning, setWarning] = useState('');

    // modal data from state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // table data from state
    const { child, state: tableState } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    const { numberBlockRates } = walkthroughIds;

    // default columns for table
    const defaultColumns = [
        {
            title: 'Block Size',
            field: 'size',
            defaultSort: 'asc',
        },
        {
            title: 'Bill',
            field: 'bill',
            type: 'boolean',
        },
        {
            title: 'Setup Fee',
            field: 'setupFee',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NumberFormatCustom
                        displayType={'text'}
                        value={rowData['setupFee']}
                    />
                );
            },
            customFilterAndSearch: (v, rowData) =>
                floatSearch(v, rowData, 'setupFee'),
        },
        {
            title: 'Rate',
            field: 'rate',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NumberFormatCustom
                        displayType={'text'}
                        value={rowData['rate']}
                    />
                );
            },
            customFilterAndSearch: (v, rowData) =>
                floatSearch(v, rowData, 'rate'),
        },

        {
            title: 'Prefix',
            field: 'prefix',
        },

        {
            title: 'Pattern',
            field: 'pattern',
        },
    ];

    // blockSize use Effect - unsure what it does
    useEffect(() => {
        if (tableState?.data) {
            const result = tableState?.data.reduce(
                (result, { size }) => {
                    result[size] = true; //true means rate exists for this blockSize.
                    return result;
                },
                { 1: false, 5: false, 10: false, 100: false },
            );
            const newWarn = Object.values(result).reduce((result, value) => {
                return result && value;
            }, true);
            setWarning(!newWarn);
        }
    }, [tableState]);

    // changes modal state if its a prefix or a pattern
    useEffect(() => {
        if (modalData?.prefix) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    prefixType: 'Prefix',
                },
            });
        } else if (modalData?.pattern) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    prefixType: 'Pattern',
                },
            });
        }
        if (modalState?.bill === undefined) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    bill: true,
                },
            });
        }
    }, [modalData?.prefix, modalData?.pattern, modalState.bill]);

    // Render table and modal or an alert if warning if true
    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                tableFetch={'/numberblockrates/' + child?.id}
                modalFetch={'/numberblockrate/' + modalData?.id}
                modalAdd="/numberblockrate/"
                modalUpdate="/numberblockrate/"
                modalUpload={(freshData, state) => {
                    const { prefixType, prefix, pattern, ...rest } = state;
                    if (prefixType === 'Prefix') {
                        return {
                            prefix: prefix || pattern,
                            ...rest,
                            planID: child?.id,
                        };
                    } else {
                        return {
                            pattern: pattern || prefix,
                            ...rest,
                            planID: child?.id,
                        };
                    }
                }}
                modalInitState={{ prefixType: 'Prefix', isActive: true }}
                title="Number Block Rates"
                defaultColumns={defaultColumns}
                noTitle
                add
                groupable
                filterable
                newPage
                maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
                modalTitle={modalTitle(
                    modalData,
                    'name',
                    ' Number Block Rate ',
                    modalMode,
                )}
                rowActions={{
                    editable: {
                        init: { prefixType: 'Prefix' },
                    },
                }}
                modalButtons={
                    modalMode === 'Edit' && (
                        <>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={numberBlockRates.ufSaveBtn}>
                                Save Changes
                            </Button>
                            <DeleteButton />
                        </>
                    )
                }
                modalValidate={() => {
                    const newErrorState = {
                        size:
                            (!modalState?.size ||
                                modalState?.size?.length < 1) &&
                            'Field is required.',
                        prefix:
                            modalState?.prefixType === 'Prefix' &&
                            (!modalState?.prefix ||
                                modalState?.prefix?.length < 1) &&
                            (!modalState?.pattern ||
                                modalState?.pattern?.length < 1) &&
                            'Field is required.',
                        pattern:
                            modalState?.prefixType === 'Pattern' &&
                            (!modalState?.prefix ||
                                modalState?.prefix?.length < 1) &&
                            (!modalState?.pattern ||
                                modalState?.pattern?.length < 1) &&
                            'Field is required.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !(
                        newErrorState.size ||
                        (modalState?.prefixType === 'Prefix' &&
                            newErrorState.prefix) ||
                        (modalState?.prefixType === 'Pattern' &&
                            newErrorState.pattern)
                    );
                }}
                editable={{
                    add: true,
                    update: true,
                }}
                modalContent={
                    <>
                        <DeleteConfirmation
                            show={modalState?.showPrompt}
                            mode={'Number Block Rate'}
                        />

                        <Row style={{ minHeight: 40 }}>
                            <Col style={{ maxWidth: '15%' }}>
                                <LoadingFieldDropdown
                                    staticEnum={['Prefix', 'Pattern']}
                                    noEmptyOption
                                    fieldValue={modalState?.prefixType}
                                    onChange={(v) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                prefixType: v,
                                            },
                                        });
                                    }}
                                    dataWalkthroughid={
                                        numberBlockRates.ufPatternSelect
                                    }
                                />
                            </Col>
                            <Col style={{ maxWidth: '35%' }}>
                                {modalState?.prefixType === 'Prefix' ? (
                                    <TextField
                                        fullWidth
                                        label={modalState?.prefixType}
                                        type="text"
                                        value={modalState?.prefix}
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    prefix: e.target.value,
                                                },
                                            });
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={() => {
                                                        window.alert(
                                                            'Prefix Example:\n61 to match numbers starting in 61, 61[23] matches numbers starting in 612 or 613',
                                                        );
                                                    }}>
                                                    <HelpRoundedIcon fontSize="small" />
                                                </IconButton>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(modalStateError?.prefix)}
                                        helperText={
                                            modalStateError?.prefix ??
                                            'Pattern is ignored if Prefix has been set.'
                                        }
                                        data-walkthroughid={
                                            numberBlockRates.ufPrefixInput
                                        }
                                    />
                                ) : (
                                    <TextField
                                        fullWidth
                                        label={modalState?.prefixType}
                                        type="text"
                                        value={modalState?.pattern}
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    pattern: e.target.value,
                                                },
                                            });
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={() => {
                                                        window.alert(
                                                            'pattern example:\n(Using Perl Regular Expression)\n^ - Start of line\n$ - End of line\n[] - list of possibilities\n() - for grouping\n{[min],[max]} - length (quantity) range\n^61[234][0-9]{8}$ = matches a number that start with 612, 613, 614, and is followed by 8 additional numbers exactly',
                                                        );
                                                    }}>
                                                    <HelpRoundedIcon fontSize="small" />
                                                </IconButton>
                                            ),
                                        }}
                                        error={Boolean(
                                            modalStateError?.pattern,
                                        )}
                                        helperText={
                                            modalStateError?.pattern ??
                                            'Pattern is ignored if Prefix has been set.'
                                        }
                                        data-walkthroughid={
                                            numberBlockRates.ufPrefixInput
                                        }
                                    />
                                )}
                            </Col>
                            <Col>
                                <LoadingFieldDropdown
                                    fieldName="Block Size"
                                    noEmptyOption
                                    staticEnum={config.blockSize.dropdown}
                                    onChange={(v) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                size: v,
                                            },
                                        });
                                    }}
                                    toggleLimit={5}
                                    fieldValue={modalState?.size}
                                    errorText={
                                        !modalState?.size &&
                                        'Field is required.'
                                    }
                                    dataWalkthroughid={
                                        numberBlockRates.ufBlockSizeSelect
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col style={{ left: 10 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={modalState?.bill}
                                            defaultChecked
                                            onChange={() => {
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        bill: !modalState?.bill,
                                                    },
                                                });
                                            }}
                                            data-walkthroughid={
                                                numberBlockRates.ufBillCheckbox
                                            }
                                        />
                                    }
                                    label="Bill"
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    label="Setup Fee"
                                    value={modalState?.setupFee}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                    fullWidth
                                    error={Boolean(modalStateError?.setupFee)}
                                    helperText={modalStateError?.setupFee}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                setupFee: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        numberBlockRates.ufSetupFeeInput
                                    }
                                />
                            </Col>
                            <Col>
                                <TextField
                                    label="Rate"
                                    value={modalState?.rate}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                    fullWidth
                                    error={Boolean(modalStateError?.rate)}
                                    helperText={modalStateError?.rate}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                rate: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        numberBlockRates.ufRateInput
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                }
            />
        </>
    );
};

export default Block;
