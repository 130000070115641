import { axios } from '@/services/axios';
export const getTimezonesList = () =>
    axios.get('/resourceaccount/aa/timezones' );

export const getLanguagesList = () =>
    axios.get('/resourceaccount/aa/languages');

export const getTemplatesList = () =>
    axios.get('/aatemplates');

export const postResourceAccount = ({ formData}) =>
    axios.post(`/resourceaccount/aa`, formData );
