import { useState } from 'react';

export function useNationalCode() {
    const nationalCodePattern = /^\d+$/;
    const [nationalCodes, setNationalCodes] = useState([]);
    const [nationalCodeInput, setNationalCodeInput] = useState('');
    const [nationalCodeError, setNationalCodeError] = useState({
        isError: false,
        message: '',
        duplicates: ''
    });

    //#region National Codes
    const addNationalCodes = (nationalCodesToAdd) => {
        const newNationalCodes = [...nationalCodes, ...nationalCodesToAdd];

        setNationalCodes(newNationalCodes);
        setNationalCodeInput('');
    };

    const removeNationalCode = (nationalCode) => {
        const index = nationalCodes.findIndex((e) => e === nationalCode);
        if (index !== -1) {
            const newNationalCodes = [...nationalCodes];
            newNationalCodes.splice(index, 1);
            setNationalCodes(newNationalCodes);
        }
    };

    const handleNationalCodesChange = (e) => {
        setNationalCodeError({ isError: false, message: '', duplicates: '' });
        setNationalCodeInput(e.target.value.trim());
    };

    const handleNationalCodesKeyDown = (e) => {
        if (['Enter', 'Tab', ','].includes(e.key)) {
            setNationalCodeError({ isError: false, message: '', duplicates: '' });
            e.preventDefault();

            // check for duplicates
            if (nationalCodes.includes(nationalCodeInput)) {
                setNationalCodeError((prevState) => ({
                    ...prevState,
                    isError: true,
                    duplicates: `National Code already exists ${nationalCodeInput}.`,
                }));
                return;
            }
            // check for format including empty string
            if (!nationalCodePattern.test(nationalCodeInput)) {
                setNationalCodeError((prevState) => ({
                    ...prevState,
                    isError: true,
                    message: `Invalid National Code ${nationalCodeInput}.`,
                }));
                return;
            }
            // add value to list
            addNationalCodes([nationalCodeInput]);
        }
    };

    const handleNationalCodesPaste = (e) => {
        e.preventDefault();
        setNationalCodeError({ isError: false, message: '', duplicates: '' });

        const pastedData = e.clipboardData.getData('text');
        // remove return and new line, split by comma and filter by empty string if exist
        const pastedNationalCodes = pastedData
            .trim()
            .replace(/\s+/g, '')
            .split(',')
            .filter((value) => value !== '');

        let invalidIds = [];
        let duplicateIds = [];
        let allowedNationalCodes = [];
        pastedNationalCodes.map((nationalCode) => {
            // national code already exist, add to duplicate error message
            if ([...nationalCodes, ...allowedNationalCodes].includes(nationalCodeInput)) {
                duplicateIds.push(nationalCode);
                return;
            }
            // national code does not match numeric format, add to invalid ids error message
            if (!nationalCodePattern.test(nationalCode)) {
                invalidIds.push(nationalCode);
                return;
            }
            allowedNationalCodes.push(nationalCode);
        });

        addNationalCodes(allowedNationalCodes);

        let duplicateString = '';
        duplicateIds?.map((dup) => {
            // if last index, add it with a fullstop, else comma
            if (duplicateIds.indexOf(dup) === duplicateIds.length - 1) {
                duplicateString += dup + '.';
            } else {
                duplicateString += dup + ', ';
            }
        });
        if (duplicateString) {
            setNationalCodeError((prevState) => ({
                ...prevState,
                isError: true,
                duplicates: 'National Code already exists ' + duplicateString,
            }));
        }

        let invalidString = '';
        invalidIds?.map((inv) => {
            // if last index, add it with a fullstop, else comma
            if (invalidIds.indexOf(inv) === invalidIds.length - 1) {
                invalidString += inv + '.';
            } else {
                invalidString += inv + ', ';
            }
        });

        if (invalidString) {
            setNationalCodeError((prevState) => ({
                ...prevState,
                isError: true,
                message: 'Invalid National Code ' + invalidString,
            }));
        }
    };

    const handleNationalCodesCloseClick = (nationalCode) => {
        removeNationalCode(nationalCode);
    };
    //#endregion

    return {
        nationalCodes,
        setNationalCodes,
        nationalCodeInput,
        nationalCodeError,
        handleNationalCodesChange,
        handleNationalCodesKeyDown,
        handleNationalCodesPaste,
        handleNationalCodesCloseClick,
    };
}
