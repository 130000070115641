/* eslint-disable no-unused-vars */
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const EditableCell = ({ getValue, row, column, table, addRow = true }) => {
    const initValue = getValue();
    const { darkMode } = useSelector((state) => state.settings);
    const {
        deleteRow,
        focusedRow,
        setCopy,
        setFocusedRow,
        updateData,
        updateTable,
    } = table.options.meta;
    const [value, setValue] = useState(initValue);
    const [shouldShowAddButton, setShouldShowAddButton] = useState(false);
    const emailInputRef = useRef(null);

    const onBlur = () => {
        setFocusedRow(-1);
        if (value !== initValue) updateData(row.index, column.id, value);
    };
    const addNewRow = () => {
        updateData(row.index, column.id, value, addRow);
        setFocusedRow((fr) => fr + 1);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onBlur();
            e.preventDefault();
            addNewRow();
        }
        if (
            e.key === 'Delete' &&
            value === '' &&
            table.options.data?.length > 1
        ) {
            e.preventDefault();
            const newIndex = row.index > 0 ? row.index - 1 : 0;
            deleteRow(row.index);
            setFocusedRow(newIndex);
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
            setCopy(true);
        }
    };

    useEffect(() => {
        const sortedRows = table?.getSortedRowModel().flatRows;
        const isLastRow =
            sortedRows[sortedRows.length - 1].original.email ===
            row.original.email;
        if (isLastRow) {
            setShouldShowAddButton(true);
        } else {
            setShouldShowAddButton(false);
        }
    }, [shouldShowAddButton, table, row, table.options?.state?.sorting]);

    useEffect(() => {
        if (emailInputRef?.current) return;
        if (row.index === focusedRow) {
            emailInputRef?.current?.focus();
        }
    }, [focusedRow, row.index, emailInputRef]);

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    useEffect(() => {
        if (row.index === 0) {
            setFocusedRow(0);
        } else {
            setFocusedRow(table.options.data.length - 1);
        }
    }, [row.index, setFocusedRow, table]);

    return (
        <InputGroup>
            <Input
                value={value}
                onChange={(e) => {
                    e.preventDefault();
                    updateTable(row.index, column.id, e.target.value, setValue);
                }}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                ref={emailInputRef}
                autoFocus={table.options.data.length - 1 === focusedRow}
                variant="flushed"
                type="text"
                name="email"
                autocomplete="email"
                size="sm"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                aria-label={`${column.id}-input-${row.index}`}
                borderColor={
                    darkMode ? 'dark.tcap-borderGray' : 'light.text-weak'
                }
                _placeholder={{
                    color: darkMode ? '#6C757D' : null,
                }}
                placeholder="Please enter email address"
                pe={8}
                onPaste={(event) => {
                    const emailData = event.clipboardData.getData('text');
                    if (emailData.length > 0) {
                        event.clipboardData.getData('text');
                        setCopy(true);
                    }
                }}
            />
            {shouldShowAddButton ? (
                <InputRightElement opacity="0.8" w={8} h={8}>
                    <Tooltip label={'Add new row'} hasArrow>
                        <IconButton
                            rounded="full"
                            variant="ghost"
                            icon={value && <AddIcon boxSize="16px" />}
                            isDisabled={!value}
                            h={8}
                            minW={8}
                            _hover={{
                                bg: darkMode ? 'dark.hoverGray' : null,
                                color: 'brand.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                            }}
                            onClick={() => {
                                addNewRow();
                            }}
                        />
                    </Tooltip>
                </InputRightElement>
            ) : (
                <InputRightElement opacity="0.8" width={8} h={8}>
                    <Tooltip label={'Delete row'} hasArrow>
                        <IconButton
                            icon={<CloseIcon boxSize="16px" />}
                            rounded="full"
                            variant="ghost"
                            height={8}
                            minW={8}
                            _hover={{
                                bg: darkMode ? 'dark.hoverGray' : null,
                                color: 'red.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                            }}
                            onClick={() => {
                                const newIndex =
                                    row.index > 0 ? row.index - 1 : 0;
                                deleteRow(row.index);
                                setFocusedRow(newIndex);
                            }}
                        />
                    </Tooltip>
                </InputRightElement>
            )}
        </InputGroup>
    );
};

export default EditableCell;
