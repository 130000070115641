export default {
  permissions: {
    ufCompany: '/permissions/input/companyInput',
    ufReadOnly: '/permissions/checkbox/readOnly',
    ufOptOut: '/permissions/checkbox/optOut',
    ufPermissionSet: '/permissions/input/permissionSet',
    ufDeleteBtn: '/permissions/button/deletePermission',
    ufSaveBtn: '/permissions/permissionsTab/button/saveBtn',
  },
};
