import classNames from 'classnames';
import {Box} from '@chakra-ui/react';

export default function Grid({
    // Grid
    rows,
    columns,
    gap,
    gapX,
    gapY,
    // Used to pass in gaps (for now)
    className,
    // Other
    children,
    ...rest
}) {
    const _columns = {
        1: 'grid-cols-1',
        2: 'grid-cols-2',
        3: 'grid-cols-3',
        4: 'grid-cols-4',
        5: 'grid-cols-5',
        6: 'grid-cols-6',
        7: 'grid-cols-7',
        8: 'grid-cols-8',
        9: 'grid-cols-9',
        10: 'grid-cols-10',
        11: 'grid-cols-11',
        12: 'grid-cols-12',
    }[columns];

    const _rows = {
        1: 'grid-rows-1',
        2: 'grid-rows-2',
        3: 'grid-rows-3',
        4: 'grid-rows-4',
        5: 'grid-rows-5',
        6: 'grid-rows-6',
        7: 'grid-rows-7',
        8: 'grid-rows-8',
        9: 'grid-rows-9',
        10: 'grid-rows-10',
        11: 'grid-rows-11',
        12: 'grid-rows-12',
    }[rows];

    const _gap = {
        0: 'gap-0',
        1: 'gap-1',
        2: 'gap-2',
        3: 'gap-3',
        4: 'gap-4',
        5: 'gap-5',
        6: 'gap-6',
        7: 'gap-7',
        8: 'gap-8',
        9: 'gap-9',
        10: 'gap-10',
    }[gap];

    const _gapX = {
        0: 'gap-x-0',
        1: 'gap-x-1',
        2: 'gap-x-2',
        3: 'gap-x-3',
        4: 'gap-x-4',
        5: 'gap-x-5',
        6: 'gap-x-6',
        7: 'gap-x-7',
        8: 'gap-x-8',
        9: 'gap-x-9',
        10: 'gap-x-10',
    }[gapX];

    const _gapY = {
        0: 'gap-y-0',
        1: 'gap-y-1',
        2: 'gap-y-2',
        3: 'gap-y-3',
        4: 'gap-y-4',
        5: 'gap-y-5',
        6: 'gap-y-6',
        7: 'gap-y-7',
        8: 'gap-y-8',
        9: 'gap-y-9',
        10: 'gap-y-10',
    }[gapY];

    const gridClasses = classNames(
        'grid',
        className,
        _columns,
        _rows,
        _gap,
        _gapX,
        _gapY,
    );

    return (
        <Box {...rest} className={gridClasses}>
            {children}
        </Box>
    );
}
