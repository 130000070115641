import { useState } from 'react';
import {
    FormControl,
    Stack,
    FormLabel,
    Tooltip,
    Icon,
    Text,
    Box,
} from '@chakra-ui/react';
import { Field } from 'formik';
import EmailField from '@/components/forms/new/formikFormFields/EmailField';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
    descriptionStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';

const AdminUsersEmail = ({ errors, touched, darkMode }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.email && touched.email}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack
                direction={'row'}
                spacing={stackSpacing}
                sx={stackStyling(true)}>
                <Box>
                    <FormLabel sx={formLabelStyling(darkMode)}>
                        Email <span style={{ color: '#e53e3e' }}>*</span>
                    </FormLabel>{' '}
                    <Text sx={descriptionStyling} maxWidth={250}>
                        If enabling SSO, please ensure the Admin Email address
                        is configured on a supported domain.
                    </Text>
                </Box>
                <Field
                    component={EmailField}
                    id="email"
                    name="email"
                    type="email"
                    walkthroughId={walkthroughIds.adminsGeneral.ufEmail}
                    validate={(value) => {
                        if (!value) {
                            return 'Please provide an email address';
                        }
                        if (
                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                                value,
                            )
                        ) {
                            return 'Please provide a valid email address';
                        }
                    }}
                />
                {isHovered && (
                    <Tooltip
                        hasArrow
                        label="Provide unique email address for the Admin User"
                        aria-label="inputGroupSelect">
                        <Icon
                            color={darkMode ? 'white' : undefined}
                            position="relative"
                            marginLeft={'-14px !important'}
                            left="25px"
                            top="25px"
                        />
                    </Tooltip>
                )}
            </Stack>
        </FormControl>
    );
};

export default AdminUsersEmail;
