import { IconButton } from '@chakra-ui/react';
import { MdOutlineDelete } from 'react-icons/md';

function DeleteBin({ onClick }) {
    return (
        <IconButton
            aria-label="Delete"
            _hover={{
                bg: 'none',
                borderRadius: 'full',
            }}
            _focus={{
                bg: 'none',
                borderRadius: 'full',
            }}
            _active={{
                bg: 'none',
            }}
            icon={<MdOutlineDelete size={18} />}
            bg="transparent"
            height={8}
            minW={8}
            onClick={onClick}
            className="hover:!text-red-500"
        />
    );
}

export default DeleteBin;
