export default {
    ufTrunkTypeSelect: '/trunks/select/trunkType',
    ufRegionSelect: '/trunks/select/region',
    ufTrunkNameInput: '/trunks/input/trunkName',
    ufChannelsInput: '/trunks/input/channels',
    ufRedundancySelect: '/trunks/select/redundancy',

    endpoints: {
        ufAuthSelect: '/trunks/endpoints/select/authType',
        // ufTableAdd: '/trunks/endpoints/table/button',
        modal: {
            ufProtocolSelect: '/trunks/endpoints/modal/select/protocol',
            ufServiceSelect: '/trunks/endpoints/modal/select/service',
            ufPortInput: '/trunks/endpoints/modal/input/port',
            ufHostInput: '/trunks/endpoints/modal/input/host',
            ufAddButton: '/trunks/endpoints/modal/button/add',
        },
        ufSrvCheckbox: '/trunks/endpoints/checkbox/srv',
        ufGeneratePassButton: '/trunks/endpoints/button/generatePass',
    },
    ufDeleteButton: '/trunks/admin/button/delete',
    ufDeleteModal: '/trunks/admin/modal/delete',
    ufCloseModal: '/trunks/admin/modal/close',
};
