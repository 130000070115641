import { Tooltip } from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';

import { getAppAuthByCompanyId, getAppAuthById } from '@/API/AppAuth';
import { renderTelephoneNumber } from '../../../../utils/utils';
import config from '@/config.json';

const validate = (rowData, field) => {
    if (rowData.isLicensed) {
        return rowData[field];
    }
    return null;
};

const customRowSort = (rowA, rowB, field) => {
    const a = rowA[field];
    const b = rowB[field];

    if (rowA.isLicensed && !rowB.isLicensed) {
        return -1; // a will come before b
    } else if (!rowA.isLicensed && rowB.isLicensed) {
        return 1;
    } else if (rowA.isLicensed && rowB.isLicensed) {
        if (!a) return 1;
        if (!b) return -1;
        return a.localeCompare(b);
    } else {
        if (!a && !b) return 0;
        if (!a) return 1;
        if (!b) return -1;
        return a.localeCompare(b);
    }
};

/**
 * @note default columns for resource accounts page
 * @type {import('material-table').Column<any>[]}
 * @const
 */
export const defaultColumns = [
    {
        title: 'Display Name',
        field: 'displayName',
        defaultSort: 'asc',
    },
    {
        title: 'Active',
        field: 'isActive',
        width: '5%',
        type: 'boolean',
    },
    {
        title: 'Status',
        field: 'status',
        width: '5%',
        export: false,
        render: (rowData) => (
            <Tooltip
                // no return message, case 1: not in error, case 2: error but no engine message so show default status
                title={
                    rowData?.returnedMessage?.length > 0
                        ? rowData?.returnedMessage
                        : config.users.status[rowData.status]
                }>
                <FiberManualRecordRoundedIcon
                    style={{
                        color: config.users.statusColor[rowData.status],
                        fontSize: 'small',
                    }}
                />
            </Tooltip>
        ),
        lookup: config.users.status,
        customSort: (a, b) => a.status - b.status,

    },

    {
        title: 'UPN',
        field: 'o365UserPrincipalName',
    },
    {
        title: 'Telephone Number',
        field: 'telephoneNumber',
        customSort: (a, b) => {
            const numberA = a?.telephoneNumber;
            const numberB = b?.telephoneNumber;
            if (!numberA && !numberB) return 0;
            if (!numberA) return 1;
            if (!numberB) return -1;
            return numberA.localeCompare(numberB);
        },
        render: (rowData) => {
            if (rowData.isLicensed) {
                if (rowData.telephoneNumber?.length > 0) {
                    return renderTelephoneNumber(rowData, 'telephoneNumber');
                }
                return config.notAllocated.table;
            }
            return rowData['telephoneNumber'];
        },
    },
    {
        title: 'Voice Policy',
        field: 'voicePolicy',
        customSort: (rowA, rowB) => {
            return customRowSort(rowA, rowB, 'voicePolicy');
        },
        render: (rowData) => {
            if (rowData.isLicensed) {
                if (rowData.voicePolicy?.length > 0) {
                    return rowData['voicePolicy'];
                }
                return config.notAllocated.table;
            }
            return <NotInterestedRoundedIcon fontSize="small" />;
        },
    },
    {
        title: 'Usage Location',
        field: 'usageLocation',
    },
    {
        title: 'Managed By',
        field: 'managedBy',
        lookup: config.users.managedByJSON,
    },
    {
        title: 'Plan',
        field: 'plan',
        hiddenByColumnsButton: true,
        hidden: true,
    },
    ...config.table.custom,
];

/** @note default columns for users page */
export const userDefaultColumns = [
    ...defaultColumns.slice(0, 5),
    {
        title: 'Dial Plan',
        field: 'dialPlan',
        customSort: (rowA, rowB) => {
            return customRowSort(rowA, rowB, 'dialPlan');
        },
        render: (rowData) => validate(rowData, 'dialPlan'),
    },
    ...defaultColumns.slice(5),
];

export const newTableMap = (data) =>
    data?.map(
        ({
            telephoneNumber,
            voicePolicy,
            dialPlan,
            plan,
            isLicensed,
            ...rest
        }) => {
            return {
                telephoneNumber: isLicensed
                    ? telephoneNumber?.length > 0
                        ? telephoneNumber
                        : config.notAllocated.table
                    : telephoneNumber,
                voicePolicy: isLicensed
                    ? voicePolicy?.length > 0
                        ? voicePolicy
                        : config.notAllocated.table
                    : '',
                dialPlan: isLicensed
                    ? dialPlan?.length > 0
                        ? dialPlan
                        : config.notAllocated.table
                    : '',
                plan: plan ?? 'Use Default',
                isLicensed,
                ...rest,
            };
        },
    );

/** @note legacy logic from old users page */
export async function userSynced(currentCompany, setHasNoUsers) {
    // no app auth auth with user sync = true
    // check all auths, if any have userSync, were good
    const appAuthCompany = await getAppAuthByCompanyId(currentCompany);
    let hasMcAuthType = false;
    let mcAuthTypeId = '';
    appAuthCompany.data.map((companyAuth) => {
        if (companyAuth.appAuthTypeID === 'Microsoft') {
            hasMcAuthType = true;
            mcAuthTypeId = companyAuth.id;
        }
    });

    if (hasMcAuthType) {
        let hasUserSynced = false;
        const appAuthId = await getAppAuthById(mcAuthTypeId);
        appAuthId?.data?.map((auth) => {
            if (auth.userSync) {
                hasUserSynced = true;
            }
        });

        if (hasUserSynced) {
            setHasNoUsers(true);
        }
    }
}
