import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { useServicesDashboardDispatch } from 'context/ServicesDashboardContext';
import getService from 'API/Service/getService';
import { initServiceData } from 'pages/services/new/ServiceDashboard/Services/constants';

function useServiceDashboard({ serviceType, serviceId, enabled }) {
    const { currentCompany: companyId } = useSelector(
        (state) => state.navigationLists,
    );
    const dispatch = useServicesDashboardDispatch();

    return useQuery({
        queryKey: [
            `Service: ${serviceType}, ServiceID: ${serviceId}, CompanyID: ${companyId}`,
        ],
        queryFn: async () => {
            try {
                const res = await getService(serviceId);
                return res.data;
            } catch (error) {
                throw new Error(error);
            }
        },
        onSuccess: (data) => {
            if (initServiceData.serviceTypes.includes(serviceType)) {
                dispatch({
                    type: `${serviceType}/loaded_status`,
                    payload: {
                        serviceType,
                        service: data,
                    },
                });
            }
        },
        enabled,
        retry: 3,
    });
}

export { useServiceDashboard };
