import React, { useState, useEffect, useRef, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Alert } from '@material-ui/lab';

import {
    TabPanel,
    LoadingFieldDropdown,
    IconButton,
} from '../../../components';
import BillingForm, { PARTNER } from '../sharedComponents/BillingForm';
import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import Approval from '../sharedComponents/Approval';
import Script from '../../scripts';
import config from '../../../config.json';
import CustomFieldsForm from '../sharedComponents/CustomFieldsForm';
import PartnersEdit from './edit/PartnerEditForm';
import { axios } from '../../../services/axios';
import PartnerBranding from './edit/PartnerBranding';
import { Box, ChakraProvider } from '@chakra-ui/react';
import chakratheme from '../../../chakratheme';
import DeletePartnerWS from '../sharedComponents/DeletePartnerWS';
import CouldNotDeletePartnerWS from '../sharedComponents/CouldNotDeletePartnerWS';
import { CANNOT_DELETE_MODE } from 'components/tables/ReduxModal';
import { Button } from 'react-bootstrap';

import walkthroughIds from '../walkthroughIds';
import { loadingColumnsConstructor } from 'components/tables/ReduxTable';
import EntityCustomFieldsForm from '../sharedComponents/EntityCustomFieldsForm';

/*
 * Partner component when clicking Partner tab of the sidebar
 */
export default function Index() {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [voicePoliciesAvailable, setVoicePoliciesAvailable] = useState([]);
    const [, force] = useState(null);
    const [partners, setPartners] = useState([]);

    // scope data from state
    const { currentScope, requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    // view data from state
    const { modalTab, breadcrumb } = useSelector((state) => {
        return { ...state.view };
    });

    // navigation data from state
    const { currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // modal Data from state
    const { modalData, modalState, modalMode } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
        };
    });

    // table data from state
    const { state: tableState, loading: tableLoading } = useSelector(
        (state) => {
            return {
                ...state.table,
            };
        },
    );

    // script data from state
    const { script } = useSelector((state) => {
        return {
            script: { ...state.script },
        };
    });

    // setting sdata from state
    const { darkMode } = useSelector((state) => {
        return { ...state.settings };
    });

    // function to simplify setting state
    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };
    const tabList = ['General', 'Billing & Invoice', 'Custom', 'Admin'];

    // default columns for the table
    const defaultColumns = [
        {
            title: 'Friendly Company Name',
            defaultSort: 'asc',

            field: 'name',
        },
        {
            title: 'Legal Entity Name',

            field: 'entityName',
        },
        {
            title: 'Status',
            field: 'status',
            lookup: {
                0: (
                    <Tooltip title="normal">
                        <FiberManualRecordRoundedIcon
                            style={{ color: 'green', fontSize: 'small' }}
                        />
                    </Tooltip>
                ),
                2: (
                    <Tooltip title="normal">
                        <FiberManualRecordRoundedIcon
                            style={{ color: '#c1c92a', fontSize: 'small' }}
                        />
                    </Tooltip>
                ),
                20: (
                    <Tooltip title="normal">
                        <FiberManualRecordRoundedIcon
                            style={{ color: 'red', fontSize: 'small' }}
                        />
                    </Tooltip>
                ),
            },
            lookupDescription: config.companies.statusNavbarJSON,
        },
        {
            title: 'Host Header',
            field: 'hostHeader',
            doNotOverrideSort: true,
        },

        {
            title: 'Default Partner Plan',
            field: 'defaultPartnerPlan.name',
            render: (rowData) => {
                if (!rowData.defaultPartnerPlan) {
                    return (
                        <IconButton
                            placement="top"
                            tooltip="No Default Partner Plan has been set. This partner will not be billed."
                            Icon={
                                <WarningRoundedIcon
                                    style={{ color: '#ff9800' }}
                                />
                            }
                        />
                    );
                }
                return rowData?.defaultPartnerPlan?.name;
            },
        },
        ...config.table.custom,
        {
            title: 'Service Countries',
            field: 'serviceCountries',
            hiddenByColumnsButton: true,
            hidden: true,
        },
        ...config.table.customPartner,
        {
            title: 'Partner Service Countries',
            field: 'pServiceCountries',
            hiddenByColumnsButton: true,
            hidden: true,
        },
        {
            title: 'Partner ID',
            field: 'id',
            hiddenByColumnsButton: true,
            hidden: true,
        },
    ];

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(defaultColumns),
        [defaultColumns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;

    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    useEffect(() => {
        if (modalMode != undefined) {
            axios
                .get(
                    currentScope < 80
                        ? '/partners/' + currentWholesaler
                        : '/partners/all',
                )
                .then((response) => {
                    setPartners(response.data);
                    dispatch({ type: 'CHANGE_TABLE_LOADING' });
                });
        }
    }, [modalMode]);

    // set voice policies based on a API call once regions has been set
    useEffect(() => {
        if (
            Array.isArray(modalState?.regions) &&
            modalState?.regions.length >= 1
        ) {
            axios
                .get(
                    `/VoicePolicies/Regions${
                        '?Regions=' +
                        modalState?.regions
                            .map((v) => {
                                if (typeof v === 'string') {
                                    return v;
                                }
                                return v?.id;
                            })
                            .join('&Regions=')
                    }`,
                )
                .then((res) => {
                    setVoicePoliciesAvailable(res.data);
                })
                .catch((e) => {
                    onModalError(e);
                });
        } else {
            setVoicePoliciesAvailable([]);
        }
    }, [modalState?.regions]);

    // function to render the modal content
    const renderModalContent = () => {
        if (modalMode === 'Edit') {
            return (
                <Box
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'brand.500',
                        },
                    }}>
                    <Tabs
                        value={modalTab}
                        onChange={(e, i) => {
                            dispatch({
                                type: 'CHANGE_MODAL_TAB_VIEW',
                                payload: i,
                            });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        aria-label="full width tabs example">
                        {tabList.map((tab, index) => (
                            <Tab
                                label={tab}
                                key={tab}
                                data-walkthroughid={
                                    walkthroughIds.partners.ufPartnerTab + tab
                                }
                            />
                        ))}
                    </Tabs>
                    <TabPanel value={modalTab} index={0}>
                        <PartnersEdit
                            setState={setState}
                            voicePoliciesAvailable={voicePoliciesAvailable}
                        />
                    </TabPanel>
                    <TabPanel value={modalTab} index={1}>
                        <BillingForm mode={PARTNER} />
                    </TabPanel>
                    <TabPanel value={modalTab} index={2}>
                        <EntityCustomFieldsForm mode={PARTNER} />
                    </TabPanel>
                    <TabPanel value={modalTab} index={3}>
                        <DeletePartnerWS mode={PARTNER} />
                    </TabPanel>
                </Box>
            );
        }

        // render approval
        if (modalMode === 'Approval') {
            return <Approval />;
        }

        if (modalMode === CANNOT_DELETE_MODE) {
            return <CouldNotDeletePartnerWS mode={PARTNER} />;
        }

        if (modalMode === 'Delete') {
            return (
                <Alert style={{ marginTop: 5 }} severity="error">
                    Are you sure you want to delete {modalState?.name} ? This
                    will de-activate {modalState?.name} , set the latest invoice
                    to finalizing, and stop all future billing.
                </Alert>
            );
        }

        // return tab view with partners general form
        return (
            <Box
                sx={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'brand.500',
                    },
                }}>
                <Tabs
                    value={modalTab}
                    onChange={(e, i) => {
                        dispatch({
                            type: 'CHANGE_MODAL_TAB_VIEW',
                            payload: i,
                        });
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    aria-label="full width tabs example">
                    <Tab label="General" />
                </Tabs>
                <TabPanel value={modalTab} index={0}>
                    <PartnersEdit setState={setState} />
                </TabPanel>
            </Box>
        );
    };

    useEffect(() => {
        if (!tableState?.modified && tableState?.data?.length > 0) {
            const statusEnum = tableState?.data?.reduce((prev, current) => {
                if (!prev[current.status]) {
                    switch (current.status) {
                        case 0:
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: 'green',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                        case 3: //for pending approval
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: 'purple',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                        case 20:
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: 'red',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );
                            break;
                        default:
                            prev[current.status] = (
                                <Tooltip
                                    title={
                                        config.companies.statusNavbarJSON[
                                            current.status
                                        ]
                                    }>
                                    <FiberManualRecordRoundedIcon
                                        style={{
                                            color: '#c1c92a',
                                            fontSize: 'small',
                                        }}
                                    />
                                </Tooltip>
                            );

                            break;
                    }
                }
                return prev;
            }, {});

            dispatch({
                type: 'CHANGE_TABLE_STATE',
                payload: {
                    ...tableState,
                    modified: true,

                    columns: defaultColumns.map(({ field, ...rest }) => {
                        if (field === 'status') {
                            return {
                                field,
                                ...rest,
                                lookup: statusEnum,
                                customSort: (l, r) => {
                                    const leftValue =
                                        typeof l === 'object'
                                            ? l.status ?? 0
                                            : l ?? 0;
                                    const rightValue =
                                        typeof r === 'object'
                                            ? r.status ?? 0
                                            : r ?? 0;
                                    return leftValue - rightValue;
                                },
                            };
                        }
                        return {
                            field,
                            ...rest,
                        };
                    }),
                },
            });

            force();
        }
    }, [tableState?.data, tableState?.modified]);

    // breadcrumb for the top of the page
    if (breadcrumb !== 'all') {
        return (
            <>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        href="/"
                        color="inherit"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                                type: 'CHANGE_BREADCRUMB_VIEW',
                                payload: 'all',
                            });
                            dispatch({
                                type: 'RESET_SCRIPT',
                            });
                        }}>
                        All Partners
                    </Link>
                    <div style={{ minWidth: 500 }}>
                        <LoadingFieldDropdown
                            dropDownValue="id"
                            displayField="name"
                            staticEnum={tableState?.data?.filter(
                                ({ status }) => {
                                    return status === 2 || status === 20;
                                },
                            )}
                            onChange={(v) => {
                                dispatch({
                                    type: 'OPEN_SCRIPT',
                                    payload: { id: v },
                                });
                            }}
                            searchable
                            onDisplayDropdown={(value) => {
                                if (typeof value === 'string') {
                                    const { name, status } =
                                        tableState?.data?.find(
                                            (v) => v.id === value,
                                        );
                                    return (
                                        name +
                                        ' - ' +
                                        config.companies.statusNavbarJSON[
                                            status
                                        ]
                                    );
                                }
                                const { name, status } = value;
                                return (
                                    name +
                                    ' - ' +
                                    config.companies.statusNavbarJSON[status]
                                );
                            }}
                            noEmptyOption
                            fieldValue={script.rowData?.id}
                        />
                    </div>
                    <Typography>Script</Typography>
                </Breadcrumbs>
                <br />
                <Script />
            </>
        );
    }

    // move thise outside of renderModalContent, so we can isolate the branding component from the
    // rest of the modal stuff
    if (modalMode === 'Change Logo') {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <PartnerBranding partner={modalData} />
            </ChakraProvider>
        );
    }

    // return table and modal
    return (
        <TableAndModal
            tableFetch={
                currentScope < 80
                    ? '/partners/' + currentWholesaler
                    : '/partners/all'
            }
            tableRef={tableRef}
            modalDelete={`/partner/${modalData?.id}`}
            defaultColumns={defaultColumns}
            columns={tableLoading ? loadingColumns : defaultColumns}
            size="xl"
            newPage={modalMode !== 'Change Logo'}
            fullpage
            dynamicScope={{ minimumScope: 60 }}
            modalFetch={
                modalMode === 'Approval'
                    ? '/company/' + modalData?.id + '/approval/'
                    : '/partner/' + modalData?.id
            }
            modalAdd="/partner/"
            modalUpdate="/partner/"
            onModalChangeLogo={() => {
                var result = [];
                if (modalState?.lightModeFile) {
                    var formData = new FormData();
                    formData.append('file', modalState?.lightModeFile);
                    result.push(
                        axios.post(
                            '/partner/' + modalData?.id + '/logo',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                                params: { mode: 'light' },
                            },
                        ),
                    );
                }
                if (modalState?.darkModeFile) {
                    var formData2 = new FormData();
                    formData2.append('file', modalState?.darkModeFile);
                    result.push(
                        axios.post(
                            '/partner/' + modalData?.id + '/logo',
                            formData2,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                                params: { mode: 'dark' },
                            },
                        ),
                    );
                }
                if (modalState?.faviconFile) {
                    var formData3 = new FormData();
                    formData3.append('file', modalState?.faviconFile);
                    result.push(
                        axios.post(
                            '/partner/' + modalData?.id + '/logo',
                            formData3,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                                params: { mode: 'favicon' },
                            },
                        ),
                    );
                }
                return Promise.all(result);
            }}
            modalUpload={(freshData, state) => {
                if (state?.trialEndLocal) {
                    return {
                        ...state,
                        trialEnd: new Date(state?.trialEndLocal)?.toUTCString(),
                        enabled: true, //hardcode for partners
                        trunkLocationID: 'MEL',
                    };
                }
                if (modalMode === 'Add') {
                    return {
                        ...freshData,
                        ...state,
                        trunkLocationID: 'MEL',
                        enabled: true, //hardcode for partners
                    };
                }

                return {
                    ...freshData,
                    ...state,
                    trunkLocationID: 'MEL',
                    enabled: true, //hardcode for partners
                };
            }}
            modalInitState={{
                isActive: true,
                wholesalerID: currentWholesaler,
                mode: darkMode ? 'dark' : 'light',
            }}
            title="Partners"
            add
            groupable
            filterable
            rowActions={{
                editable: true,
                changeLogo: requiredScope >= 60,
            }}
            modalButtons={
                modalMode === CANNOT_DELETE_MODE && (
                    <Button
                        variant="danger"
                        disabled={modalState?.isNextButtonDisabled}
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Delete',
                                    uploading: false,
                                    loading: false,
                                    data: modalData,
                                    state: modalState,
                                },
                            });
                        }}
                        data-walkthroughid={
                            walkthroughIds.partners.ufDeleteBtn
                        }>
                        Delete
                    </Button>
                )
            }
            onModalSucceed={
                modalMode === 'Add' &&
                function (result) {
                    dispatch({
                        type: 'OPEN_MODAL',
                        payload: {
                            mode: 'Edit',
                            data: result?.data,
                        },
                    });
                }
            }
            modalValidate={() => {
                if (modalMode === 'Approval') {
                    return true;
                }
                if (modalMode === 'Change Logo') {
                    const newErrorState = {
                        lightModeFile: modalState?.lightModeFile?.type
                            ? modalState?.lightModeFile?.type !== 'image/png' &&
                              'Only png file is allowed.'
                            : false,
                        darkModeFile: modalState?.darkModeFile?.type
                            ? modalState?.darkModeFile?.type !== 'image/png' &&
                              'Only png file is allowed.'
                            : false,
                        faviconFile: modalState?.faviconFile?.type
                            ? modalState?.faviconFile?.type !== 'image/png' &&
                              'Only png file is allowed.'
                            : false,
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !(
                        newErrorState.darkModeFile ||
                        newErrorState.lightModeFile ||
                        newErrorState.faviconFile
                    );
                } else {
                    const newErrorState = {
                        name:
                            (!modalState?.name ||
                                modalState?.name?.length < 1) &&
                            'Field is required.',
                        hostHeader:
                            modalState?.hostHeader &&
                            new RegExp(':|/').test(modalState.hostHeader) &&
                            'Host header cannot contain protocol in front of URL.',
                        voicePolicies:
                            modalState?.voicePolicies?.some(({ id }) => {
                                return !voicePoliciesAvailable.find(
                                    (vpItem) => vpItem.id === id,
                                );
                            }) && 'Some Voice Policies are not valid.',
                        pAttribute1:
                            modalState?.pAttribute1?.length > 100 &&
                            'Maximum field length is 100 characters. ',
                        pAttribute2:
                            modalState?.pAttribute2?.length > 100 &&
                            'Maximum field length is 100 characters. ',
                        pAttribute3:
                            modalState?.pAttribute3?.length > 100 &&
                            'Maximum field length is 100 characters. ',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !(
                        newErrorState.name ||
                        newErrorState.hostHeader ||
                        newErrorState.pAttribute1 ||
                        newErrorState.pAttribute2 ||
                        newErrorState.pAttribute3 ||
                        newErrorState.voicePolicies
                    );
                }
            }}
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(
                modalData,
                'name',
                ' Partners ',
                modalMode === CANNOT_DELETE_MODE &&
                    !modalState?.isNextButtonDisabled
                    ? 'Delete'
                    : modalMode,
            )}
            modalContent={renderModalContent()}
        />
    );
}
