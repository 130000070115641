import {axios} from '../../services/axios';

export default async function getAvailableServiceProfiles(companyID) {
    try {
        return axios
            .get(`/Services/Company/${companyID}/ServiceProfiles/Available`)
            .then((resp) => resp.data);
    } catch (error) {
        console.log(error);
    }
}
