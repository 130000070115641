import { useMutation } from '@tanstack/react-query';
import { postRequestNumberVerification } from 'API/TPMTrial';

function useRequestNumber(id, mpn, delay) {
    return useMutation({
        mutationKey: ['requestNumber'],
        mutationFn: () =>
            postRequestNumberVerification({
                serviceID: id,
                phonenumber: mpn,
            }),
        onSuccess: () => {
            console.log('Mutation successful');
        },
        retryDelay: delay,
        retry: 3,
    });
}

export default useRequestNumber;
