import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@/components/v4';
import ModalCloseButton from '@/components/v4/ModalCloseButton';
import { axios } from '@/services/axios';
import { useQueryClient } from '@tanstack/react-query';
import { useSingleToast } from 'hooks/useSingleToast';
import { useState } from 'react';

function LeaveVgModal({ isOpen, onClose, vg }) {
    const { successToast, errorToast } = useSingleToast();

    const [isLeaving, setIsLeaving] = useState(false);

    const queryClient = useQueryClient();

    const leaveVg = async () => {
        setIsLeaving(true);
        try {
            await axios.post(`/visibilitygroup/${vg.id}/leave`);
            onClose();
            successToast('Successfully left visibility group!');
            queryClient.invalidateQueries({ queryKey: ['visibilityGroups'] });
        } catch (e) {
            errorToast(
                e?.response?.data?.message ||
                    'Unable to leave visibility group.',
            );
        } finally {
            setIsLeaving(false);
        }
    };

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent
                as="form"
                data-walkthroughid={'visibilityGroupModal.leaveModal'}>
                <ModalHeader>Leave Visibility Group</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you would like to leave?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        colorScheme="red"
                        minW="100px"
                        isLoading={isLeaving}
                        onClick={leaveVg}>
                        Leave
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default LeaveVgModal;
