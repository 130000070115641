import { Outlet } from 'react-router-dom';

import { VisibilityGroupsProvider as VgsContextProvider } from 'context/VisibilityGroupsContext';

function VisibilityGroupsProvider() {
    return (
        <VgsContextProvider>
            <Outlet />
        </VgsContextProvider>
    );
}
export default VisibilityGroupsProvider;
