import {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';
import {
    chakraDatepicker,
    chakraDatepickerDarkMode,
    chakraSelectDarkMode,
    defaultChakraSelectStyle,
} from '@/constants';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import {
    Box,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    InputGroup,
    InputRightElement,
    Skeleton,
    Stack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getAdminPermission } from 'API/AdminUser';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { Select } from 'chakra-react-select';
import { Input } from 'components/v4';
import { useSelector } from 'react-redux';
import walkthroughIds from '../../walkthroughIds';

export default function EditGeneral({
    apiUser,
    setApiUser,
    errors,
    setErrors,
    isLoading,
    apiUserLoading,
}) {
    const { requiredScope: accountScope } = useSelector((state) => state.login);
    const { currentCompany } = useSelector((state) => state.navigationLists);
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const { data: permissionSets, isFetching: permissionSetLoading } = useQuery(
        {
            queryKey: ['permissionSets', currentCompany],
            queryFn: () => getAdminPermission({ accountScope, currentCompany }),
            enabled: Boolean(currentCompany),
            onSuccess: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            },
            refetchOnWindowFocus: false,
        },
    );

    const changeHandler = (key, value) => {
        setApiUser({
            ...apiUser,
            [key]: value,
        });
    };

    return (
        <Stack spacing="3">
            <Stack
                spacing="6"
                direction={{
                    base: 'column',
                    md: 'row',
                }}>
                <FormControl isInvalid={errors.name.isError} isRequired>
                    <FormLabel>Name</FormLabel>
                    {apiUserLoading ? (
                        <Skeleton>
                            {' '}
                            <Input />{' '}
                        </Skeleton>
                    ) : (
                        <Input
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersGeneral.ufName
                            }
                            isDisabled={isLoading}
                            type="text"
                            placeholder="Please enter a name"
                            name="name"
                            onChange={(e) => {
                                changeHandler('name', e.target.value);
                                if (e.target.value.length < 1) {
                                    setErrors({
                                        ...errors,
                                        name: {
                                            isError: true,
                                            message: 'Field is required.',
                                        },
                                    });
                                } else {
                                    setErrors({
                                        ...errors,
                                        name: {
                                            isError: false,
                                            message: '',
                                        },
                                    });
                                }
                            }}
                            value={apiUser.name}
                        />
                    )}

                    {errors.name.isError ? (
                        <FormErrorMessage>
                            {errors.name.message}
                        </FormErrorMessage>
                    ) : (
                        <FormHelperText>Eg. API for Billing</FormHelperText>
                    )}
                </FormControl>
            </Stack>

            <Stack spacing="6" direction="row">
                <FormControl id="isActive">
                    <Box display="flex" alignItems="center">
                        {apiUserLoading ? (
                            <Skeleton>
                                {' '}
                                <Checkbox mb={0} />{' '}
                            </Skeleton>
                        ) : (
                            <Checkbox
                                isDisabled={isLoading}
                                isChecked={apiUser?.isActive}
                                onChange={() =>
                                    changeHandler(
                                        'isActive',
                                        !apiUser?.isActive,
                                    )
                                }
                                colorScheme="whatsapp"
                                name="isActive"
                                mb={0}
                                data-walkthroughid={
                                    walkthroughIds.adminApiUsersGeneral
                                        .ufIsActive
                                }
                            />
                        )}
                        <FormLabel mb={0} ml={2}>
                            Active
                        </FormLabel>
                    </Box>
                </FormControl>
            </Stack>
            <FormControl
                mt={3}
                isInvalid={errors.permission.isError}
                isRequired
                data-walkthroughid={
                    walkthroughIds.adminApiUsersGeneral.ufPermission
                }>
                <FormLabel>Permission</FormLabel>
                {permissionSetLoading || apiUserLoading ? (
                    <Skeleton>
                        {' '}
                        <Select />{' '}
                    </Skeleton>
                ) : (
                    <Select
                        isDisabled={isLoading}
                        value={permissionSets?.find(
                            (item) => item.id === apiUser.permissionSetID,
                        )}
                        options={permissionSets}
                        name="permissionSetID"
                        onChange={(e) => {
                            changeHandler('permissionSetID', e.id);
                            setErrors({
                                ...errors,
                                permission: {
                                    isError: false,
                                    message: '',
                                },
                            });
                        }}
                        placeholder="Select Permission"
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(i) => i.id}
                        isLoading={permissionSetLoading}
                        // two lines below to overlay calendar/time icon
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        chakraStyles={{
                            ...defaultChakraSelectStyle,
                            ...chakraSelectDark,
                        }}
                        selectedOptionColor="brand"
                    />
                )}
            </FormControl>

            <Stack
                spacing="6"
                direction={{
                    base: 'column',
                    md: 'row',
                }}>
                <FormControl isRequired>
                    <FormLabel>Expiration</FormLabel>

                    {apiUserLoading ? (
                        <Skeleton>
                            {' '}
                            <Input />{' '}
                        </Skeleton>
                    ) : (
                        <InputGroup
                            sx={
                                darkMode
                                    ? chakraDatepickerDarkMode
                                    : chakraDatepicker
                            }
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersGeneral.ufExpiration
                            }>
                            <SingleDatepicker
                                disabled={isLoading}
                                minDate={new Date()}
                                name="date-input"
                                date={apiUser.expirationDate}
                                onDateChange={(value) => {
                                    changeHandler('expirationDate', value);
                                }}
                                configs={{
                                    dateFormat: 'ccc, dd MMM, yyyy zzz',
                                }}
                                propsConfigs={
                                    darkMode
                                        ? ChakraDatePickerDarkModeStyles
                                        : ChakraDatePickerStyles
                                }
                            />
                            <InputRightElement
                                color={darkMode ? 'white' : 'gray.500'}
                                pointerEvents="none"
                                children={<CalendarIcon />}
                            />
                        </InputGroup>
                    )}

                    <FormHelperText>ddd, dd MMM, yyyy zzz</FormHelperText>
                </FormControl>

                <FormControl mt={3} isInvalid={errors.expirationTime.isError}>
                    <FormLabel>Time</FormLabel>
                    {apiUserLoading ? (
                        <Skeleton>
                            {' '}
                            <Input />{' '}
                        </Skeleton>
                    ) : (
                        <InputGroup
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersGeneral.ufTime
                            }>
                            <Input
                                isDisabled={isLoading}
                                type="time"
                                value={apiUser.expirationTime}
                                onChange={(e) => {
                                    changeHandler(
                                        'expirationTime',
                                        e.target.value,
                                    );
                                }}
                            />
                            <InputRightElement
                                color={darkMode ? 'white' : 'gray.500'}
                                pointerEvents="none"
                                children={<TimeIcon />}
                            />
                        </InputGroup>
                    )}
                    {errors.expirationTime.isError ? (
                        <FormErrorMessage>
                            {errors.expirationTime.message}
                        </FormErrorMessage>
                    ) : (
                        <FormHelperText>HH:mm tt</FormHelperText>
                    )}
                </FormControl>
            </Stack>
        </Stack>
    );
}
