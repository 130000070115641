import { TextField } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import React from 'react';
import { useDispatch } from 'react-redux';

/*
 * if multiline input, return this text editor
 * used for adding chip to text field
 * matching id to filOnClickMutliSelect active text area
 */
const TextEditor = ({
    textfield = {},
    id = 'textfieldarea1',
    field,
    setCursorPosition,
    dataWalkthroughId,
}) => {
    const inputRef = React.useRef();
    const dispatch = useDispatch();
    return (
        <div data-walkthroughid={dataWalkthroughId}>
            <Row>
                <Col>
                    <TextField
                        id={id}
                        fullWidth
                        multiline
                        inputRef={inputRef}
                        rows={10}
                        onSelect={() => {
                            /* Manual tracking of cursor position when input is clicked */
                            setCursorPosition(inputRef.current.selectionStart);
                        }}
                        rowsMax={10}
                        defaultValue={textfield.value}
                        {...textfield}
                        onChange={(e) => {
                            textfield.onChange(e);
                            setCursorPosition(inputRef.current.selectionStart);
                        }}
                        onFocus={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    activeTextareaID: id,
                                    activeTextareaField: field,
                                },
                            });
                            setCursorPosition(inputRef.current.selectionStart);
                            if (Boolean(textfield.onFocus)) {
                                textfield.onFocus(e);
                            }
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default TextEditor;
