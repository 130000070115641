export const _ADDRESS_SCHEMA = {
    description: '',
    buildingType: 'Units',
    building1stNumber: '6',
    building1stSuffix: 'A',
    building2ndNumber: '8',
    building2ndSuffix: 'C',
    buildingFloorType: 'Floor',
    buildingFloorNumber: '3',
    buildingFloorNumberSuffix: 'A',
    buildingProperty: 'Treasury Building',
    buildingLocation: 'Rear',
    streetHouseNumber1: '15',
    streetHouseNumber1Suffix: 'A',
    streetHouseNumber2: '19',
    streetHouseNumber2Suffix: 'C',
    streetName1: 'Church',
    streetType1: 'Street',
    streetSuffix1: 'South',
    streetName2: '',
    streetType2: '',
    streetSuffix2: '',
    locality: 'Ashburton',
    district: '',
    state: 'Vic',
    postcode: '3147',
    country: '',
    googlePlaceID: '',
}; 
export const ADDRESS_SCHEMA = {
    description: '',
    buildingType: '',
    building1stNumber: '',
    building1stSuffix: '',
    building2ndNumber: '',
    building2ndSuffix: '',
    buildingFloorType: '',
    buildingFloorNumber: '',
    buildingFloorNumberSuffix: '',
    buildingProperty: '',
    buildingLocation: '',
    streetHouseNumber1: '',
    streetHouseNumber1Suffix: '',
    streetHouseNumber2: '',
    streetHouseNumber2Suffix: '',
    streetName1: '',
    streetType1: '',
    streetSuffix1: '',
    streetName2: '',
    streetType2: '',
    streetSuffix2: '',
    locality: '',
    district: '',
    state: '',
    postcode: '',
    country: '',
    googlePlaceID: '',
};
