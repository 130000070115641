import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import useDetectFileType from 'hooks/useDetectFileType';

function usePublicParnerEndpoint() {
    const dispatch = useDispatch();
    const [publicParnerData, setPublicParnerData] = useState(null);
    const [imgPath, setImgPath] = useState(null);

    useEffect(() => {
        async function getPublicPartnerData() {
            try {
                fetch(
                    `${import.meta.env.VITE_APP_API_URL}/theme/${
                        window.location.host
                    }`,
                    {
                        headers: {
                            'x-RequestScope': '20',
                        },
                    },
                ).then(async (response) => {
                    const data = await response.json();
                    setPublicParnerData(data);
                    dispatch({
                        type: 'SET_SBC_DOMAIN',
                        payload: data.sbcDomain,
                    });
                    dispatch({
                        type: 'CHANGE_IMAGE_PATH_LIGHT_MODE',
                        payload: data.lightLogo,
                    });
                    dispatch({
                        type: 'CHANGE_IMAGE_PATH_DARK_MODE',
                        payload: data.darkLogo,
                    });

                    dispatch({
                        type: 'HEADER_FAVICON_SUCCESS',
                        payload: data.favicon,
                    });
                    dispatch({
                        type: 'HEADER_TITLE_SUCCESS',
                        payload: data.titleName,
                    });
                    dispatch({
                        type: 'SET_SUPPORT_URL',
                        payload: data.extLinkSupport,
                    });
                    setImgPath(data.lightLogo);
                });
            } catch (error) {}
        }
        if (!imgPath) {
            getPublicPartnerData();
        }
    }, [imgPath]);

    return publicParnerData;
}

function usePartnerProvidedBackground(ref, optionsOveride) {
    const data = usePublicParnerEndpoint();

    async function renderPartnerProvidedBackground(partnerData) {
        switch (partnerData.backgroundType) {
            // Default bg color
            case 0:
                ref.current.style.backgroundColor = `#F5F5F5`;
                ref.current.style.backgroundImage = `none`;
                break;
            // Solid defined bg color
            case 1:
                const bgColor = partnerData.backgroundColor.includes('#')
                    ? partnerData.backgroundColor
                    : `#${partnerData.backgroundColor}`;
                ref.current.style.backgroundColor = bgColor;
                ref.current.style.backgroundImage = `none`;
                break;
            // Defined bg image
            case 2:
                ref.current.style.backgroundPosition = 'center';
                ref.current.style.backgroundRepeat = 'no-repeat';
                ref.current.style.backgroundSize = 'cover';
                ref.current.style.backgroundImage = `url('${partnerData.backgroundImage}')`;
                break;
            // Bing image of the day
            case 3:
                ref.current.style.backgroundPosition = 'center';
                ref.current.style.backgroundRepeat = 'no-repeat';
                ref.current.style.backgroundSize = 'cover';
                ref.current.style.backgroundImage = `url('https://bing.biturl.top/?resolution=1920&format=image&index=0')`;
                break;
            // NASA image of the day
            case 4:
                await fetch(
                    'https://api.nasa.gov/planetary/apod?api_key=VKrdDxR2J9gaBTICmIWsDGN57FIiOLFg4tZaQKHe&thumbs=true',
                    {
                        method: 'GET',
                    },
                ).then(async (response) => {
                    const { hdurl, thumbnail_url } = await response.json();
                    ref.current.style.backgroundPosition = 'center';
                    ref.current.style.backgroundRepeat = 'no-repeat';
                    ref.current.style.backgroundSize = 'cover';
                    ref.current.style.backgroundImage = `url('${
                        hdurl || thumbnail_url
                    }')`;
                });
                break;
        }
    }

    useEffect(() => {
        if (optionsOveride) {
            renderPartnerProvidedBackground(optionsOveride);
        } else if (data) {
            renderPartnerProvidedBackground(data);
        }
    }, [data, optionsOveride]);
}

export default function TcapPageBackgroundLoader({
    children,
    optionsOveride,
    ...props
}) {
    const authPageContainerRef = useRef(null);
    usePartnerProvidedBackground(authPageContainerRef, optionsOveride);

    return (
        <Box ref={authPageContainerRef} sx={{ display: 'flex' }} {...props}>
            {children}
        </Box>
    );
}
