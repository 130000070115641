import { Alert } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const STATUSES = {
    info: { colorScheme: 'blue' },
    warning: { colorScheme: 'orange' },
    success: { colorScheme: 'green' },
    error: { colorScheme: 'red' },
};

const variant = (status) => ({
    'inline-accent': {
        borderRadius: 'md',
        borderInlineWidth: '3px',
        borderColor: `var(--chakra-colors-${STATUSES[status].colorScheme}-500)`,
        py: 2,
        minH: '40px',
    },
});
/**
 * @typedef {Object} ChakraAlertProps
 * @property {import('@chakra-ui/react').AlertProps} props
 * @property {import('react').ReactNode} props.children
 * @property {'left-accent' | 'inline-accent' | 'solid' | 'subtle'} props.variant
 */

/**
 * @param {import('@chakra-ui/react').AlertProps & ChakraAlertProps} props
 */
export default function ChakraAlert({ children, ...props }) {
    const { darkMode } = useSelector((state) => state.settings);
    const setAlertStatusBg = (status) => {
        switch (status) {
            case 'error': {
                return darkMode ? 'rgba(254, 178, 178, 0.16)' : null;
            }

            case 'info': {
                return darkMode ? 'rgba(144, 205, 244, 0.16)' : null;
            }

            case 'success': {
                return darkMode ? 'rgba(154, 230, 180, 0.16)' : null;
            }

            case 'warning': {
                return darkMode ? 'rgba(251, 211, 141, 0.16)' : null;
            }

            default: {
                return null;
            }
        }
    };
    const alertBg = setAlertStatusBg(props.status);
    return (
        <Alert
            {...props}
            overflow="initial"
            bg={alertBg}
            variant={
                props.variant === 'inline-accent'
                    ? 'left-accent'
                    : props.variant
            }
            sx={{
                ...variant(props.status)['inline-accent'],
                ...props.sx,
            }}
        >
            {children}
        </Alert>
    );
}
