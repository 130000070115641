//TODO: refactor
// used for nav list at the top of the page
const getCurrentCompany = () => {
    return sessionStorage.getItem('currentCompany');
};
const getCurrentPartner = () => {
    return sessionStorage.getItem('currentPartner');
};
const getCurrentWholesaler = () => {
    return sessionStorage.getItem('currentWholesaler');
};

const initNavListState = {
    companiesAndPartners: [],
    wholesalers: [],
    companiesAndPartnersLoading: true,
    wholesalersLoading: true,
    currentCompanyData: {
        isTrial: false,
    },
    currentPartner: getCurrentPartner() === 'null' ? null : getCurrentPartner(),
    currentWholesaler:
        getCurrentWholesaler() === 'null' ? null : getCurrentWholesaler(),
    currentCompany: getCurrentCompany() === 'null' ? null : getCurrentCompany(),
};

export const navigationListsReducer = (state = initNavListState, action) => {
    switch (action.type) {
        case 'CHANGE_COMPANIES_AND_PARTNERS_LOADING':
            return { ...state, companiesAndPartnersLoading: action.payload };
        case 'CHANGE_WHOLESALERS_LOADING':
            return { ...state, wholesalersLoading: action.payload };
        case 'CHANGE_COMPANIES_AND_PARTNERS':
            return { ...state, companiesAndPartners: action.payload };
        case 'CHANGE_WHOLESALERS':
            return { ...state, wholesalers: action.payload };
        case 'CHANGE_CURRENT_COMPANY_DATA':
            return { ...state, currentCompanyData: action.payload };
        case 'CHANGE_CURRENT_COMPANY':
            sessionStorage.setItem('currentCompany', action.payload);
            return { ...state, currentCompany: action.payload };
        case 'CHANGE_CURRENT_PARTNER':
            sessionStorage.setItem('currentPartner', action.payload);
            return { ...state, currentPartner: action.payload };
        case 'CHANGE_CURRENT_WHOLESALER':
            sessionStorage.setItem('currentWholesaler', action.payload);
            return { ...state, currentWholesaler: action.payload };
        case 'LOGOUT':
            sessionStorage.clear();
            return {
                companiesAndPartners: [],
                wholesalers: [],
                companiesAndPartnersLoading: true,
                wholesalersLoading: true,
                currentCompanyData: {},
                currentPartner: null,
                currentWholesaler: null,
                currentCompany: null,
            };

        default:
            return state;
    }
};
