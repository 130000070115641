//URL reducer
//TODO: remove this due to react router
export const getPathname = () => {
    return sessionStorage.getItem('pathname');
};

const initURLState = {
    pathname: getPathname(),
    redirect: null,
    usingRedux: true,
};
const PAGES_NOT_USING_REDUX = ['/services/provisionedservices'];
export const urlReducer = (state = initURLState, action) => {
    switch (action.type) {
        case 'REDIRECT':
            sessionStorage.setItem('pathname', action.payload.pathname);
            const usingRedux = !PAGES_NOT_USING_REDUX.includes(
                action.payload.pathname,
            );
            return {
                ...state,
                pathname: action.payload.pathname,
                usingRedux,
            };
        case 'RELOGIN':
            return {
                ...state,
                redirect: state.pathname,
            };
        case 'RELOAD_SAME_PAGE':
            return {
                ...state,
                pathname: action.payload.pathname,
                ...action.payload,
            };
        case 'CLEAR_TOKEN':
            return {
                pathname: getPathname(),
                redirect: null,
                usingRedux: true,
            };
        default:
            return state;
    }
};
