import { classNames } from '@/utils/classNames';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import ConfirmationModal from './ConfirmationModal';
import styles from './styles';

/**
 * @typedef {Object} ServiceProfileCardProps
 * @property {React.ReactNode} children
 * @param {ServiceProfileCardProps & import('@chakra-ui/react').BoxProps} props
 */
export default function ServiceProfileCard({ children, ...props }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { darkMode } = useSelector((state) => state.settings);
    const serviceName = props['data-walkthroughid']?.split('/')[4];

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onClick(e);
        onClose();
    };

    const handleOnClick = () => {
        // no confirmation when vendor defined required as will redirect to form page
        if (props?.vendorDefined) {
            props.onClick();
            return;
        }
        // show modal confirmation on creating a service
        onOpen();
    };

    return (
        <>
            <Box
                sx={styles.container}
                {...props}
                display={'flex'}
                flexDir={'column'}
                className={classNames(
                    darkMode
                        ? 'bg-[var(--chakra-colors-gray-tcap)]'
                        : 'bg-white',
                    '!shadow-lg',
                )}
                borderRadius="lg"
                onClick={() => handleOnClick()}>
                {children}
            </Box>

            <ConfirmationModal
                serviceName={serviceName}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                vendorDefined={props?.vendorDefined}
                handleSubmit={handleSubmit}
            />
        </>
    );
}
