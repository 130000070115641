import React, { useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';

import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';
import {axios} from '../../../services/axios';
import Edit from './edit/TemplateEdit';

// Component for 'Template Table' in the GA section of the sidebar
const Templates = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const {modalData, modalState, modalMode} = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
        };
    });

    // default columns for the table
    const defaultColumns = [
        {
            title: 'ID',
            defaultSort: 'asc',
            field: 'id',
        },
        {
            title: 'Name',
            field: 'name',
        },
    ];

    return (
        <TableAndModal
            tableFetch="/AATemplates"
            tableRef={tableRef}
            newPage
            modalFetch={`/AATemplate/${modalData?.id}`}
            modalDelete={`/AATemplate/${modalData?.id}`}
            overrideModalAdd={() => {
                axios
                    .post('/AATemplate/', modalState)
                    .then((res) => {
                        if (modalState?.newImage) {
                            var formData = new FormData();
                            formData.append('file', modalState?.newImage);
                            axios
                                .post(
                                    '/AATemplate/' + modalState?.id + '/Image',
                                    formData,
                                    {
                                        headers: {
                                            'Content-Type':
                                                'multipart/form-data',
                                        },
                                    },
                                )
                                .then((res) => {
                                    dispatch({
                                        type: 'RESET_MODAL',
                                    });
                                    dispatch({
                                        type: 'RESET_TABLE',
                                    });
                                });
                        } else {
                            dispatch({
                                type: 'RESET_MODAL',
                            });
                            dispatch({
                                type: 'RESET_TABLE',
                            });
                        }
                    })
                    .catch((e) => {
                        onModalError(e);
                    });
            }}
            overrideModalUpdate={() => {
                axios
                    .put('/AATemplate/', modalState)
                    .then((res) => {
                        if (modalState?.newImage) {
                            var formData = new FormData();
                            formData.append('file', modalState?.newImage);
                            axios
                                .post(
                                    '/AATemplate/' + modalState?.id + '/Image',
                                    formData,
                                    {
                                        headers: {
                                            'Content-Type':
                                                'multipart/form-data',
                                        },
                                    },
                                )
                                .then((res) => {
                                    dispatch({
                                        type: 'RESET_MODAL',
                                    });
                                    dispatch({
                                        type: 'RESET_TABLE',
                                    });
                                });
                        } else {
                            dispatch({
                                type: 'RESET_MODAL',
                            });
                            dispatch({
                                type: 'RESET_TABLE',
                            });
                        }
                    })
                    .catch((e) => {
                        onModalError(e);
                    });
            }}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Templates"
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            rowActions={{
                editable: true,
                delete: true,
            }}
            modalValidate={() => {
                const newErrorState = {
                    newImage: modalState?.newImage?.type
                        ? modalState?.newImage?.type !== 'image/png' &&
                          'Only png file is allowed.'
                        : false,
                    id: modalState?.id?.length < 1 && 'ID is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(newErrorState.newImage || newErrorState.id);
            }}
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(modalData, 'name', ' Templates ', modalMode)}
            modalContent={<Edit />}
        />
    );
};

export default Templates;
