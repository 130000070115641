/* eslint-disable no-unused-vars */
import { useState, useEffect, memo } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { Box } from '@chakra-ui/react';
import { EditIcon, AddIcon, ViewIcon } from '@chakra-ui/icons';

// Utils
import trunkStatusCodeToText from 'utils/trunkStatusCodeToText';

//Components
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardSbcStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardSbcStatusbar';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import { MenuItem } from 'components/v4';

//APIs
import { useQuery } from '@tanstack/react-query';
import getTrunks from 'API/Trunk/getTrunks';

// Assets
import sbcLogo from 'assets/images/SbcaasLogo.png';
import { useDashboardMenu } from 'context/DashboardMenuContext';
export const QUERY_CACHE_TIME = 1; // cache time set to 1 ms. (converted to milliseconds)
export const REFETCH_INTERVAL = 15 * 1000; // refetch interval set to 15 secs. (converted to milliseconds)

export default function SBCaaSCard({ service }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isQueryDisabled } = useDashboardMenu();
    const currentCompany = useSelector(
        (state) => state.navigationLists.currentCompany,
    );
    const accountScope = useSelector((state) => state.login.requiredScope);
    const [data, setData] = useState(service);
    const { data: trunkData } = useQuery(
        ['getTrunks'],
        () => getTrunks(currentCompany),
        {
            cacheTime: QUERY_CACHE_TIME,
            refetchInterval: REFETCH_INTERVAL,
            enabled: !isQueryDisabled,
        },
    );

    const [actionPermissions, setActionPermissions] = useState({
        menuEditServiceScope: 40,
    });
    const [cardStatus, setCardStatus] = useState({
        sipCount: '...',
        channelCount: '...',
        status: '...',
        statusCode: null,
    });

    const classes = classNames({
        flash: service.changes === 'added',
    });

    function calculateSbcStatus(trunkData) {
        let sipCount = 0;
        let channelCount = 0;
        let status = '';
        let statusCode = null;

        trunkData?.data?.map((trunk) => {
            // Count the SIP trunks
            if (trunk.trunkType.sipEnabled) {
                sipCount++;
            }

            // Count the channels
            channelCount += trunk.channels;
        });

        // Find the worst status
        const isOk = trunkData.data
            .map((trunk) => {
                if (trunk.status >= 0 && trunk.status <= 4) {
                    return trunk.status;
                }
            })
            .filter((n) => !Number(n) && typeof n !== 'undefined');

        const isWarning = trunkData.data
            .map((trunk) => {
                if (trunk.status === 8 || trunk.status === 9) {
                    return trunk.status;
                }
            })
            .filter((n) => !Number(n) && typeof n !== 'undefined');
        const isError = trunkData.data
            .map((trunk) => {
                if (trunk.status === 20) {
                    return trunk.status;
                }
            })
            .filter((n) => !Number(n) && typeof n !== 'undefined');

        if (isError.length) {
            statusCode = Math.max(...isError);
            status = trunkStatusCodeToText(statusCode);
        } else if (isWarning.length) {
            statusCode = Math.max(...isWarning);
            status = trunkStatusCodeToText(statusCode);
        } else if (isOk.length) {
            statusCode = Math.max(...isOk);
            status = trunkStatusCodeToText(statusCode);
        }

        setCardStatus({
            sipCount,
            channelCount,
            status,
            statusCode,
        });
    }

    useEffect(() => {
        if (trunkData) {
            setTimeout(() => {
                calculateSbcStatus(trunkData);
            }, 500);
        }
    }, [trunkData]);

    const addTrunks = () => {
        // get parent menu item Accounts
        let tel = document.querySelector('.Telephony');
        // if only 1 then the menu item is closed
        if (tel.children.length == 1) {
            var divElement = tel.querySelector('div');
            // create on click event
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            divElement.dispatchEvent(clickEvent);
        }

        // redirect is to select the sub menu item
        // scope 20 to focus on currently managing company
        dispatch({
            type: 'REDIRECT',
            payload: {
                pathname: '/telephony/trunks',
                scope: 20,
            },
        });
        navigate('/trunks/add');
    };

    const viewTrunks = () => {
        // get parent menu item Accounts
        let tel = document.querySelector('.Telephony');
        // if only 1 then the menu item is closed
        if (tel.children.length == 1) {
            var divElement = tel.querySelector('div');
            // create on click event
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            divElement.dispatchEvent(clickEvent);
        }

        // redirect is to select the sub menu item
        // scope 20 to focus on currently managing company
        dispatch({
            type: 'REDIRECT',
            payload: {
                pathname: '/telephony/trunks',
                scope: 20,
            },
        });
        navigate('/trunks');
    };

    return (
        <DashboardServiceCard
            className={classes}
            dataWalkthroughId={service.name}>
            <DashboardServiceCardSbcStatusbar
                status={
                    cardStatus.statusCode ??
                    service?.statusDisplay?.statusDisplayLevel
                }
            />
            <DashboardServiceCardHeader>
                <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                    {data.name}
                </Text>
                <DashboardServiceCardHeaderActionButton
                    dataWalkthroughId={service.name}>
                    <MenuItem
                        icon={<AddIcon />}
                        onClick={addTrunks}
                        data-walkthroughid={`/dashboard-service/card/${service.name}/add-trunk`}>
                        <Text marginBottom={'0'}>Add Trunk</Text>
                    </MenuItem>
                    <MenuItem icon={<ViewIcon />} onClick={viewTrunks}>
                        <Text marginBottom={'0'}>View Trunks</Text>
                    </MenuItem>
                    {accountScope >= actionPermissions.menuEditServiceScope && (
                        <Link
                            className="bg-transparent !no-underline hover:text-inherit"
                            to={`/services/sbcaas/${data.id}/edit`}
                            state={{
                                serviceId: data.id,
                                data,
                            }}>
                            <MenuItem
                                icon={<EditIcon />}
                                data-walkthroughid={`/dashboard-service/card/${service.name}/edit-trunk`}>
                                <Text marginBottom={'0'}>Edit Service</Text>
                            </MenuItem>
                        </Link>
                    )}
                </DashboardServiceCardHeaderActionButton>
            </DashboardServiceCardHeader>
            <DashboardServiceCardContent>
                <Box>
                    <Image
                        src={sbcLogo}
                        sx={{ maxWidth: '50px', margin: '0 auto 15px auto' }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDir: 'column',
                        }}>
                        {/* Trunk Health*/}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '15px',
                            }}>
                            <Text
                                sx={{
                                    fontWeight: '500',
                                    minWidth: '120px',
                                    marginBottom: '5px',
                                }}>
                                Status
                            </Text>
                            <Text>
                                {cardStatus.status === 'OK' ||
                                service?.statusDisplay?.statusDisplayDetail ===
                                    'Ok'
                                    ? 'Healthy'
                                    : cardStatus.status}
                            </Text>
                        </Box>

                        {/* SIP Trunks */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '15px',
                            }}>
                            <Text
                                sx={{
                                    fontWeight: '500',
                                }}>
                                SIP Trunks
                            </Text>
                            <Text sx={{ textAlign: 'left' }}>
                                {cardStatus.sipCount}
                            </Text>
                        </Box>

                        {/* Channels */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '15px',
                            }}>
                            <Text
                                sx={{
                                    fontWeight: '500',
                                }}>
                                Channels
                            </Text>
                            <Text sx={{ textAlign: 'left' }}>
                                {cardStatus.channelCount}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </DashboardServiceCardContent>
        </DashboardServiceCard>
    );
}
