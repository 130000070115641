import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';

import { axios } from 'services/axios';
import { query, multiSelectDataMapper, toSentenceCase } from 'utils/utils';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    FormLabel,
    FormControl,
    Heading,
    LoadingIndicator,
} from '@/components/v4';
import Grid from 'components/v4/Grid';

import getService from 'API/Service/getService';
import { classNames } from '@/utils/classNames';

import { TpmWrapper } from 'pages/tpm';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';

export default function TPM({ mode }) {
    // Options
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [callingProfiles, setCallingProfiles] = useState(null);
    const [operatorProfile, setOperatorProfile] = useState(null);
    const [dropdowns, setDropdowns] = useState({});
    const [error, setError] = useState(null);
    // Flags
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const { id } = useParams();

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    function createOcFields(params) {
        let ocParams = { companyID: currentCompany };
        const {
            operatorProfileId,

            id,
            name,

            callingProfiles,
        } = params;

        if (id) {
            ocParams.id = id;
        }
        if (name) {
            ocParams.name = name;
        }
        if (operatorProfileId) {
            ocParams.operatorProfileID = operatorProfileId;
        }
        if (callingProfiles?.length) {
            ocParams.callingProfiles = callingProfiles.map((cp) => ({
                id: cp.value,
                name: cp.label,
            }));
        }

        return ocParams;
    }

    function updateOcService(e) {
        setIsLoading(true);

        const params = {
            ...data,
            callingProfiles,
        };

        axios
            .put(`/Service/TeamsPhoneMobile`, params)
            .then((response) => {
                // TODO: mightwanna do diff flow here later.
                navigate('/dashboard-services');
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getDropdownData = async () => {
        const _callingProfiles = data.callingProfiles.map(
            multiSelectDataMapper,
        );

        setName(data.name);
        setCallingProfiles(_callingProfiles);

        setOperatorProfile(data.operatorProfile.id);

        try {
            const opRes = await axios.get(
                `/Services/TeamsPhoneMobile/Company/${currentCompany}/OperatorProfiles/Available`,
            );

            setDropdowns((prev) => ({
                ...prev,
                operatorProfile: opRes.data.map(multiSelectDataMapper),
            }));
        } catch (e) {
            console.log('error fetch data', { e });
        }
    };

    const getRegionsAndVPDropdown = async () => {
        try {
            const cpRes = await axios.get(
                `/Services/TeamsPhoneMobile/Company/${currentCompany}/Profile/${operatorProfile.id}/CallingProfiles/Available`,
            );
            setDropdowns((prev) => ({
                ...prev,

                callingProfiles: cpRes.data.map(multiSelectDataMapper),
            }));
        } catch (e) {
            console.log('error fetch data', { e });
        }
    };

    const getData = async (id) => {
        const res = await getService(id);
        setData(res.data);
        const newData = createOcFields(res.data);
        setName(res.data.name);
        setCallingProfiles(res.data.callingProfiles.map(multiSelectDataMapper));
        setOperatorProfile(res.data.operatorProfile);
        getDropdownData();
    };
    useEffect(() => {
        if (id) {
            getData(id);
        }
    }, [id]);

    useEffect(() => {
        getRegionsAndVPDropdown();
    }, [operatorProfile]);

    /**
     * Loading.
     */
    if (callingProfiles === null) {
        return <LoadingIndicator />;
    }

    return (
        <TpmWrapper>
            <Box
                sx={{
                    padding: '10px 0',
                    maxWidth: '2xl',
                    minWidth: 'xl',
                    margin: '0 auto',
                }}>
                <Heading fontSize="3xl" as="h2">
                    {toSentenceCase(mode)} {data.name}
                </Heading>
                {error && (
                    <Alert status="error" sx={{ marginTop: '1rem' }}>
                        <AlertIcon />
                        <AlertDescription>
                            {error?.response?.data?.message ||
                                'An error occured. Please try again later.'}
                        </AlertDescription>
                    </Alert>
                )}
                <Box sx={{ padding: '10px 0' }}>
                    <Stack space="30px">
                        {/* Calling Profiles */}
                        <FormControl>
                            <FormLabel>Calling Profiles</FormLabel>
                            <MultiSelect
                                chakraStyles={{
                                    outlineColor: 'gray.300',
                                    ...defaultChakraSelectStyle,
                                    ...chakraSelectDark,
                                }}
                                isMulti
                                name="colors"
                                placeholder="- Select Calling Profiles -"
                                closeMenuOnSelect={false}
                                tagVariant="solid"
                                onChange={(e) => setCallingProfiles(e)}
                                options={dropdowns.callingProfiles}
                                defaultValue={callingProfiles}
                            />
                        </FormControl>

                        <Box sx={{ margin: '20px 0' }} />
                    </Stack>

                    <Box
                        sx={{ margin: '20px 0 60px 0', display: 'flex' }}></Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '170px',
                            margin: '0 auto',
                        }}>
                        <Button
                            variant="outline"
                            className={classNames(
                                darkMode ? 'bg-white hover:opacity-70' : null,
                            )}
                            onClick={() => navigate('/dashboard-services')}>
                            Close
                        </Button>
                        <Button
                            onClick={() => updateOcService()}
                            disabled={isLoading}
                            type="submit">
                            Update
                        </Button>
                    </Box>
                </Box>
            </Box>
        </TpmWrapper>
    );
}
