import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import {axios} from '../../services/axios';
import {Error, Instructions} from '../';

//page for validate using powershell
const Script = ({viewCurrentCompany, onClick}) => {
    const {script} = useSelector((state) => {
        return {script: {...state.script}};
    });
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    const {currentCompany} = useSelector((state) => {
        return {...state.navigationLists};
    });

    const dispatch = useDispatch();

    useEffect(() => {
        viewCurrentCompany &&
            dispatch({type: 'CHANGE_SCRIPT_LOADING', payload: true});
    }, [currentCompany]);

    useEffect(() => {
        if (script?.loading) {
            axios
                .get(
                    viewCurrentCompany
                        ? '/company/' +
                              currentCompany +
                              '/script/' +
                              window.location.host
                        : '/company/' +
                              script?.rowData?.id +
                              '/script/' +
                              window.location.host,
                )
                .then((res) => {
                    dispatch({type: 'CHANGE_SCRIPT_DATA', payload: res.data});
                    dispatch({type: 'CHANGE_SCRIPT_LOADING', payload: false});
                })
                .catch((e) => {
                    dispatch({type: 'CHANGE_SCRIPT_ERROR', payload: e});
                });
        }
    }, [script?.loading, currentCompany, script?.rowData]);

    if (script?.error) {
        return (
            <>
                <Button variant="secondary" onClick={onClick}>
                    Back
                </Button>
                <br />
                <Error style={{marginTop: 10}} error={script?.error} />
            </>
        );
    }
    if (script?.loading) {
        return (
            <>
                <Button variant="secondary" onClick={onClick}>
                    Back
                </Button>
                <br />
                <CircularProgress
                    style={{marginTop: '25%', marginLeft: '50%'}}
                />
            </>
        );
    }
    return (
        <>
            <Button variant="secondary" onClick={onClick}>
                Back
            </Button>
            <Button
                style={{marginLeft: 10}}
                variant="primary"
                onClick={() => {
                    navigator.clipboard.writeText(script?.data);
                }}>
                Copy To Clipboard
            </Button>
            <Button
                variant="primary"
                onClick={handleShow}
                style={{marginLeft: 10}}>
                Running Script Guide
            </Button>
            <Instructions handleClose={() => setShow(false)} show={show} />
            <pre>
                <code className="script">{script?.data}</code>
            </pre>
        </>
    );
};

export default Script;
