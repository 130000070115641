/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import { axios } from '../../../services/axios';
import { addSpacing, hashObject } from '../../../utils/utils';
import './style.css';
import config from '../../../config.json';
import { onModalError } from '../../../components/tables/TableAndModal';
import { Error } from '../../../components';
import walkthroughIds from './walkthroughIds';

/*
 * Shows a list of items that a user can have permissions for in the Permissions tab of of a entry from the 'Permissions' Sidebar item
 */
const Item = ({ disabled }) => {
    const { loading: tableLoading, child } = useSelector((state) => {
        return { ...state.table };
    });
    const {
        state: modalState,
        hash: modalHash,
        restError,
    } = useSelector((state) => {
        return { ...state.modal };
    });

    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState({});
    const [submitData, setSubmitData] = useState({});
    const [uploading, setUploading] = useState(false);

    const { ufSaveBtn } = walkthroughIds.permissions;

    const hasChanges = useMemo(() => {
        const newHash = hashObject(modalState);
        return newHash !== modalHash;
    }, [modalState]);

    const handleOnChange = ({ e, permissionName, scopeNumber, mode }) => {
        var newData = { ...modalState };
        newData[permissionName][scopeNumber][mode] = e.target.checked;
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: newData,
        });

        var newSubmitData = { ...submitData };
        if (!newSubmitData[newData[permissionName][scopeNumber].id]) {
            newSubmitData[newData[permissionName][scopeNumber].id] = {
                ...newData[permissionName][scopeNumber],
                [mode]: e.target.checked,
            };
        } else {
            newSubmitData[newData[permissionName][scopeNumber].id][mode] =
                e.target.checked;
        }
        setSubmitData(newSubmitData);
    };

    const handleSubmit = () => {
        setUploading(true);

        const responses = Object.values(submitData).map((v) =>
            axios.put('/permission/', v),
        );
        Promise.all(responses)
            .then(() => {
                setUploading(false);
                dispatch({
                    type: 'CHANGE_TABLE_LOADING',
                });
            })
            .catch((e) => {
                setUploading(false);
                onModalError(e);
            });
    };
    const handleExpand = (panel) => () => {
        setExpanded({ ...expanded, [panel]: !expanded[panel] });
    };

    useEffect(() => {
        if (tableLoading) {
            axios
                .get('/permissions/' + child?.id)
                .then((res) => {
                    const newData = res.data.reduce((result, currentValue) => {
                        const currentValueName = addSpacing(currentValue.name);
                        if (!result[currentValueName]) {
                            result[currentValueName] = {
                                [currentValue.scope]: currentValue,
                            };
                            return result;
                        }
                        result[currentValueName][currentValue.scope] =
                            currentValue;
                        return result;
                    }, {});
                    dispatch({
                        type: 'OPEN_MODAL',
                        payload: {
                            mode: 'Edit',
                            data: newData,
                            state: newData,
                            hash: hashObject(newData),
                            loading: false,
                            uploading: false,
                        },
                    });
                    dispatch({
                        type: 'CHANGE_TABLE_LOADING',
                    });
                    setSubmitData({});
                })
                .catch((e) => {
                    dispatch({
                        type: 'CHANGE_TABLE_ERROR',
                        payload: e,
                    });
                });
        }
    }, [tableLoading]);

    if (tableLoading || uploading) {
        return (
            <CircularProgress
                style={{ marginTop: '2.5%', marginLeft: '50%' }}
            />
        );
    }

    return (
        <div id="permissions-table-component">
            {restError && (
                <Error
                    error={restError}
                    style={{ marginBottom: 5, marginTop: 5 }}
                />
            )}
            {hasChanges && (
                <Fab
                    onClick={handleSubmit}
                    style={{
                        zIndex: 2,
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                    }}>
                    <SaveRoundedIcon data-walkthroughid={ufSaveBtn} />
                </Fab>
            )}
            <div className="table-content">
                {Object.entries(modalState)
                    .sort((left, right) => left[0].localeCompare(right[0]))
                    .map(([permissionName, value]) => (
                        <Accordion
                            key={`${permissionName}`}
                            expanded={expanded[permissionName]}
                            onChange={handleExpand(permissionName)}>
                            <AccordionSummary
                                key={`${permissionName}`}
                                expandIcon={<ExpandMoreIcon />}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {permissionName}
                                        </Typography>
                                    </Grid>
                                    {expanded[permissionName] &&
                                        [
                                            'Create',
                                            'Read',
                                            'Update',
                                            'Delete',
                                        ].map((mode) => (
                                            <Grid
                                                item
                                                xs={2}
                                                key={`${permissionName}-${mode}`}>
                                                <Typography>{mode}</Typography>
                                            </Grid>
                                        ))}
                                </Grid>
                            </AccordionSummary>
                            {[20, 40, 60, 80]
                                .filter((scopeNumber) => value[scopeNumber])
                                .map((scopeNumber) => (
                                    <AccordionDetails
                                        key={`${permissionName}-${scopeNumber}`}>
                                        <div className="table-data">
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {
                                                        config.scope.json[
                                                            scopeNumber
                                                        ]
                                                    }
                                                </Grid>
                                                {[
                                                    'create',
                                                    'read',
                                                    'update',
                                                    'delete',
                                                ].map((mode) => (
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        key={`${permissionName}-${scopeNumber}-${mode}`}>
                                                        <Checkbox
                                                            disabled={disabled}
                                                            checked={
                                                                value[
                                                                    scopeNumber
                                                                ][mode]
                                                            }
                                                            onChange={(e) => {
                                                                handleOnChange({
                                                                    e,
                                                                    mode,
                                                                    scopeNumber,
                                                                    permissionName,
                                                                });
                                                            }}
                                                            data-walkthroughid={`/permissions/permissionsTab/${permissionName}/${mode}`}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    </AccordionDetails>
                                ))}
                        </Accordion>
                    ))}
            </div>
        </div>
    );
};

export default Item;
