import { useState, useEffect } from 'react';
import {
    useNavigate,
    useLocation,
    useParams,
    Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, useToast } from '@chakra-ui/react';
import {
    Select as MultiSelect,
    Select as ChakraSelect,
} from 'chakra-react-select';

import { axios } from '@/services/axios';
import useFieldValidation from 'hooks/useFieldValidation';
import { multiSelectDataMapper } from 'utils/utils';
import { classNames } from 'utils/classNames';

import getService from 'API/Service/getService';

import FieldValidation from 'components/v4/FieldValidation';
import {
    Button,
    LoadingIndicator,
    FormLabel,
    Input,
    Heading,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@/components/v4';
import { Container } from 'components/v4/Container/Container';

import { createSbcaasValidationSchema } from '@/pages/services/provisionedservices/consts';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import walkthroughIds from 'pages/services/walkthroughIds';
import { maxRedundancyOptions, toastSuccess } from './constants';

export function SBCaaS() {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const { id } = useParams();
    const toast = useToast();
    // Loading
    const [isLoading, setIsLoading] = useState(false);

    // Error
    const [error, setError] = useState(null);

    // Available Options
    const [availableSbcRegions, setAvailableSbcRegions] = useState();
    const [availableTrunkTypes, setAvailableTrunkTypes] = useState([]);

    // Selected options
    const [selectedSbcRegions, setSelectedSbcRegions] = useState({});
    const [selectedTrunkTypes, setSelectedTrunkTypes] = useState({});

    const [selectedMaxChannel, setSelectedMaxChannel] = useState('');
    const [selectedMaxRedundancy, setSelectedMaxRedundancy] = useState();
    const [serviceName, setServiceName] = useState('');
    const [selectedServiceData, setSelectedServiceData] = useState();

    // Validation
    const { validationErrors, validatefn } = useFieldValidation(
        {
            selectedMaxChannel,
            serviceName,
        },
        createSbcaasValidationSchema,
    );
    const [validateRegions, setValidateRegions] = useState([]);
    const [validateTrunkTypes, setValidateTrunkTypes] = useState([]);
    const companyID = useSelector(
        (state) => state.navigationLists.currentCompany,
    );

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        outlineColor: 'gray.300',
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    const { serviceProfiles } = walkthroughIds;

    async function editServiceExternaly(location) {
        if (location?.state?.serviceId) {
            setIsLoading(true);
            // fetch data
            const data = await getService(location.state.serviceId);
            setIsLoading(false);
            // Set data
            setSelectedServiceData(data.data);
        }
    }

    // Used to detect editing a provisioned serviced externaly (meaning, from services page + edit button)
    useEffect(() => {
        editServiceExternaly(location);
    }, [location]);

    function updateSbcService(e) {
        setIsLoading(true);
        e.preventDefault();
        setError(null);

        const isFieldsValid = validateSelectionFn(
            selectedSbcRegions,
            setValidateRegions,
            'You need to select at least one region.',
        );
        const isTrunkTypesValid = validateSelectionFn(
            selectedTrunkTypes,
            setValidateTrunkTypes,
            'You need to select at least one trunk type.',
        );
        const isRegionsValid = validatefn();
        if (!isFieldsValid || !isTrunkTypesValid || !isRegionsValid) {
            setIsLoading(false);
            setError({
                response: {
                    data: {
                        message: 'There are some validation errors in the form',
                    },
                },
            });
            return;
        }

        // Transform regions back to format needed for backend
        const transformedRegions = selectedSbcRegions.map((region) => ({
            id: region.value,
        }));
        const transformedTrunkTypes = selectedTrunkTypes.map((trunkType) => ({
            id: trunkType.value,
        }));

        const params = {
            id: selectedServiceData.id,
            regions: transformedRegions,
            trunkTypes: transformedTrunkTypes,
            maxChannel: selectedMaxChannel,
            maxRedundancy: selectedMaxRedundancy,
            companyID: companyID,
            name: serviceName,
        };

        axios
            .put('/Service/SBCaaS', params)
            .then((response) => {
                setTimeout(() => {
                    setIsLoading(false);
                    toast(toastSuccess);
                    navigate('/dashboard-services');
                }, 2000);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error);
            });
    }

    function onRegionChange(selectedOptions) {
        setSelectedSbcRegions(selectedOptions);
    }

    function onTrunkTypeChange(selectedOptions) {
        setSelectedTrunkTypes(selectedOptions);
    }

    function onMaxChannelChange(e) {
        const value = Number(e.target.value);

        if (value === 0) {
            setSelectedMaxChannel('');
            return;
        }

        if (value < 1 || value > 100) {
            return;
        }

        setSelectedMaxChannel(String(value));
    }

    function validateSelectionFn(
        selectedItems,
        setValidationState,
        validationMessage,
    ) {
        if (selectedItems.length) {
            setValidationState([]);
            return true;
        } else {
            setValidationState([validationMessage]);
            return false;
        }
    }

    useEffect(() => {
        // Grab available regions and trunk types for sbcaas
        Promise.all([
            axios.get(
                `/Services/SBCaaS/Company/${companyID}/Regions/Available`,
            ),
            axios.get(
                `/Services/SBCaaS/Company/${companyID}/TrunkTypes/Available`,
            ),
        ]).then(([regionRes, trunkTypeRes]) => {
            setAvailableSbcRegions(regionRes.data.map(multiSelectDataMapper));
            setAvailableTrunkTypes(
                trunkTypeRes.data.map(multiSelectDataMapper),
            );

            axios.get(`/Service/${id}`).then((response) => {
                const { maxChannel, maxRedundancy, regions, name, trunkTypes } =
                    response.data;
                // Set selected regions, by selecting comparing selected regions
                // vs list of available regions
                const defaultSelectedRegions = regions.map(
                    multiSelectDataMapper,
                );
                const defaultSelectedTrunkTypes = trunkTypes.map(
                    multiSelectDataMapper,
                );
                setSelectedSbcRegions(defaultSelectedRegions);
                setSelectedTrunkTypes(defaultSelectedTrunkTypes);

                // Set other selected options
                setServiceName(name);
                setSelectedMaxRedundancy(maxRedundancy);
                setSelectedMaxChannel(maxChannel);
            });
        });
    }, []);

    if (!state?.serviceId) {
        return <Navigate to="/dashboard-services" />;
    }

    if (
        !availableSbcRegions ||
        !selectedMaxRedundancy ||
        !selectedSbcRegions ||
        !availableTrunkTypes ||
        !selectedTrunkTypes
    ) {
        return <LoadingIndicator />;
    }

    return (
        <section>
            <Heading fontSize="3xl" className="text-center" as="h2">
                Editing {selectedServiceData.name}
            </Heading>

            <Container>
                {error && (
                    <Alert status="error" sx={{ marginTop: '1rem' }}>
                        <AlertIcon />
                        <AlertDescription>
                            {error?.response?.data?.message ||
                                'An error occured. Please try again later.'}
                        </AlertDescription>
                    </Alert>
                )}
                <Box onSubmit={updateSbcService} as="form">
                    <Box data-walkthroughid={serviceProfiles.ufRegionsSelect}>
                        <FormLabel>Trunk Types</FormLabel>
                        <MultiSelect
                            chakraStyles={chakraStyles}
                            isMulti
                            name="trunk types"
                            placeholder="- Select trunk types -"
                            closeMenuOnSelect={false}
                            tagVariant="solid"
                            onChange={onTrunkTypeChange}
                            options={availableTrunkTypes}
                            defaultValue={selectedTrunkTypes}
                        />
                        <FieldValidation
                            sx={{ marginTop: 1 }}
                            errors={validateTrunkTypes}
                        />
                    </Box>

                    <Box
                        sx={{ margin: '20px 0' }}
                        aria-label="margin-box"></Box>
                    <Box data-walkthroughid={serviceProfiles.ufRegionsSelect}>
                        <FormLabel>Regions</FormLabel>
                        <MultiSelect
                            chakraStyles={chakraStyles}
                            isMulti
                            name="regions"
                            placeholder="- Select regions -"
                            closeMenuOnSelect={false}
                            tagVariant="solid"
                            onChange={onRegionChange}
                            options={availableSbcRegions}
                            defaultValue={selectedSbcRegions}
                        />
                        <FieldValidation
                            sx={{ marginTop: 1 }}
                            errors={validateRegions}
                        />
                    </Box>

                    <Box
                        sx={{ margin: '20px 0' }}
                        aria-label="margin-box"></Box>

                    <FormLabel>Max channel</FormLabel>
                    <Input
                        placeholder="1 - 100"
                        type="number"
                        pattern="[0-9]"
                        onChange={onMaxChannelChange}
                        value={selectedMaxChannel}
                        data-walkthroughid={serviceProfiles.ufMaxChannelsInput}
                    />
                    <FieldValidation
                        sx={{ marginTop: 1 }}
                        errors={validationErrors?.selectedMaxChannel}
                    />

                    <Box
                        sx={{ margin: '20px 0' }}
                        aria-label="margin-box"></Box>

                    <Box
                        data-walkthroughid={
                            serviceProfiles.ufMaxRedundancyLevelSelect
                        }>
                        <FormLabel>Max redundancy</FormLabel>
                        <ChakraSelect
                            defaultValue={
                                maxRedundancyOptions[selectedMaxRedundancy - 1]
                            }
                            options={maxRedundancyOptions}
                            onChange={(e) => setSelectedMaxRedundancy(e.value)}
                            chakraStyles={chakraStyles}
                            selectedOptionColor="brand"
                        />
                    </Box>

                    <Box mb="52px" aria-label="margin-box" />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '170px',
                            margin: '0 auto',
                        }}>
                        <Button
                            variant="outline"
                            w={{ base: '100%', md: '80px' }}
                            className={classNames(
                                darkMode ? 'bg-white hover:opacity-70' : null,
                            )}
                            onClick={() => navigate('/dashboard-services')}
                            data-walkthroughid={serviceProfiles.ufCloseButton}>
                            Back
                        </Button>
                        <Button
                            ml="1rem"
                            w={{ base: '100%', md: '80px' }}
                            disabled={isLoading}
                            type="submit"
                            data-walkthroughid={serviceProfiles.ufUpdateButton}>
                            Update
                        </Button>
                    </Box>
                </Box>
            </Container>
        </section>
    );
}
