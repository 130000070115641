import { Divider, HStack, Skeleton, Stack, VStack } from '@chakra-ui/react';

export default function FilterSkeleton() {
    return (
        <Stack>
            <HStack justifyContent={'space-between'}>
                <Skeleton width={'44px'} h={6} />
                <Skeleton width={'50px'} h={6} />
            </HStack>
            <Divider />

            <VStack
                paddingInline={'16px'}
                paddingY={'8px'}
                alignItems={'start'}>
                <HStack>
                    <Skeleton h={5} w={5} />
                    <Skeleton h={5} w={100} />
                </HStack>

                <VStack
                    paddingTop={'8px'}
                    paddingBottom={'20px'}
                    alignItems={'start'}>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={68} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={38} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={42} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={51} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={42} />
                    </HStack>
                </VStack>
            </VStack>

            <VStack
                paddingInline={'16px'}
                paddingY={'8px'}
                alignItems={'start'}>
                <HStack>
                    <Skeleton h={5} w={5} />
                    <Skeleton h={5} w={70} />
                </HStack>

                <VStack
                    paddingTop={'8px'}
                    paddingBottom={'20px'}
                    alignItems={'start'}>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={125} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={103} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={143} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={132} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={70} />
                    </HStack>
                </VStack>
            </VStack>

            <VStack
                paddingInline={'16px'}
                paddingY={'8px'}
                alignItems={'start'}>
                <HStack>
                    <Skeleton h={5} w={5} />
                    <Skeleton h={5} w={120} />
                </HStack>

                <VStack
                    paddingTop={'8px'}
                    paddingBottom={'20px'}
                    alignItems={'start'}>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={57} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={84} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={148} />
                    </HStack>
                    <HStack>
                        <Skeleton h={5} w={5} />
                        <Skeleton h={5} w={155} />
                    </HStack>
                </VStack>
            </VStack>
        </Stack>
    );
}
