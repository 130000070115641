import { createContext, useMemo, useState } from 'react';

const DashboardMenuContext = createContext(null);
DashboardMenuContext.displayName = 'DashboardMenuContext';

export const DashboardMenuProvider = (props) => {
    const [isQueryDisabled, setIsQueryDisabled] = useState(false);

    const value = useMemo(
        () => ({
            isQueryDisabled,
            setIsQueryDisabled,
        }),
        [isQueryDisabled],
    );

    return <DashboardMenuContext.Provider value={value} {...props} />;
};

export { DashboardMenuContext };
