import { Modal } from '@chakra-ui/react';

export default function ChakraModal({
    children,
    closeOnOverlayClick = false,
    autoFocus = false,
    ...props
}) {
    return (
        <Modal
            closeOnOverlayClick={closeOnOverlayClick}
            autoFocus={autoFocus}
            {...props}>
            {children}
        </Modal>
    );
}
