export const serviceTableColumns = [
    {title: 'Service name', field: 'name'},
    {title: 'Status', field: 'statusDisplay.statusDisplayDetail'},
    {title: 'Last Health Check', field: 'lastHealthCheck'},
    {title: 'Actions', field: 'actions'},
];

export const createSbcaasValidationSchema = {
    selectedMaxChannel: [
        {type: 'isEmpty', message: 'You need to specificy the maximum channels.'},
    ],
    serviceName: [
        {type: 'isEmpty', message: 'You need a service name.'},
    ],
};
