import { Box } from '@chakra-ui/react';
import Input from '@/components/v4/Input';

const baseStyles = {
    padding: 0,
    width: 50,
    height: '60px',
    textAlign: 'center',
    background: 'white',
    //marginRight: 5,
    borderRadius: 3,
    borderColor: 'gray.400',
    fontSize: '30px',
};

export default function MfaCodeBox({
    mfaCodeObj,
    shouldWeSwapToNextInput,
    shouldWeGoToPreviousInput,
    refs,
}) {
    return (
        <Box
            sx={{
                margin: '25px 0',
            }}>
            <Box
                sx={{
                    maxWidth: '400px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0 auto',
                }}>
                {[...Array(6)].map((k, i) => {
                    return (
                        <Input
                            className="!text-black"
                            sx={baseStyles}
                            key={i}
                            id={i}
                            value={mfaCodeObj[i]}
                            onChange={(e) => shouldWeSwapToNextInput(e, i)}
                            onKeyDown={(e) => shouldWeGoToPreviousInput(e, i)}
                            customRef={refs[i]}
                            pattern="[0-9]{1}"
                            min={0}
                            max={9}
                            maxLength={1}
                            autoFocus={i === 0}
                            _focus={{
                                boxShadow:
                                    'inset 0 0 0 0.125rem #1040c1, 0 0 0 0.375rem rgb(16 114 235 / 16%)',
                            }}
                        />
                    );
                })}
            </Box>
        </Box>
    );
}
