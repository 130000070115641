import Button from '../../../../../components/v4/Button';
import {Box} from '@chakra-ui/react';

export default function ServiceWizardSyncUsersQuestion({
    serviceWizard,
    isLoading,
    setSyncUsers,
}) {
    return (
        serviceWizard.company.selectiveSyncPolicy ===
            1 && (
            <>
                <Button onClick={() => {
                    setSyncUsers(true)
                }}>Yes</Button>
                <Button
                    onClick={() => serviceWizard.onSelectiveSyncDeny()}
                    colorScheme="gray"
                    sx={{
                        background: 'gray.300',
                        marginLeft: '20px',
                        '&:hover': {
                            background: 'gray.300',
                        },
                    }}
                    disabled={isLoading}>
                    No
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '15px',
                        marginTop: '15px',
                    }}
                />
            </>
        )
    );
}
