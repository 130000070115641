import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {Modal} from 'react-bootstrap';

import firstStep from '../../assets/images/instructions/1_copyScript.png';
import secondStep from '../../assets/images/instructions/2_runPowershellAdmin.png';
import thirdStep from '../../assets/images/instructions/3_Paste_Script.png';
import fourthStep from '../../assets/images/instructions/4_signInTenancy.png';
import fifthStep from '../../assets/images/instructions/5_password.png';
import sixthStep from '../../assets/images/instructions/6_Confirmation.png';

// array of steps
const tutorialSteps = [
    {
        label: 'Firstly, copy the script to clipboard.',
        imgPath: firstStep,
    },
    {
        label: 'Secondly, run Windows PowerShell as Administrator.',
        imgPath: secondStep,
    },
    {
        label: 'Then, paste the copied script. ',
        imgPath: thirdStep,
    },
    {
        label:
            'Enter your global admin email account when prompted to sign in to Microsoft. ',
        imgPath: fourthStep,
    },
    {
        label: 'Then, enter your password.',
        imgPath: fifthStep,
    },
    {
        label: 'Done! your script has been executed successfully.',
        imgPath: sixthStep,
    },
];

// theme for the component
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 500,
        width: 500,
        overflow: 'hidden',
        display: 'block',
    },
    content: {
        padding: '5%',
    },
}));

/*
 * Stepper component for the instructions component
 * hard coded for the instructions to use a powershell script
 */
const TextMobileStepper = ({handleClose, show}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    // handlers for the next or previous steps
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Modal with a stepper component inside it
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Running The Script Guide </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <img
                            className={classes.img}
                            src={tutorialSteps[activeStep].imgPath}
                            alt={tutorialSteps[activeStep].label}
                        />
                        <Typography>
                            {tutorialSteps[activeStep].label}
                        </Typography>
                    </div>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}>
                                Next
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TextMobileStepper;
