import { Icon } from '@chakra-ui/react';
export const TenantIcon = (props) => (
    <Icon
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        focusable="false"
        aria-hidden="true"
        className="chakra-menu__icon"
        color={props.color}
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M18.719 11.985l-5.889 -9.539a.999 .999 0 0 0 -1.664 0l-8.54 13.836a1.005 1.005 0 0 0 .386 1.452l8.092 4.054a1.994 1.994 0 0 0 1.789 0l.149 -.074" />
        <path d="M12 2v20" />
        <circle cx="18" cy="18" r="3" />
        <line x1="20.5" y1="20.5" x2="23" y2="23" />
    </Icon>
);
