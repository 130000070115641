import { Box } from '@chakra-ui/react';

import { classNames } from 'utils/classNames';

/**
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {import('react').CSSProperties} props.className
 */
export default function TpmContent({ children, className = '' }) {
    // const { darkMode } = useSelector((state) => state.settings);
    return (
        <Box
            minW="xl"
            maxW="4xl"
            paddingLeft="7px"
            className={classNames('ml-0', className)}
            id="tpm-content">
            {children}
        </Box>
    );
}
