export default {
  ufSyncBtn: 'table/button/syncBtn',
  ufBuilderBtn: 'table/button/builderBtn',
  ufHelperBtn: 'table/button/helperBtn',
  ufCsvUpdateBtn: 'table/button/csvUpdateBtn',
  ufEditBtn: 'table/button/editBtn',
  tabs: {
    ufGeneralTab: 'tab/button/General',
    ufCustomTab: 'tab/button/Custom',
    ufAdminTab: 'tab/button/Admin',
  },
  general: {
    ufManagedBy: '/users/select/managedBy',
    ufUsageLocation: '/users/input/usageLocation',
    ufDefaultPlan: '/users/select/defaultPlan',
    ufPlan: '/users/input/plan',
    ufTelephoneNumber: '/users/select/number',
    ufVoicePolicy: '/users/select/voicePolicy',
    ufDialPlan: '/users/select/dialPlan',
  },
  admin: {
    ufConvertBtn: '/resource-accounts/button/convert',
    ufYesBtn: '/resource-accounts/button/yes',
    ufNoBtn: '/resource-accounts/button/no'
  },
  autoAttendant: {
    ufAccountName: '/resource-accounts/input/accountName',
    ufDisplayName: '/resource-accounts/input/displayName',
    ufTimezone: '/resource-accounts/select/timezone',
    ufLanguage: '/resource-accounts/select/language',
    ufTemplate: '/resource-accounts/select/template',
    ufTemplateImage: '/resource-accounts/image/templateImage',
  },
  modal: {
    ufModal: 'autoAttendant/modal/1',
    ufMicrosoftGuide: 'autoAttendant/modal/link/msReference',
    ufAAGuide: 'autoAttendant/modal/link/aaGuide',
    ufResourceGuide: 'autoAttendant/modal/link/resourceLicense',
  },
  csv: {
    ufDownloadBtn: '/csvusers/button/download',
    ufUploadBtn: '/csvusers/button/upload',
    ufCloseBtn: '/csvusers/button/close',
  },
};
