/* eslint-disable no-unused-vars */
import React, { useState, useEffect, forwardRef } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { MenuItem, FormControl, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { axios } from '../../services/axios';
import Searchbox from '../presentational/Searchbox.jsx';
import config from '../../config.json';
import { customAutoCompleteStyle } from 'pages/navbar/constant';

// eslint-disable-next-line react/display-name
const CustomChevronDownIcon = forwardRef((props, ref) => (
    <ChevronDownIcon {...props} ref={ref} boxSize={5} mr={1} mt="1px" />
));

/*
 * Component used for drop down fields in the UI that require a series of options
 * those options can be gathered from a static list, fetched from the API when the component renders etc
 * This component also changes state so that modal forms can submit data using the value from the drop down
 */
const LoadingFieldDropdown = ({
    // this prop stops the component from fetching until another field/value has data
    dependency,
    // if there is no field display or we want a different default display then this value is shown
    noneFieldDisplay,
    // determines when the data will be fetched, if init is true it will be fetched when its loaded
    init,
    // function that triggers when the display drop down is shown
    onDisplayDropdown,
    // value behind the noneFieldDisplay as all values have a different display
    noneFieldValue,
    // prop to determine if its not a modal component
    notModalComponent,
    // instead of a api fetch the component can be fed a static list to use
    staticEnum,
    // the onChange function to be passed into the material component of this
    onChange,
    //
    noneField,
    // a function that can be passed in to represent a error, usually the onError from TableAndModal.jsx
    onError,
    // prop to pass through to sub componennts
    toggleLimit,
    // prop to pass through to sub componennts
    loadDependencies,
    // boolean to determine whether its searchable or not
    searchable,
    // the URL for fetching the data to populate the list
    fieldFetch,
    // label for the component as its a MUI textfield/searchbox
    fieldName,
    // used to determine the type of the value in the drop down, strint, int, bool etc
    fieldValue,
    // the value that is used for the state setting when selecting a value
    dropDownValue,
    // the field that is actually displayed in the drop down
    displayField,
    // props for the drop down list
    dropdownProps,
    // makes it so there is no empty option when the field first loads the drop down data
    noEmptyOption,
    // a function that triggers when the fetch is sucessful
    onFieldFetch,
    // determins if the component has dependencies
    hasDependency,
    // additional options for the static array that is passed into this component
    additional,
    // helper text is set inside the drop down
    helperText,
    // error text is what is displayed if there is a modalError
    errorText,
    // a bool that disalbes the drop down
    disabled,
    // a value to group the results of the api call or static enum by
    groupBy,
    // funciton that triggers when the funciton is focussed on
    onFocus,
    // if you want to render whats inside the wrapper as something that isnt a searchbox/textfield
    renderOption,
    // a filter for the options of the search box
    filter,
    // icon or any other sort of adornment that goes at the end of the field
    endAdornment,
    dataWalkthroughid, // used for the walkthrough
    isRequired, // show asterick beside label
    hasTooltip, // show tooltip on the right end
    tooltipLabel, // custom title for tooltip
    ...rest
}) => {
    const [enumArray, setEnum] = useState(staticEnum || []);
    const [enumLoading, setEnumLoading] = useState(false);

    // state to detemine if the modal is showing
    const { show: modalShow } = useSelector((state) => {
        return { ...state.modal };
    });
    const { colorScheme } = useSelector((state) => state.settings);

    /*
     * Fetch function for the results based with onFieldFetch, fieldFetch props
     * if this is sucsessful the enum state is set to the result
     */
    const fetch = () => {
        setEnumLoading(true);
        if (onFieldFetch) {
            onFieldFetch()
                .then((res) => {
                    setEnum(res.data);
                    setEnumLoading(false);
                })
                .catch((e) => {
                    onError(e);
                    setEnumLoading(false);
                });
            return;
        }
        axios
            .get(fieldFetch)
            .then((res) => {
                setEnum(res.data);
                setEnumLoading(false);
            })
            .catch((e) => {
                onError(e);
                setEnumLoading(false);
            });
    };

    /*
     * Function to extract the field value
     * checks the type of this value and returns the fieldvalue
     */
    const extractFieldValue = () => {
        //set default case
        switch (typeof fieldValue) {
            case 'object':
                return fieldValue || '';
            case 'string':
                return fieldValue || '';
            case 'number':
                return fieldValue;
            default:
                return fieldValue;
        }
    };

    /*
     * calls a fetch based onFieldFetch or fieldFetch existing
     * also requires static enum and dependency to be false
     * also requires init or modalShow or notModalComponent to execuse fetch()
     */
    useEffect(() => {
        if (
            (onFieldFetch || fieldFetch) &&
            !staticEnum && //not static
            !hasDependency && // not depending on sth else
            (init || //do at first place
                modalShow)
        ) {
            fetch();
        }
    }, [modalShow, staticEnum]);

    /*
     * fetches based on the same factors as abovem doesnt accoutn for init or modalShow
     * could most likley be refacorted into a single useEffect()
     */
    useEffect(() => {
        if ((onFieldFetch || fieldFetch) && !staticEnum && hasDependency) {
            fetch();
        }
    }, [hasDependency, dependency, staticEnum]);

    /*
     * If the enumLoading state is true, returns a skeleton component
     */
    if (enumLoading) {
        return <Skeleton style={{ marginTop: '2%' }} />;
    }

    /*
     * If the serachable prop is entered with a static enum of length > 0 then render a search box
     * many props are used for material ui requirements for SearchBox, documentation is online for those props
     */
    if (
        searchable &&
        !disabled &&
        (staticEnum || enumArray)?.concat(additional || []).length > 0
    ) {
        return (
            <Searchbox
                dataWalkthroughid={dataWalkthroughid}
                onFocus={onFocus}
                endAdornment={endAdornment}
                defaultValue={extractFieldValue()}
                onChange={(v) => {
                    onChange(v[dropDownValue]);
                }}
                noneFieldDisplay={noneFieldDisplay}
                options={(staticEnum || enumArray || [])
                    .concat(additional || [])
                    .concat(
                        !noEmptyOption
                            ? [
                                  {
                                      [dropDownValue]: null,
                                      [displayField]:
                                          noneFieldDisplay ||
                                          config.notAllocated.dropdown,
                                  },
                              ]
                            : [],
                    )
                    .sort((left, right) =>
                        left && right
                            ? groupBy
                                ? groupBy(left)?.localeCompare(groupBy(right))
                                : onDisplayDropdown
                                  ? onDisplayDropdown(left)?.localeCompare(
                                        onDisplayDropdown(right),
                                    )
                                  : left[displayField]?.localeCompare(
                                        right[displayField],
                                    )
                            : 0,
                    )
                    .filter(
                        filter ??
                            function (v) {
                                return true;
                            },
                    )}
                helperText={helperText}
                errorText={errorText}
                getOptionLabel={(v) => {
                    if (typeof v === 'string') {
                        if (v.length === 0) {
                            return (
                                noneFieldDisplay ?? config.notAllocated.dropdown
                            );
                        }

                        const result = (staticEnum || enumArray || [])
                            ?.concat(additional || [])
                            ?.concat(
                                !noEmptyOption
                                    ? [
                                          {
                                              [dropDownValue]: null,
                                              [displayField]:
                                                  noneFieldDisplay ||
                                                  config.notAllocated.dropdown,
                                          },
                                      ]
                                    : [],
                            )
                            .find((item) => item[dropDownValue] === v);
                        if (result && onDisplayDropdown) {
                            return (
                                onDisplayDropdown(result) ??
                                noneFieldDisplay ??
                                config.notAllocated.dropdown
                            );
                        }
                        return (
                            result?.[displayField] ??
                            noneFieldDisplay ??
                            config.notAllocated.dropdown
                        );
                    }
                    if (onDisplayDropdown) {
                        return (
                            onDisplayDropdown(v) ??
                            noneFieldDisplay ??
                            config.notAllocated.dropdown
                        );
                    }

                    return (
                        v[displayField] ??
                        noneFieldDisplay ??
                        config.notAllocated.dropdown
                    );
                }}
                displayField={displayField}
                dropDownValue={dropDownValue}
                label={fieldName}
                //groupByProps={groupBy}
                onDisplayDropdown={onDisplayDropdown}
                renderOption={renderOption}
                getOptionSelected={(option, v) => {
                    return option[dropDownValue] === v;
                }}
                isRequired={isRequired}
                hasTooltip={hasTooltip}
                tooltipLabel={tooltipLabel}
                {...dropdownProps}
            />
        );
    }

    /*
     * If the static enum or the array of possible choices has a length of less than 1
     * Render a textfield with a single option
     */
    if ((staticEnum || enumArray)?.concat(additional || []).length < 1) {
        return (
            <TextField
                data-walkthroughid={dataWalkthroughid}
                disabled={true}
                label={fieldName}
                helperText={errorText || helperText}
                error={Boolean(errorText)}
                variant={'outlined'}
                fullWidth
                value={noneFieldDisplay || config.notAllocated.dropdown}
            />
        );
    }

    /*
     * If there is no staticEnum input into the component then render this
     * mainly using props from material ui textfield some some aspects that I do not understand
     * This maps the dropDownValue and displayField to a list of MenuItems for the component to use
     */
    return (
        <Box sx={customAutoCompleteStyle(colorScheme)}>
            <FormControl size="small" fullWidth error={Boolean(errorText)}>
                <TextField
                    data-walkthroughid={dataWalkthroughid}
                    select
                    disabled={disabled}
                    label={fieldName}
                    value={extractFieldValue()}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    helperText={errorText || helperText}
                    error={Boolean(errorText)}
                    variant={'outlined'}
                    SelectProps={{
                        IconComponent: CustomChevronDownIcon,
                    }}
                    {...dropdownProps}>
                    {!noEmptyOption && (
                        <MenuItem value={noneFieldValue || ''}>
                            {noneFieldDisplay || config.notAllocated.dropdown}
                        </MenuItem>
                    )}

                    {dropDownValue && displayField
                        ? (staticEnum || enumArray)
                              ?.concat(additional || [])
                              .map(
                                  (
                                      {
                                          [dropDownValue]: id,
                                          [displayField]: name,
                                          ...rest
                                      },
                                      idx,
                                  ) => {
                                      return (
                                          <MenuItem key={idx} value={id}>
                                              {(onDisplayDropdown &&
                                                  onDisplayDropdown({
                                                      [dropDownValue]: id,
                                                      [displayField]: name,
                                                      ...rest,
                                                  })) ||
                                                  name}
                                          </MenuItem>
                                      );
                                  },
                              )
                        : (staticEnum || enumArray)
                              ?.concat(additional || [])
                              .map((v, idx) => {
                                  return (
                                      <MenuItem key={idx} value={v}>
                                          {(onDisplayDropdown &&
                                              onDisplayDropdown(v)) ||
                                              v}
                                      </MenuItem>
                                  );
                              })}
                </TextField>
            </FormControl>
        </Box>
    );
};

export default LoadingFieldDropdown;
