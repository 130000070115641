import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from '@chakra-ui/react';
import Heading from '../../../../components/v4/Heading';
// import Text from '../../../../components/v4/Text';
import Button from '../../../../components/v4/Button';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { IGNORED_MESSAGE } from './useCSVUsers';
import ProgressBarView from './ProgressBarView';
import Alert from '../../../../components/v4/Alert';
import AlertIcon from '../../../../components/v4/AlertIcon';

function useAPILoadingView(props) {
    const { completionCounter, verificationResult, errorCounter, total } =
        props;
    const current = Math.min(completionCounter + 1, total);
    const successful = `Successful : ${completionCounter - errorCounter}`;
    const failed = `Failed : ${errorCounter}`;
    const ignored = `No Change (Ignored): ${verificationResult?.passed?.data?.filter(
        (v) => v.verificationStatus === IGNORED_MESSAGE,
    ).length
        }`;
    return {
        ignored,
        successful,
        failed,
        current,
        completionCounter,
        warning:
            'Do not close this window or navigate away. This may take a few minutes.',
        viewResultsText: 'View Results',
        header: 'Importing CSV...',
        completedText: 'Completed',
    };
}

export default function APILoadingView(props) {
    const { handleViewAPIResults, total } = props;
    const {
        ignored,
        successful,
        current,
        failed,
        completionCounter,
        warning,
        viewResultsText,
        completedText,
        header,
    } = useAPILoadingView(props);
    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Box display={'flex'} justifyContent="center">
            <Box
                width={400}
                display={'flex'}
                flexDirection={'column'}
                gap={'1px'}
                fontFamily={'Helvetica'}
                justifyContent={'center'}
                boxShadow="base"
                p="6"
                bg={darkMode ? 'dark.tcap' : 'white'}
                rounded="md">
                <Heading size="lg">{header}</Heading>
                {completionCounter >= total ? (
                    <Alert status="success" mt={5}>
                        <AlertIcon />

                        <Text size="sm" whiteSpace="pre-line">
                            {completedText}
                        </Text>
                    </Alert>
                ) : (
                    <ProgressBarView
                        current={current}
                        total={total}
                        completionCounter={completionCounter}
                    />
                )}
                <Box mt={5} padding={'10px'}>
                    <Text sx={{ color: 'green.500' }} size="md">
                        {successful}
                    </Text>
                    <Text sx={{ color: 'red.500' }} mt={1} size="md">
                        {failed}
                    </Text>
                    <Text
                        sx={{ color: darkMode ? 'gray.300' : 'gray.500' }}
                        mt={1}
                        size="md">
                        {ignored}
                    </Text>
                </Box>
                {completionCounter >= total ? (
                    <Box mt={3} display={'flex'} justifyContent={'right'}>
                        <Button
                            onClick={handleViewAPIResults}
                            rightIcon={<ArrowForwardIcon />}
                            size={'sm'}>
                            {viewResultsText}
                        </Button>
                    </Box>
                ) : (
                    <Box mt={5} padding={'10px'}>
                        <Text size="md">{warning}</Text>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
