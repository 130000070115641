/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Divider,
    FormHelperText,
    HStack,
    IconButton,
    Skeleton,
    SkeletonCircle,
    Stack,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import { Alert, AlertIcon, FormControl, FormLabel } from 'components/v4';
import ChakraButton from 'components/v4/Button';
import { useState, useEffect } from 'react';
import process from 'process';
import { useSelector } from 'react-redux';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { axios } from '@/services/axios';
import AddProvider from './AddProvider';
import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { toast } from 'react-toastify';
import { providersOption } from './constant';
import EditMicrosoftSSO from './EditMicrosoftSSO';
import { useMsal } from '@azure/msal-react';
import walkthroughIds from '../../../accounts/admins/walkthroughIds';
import { loginRequest } from 'pages/auth/new/sso/config/authConfig';

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { Button } from 'react-bootstrap';

export default function SSO() {
    const { darkMode } = useSelector((state) => state.settings);

    const [isLoading, setIsLoading] = useState(true);
    const [isBusy, setIsBusy] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [switchDisabled, setSwitchDisabled] = useState(false);
    const {
        isOpen: isOpenProvider,
        onOpen: onOpenProvider,
        onClose: onCloseProvider,
    } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        ufAllowSSO,
        ufProviderBtn,
        ufEnforceSSO,
        ufSaveBtn,
        ufVerifyBtn,
        ufActiveBtn,
        ufEditBtn,
    } = walkthroughIds.adminSSO;

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { instance } = useMsal();

    const initialState = {
        isEnabled: false,
        providers: [],
        isEnforced: false,
    };

    const [ssoConfig, setSSOConfig] = useState(initialState);

    const handleSSOCheckbox = () => {
        setIsChanged(true);
        setSSOConfig((prevState) => ({
            ...prevState,
            isEnabled: !prevState.isEnabled,
        }));
    };

    const handleEnforceCheckbox = () => {
        setIsChanged(true);
        setSSOConfig((prevState) => ({
            ...prevState,
            isEnforced: !prevState.isEnforced,
        }));
    };

    // api call for getting sso configuration on modal open
    useEffect(async () => {
        if (currentCompany && !isOpen && !isOpenProvider) {
            setIsChanged(false);
            setIsLoading(true);
            const result = await axios.get(`/sso/${currentCompany}`);
            setSSOConfig({ ...result.data });
            setIsLoading(false);
        }
    }, [isOpen, isOpenProvider]);

    // api call for savings sso settings for company
    const handleOnSave = async () => {
        setIsBusy(true);
        var sso = {
            isEnabled: ssoConfig?.isEnabled || false,
            isEnforced: ssoConfig?.isEnforced || false,
            companyID: currentCompany,
        };
        if (ssoConfig?.id) {
            sso['id'] = ssoConfig.id;
            try {
                await axios.put('/sso', sso);
                toast.success('SSO has been saved successfully!');
            } catch (e) {
                toast.error(e?.response?.data?.message);
            } finally {
                setIsBusy(false);
                setIsChanged(false);
            }
        } else {
            try {
                await axios.post('/sso', sso);
                toast.success('SSO has been created successfully!');
            } catch (e) {
                toast.error(e?.response?.data?.message);
            } finally {
                setIsBusy(false);
                setIsChanged(false);
            }
        }
    };

    const handleVerify = async () => {
        setIsLoading(true);

        const requestHeaders = new Headers();
        try {
            let redirectUri;
            // localhost is not recognize, it gets overriden later
            const reference =
                process.env.NODE_ENV === 'development'
                    ? 'teamsdev.tcap.dev'
                    : window.location.host;

            const response = await fetch(
                `${import.meta.env.VITE_APP_API_URL}/domain`,
                {
                    method: 'POST',
                    headers: {
                        ...requestHeaders,
                        ...{
                            'Content-Type': 'application/json',
                            'X-RequestScope': 20,
                        },
                    },
                    mode: 'cors',
                    credentials: 'omit',
                    body: JSON.stringify(reference),
                },
            );

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData?.message);
                return;
            }

            const result = await response.json();
            const decodedReference = window.atob(result).split('|');

            const referenceID = decodedReference[0];

            if (process.env.NODE_ENV === 'development') {
                redirectUri = `${window.location.origin}/ui/sso/ms/redirect`;
            } else if (window.location.host == 'teamsdev.tcap.dev') {
                redirectUri = `https://${window.location.host}/ui/sso/ms/redirect`;
            } else {
                redirectUri = `https://${decodedReference[1]}/ui/sso/ms/redirect`;
            }

            const login = await instance
                .loginPopup({
                    ...loginRequest,
                    redirectUri: redirectUri,
                    state: referenceID,
                })
                .catch((e) => console.log(e));

            if (login) {
                const encodedToken = window.btoa(login.accessToken);
                try {
                    const result = await axios.post('/sso/verify', {
                        accessToken: encodedToken,
                        ssoid: ssoConfig?.id,
                    });

                    const newProvider = ssoConfig.providers.map((p) => {
                        if (p.provider === 0) {
                            return result.data;
                        }
                        return p;
                    });
                    setSSOConfig((prevState) => ({
                        ...prevState,
                        providers: newProvider,
                    }));
                    toast.success('Provider has been verified!');
                } catch (e) {
                    toast.error(e?.response?.data?.message);
                }
            } else {
                toast.error('Unable to verify provider.');
            }
        } catch (e) {
            toast.error('Unable to verify provider.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSwitchChange = (e) => {
        setSwitchDisabled(true);

        var updatedProviders = [...ssoConfig.providers];
        var editProv = { ...updatedProviders[e.target.id] };
        editProv.isEnabled = e.target.checked;
        editProv.companyID = currentCompany;

        axios
            .put('/sso/provider', editProv)
            .then(() => {
                setSSOConfig((prevState) => {
                    const updatedProvidersState = [...prevState.providers];
                    updatedProvidersState[e.target.id] = editProv;

                    if (
                        updatedProviders.some(
                            (a) => a.isVerified && a.isEnabled,
                        )
                    ) {
                        setSwitchDisabled(true);
                        return {
                            ...prevState,
                            isEnabled: false,
                            isEnforced: false,
                            providers: updatedProvidersState,
                        };
                    }

                    return {
                        ...prevState,
                        providers: updatedProvidersState,
                    };
                });
            })
            .catch((e) => toast.error(e?.response?.data?.message))
            .finally(() => {
                setSwitchDisabled(false);
            });
    };

    return (
        <>
            <h4 style={{ marginBottom: '20px', fontSize: '1.5rem' }}>
                Single Sign-On (SSO) Settings
            </h4>
            {isLoading ? (
                <Stack>
                    <Stack mb={3}>
                        <HStack width={'8%'}>
                            <SkeletonCircle flex={1} size={5} />
                            <Skeleton flex={3} h={5} />
                        </HStack>
                        <Skeleton h={5} width={'10%'} />
                    </Stack>

                    <HStack width={'12%'}>
                        <Skeleton flex={1} border={'10px'} h={6} />
                        <Skeleton flex={1} border={'10px'} h={8} />
                    </HStack>
                    <Divider h={1} />
                    <HStack>
                        <Skeleton border={'10px'} flex={1} h={5} />
                        <Skeleton border={'10px'} flex={1} h={5} />
                        <Skeleton border={'10px'} flex={1} h={5} />
                        <Skeleton border={'10px'} flex={1} h={5} />
                    </HStack>
                    <HStack>
                        <Skeleton border={'10px'} flex={1} h={8} />
                        <Skeleton border={'10px'} flex={1} h={8} />
                        <Skeleton border={'10px'} flex={1} h={8} />
                        <Skeleton border={'10px'} flex={1} h={8} />
                    </HStack>
                    <Divider h={1} />
                    <Stack mb={3}>
                        <HStack width={'8%'}>
                            <SkeletonCircle flex={1} size={5} />
                            <Skeleton flex={3} h={5} />
                        </HStack>
                        <Skeleton width={'26%'} h={5} />
                    </Stack>
                </Stack>
            ) : (
                <>
                    {!ssoConfig.providers?.some(
                        (p) => p.isVerified && p.isEnabled,
                    ) && (
                        <Alert mb={'0.5rem'} status="info">
                            <AlertIcon />
                            To allow or enforce SSO, a provider must be verified
                            and active.
                        </Alert>
                    )}
                    <FormControl mb={4}>
                        <Box className="flex items-center">
                            <Switch
                                data-walkthroughid={ufAllowSSO}
                                isDisabled={
                                    !ssoConfig.providers?.some(
                                        (p) => p.isVerified && p.isEnabled,
                                    )
                                }
                                isChecked={ssoConfig.isEnabled}
                                onChange={handleSSOCheckbox}
                                mb={0}
                            />

                            <FormLabel mb={0} ml={2}>
                                Allow SSO
                            </FormLabel>
                        </Box>
                        <FormHelperText>
                            Toggle to allow SSO login
                        </FormHelperText>
                    </FormControl>

                    <FormControl mb={3} display={'flex'}>
                        <h5
                            style={{
                                marginRight: '10px',
                                marginBottom: 0,
                                fontSize: '1.25rem',
                            }}>
                            Providers
                        </h5>
                        <Button
                            className="btn btn-primary btn-sm"
                            data-walkthroughid={ufProviderBtn}
                            onClick={() => onOpenProvider()}>
                            {' '}
                            <AddRoundedIcon
                                fontSize="small"
                                style={{
                                    marginBottom: '2%',
                                }}
                            />{' '}
                            Provider
                        </Button>
                    </FormControl>
                    <TableContainer mb={4}>
                        <Table
                            colorScheme={'messenger'}
                            variant={'simple'}
                            size={'sm'}>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th textAlign={'center'}>Verified</Th>
                                    <Th>Active</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ssoConfig.providers?.length > 0 ? (
                                    ssoConfig.providers.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>
                                                {
                                                    providersOption.find(
                                                        (a) =>
                                                            a.value ==
                                                            item.provider,
                                                    ).name
                                                }
                                            </Td>

                                            <Td textAlign={'center !important'}>
                                                {item.isVerified ? (
                                                    <CheckIcon
                                                        color={'whatsapp.500'}
                                                    />
                                                ) : (
                                                    <ChakraButton
                                                        data-walkthroughid={
                                                            ufVerifyBtn +
                                                            providersOption.find(
                                                                (a) =>
                                                                    a.value ==
                                                                    item.provider,
                                                            ).name
                                                        }
                                                        onClick={() =>
                                                            handleVerify()
                                                        }
                                                        _hover={{
                                                            textColor: 'white',
                                                        }}
                                                        size={'sm'}
                                                        colorScheme={'orange'}
                                                        className="btn"
                                                        leftIcon={
                                                            <WarningTwoIcon />
                                                        }>
                                                        Verify
                                                    </ChakraButton>
                                                )}
                                            </Td>

                                            <Td>
                                                <Switch
                                                    data-walkthroughid={
                                                        ufActiveBtn +
                                                        providersOption.find(
                                                            (a) =>
                                                                a.value ==
                                                                item.provider,
                                                        ).name
                                                    }
                                                    isDisabled={
                                                        item.isVerified ==
                                                            false ||
                                                        (item.isVerified ==
                                                            true &&
                                                            switchDisabled ==
                                                                true)
                                                    }
                                                    id={index}
                                                    w={5}
                                                    onChange={(e) =>
                                                        handleSwitchChange(e)
                                                    }
                                                    isChecked={item.isEnabled}
                                                />
                                            </Td>

                                            <Td>
                                                <Tooltip
                                                    label={'Edit Provider'}
                                                    hasArrow
                                                    placement="bottom">
                                                    <IconButton
                                                        data-walkthroughid={
                                                            ufEditBtn +
                                                            providersOption.find(
                                                                (a) =>
                                                                    a.value ==
                                                                    item.provider,
                                                            ).name
                                                        }
                                                        onClick={() => onOpen()}
                                                        className="btn"
                                                        icon={
                                                            <EditRoundedIcon />
                                                        }
                                                        size="xs"
                                                        color={
                                                            darkMode
                                                                ? 'white'
                                                                : 'inherit'
                                                        }
                                                        bg="transparent"
                                                        _hover={{
                                                            bg: 'transparent',
                                                            color: darkMode
                                                                ? 'white'
                                                                : 'inherit',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Td>
                                        </Tr>
                                    ))
                                ) : (
                                    <Tr>
                                        <Td borderBottom={'none'}>
                                            No providers configured
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <FormControl mb={4}>
                        <Box className="flex items-center">
                            <Switch
                                data-walkthroughid={ufEnforceSSO}
                                isDisabled={
                                    !ssoConfig.providers?.some(
                                        (p) => p.isVerified && p.isEnabled,
                                    )
                                }
                                isChecked={ssoConfig.isEnforced}
                                onChange={handleEnforceCheckbox}
                                mb={0}
                            />
                            <FormLabel mb={0} ml={2}>
                                Enforce SSO
                            </FormLabel>
                        </Box>
                        <FormHelperText>
                            Make SSO mandatory for all users. Leave blank to
                            make it optional
                        </FormHelperText>
                    </FormControl>
                </>
            )}

            {isChanged && (
                <Alert mb={'0.5rem'} status="warning">
                    <AlertIcon />
                    Changes detected. Please remember to save.
                </Alert>
            )}

            <Box
                style={{
                    border: 'none',
                    textAlign: 'center',
                    justifyContent: 'center',
                    marginTop: 10,
                }}>
                <ChakraButton
                    data-walkthroughid={ufSaveBtn}
                    isDisabled={
                        isLoading ||
                        !ssoConfig.providers?.some(
                            (p) => p.isVerified && p.isEnabled,
                        )
                    }
                    isLoading={isBusy}
                    type="submit"
                    className="btn-sm disabled:cursor-not-allowed"
                    onClick={() => handleOnSave()}>
                    Save
                </ChakraButton>
            </Box>

            <AddProvider
                isOpen={isOpenProvider}
                onClose={onCloseProvider}
                ssoProviders={ssoConfig.providers?.map((prov) => prov.provider)}
            />

            <EditMicrosoftSSO
                isOpen={isOpen}
                onClose={onClose}
                providerId={
                    ssoConfig.providers?.find((a) => a.provider == 0)?.id
                }
            />
        </>
    );
}
