import { useState } from 'react';
import { FormControl, Stack, FormLabel, Tooltip, Icon } from '@chakra-ui/react';
import { Field } from 'formik';
import NameField from '@/components/forms/new/formikFormFields/NameField';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';

const AdminUsersFirstName = ({ errors, touched, darkMode }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.firstName && touched.firstName}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack direction={'row'} spacing={stackSpacing} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>
                    First Name <span style={{ color: '#e53e3e' }}>*</span>
                </FormLabel>
                <Field
                    component={NameField}
                    walkthroughId={walkthroughIds.adminsGeneral.ufFirstName}
                    id="firstName"
                    name="firstName"
                    validate={(value) => {
                        if (!value || value === '') {
                            return 'First name is required';
                        }
                    }}
                />
                {isHovered && (
                    <Tooltip
                        hasArrow
                        label="Enter Admin User's first name"
                        aria-label="inputGroupSelect">
                        <Icon
                            color={darkMode ? 'white' : undefined}
                            position="relative"
                            marginLeft={'-14px !important'}
                            left="25px"
                            top="25px"
                        />
                    </Tooltip>
                )}
            </Stack>
        </FormControl>
    );
};

export default AdminUsersFirstName;
