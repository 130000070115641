import { ListItem, UnorderedList } from '@chakra-ui/react';
import { useState } from 'react';

import { INVALID_CHAR, NOT_AVAILABLE, NOT_VALID } from './helper';

const DisplayMessages = ({ groupedMessages }) => {
    const [showFullValidity] = useState(false);
    const [showFullAvailability] = useState(false);

    const renderMessage = (numbers, showFull, messageType) => {
        const displayNumbers = showFull ? numbers : numbers.slice(0, 3);
        const isTruncated = numbers.length > 3;

        return (
            <ListItem>
                {`${messageType}: ${displayNumbers.join(', ')}${isTruncated && !showFull ? ', ...' : ''}`}
            </ListItem>
        );
    };
    return (
        <UnorderedList>
            {groupedMessages.validity?.length > 0 &&
                renderMessage(
                    groupedMessages.validity,
                    showFullValidity,
                    NOT_VALID,
                )}
            {groupedMessages.invalidChars?.length > 0 && (
                <ListItem>
                    {INVALID_CHAR}, whitespace or number starts with 0
                </ListItem>
            )}
            {groupedMessages.availability?.length > 0 &&
                renderMessage(
                    groupedMessages.availability,
                    showFullAvailability,
                    NOT_AVAILABLE,
                )}
            )
        </UnorderedList>
    );
};

export default DisplayMessages;
