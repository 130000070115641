import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { modalTitle } from '../../../components/tables/TableAndModal';
import ItemCodeMapping from './edit/ItemCodeMapping';
import CallInclusions from './edit/CallInclusions';
import CallingRates from './edit/CallingRates';
import NumberBlockRates from './edit/NumberBlockRates';
import config from '../../../config.json';
import PlanDetails from './edit/PlanDetails';
import { ModalCopy, MultiChild } from '../../../components';

/*
 * Plans component that renders for the Plans page of the sideboard
 * The tabs in the edit page are ItemContent, Block, Calling, ItemCodeMappings and Types
 */
const Plans = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // modal data from state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // navigation data from state
    const { currentPartner, currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // scope data from state
    const { currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // default columns for table
    const defaultColumns = [
        {
            title: 'Wholesaler',
            field: 'wholesaler',
            hidden: currentScope !== 80,
        },
        {
            title: 'Partner',
            field: 'partner',
            hidden: currentScope < 60,
        },

        {
            title: 'Plan',
            field: 'name',
        },
        {
            title: 'Description',
            field: 'description',
        },
    ];

    // function that returns the table fetch query
    const tableFetch = () => {
        switch (currentScope) {
            case 40:
                return '/partner/' + currentPartner + '/plans';
            case 60:
                return '/wholesaler/' + currentWholesaler + '/plans';
            default:
                return '/plans/';
        }
    };

    return (
        <MultiChild
            tableRef={tableRef}
            tableFetch={tableFetch()}
            newPage
            onTableSucceed={(data) => {
                return data.map(({ wholesaler, partner, ...rest }) => ({
                    ...rest,
                    wholesaler:
                        wholesaler?.length > 0
                            ? wholesaler
                            : config.notAllocated.table,
                    partner:
                        partner?.length > 0
                            ? partner
                            : config.notAllocated.table,
                }));
            }}
            modalFetch={'/plan/' + modalData?.id}
            modalAdd="/plan/"
            size={'md'}
            modalUpdate="/plan/"
            modalCopy={`/plan/${modalData?.id}/copy`}
            modalUpload={(freshData, modalState) => {
                return {
                    ...freshData,
                    ...modalState,
                };
            }}
            modalInitState={{
                partnerID: currentPartner,
                wholesalerID: currentWholesaler,
                isActive: true,
            }}
            // Tabs are components all in the 'Rates' Folder
            tabs={[
                { details: true },
                { component: <CallingRates />, label: 'Calling Rates' },
                { component: <CallInclusions />, label: 'Call Inclusions' },
                {
                    component: <NumberBlockRates />,
                    label: 'Number Block Rates',
                },
                { component: <ItemCodeMapping />, label: 'Item Code Mappings' },
            ]}
            dynamicScope={{ minimumScope: 40 }}
            modalValidate={() => {
                var newErrorState;
                if (modalMode === 'Copy') {
                    newErrorState = {
                        copyName:
                            (!modalState?.copyName ||
                                modalState?.copyName?.length < 1) &&
                            'Field is required.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !newErrorState.copyName;
                }
                newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    country:
                        (!modalState?.country ||
                            modalState?.country?.length < 1) &&
                        'Field is required.',
                    countryPrefix:
                        (!modalState?.countryPrefix ||
                            modalState?.countryPrefix?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(
                    newErrorState.name ||
                    newErrorState.country ||
                    newErrorState.countryPrefix
                );
            }}
            title="Plans"
            defaultColumns={defaultColumns}
            add
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            modalContent={
                modalMode === 'Copy' ? (
                    <ModalCopy
                        modalData={modalData}
                        modalState={modalState}
                        modalStateError={modalStateError}
                        setModalState={(payload) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload,
                            });
                        }}
                    />
                ) : (
                    <PlanDetails />
                )
            }
            modalTitle={modalTitle(modalData, 'name', ' Plan ', modalMode)}
            rowActions={{
                copiable: true,
                multiChildTable: {
                    name: 'Edit Plan, Rates and Inclusions',
                    to: 0,
                },
            }}
            onModalSucceed={(res) => {
                dispatch({
                    type: 'OPEN_CHILD_TABLE',
                    payload: { ...res.data, append: true },
                });
                dispatch({
                    type: 'CHANGE_BREADCRUMB_VIEW',
                    payload: 1,
                });
                setTimeout(() => {
                    dispatch({
                        type: 'CHANGE_BREADCRUMB_VIEW',
                        payload: 0,
                    });
                }, 500);

                dispatch({
                    type: 'RESET_MODAL',
                });
            }}
        />
    );
};

export default Plans;
