import React from 'react';
import {Box} from '@chakra-ui/react';
import styles from './styles';
import {PlusSquareIcon} from '@chakra-ui/icons';

export default function DashboardServiceAddServiceCardContent({
    children,
    ...props
}) {
    return (
        <Box sx={styles.DashboardServiceAddServiceCardContent} {...props}>
            {children}
        </Box>
    );
}
