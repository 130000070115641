import { useMutation } from '@tanstack/react-query';

import { postDeactivateNumbers } from 'API/TPMTrial';
import { useSingleToast } from 'hooks/useSingleToast';

/**
 * Custom hook that contain a `mutateAsync` function to trigger the tpm numbers deactivation.
 *
 * @example
 * const { mutateAsync: deactivateNumbers } = useDeactivate();
 *
 * const handleSubmitDn = async () => {
 *     deactivateNumbers({
 *         serviceID: <TpmServiceID>,
 *         phoneNumbers: ['61435678910']
 *     });
 * };
 **/
function useDeactivate() {
    const { successToast, errorToast } = useSingleToast();
    return useMutation({
        /**
         * Mutation function to deactivate phone numbers for a specific service.
         *
         * @param {Object} params - Parameters for deactivation.
         * @param {string} params.serviceID - The ID of the service.
         * @param {string[]} params.phoneNumbers - Array of phone numbers to deactivate.
         * @returns {Promise<Object>} - The response from the API.
         */
        mutationFn: async ({ serviceID, phoneNumbers }) => {
            return await postDeactivateNumbers({
                serviceID,
                phoneNumbers,
            });
        },
        onSuccess: () => {
            successToast('Successfully disassociated operator number(s)!', {
                autoClose: 1500,
            });
        },
        onError: (e) => {
            errorToast(
                e?.response?.data?.message ||
                    'Unable to disassociate operator number(s). Please try again later',
                {
                    autoClose: 1500,
                },
            );
        },
    });
}

export default useDeactivate;
