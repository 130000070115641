import { axios } from '@/services/axios';

const getTrunkTypesServiceCompany = async (companyId) => {
    try {
        const trunkResult = await axios.get(
            `/Services/SBCaaS/Company/${companyId}/TrunkTypes/Available`,
        );
        return trunkResult;
    } catch (e) {
        throw new Error(e);
    }
};

export default getTrunkTypesServiceCompany;
