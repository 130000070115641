import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { Alert, AlertTitle } from '@material-ui/lab';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';

import { TableAndModal, LoadingFieldDropdown } from '../../../components';
import { NegativeSixDP } from 'components/maintenance/NumberFormatCustom';
import InvoiceLines from './invoiceLines/Index';
import config from '../../../config.json';
import { CustomFieldsForm } from '../../organisations';
import { axios } from '../../../services/axios';
import ModalApprovalContent from './ModalApprovalContent';

import walkthroughIds from './walkthroughIds';

/*
 * Base component for rendering the Invoices tab on the sidebar
 */
const Invoice = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [warning, setWarning] = useState(null);

    // table state data
    const { child, parent, invoiceType } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // modal state data
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // scope state
    const { requiredScope, currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // company, wholesaler and partner from state
    const { currentCompany, currentPartner, currentWholesaler } = useSelector(
        (state) => {
            return { ...state.navigationLists };
        },
    );

    const { invoices } = walkthroughIds;

    // default columns for table
    const defaultColumns = [
        {
            title: 'Invoiced By',
            field: 'parentName',
        },
        {
            title: 'Invoiced To',
            field: 'entityName',
        },
        {
            title: 'Number',
            field: 'invoiceNumber',
        },
        {
            title: 'Due Date',
            field: 'dueDate',
            type: 'date',
            width: '15%',
        },
        {
            title: 'End Date',
            field: 'endDate',
            defaultSort: 'desc',
            type: 'date',
            width: '15%',
        },
        {
            title: 'Total',
            field: 'invoiceTotal',
            doNotOverrideSort: true,
            render: (rowData) => {
                return (
                    <NegativeSixDP
                        displayType={'text'}
                        value={rowData['invoiceTotal']}
                    />
                );
            },
        },
        {
            title: 'Line Count',
            field: 'lineCount',
            doNotOverrideSort: true,
        },
        {
            title: 'Status',
            field: 'invoiceStatus',
            lookup: config.invoices.statusJSON,
            customSort: (l, r) => {
                const leftValue =
                    typeof l === 'object'
                        ? config.invoices.statusJSON[l?.invoiceStatus]
                        : config.invoices.statusJSON[l];
                const rightValue =
                    typeof r === 'object'
                        ? config.invoices.statusJSON[r?.invoiceStatus]
                        : config.invoices.statusJSON[r];

                return leftValue?.localeCompare(rightValue);
            },
        },
        ...config.table.custom,
    ];

    /*
     * onTableFetch Function for the TableAndModal
     * checks if currentCompany / partner / wholesaler actually set for proper filtering
     * Then after that returns a axios call with the data
     */
    const onTableFetch = () => {
        setWarning(null);
        switch (currentScope) {
            case 20:
                if (!currentCompany) {
                    setWarning({ scope: 'Company' });
                }
                break;

            case 40:
                if (!currentPartner) {
                    setWarning({ scope: 'Partner' });
                }
                break;
            case 60:
                if (!currentWholesaler) {
                    setWarning({ scope: 'Wholesaler' });
                }
                break;
            default:
                break;
        }
        switch (String(invoiceType)) {
            case '2':
                return axios.get('/invoices/', {
                    params: {
                        wholesalerID:
                            currentScope === 60 ? currentWholesaler : null,
                        type: invoiceType,
                    },
                });
            case '1':
                return axios.get('/invoices/', {
                    params: {
                        wholesalerID:
                            currentScope === 60 ? currentWholesaler : null,
                        partnerID: currentScope === 40 ? currentPartner : null,
                        type: invoiceType,
                    },
                });
            case '0':
                return axios.get('/invoices/', {
                    params: {
                        type: invoiceType,
                        companyID: currentScope === 20 ? currentCompany : null,
                        partnerID: currentScope === 40 ? currentPartner : null,
                        wholesalerID:
                            currentScope === 60 ? currentWholesaler : null,
                    },
                });
            default:
                dispatch({
                    type: 'CHANGE_TABLE_INVOICE_TYPE',
                    payload: 0,
                });

                return axios.get('/invoices/', {
                    params: {
                        type: 0,
                        companyID: currentScope === 20 ? currentCompany : null,
                    },
                });
        }
    };

    /*
     * Child component is the button in the table with the tooltip 'View Invoice Lines'
     * When Clicked -> opened in a full page modal
     */
    if (child) {
        return (
            <>
                <Breadcrumbs aria-label="breadcrumb" className="ml-2">
                    <Link
                        href="/"
                        color="inherit"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                                type: 'RESET_CHILD_TABLE',
                            });
                            dispatch({
                                type: 'RESET_MODAL',
                            });
                        }}>
                        All Invoices
                    </Link>
                    <div style={{ minWidth: 500 }}>
                        <LoadingFieldDropdown
                            dropDownValue="id"
                            displayField="invoiceNumber"
                            searchable
                            staticEnum={parent?.sort((l, r) =>
                                l?.entityName?.localeCompare(r?.entityName),
                            )}
                            onChange={(v) => {
                                dispatch({
                                    type: 'CHANGE_CHILD_TABLE',
                                    payload: { id: v },
                                });
                                dispatch({
                                    type: 'INIT_WEB_WORKER_STATE',
                                    payload: {
                                        fetchURL: `/invoicelines/${v}`,
                                        attribute: 'invoiceLines',
                                    },
                                });
                            }}
                            groupBy={(option) =>
                                option?.entityName +
                                ' - ' +
                                config.invoices.statusJSON[
                                    option?.invoiceStatus
                                ]
                            }
                            onDisplayDropdown={(value) => {
                                if (typeof value === 'string') {
                                    const parentItem = parent?.find(
                                        (v) => v.id === value,
                                    );
                                    if (parentItem) {
                                        const {
                                            entityName,
                                            invoiceNumber,
                                            invoiceStatus,
                                        } = parentItem;
                                        return (
                                            entityName +
                                            ' - ' +
                                            config.invoices.statusJSON[
                                                invoiceStatus
                                            ] +
                                            ' - ' +
                                            invoiceNumber
                                        );
                                    }
                                }
                                return (
                                    value?.entityName +
                                    ' - ' +
                                    config.invoices.statusJSON[
                                        value?.invoiceStatus
                                    ] +
                                    ' - ' +
                                    value?.invoiceNumber
                                );
                            }}
                            noEmptyOption
                            fieldValue={child.id}
                        />
                    </div>
                    <Typography>Invoice Lines</Typography>
                </Breadcrumbs>
                <InvoiceLines />
            </>
        );
    }

    /*
     * Returns a Alert if the warning is set else
     * Returns a TableAndModal for invoices
     */
    return (
        <>
            {warning && (
                <Alert severity="warning" style={{ marginTop: 10 }}>
                    <AlertTitle>No {warning.scope} Set </AlertTitle>
                    There are no {warning.scope} set. Invoice list is not
                    filtered to the current {warning.scope} being managed.
                </Alert>
            )}
            <TableAndModal
                tableRef={tableRef}
                onTableFetch={onTableFetch}
                title="Invoices"
                defaultColumns={defaultColumns}
                modalFetch={'/invoice/' + modalData?.id}
                modalApproval={`/invoice/${modalData?.id}/${modalState?.upload}`}
                rowActions={{
                    editable: requiredScope > [20, 40, 60][invoiceType], // displayCustomFields.invoices, //editable 1 up
                    invoiceType: requiredScope !== 20, //not a CA
                    xero: { position: 'row' },
                    cdr: invoiceType === 0 && { position: 'row' }, //CDR for company invoice type only
                }}
                dynamicScope
                modalTitle={
                    modalMode === 'Edit'
                        ? `Edit Invoice Custom Attributes :  ${modalData?.parentName} - ${modalData?.entityName} - ${modalData?.invoiceNumber}`
                        : ` Update Invoice Status of:  ${modalData?.parentName} - ${modalData?.entityName} - ${modalData?.invoiceNumber}`
                }
                additionalActions={[
                    (rowData) => ({
                        icon: () => (
                            <ListRoundedIcon
                                fontSize="small"
                                data-walkthroughid={
                                    rowData.tableData.id === 0
                                        ? invoices.ufViewInvoiceBtn
                                        : null
                                }
                            />
                        ), //for viewing child tables only
                        tooltip: 'View Invoice Lines',
                        position: 'row',
                        onClick: (event, rowData) => {
                            dispatch({
                                type: 'OPEN_CHILD_TABLE',
                                payload: rowData,
                            });
                            dispatch({
                                type: 'INIT_WEB_WORKER_STATE',
                                payload: {
                                    fetchURL: `/invoicelines/${rowData.id}`,
                                    attribute: 'invoiceLines',
                                },
                            });
                        },
                    }),
                    (rowData) => ({
                        isFreeAction: false,
                        position: 'row',
                        tooltip: 'Update Invoice Status',
                        icon: () => (
                            <LoopRoundedIcon
                                fontSize="small"
                                data-walkthroughid={
                                    rowData.tableData.id === 0
                                        ? invoices.ufReloadBtn
                                        : null
                                }
                            />
                        ),
                        hidden:
                            requiredScope <= [20, 40, 60][invoiceType] ||
                            rowData?.invoiceStatus === 8,
                        onClick: () =>
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Approval',
                                    data: rowData,
                                    show: true,
                                    state: config.invoices.statusUpload[
                                        rowData?.invoiceStatus
                                    ][0],
                                },
                            }),
                    }),
                ]}
                groupable
                filterable
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
                modalContent={
                    modalMode === 'Approval' ? (
                        <ModalApprovalContent />
                    ) : (
                        <CustomFieldsForm />
                    )
                }
                modalUpdate={`/invoice/attributes/${modalData?.id}`}
                modalUpload={(freshData, state) => {
                    const { attribute1, attribute2, attribute3 } = {
                        ...freshData,
                        ...state,
                    };
                    return {
                        config: {
                            params: {
                                attribute1,
                                attribute2,
                                attribute3,
                            },
                        },
                    };
                }}
                modalButtons={
                    modalMode === 'Approval' && (
                        <Button
                            variant="primary"
                            onClick={() => {
                                dispatch({
                                    type: 'ON_MODAL_SUBMIT',
                                    payload: true,
                                });
                            }}>
                            Update
                        </Button>
                    )
                }
            />
        </>
    );
};

export default Invoice;
