import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { axios } from 'services/axios';
import { Box, Tooltip, forwardRef } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import Button from 'components/v4/Button';
import Dropdown from 'components/v4/Select';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import updateServiceProfiles from 'API/ServiceProfiles/updateServiceProfiles';
import { Select as _MultiSelect, Select as _Select } from 'chakra-react-select';
import {
    multiSelectDataMapper,
    toSentenceCase,
    filterUniqueItemList,
} from 'utils/utils';
import getServiceProfiles from 'API/getServiceProfiles';
import style from './styles';
import FooterContainer from './FooterContainer';
import Heading from 'components/v4/Heading';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import createServiceProfile from 'API/ServiceProfiles/createServiceProfile';
import getTrunkTypesSpPartner from 'API/ServiceProfiles/getTrunkTypesServiceProfile';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import useFieldValidation from 'hooks/useFieldValidation';
import FieldValidation from 'components/v4/FieldValidation';
import { validationSchemaSBCAAS } from './schema';

import walkthroughIds from '../../walkthroughIds';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import config from '@/config.json';

const MAX_REDUNDANCY_DROPDOWN = [
    {
        name: '1',
        value: 1,
    },
    {
        name: '2',
        value: 2,
    },
    {
        name: '3',
        value: 3,
    },
    {
        name: '4',
        value: 4,
    },
];

const MultiSelect = forwardRef((props, ref) => (
    <_MultiSelect selectedOptionColor="brand" ref={ref} {...props} />
));

const Select = forwardRef((props, ref) => (
    <_Select selectedOptionColor="brand" ref={ref} {...props} />
));

const SBCAsAService = ({
    mode,
    data,
    handleBackButton,
    level,
    visibleScopeOptions,
}) => {
    const {
        currentWholesaler: wholesalerID,
        currentPartner: partnerID,
        wholesalers,
        companiesAndPartners,
    } = useSelector((state) => state.navigationLists);
    const { requiredScope } = useSelector((state) => state.login);

    const { darkMode } = useSelector((state) => state.settings);
    const { ...ufElements } = walkthroughIds.serviceProfiles;
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    const DEFAULT_SBCAAS_PAYLOAD = {
        name: '[name] - SBC as a Service Wholesaler Profile',
        maxChannel: 10,
        maxRedundancy: 2,
        partnerID: level === 'partner' ? partnerID : undefined,
        wholesalerID: level === 'partner' ? undefined : wholesalerID,
        serviceType: 'SBCaaS',
        visibleScope: level === 'partner' ? 20 : 60,
        regions: [],
        trunkTypes: [],
    };

    useEffect(() => {
        if (mode !== 'EDIT' && level !== 'partner' && wholesalers?.length > 0) {
            const wsName = wholesalers.find(
                ({ id, name }) => id === wholesalerID,
            )?.name;
            handleChangeState({
                name: `${wsName} - SBC as a Service Wholesaler Profile`,
            });
        } else if (
            mode !== 'EDIT' &&
            level === 'partner' &&
            wholesalers?.length > 0
        ) {
            const partnerName = companiesAndPartners.find(
                ({ id, name }) => id === partnerID,
            )?.name;
            handleChangeState({
                name: `${partnerName} - SBC as a Service Partner Profile`,
            });
        }
    }, [mode, companiesAndPartners, wholesalers, level]);

    const [modalState, setModalState] = useState(
        data || DEFAULT_SBCAAS_PAYLOAD,
    );
    const [availableRegions, setAvailableRegions] = useState([]);
    const [trunkTypes, setTrunkTypes] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaSBCAAS,
    );

    /*
     * A function to get the current scope and append it for API calls
     */
    const scope = () => {
        if (level === 'partner') {
            return `Partner/${partnerID}`;
        } else {
            return `Wholesaler/${wholesalerID}`;
        }
    };

    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleGetDropdownData = async () => {
        setIsLoading(true);
        const fetchSPData = mode === 'EDIT' && data;
        try {
            const result = await axios.get(
                `/ServiceProfiles/SBCaaS/${scope()}/Regions/Available`,
            );
            // NOTE: if partner, fetch sbcaas of partner
            if (level === 'partner') {
                const trunkResult = await getTrunkTypesSpPartner(partnerID);
                setTrunkTypes(trunkResult.data.map(multiSelectDataMapper));
            } else {
                // NOTE: if wholesaler, check all trunk types available
                const trunkResult = await axios.get(`TrunkTypes/Available`);
                setTrunkTypes(trunkResult.data.map(multiSelectDataMapper));
            }
            setAvailableRegions(result.data.map(multiSelectDataMapper));
            if (fetchSPData) {
                const spResult = await getServiceProfiles({
                    level,
                    id: data?.id,
                });
                const regions = spResult.data.regions.map(
                    multiSelectDataMapper,
                );
                const trunkTypes = spResult.data.trunkTypes.map(
                    multiSelectDataMapper,
                );
                setModalState({
                    ...spResult.data,
                    regions,
                    trunkTypes,
                });
            }
        } catch (e) {
            setError(e);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    };

    const handleSubmit = async () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }

        setIsLoading(true);
        try {
            if (mode === 'EDIT') {
                const result = await updateServiceProfiles({
                    type: 'SBCaaS',
                    level,
                    payload: modalState,
                    scope: requiredScope,
                });
                handleBackButton();
            } else {
                const result = await createServiceProfile({
                    type: 'SBCaaS',
                    payload: modalState,
                    level,
                    scope: requiredScope,
                });
                handleBackButton();
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetDropdownData();
    }, [data, level]);

    useEffect(() => {
        const excludedRegions = filterUniqueItemList(
            availableRegions,
            modalState?.regions,
            'id',
        );
        if (
            error?.response?.data?.message.includes('Invalid Region provided')
        ) {
            setError({
                response: {
                    data: {
                        message: `Invalid Region provided (${excludedRegions[0]?.label})`,
                    },
                },
            });
        }
    }, [error?.response?.data?.message, availableRegions, modalState?.regions]);

    if (isLoading) {
        return <LoadingIndicator />;
    }
    return (
        <Box sx={{ maxWidth: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading as="h2" fontSize="3xl" sx={{ marginBottom: '10px' }}>
                {`${toSentenceCase(mode)} ${modalState.name || data?.name}`}
            </Heading>

            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Service name</FormLabel>
                <Input
                    value={modalState.name}
                    onChange={(e) =>
                        handleChangeState({ name: e.target.value })
                    }
                    sx={style.inputField}
                    data-walkthroughid={ufElements.ufServiceNameInput}
                />
                <FieldValidation errors={validationErrors?.name} />
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>
                    Visible Scope &nbsp;
                    <Tooltip
                        label="Determine visibility and service creation level"
                        hasArrow
                        placement="top-end">
                        <InfoIcon />
                    </Tooltip>
                </FormLabel>
                {modalState.visibleScope <= requiredScope ? (
                    <Select
                        defaultValue={visibleScopeOptions.find(
                            (option) =>
                                option.value === modalState.visibleScope,
                        )}
                        options={visibleScopeOptions}
                        chakraStyles={chakraStyles}
                        onChange={(selectedOption) => {
                            handleChangeState({
                                visibleScope: selectedOption.value,
                            });
                        }}
                    />
                ) : (
                    <Input
                        isDisabled
                        value={config.scope.json[modalState.visibleScope]}
                    />
                )}
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Trunk Types</FormLabel>
                <MultiSelect
                    isMulti
                    name="trunk types"
                    placeholder="- Select Trunk Types -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({ trunkTypes: selectedOptions });
                    }}
                    options={trunkTypes}
                    defaultValue={modalState.trunkTypes}
                    chakraStyles={chakraStyles}
                />
                {/* <FieldValidation errors={validationErrors?.trunkTypes} /> */}
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Regions</FormLabel>
                <MultiSelect
                    isMulti
                    name="colors"
                    placeholder="- Select Regions -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({ regions: selectedOptions });
                    }}
                    options={availableRegions}
                    defaultValue={modalState.regions}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.regions} />
            </Box>
            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Max Channel per SBC</FormLabel>
                <Input
                    placeholder="1 - 100"
                    type="number"
                    pattern="[0-9]"
                    onChange={(e) =>
                        handleChangeState({ maxChannel: e.target.value })
                    }
                    value={modalState?.maxChannel}
                    sx={style.inputField}
                    data-walkthroughid={ufElements.ufMaxChannelsInput}
                />
                <FieldValidation errors={validationErrors?.maxChannel} />
            </Box>

            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufMaxRedundancyLevelSelect}>
                <FormLabel sx={style.label}>Max Redundancy Level</FormLabel>
                <Select
                    defaultValue={MAX_REDUNDANCY_DROPDOWN[0]}
                    value={MAX_REDUNDANCY_DROPDOWN.find(
                        (option) => option.value === modalState.maxRedundancy,
                    )}
                    options={MAX_REDUNDANCY_DROPDOWN}
                    onChange={(e) =>
                        handleChangeState({ maxRedundancy: e.value })
                    }
                    getOptionLabel={(option) => option.name}
                    chakraStyles={chakraStyles}
                />
            </Box>

            <FooterContainer>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    w={{ base: 'full', md: '80px' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufElements.ufCloseButton}>
                    Close
                </Button>
                <Button
                    w={{ base: 'full', md: '80px' }}
                    onClick={handleSubmit}
                    data-walkthroughid={ufElements.ufUpdateButton}>
                    {mode === 'ADD' ? 'Add' : 'Update'}
                </Button>
            </FooterContainer>
        </Box>
    );
};

export default SBCAsAService;
