import { Input } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ChakraInputProps
 * @property {React.RefObject<HTMLInputElement>} customRef
 * @property {ChakraInputProps & import('@chakra-ui/react').InputProps} props
 * @param {ChakraInputProps} customRef
 * @param {import('@chakra-ui/react').InputProps} rest
 * @returns
 */
export default function ChakraInput({ customRef, ...rest }) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Input
            {...rest}
            ref={customRef}
            color={darkMode ? 'dark.textDark' : null}
            background={darkMode ? 'dark.bgDark' : null}
            borderColor={darkMode ? 'dark.tcap-borderGray' : null}
        />
    );
}
