import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { AnimatePresence, motion } from 'framer-motion';
import config from '../../../../config.json';

import Image from '../../../../components/v4/Image';
import FormLabel from '../../../../components/v4/FormLabel';
import Input from '../../../../components/v4/Input';
import Button from '../../../../components/v4/Button';
import FieldValidation from '../../../../components/v4/FieldValidation';
import useFieldValidation from '../../../../hooks/useFieldValidation';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';
import useDetectFileType from '../../../../hooks/useDetectFileType';

import { validationSchema } from './consts';
import { exit, inputVariants, buttonVariants } from '../constants.js';

export default function ForgotPassword() {
    const [fields, setFields] = useState({ email: '' });
    const [hasError, setHasError] = useState(null);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const { validationErrors, validatefn } = useFieldValidation(
        fields,
        validationSchema,
    );

    /**
     * Pingco Logo.
     */
    const { imagePathLightMode } = useSelector((state) => {
        return { ...state.login };
    });

    const fileType = useDetectFileType(
        import.meta.env.VITE_APP_IMAGE_BASE + imagePathLightMode,
    );

    /**
     * Password reset handler.
     */
    function onPasswordResetHandler(e) {
        e.preventDefault();

        if (!validatefn()) return;
        setLoading(true);
        setHasError(null);
        setHasSuccess(false);

        const host = window.location.host.startsWith('localhost')
            ? 'teamsdev.tcap.dev'
            : window.location.host;
        const endpoint = `https://${host}/api/ui/forgotpassword`;
        //const endpoint = `https://teamsdev.tcap.dev/api/ui/forgotpassword`;

        fetch(endpoint, {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json;charset=UTF-8',
                'x-requestscope': '20',
            },
            method: 'POST',
            body: JSON.stringify({
                username: fields.email,
                host: `${host}/ui/change-password?token=[TOKEN]`,
            }),
        })
            .then(async (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setHasError(null);
                    setHasSuccess(true);
                } else {
                    const data = await response.json();
                    setHasError(data.message);
                    setHasSuccess(false);
                }
            })
            .catch((e) => {
                setLoading(false);
                setHasError(
                    e?.message || 'An error occurred, please try again later.',
                );
                setHasSuccess(false);
            });
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 60px',
                background: 'white',
                boxShadow: '2xl',
                width: '500px',
                margin: '0 auto',
                borderRadius: '10px',
            }}
        >
            <Box as="form" onSubmit={onPasswordResetHandler}>
                {fileType === 'svg' && (
                    <object
                        alt="Domain Logo"
                        style={{
                            display: 'block',
                            maxWidth: '300px',
                            maxHeight: '150px',
                            margin: '0 auto',
                        }}
                        data={
                            import.meta.env.VITE_APP_IMAGE_BASE +
                            imagePathLightMode
                        }
                        type="image/svg+xml"
                    ></object>
                )}
                {fileType === 'png' && (
                    <Image
                        alt="Domain Logo"
                        style={{
                            display: 'block',
                            maxWidth: '300px',
                            maxHeight: '150px',
                            margin: '0 auto',
                        }}
                        src={
                            import.meta.env.VITE_APP_IMAGE_BASE +
                            imagePathLightMode
                        }
                    />
                )}

                <Box sx={{ margin: '25px 0' }}></Box>

                <AnimatePresence>
                    <motion.div
                        key="forgot-password-box"
                        initial="hidden"
                        whileInView="visible"
                        animate="visible"
                        exit={exit}
                    >
                        <motion.div variants={inputVariants}>
                            <FormLabel
                                sx={{ margin: 0 }}
                                for="forgot-email"
                                className="!text-black"
                            >
                                EMAIL
                            </FormLabel>
                            <Input
                                borderColor="gray"
                                variant="flushed"
                                size="sm"
                                className="!text-gray-700"
                                id="forgot-email"
                                value={fields.email}
                                onChange={(e) => {
                                    setFields({ email: e.target.value });
                                }}
                            />
                            <FieldValidation errors={validationErrors?.email} />
                        </motion.div>
                        <Box sx={{ margin: '15px 0', display: 'flex' }} />
                        <motion.div variants={buttonVariants}>
                            <Button
                                type="submit"
                                isDisabled={loading}
                                sx={{
                                    textAlign: 'center',
                                    display: 'block',
                                    width: '100%',
                                }}
                            >
                                Reset my password
                            </Button>

                            {hasError && (
                                <>
                                    <Box
                                        sx={{
                                            margin: '15px 0',
                                            display: 'flex',
                                        }}
                                    />

                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertDescription>
                                            {hasError}
                                        </AlertDescription>
                                    </Alert>
                                </>
                            )}
                            {hasSuccess && (
                                <>
                                    <Box
                                        sx={{
                                            margin: '15px 0',
                                            display: 'flex',
                                        }}
                                    />

                                    <Alert status="success">
                                        <AlertIcon />
                                        <AlertDescription>
                                            Please check your email for your
                                            password reset link.
                                        </AlertDescription>
                                    </Alert>
                                </>
                            )}
                            <Box sx={{ margin: '15px 0', display: 'flex' }} />

                            <Link to="/">
                                <Button
                                    variant="link"
                                    sx={{
                                        textAlign: 'center',
                                        margin: '10px auto 0 auto',
                                        display: 'block',
                                    }}
                                >
                                    Back to login
                                </Button>
                            </Link>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            </Box>
        </Box>
    );
}
