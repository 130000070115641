import React, { useRef } from 'react';
import {TextField} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'react-bootstrap';

import TableAndModal, {
    modalTitle,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';

/*
 * Carriers page in the general admin tab of TCAP
 * Handles CRUD for this aspect of the app
 */
const Carriers = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // default columns
    const defaultColumns = [
        {
            title: 'Carrier Name',
            field: 'name',
        },
    ];

    // changer handlers
    const carrierChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    // returns Table and Modal for the component
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/carriers/'}
            modalFetch={'/carrier/' + modalData?.id}
            modalAdd="/carrier/"
            size={'md'}
            modalUpdate="/carrier/"
            editable={{add: true, update: true}}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Carriers"
            defaultColumns={defaultColumns}
            rowActions={{
                editable: true,
            }}
            add
            groupable
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(modalData, 'name', ' Carriers ', modalMode)}
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !newErrorState.name;
            }}
            modalContent={
                <>
                    <Row style={{minHeight: 60}}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Carrier Name"
                                label="Carrier Name"
                                type="text"
                                value={modalState?.name}
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                onChange={carrierChangeHandler}
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default Carriers;
