import {
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Tooltip,
    Icon,
    forwardRef,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Input } from '@/components/v4';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import {
    NOT_ALLOCATED,
    PlanMode,
    managedByOptions,
} from '@/pages/accounts/resourceAccounts/constants';

const ChakraSelect = forwardRef((props, ref) => (
    <Select selectedOptionColor="brand" ref={ref} {...props} />
));
export default function General({
    state,
    validation,
    userPlans,
    numberList,
    userData,
    handleManagedBy,
    handleChangeNumber,
    handleChangePlan,
    handleChangeVoicePolicy,
    handleChangeDialPlan,
    chakraStyles,
    enabledPlanMode,
    partnerOnlyPlanMode,
    disabledPlanMode,
    isLoading,
    // assignedTrunkType,
}) {
    const plans = userPlans?.length && [
        ...userPlans,
        {
            id: null,
            name: 'Company Default Plan',
        },
    ];

    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Stack spacing="3">
            {/* Managed By */}
            <FormControl>
                <FormLabel>Managed By</FormLabel>
                <ChakraSelect
                    name="managedBy"
                    options={managedByOptions}
                    value={managedByOptions.find(
                        (item) => item.value === state.managedBy,
                    )}
                    onChange={(item) => handleManagedBy(item)}
                    chakraStyles={chakraStyles}
                    isLoading={isLoading}
                    placeholder="Select Managed By"
                />
            </FormControl>
            {state.managedBy === 0 && state.isLicensed && (
                <>
                    {/* Usage Location */}
                    <FormControl>
                        <FormLabel>Usage Location</FormLabel>
                        <Input
                            name="usageLocation"
                            value={state.usageLocation}
                            isDisabled
                        />
                    </FormControl>

                    {/* Number */}
                    <FormControl>
                        <FormLabel>
                            Number
                            <span
                                style={{
                                    display: 'inline-block',
                                    float: 'right',
                                }}>
                                <Tooltip
                                    hasArrow
                                    label="Only numbers in a supported status are available to assign. Please check your number in Telephony > Numbers."
                                    aria-label="inputGroupSelect">
                                    <Icon
                                        width={30}
                                        height={30}
                                        top={1}
                                        left={2}
                                        color={darkMode ? 'white' : undefined}
                                        position="relative">
                                        <IoMdInformationCircleOutline />
                                    </Icon>
                                </Tooltip>
                                Why can&apos;t I see my number?
                            </span>
                        </FormLabel>

                        <ChakraSelect
                            name={'number'}
                            options={numberList}
                            value={numberList?.find(
                                (num) => num.id === state.numberID,
                            )}
                            onChange={(v) => handleChangeNumber(v, numberList)}
                            getOptionLabel={(i) => i.telephoneNumber}
                            getOptionValue={(i) => i.id}
                            chakraStyles={chakraStyles}
                            formatOptionLabel={(option) =>
                                (Number(option['telephoneNumber']) &&
                                    `+ ${option['telephoneNumber']}`) ||
                                option['telephoneNumber']
                            }
                            placeholder={NOT_ALLOCATED}
                        />
                    </FormControl>

                    {/* Plan */}
                    {state.numberID &&
                        state.userPlanMode !== PlanMode.HIDDEN && (
                            <FormControl>
                                {(enabledPlanMode || partnerOnlyPlanMode) && (
                                    <>
                                        <FormLabel>Plan</FormLabel>
                                        <ChakraSelect
                                            options={plans}
                                            value={plans?.find(
                                                (item) =>
                                                    item.id === state.planID,
                                            )}
                                            onChange={handleChangePlan}
                                            placeholder="Company Default Plan"
                                            getOptionLabel={(i) => i.name}
                                            getOptionValue={(i) => i.id}
                                            chakraStyles={chakraStyles}
                                        />
                                    </>
                                )}
                                {disabledPlanMode && userData && (
                                    <>
                                        <FormLabel>Plan</FormLabel>
                                        <Input
                                            isDisabled
                                            type="text"
                                            value={
                                                isEmpty(state?.plan)
                                                    ? 'Company Default Plan'
                                                    : state.plan
                                            }
                                        />
                                    </>
                                )}
                            </FormControl>
                        )}

                    {/* IsDrNumber check AND Voice Policies AND dialPlans */}
                    {state.isDrNumber && (
                        <>
                            <FormControl isInvalid={!state?.voicePolicyID}>
                                <FormLabel>Voice Policy</FormLabel>
                                <ChakraSelect
                                    options={state?.voicePolicies}
                                    value={state?.voicePolicies?.find(
                                        (item) =>
                                            item.id === state.voicePolicyID,
                                    )}
                                    getOptionLabel={(i) => i.name}
                                    getOptionValue={(i) => i.id}
                                    onChange={handleChangeVoicePolicy}
                                    placeholder={NOT_ALLOCATED}
                                    chakraStyles={chakraStyles}
                                    isLoading={!state?.voicePolicies}
                                    // isDisabled={!state.voicePolicies}
                                />
                                <FormErrorMessage>
                                    {!state?.voicePolicyID &&
                                        (validation?.voicePolicyError ||
                                            'Field is required')}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!state?.dialPlanID}>
                                <FormLabel>Dial Plan</FormLabel>
                                <ChakraSelect
                                    options={state?.dialPlans}
                                    value={state?.dialPlans?.find(
                                        (item) => item.id === state.dialPlanID,
                                    )}
                                    getOptionLabel={(i) => i.name}
                                    getOptionValue={(i) => i.id}
                                    onChange={handleChangeDialPlan}
                                    placeholder={NOT_ALLOCATED}
                                    chakraStyles={chakraStyles}
                                    isLoading={!state?.dialPlans}
                                    // isDisabled={!state.dialPlans}
                                />
                                <FormErrorMessage>
                                    {!state?.dialPlanID &&
                                        (validation?.dialPlanError ||
                                            'Field is required')}
                                </FormErrorMessage>
                            </FormControl>
                        </>
                    )}
                </>
            )}
        </Stack>
    );
}
