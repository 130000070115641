export default {
    deleteMode: {
        ufTriggerName: '/triggers/input/triggerName',
        ufTriggerDescription: '/triggers/input/description',
    },
    editMode: {
        ufNameInput: '/triggers/input/name',
        ufEnabledCheckbox: '/triggers/checkbox/enabled',
        ufTypeSelect: '/triggers/select/type',
        ufEntitySelect: '/triggers/select',
        ufDescriptionTextarea: '/triggers/textarea/description',
        ufEventSelect: '/triggers/select/event',
        ufActionTypeSelect: '/triggers/select/actionType',

        ufEmailAddressInput: '/triggers/input/email',
        ufSubjectInput: '/triggers/input/subject',
        ufHtmlBodyCheckbox: '/triggers/checkbox/htmlBody',
        ufBodyTextarea: '/triggers/textarea/body',

        ufWebHookMethod: '/triggers/select/method',
        ufUriInput: '/triggers/input/uri',
        ufHeadersKeyInput: 'triggers/input/headersKey',
        ufHeadersValueInput: 'triggers/input/headersValue',
        ufClearHeadersBtn: 'triggers/button/clearHeaders',

        ufFieldsTags: '/triggers/tag/fields',
        ufDeleteBtn: '/triggers/button/delete',
    },
    addMode: {
        ufAddBtn: '/triggers/button/add',
        ufAddEnableBtn: '/triggers/button/addEnable',
    },
};
