import React from 'react';
import { Menu, MenuButton, IconButton } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { MenuList } from '@/components/v4';
import style from './styles';

function DashboardServiceCardHeaderActionButton({
    children,
    dataWalkthroughId,
    id,
}) {
    const { darkMode } = useSelector((state) => state.settings);
    const textColor = darkMode ? 'white' : 'black';
    const { actionButton } = style;

    const hasChildren =
        children &&
        React.Children.toArray(children).some((child) => {
            return React.isValidElement(child);
        });

    return hasChildren ? (
        <Menu key="3-dot-menu" id={id}>
            {() => (
                <React.Fragment key="3-dot-menu-wrapper">
                    <MenuButton
                        id={id}
                        sx={actionButton}
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreHorizRoundedIcon />}
                        variant="outline"
                        color={textColor}
                        _active={{
                            bg: darkMode
                                ? 'rgba(255, 255, 255, 0.16)'
                                : 'gray.200',
                        }}
                        data-walkthroughid={`/dashboard-services/card/${dataWalkthroughId}`}
                    />
                    <MenuList>{children}</MenuList>
                </React.Fragment>
            )}
        </Menu>
    ) : null;
}

export default DashboardServiceCardHeaderActionButton;
