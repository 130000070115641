import {
    Box,
    Text,
    Tooltip,
    IconButton,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { MdDialpad } from 'react-icons/md';

const ManageNumbers = ({
    cardStatus,
    isTrialExpired,
    darkMode,
    numbersPath,
    tpmId,
}) => {
    const { pathname } = useLocation();
    return cardStatus?.statusLabel === 'Healthy' && !isTrialExpired ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '15px',
            }}>
            <Text
                sx={{
                    fontWeight: '500',
                }}>
                Manage Numbers
            </Text>

            <span className="mx-1 inline-flex flex-shrink-0 self-center">
                <Tooltip
                    label={
                        'Associate and Manage Mobile Operator Accounts and Numbers'
                    }
                    hasArrow
                    placement="bottom">
                    <ChakraLink
                        as={Link}
                        to={`/${numbersPath}/tpm/${tpmId}/activation`}
                        state={{
                            from: pathname,
                        }}>
                        <IconButton
                            icon={
                                <MdDialpad
                                    icon="Settings"
                                    color={darkMode ? 'white' : 'black'}
                                />
                            }
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                            _active={{ bg: 'transparent' }}
                            _focus={{ bg: 'transparent' }}
                            onClick={() => {}}
                        />
                    </ChakraLink>
                </Tooltip>
            </span>
        </Box>
    ) : (
        <Box
            minH="21px"
            mb="15px"
            display="flex"
            aria-label="hidden"
            aria-hidden={true}
        />
    );
};

export default ManageNumbers;
