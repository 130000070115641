import { useState } from 'react';
import { TextEditor } from 'components';
import AutoFillMultiSelect from 'components/presentational/FillOnClickMultiselect';
import { TextField } from '@material-ui/core';
import { Box, Stack } from '@chakra-ui/react';
import Button from 'components/v4/Button';
import { useDispatch } from 'react-redux';
import './links.css';
const DEFAULT_SUPPORT_LINK_FIELDS = {
    COMPANY_ID: 'Company ID',
    PARTNER_ID: 'Partner ID',
    USER_ID: 'User ID',
    COMPANY_NAME: 'Company Name',
    COMPANY_LEGAL_ENTITY_NAME: 'Company Legal Entity Name',
    USER_EMAIL: 'User Email',
    USER_FIRST_NAME: 'User First Name',
    USER_LAST_NAME: 'User Last Name',
    COMPANY_ATTR_1: "Company's Attribute 1",
    COMPANY_ATTR_2: "Company's Attribute 2",
    COMPANY_ATTR_3: "Company's Attribute 3",
    TRIAL_STATUS: 'Trial Status',
};

import walkthroughIds from '../../walkthroughIds';

export default function Links({
    submitState,
    partnerBranding,
    setPartnerBranding,
    updatePartnerbranding,
}) {
    const dispatch = useDispatch();
    const [cursorPosition, setCursorPosition] = useState(0);

    // const [partnerBranding, setPartnerBranding] = useState({});

    const { branding } = walkthroughIds.partners;

    // const updatePartnerbranding = async () => {
    //     setSubmitted(false);
    //     try {
    //         const currentPartner = await axios.get(`/partner/${partner?.id}`);

    //         const updateRes = await axios.put('/partner/', {
    //             ...currentPartner.data,
    //             partnerBranding: {
    //                 ...currentPartner.data.partnerBranding,
    //                 extLinkSupport: partnerBranding.extLinkSupport,
    //                 extLinkRegistrationTerms:
    //                     partnerBranding.extLinkRegistrationTerms,
    //             },
    //         });
    //         if (partner?.id === defaultCompany) {
    //             dispatch({
    //                 type: 'SET_SUPPORT_URL',
    //                 payload: partnerBranding.extLinkSupport,
    //             });
    //         }
    //         setSubmitted(true);
    //     } catch (e) {}

    // };

    // const handleGetData = async () => {
    //     // If no host header has been set, use a random string (unknown in this case) to return the default branding set (TCAP)
    //     let newPartnerBranding = {
    //         ...partner?.partnerBranding,
    //     };
    //     setIsLoading(true);
    //     try {
    //         let defaultBrandingKit = {data: null};
    //         if (partner.hostHeader) {
    //             defaultBrandingKit = await axios
    //                 .get('/theme/' + partner.hostHeader)
    //                 .then((response) => {
    //                     return response.data;
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                 });
    //             newPartnerBranding = {
    //                 ...newPartnerBranding,
    //                 ...defaultBrandingKit.data,
    //             };
    //         }

    //         setPartnerBranding(newPartnerBranding);
    //     } catch (e) {
    //         console.log('error fetch', {e});
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     if (submitted) {
    //         toast({
    //             title: 'Links have been submitted successfully.',
    //             status: 'success',
    //             duration: 6000,
    //             isClosable: true,
    //             position: 'bottom',
    //         });
    //     }
    // }, [submitted]);

    // useEffect(() => {
    //     if (partner) {
    //         handleGetData();
    //     }
    // }, [partner]);

    return (
        <>
            <Stack spacing="25px" id="partner-branding-links">
                <TextEditor
                    id={'extLinkSupport'}
                    label={'Support Link'}
                    placeholder="Support Link"
                    setCursorPosition={(value) => {
                        setCursorPosition(value);
                    }}
                    textfield={{
                        value: partnerBranding.extLinkSupport ?? '',
                        label: 'Support Link',

                        autoFocus: true,
                        onChange: (e) => {
                            setPartnerBranding({
                                ...partnerBranding,
                                extLinkSupport: e.target.value,
                            });
                        },
                    }}
                    dataWalkthroughId={branding.ufSupportLinkInput}
                />
                <AutoFillMultiSelect
                    staticEnum={Object.entries(DEFAULT_SUPPORT_LINK_FIELDS).map(
                        ([id, name]) => ({
                            id: `[${id}]`,
                            name,
                        }),
                    )}
                    activeTextareaID={'extLinkSupport'}
                    activeTextareaField={'extLinkSupport'}
                    setState={setPartnerBranding}
                    label={'Support Link Fields'}
                    setCursorPosition={(value) => {
                        setCursorPosition(value);
                    }}
                    cursorPosition={cursorPosition ?? 0}
                    data-walkthroughid={branding.ufSupportLinkSelect}
                />
                <TextField
                    value={partnerBranding.extLinkRegistrationTerms}
                    multiline
                    id={'extLinkRegistrationTerms'}
                    label="Registration Terms and Conditions"
                    fullWidth
                    placeholder="Registration Terms and Conditions"
                    helperText={
                        'Example: https://www.example.com/terms-conditions'
                    }
                    onChange={(e) => {
                        setPartnerBranding({
                            ...partnerBranding,
                            extLinkRegistrationTerms: e.target.value,
                        });
                    }}
                    data-walkthroughid={branding.ufRegTcInput}
                />
                <TextField
                    value={partnerBranding.extLinkPrivacyPolicy}
                    multiline
                    id={'extLinkPrivacyPolicy'}
                    label="Registration Privacy Policy"
                    fullWidth
                    placeholder="Registration Privacy Policy"
                    helperText={
                        'Example: https://www.example.com/privacy-policy'
                    }
                    onChange={(e) => {
                        setPartnerBranding({
                            ...partnerBranding,
                            extLinkPrivacyPolicy: e.target.value,
                        });
                    }}
                    data-walkthroughid={branding.ufPrivacyPolicyInput}
                />
            </Stack>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxW: '140px',
                    margin: '0 auto',
                    marginTop: '10px',
                }}>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    _hover={{
                        bg: 'brand.50',
                        color: 'brand.600',
                    }}
                    onClick={() => dispatch({ type: 'RESET_MODAL' })}
                    data-walkthroughid={`${branding.ufBackButton}/links`}>
                    Back
                </Button>
                <Button
                    ml={4}
                    isLoading={submitState === 'pending'}
                    onClick={updatePartnerbranding}
                    data-walkthroughid={`${branding.ufSaveButton}/links`}>
                    Save
                </Button>
            </Box>
        </>
    );
}
