import SkeletonPlaceholder from 'components/v4/Cards/ServiceProfileCardPlaceholder';
import { useContext, useEffect, useRef, useState } from 'react';
import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardImage from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardImage';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    Image,
    Text,
    Tooltip,
} from '@chakra-ui/react';

import Grid from 'components/v4/Grid';

import { FilterContext } from './FilterContext';
import { Alert, AlertIcon } from 'components/v4';
import OcService from '../OcService';
import OccService from '../OccService';
import TpmService from '../TpmService';
import DrService from '../DrService';

// Service images
import msTeamsLogo from 'assets/images/ms_teams_logo.png';
import gcchighLogo from 'assets/images/gcchigh.png';
import sharedCallingLogo from 'assets/images/sharedCalling.png';
import drManualLogo from 'assets/images/drmanual.png';
import webexLogo from 'assets/images/webex.png';
import zoomLogo from 'assets/images/zoom.png';
import voiceLogo from 'assets/images/voice.png';
import calendarLogo from 'assets/images/calendar.png';
import dynamicsLogo from 'assets/images/dynamics.png';
import five9Logo from 'assets/images/five9.png';
import eightLogo from 'assets/images/8x8.png';
import genesysLogo from 'assets/images/genesys.png';
import anywhere365Logo from 'assets/images/anywhere365.png';
import aiLogo from 'assets/images/ai.png';
import recorderLogo from 'assets/images/recorder.png';
import backupLogo from 'assets/images/backup.png';
import insightsLogo from 'assets/images/insights.png';
import transcriptionLogo from 'assets/images/transcription.png';
import trainingLogo from 'assets/images/training.png';
import microsoftLogo from 'assets/images/microsoft.png';
import certifiedLogo from 'assets/images/certified.png';

import callLogo from 'assets/images/call.png';
import cloudLogo from 'assets/images/cloud.png';
import zoomCircleLogo from 'assets/images/zoom-circle.png';
import googleLogo from 'assets/images/google.png';

import lockLogo from 'assets/images/lock.png';
import compliantLogo from 'assets/images/compliant.png';
import businessLogo from 'assets/images/pie-chart.png';
import managementLogo from 'assets/images/bullseye.png';
import AcsService from '../AcsService';
import SbcService from '../SbcService';
import walkthroughIds from 'pages/services/walkthroughIds';
import { useWindowSize } from '@uidotdev/usehooks';
import DemoServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/DemoServiceProfileCard';

import ringCentralLogo from 'assets/images/ringcentralmigrate.png';
import netsapiensLogo from 'assets/images/netsapiensmigrate.png';
import googleMigrationLogo from 'assets/images/googlemigrate.png';
import maxucLogo from 'assets/images/maxucmigrate.png';
import webexMigrationLogo from 'assets/images/webexmigrate.png';
import ciscoCallManagerLogo from 'assets/images/ciscomigrate.png';
import zoomMigrationLogo from 'assets/images/zoommigrate.png';
import pbxLogo from 'assets/images/pbxmigrate.png';
import migrationLogo from 'assets/images/migration.png';

export default function DemoServiceProfiles({
    serviceProfiles,
    serviceWizard,
}) {
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;

    const { filterState, noFilters } = useContext(FilterContext);

    const size = useWindowSize();
    const refBox = useRef();
    const [columns, setColumns] = useState(3);

    useEffect(() => {
        if (size.width > 0) {
            // -500 is rough estimate of the sidebar and whitespace gaps
            const width = Math.floor((size.width - 500) / 220) - 1;
            setColumns(width <= 2 ? 3 : width);
        }
    }, [size]);

    if (serviceProfiles === null) {
        return (
            <SkeletonPlaceholder
                skeletons={13}
                rows={1}
                columns={columns ?? 3}
                gap={4}
                items={4}
            />
        );
    }

    if (!serviceProfiles?.length) {
        return (
            <Alert status="info">
                <AlertIcon />
                Your partner does not have any additional services which can be
                added.
            </Alert>
        );
    }

    // service profile types available
    const oc = serviceProfiles.find((s) => s.serviceType === 'OperatorConnect');
    const tpm = serviceProfiles.find(
        (s) => s.serviceType === 'TeamsPhoneMobile',
    );
    const dr = serviceProfiles.find((s) => s.serviceType === 'DirectRouting');
    const acs = serviceProfiles.find(
        (s) => s.serviceType === 'ACSDirectRouting',
    );
    const sbcaas = serviceProfiles.find((s) => s.serviceType === 'SBCaaS');
    const occ = serviceProfiles.find(
        (s) => s.serviceType === 'OperatorConnectConferencing',
    );

    return (
        <Box display={'flex'} flexGrow={1} flexDir={'column'} height={'100%'}>
            {serviceWizard.hasCreateServiceError &&
                serviceWizard.isCreatingService && (
                    <Alert
                        status="error"
                        sx={{
                            marginBottom: '10px',
                            whiteSpace: 'pre-line',
                        }}>
                        <AlertIcon />
                        {serviceWizard.hasCreateServiceError}
                    </Alert>
                )}
            <Box ref={refBox} style={{ height: '100%', overflowY: 'auto' }}>
                <Accordion
                    defaultIndex={[0, 1, 2, 3, 4]}
                    allowMultiple
                    allowToggle>
                    {(noFilters ||
                        filterState.microsoft ||
                        filterState.audio ||
                        filterState.fixed ||
                        filterState.mobile) && (
                        <AccordionItem borderTop={0}>
                            <AccordionButton>
                                <AccordionIcon mr={2} />
                                <Box as="span" flex="1" textAlign="left">
                                    Microsoft Teams Calling Services
                                </Box>
                            </AccordionButton>

                            <AccordionPanel>
                                <Grid
                                    columns={columns ?? 3}
                                    gap={8}
                                    minChildWidth="120px">
                                    {/* OC */}
                                    {oc &&
                                        (noFilters ||
                                            filterState.microsoft ||
                                            filterState.fixed) && (
                                            <OcService
                                                serviceProfile={oc}
                                                serviceWizard={serviceWizard}
                                                id="Operator Connect"
                                            />
                                        )}

                                    {/* OCC */}
                                    {occ &&
                                        (noFilters ||
                                            filterState.microsoft ||
                                            filterState.audio) && (
                                            <OccService
                                                serviceProfile={occ}
                                                serviceWizard={serviceWizard}
                                                id="Operator Connect Conferencing"
                                            />
                                        )}

                                    {/* TPM */}
                                    {tpm &&
                                        (noFilters ||
                                            filterState.microsoft ||
                                            filterState.mobile) && (
                                            <TpmService
                                                serviceWizard={serviceWizard}
                                                serviceProfile={tpm}
                                                id="Teams Phone Mobile"
                                            />
                                        )}

                                    {/* OCSC */}
                                    {(noFilters ||
                                        filterState.microsoft ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={sharedCallingLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Operator Connect Shared Calling`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'OCSC' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={sharedCallingLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Operator Connect Shared Calling
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Allow TCAP to automate the
                                                implementation of Microsoft
                                                Teams Shared Calling feature.
                                                Share a single number across
                                                multiple users for inbound and
                                                outbound calling.
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Microsoft">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={msTeamsLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* DIRECT ROUTING */}
                                    {dr &&
                                        (noFilters ||
                                            filterState.microsoft ||
                                            filterState.fixed) && (
                                            <DrService
                                                serviceProfile={dr}
                                                serviceWizard={serviceWizard}
                                                id="Direct Routing"
                                            />
                                        )}

                                    {/* GCC High */}
                                    {(noFilters ||
                                        filterState.microsoft ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={gcchighLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/GCC High`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'GCCHigh' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={gcchighLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                GCC High
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Authorize TCAP access to your
                                                GCC High tenancy and configure
                                                Direct Routing services.
                                                Requires a global administrator
                                                account to authorize the
                                                application.
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Microsoft">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={msTeamsLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* ACS */}
                                    {acs &&
                                        (noFilters ||
                                            filterState.microsoft ||
                                            filterState.fixed) && (
                                            <AcsService
                                                serviceWizard={serviceWizard}
                                                id="ACS Direct Routing"
                                            />
                                        )}

                                    {(noFilters ||
                                        filterState.microsoft ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={drManualLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Direct Routing (Manual)`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'DirectRoutingManual' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={drManualLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Direct Routing (Manual)
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Configure Direct Routing
                                                manually (no automation
                                                provided).
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Microsoft">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={msTeamsLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    )}

                    {(noFilters ||
                        filterState.fixed ||
                        filterState.other ||
                        filterState.cisco ||
                        filterState.zoom ||
                        filterState.google) && (
                        <AccordionItem borderTop={0}>
                            <AccordionButton>
                                <AccordionIcon mr={2} />
                                <Box as="span" flex="1" textAlign="left">
                                    Other Calling Services
                                </Box>
                            </AccordionButton>

                            <AccordionPanel>
                                <Grid
                                    columns={columns ?? 3}
                                    gap={8}
                                    minChildWidth="120px">
                                    {/* SIP */}
                                    {sbcaas &&
                                        (noFilters ||
                                            filterState.other ||
                                            filterState.fixed) && (
                                            <SbcService
                                                serviceProfile={sbcaas}
                                                serviceWizard={serviceWizard}
                                                id="SBC as a Service"
                                            />
                                        )}

                                    {/* Webex */}
                                    {(noFilters ||
                                        filterState.cisco ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={webexLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Cisco Webex Calling`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'CiscoWebexCalling' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={webexLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Cisco WebEx Calling
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Authorize TCAP to automate WebEx
                                                Calling configuration and
                                                management
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Cisco">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={webexLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Zoom Phone */}
                                    {(noFilters ||
                                        filterState.zoom ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={zoomLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Zoom Phone`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'ZoomPhone' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={zoomLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Zoom Phone
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Authorize TCAP to automate Zoom
                                                Phone configuration and
                                                management
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Zoom">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={zoomCircleLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Google Voice */}
                                    {(noFilters ||
                                        filterState.google ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={voiceLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Google Voice`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'GoogleVoice' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={voiceLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Google Voice
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Authorize TCAP to automate
                                                Google Voice configuration and
                                                management
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Google">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={googleLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    )}

                    {(noFilters || filterState.ccaas) && (
                        <AccordionItem borderTop={0}>
                            <AccordionButton>
                                <AccordionIcon mr={2} />
                                <Box as="span" flex="1" textAlign="left">
                                    Contact Center as a Service
                                </Box>
                            </AccordionButton>

                            <AccordionPanel>
                                <Grid
                                    columns={columns ?? 3}
                                    gap={8}
                                    minChildWidth="120px">
                                    {/* Dynamics */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={dynamicsLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Microsoft Dynamics`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'MicrosoftDynamics' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={dynamicsLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            Microsoft Dynamics
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Enable PSTN services to your
                                            Dynamics Contact Center.
                                        </Text>
                                        <HStack mt={1}>
                                            <Tooltip label="CCaaS Enablement">
                                                <Image
                                                    boxSize={'5'}
                                                    src={certifiedLogo}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </DemoServiceProfileCard>

                                    {/* Five9 */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={five9Logo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Five9`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'Five9' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={five9Logo}
                                        />
                                        <ServiceProfileCardTitle>
                                            Five9
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Enable PSTN services to your Five9
                                            Contact Center.
                                        </Text>
                                        <HStack mt={1}>
                                            <Tooltip label="CCaaS Enablement">
                                                <Image
                                                    boxSize={'5'}
                                                    src={certifiedLogo}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </DemoServiceProfileCard>

                                    {/* 8x8 */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={eightLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/8x8`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType === '8x8' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={eightLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            8x8
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Enable PSTN services to your 8x8
                                            Contact Center.
                                        </Text>
                                        <HStack mt={1}>
                                            <Tooltip label="CCaaS Enablement">
                                                <Image
                                                    boxSize={'5'}
                                                    src={certifiedLogo}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </DemoServiceProfileCard>

                                    {/* Genesys */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={genesysLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Genesys`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'Genesys' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={genesysLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            Genesys
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Enable PSTN services to your Genesys
                                            Contact Center.
                                        </Text>
                                        <HStack mt={1}>
                                            <Tooltip label="CCaaS Enablement">
                                                <Image
                                                    boxSize={'5'}
                                                    src={certifiedLogo}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </DemoServiceProfileCard>

                                    {/* Anywhere365 */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={anywhere365Logo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Anywhere365`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'Anywhere365' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={anywhere365Logo}
                                        />
                                        <ServiceProfileCardTitle>
                                            Anywhere365
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Enable PSTN services to your
                                            Anywhere365 Contact Center.
                                        </Text>
                                        <HStack mt={1}>
                                            <Tooltip label="CCaaS Enablement">
                                                <Image
                                                    boxSize={'5'}
                                                    src={certifiedLogo}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </DemoServiceProfileCard>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    )}

                    {(noFilters ||
                        filterState.fixed ||
                        filterState.microsoft ||
                        filterState.security ||
                        filterState.compliance ||
                        filterState.business ||
                        filterState.management ||
                        filterState.other) && (
                        <AccordionItem borderY={0}>
                            <AccordionButton>
                                <AccordionIcon mr={2} />
                                <Box as="span" flex="1" textAlign="left">
                                    Add-On Services
                                </Box>
                            </AccordionButton>

                            <AccordionPanel>
                                <Grid
                                    columns={columns ?? 3}
                                    gap={8}
                                    minChildWidth="120px">
                                    {/* AI Threat Intelligence */}
                                    {(noFilters ||
                                        filterState.fixed ||
                                        filterState.security) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={aiLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/AI Threat Intelligence`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'AIThreatIntelligence' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={aiLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                AI Threat Intelligence
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Protect your organization and
                                                end users from social
                                                engineering attempts via TCAP's
                                                AI threat protection engine.
                                            </Text>
                                            <HStack mt={1}>
                                                <Tooltip label="Security">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={lockLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Call Recording */}
                                    {(noFilters ||
                                        filterState.compliance ||
                                        filterState.security ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={recorderLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Call Recording`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'CallRecording' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={recorderLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Call Recording
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Ensure compliance across
                                                multiple industries by enabling
                                                Call Recording Services on your
                                                UCaaS platform.
                                            </Text>
                                            <HStack mt={1}>
                                                <Tooltip label="Compliance">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={compliantLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Security">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={lockLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Teams Backup */}
                                    {(noFilters ||
                                        filterState.microsoft ||
                                        filterState.security ||
                                        filterState.management ||
                                        filterState.business) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={backupLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Teams Backup as a Service`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'TeamsBackupAsAService' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={backupLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Teams Backup as a Service
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Enable Teams Change Management
                                                automation via backup, history
                                                and notifications of Teams
                                                calling configurations.
                                            </Text>
                                            <HStack mt={1}>
                                                <Tooltip label="Microsoft">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={msTeamsLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Security">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={lockLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Change Management">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={managementLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Business Intelligence">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={businessLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Insights */}
                                    {(noFilters || filterState.business) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={insightsLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Customer Insights`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'CustomerInsights' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={insightsLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Customer Insights
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Maximise RoI with in depth
                                                knowledge of UCaaS service
                                                adoption, technologies and usage
                                                patterns.
                                            </Text>
                                            <HStack mt={1}>
                                                <Tooltip label="Business Intelligence">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={businessLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Transcription */}
                                    {(noFilters ||
                                        filterState.compliance ||
                                        filterState.fixed) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={insightsLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/PSTN Transcription`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'PSTNTranscription' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={transcriptionLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                PSTN Transcription
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Enhance your eDiscovery and
                                                retention strategies via
                                                automated transcription services
                                                to any PSTN calls.
                                            </Text>
                                            <HStack mt={1}>
                                                <Tooltip label="Compliance">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={compliantLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Fixed Line Calling">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={callLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Training */}
                                    {(noFilters || filterState.management) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={trainingLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Training & Adoption`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'TrainingAndAdoption' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={trainingLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Training & Adoption
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Maximise UCaaS RoI and adoption
                                                by ensuring your users are
                                                successfully deployed, enabled
                                                and trained.
                                            </Text>
                                            <HStack mt={1}>
                                                <Tooltip label="Change Management">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={managementLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Kade */}
                                    {(noFilters || filterState.other) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={calendarLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Kade`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'Kade' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={calendarLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Kade
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Track and manage your TCAP
                                                Number/Porting requests
                                            </Text>
                                            <HStack mt={'auto'}>
                                                <Tooltip label="Other">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={cloudLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Licensing */}
                                    {(noFilters ||
                                        filterState.microsoft ||
                                        filterState.management) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={microsoftLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Microsoft Licensing`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'MicrosoftLicensing' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={microsoftLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Microsoft Licensing
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Manage your Microsoft 365
                                                subscriptions via TCAP. Requires
                                                Microsoft reseller agreement.
                                            </Text>
                                            <HStack mt={6}>
                                                <Tooltip label="Microsoft">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={msTeamsLogo}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Change Management">
                                                    <Image
                                                        boxSize={'5'}
                                                        src={managementLogo}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        </DemoServiceProfileCard>
                                    )}

                                    {/* Certified */}
                                    {(noFilters || filterState.management) && (
                                        <DemoServiceProfileCard
                                            pointerEvents={
                                                serviceWizard.isCreatingService
                                                    ? 'none'
                                                    : 'auto'
                                            }
                                            logo={certifiedLogo}
                                            data-walkthroughid={`${ufNewServiceProfileCard}/Certified Devices`}>
                                            {serviceWizard.isCreatingService && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        left: '0',
                                                        top: '0',
                                                        background: 'white',
                                                        opacity: '0.7',
                                                    }}>
                                                    {serviceWizard
                                                        .selectedServiceProfile
                                                        .serviceType ===
                                                        'CertifiedDevices' && (
                                                        <Spinner
                                                            thickness="4px"
                                                            emptyColor="gray.200"
                                                            color="brand.500"
                                                            size="xl"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ServiceProfileCardImage
                                                src={certifiedLogo}
                                            />
                                            <ServiceProfileCardTitle>
                                                Certified Devices
                                            </ServiceProfileCardTitle>
                                            <Text>
                                                Browse, order and manage
                                                certified devices
                                            </Text>
                                            <Tooltip label="Change Management">
                                                <Image
                                                    marginTop={'45px'}
                                                    boxSize={'5'}
                                                    src={managementLogo}
                                                />
                                            </Tooltip>
                                        </DemoServiceProfileCard>
                                    )}
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    )}

                    {(noFilters || filterState.migration) && (
                        <AccordionItem borderY={0}>
                            <AccordionButton>
                                <AccordionIcon mr={2} />
                                <Box as="span" flex="1" textAlign="left">
                                    Migration Services
                                </Box>
                            </AccordionButton>

                            <AccordionPanel>
                                <Grid
                                    columns={columns ?? 3}
                                    gap={8}
                                    minChildWidth="120px">
                                    {/* On-Prem PBX */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={pbxLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/On-Prem PBX`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType === 'pbx' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={pbxLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From On-Prem PBX
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from On-Prem PBX
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'45px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* Ring Central */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={ringCentralLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/RingCentral`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'RingCentral' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={ringCentralLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From RingCentral
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from RingCentral
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                marginTop={'45px'}
                                                boxSize={'5'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* Cisco CallManager */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={ciscoCallManagerLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Call Manager (Cisco)`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'callmanager' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={ciscoCallManagerLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From Call Manager (Cisco)
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from Call Manager (Cisco)
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'24px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* Netsapiens */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={netsapiensLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Netsapiens`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'Netsapiens' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={netsapiensLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From Netsapiens
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from Netsapiens
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'45px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* Webex Migration */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={webexMigrationLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Webex`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'webexmigration' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={webexMigrationLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From Webex
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from Webex
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'45px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* Zoom */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={zoomMigrationLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Zoom`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'zoommigration' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={zoomMigrationLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From Zoom
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from Zoom
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'45px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* Google */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={googleMigrationLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/Google`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'Google' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={googleMigrationLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From Google
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from Google
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'45px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>

                                    {/* MaX UC */}
                                    <DemoServiceProfileCard
                                        pointerEvents={
                                            serviceWizard.isCreatingService
                                                ? 'none'
                                                : 'auto'
                                        }
                                        logo={maxucLogo}
                                        data-walkthroughid={`${ufNewServiceProfileCard}/MaX UC`}>
                                        {serviceWizard.isCreatingService && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    height: '100%',
                                                    width: '100%',
                                                    left: '0',
                                                    top: '0',
                                                    background: 'white',
                                                    opacity: '0.7',
                                                }}>
                                                {serviceWizard
                                                    .selectedServiceProfile
                                                    .serviceType ===
                                                    'maxuc' && (
                                                    <Spinner
                                                        thickness="4px"
                                                        emptyColor="gray.200"
                                                        color="brand.500"
                                                        size="xl"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <ServiceProfileCardImage
                                            src={maxucLogo}
                                        />
                                        <ServiceProfileCardTitle>
                                            From MaX UC
                                        </ServiceProfileCardTitle>
                                        <Text>
                                            Migration tools built to facilitate
                                            migrations from MaX UC
                                        </Text>
                                        <Tooltip label="Migration">
                                            <Image
                                                boxSize={'5'}
                                                marginTop={'45px'}
                                                src={migrationLogo}
                                            />
                                        </Tooltip>
                                    </DemoServiceProfileCard>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    )}
                </Accordion>
            </Box>
        </Box>
    );
}
