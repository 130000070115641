import { axios } from '../../services/axios';

export default async function getService(serviceId) {
    return axios
        .get(`/Service/${serviceId}`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
        .then((res) => res)
        .catch((e) => e);
}
