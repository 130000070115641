import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';

export const TableTitleAdd = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const handleNavigate = () => {
        navigate(`${pathname}/add`);
    };

    return (
        <>
            <h1>{title}</h1>
            <Button
                className="!mb-[5%] !bg-blue-600 py-1 px-2 "
                variant="contained"
                onClick={handleNavigate}
                startIcon={<MdAdd />}>
                {title}
            </Button>
        </>
    );
};
