import { isEmpty } from 'lodash';
import { useServices } from '../ServicesContext';

export default function useServicesAttributesValidation() {
    const { dispatch } = useServices();

    const handleAttributeError = (index, message, serviceIndex) => {
        dispatch({
            type: 'ADD_ATT_ERROR',
            index: index,
            serviceIndex: serviceIndex,
            payload: {
                id: index,
                serviceIndex: serviceIndex,
                isError: true,
                message: message,
            },
        });
    };

    const removeAttributeError = (index, serviceIndex) => {
        dispatch({
            type: 'UPDATE_ATT_ERROR',
            index: index,
            serviceIndex: serviceIndex,
        });
    };

    // update services data attribute value on change input
    const handleAttributeChange = (value, attIndex, serviceIndex) => {
        dispatch({
            type: 'UPDATE_ATT_VALUE',
            serviceIndex: serviceIndex,
            attIndex: attIndex,
            // value is empty string, set to null
            payload: isEmpty(value) ? null : value,
        });
    };

    // update original data attribute value upon save successful
    const handleOGAttributeChange = (value, attIndex, serviceIndex) => {
        dispatch({
            type: 'UPDATE_OG_ATT_VALUE',
            serviceIndex: serviceIndex,
            attIndex: attIndex,
            // value is empty string, set to null
            payload: isEmpty(value) ? null : value,
        });
    };

    return {
        handleAttributeError,
        removeAttributeError,
        handleAttributeChange,
        handleOGAttributeChange
    };
}
