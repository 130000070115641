import React from 'react';
import {
    Start,
    RegisterMainCQ,
    RegisterAHCQ,
    RegisterMainAA,
    CheckLicense,
    ActiveUsers,
    AssignLicense,
    AssignNumbers,
    CheckNumbers,
    NavigateMainCQ,
    MainCQAgentAlert,
    MainCQCallTimeout,
    MainCQCallGroup,
    MAHCQName,
    MAHCQAddAcc,
    MAHCQCallGroup,
    MAHCQCallTimeout,
    NavigateAA,
    AAGeneral,
    AACF,
    AABH,
    AAAH,
    AARA,
    AASubmit,
    MainCQ,
    MainCQAdd,
} from '../../../../assets/images/Instructions_AA_CQ/index';

// Guide to setting up a Auto Attendant and Call Queue
export default function AutoAttendantGuide() {
    return (
        <>
            <h3> 1. Add Auto Attendant and Call Queue Accounts </h3>
            <ol>
                <li>
                    Navigate to admin.teams.microsoft.com &gt; Org-wide Settings
                    &gt; Resource accounts &gt; Add <br />
                    <img alt="" src={Start} width="60%" style={{padding: 10}} />
                </li>
                <li>
                    Add 3 accounts : Main CQ (Call Queue), Main After Hours CQ,
                    and Main AA (Auto Attendant) with their respective email and
                    type. <br />
                    <img
                        alt=""
                        src={RegisterMainAA}
                        width="30%"
                        style={{padding: 10}}
                    />
                    <img
                        alt=""
                        src={RegisterMainCQ}
                        width="30%"
                        style={{padding: 10}}
                    />
                    <img
                        alt=""
                        src={RegisterAHCQ}
                        width="30%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
            <h3> 2. Set Auto Attendant License &amp; Number </h3>
            <ol>
                <li>
                    Navigate to Billing &gt; Licenses . Make sure you have the
                    license highlighted below: <br />
                    <img
                        alt=""
                        src={CheckLicense}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Navigate to Users &gt; Active Users . Click your
                    <b> Main AA </b> Account. <br /> When the pop-up window pops
                    up, switch to <b> Licenses and Apps</b> tab, activate the
                    <b>
                        Microsoft 365 Phone System - Virtual User for students
                    </b>
                    license, then click <b> Save changes </b> . <br />
                    <img
                        alt=""
                        src={ActiveUsers}
                        width="60%"
                        style={{padding: 10}}
                    />
                    <img
                        alt=""
                        src={AssignLicense}
                        width="30%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    On the TCAP Portal, assign a number to your <b>Main AA</b>
                    Account. <br />
                    <img
                        alt=""
                        src={AssignNumbers}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Then, on Microsoft portal (Org-wide Settings &gt; Resource
                    accounts), make sure that the number has been assigned to
                    your your <b> Main AA </b> Account. <br />
                    <img
                        alt=""
                        src={CheckNumbers}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
            <h3> 3. Set Main Call Queue (Main CQ) </h3>
            <ol>
                <li>
                    Navigate to Voice &gt; Call queues &gt; Add <br />
                    <img
                        alt=""
                        src={NavigateMainCQ}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Set Name: 'Main CQ' . Then, click on 'Add accounts'. Add
                    your Main CQ resource accounts.
                    <img
                        alt=""
                        src={MainCQ}
                        width="80%"
                        style={{padding: 10}}
                    />
                    <img
                        alt=""
                        src={MainCQAdd}
                        width="40%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Under <b>Call answering</b> section:
                    <ul>
                        <li>
                            Click on <b> Add groups </b>, then add your desired
                            group for Main CQ. <br />
                            <img
                                alt=""
                                src={MainCQCallGroup}
                                width="60%"
                                style={{padding: 10}}
                            />
                        </li>
                        <li>
                            Set <b> Agent alert time </b> to <b>15 seconds</b>.
                            <br />
                            <img
                                alt=""
                                src={MainCQAgentAlert}
                                width="40%"
                                style={{padding: 10}}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    Under <b>Call overflow handling</b> section, set
                    <b> Maximum calls in the queue </b> to <b> 50 </b>. <br />
                    Under <b>Call time out handling </b> section, set
                    <b> Maximum wait time </b> to <b> 20 minutes </b>. <br />
                    Then, click <b> Save </b>. <br />
                    <img
                        alt=""
                        src={MAHCQCallTimeout}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
            <h3> 4. Set Main After Hours Call Queue (Main After Hours CQ) </h3>
            <ol>
                <li>
                    Navigate to Voice &gt; Call queues &gt; Add <br />
                    <img
                        alt=""
                        src={NavigateMainCQ}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Set Name: 'Main After Hours CQ' . Then, click on 'Add
                    accounts'. Add your Main After Hours CQ resource accounts.
                    <img
                        alt=""
                        src={MAHCQName}
                        width="80%"
                        style={{padding: 10}}
                    />
                    <img
                        alt=""
                        src={MAHCQAddAcc}
                        width="40%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Under <b>Call answering</b> section:
                    <ul>
                        <li>
                            Click on <b> Add groups </b>, then add your desired
                            group for Main After Hours CQ. <br />
                            <img
                                alt=""
                                src={MAHCQCallGroup}
                                width="60%"
                                style={{padding: 10}}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    Under <b>Call overflow handling</b> section, set
                    <b> Maximum calls in the queue </b> to <b> 50 </b>. <br />
                    Under <b>Call time out handling </b> section, set
                    <b> Maximum wait time </b> to <b> 20 minutes </b>. <br />
                    Then, click <b> Save </b>. <br />
                    <img
                        alt=""
                        src={MAHCQCallTimeout}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
            <h3> 5. Adjust Auto Attendant Settings </h3>
            <ol>
                <li>
                    Navigate to Voice &gt; Auto attendants &gt; Add <br />
                    <img
                        alt=""
                        src={NavigateAA}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
                <li>
                    Under <b>General info</b> section,
                    <ul>
                        <li>Set Name : 'Main AA'</li>
                        <li>Set appropriate Time zone and language,</li>
                    </ul>
                    <br />
                    <img
                        alt=""
                        src={AAGeneral}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>

                <li>
                    Under <b>Call flow </b> section,
                    <ol>
                        <li>
                            Select <b>Redirect call</b>,
                        </li>
                        <li>
                            Redirect to your <b> Main CQ </b> account
                        </li>
                    </ol>
                    <br />
                    <img alt="" src={AACF} width="60%" style={{padding: 10}} />
                </li>
                <li>
                    Set your operational business hours
                    <br />
                    <img alt="" src={AABH} width="60%" style={{padding: 10}} />
                </li>

                <li>
                    Under <b> Set up after hours call flow</b> section,
                    <ul>
                        <li> Set your greeting message </li>
                        <li>
                            Redirect to your <b> Main After Hours CQ </b>
                            account
                        </li>
                    </ul>
                    <br />
                    <img alt="" src={AAAH} width="60%" style={{padding: 10}} />
                </li>
                <li>
                    Under <b> Resource acounts </b> section,
                    <ol>
                        <li>
                            Click on <b>Add Accounts </b>
                        </li>
                        <li>Add your main AA account.</li>
                    </ol>
                    <br />
                    <img alt="" src={AARA} width="60%" style={{padding: 10}} />
                </li>
                <li>
                    Click <b> Submit </b> <br />
                    <img
                        alt=""
                        src={AASubmit}
                        width="60%"
                        style={{padding: 10}}
                    />
                </li>
            </ol>
        </>
    );
}
