import { useTheme } from '@chakra-ui/react';

export function useChakraTheme() {
    const theme = useTheme();
    const colors = Object.keys(theme.colors);
    const filterColors = colors.filter(
        (name) =>
            ![
                'transparent',
                'current',
                'black',
                'white',
                'blackAlpha',
                'whiteAlpha',
                'light',
                'dark',
                'brand',
                'red',
            ].includes(name), // NOTE: those have no color palette or reserve colours
    );
    const colorList = [...filterColors.slice(15), ...filterColors.slice(0, 15)];

    return { colorList, theme, colors };
}
