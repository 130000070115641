import { Box, HStack, PinInput, PinInputField } from '@chakra-ui/react';

import { classNames } from 'utils/classNames';
import walkthroughIds from './walkthroughIds';

export default function MfaChakraCodeBox({ onComplete, otp, onChange }) {
    const { ufMfaCode } = walkthroughIds.teamsPhoneMobileTrial;

    return (
        <Box className={classNames('px-8')}>
            <HStack
                className="mx-auto my-0 h-[104px] max-w-[400px] justify-between"
                data-walkthroughid={ufMfaCode}>
                <PinInput
                    otp
                    value={otp}
                    onChange={onChange}
                    onComplete={onComplete}
                    autoFocus
                    placeholder="">
                    {[...Array(6)].map((_filler, index) => (
                        <PinInputField
                            key={`${index + 1}`}
                            borderColor="gray.400"
                            w="50px"
                            h="60px"
                            fontSize="30px"
                            _focus={{
                                boxShadow:
                                    'inset 0 0 0 0.125rem #1040c1, 0 0 0 0.375rem rgb(16 114 235 / 16%)',
                            }}
                        />
                    ))}
                </PinInput>
            </HStack>
        </Box>
    );
}
