import React, { useState, useRef } from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { Box, Text } from '@chakra-ui/react';

function PasswordFieldValidationIcons({ hasError }) {
    return hasError ? (
        <CancelRoundedIcon
            fontSize="small"
            style={{
                fill: 'red',
            }}
        />
    ) : (
        <CheckCircleRoundedIcon
            fontSize="small"
            style={{
                fill: 'green',
            }}
        />
    );
}

function ValidationLine({ children }) {
    return <Text sx={{ marginBottom: '3px' }} className='!text-gray-700'>{children}</Text>;
}

function PasswordFieldValidations({ errors }) {
    const _errors = errors.toString();

    const hasLessThan = _errors.includes('<');
    const hasGreaterThan = _errors.includes('>');
    const isTooShort = _errors.includes('You need atleast 8 characters.');
    const hasOneSpecialChar = _errors.includes(
        'You need atleast one special character.',
    );
    const hasNoUppercase = _errors.includes(
        'You need atleast one uppercase character.',
    );
    const hasNoLowercase = _errors.includes(
        'You need atleast one lowercase character.',
    );
    const hasNoNumeric = _errors.includes(
        'You need atleast one numeric value.',
    );

    return (
        <>
            <ValidationLine>
                <PasswordFieldValidationIcons hasError={hasNoUppercase} /> You
                need atleast one uppercase character.
            </ValidationLine>
            <ValidationLine>
                <PasswordFieldValidationIcons hasError={hasNoLowercase} /> You
                need atleast one lowercase character.
            </ValidationLine>
            <ValidationLine>
                <PasswordFieldValidationIcons hasError={hasNoNumeric} /> Has
                atleast 1 number
            </ValidationLine>

            <ValidationLine>
                <PasswordFieldValidationIcons hasError={hasOneSpecialChar} />{' '}
                Has atleast 1 special character
            </ValidationLine>

            <ValidationLine>
                <PasswordFieldValidationIcons hasError={hasLessThan} /> Does not
                contain {'<'}
            </ValidationLine>
            <ValidationLine>
                <PasswordFieldValidationIcons hasError={hasGreaterThan} /> Does
                not contain {'>'}
            </ValidationLine>
            <ValidationLine>
                <PasswordFieldValidationIcons hasError={isTooShort} /> Has
                atleast 8 characters
            </ValidationLine>
        </>
    );
}

export function PasswordFieldValidation({ errors, show }) {
    if (!errors) return null;
    if (!show) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDir: 'column',
                background: 'white',
                zIndex: 1300,
                position: 'absolute',
                top: '-60px',
                right: '-320px',
                borderColor: 'gray.300',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '5px',
            }}>
            <Text
                className="!text-gray-700"
                sx={{
                    fontWeight: 'bold',
                    color: 'gray.700',
                    fontSize: '20px',
                    padding: '10px ',
                }}>
                Requirements
            </Text>
            <Box sx={{ padding: '0 10px 10px 10px' }}>
                <PasswordFieldValidations errors={errors} />
            </Box>
        </Box>
    );
}

export default function useTempPasswordChecker(value) {
    if (!value) return;

    let errors = [];
    if (value.includes('<')) errors.push('No < allowed.');
    if (value.includes('>')) errors.push('No > allowed.');
    if (value.match(/^[A-Za-z0-9<>]+$/)) {
        errors.push('You need atleast one special character.');
    }
    if (value.length < 8) errors.push('You need atleast 8 characters.');
    if (!value.match(/[A-Z]/))
        errors.push('You need atleast one uppercase character.');
    if (!value.match(/[a-z]/))
        errors.push('You need atleast one lowercase character.');
    if (!value.match(/[0-9]/))
        errors.push('You need atleast one numeric value.');
    if (errors.length) {
        return errors;
    }
}
