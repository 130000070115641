import { useRef } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Edit } from '@material-ui/icons';
import { Box } from '@chakra-ui/react';

import { TableAndModal } from '../../../../components';
import config from '../../../../config.json';
import { renderTelephoneNumber } from '../../../../utils/utils';
import { axios } from 'services/axios';
import walkthroughIds from 'pages/telephony/numbers/walkthroughIds';
import RequestBlockForm from './RequestBlockForm';
import { Alert, AlertIcon } from 'components/v4';
import { toast } from 'react-toastify';

/*
 * Availible Number blocks tab of the Request new Block form of Numbers Sidebar Item
 */
const AvailableBlock = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const { data: modalData, tab } = useSelector((state) => {
        return {
            ...state.modal,
            ...state.view,
        };
    });

    const { currentCompany, currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const { darkMode } = useSelector((state) => state.settings);

    const MuiFormStyles = {
        '& div.MuiSelect-root, & div.MuiInputBase-root': {
            background: darkMode
                ? 'var(--chakra-colors-dark-bgDark)'
                : '#FFFFFF',
        },
    };
    const { requestAvailableBlock } = walkthroughIds;

    // default columns for the availible number blocks tab
    const defaultColumns = [
        {
            title: 'First Number',
            field: 'first',
            defaultSort: 'asc',
            render: (rowData) => renderTelephoneNumber(rowData, 'first'),
        },

        {
            title: 'Block Size',
            field: 'blockSize',
        },
    ];

    // return the table and modal with the right endpoints and modal Content
    return (
        <>
            {tab['level2'] === 0 ? (
                <TableAndModal
                    tableRef={tableRef}
                    filterable
                    tableFetch={`company/${currentCompany}/numberblocks/available`}
                    // modalUpdate={'/numberblock/'}
                    onModalUpdate={async () => {
                        return await axios
                            .post(
                                `/numberblock/requestexisting/${currentCompany}/${modalData?.id}`,
                            )
                            .then(() => {
                                toast.success('Requesting Block Sent');
                            })
                            .catch((e) => {
                                toast.error(
                                    e?.message || 'Error Requesting Block',
                                );
                            });
                    }}
                    modalUpload={(freshData) => {
                        return {
                            ...freshData,
                            companyID: currentCompany,
                        };
                    }}
                    noColumnsButton={true}
                    modalApproval={'/numberblock/'}
                    title={'Available Number Blocks'}
                    titleProps={
                        <Button
                            data-walkthroughid={walkthroughIds.ufCsvUpdateBtn}
                            className="btn btn-sm"
                            variant="primary"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                dispatch({
                                    type: 'CHANGE_TAB_VIEW',
                                    payload: {
                                        level1: 1,
                                        level2: 1,
                                    },
                                });
                            }}>
                            {'Request New Block'}
                        </Button>
                    }
                    defaultColumns={defaultColumns}
                    maxBodyHeight={
                        window.innerHeight * config.tabView.tableHeight
                    }
                    modalTitle={'Requesting Available Block'}
                    noTitle
                    additionalActions={[
                        (rowData) => ({
                            icon: () => (
                                <Edit
                                    data-walkthroughid={
                                        rowData.tableData.id === 0
                                            ? requestAvailableBlock.addButton
                                            : null
                                    }
                                    fontSize="small"
                                />
                            ),
                            isFreeAction: false,
                            tooltip: 'Requesting Available Block',
                            onClick: (_e, rowData) => {
                                dispatch({
                                    type: 'OPEN_MODAL',
                                    payload: {
                                        mode: 'Edit',
                                        data: rowData,
                                        loading: false,
                                    },
                                });
                            },
                        }),
                    ]}
                    modalButtons={
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!currentCompanyData?.approved}
                            walkthroughId={requestAvailableBlock.requestBlock}>
                            Submit
                        </Button>
                    }
                    modalContent={
                        <>
                            <Row style={{ minHeight: 60 }}>
                                <Col>
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        placeholder="First Number"
                                        size="small"
                                        label="First Number"
                                        value={modalData?.first}
                                        onChange={() => {}}
                                        disabled
                                        data-walkthroughid={
                                            requestAvailableBlock.firstNumber
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ minHeight: 60 }}>
                                <Col>
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        placeholder="Block Size"
                                        size="small"
                                        label="Block Size"
                                        value={modalData?.blockSize}
                                        onChange={() => {}}
                                        disabled
                                        data-walkthroughid={
                                            requestAvailableBlock.blockSize
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ minHeight: 60 }}>
                                <Col>
                                    <Alert status="info">
                                        <AlertIcon />
                                        This constitutes an order and the block
                                        will be added to your account. Charges
                                        based on your plan will apply.
                                    </Alert>
                                </Col>
                            </Row>
                        </>
                    }
                />
            ) : (
                <Box w="896px" mx="auto" sx={MuiFormStyles}>
                    <RequestBlockForm />
                </Box>
            )}
        </>
    );
};

export default AvailableBlock;
