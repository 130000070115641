import { Box, FormLabel, Input } from '@chakra-ui/react';
import Button from '../../../../components/v4/Button';
// import Input from '../../../../components/v4/Input';
// import FormLabel from '../../../../components/v4/FormLabel';
import { Link } from 'react-router-dom';
import useDetectFileType from '../../../../hooks/useDetectFileType';
import Image from '../../../../components/v4/Image';
import { useEffect, useState } from 'react';

import './fakelogin.css';

import ImageLoader from '../../../../components/v4/ImageLoader';

export default function FakeLogin({ logo, favicon, email, dataWalkthroughId }) {
    return (
        <Box
            className="fakelogin"
            style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                maxWidth: '400px',
                width: '100%',
                padding: '20px',
                margin: '50px auto',
                borderRadius: '10px',
            }}
            data-walkthroughid={dataWalkthroughId}>
            <Box sx={{ height: '80px' }}>
                <ImageLoader
                    image={logo}
                    objectProps={{
                        alt: 'Domain Logo',
                        style: {
                            display: 'block',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: '0 auto',
                        },
                        type: 'image/svg+xml',
                    }}
                    imageProps={{
                        sx: {
                            display: 'block',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: '0 auto',
                        },
                    }}
                />
            </Box>

            <Box sx={{ margin: '0 0 20px 0' }}></Box>

            <FormLabel
                sx={{ margin: 0, color: 'gray.700' }}
                for="login-username">
                EMAIL
            </FormLabel>
            <Input
                value={email}
                placeholder="username@email.com"
                variant="flushed"
                borderColor="gray"
                size="sm"
                id="login-username"
                color="gray.700"
            />

            <Box sx={{ margin: '20px 0' }}></Box>

            <FormLabel
                sx={{ margin: 0, color: 'gray.700' }}
                for="login-password">
                PASSWORD
            </FormLabel>
            <Input
                value="1222222222"
                borderColor="gray"
                variant="flushed"
                size="sm"
                type={'password'}
                id="login-password"
                color="gray.700"
            />

            <Box sx={{ margin: '20px 0' }}></Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '20px 0',
                }}>
                <Button type="submit">LOGIN</Button>
                <Link to="#" style={{ textDecoration: 'none' }}>
                    <Button
                        _hover={{ textDecoration: 'none' }}
                        variant="outline">
                        REGISTER
                    </Button>
                </Link>
            </Box>

            <Link to="#" style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="link">Forgot my password</Button>
            </Link>
        </Box>
    );
}
