//external imports
import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import TableAndModal, {
    modalTitle,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';
import walkthroughIds from './walkthroughIds';

/*
 * Voice Policies Component in the Telephony tab of TCAP
 * This handles CRUD for this aspect of the app
 */
const VoicePolicies = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    //default table fields for voice policies
    const defaultColumns = [
        {
            title: 'Voice Policy',
            field: 'name',
        },
        {
            title: 'Pattern',
            field: 'pattern',
        },
    ];

    // change handlers
    const patternChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                pattern: e.target.value,
            },
        });
    };

    const voiceChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    // returns table and modal for this component
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/voicepolicies/'}
            modalFetch={'/voicepolicy/' + modalData?.id}
            modalAdd="/voicepolicy/"
            modalUpdate="/voicepolicy/"
            modalSize={'md'}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Voice Policies"
            defaultColumns={defaultColumns}
            add
            groupable
            rowActions={{
                editable: true,
            }}
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(
                modalData,
                'name',
                ' Voice Policy ',
                modalMode,
            )}
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    pattern:
                        (!modalState?.pattern ||
                            modalState?.pattern?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(newErrorState.name || newErrorState.pattern);
            }}
            modalContent={
                <>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Voice Policy"
                                label="Voice Policy"
                                type="text"
                                value={modalState?.name}
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                onChange={voiceChangeHandler}
                                data-walkthroughid={
                                    walkthroughIds.ufVoicePolicy
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Pattern"
                                label="Pattern"
                                type="text"
                                value={modalState?.pattern}
                                error={Boolean(modalStateError?.pattern)}
                                helperText={modalStateError?.pattern}
                                onChange={patternChangeHandler}
                                data-walkthroughid={walkthroughIds.ufPattern}
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default VoicePolicies;
