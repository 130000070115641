export const serviceTypeRender = (rowData) => {
    switch (rowData.serviceType) {
        case 'ACSDirectRouting':
            return 'ACS Direct Routing';

        case 'TeamsPhoneMobile':
            return 'Teams Phone Mobile';

        case 'OperatorConnect':
            return 'Operator Connect';

        case 'DirectRouting':
            return 'Direct Routing';

        case 'SBCaaS':
            return 'SBCaaS';

        case 'OperatorConnectConferencing':
            return 'Operator Connect Conferencing';

        case 'VendorDefined':
            return 'Vendor Defined';

        default:
            return null;
    }
};
