import React, { useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';

import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import { useSelector, useDispatch } from 'react-redux';
import Searchbox from '../../../components/presentational/MultiselectSearchbox';

import { useQuery } from '@tanstack/react-query';
import { Select as MultiSelect } from 'chakra-react-select';
import { ChakraProvider, FormControl, Box } from '@chakra-ui/react';

import config from '../../../config.json';
import { OrderedLists } from '../../../components';
import { axios } from '@/services/axios';
import chakratheme, {
    floatingLabelStyles as floatingLabel,
} from '../../../chakratheme';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { FormLabel, Input } from 'components/v4';
import { multiSelectDataMapper } from 'utils/utils';

/*
 * Regions Tab of the Regions and Locations page rendered in TCAP
 * Performs CRUD for the regions aspect of the page
 */
const Regions = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );

    // default columsn for regions table
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Locations',
            field: 'locations',
        },
        {
            title: 'Voice Policies',
            field: 'voicePoliciesDisplay',
        },
    ];
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    const floatingLabelStyles = floatingLabel(darkMode);

    const [voicePolicies, setVoicePolicies] = useState([]);

    const { data: regionsData } = useQuery({
        queryKey: ['regionsData', companyID],
        queryFn: async () => {
            const result = await axios.get(`/VoicePolicies/`);

            return result.data;
        },
        onSuccess: (data) => {
            setVoicePolicies(data.map(multiSelectDataMapper));
        },
        // enabled: data.regions && data.regions.length > 0,
        refetchOnWindowFocus: false,
    });

    // change handlers for edit/add form
    const nameChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    const voiceSetState = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                voicePolicies: v,
            },
        });
    };

    const listSetState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    // render table and modal for regions
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/regions/'}
            modalFetch={'/region/' + modalData?.id}
            modalAdd="/region/"
            size={'md'}
            modalUpdate="/region/"
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            editable={{
                add: true,
            }}
            title="Regions"
            noTitle
            defaultColumns={defaultColumns}
            add
            groupable
            rowActions={{
                editable: true,
            }}
            onModalSucceed={
                modalMode === 'Add' &&
                function (result) {
                    dispatch({
                        type: 'OPEN_MODAL',
                        payload: {
                            mode: 'Edit',
                            data: result?.data,
                        },
                    });
                }
            }
            filterable
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !newErrorState.name;
            }}
            maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            modalTitle={modalTitle(modalData, 'name', ' Region ', modalMode)}
            modalContent={
                <>
                    <ChakraProvider theme={chakratheme} resetCSS={true}>
                        <Box mt={4} display="flex">
                            <FormControl
                                isRequired
                                isInvalid={Boolean(modalStateError?.name)}
                                sx={floatingLabelStyles}>
                                <Input
                                    id="name"
                                    placeholder=" "
                                    data-peer
                                    value={modalState?.name}
                                    onChange={nameChangeHandler}
                                />
                                <FormLabel htmlFor="name" variant="floating">
                                    Name
                                </FormLabel>
                                {modalStateError?.name && (
                                    <FormErrorMessage>
                                        {modalStateError?.name}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        </Box>
                        {modalMode === 'Add' ? null : (
                            <>
                                <Box mt={4} display="flex">
                                    <FormControl>
                                        <FormLabel mb={1} fontSize="sm">
                                            Voice Policies
                                        </FormLabel>
                                        <MultiSelect
                                            chakraStyles={chakraStyles}
                                            isMulti
                                            name="voicePolicies"
                                            placeholder="+"
                                            tagVariant="solid"
                                            options={voicePolicies}
                                            closeMenuOnSelect={false}
                                            value={modalState?.voicePolicies}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            getOptionValue={(option) =>
                                                option.id
                                            }
                                            onChange={voiceSetState}
                                        />
                                    </FormControl>
                                </Box>
                                <Box mt={4} />
                                <Box
                                    sx={{
                                        label: {
                                            marginBottom: '0.25rem',
                                            fontSize: 'sm',
                                        },
                                    }}>
                                    <OrderedLists
                                        setState={listSetState}
                                        dependency={modalData?.id}
                                        fieldFetch={'/trunklocations/'}
                                        attribute="trunkLocations"
                                        header="Locations"
                                        placeholder="- Select Locations -"
                                        modalState={modalState}
                                    />
                                </Box>
                            </>
                        )}
                    </ChakraProvider>
                </>
            }
        />
    );
};

export default Regions;
