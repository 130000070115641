import React, { useCallback, useEffect, useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

import { MultiSelect, LoadingFieldDropdown2 } from '../../../../components';
import { axios } from '../../../../services/axios';
import { onModalError } from '../../../../components/tables/TableAndModal';

import walkthroughIds from '../../walkthroughIds.js';
import config from '@/config.json';

/*
 * Form that is rendered when adding or editing a partner
 */
const General = (props) => {
    const dispatch = useDispatch();
    const { setState, voicePoliciesAvailable } = props;
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({
        id: '',
        name: '',
    });
    const [status, setStatus] = useState('idle');

    // modal data from State
    const {
        data: modalData,
        state,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // navigation list from state
    const { currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { general } = walkthroughIds.partners;

    const handlePlanChange = (v) => {
        const selectedPlan = plans.find((plan) => plan.id === v);
        setSelectedPlan(selectedPlan);
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                defaultPartnerPlanID: v,
            },
        });
    };

    // get plans for ths partner and assign it to state
    useEffect(() => {
        if (modalData?.id) {
            axios
                .get('/partner/' + modalData?.id + '/plans/assigned')
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_MODAL_STATE',
                        payload: {
                            plans: res.data,
                        },
                    });
                })
                .catch((e) => onModalError(e));
        }
    }, [modalData?.id]);

    const fetchPlans = useCallback(() => {
        setStatus('loading');
        return axios
            .get(`/wholesaler/${currentWholesaler}/plans`, {
                params: {
                    wholesalerOnly: true,
                },
            })
            .then(
                (res) => {
                    setStatus('success');
                    const mapPlans = res.data.map((plan) => ({
                        id: plan.id,
                        name: plan.name,
                    }));
                    setPlans(mapPlans);
                    // setPlans(res.data);
                },
                (rej) => console.error(rej),
            )
            .finally(() => setStatus('idle'));
    }, [currentWholesaler]);

    useEffect(() => {
        fetchPlans();
    }, []);

    if (status === 'loading' && modalMode === 'Add') {
        return (
            <Container style={{ marginTop: '2%' }}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Container>
        );
    }

    // return a container with the form fields required for a parnter
    return (
        <>
            <Container style={{ marginTop: '2%' }}>
                {/* Friendly Company Name */}
                {modalMode === 'Add' && (
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                placeholder="Partner Name"
                                size="small"
                                label="Partner Name"
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                value={state?.name}
                                onChange={(e) => {
                                    setState({ name: e.target.value });
                                    setState({ entityName: e.target.value });
                                }}
                                data-walkthroughid={general.ufPartnerNameInput}
                            />
                        </Col>
                    </Row>
                )}
                {modalMode !== 'Add' && (
                    <>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Friendly Company Name"
                                    size="small"
                                    label="Friendly Company Name"
                                    error={Boolean(modalStateError?.name)}
                                    helperText={modalStateError?.name}
                                    value={state?.name}
                                    onChange={(e) => {
                                        setState({ name: e.target.value });
                                    }}
                                    data-walkthroughid={
                                        general.ufFriendlyNameInput
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Legal Entity Name"
                                    size="small"
                                    label="Legal Entity Name"
                                    error={Boolean(modalStateError?.entityName)}
                                    helperText={modalStateError?.entityName}
                                    value={state?.entityName}
                                    onChange={(e) => {
                                        setState({
                                            entityName: e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufLegalNameInput
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )}

                {/* Host Header */}
                <Row style={{ minHeight: 60 }}>
                    <Col>
                        <TextField
                            fullWidth
                            placeholder="Host Header"
                            size="small"
                            label="Host Header"
                            value={state?.hostHeader}
                            error={Boolean(modalStateError?.hostHeader)}
                            helperText={modalStateError?.hostHeader}
                            onChange={(e) => {
                                setState({
                                    hostHeader: e.target.value,
                                });
                            }}
                            data-walkthroughid={general.ufHostHeaderInput}
                        />
                    </Col>
                </Row>
                {modalMode === 'Add' && (
                    <>
                        {/* Default Partner Plan */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <LoadingFieldDropdown2
                                    searchable
                                    fieldName="Default Partner Plan"
                                    dropDownValue="id"
                                    onError={onModalError}
                                    displayField="name"
                                    onChange={handlePlanChange}
                                    staticEnum={plans}
                                    fieldValue={
                                        state?.defaultPartnerPlanID ||
                                        selectedPlan
                                    }
                                    toggleLimit={5}
                                    noEmptyOption
                                    noneFieldDisplay={
                                        config.notAllocated.dropdown
                                    }
                                    dataWalkthroughid={
                                        general.ufDefaultPlanSelect
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {modalMode !== 'Add' && (
                    <>
                        {/* Business Number */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Company Registration Number"
                                    size="small"
                                    label="Company Registration Number"
                                    value={state?.businessNumber}
                                    onChange={(e) => {
                                        setState({
                                            businessNumber: e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufCompanyRegNumberInput
                                    }
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact - Name */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Primary Contact Name"
                                    size="small"
                                    label="Primary Contact Name"
                                    value={state?.primaryContactName}
                                    onChange={(e) => {
                                        setState({
                                            primaryContactName: e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufPrimaryContactNameInput
                                    }
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact - Email */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Primary Contact Email"
                                    size="small"
                                    label="Primary Contact Email"
                                    value={state?.primaryContactEmail}
                                    onChange={(e) => {
                                        setState({
                                            primaryContactEmail: e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufPrimaryContactEmailInput
                                    }
                                />
                            </Col>
                        </Row>
                        {/* Primary Contact - Phone */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Primary Contact Phone"
                                    size="small"
                                    label="Primary Contact Phone"
                                    value={state?.primaryContactPhone}
                                    onChange={(e) => {
                                        setState({
                                            primaryContactPhone: e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufPrimaryContactPhoneInput
                                    }
                                />
                            </Col>
                        </Row>
                        {/* Accounts Contact Email */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Accounts Contact Email"
                                    size="small"
                                    label="Accounts Contact Email"
                                    value={state?.accountsContactEmail}
                                    onChange={(e) => {
                                        setState({
                                            accountsContactEmail:
                                                e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufAccountsContactEmailInput
                                    }
                                />
                            </Col>
                        </Row>
                        {/* Support Contact Email */}
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    placeholder="Support Contact Email"
                                    size="small"
                                    label="Support Contact Email"
                                    value={state?.supportContactEmail}
                                    onChange={(e) => {
                                        setState({
                                            supportContactEmail: e.target.value,
                                        });
                                    }}
                                    data-walkthroughid={
                                        general.ufSupportContactEmailInput
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
};

export default General;
