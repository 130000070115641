import React from 'react';
import {Box} from '@chakra-ui/react';
import styles from './styles';
import {PlusSquareIcon} from '@chakra-ui/icons';
import Text from '../../Text';

export default function DashboardServiceAddServiceCardTitle({
    children,
    ...props
}) {
    return (
        <Text sx={styles.DashboardServiceAddServiceCardTitle} {...props}>
            {children}
        </Text>
    );
}
