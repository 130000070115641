import {
    AlertTitle,
    Box,
    Button,
    Flex,
    Heading,
    Tooltip,
    useDisclosure,
    VStack,
    HStack,
    Spacer,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    Navigate,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { postActivateNumbers, postValidateNumbers } from 'API/TPMTrial';
import { useAssociatedAccounts } from 'hooks/useCustomQuery';
import {
    clearErrors,
    groupMessages,
    initValidation,
    NUMBER_EXCEED_LIMIT,
} from './helper';

import { Alert, AlertIcon, Card, CardBody, Text } from '@/components/v4';
import { useTpmContext } from '../';
import { ActivateNumbers as ActivateNumbersTable } from './ActivateNumbers';
import AssociatedAccountsTable from './AssociatedAccountsTable';
import DisplayMessages from './DisplayMessages';
import EditNumbersModal from './EditNumbersModal';
import ErrorAssociatedAccCard from './ErrorAssociatedAccCard';

import { CONTINUE, RESET } from '@/store/constants';
import { initialState } from '../constants';
import { LuTrash } from 'react-icons/lu';

export default function AssociatedAccounts() {
    const {
        tpmNumber: [state, setState],
        tpmActivatedNumber: [telephoneNumbers, setTelephoneNumbers],
        tpmDeactivateNumber: [deactivateNumbers, setDeactivateNumbers],
    } = useTpmContext();
    const [filteredData, setFilteredData] = useState([]);
    const [numberCopy, setNumberCopy] = useState(false);
    const [validateNumbers, setValidateNumbers] = useState([initValidation]);
    const [formState, setFormState] = useState('idle');
    const [activateList, setActivateList] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const {
        isOpen: isModalOpen,
        onOpen: onModalOpen,
        onClose: onModalClose,
    } = useDisclosure();
    const { onClose: onDmodalClose } = useDisclosure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { pathname } = useLocation();
    const previousPage = pathname.split('/').slice(0, -1).join('/');
    const { darkMode, colorScheme } = useSelector((state) => {
        return {
            darkMode: state.settings.darkMode,
            colorScheme: state.settings?.colorScheme,
        };
    });
    const { number: mpn, currentStep } = useSelector(
        (state) => state.service.tpm,
    );

    const { mutate, status: verifiedStatus } = useVerifyNumbers();
    const { mutate: importNumbers, status: importStatus } = useImport();

    const handleStartOver = () => {
        setState(initialState);
        dispatch({
            type: 'RESET_MODAL',
        });
        dispatch({ type: RESET });
        isModalOpen ? onModalClose() : onDmodalClose();
    };

    const handleVerify = () => {
        const telephoneList = [
            ...new Map(
                telephoneNumbers
                    .filter((item) => item.number.trim() !== '')
                    .map((item) => ({
                        ...item,
                        number: item.number.replace(/\D/g, ''),
                    }))
                    .map((item) => [item.number, item]),
            ).values(),
        ];

        mutate(
            telephoneList.map((item) => item.number),
            {
                onSuccess: async (res) => {
                    const data = await res.data;
                    const vTelNumbers = telephoneList.map((item) => {
                        const foundData = data.find(
                            (d) => d.number === item.number,
                        );
                        if (foundData) {
                            return {
                                ...item,
                                status: foundData.message && 'FAIL',
                            };
                        }
                        return {
                            ...item,
                            status: 'PASS',
                        };
                    });

                    setTelephoneNumbers(vTelNumbers);
                    setValidateNumbers(data);
                },
                onError: async (error) => {
                    toast.error('Error Verifying Numbers', { autoClose: 1500 });
                    if (error?.message.includes('400')) {
                        setErrorMessage(
                            'Cannot exceed a total of 5 numbers while Company is on Trial.',
                        );
                        const updatedTelephoneList = telephoneList.map(
                            (item) => ({
                                ...item,
                                status:
                                    item.status !== 'PASS'
                                        ? 'FAIL'
                                        : item.status || 'PASS',
                            }),
                        );
                        setTelephoneNumbers(updatedTelephoneList);
                    } else {
                        // handle other errors
                        const failedTelephoneList = telephoneList.map(
                            (item) => ({
                                ...item,
                                status: 'FAIL',
                            }),
                        );
                        setTelephoneNumbers(failedTelephoneList);
                    }
                },
            },
        );
    };

    const handleContinueButton = () => {
        setState(initialState);
        dispatch({
            type: 'RESET_MODAL',
        });
        // TODO: extra action, check to remove in the future
        dispatch({
            type: CONTINUE,
        });
    };

    const handleAssociationButton = () => {
        setState(initialState);
        dispatch({
            type: RESET,
        });
    };

    function useVerifyNumbers() {
        return useMutation({
            mutationFn: async (data) => {
                try {
                    return await postValidateNumbers({
                        serviceID: id,
                        phoneNumbers: data,
                    });
                } catch (error) {
                    throw new Error(
                        error?.message || 'Error verifying numbers',
                    );
                }
            },
        });
    }

    const handleClearErrors = () => {
        setErrorMessage('');
        clearErrors(setTelephoneNumbers, setValidateNumbers);
    };

    const handleSubmit = () => {
        setFormState('submitting');

        const activateNumbers = telephoneNumbers
            .filter((item) => item.status === 'PASS')
            .map((item) => item.number);
        importNumbers(activateNumbers, {
            onSuccess: () => {
                setFormState('finish');
                setActivateList(activateNumbers);
                setTelephoneNumbers([{ number: '', status: '' }]);
            },
            onError: (error) => {
                setFormState('error');
                setActivateList(activateNumbers);
                throw new Error(error);
            },
        });
    };

    function useImport() {
        return useMutation({
            mutationFn: async (data) => {
                try {
                    onModalOpen();
                    return await postActivateNumbers({
                        serviceID: id,
                        phoneNumbers: data,
                    });
                } catch (error) {
                    throw new Error(
                        error?.message || 'Error importing numbers',
                    );
                }
            },
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const groupedMessages =
        verifiedStatus === 'success' &&
        validateNumbers.length >= 1 &&
        groupMessages(validateNumbers);

    const {
        data: associatedAccounts,
        isLoading,
        isError,
    } = useAssociatedAccounts(id);

    const accCardProps = {
        id,
        setState,
        state,
        darkMode,
    };

    useEffect(() => {
        setFilteredData(telephoneNumbers);
    }, [telephoneNumbers, deactivateNumbers, setDeactivateNumbers]);

    useEffect(() => {
        if (state.number) {
            const newStatus = associatedAccounts?.find(
                (aa) => aa?.verifiedNumber === state.number,
            )
                ? 'PASS'
                : '';
            setTelephoneNumbers([{ number: state.number, status: newStatus }]);
        }
    }, [state.number, associatedAccounts, setTelephoneNumbers]);

    useEffect(() => {
        if (!telephoneNumbers?.length) {
            setTelephoneNumbers([{ number: '', status: '' }]);
        }
        if (!deactivateNumbers?.length) {
            setDeactivateNumbers([{ number: '', status: '' }]);
        }
    }, [
        telephoneNumbers,
        setTelephoneNumbers,
        deactivateNumbers,
        setDeactivateNumbers,
    ]);

    useEffect(() => {
        if (importStatus === 'loading') {
            setModalTitle('Associate Request Pending');
        }

        if (formState === 'error' && importStatus === 'error') {
            setModalTitle('Associate Request Error');
        }

        if (formState === 'finish' && importStatus === 'success') {
            setModalTitle('Associate Request Sent');
        }
    }, [formState, importStatus]);

    if (isLoading) {
        return (
            <VStack
                className="h-[calc(100vh-200px)]"
                width="full"
                justifyContent="center">
                <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
                    <CardBody>
                        <Flex
                            justifyContent="flex-start"
                            flexDir="column"
                            width="full">
                            <Heading
                                as="h4"
                                fontWeight="semibold"
                                fontSize="2xl"
                                color={darkMode ? 'white' : 'black'}>
                                Associated Accounts
                            </Heading>
                            <Text mt={2}>
                                These associated entities exist with your
                                Operator account.
                            </Text>
                        </Flex>

                        <AssociatedAccountsTable
                            {...accCardProps}
                            data={associatedAccounts}
                            loading={isLoading}
                            serviceID={id}
                        />
                    </CardBody>
                </Card>
            </VStack>
        );
    }

    if (!associatedAccounts?.length) {
        return <Navigate to={previousPage} />;
    }

    // FIXME: This catch all error has broken UI, need to remove later
    if (isError || state?.status === 'ERROR') {
        return (
            <ErrorAssociatedAccCard
                initialState={initialState}
                navigate={navigate}
                {...accCardProps}
            />
        );
    }

    return (
        <VStack height="full" width="full" alignItems="flex-start">
            <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'} width="full">
                <CardBody>
                    <Flex
                        justifyContent="flex-start"
                        flexDir="column"
                        width="full">
                        <Heading
                            as="h4"
                            fontWeight="semibold"
                            fontSize="2xl"
                            color={darkMode ? 'white' : 'black'}>
                            Associated Accounts
                        </Heading>
                        <Text>
                            These associated entities exist with your Operator
                            account.
                        </Text>
                    </Flex>
                    <AssociatedAccountsTable
                        {...accCardProps}
                        data={associatedAccounts}
                        loading={isLoading}
                        serviceID={id}
                    />
                    <HStack spacing={6} paddingTop={5}>
                        {mpn && currentStep !== 0 && currentStep !== 3 && (
                            <Link
                                to={
                                    currentStep === 1
                                        ? {
                                              pathname: `/numbers/tpm/${id}/activation/`,
                                          }
                                        : {
                                              pathname: '../sms-authorization',
                                              search: `?number=${mpn.replace(/\d(?=\d{4})/g, 'X')}`,
                                          }
                                }
                                state={{ from: pathname }}>
                                <Button
                                    px={2}
                                    minW="128px"
                                    colorScheme={
                                        colorScheme === 'secondary' ||
                                        colorScheme === 'green' ||
                                        colorScheme === 'whatsapp'
                                            ? 'tertiary'
                                            : 'secondary'
                                    }
                                    onClick={handleContinueButton}>
                                    Continue
                                </Button>
                            </Link>
                        )}
                        <Link to={previousPage} state={{ from: pathname }}>
                            <Button
                                minW="128px"
                                onClick={handleAssociationButton}>
                                Associate
                            </Button>
                        </Link>
                    </HStack>
                </CardBody>
            </Card>

            <Box aria-label="spacing box" display="flex" className="h-4" />

            {/* Activate Numbers Table */}
            {associatedAccounts?.length > 0 && (
                <Card
                    as="form"
                    onSubmit={onSubmit}
                    bg={darkMode ? 'dark.bgDarkGray' : 'white'}
                    width="full"
                    mt={4}>
                    <CardBody>
                        <Flex width="full" className="mt-0">
                            <Flex
                                justifyContent="flex-start"
                                flexDir="column"
                                width="full"
                                className="mt-1">
                                <Heading
                                    as="h4"
                                    fontWeight="semibold"
                                    fontSize="2xl"
                                    color={darkMode ? 'white' : 'black'}>
                                    Associate Numbers
                                </Heading>
                                <Text fontSize="sm">
                                    Adds Telephone Numbers from Associated
                                    Accounts. Add numbers below. 1 per line, max
                                    1000 lines.
                                </Text>
                            </Flex>
                        </Flex>
                        <Box color={darkMode ? 'white' : 'black'}>
                            {/* Error Message */}
                            {verifiedStatus === 'error' && errorMessage && (
                                <Alert
                                    status="error"
                                    flexDir="column"
                                    alignItems="flex-start"
                                    mt={4}>
                                    <Flex width="100%" alignItems="center">
                                        <AlertIcon />
                                        <AlertTitle>
                                            Verification Error!
                                        </AlertTitle>
                                        <Spacer />
                                        <Button
                                            justifyContent="flex-end"
                                            ml={14}
                                            variant="link"
                                            h="24px"
                                            color={darkMode ? 'white' : 'black'}
                                            leftIcon={<LuTrash />}
                                            isDisabled={
                                                !telephoneNumbers?.some(
                                                    (item) =>
                                                        item.status === 'FAIL',
                                                )
                                            }
                                            onClick={handleClearErrors}>
                                            Clear Errors
                                        </Button>
                                    </Flex>
                                    {errorMessage}
                                </Alert>
                            )}
                            {verifiedStatus === 'success' &&
                                validateNumbers?.length >= 1 &&
                                telephoneNumbers.find(
                                    (n) => n.status === 'FAIL',
                                ) && (
                                    <Alert
                                        status="error"
                                        flexDir="column"
                                        alignItems="flex-start"
                                        mt={4}>
                                        <Flex width="100%" alignItems="center">
                                            <AlertIcon />
                                            <AlertTitle>
                                                Verification Error!
                                            </AlertTitle>
                                            <Spacer />
                                            <Button
                                                justifyContent="flex-end"
                                                ml={14}
                                                variant="link"
                                                h="24px"
                                                color={
                                                    darkMode ? 'white' : 'black'
                                                }
                                                leftIcon={<LuTrash />}
                                                isDisabled={
                                                    !telephoneNumbers?.some(
                                                        (item) =>
                                                            item.status ===
                                                            'FAIL',
                                                    )
                                                }
                                                onClick={handleClearErrors}>
                                                Clear Errors
                                            </Button>
                                        </Flex>
                                        <DisplayMessages
                                            groupedMessages={groupedMessages}
                                        />
                                    </Alert>
                                )}

                            {telephoneNumbers?.length > 1000 && (
                                <Alert
                                    status="error"
                                    flexDir="column"
                                    alignItems="flex-start"
                                    mt={4}>
                                    <Flex>
                                        <AlertIcon />
                                        <AlertTitle>Error!</AlertTitle>
                                        {NUMBER_EXCEED_LIMIT}
                                    </Flex>
                                </Alert>
                            )}
                        </Box>

                        <ActivateNumbersTable
                            type="activate"
                            data={
                                filteredData ? filteredData : telephoneNumbers
                            }
                            setData={setTelephoneNumbers}
                            verifiedStatus={verifiedStatus}
                            numberCopy={numberCopy}
                            setNumberCopy={setNumberCopy}
                        />
                        <HStack spacing={6} paddingTop={5}>
                            <Tooltip
                                hasArrow
                                shouldWrapChildren
                                mt="1"
                                label={
                                    telephoneNumbers?.some(
                                        (item) => item.number === '',
                                    )
                                        ? 'Please fill in all numbers'
                                        : ''
                                }>
                                <Button
                                    colorScheme="whatsapp"
                                    minW="128px"
                                    px={2}
                                    onClick={handleVerify}>
                                    {verifiedStatus === 'loading'
                                        ? 'Verifying'
                                        : 'Verify'}
                                </Button>
                            </Tooltip>
                            <Tooltip
                                hasArrow
                                shouldWrapChildren
                                mt="1"
                                label={
                                    telephoneNumbers?.some(
                                        (item) => item.status !== 'PASS',
                                    )
                                        ? 'Please verify numbers and clear errors before importing'
                                        : ''
                                }>
                                <Button
                                    type="submit"
                                    minW="128px"
                                    isDisabled={
                                        telephoneNumbers?.some(
                                            (item) => item.status !== 'PASS',
                                        ) || importStatus === 'loading'
                                    }>
                                    Associate
                                </Button>
                            </Tooltip>
                        </HStack>
                    </CardBody>
                </Card>
            )}
            <EditNumbersModal
                isOpen={isModalOpen}
                onClose={handleStartOver}
                status={importStatus}
                modalTitle={modalTitle}
                numberList={activateList}
            />
        </VStack>
    );
}

AssociatedAccounts.displayName = 'AssociatedAccounts.Index';
