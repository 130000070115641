import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';

import { axios } from '@/services/axios';
import { downloadCSV } from '@/utils/utils';

/** Resource Account Fields */
const FIELDS_TO_INCLUDE = {
    o365UserPrincipalName: 'UPN',
    displayName: 'Display Name',
    telephoneNumber: 'Telephone Number',
    voicePolicy: 'Voice Policy',
    usageLocation: 'Usage Location',
    managedBy: 'Managed By (TCAP/Other)',
    plan: 'Plan',
    attribute1: 'Attribute 1',
    attribute2: 'Attribute 2',
    attribute3: 'Attribute 3',
};

import path from '@/routes/constants';

const RESOURCE_ACCOUNTS_TEMPLATE_FILENAME = 'ResourceAccountsTemplate.csv';
/**
 * handles downloading resource account's CSV template
 */
export default function useCSVTemplate() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [jsonData, setJSONData] = useState([]);
    const [template, setTemplate] = useState(null);

    const { RESOURCE_ACCOUNTS_ENDPOINT } = path;

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    /**
     * transforms JSON Data from BE fields into more human readable CSV heading columns
     * @param {*} data
     * @returns
     */
    const transformJSONData = (data) => {
        return data.map((user) => {
            return Object.keys(FIELDS_TO_INCLUDE).reduce((acc, key) => {
                const value = user[key];
                const newKey = FIELDS_TO_INCLUDE[key];
                acc[newKey] = value;
                if (key === 'managedBy') {
                    acc[newKey] = value === 0 ? 'TCAP' : 'Other';
                }
                if (key === 'plan') {
                    acc[newKey] =
                        value === null &&
                        acc['Telephone Number'] !== 'Not Licensed' &&
                        acc['Managed By (TCAP/Other)'] === 'TCAP'
                            ? 'Company Default Plan'
                            : value;
                }
                return acc;
            }, {});
        });
    };

    /**
     * handles downloading user data from BE.
     */
    const downloadUsersData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${RESOURCE_ACCOUNTS_ENDPOINT}/${currentCompany}`,
            );
            const newJSONData = transformJSONData(response.data);
            setJSONData(newJSONData);
        } catch (error) {
            setError(error);
            window.alert(
                `Could not download resource accounts data at this time. Please try again later.`,
            );
        } finally {
            setLoading(false);
        }
    };

    /**
     * converts the template from JSON into CSV
     */
    const processTemplate = () => {
        const res = Papa.unparse(jsonData);
        setTemplate(res);
        downloadCSV(res, RESOURCE_ACCOUNTS_TEMPLATE_FILENAME);
    };
    /**
     * handle download template UI button
     */
    const handleDownloadTemplate = () => {
        downloadUsersData();
    };

    useEffect(() => {
        //after data downloaded from BE, convert to CSV
        if (jsonData.length > 0) {
            processTemplate();
        }
    }, [jsonData]);

    return {
        handleDownloadTemplate,
        loading,
        error,
        template,
    };
}
