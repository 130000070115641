import {
    Box,
    ChakraProvider,
    FormErrorMessage,
    FormHelperText,
    Stack,
    Tooltip,
} from '@chakra-ui/react';
import {
    Alert,
    AlertIcon,
    Button,
    FormControl,
    FormLabel,
    Input,
} from 'components/v4';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChipList } from 'components/v4/ChipInput/ChipList';
import ChipInput from 'components/v4/ChipInput/ChipInput';
import { Container, Modal } from 'react-bootstrap';
import { useNationalCode } from './useNationalCode';
import { useMultiNationalCode } from './useMultiNationalCode';
import { axios } from '@/services/axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const AddCountry = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { theme } = useDeepMerge();

    const { requiredScope } = useSelector((state) => state.login);

    useEffect(() => {
        if (requiredScope != 80) {
            // get parent menu item Dashboards
            let dashboard = document.querySelector('.Dashboards');
            // if only 1 then the menu item is closed
            if (dashboard?.children?.length == 1) {
                var divElement = dashboard.querySelector('div');
                // create on click event
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                divElement.dispatchEvent(clickEvent);
            }

            // redirect is to select the sub menu item
            // scope 20 to focus on currently managing company
            dispatch({
                type: 'REDIRECT',
                payload: {
                    pathname: '//dashboardServices',
                    scope: 20,
                },
            });

            navigate('/dashboard-services');
        }
    }, [requiredScope]);

    const {
        nationalCodes,
        nationalCodeInput,
        nationalCodeError,
        handleNationalCodesChange,
        handleNationalCodesKeyDown,
        handleNationalCodesPaste,
        handleNationalCodesCloseClick,
    } = useNationalCode();

    const {
        multiNationalCodes,
        multiNationalCodeInput,
        multiNationalCodeError,
        handleMultiNationalCodesChange,
        handleMultiNationalCodesKeyDown,
        handleMultiNationalCodesPaste,
        handleMultiNationalCodesCloseClick,
    } = useMultiNationalCode();

    const initialState = {
        name: '',
        countryCode: '',
        alpha2: '',
        alpha3: '',
        nationalCodes: [],
        multiNationalCodes: [],
    };
    const [countryState, setCountryState] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (key, e) => {
        setCountryState((prevState) => ({
            ...prevState,
            [key]: e.target.value,
        }));
    };

    const handleAdd = async () => {
        try {
            setIsDisabled(true);
            const country = {
                ...countryState,
                nationalCodes: nationalCodes,
                multiNationalCodes: multiNationalCodes,
            };
            await axios.post('/country', country);
            toast.success('Country created successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/countries');
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <ChakraProvider resetCSS theme={theme}>
            <Container fluid>
                <Modal.Title> Add Country </Modal.Title>
                <Box
                    sx={{ flexGrow: 1, paddingTop: '1%', paddingBlock: '1%' }}
                    className="mx-auto min-w-[36rem] max-w-4xl">
                    <Alert mb={3} status="info">
                        <AlertIcon />
                        National and Multi-National codes are ONLY used when a
                        Country Code is shared across multiple countries.
                    </Alert>
                    <Stack direction={['column', 'row']} spacing={'24px'}>
                        <FormControl isRequired mb={2}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                value={countryState.name}
                                isDisabled={isDisabled}
                                onChange={(e) => handleChange('name', e)}
                                onKeyPress={(event) => {
                                    if (!/^[a-zA-Z\s\-]+$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                placeholder="Please enter a Name"
                            />
                            <FormHelperText>eg. United States</FormHelperText>
                        </FormControl>

                        <FormControl isRequired mb={2}>
                            <FormLabel>Country Code</FormLabel>
                            <Input
                                value={countryState.countryCode}
                                isDisabled={isDisabled}
                                onChange={(e) => handleChange('countryCode', e)}
                                onKeyPress={(event) => {
                                    if (!/^[0-9]$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                placeholder="Please enter a Country Code"
                            />
                            <FormHelperText>eg. 1</FormHelperText>
                        </FormControl>
                    </Stack>

                    <Stack direction={['column', 'row']} spacing={'24px'}>
                        <FormControl isRequired mb={2}>
                            <FormLabel>Alpha-2</FormLabel>
                            <Input
                                value={countryState.alpha2}
                                isDisabled={isDisabled}
                                onKeyPress={(event) => {
                                    const currentInput =
                                        event.target.value + event.key;
                                    if (
                                        !/^[A-Z]$/.test(event.key) ||
                                        currentInput.length > 2
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => handleChange('alpha2', e)}
                                placeholder="Please enter a Alpha-2"
                            />
                            <FormHelperText>eg. US</FormHelperText>
                        </FormControl>

                        <FormControl isRequired mb={2}>
                            <FormLabel>Alpha-3</FormLabel>
                            <Input
                                value={countryState.alpha3}
                                onChange={(e) => handleChange('alpha3', e)}
                                isDisabled={isDisabled}
                                onKeyPress={(event) => {
                                    const currentInput =
                                        event.target.value + event.key;
                                    if (
                                        !/^[A-Z]$/.test(event.key) ||
                                        currentInput.length > 3
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                placeholder="Please enter a Alpha-3"
                            />
                            <FormHelperText>eg. USA</FormHelperText>
                        </FormControl>
                    </Stack>

                    <FormControl mb={2} isInvalid={nationalCodeError.isError}>
                        <FormLabel>National Codes</FormLabel>
                        <ChipList
                            inputs={nationalCodes}
                            isDisabled={isDisabled}
                            onCloseClick={handleNationalCodesCloseClick}
                        />
                        <ChipInput
                            placeholder="Please enter National Codes"
                            isDisabled={isDisabled}
                            onPaste={handleNationalCodesPaste}
                            onKeyPress={(event) => {
                                if (!/^[0-9]$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onKeyDown={handleNationalCodesKeyDown}
                            onChange={handleNationalCodesChange}
                            value={nationalCodeInput.trim()}
                        />
                        {!nationalCodeError.isError ? (
                            <FormHelperText>
                                eg. 325, 330, 518, 957
                            </FormHelperText>
                        ) : nationalCodeError.message ? (
                            <FormErrorMessage>
                                {nationalCodeError.message}
                            </FormErrorMessage>
                        ) : (
                            <FormErrorMessage>
                                {nationalCodeError.duplicates}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl
                        mb={2}
                        isInvalid={multiNationalCodeError.isError}>
                        <FormLabel>Multi-National Codes</FormLabel>
                        <ChipList
                            inputs={multiNationalCodes}
                            isDisabled={isDisabled}
                            onCloseClick={handleMultiNationalCodesCloseClick}
                        />
                        <ChipInput
                            placeholder="Please enter Multi-National Codes"
                            onPaste={handleMultiNationalCodesPaste}
                            isDisabled={isDisabled}
                            onKeyPress={(event) => {
                                if (!/^[0-9]$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onKeyDown={handleMultiNationalCodesKeyDown}
                            onChange={handleMultiNationalCodesChange}
                            value={multiNationalCodeInput.trim()}
                        />
                        {!multiNationalCodeError.isError ? (
                            <FormHelperText>eg. 800, 822</FormHelperText>
                        ) : multiNationalCodeError.message ? (
                            <FormErrorMessage>
                                {multiNationalCodeError.message}
                            </FormErrorMessage>
                        ) : (
                            <FormErrorMessage>
                                {multiNationalCodeError.duplicates}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <Box display="flex" className="mt-3 justify-center">
                        <Button
                            variant="outline"
                            bg="white"
                            minW={['100%', '82px']}
                            isDisabled={isDisabled}
                            onClick={() => {
                                navigate('/countries');
                            }}>
                            Back
                        </Button>

                        <Button
                            ml={4}
                            isLoading={isDisabled}
                            onClick={() => handleAdd()}>
                            Create
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ChakraProvider>
    );
};

export default AddCountry;
