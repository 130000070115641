import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    chakra,
    useDisclosure,
    Tooltip,
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import DeleteAssociatedAccModal from './DeleteAssociatedAccModal';
import DeleteBin from './DeleteBin';
import NoAssociatedAccCard from './NoAssociatedAccCard';
import { useDeleteAAccount } from './useDeleteAAccount';
import { useTpmContext } from '../';
import { initialState } from '../constants';
import { useAssociatedAccounts } from 'hooks/useCustomQuery';
import { RESET } from '@/store/constants';

// Modern TableAndModal component
function AssociatedAccountsTable({ data, loading, serviceID, accCardProps }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { darkMode, colorScheme } = useSelector((state) => state.settings);
    const columnHelper = createColumnHelper();
    const [sorting, setSorting] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const {
        tpmNumber: [, setState],
    } = useTpmContext();
    const dispatch = useDispatch();

    const { data: associatedAccounts } = useAssociatedAccounts(serviceID);

    const tableData = useMemo(
        () =>
            loading
                ? Array(5).fill({})
                : data || [
                      {
                          numberCount: 0,
                          operatorCustomerID: 'N/A',
                      },
                  ],
        [loading, data],
    );

    /** @const {import('@tanstack/react-table').ColumnDef} */
    const columns = useMemo(
        () => [
            columnHelper.accessor('numberCount', {
                cell: (info) => info?.getValue(),
                header: (
                    <chakra.span>
                        <Tooltip
                            hasArrow
                            label="This is the total Phone Numbers under the associated Operator ID.  A single Operator ID may contain multiple Phone Numbers.">
                            Total Numbers
                        </Tooltip>
                    </chakra.span>
                ),
                meta: {
                    // isNumeric: true,
                    align: 'left',
                },
            }),
            columnHelper.accessor('operatorCustomerID', {
                cell: (info) => info?.getValue(),
                header: (
                    <chakra.span>
                        <Tooltip
                            hasArrow
                            label="This is your unique Operator reference per assigned account.  multiple  Accounts may be Associated via the Association button.">
                            Operator Company ID
                        </Tooltip>
                    </chakra.span>
                ),
            }),
            {
                id: 'actions',
                header: (
                    <chakra.span>
                        <Tooltip hasArrow label="Remove Association">
                            Actions
                        </Tooltip>
                    </chakra.span>
                ),
                accessorKey: 'id',
                cell: (info) => (
                    <DeleteBin
                        onClick={() => {
                            onOpen();
                            setSelectedRow(info.row.original);
                        }}
                    />
                ),
                meta: {
                    align: 'right',
                },
            },
        ],
        [columnHelper, onOpen],
    );
    const tableColumns = loading
        ? columns.map((column) => ({
              ...column,
              cell: <Skeleton rounded="md" height="20px" width="100%" />,
          }))
        : columns;
    const table = useReactTable({
        columns: tableColumns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });

    const { mutate, status } = useDeleteAAccount(serviceID);

    const handleDeleteOnClick = () => {
        mutate(selectedRow, {
            onSuccess: () => {
                toast.success('Associated account deleted successfully');
                setSelectedRow({});
                setState(initialState);
                onClose();
            },
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleDeleteOnClick();
    };

    const deleteModalProps = {
        isOpen,
        onClose,
        status,
        selectedRow,
        handleSubmit,
    };

    useEffect(() => {
        if (!associatedAccounts?.length) {
            dispatch({ type: RESET });
        }
    }, [associatedAccounts, dispatch]);

    // loading finished but no data
    if (!tableData && !loading) {
        return <NoAssociatedAccCard {...accCardProps} />;
    }

    return (
        <Flex
            flex="1 1 auto"
            flexDirection="column"
            overflow="auto"
            w="full"
            maxHeight="400px">
            <TableContainer width="full" overflowY="auto" className="mt-4">
                <Table variant="striped" size="sm" colorScheme="messenger">
                    <Thead
                        position="sticky"
                        top={0}
                        zIndex={1}
                        height="40px"
                        background={darkMode ? 'dark.tcap' : 'white'}>
                        {table?.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const meta = header.column.columnDef.meta;
                                    return (
                                        <Th
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                            isNumeric={meta?.isNumeric}
                                            textTransform="capitalize"
                                            fontSize="sm"
                                            letterSpacing="wide"
                                            textAlign={
                                                header.id.includes('actions')
                                                    ? 'center'
                                                    : 'left'
                                            }>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}

                                            <chakra.span pl="4">
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() ===
                                                    'desc' ? (
                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                    ) : (
                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                    )
                                                ) : null}
                                            </chakra.span>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {tableData?.length > 0 ? (
                            table?.getRowModel()?.rows.map((row, index) => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        const meta =
                                            cell?.column.columnDef.meta;
                                        return (
                                            <Td
                                                key={cell?.id}
                                                id={cell?.id}
                                                isNumeric={meta?.isNumeric}
                                                align={
                                                    cell?.column.columnDef.meta
                                                        ?.align
                                                }
                                                borderBottom="1px solid"
                                                borderColor={
                                                    darkMode
                                                        ? 'gray.300'
                                                        : 'gray.200'
                                                }
                                                className={
                                                    darkMode
                                                        ? index % 2 === 0
                                                            ? '!text-black'
                                                            : '!text-white'
                                                        : 'text-black'
                                                }
                                                sx={{
                                                    '& button': {
                                                        color: darkMode
                                                            ? index % 2 === 0
                                                                ? 'black'
                                                                : 'white'
                                                            : 'black',
                                                    },
                                                    '& button:hover': {
                                                        color: `${colorScheme}.500`,
                                                    },
                                                }}>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent={
                                                        cell.id.includes(
                                                            'actions',
                                                        )
                                                            ? 'center'
                                                            : 'flex-start'
                                                    }
                                                    h={8}
                                                    mr={
                                                        cell.id.includes(
                                                            'actions',
                                                        )
                                                            ? 10
                                                            : 0
                                                    }>
                                                    {flexRender(
                                                        cell?.column.columnDef
                                                            .cell,
                                                        cell?.getContext(),
                                                    )}
                                                </Box>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td
                                    colSpan={3}
                                    className="!border-none !bg-transparent text-center">
                                    No associated account to display.
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>

            {selectedRow && <DeleteAssociatedAccModal {...deleteModalProps} />}
        </Flex>
    );
}

export default AssociatedAccountsTable;
