const GENERAL_TAB = 'General';
const CUSTOM_TAB = 'Custom';
export const tabList = [GENERAL_TAB, CUSTOM_TAB];

export const actionButtons = [
    {
        label: 'Add new block',
        ariaLabel: 'Add',
    },
    {
        label: 'Clear row',
        ariaLabel: 'Clear',
    },
];

export const initTableState = [
    {
        id: 0,
        blockSize: 1,
        number: '',
    },
];

export const blockSizes = [{ value: 1, name: '1' },{ value: 5, name: '5' },{ value: 10, name: '10' },{ value: 100, name: '100' },];

export const getErrorStyles = (errorField) => {
    return {
        '& fieldset, & label.MuiFormLabel-root.MuiInputLabel-root': {
            borderColor: errorField ? 'red' : null,
            color: errorField ? 'red' : null,
        },
    };
};
