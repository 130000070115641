import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    useAssociatedAccounts,
    useDashboardServices,
} from 'hooks/useCustomQuery';
import { TpmProvider } from 'pages/tpm/TpmProvider';
import { TabView } from '../../../components';
import config from '../../../config.json';
import Table from './edit/Index';
import ExistingNumbers from './existingNumbers/Index';
import NumberManagement from './numberManagement/Index';
import AvailableBlock from './requestBlock/AvailableBlock';

const getTpmPath = (tpmService, associatedAccounts, isTrialExpired) => {
    if (!tpmService) return '/numbers';

    const basePath = `/numbers/tpm/${tpmService.id}/activation`;
    return isTrialExpired || !associatedAccounts?.length
        ? basePath
        : `${basePath}/associated-accounts`;
};

/**
 * Component for the Number Tab of TCAP
 * Handles CRUD for this asepct of the app
 */
const Numbers = () => {
    const dispatch = useDispatch();
    const [tpmService, setTpmService] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { state: locationState } = location;

    // state access
    const { tab } = useSelector((state) => {
        return { ...state.view };
    });

    const handleTabChange = (_e, v) => {
        dispatch({
            type: 'CHANGE_TAB_VIEW',
            payload: {
                level1: v,
                level2: 0,
            },
        });
    };

    const tabContents = [
        <Table key={config.numbers.companies.tabs[0]} />,
        <AvailableBlock key={config.numbers.companies.tabs[1]} />,
        <ExistingNumbers key={config.numbers.companies.tabs[2]} />,
        tpmService ? (
            <TpmProvider>
                <NumberManagement key={config.numbers.companies.tabs[3]} />
            </TpmProvider>
        ) : null,
    ];
    const { currentCompany, currentCompanyData: companyData } = useSelector(
        (state) => state.navigationLists,
    );

    const isTrialExpired =
        companyData?.isTrial && Date.parse(companyData?.trialEnd) < Date.now();

    const { data: availableServices } = useDashboardServices({
        currentCompany,
        options: {
            cacheTime: 1,
        },
    });
    const { data: associatedAccounts } = useAssociatedAccounts(tpmService?.id);

    useEffect(() => {
        if (locationState?.from === '/dashboard-services') {
            const telePhoneBtn = document.querySelector('button.Telephony'); // NOTE: be careful when changing the class of the button
            if (telePhoneBtn && telePhoneBtn?.ariaExpanded === 'false') {
                telePhoneBtn.click();
            }
            dispatch({
                type: 'CHANGE_TAB_VIEW',
                payload: {
                    level1: 3,
                },
            });
        }
    }, [locationState, dispatch]);

    useEffect(() => {
        if (availableServices) {
            setTpmService(
                availableServices.find(
                    (service) => service.name === 'Teams Phone Mobile',
                ),
            );
        }
    }, [availableServices]);

    useEffect(() => {
        const path =
            tab['level1'] !== 3
                ? '/numbers'
                : getTpmPath(tpmService, associatedAccounts, isTrialExpired);
        navigate(path);
    }, [tab, tpmService, navigate, associatedAccounts, isTrialExpired]);

    // Returns the Tab view of the numbers page
    return (
        <>
            <h1 className="mb-2 text-[2.5rem] font-medium">Numbers</h1>
            <TabView
                onChange={handleTabChange}
                value={tab['level1']}
                contents={tabContents}
                tabLabels={
                    tpmService
                        ? config.numbers.companies.tabs
                        : config.numbers.companies.tabs.slice(0, 3)
                }
                isLink
                tpmService={tpmService}
                to={(i) =>
                    i === 3 && tpmService
                        ? getTpmPath(
                              tpmService,
                              associatedAccounts,
                              isTrialExpired,
                          )
                        : '/numbers'
                }
            />
        </>
    );
};

export default Numbers;
