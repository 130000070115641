import { useContext, useDebugValue } from 'react';

import {
    ServicesDashboardContext,
    ServicesDashboardDispatchContext,
} from './ServicesDashboardContext';

export const useServicesDashboard = () => {
    const context = useContext(ServicesDashboardContext);
    useDebugValue(context, 'ServicesDashboardContext');
    if (context === undefined) {
        throw new Error(
            'useServicesDashboard must be used within a ServicesDashboardProvider',
        );
    }

    return context;
};

export const useServicesDashboardDispatch = () => {
    const context = useContext(ServicesDashboardDispatchContext);
    useDebugValue(context, 'ServicesDashboardDispatchContext');
    if (context === undefined) {
        throw new Error(
            'useServicesDashboardDispatch must be used within a ServicesDashboardDispatchProvider',
        );
    }

    return context;
};
