import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

const ApplyTheme = ({ theme, children }) => {
    //Update the CSS Variables
    const updateCSSVariables = (theme) => {
        const arrayOfVariableKeys = Object.keys(theme.theme);
        const arrayOfVariableValues = Object.values(theme.theme);

        //Loop through each array key and set the CSS Variables
        arrayOfVariableKeys.forEach((cssVariableKey, index) => {
            //Based on our snippet from MDN
            document.documentElement.style.setProperty(
                cssVariableKey,
                arrayOfVariableValues[index],
            );
        });
    };

    //On Component Mount and Component Update
    useEffect(() => {
        updateCSSVariables(theme);
    }, [theme]);

    return <Fragment>{children}</Fragment>;
};

//Connect State To Props
const mapStateToProps = (state) => ({
    theme: state,
});
const connectedApplyTheme = connect(mapStateToProps)(ApplyTheme);

export default connectedApplyTheme;
