import config from '../config.json';

export default async (result, dispatch) => {
    /**
     * Defaults.
     */
    const {
        defaultCompanyID,
        defaultPartnerID,
        defaultWholesalerID,
        hostPartnerID,
        hostWholesalerID,
        permissionSet: { permissions, requiredScope },
    } = result.data;

    dispatch({
        type: 'CHANGE_WHOLESALERS_LOADING',
        payload: true,
    });
    dispatch({
        type: 'INIT_DEFAULT_ENTITIES',
        payload: {
            defaultCompany: hostPartnerID || defaultCompanyID,
            defaultPartner: hostPartnerID || defaultPartnerID,
            defaultWholesaler: hostWholesalerID || defaultWholesalerID,
            originPartner: defaultPartnerID,
        },
    });
    dispatch({
        type: 'CHANGE_CURRENT_PARTNER',
        payload: hostPartnerID || defaultPartnerID,
    });
    dispatch({
        type: 'CHANGE_CURRENT_COMPANY',
        payload: hostPartnerID || defaultCompanyID,
    });
    dispatch({
        type: 'CHANGE_CURRENT_WHOLESALER',
        payload: hostWholesalerID || defaultWholesalerID,
    });

    /**
     * Wholesaler and GA setup.
     */
    // if (requiredScope >= 60) {
    //     axios
    //         .get(
    //             requiredScope === 60
    //                 ? // Get all the partners under this wholesaler (Wholesaler)
    //                   `/partners/${defaultWholesalerID}`
    //                 : // Get all partners (GA)
    //                   `/partners/all`,
    //         )
    //         .then((partnerRes) => {
    //             // Find the corresponding partner
    //             const partner = partnerRes.data?.find(
    //                 (v) => v.hostHeader === window.location.host,
    //                 // (v) => v.hostHeader === 'teamsdev.etherealblaze.com',
    //             );

    //             // If we matched a partner, set all the defaults
    //             if (partner) {
    //                 dispatch({
    //                     type: 'LOGIN_SUCCESS',
    //                     payload: {
    //                         defaultCompany: partner?.id,
    //                         defaultPartner: partner?.id,
    //                         defaultWholesaler: partner?.wholesalerID,
    //                         originPartner: defaultPartnerID,
    //                     },
    //                 });
    //                 dispatch({
    //                     type: 'CHANGE_CURRENT_PARTNER',
    //                     payload: partner?.id,
    //                 });
    //                 dispatch({
    //                     type: 'CHANGE_CURRENT_COMPANY',
    //                     payload: partner?.id,
    //                 });
    //                 dispatch({
    //                     type: 'CHANGE_CURRENT_WHOLESALER',
    //                     payload: partner?.wholesalerID,
    //                 });
    //             } else {
    //                 //login dispatch with defaults and currents
    //                 dispatch({
    //                     type: 'LOGIN_SUCCESS',
    //                     payload: {
    //                         defaultCompany: defaultCompanyID,
    //                         defaultPartner: defaultPartnerID,
    //                         defaultWholesaler: defaultWholesalerID,
    //                         originPartner: defaultPartnerID,
    //                     },
    //                 });
    //                 dispatch({
    //                     type: 'CHANGE_CURRENT_PARTNER',
    //                     payload: defaultPartnerID,
    //                 });
    //                 dispatch({
    //                     type: 'CHANGE_CURRENT_COMPANY',
    //                     payload: defaultCompanyID,
    //                 });
    //                 dispatch({
    //                     type: 'CHANGE_CURRENT_WHOLESALER',
    //                     payload: defaultWholesalerID,
    //                 });
    //             }
    //         })
    //         .catch((e) => {
    //             console.log({e});
    //         });
    // } else {
    //     /**
    //      *
    //      */
    //     //login dispatch with defaults and currents
    //     dispatch({
    //         type: 'LOGIN_SUCCESS',
    //         payload: {
    //             defaultCompany: defaultCompanyID,
    //             defaultPartner: defaultPartnerID,
    //             defaultWholesaler: defaultWholesalerID,
    //             originPartner: defaultPartnerID,
    //         },
    //     });
    //     dispatch({
    //         type: 'CHANGE_CURRENT_PARTNER',
    //         payload: defaultPartnerID,
    //     });
    //     dispatch({
    //         type: 'CHANGE_CURRENT_COMPANY',
    //         payload: defaultCompanyID,
    //     });
    //     dispatch({
    //         type: 'CHANGE_CURRENT_WHOLESALER',
    //         payload: defaultWholesalerID,
    //     });
    // }

    /**
     * Permissions.
     */

    //checking that permissions have scope
    const unauthorised = permissions
        .filter((v) => v.read === false)
        .map(
            (v) =>
                '/' +
                config.scope.menu[v.scope].name +
                '/' +
                v.name.toLowerCase(),
        );

    //reducing access lists to current scopes
    const accessList = permissions
        // gets those that could be read
        .filter((v) => v.read === true)
        .reduce(
            //group by the scope. so we expect 4 arrays at the end as we have 4 diff scopes
            (result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue['scope']] =
                    result[currentValue['scope']] || []).push({
                    ...currentValue,
                    name: currentValue.name?.toLowerCase(),
                });
                return result;
            },
            {}, // empty object is the initial value for result object
        );

    //set accessList & redirect
    dispatch({
        type: 'MENU_UNAUTHORISED_SUCCESS',
        payload: unauthorised,
    });
    dispatch({
        type: 'CHANGE_WHOLESALERS_LOADING',
        payload: true,
    });

    //TODO: FIND out what this is
    setTimeout(() => {
        dispatch({
            type: 'ACCESS_LIST_SUCCESS',
            payload: accessList,
        });
        dispatch({
            type: 'CHANGE_REQUIRED_SCOPE',
            payload: requiredScope,
        });
        dispatch({
            type: 'REDIRECT',
            payload: { pathname: '/', scope: 20 },
        });
    }, 200);
};
