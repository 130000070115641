import React, { createContext, useState, useContext } from 'react';

const initialState = {
    microsoft: false,
    cisco: false,
    zoom: false,
    google: false,
    other: false,

    fixed: false,
    mobile: false,
    audio: false,
    ccaas: false,

    security: false,
    compliance: false,
    business: false,
    management: false,
};

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [filterState, setFilterState] = useState(initialState);
    let noFilters = Object.values(filterState).every(
        (value) => value === false,
    );

    const changeCheckbox = (value) => {
        setFilterState((prevState) => ({
            ...prevState,
            [value]: !prevState[value],
        }));
    };

    const clearFilter = () => {
        setFilterState(initialState);
    };

    return (
        <FilterContext.Provider
            value={{ filterState, changeCheckbox, clearFilter, noFilters }}>
            {children}
        </FilterContext.Provider>
    );
};
