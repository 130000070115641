import { useMemo } from 'react';

import MaterialTable from 'components/v4/MaterialTable';
import { loadingColumnsConstructor } from 'components/tables/ReduxTable';

/**
 * @typedef {Object} SkeletonTableProps
 * @property {string | React.Component<any>} title - Title of the table, include heading and buttons
 * @property {import('material-table').Column<any>[]} columns 
 * @param {SkeletonTableProps} props
 * @returns 
 */
export const SkeletonTable = ({ title, columns }) => {
    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(columns),
        [columns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;

    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    return (
        <MaterialTable
            title={title}
            data={dummyTable}
            columns={loadingColumns}
            refreshFn={() => {}}
        />
    );
};
