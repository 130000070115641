import { ACTIONS } from './constants';

export const reducerFn = (state, action) => {
    switch (action.type) {
        case ACTIONS.FETCH:
            return action.payload;

        case ACTIONS.ADDED: {
            const { name, id, description, members } = action.payload;
            return [
                ...state,
                {
                    name,
                    id,
                    description,
                    members,
                },
            ];
        }

        case ACTIONS.UPDATED: {
            const { vg: vGroup } = action.payload;
            return state.map((vg) => (vg.id === vGroup.id ? vGroup : vg));
        }

        case ACTIONS.DELETED: {
            return state.filter((vg) => vg.id !== action.payload.id);
        }

        case ACTIONS.CLEARED:
            return [];

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
};
