import {
    Box,
    ChakraProvider,
    FormErrorMessage,
    FormHelperText,
    Skeleton,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useNationalCode } from './useNationalCode';
import { useMultiNationalCode } from './useMultiNationalCode';
import { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import {
    Alert,
    AlertIcon,
    Button,
    FormControl,
    FormLabel,
    Input,
} from 'components/v4';
import { ChipList } from 'components/v4/ChipInput/ChipList';
import ChipInput from 'components/v4/ChipInput/ChipInput';
import { toast } from 'react-toastify';
import { axios } from '@/services/axios';
import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import { DeleteBox } from 'components/index';

const EditCountry = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { theme } = useDeepMerge();
    const { darkMode } = useSelector((state) => state.settings);

    const tabList = ['General', 'Admin'];
    const [tabIndex, setTabIndex] = useState(0);

    const { requiredScope } = useSelector((state) => state.login);

    useEffect(() => {
        if (requiredScope != 80) {
            // get parent menu item Dashboards
            let dashboard = document.querySelector('.Dashboards');
            // if only 1 then the menu item is closed
            if (dashboard?.children?.length == 1) {
                var divElement = dashboard.querySelector('div');
                // create on click event
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                divElement.dispatchEvent(clickEvent);
            }

            // redirect is to select the sub menu item
            // scope 20 to focus on currently managing company
            dispatch({
                type: 'REDIRECT',
                payload: {
                    pathname: '//dashboardServices',
                    scope: 20,
                },
            });

            navigate('/dashboard-services');
        }
    }, [requiredScope]);

    const {
        nationalCodes,
        setNationalCodes,
        nationalCodeInput,
        nationalCodeError,
        handleNationalCodesChange,
        handleNationalCodesKeyDown,
        handleNationalCodesPaste,
        handleNationalCodesCloseClick,
    } = useNationalCode();

    const {
        multiNationalCodes,
        setMultiNationalCodes,
        multiNationalCodeInput,
        multiNationalCodeError,
        handleMultiNationalCodesChange,
        handleMultiNationalCodesKeyDown,
        handleMultiNationalCodesPaste,
        handleMultiNationalCodesCloseClick,
    } = useMultiNationalCode();

    const initialState = {
        name: '',
        countryCode: '',
        alpha2: '',
        alpha3: '',
        nationalCodes: [],
        multiNationalCodes: [],
    };
    const [countryState, setCountryState] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isBusy, setIsBusy] = useState(false);

    const handleChange = (key, e) => {
        setCountryState((prevState) => ({
            ...prevState,
            [key]: e.target.value,
        }));
    };

    useEffect(async () => {
        if (id) {
            try {
                await new Promise((resolve) => setTimeout(resolve, 2000));
                const res = await axios.get('/country/' + id);
                setCountryState(() => ({
                    ...res.data,
                }));
                setNationalCodes((prevState) => [
                    ...prevState,
                    ...(res.data?.nationalCodes[0] === ''
                        ? []
                        : res.data?.nationalCodes),
                ]);
                setMultiNationalCodes((prevState) => [
                    ...prevState,
                    ...(res.data?.multiNationalCodes[0] === ''
                        ? []
                        : res.data?.multiNationalCodes),
                ]);
            } catch (e) {
                toast.error('Unable to retrieve country. Please try again.');
                navigate('/countries');
            } finally {
                setIsLoading(false);
                setIsDisabled(false);
            }
        }
    }, [id]);

    const handleEdit = async () => {
        try {
            setIsBusy(true);
            const country = {
                ...countryState,
                nationalCodes: nationalCodes,
                multiNationalCodes: multiNationalCodes,
            };
            await axios.put('/country', country);
            toast.success('Country updated successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/countries');
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsBusy(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDisabled(true);
            setIsBusy(true);
            await axios.delete(`/country/${id}`);
            toast.success('Country deleted successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/countries');
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsDisabled(false);
            setIsBusy(false);
        }
    };

    return (
        <ChakraProvider resetCSS theme={theme}>
            <Container fluid>
                <Modal.Title> Edit Country </Modal.Title>
                <Tabs
                    isManual
                    index={tabIndex}
                    onChange={(index) => {
                        setTabIndex(index);
                    }}>
                    <TabList borderBottom={'none'} justifyContent="center">
                        {tabList.map((tab, index) => (
                            <Tab
                                isDisabled={isDisabled}
                                key={index}
                                onMouseDown={(e) =>
                                    e.button === 2 && e.preventDefault()
                                }
                                sx={customTabStylesCombineTheme}
                                _selected={{
                                    ...selectedTabStylesCombineTheme,
                                    textColor: darkMode
                                        ? 'rgba(255, 255, 255)'
                                        : 'inherit',
                                }}>
                                {tab}
                            </Tab>
                        ))}
                    </TabList>

                    <TabPanels padding={0}>
                        <TabPanel>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    paddingTop: '1%',
                                    paddingBlock: '1%',
                                }}
                                className="mx-auto min-w-[36rem] max-w-4xl">
                                <Alert mb={3} status="info">
                                    <AlertIcon />
                                    National and Multi-National codes are ONLY
                                    used when a Country Code is shared across
                                    multiple countries.
                                </Alert>
                                <Stack
                                    direction={['column', 'row']}
                                    spacing={'24px'}>
                                    <FormControl isRequired mb={2}>
                                        <FormLabel>Name</FormLabel>
                                        {isLoading ? (
                                            <Skeleton
                                                h={'40px'}
                                                borderRadius={'0.375rem'}
                                            />
                                        ) : (
                                            <Input
                                                value={countryState.name}
                                                isDisabled={isBusy}
                                                onChange={(e) =>
                                                    handleChange('name', e)
                                                }
                                                onKeyPress={(event) => {
                                                    if (
                                                        !/^[a-zA-Z\s\-]+$/.test(
                                                            event.key,
                                                        )
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                placeholder="Please enter a Name"
                                            />
                                        )}
                                        <FormHelperText>
                                            eg. United States
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl isRequired mb={2}>
                                        <FormLabel>Country Code</FormLabel>
                                        {isLoading ? (
                                            <Skeleton
                                                h={'40px'}
                                                borderRadius={'0.375rem'}
                                            />
                                        ) : (
                                            <Input
                                                value={countryState.countryCode}
                                                isDisabled={isBusy}
                                                onChange={(e) =>
                                                    handleChange(
                                                        'countryCode',
                                                        e,
                                                    )
                                                }
                                                onKeyPress={(event) => {
                                                    if (
                                                        !/^[0-9]$/.test(
                                                            event.key,
                                                        )
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                placeholder="Please enter a Country Code"
                                            />
                                        )}
                                        <FormHelperText>eg. 1</FormHelperText>
                                    </FormControl>
                                </Stack>

                                <Stack
                                    direction={['column', 'row']}
                                    spacing={'24px'}>
                                    <FormControl isRequired mb={2}>
                                        <FormLabel>Alpha-2</FormLabel>
                                        {isLoading ? (
                                            <Skeleton
                                                h={'40px'}
                                                borderRadius={'0.375rem'}
                                            />
                                        ) : (
                                            <Input
                                                value={countryState.alpha2}
                                                isDisabled={isBusy}
                                                onKeyPress={(event) => {
                                                    const currentInput =
                                                        event.target.value +
                                                        event.key;
                                                    if (
                                                        !/^[A-Z]$/.test(
                                                            event.key,
                                                        ) ||
                                                        currentInput.length > 2
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    handleChange('alpha2', e)
                                                }
                                                placeholder="Please enter a Alpha-2"
                                            />
                                        )}
                                        <FormHelperText>eg. US</FormHelperText>
                                    </FormControl>

                                    <FormControl isRequired mb={2}>
                                        <FormLabel>Alpha-3</FormLabel>
                                        {isLoading ? (
                                            <Skeleton
                                                h={'40px'}
                                                borderRadius={'0.375rem'}
                                            />
                                        ) : (
                                            <Input
                                                value={countryState.alpha3}
                                                onChange={(e) =>
                                                    handleChange('alpha3', e)
                                                }
                                                isDisabled={isBusy}
                                                onKeyPress={(event) => {
                                                    const currentInput =
                                                        event.target.value +
                                                        event.key;
                                                    if (
                                                        !/^[A-Z]$/.test(
                                                            event.key,
                                                        ) ||
                                                        currentInput.length > 3
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                placeholder="Please enter a Alpha-3"
                                            />
                                        )}
                                        <FormHelperText>eg. USA</FormHelperText>
                                    </FormControl>
                                </Stack>

                                <FormControl
                                    mb={2}
                                    isInvalid={nationalCodeError.isError}>
                                    <FormLabel>National Codes</FormLabel>
                                    <ChipList
                                        inputs={nationalCodes}
                                        isDisabled={isBusy}
                                        onCloseClick={
                                            handleNationalCodesCloseClick
                                        }
                                    />
                                    {isLoading ? (
                                        <Skeleton
                                            h={'40px'}
                                            borderRadius={'0.375rem'}
                                        />
                                    ) : (
                                        <ChipInput
                                            placeholder="Please enter National Codes"
                                            isDisabled={isBusy}
                                            onPaste={handleNationalCodesPaste}
                                            onKeyPress={(event) => {
                                                if (
                                                    !/^[0-9]$/.test(event.key)
                                                ) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onKeyDown={
                                                handleNationalCodesKeyDown
                                            }
                                            onChange={handleNationalCodesChange}
                                            value={nationalCodeInput.trim()}
                                        />
                                    )}
                                    {!nationalCodeError.isError ? (
                                        <FormHelperText>
                                            eg. 325, 330, 518, 957
                                        </FormHelperText>
                                    ) : nationalCodeError.message ? (
                                        <FormErrorMessage>
                                            {nationalCodeError.message}
                                        </FormErrorMessage>
                                    ) : (
                                        <FormErrorMessage>
                                            {nationalCodeError.duplicates}
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl
                                    mb={2}
                                    isInvalid={multiNationalCodeError.isError}>
                                    <FormLabel>Multi-National Codes</FormLabel>
                                    <ChipList
                                        inputs={multiNationalCodes}
                                        isDisabled={isBusy}
                                        onCloseClick={
                                            handleMultiNationalCodesCloseClick
                                        }
                                    />
                                    {isLoading ? (
                                        <Skeleton
                                            h={'40px'}
                                            borderRadius={'0.375rem'}
                                        />
                                    ) : (
                                        <ChipInput
                                            placeholder="Please enter Multi-National Codes"
                                            onPaste={
                                                handleMultiNationalCodesPaste
                                            }
                                            isDisabled={isBusy}
                                            onKeyPress={(event) => {
                                                if (
                                                    !/^[0-9]$/.test(event.key)
                                                ) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onKeyDown={
                                                handleMultiNationalCodesKeyDown
                                            }
                                            onChange={
                                                handleMultiNationalCodesChange
                                            }
                                            value={multiNationalCodeInput.trim()}
                                        />
                                    )}
                                    {!multiNationalCodeError.isError ? (
                                        <FormHelperText>
                                            eg. 800, 822
                                        </FormHelperText>
                                    ) : multiNationalCodeError.message ? (
                                        <FormErrorMessage>
                                            {multiNationalCodeError.message}
                                        </FormErrorMessage>
                                    ) : (
                                        <FormErrorMessage>
                                            {multiNationalCodeError.duplicates}
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                <Box
                                    display="flex"
                                    className="mt-3 justify-center">
                                    <Button
                                        variant="outline"
                                        bg="white"
                                        minW={['100%', '82px']}
                                        isDisabled={isDisabled || isBusy}
                                        onClick={() => {
                                            navigate('/countries');
                                        }}>
                                        Back
                                    </Button>

                                    <Button
                                        ml={4}
                                        isDisabled={isDisabled}
                                        isLoading={isBusy}
                                        onClick={() => handleEdit()}>
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </TabPanel>

                        <TabPanel>
                            <DeleteBox
                                onClick={handleDelete}
                                isLoading={isBusy}
                                isDisabled={isDisabled}
                                deleteText={'Delete Country'}
                                buttonText={'Delete'}
                                warningText={
                                    <>
                                        If you wish to delete a country, please
                                        ensure it is not in use by a
                                        number/address. <br /> After deleting,
                                        this country will no longer be serviced.
                                    </>
                                }
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </ChakraProvider>
    );
};

export default EditCountry;
