//external imports
import React from 'react';
import { useSelector } from 'react-redux';

//STEP 1:
//create components using React.lazy
const LightTheme = React.lazy(() => import('./Light'));
const DarkTheme = React.lazy(() => import('./Dark'));
const MixedTheme = React.lazy(() => import('./Mixed'));
const AuthTheme = React.lazy(() => import('./Auth'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
export default function Theme({ children }) {
    const { darkMode, mixedMode: lightMode } = useSelector((state) => {
        return { ...state.settings };
    });
    const { reLogin, loadAccessList, token } = useSelector((state) => {
        return { ...state.login };
    });

    //TODO: conditionally render in single block
    //if logging in => render auth theme.
    if (!token || reLogin || loadAccessList) {
        return (
            <>
                <React.Suspense fallback={<> </>}>
                    <AuthTheme />
                </React.Suspense>
                {children}
            </>
        );
    }
    //else render mode.
    return (
        <>
            <React.Suspense fallback={<> </>}>
                {!darkMode && <LightTheme />}
                {darkMode && <DarkTheme />}
                {lightMode && <MixedTheme />}
            </React.Suspense>
            {children}
        </>
    );
}
