export default {
    table: {
        ufAddBtn: 'table/toolbar/button/addBtn',
        ufSortBtn: 'table/toolbar/select/sortBtn',
        ufFilterBtn: 'table/toolbar/button/filterBtn',
        ufRefreshBtn: 'table/toolbar/button/refreshBtn',
        ufInvoiceBtn: 'table/toolbar/button/invoiceBtn',
        ufGroupBtn: 'table/toolbar/button/groupBtn',
        ufSearch: 'table/toolbar/input/search',
        ufViewColBtn: 'table/toolbar/button/viewColumnBtn',
        ufDefaultActions: 'table/toolbar/button/defaultActions',
        ufExportBtn: 'table/toolbar/button/exportBtn',
        ufCdrBtn: 'table/button/cdrBtn',
        ufEditBtn: 'table/button/editBtn',
        ufCopyBtn: 'table/button/copyBtn',
        ufViewBtn: 'table/button/viewBtn',
    },
    modal: {
        ufCopyInput: 'modal/input/copyAs',
        ufDeleteBtn: 'modal/button/delete',
        ufCopyBtn: 'modal/button/copy',
        ufBackBtn: 'modal/button/back',
        ufCloseBtn: 'modal/button/close',
        ufCreateBtn: 'modal/button/create',
        ufConvertBtn: 'modal/button/convert',
        ufSaveBtn: 'modal/button/saveChanges',
    },
};
