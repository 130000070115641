import { useEffect, useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Select as MultiSelect } from 'chakra-react-select';

import { Box, ChakraProvider, useToast } from '@chakra-ui/react';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import FooterContainer from 'pages/services/serviceprofiles/edit/FooterContainer';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
} from '@/components/v4';

// import VoiceRoutesTable from './VoiceRoutesTable';
import FieldValidation from 'components/v4/FieldValidation';

import { multiSelectDataMapper, toSentenceCase } from 'utils/utils';
import { classNames } from 'utils/classNames';
import useFieldValidation from 'hooks/useFieldValidation';

// API
import createACSService from 'API/ProvisionedServices/createACSService';
import updateACSService from 'API/ProvisionedServices/updateACSService';
import getService from 'API/Service/getService';
import getACSRegions from 'API/ProvisionedServices/getACSRegions';

import chakratheme from '@/chakratheme';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { Container } from 'components/v4/Container/Container';

const walkthroughIds = {
    ufRegionsSelect: '/service-profiles/acs/select/regions',
    ufMaxChannel: '/service-profiles/acs/input/max-channel',
    ufCloseButton: '/service-profiles/acs/button/close',
    ufAddButton: '/service-profiles/acs/button/add',
};

const validationSchemaACS = {
    regions: [
        { type: 'isArrayEmpty', message: 'Please select at least one region.' },
    ],
    maxChannel: [
        {
            type: 'isAboveEQ',
            message: 'Channel Count needs at least 2 channels.',
            conditions: [2],
        },
    ],
};

/**
 * Azure Service Profile page
 * @param {Object} props
 * @param {'ADD' | 'EDIT'} props.mode - decide whether to add or edit an ACS service
 * @returns
 */
export default function EditAzureService({ mode = 'ADD' }) {
    const navigate = useNavigate();
    const toast = useToast();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const [regionsEnum, setRegionsEnum] = useState([]);

    const companyID = useSelector(
        (state) => state.navigationLists.currentCompany,
    );
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    // const [searchParams] = useSearchParams();
    const { id: dataID } = useParams();

    // const dataID = searchParams.get('id');

    const [modalState, setModalState] = useState({
        maxChannel: 2,
        regions: [],
        voiceRoutes: [],
        companyID,
        name: 'ACS Direct Routing',
    });

    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaACS,
    );

    const handleGetDropdown = () => {
        setIsLoading(true);
        const fetchData = mode === 'EDIT' && dataID;
        Promise.all([
            fetchData
                ? getService(dataID)
                : setTimeout(() => {
                      new Promise((resolve, reject) => {
                          resolve();
                      });
                  }, 1),
            getACSRegions(companyID),
        ])
            .then(([serviceResult, regionsResult]) => {
                setRegionsEnum(regionsResult.data.map(multiSelectDataMapper));
                if (fetchData) {
                    const regions = serviceResult.data.regions.map(
                        multiSelectDataMapper,
                    );

                    setModalState({
                        ...serviceResult.data,
                        regions,
                    });
                }
            })
            .catch((e) => {
                console.log('error!', { e });
                setError(e);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            });
    };

    useEffect(() => {
        handleGetDropdown();
    }, [mode, dataID]);

    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleBackButton = () => {
        navigate('/dashboard-services');
    };

    const handleSubmit = () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }
        setIsLoading(true);
        let result = null;
        if (mode === 'EDIT') {
            result = updateACSService(modalState);
        } else {
            result = createACSService(modalState);
        }
        result
            .then((res) => {
                toast({
                    title: 'Success!',
                    description: `ACS Service has been ${mode === 'EDIT' ? 'updated' : 'created'}.`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                handleBackButton();
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    if (!dataID && mode === 'EDIT') {
        return <Navigate to="/dashboard-services" />;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <Heading className="mb-4 text-center" as="h2" fontSize="3xl">
                {`${toSentenceCase(mode)}`} ACS Service{' '}
            </Heading>
            <Container py={0}>
                {error && (
                    <Alert status="error" sx={{ marginTop: '1rem' }}>
                        <AlertIcon />
                        <AlertDescription>
                            {error?.response?.data?.message ||
                                'An error occured. Please try again later.'}
                        </AlertDescription>
                    </Alert>
                )}
                <Box data-walkthroughid={walkthroughIds.ufRegionsSelect}>
                    <FormLabel>Regions</FormLabel>
                    <MultiSelect
                        isMulti
                        placeholder="- Select Regions -"
                        closeMenuOnSelect={false}
                        tagVariant="solid"
                        onChange={(selectedOptions) => {
                            handleChangeState({
                                regions: selectedOptions,
                            });
                        }}
                        options={regionsEnum}
                        defaultValue={modalState.regions}
                        chakraStyles={chakraStyles}
                    />
                    <FieldValidation errors={validationErrors?.regions} />
                </Box>

                <FormControl className="my-4">
                    <FormLabel>Channel Count</FormLabel>
                    <Input
                        value={modalState.maxChannel}
                        // max={maxChannelMax}
                        // type="number"
                        // min={0}
                        onChange={(e) => {
                            ((Number(e.target.value) && e.target.value >= 0) ||
                                e.target.value.length === 0) &&
                                setModalState((p) => ({
                                    ...p,
                                    maxChannel: e.target.value,
                                }));
                        }}
                        data-walkthroughid={walkthroughIds.ufMaxChannel}
                    />
                    <FieldValidation errors={validationErrors?.maxChannel} />
                </FormControl>

                <Box mb="52px" aria-label="margin-box" />
                {/* <VoiceRoutesTable /> */}
                <FooterContainer>
                    <Button
                        variant="outline"
                        w={{ base: 'full', md: '80px' }}
                        className={classNames(
                            darkMode ? 'bg-white hover:opacity-70' : null,
                        )}
                        onClick={handleBackButton}
                        data-walkthroughid={walkthroughIds.ufCloseButton}>
                        Close
                    </Button>
                    <Button
                        w={{ base: 'full', md: '80px' }}
                        onClick={handleSubmit}
                        data-walkthroughid={walkthroughIds.ufAddButton}>
                        {mode === 'ADD' ? 'Add' : 'Update'}
                    </Button>
                </FooterContainer>
            </Container>
        </ChakraProvider>
    );
}
