import MaterialTable from '../../../../components/v4/MaterialTable';
import React from 'react';
import {Badge, Box, Divider} from '@chakra-ui/react';
import Heading from '../../../../components/v4/Heading';
import {
    CloseIcon,
    ArrowBackIcon,
    CheckIcon,
    DownloadIcon,
    MinusIcon,
} from '@chakra-ui/icons';
import Button from '../../../../components/v4/Button';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import {IGNORED_MESSAGE} from './useCSVUsers';

function useVerifyCSVTableView({
    verificationResult,
    passedColumns,
    failedColumns,
}) {
    const showFailedData = verificationResult?.failed?.data?.length > 0;
    return {
        validationHeading: 'CSV Validation',
        validationPassedHeading: 'Passed',
        validationFailedHeading: 'Failed',
        maxBodyHeight: showFailedData ? 350 : 400,
        cancelButtonText: 'Cancel',
        warningText: 'This cannot be undone.',
        importButtonText: 'Import',
        ignoredHeading: 'Ignored',
        startOverButtonText: 'Start Over',
        showFailedData,
        isImportDisabled: verificationResult?.passed?.data?.every(
            (v) => v.verificationStatus === IGNORED_MESSAGE,
        ),
    };
}

const styles = {
    mainContainer: {display: 'block'},
    validationPassedContainer: {
        marginBottom: 10,
        width: '100%',
    },
    validationFailedContainer: {
        width: '100%',
    },
    actionLabelContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
    },
};

export default function VerifyCSVTableView(props) {
    const {
        verificationResult,
        handleCancel,
        handleImport,
        handleExportInvalidData,
        handleStartOver,
        passedColumns,
        failedColumns,
    } = props;
    const {
        validationPassedHeading,
        validationFailedHeading,
        validationHeading,
        maxBodyHeight,
        cancelButtonText,
        importButtonText,
        isImportDisabled,
        warningText,
        ignoredHeading,
        startOverButtonText,
        showFailedData,
    } = useVerifyCSVTableView({verificationResult});

    return (
        <Box sx={styles.mainContainer}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Heading size="xl">{validationHeading}</Heading>
            </Box>
            {!isImportDisabled && (
                <>
                    <Box sx={styles.validationPassedContainer}>
                        <MaterialTable
                            title={
                                <Heading size="md">
                                    {validationPassedHeading}
                                    <CheckIcon
                                        color="green.500"
                                        boxSize="1rem"
                                        ml={3}
                                    />
                                </Heading>
                            }
                            columns={passedColumns}
                            data={verificationResult.passed.data}
                            options={{
                                maxBodyHeight,
                            }}
                        />
                    </Box>
                    <Box mt={5} sx={styles.actionContainer}>
                        <Button
                            rightIcon={<CloseIcon boxSize={3} />}
                            colorScheme="red"
                            onClick={handleCancel}>
                            {cancelButtonText}
                        </Button>

                        <Button
                            ml={2}
                            isDisabled={isImportDisabled}
                            rightIcon={<DownloadIcon />}
                            onClick={handleImport}>
                            {importButtonText}
                        </Button>
                    </Box>
                    <Box mt={5} sx={styles.actionLabelContainer}>
                        <Badge colorScheme="red">{warningText}</Badge>
                    </Box>
                    {showFailedData && (
                        <Divider mt={10} border={'1px'} opacity={1} />
                    )}
                </>
            )}
            {showFailedData && (
                <Box sx={styles.validationFailedContainer}>
                    <MaterialTable
                        title={
                            <Heading size="md">
                                {validationFailedHeading}
                                <CloseIcon
                                    color="red.500"
                                    boxSize="1rem"
                                    ml={3}
                                />
                            </Heading>
                        }
                        columns={failedColumns}
                        data={verificationResult.failed.data}
                        actions={[
                            {
                                icon: DownloadRoundedIcon,
                                tooltip: 'Export to CSV',
                                isFreeAction: true,
                                onClick: () => handleExportInvalidData(),
                            },
                        ]}
                        options={{
                            maxBodyHeight,
                            exportButton: false,
                        }}
                    />
                </Box>
            )}
            {isImportDisabled && (
                <>
                    <Box sx={styles.validationPassedContainer}>
                        <MaterialTable
                            title={
                                <Heading size="md">
                                    {ignoredHeading}
                                    <MinusIcon boxSize="1rem" ml={3} />
                                </Heading>
                            }
                            columns={passedColumns}
                            data={verificationResult.passed.data}
                            options={{
                                maxBodyHeight,
                            }}
                        />
                    </Box>
                    <Box mt={5} display={'flex'} justifyContent={'left'}>
                        <Button
                            leftIcon={<ArrowBackIcon boxSize={3} />}
                            onClick={handleStartOver}>
                            {startOverButtonText}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
