import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { axios } from '../services/axios';
import { Container, Modal, Button, ProgressBar } from 'react-bootstrap';
import walkthroughIds from './walkthroughIds';

/*  runs background task on main thread. */
export default function (props) {
    const dispatch = useDispatch();

    const {
        // totalNumberOfPages,
        loading,
        attribute,
        visibility,
        limit,
        tableData,
        fetchURL,
        total,
    } = useSelector((state) => {
        return { ...state.webWorker };
    });

    const { modal } = walkthroughIds;

    const getFirstData = () => {
        axios
            .get(fetchURL, {
                params: {
                    page: 0,
                    limit,
                },
            })
            .then((res) => {
                const { total, limit, [attribute]: newTableData } = res.data;
                // state = {...state, totalNumberOfPages:  Math.ceil(res.data.total / state.limit), total, tableData     }
                dispatch({
                    type: 'CHANGE_WEB_WORKER_STATE',
                    payload: { total },
                });
                dispatch({
                    type: 'ADD_WEB_WORKER_TABLE_DATA',
                    payload: newTableData,
                });
                getSubsequentData({
                    totalNumberOfPages: Math.ceil(res.data.total / limit),
                });
            })
            .catch(handleError);
    };
    const handleSubsequentResponses = (res, counter) => {
        const failed =
            res
                .filter((v) => v.status === 'rejected')
                .map((v) => {
                    return v.reason.config;
                }) ?? [];

        dispatch({
            type: 'ADD_WEB_WORKER_TABLE_DATA',
            payload: res
                .filter((v) => v.status !== 'rejected')
                .reduce((res, currentVal) => {
                    return [...res, ...currentVal.value.data[attribute]];
                }, []),
        });

        if (failed.length > 0) {
            //retry
            retry(failed, counter);

            return;
        }

        onSuccess();
    };
    const handleError = (error) => {
        dispatch({
            type: 'CHANGE_WEB_WORKER_STATE',
            payload: { error },
        });
    };

    const getSubsequentData = ({ totalNumberOfPages }) => {
        var responseObjects = [];
        for (var i = 1; i < totalNumberOfPages; i++) {
            responseObjects.push(
                axios.get(fetchURL, {
                    params: {
                        page: i,
                        limit,
                    },
                    // headers: {
                    //     common: {
                    //         [config.scope.name]: currentScope,
                    //         authorization: `bearer ${token}`
                    //     }
                    // }
                }),
            );
        }
        Promise.allSettled(responseObjects)
            .then((res) => handleSubsequentResponses(res, 0))
            .catch(handleError);
        // //TODO: might want to sort data!

        // dispatch({
        //     type: 'CHANGE_WEB_WORKER_STATE', payload:
        //         { tableData: allResponses.concat(tableData), loading: false , visibility: 'hidden'}
        // })
    };

    const retry = (failed, counter) => {
        if (counter < 3) {
            //max retry 3 times

            Promise.allSettled(failed.map((v) => axios(v)))
                .then((res) => handleSubsequentResponses(res, counter + 1))
                .catch(handleError);
        } else {
            dispatch({
                type: 'CHANGE_WEB_WORKER_STATE',
                payload: { error: true },
            });
        }
    };
    const onHide = () => {
        dispatch({
            type: 'RESET_WEB_WORKER_STATE',
        });
        dispatch({
            type: 'RESET_TABLE',
        });
    };
    const onSuccess = () => {
        dispatch({
            type: 'CHANGE_TABLE_STATE_WEB_WORKER',
        });
        dispatch({
            type: 'CHANGE_TABLE_LOADING',
            payload: false,
        });
        setTimeout(() => {
            dispatch({
                type: 'CHANGE_WEB_WORKER_STATE',
                payload: { visibility: false, loading: false },
            });
        }, 500);
    };
    useEffect(() => {
        if (loading) {
            getFirstData();
        }
    }, [loading, fetchURL]);

    //MODAL that is displayed on call stats loading page - refactor into a readable file
    return (
        <Modal show={visibility} onHide={onHide} size={'lg'} animation={false}>
            <Modal.Header closeButton style={{ border: 'none' }}>
                <Modal.Title> Loading... </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <ProgressBar
                        animated
                        striped
                        now={tableData.length}
                        max={total}
                        label={
                            total > 0
                                ? Math.round((tableData.length / total) * 100) +
                                  '%'
                                : '0%'
                        }
                        data-walkthroughid={modal.ufModalProgress}
                    />

                    <Modal.Footer
                        style={{
                            border: 'none',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="secondary"
                            onClick={onHide}
                            data-walkthroughid={modal.ufModalCancelBtn}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Container>
            </Modal.Body>
        </Modal>
    );
}
