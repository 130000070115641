import { AddIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

import { Button } from 'components/v4';

function AssociationButton({ id, setState, initialState }) {
    return (
        <Link to={`/numbers/tpm/${id}/activation`}>
            <Button
                onClick={() => {
                    setState(initialState);
                }}
                leftIcon={<AddIcon />}>
                Association
            </Button>
        </Link>
    );
}

export default AssociationButton;
