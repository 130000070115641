export const maxRedundancyOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
];

export const toastSuccess = {
    title: 'Service updated successfully',
    status: 'success',
    duration: 3000,
    isClosable: true,
    description: `SBCaaS has been updated!`,
};
