import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { MenuItem, FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import ErrorBoundary from '@/components/maintenance/ErrorBoundary';
import { axios } from '@/services/axios';
import config from '@/config.json';
import { customAutoCompleteStyle } from 'pages/navbar/constant';

// NOTE: copy of LoadingFieldDropdown component, otherwise TCAP will break
const disabledInputStyle = {
    '& input:disabled': {
        cursor: 'not-allowed',
    },
};

export const findCurrentValue = ({
    options,
    displayField,
    dropDownValue,
    currentValue,
    noneFieldDisplay,
    onDisplayDropdown,
}) => {
    if (!options || !displayField || !dropDownValue) {
        return null;
    }
    var res = options?.find((v) => {
        return v[dropDownValue] === currentValue;
    });

    if (!res) {
        return noneFieldDisplay || config.notAllocated.dropdown;
    }
    return (
        (onDisplayDropdown && onDisplayDropdown(res)) ||
        res[displayField] ||
        res ||
        'ERROR:Not Found!!!!'
    );
};

// Specific for 'Company Managing' field on drop down
// Labels company and partner for group by function of autocomplete
export const relabel = (companies) => {
    var res = [];
    let parentName = '';
    let parentID = '';
    for (const company of companies) {
        if (company.parent == null || company.parent.length === 0) {
            parentName = company.name;
            parentID = company.id;
            res.push({
                id: company.id,
                name: company.name,
                parentID: '',
                parentName: '',
            });
        } else {
            res.push({
                id: company.id,
                name: company.name,
                parentID,
                parentName,
            });
        }
    }
    return res;
};

export const groupBy = (option) => {
    if (option.parentName.length === 0) {
        return option.name;
    }
    return option.parentName;
};

const getOptionSelectedID = (option, v) => {
    return option.id === v.id;
};

export const getOptionSelected = (option, value) => {
    if (typeof value === 'string') {
        return value === option.id;
    }
    return option.name === value.name;
};

export const getOptionLabel = (option) => option.name;

/*
 * SearchBox component used by multi selects, auto completes and drop downs that require search functionality
 */
export function SearchBox(props) {
    const [open, setOpen] = useState(false);
    const {
        placeholder,
        defaultValue,
        loading,
        onChange,
        message,
        onFocus,
        label,
        displayField,
        dropDownValue,
        onDisplayDropdown,
        options,
        noneFieldDisplay,
        helperText,
        errorText,
        groupByProps,
        endAdornment,
        renderOption,
        getOptionSelected: getOptionSelectedProps,
        getOptionLabel: getOptionLabelProps,
        dataWalkthroughid,
        inputValue,
    } = props;

    const { colorScheme } = useSelector((state) => state.settings);

    const findCurrentName = () => {
        var res = options.find((v) => {
            return v.id === defaultValue;
        });
        var name = res?.name;
        if (res?.parentName) {
            name = res?.name + ' Under: ' + res?.parentName;
        }
        return name;
    };

    // For each option you pass in, it must not be null, hence the error boundary
    return (
        <ErrorBoundary>
            <Box sx={customAutoCompleteStyle(colorScheme)}>
                <Autocomplete
                    data-walkthroughid={dataWalkthroughid}
                    {...props}
                    open={open}
                    clearOnBlur={false}
                    defaultValue={defaultValue}
                    onFocus={onFocus}
                    value={defaultValue}
                    inputValue={inputValue}
                    renderOption={renderOption}
                    placeholder={placeholder}
                    onChange={(e, value) => {
                        //ASSUMPTION :ONLY USED WHEN THERE'S GROUP BY?
                        if (value != null) {
                            //CHANGE!
                            const foundItem = options.filter(
                                (company) =>
                                    company?.[dropDownValue ?? 'id'] ===
                                    value?.[dropDownValue ?? 'id'],
                            );
                            onChange(foundItem[0]);
                        }
                    }}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    groupBy={groupByProps}
                    getOptionSelected={
                        getOptionSelectedProps || getOptionSelectedID
                    }
                    getOptionLabel={getOptionLabelProps || getOptionLabel}
                    options={options}
                    loading={loading}
                    disableClearable
                    renderInput={(params) => {
                        return (
                            <TextField
                                label={label}
                                {...params}
                                helperText={errorText || helperText}
                                error={Boolean(errorText)}
                                placeholder={
                                    placeholder ||
                                    findCurrentValue({
                                        options,
                                        displayField,
                                        dropDownValue,
                                        currentValue: defaultValue,
                                        message,
                                        onDisplayDropdown,
                                        noneFieldDisplay,
                                    }) ||
                                    findCurrentName()
                                }
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress size={20} />
                                            ) : null}
                                            {endAdornment}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        );
                    }}
                    popupIcon={<ChevronDownIcon boxSize={5} mt="2px" />}
                />
            </Box>
        </ErrorBoundary>
    );
}

/*
 * Component used for drop down fields in the UI that require a series of options
 * those options can be gathered from a static list, fetched from the API when the component renders etc
 * This component also changes state so that modal forms can submit data using the value from the drop down
 */
const LoadingFieldDropdownWithPlaceholder = ({
    // this prop stops the component from fetching until another field/value has data
    dependency,
    // if there is no field display or we want a different default display then this value is shown
    noneFieldDisplay,
    // determines when the data will be fetched, if init is true it will be fetched when its loaded
    init,
    // function that triggers when the display drop down is shown
    onDisplayDropdown,
    // value behind the noneFieldDisplay as all values have a different display
    noneFieldValue,
    // prop to determine if its not a modal component
    notModalComponent,
    // instead of a api fetch the component can be fed a static list to use
    staticEnum,
    // the onChange function to be passed into the material component of this
    onChange,
    //
    noneField,
    // a function that can be passed in to represent a error, usually the onError from TableAndModal.jsx
    onError,
    // prop to pass through to sub componennts
    toggleLimit,
    // prop to pass through to sub componennts
    loadDependencies,
    // boolean to determine whether its searchable or not
    searchable,
    // the URL for fetching the data to populate the list
    fieldFetch,
    // label for the component as its a MUI textfield/searchbox
    fieldName,
    // used to determine the type of the value in the drop down, strint, int, bool etc
    fieldValue,
    // the value that is used for the state setting when selecting a value
    dropDownValue,
    // the field that is actually displayed in the drop down
    displayField,
    // props for the drop down list
    dropdownProps,
    // makes it so there is no empty option when the field first loads the drop down data
    noEmptyOption,
    // a function that triggers when the fetch is sucessful
    onFieldFetch,
    // determins if the component has dependencies
    hasDependency,
    // additional options for the static array that is passed into this component
    additional,
    // helper text is set inside the drop down
    helperText,
    // error text is what is displayed if there is a modalError
    errorText,
    // a bool that disalbes the drop down
    disabled,
    // a value to group the results of the api call or static enum by
    groupBy,
    // funciton that triggers when the funciton is focussed on
    onFocus,
    // if you want to render whats inside the wrapper as something that isnt a searchbox/textfield
    renderOption,
    // a filter for the options of the search box
    filter,
    // icon or any other sort of adornment that goes at the end of the field
    endAdornment,
    dataWalkthroughid, // used for the walkthrough
    placeholder,
    inputValue,
    getOptionSelected: getOptionSelectedProps,
    ...rest
}) => {
    const [enumArray, setEnum] = useState(staticEnum || []);
    const [enumLoading, setEnumLoading] = useState(false);

    // state to detemine if the modal is showing
    const { show: modalShow, state } = useSelector((state) => {
        return { ...state.modal };
    });

    const dispatch = useDispatch();

    /*
     * Fetch function for the results based with onFieldFetch, fieldFetch props
     * if this is sucsessful the enum state is set to the result
     */
    const fetch = () => {
        setEnumLoading(true);
        if (onFieldFetch) {
            onFieldFetch()
                .then((res) => {
                    setEnum(res.data);
                    setEnumLoading(false);
                })
                .catch((e) => {
                    onError(e);
                    setEnumLoading(false);
                });
            return;
        }
        axios
            .get(fieldFetch)
            .then((res) => {
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        [fieldName]: res.data,
                    },
                });
                setEnum(res.data);
                setEnumLoading(false);
            })
            .catch((e) => {
                onError(e);
                setEnumLoading(false);
            });
    };

    /*
     * Function to extract the field value
     * checks the type of this value and returns the fieldvalue
     */
    const extractFieldValue = () => {
        //set default case
        switch (typeof fieldValue) {
            case 'object':
                return fieldValue || '';
            case 'string':
                return fieldValue || '';
            case 'number':
                return fieldValue;
            default:
                return fieldValue;
        }
    };

    /*
     * calls a fetch based onFieldFetch or fieldFetch existing
     * also requires static enum and dependency to be false
     * also requires init or modalShow or notModalComponent to execuse fetch()
     */
    useEffect(() => {
        if (
            (onFieldFetch || fieldFetch) &&
            !staticEnum && //not static
            !hasDependency && // not depending on sth else
            (init || //do at first place
                modalShow ||
                notModalComponent)
        ) {
            fetch();
        }
    }, [
        // onFieldFetch, fieldFetch,
        modalShow,
        notModalComponent,
        staticEnum,
    ]);

    /*
     * fetches based on the same factors as abovem doesnt accoutn for init or modalShow
     * could most likley be refacorted into a single useEffect()
     */
    useEffect(() => {
        if ((onFieldFetch || fieldFetch) && !staticEnum && hasDependency) {
            fetch();
        }
    }, [onFieldFetch, fieldFetch, hasDependency, dependency, staticEnum]);

    /*
     * If the enumLoading state is true, returns a skeleton component
     */
    if (enumLoading) {
        return <Skeleton style={{ marginTop: '2%' }} />;
    }

    /*
     * If the serachable prop is entered with a static enum of length > 0 then render a search box
     * many props are used for material ui requirements for SearchBox, documentation is online for those props
     */
    if (
        searchable &&
        !disabled &&
        (staticEnum || enumArray)?.concat(additional || []).length > 0
    ) {
        return (
            <SearchBox
                {...rest}
                inputValue={inputValue}
                dataWalkthroughid={dataWalkthroughid}
                onFocus={onFocus}
                endAdornment={endAdornment}
                defaultValue={extractFieldValue()}
                onChange={(v) => {
                    onChange(v[dropDownValue]);
                }}
                noneFieldDisplay={noneFieldDisplay}
                options={(staticEnum || enumArray || [])
                    .concat(additional || [])
                    .concat(
                        !noEmptyOption
                            ? [
                                  {
                                      [dropDownValue]: null,
                                      [displayField]:
                                          noneFieldDisplay || placeholder,
                                  },
                              ]
                            : [],
                    )
                    .sort((left, right) =>
                        left && right
                            ? groupBy
                                ? groupBy(left)?.localeCompare(groupBy(right))
                                : onDisplayDropdown
                                  ? onDisplayDropdown(left)?.localeCompare(
                                        onDisplayDropdown(right),
                                    )
                                  : left[displayField]?.localeCompare(
                                        right[displayField],
                                    )
                            : 0,
                    )
                    .filter(
                        filter ??
                            function (v) {
                                return true;
                            },
                    )}
                helperText={helperText}
                errorText={errorText}
                getOptionLabel={(v) => {
                    if (typeof v === 'string') {
                        if (v.length === 0) {
                            return noneFieldDisplay ?? placeholder;
                        }

                        const result = (staticEnum || enumArray || [])
                            ?.concat(additional || [])
                            ?.concat(
                                !noEmptyOption
                                    ? [
                                          {
                                              [dropDownValue]: null,
                                              [displayField]:
                                                  noneFieldDisplay ||
                                                  placeholder,
                                          },
                                      ]
                                    : [],
                            )
                            .find((item) => item[dropDownValue] === v);
                        if (result && onDisplayDropdown) {
                            return (
                                onDisplayDropdown(result) ??
                                noneFieldDisplay ??
                                placeholder
                            );
                        }
                        return (
                            result?.[displayField] ??
                            noneFieldDisplay ??
                            placeholder
                        );
                    }
                    if (onDisplayDropdown) {
                        return (
                            onDisplayDropdown(v) ??
                            noneFieldDisplay ??
                            placeholder
                        );
                    }

                    return v[displayField] ?? noneFieldDisplay ?? placeholder;
                }}
                displayField={displayField}
                dropDownValue={dropDownValue}
                label={fieldName}
                //groupByProps={groupBy}
                onDisplayDropdown={onDisplayDropdown}
                renderOption={renderOption}
                getOptionSelected={(option, v) => {
                    return option[dropDownValue] === v;
                }}
                // {...dropdownProps}
                placeholder={placeholder}
            />
        );
    }

    /*
     * If the static enum or the array of possible choices has a length of less than 1
     * Render a textfield with a single option
     */
    if ((staticEnum || enumArray)?.concat(additional || []).length < 1) {
        return (
            <Box sx={disabledInputStyle}>
                <TextField
                    data-walkthroughid={dataWalkthroughid}
                    disabled={true}
                    label={fieldName}
                    helperText={errorText || helperText}
                    error={Boolean(errorText)}
                    variant={'outlined'}
                    fullWidth
                    value={noneFieldDisplay || placeholder}
                    className="disabled:cursor-not-allowed"
                />
            </Box>
        );
    }

    /*
     * If there is no staticEnum input into the component then render this
     * mainly using props from material ui textfield some some aspects that I do not understand
     * This maps the dropDownValue and displayField to a list of MenuItems for the component to use
     */
    return (
        <Box sx={customAutoCompleteStyle(colorScheme)}>
            <FormControl size="small" fullWidth error={Boolean(errorText)}>
                <TextField
                    data-walkthroughid={dataWalkthroughid}
                    select
                    disabled={disabled}
                    className="disabled:cursor-not-allowed"
                    label={fieldName}
                    value={extractFieldValue()}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    helperText={errorText || helperText}
                    error={Boolean(errorText)}
                    variant={'outlined'}
                    {...dropdownProps}>
                    {!noEmptyOption && (
                        <MenuItem value={noneFieldValue || ''}>
                            {noneFieldDisplay || placeholder}
                        </MenuItem>
                    )}

                    {dropDownValue && displayField
                        ? (staticEnum || enumArray)
                              ?.concat(additional || [])
                              .map(
                                  (
                                      {
                                          [dropDownValue]: id,
                                          [displayField]: name,
                                          ...rest
                                      },
                                      idx,
                                  ) => {
                                      return (
                                          <MenuItem key={idx} value={id}>
                                              {(onDisplayDropdown &&
                                                  onDisplayDropdown({
                                                      [dropDownValue]: id,
                                                      [displayField]: name,
                                                      ...rest,
                                                  })) ||
                                                  name}
                                          </MenuItem>
                                      );
                                  },
                              )
                        : (staticEnum || enumArray)
                              ?.concat(additional || [])
                              .map((v, idx) => {
                                  return (
                                      <MenuItem key={idx} value={v}>
                                          {(onDisplayDropdown &&
                                              onDisplayDropdown(v)) ||
                                              v}
                                      </MenuItem>
                                  );
                              })}
                </TextField>
            </FormControl>
        </Box>
    );
};

export default LoadingFieldDropdownWithPlaceholder;

LoadingFieldDropdownWithPlaceholder.displayName = 'LoadingFieldDropdown2';
