export const exit = {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    padding: 0,
    transition: { duration: 0.5 },
};

const hiddenInput = { opacity: 0, height: 0 };
const visibleInput = {
    opacity: 1,
    height: 'auto',
    transition: {
        duration: 0.6,
    },
};
export const inputVariants = {
    hidden: hiddenInput,
    visible: visibleInput,
};

const hiddenButton = { opacity: 0, y: 20 };
const visibleButton = {
    opacity: 1,
    y: 0,
    transition: {
        delay: 0.1,
        duration: 0.5,
    },
};
export const buttonVariants = {
    hidden: hiddenButton,
    visible: visibleButton,
};
