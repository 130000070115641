export const visibilityGroups = 'visibilityGroups';

// TODO: VISIBILITY GROUPS
// change this with real API data later
// move to a separate file for error handling

export const initState = JSON.parse(
    localStorage.getItem(visibilityGroups) || '[]',
);

export let nextId =
    initState.length > 0 ? initState[initState.length - 1].id + 1 : 1;

export const ACTIONS = {
    FETCH: 'fetched',
    ADDED: 'added',
    UPDATED: 'updated',
    DELETED: 'deleted',
    CLEARED: 'cleared',
};
