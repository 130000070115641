import {axios} from '../../services/axios';

export default async function createOperatorConnectService(companyId) {
    return axios
        .post(`/Service/OperatorConnect/Magic/${companyId}`, {
            name: 'Operator Connect',
            companyId,
        })
        .then((res) => res)
        .catch((e) => e);
}
