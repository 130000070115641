import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, AlertTitle, Box } from '@mui/material';
import { MdOutlineWarning } from 'react-icons/md';
import walkthroughIds from '../walkthroughIds';
import { axios } from '@/services/axios';
import { DeleteBox } from 'components/index';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

const buttonStyle = { justifyContent: 'center', paddingBottom: '12px' };

// A admin tab for trunks with a button that deletes the trunk with a 'are you sure?' confirmation
const EditTrunkAdminTab = ({ trunkValid }) => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);

    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { darkMode } = useSelector((state) => state.settings);

    // logic for open and close
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError(false);
    };

    // axios calls for delete and dispatches
    const deleteHandler = () => {
        setIsDisabled(true);
        axios
            .delete('/trunk/' + state.id)
            .then(() => {
                navigate(`/trunks`);
                dispatch({
                    type: 'RESET_MODAL',
                });
                dispatch({
                    type: 'RESET_TABLE',
                });
                return () => handleClose();
            })
            .catch((error) => {
                setErrorMessage(error.response.data.message);
                setError(true);
                setIsDisabled(false);
            });
    };

    return (
        <>
            {/* Incomplete trunk alert shows if the trunkValid prop is false */}
            {!trunkValid && (
                <Alert
                    severity="warning"
                    variant="filled"
                    style={{ marginTop: 5, marginBottom: 15 }}>
                    <AlertTitle>Incomplete Trunk</AlertTitle>
                    You must add Collection Ports and Termination IPs before
                    trunk is operational.
                </Alert>
            )}
            <Box className="mx-auto max-w-4xl">
                <DeleteBox
                    onClick={handleOpen}
                    warningText={
                        'By deleting the trunk, it will remove all trunk configuration and calls will stop processing'
                    }
                    walkthroughId={walkthroughIds.ufDeleteButton}
                />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: darkMode ? '#212121' : null,
                        color: darkMode ? 'white' : 'inherit',
                    },
                }}>
                {!error && (
                    <>
                        <DialogTitle>Delete Trunk</DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                sx={{
                                    color: darkMode ? 'white' : 'inherit',
                                }}>
                                Are you sure you want to delete this trunk? This
                                cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={buttonStyle}>
                            <Button
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    deleteHandler();
                                }}
                                disabled={isDisabled}
                                data-walkthroughid={
                                    walkthroughIds.ufDeleteModal
                                }>
                                Delete
                            </Button>
                        </DialogActions>
                    </>
                )}
                {error && (
                    <>
                        <DialogTitle>Delete Trunk</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <div className="border-l-4 border-red-400 bg-red-50 p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <MdOutlineWarning
                                                className="h-5 w-5 text-red-400"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-base text-red-700">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={buttonStyle}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleClose()}
                                data-walkthroughid={
                                    walkthroughIds.ufCloseModal
                                }>
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default EditTrunkAdminTab;
