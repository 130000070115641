/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Input,
    IconButton,
    Text,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Tooltip,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';

import {
    findObjectsWithSameNumber,
    calculateLastNumber,
} from 'utils/numberBlockUtils.js';

import { Alert, AlertIcon, AlertDescription, Select } from '@/components/v4';
import { ErrorMessage } from './ErrorMessage';
import { actionButtons, blockSizes } from './constants';
import NumberFormat from 'react-number-format';

/**
 * @typedef {Object} AddBlockTableProps
 * @property {import('@chakra-ui/react').TableProps} props
 * @property {React.ReactNode} props.children - The content of the table.
 */

/**
 * @param {AddBlockTableProps} props - Props for the AddBlockTable component.
 * @returns {JSX.Element}
 */

export default function AddBlockTable(props) {
    const {
        aggregateTable,
        updateAggregateTable,
        hasError,
        setHasError,
        setHasEmptyNumber,
        hasOverlapBlocksize,
    } = props;

    const blockSizeSelectRef = useRef(null);
    const firstNumberInputRef = useRef(null);
    const addButtonRef = useRef(null);
    const clearButtonRef = useRef(null);
    const [focusedRow, setFocusedRow] = useState(-1);
    const [duplicates, setDuplicates] = useState([]);
    const [numberBlock, setNumberBlock] = useState('1');
    const [numberCopy, setNumberCopy] = useState();
    const { darkMode } = useSelector((state) => state.settings);

    const handleNextFocus = (index, row) => {
        switch (index) {
            case 0: {
                setFocusedRow(row.id);
                firstNumberInputRef.current.focus();
                break;
            }
            case 1: {
                setFocusedRow(row.id + 1);
                addButtonRef.current.focus();
                addButtonRef.current.click();
                break;
            }
            case 2: {
                setFocusedRow(row.id + 1);
                blockSizeSelectRef.current.focus();
                break;
            }

            default: {
                setFocusedRow(-1);
                return null;
            }
        }
    };

    const handleAddButton = () => {
        const newRow = {
            id: aggregateTable.length,
            blockSize: 1,
            number: '',
        };
        updateAggregateTable((prevTable) => [...prevTable, newRow]);
    };
    const handleClearButton = (index) => {
        const newTable = [...aggregateTable];
        newTable.splice(index, 1);
        updateAggregateTable(newTable);
    };

    useEffect(() => {
        aggregateTable?.map((row) => {
            if (row.number === '') {
                setHasEmptyNumber(true);
            } else {
                setHasEmptyNumber(false);
            }
        });
    }, [aggregateTable]);

    const findObjWithSameNumber = useCallback(findObjectsWithSameNumber, [
        duplicates,
    ]);
    useEffect(() => {
        const duplicates = findObjWithSameNumber(aggregateTable);
        setDuplicates(duplicates);
        if (
            duplicates.length > 0 &&
            duplicates.filter((number) => number.number !== '').length > 0
        ) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [findObjWithSameNumber, aggregateTable]);

    return (
        <Box>
            {(hasError || hasOverlapBlocksize) && (
                <Alert status="error" mb={4}>
                    <AlertIcon />
                    <AlertDescription>
                        <ErrorMessage
                            hasOverlapBlocksize={hasOverlapBlocksize}
                            duplicates={duplicates}
                        />
                    </AlertDescription>
                </Alert>
            )}
            <Table variant="unstyled" size="sm" {...props}>
                <Thead>
                    <Tr>
                        <Th>
                            <Text>Block Size</Text>
                        </Th>
                        <Th>
                            <Text>First Number</Text>
                        </Th>
                        <Th>
                            <Text>Last Number</Text>
                        </Th>
                        <Th>
                            <Text>Action</Text>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {aggregateTable?.map((row, index) => {
                        const lastNumber = calculateLastNumber(
                            row.number,
                            row.blockSize,
                        );

                        return (
                            <Tr key={row?.id + index}>
                                <Td>
                                    <Select
                                        name="blockSize"
                                        id="blockSize"
                                        variant="flushed"
                                        value={row.blockSize}
                                        ref={blockSizeSelectRef}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleNextFocus(0, row);
                                            }
                                        }}
                                        autoFocus={row.id === focusedRow}
                                        textAlign="center"
                                        options={blockSizes}
                                        onChange={(e) => {
                                            e.persist();
                                            updateAggregateTable(
                                                (prevTable) => {
                                                    const newTable = [
                                                        ...prevTable,
                                                    ];
                                                    newTable[index].blockSize =
                                                        e?.target?.value;
                                                    setNumberBlock(
                                                        e.target.value,
                                                    );
                                                    return newTable;
                                                },
                                            );
                                            handleNextFocus(0, row);
                                        }}></Select>
                                </Td>
                                {/* First Number */}
                                <Td>
                                    <Input
                                        as={
                                            numberCopy === false && NumberFormat
                                        }
                                        isNumericString
                                        allowNegative={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        name="firstNumber"
                                        id="firstNumber"
                                        variant="flushed"
                                        value={row.number}
                                        getInputRef={(el) =>
                                            (firstNumberInputRef.current = el)
                                        }
                                        _placeholder={{
                                            color: darkMode ? '#6c757d' : null,
                                        }}
                                        placeholder="Please enter number in E164 format."
                                        ref={firstNumberInputRef}
                                        autoFocus={row.id === focusedRow}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleNextFocus(1, row);
                                            }
                                        }}
                                        isInvalid={hasError}
                                        color={hasError ? 'red.500' : 'inherit'}
                                        onPaste={(event) => {
                                            const numberData =
                                                event.clipboardData.getData(
                                                    'text',
                                                );
                                            if (numberData.length > 0) {
                                                event.clipboardData.getData(
                                                    'text',
                                                );
                                                setNumberCopy(true);
                                            }
                                        }}
                                        onChange={(e) => {
                                            e.persist();
                                            updateAggregateTable(
                                                (prevTable) => {
                                                    const newTable = [
                                                        ...prevTable,
                                                    ];

                                                    if (
                                                        index === 0 &&
                                                        numberCopy === true &&
                                                        e.target.value !==
                                                            'NAN' &&
                                                        e.target.value.length >
                                                            0
                                                    ) {
                                                        const inputData =
                                                            e.target.value.trim();
                                                        if (!inputData) {
                                                            return prevTable;
                                                        }
                                                        const newNumbers =
                                                            inputData
                                                                .split(/\s+/)
                                                                .map(Number)
                                                                .join(',');

                                                        const splitNumbers =
                                                            newNumbers.split(
                                                                /\s*(?:,\s*|\n|\/n)/,
                                                            );
                                                        const filteredNumbers =
                                                            splitNumbers.filter(
                                                                (num) => !!num,
                                                            ); // Filter out empty strings

                                                        newTable.length = 0;
                                                        let updatedNumbers =
                                                            filteredNumbers.map(
                                                                (
                                                                    row,
                                                                    index,
                                                                ) => ({
                                                                    id: index,
                                                                    blockSize:
                                                                        numberBlock,
                                                                    number: Number(
                                                                        row,
                                                                    ),
                                                                }),
                                                            );
                                                        const filterPrevTable =
                                                            prevTable.filter(
                                                                (row) =>
                                                                    row.number !==
                                                                    '',
                                                            );
                                                        newTable.push(
                                                            ...updatedNumbers,
                                                            ...filterPrevTable,
                                                        );
                                                        setNumberCopy(false);
                                                    } else {
                                                        newTable[index].number =
                                                            e?.target?.value;
                                                        setNumberCopy(false);
                                                    }

                                                    return newTable;
                                                },
                                            );
                                        }}
                                    />
                                </Td>
                                {/* Last Number */}
                                <Td>
                                    <Input
                                        name="lastNumber"
                                        id="lastNumber"
                                        variant="flushed"
                                        isDisabled
                                        className="disabled:cursor-not-allowed"
                                        value={lastNumber}
                                        color="#989898"
                                    />
                                </Td>
                                {/* Action Buttons */}
                                <Td>
                                    <Tooltip
                                        label={
                                            !hasError && actionButtons[0].label
                                        }
                                        hasArrow>
                                        <IconButton
                                            id={`${actionButtons[0].ariaLabel}-${index}`}
                                            ref={addButtonRef}
                                            icon={<AddIcon />}
                                            rounded="full"
                                            variant="ghost"
                                            color={hasError ? 'red.500' : null}
                                            isDisabled={hasError}
                                            _hover={{
                                                bg: darkMode
                                                    ? 'dark.hoverGray'
                                                    : null,
                                                color: hasError
                                                    ? null
                                                    : 'brand',
                                            }}
                                            _focus={{
                                                boxShadow: 'none',
                                            }}
                                            onClick={() => {
                                                handleAddButton();
                                                handleNextFocus(2, row);
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        label={actionButtons[1].label}
                                        hasArrow>
                                        <IconButton
                                            id={`${actionButtons[1].ariaLabel}-${index}`}
                                            ref={clearButtonRef}
                                            icon={<CloseIcon />}
                                            rounded="full"
                                            variant="ghost"
                                            _hover={{
                                                bg: darkMode
                                                    ? 'dark.hoverGray'
                                                    : null,
                                                color: 'brand',
                                            }}
                                            _focus={{
                                                boxShadow: 'none',
                                            }}
                                            onClick={() =>
                                                handleClearButton(index)
                                            }
                                        />
                                    </Tooltip>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Box>
    );
}
