import { AddRounded } from '@material-ui/icons';

import { Button } from 'components/v4';

export default function TableHeader({ onClick }) {
    return (
        <Button
            className="mb-4 items-center text-sm focus:shadow-none"
            onClick={onClick}
            px={2}
            py={1}
            minW="100px">
            <AddRounded fontSize="small" className="-ml-2" />
            Visibility Group
        </Button>
    );
}
