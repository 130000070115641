/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Icon } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import classNames from 'classnames';

import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardOcStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardOcStatusbar';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import { MenuItem } from 'components/v4';

// Assets
import occLogo from 'assets/images/occ.png';

import walkthroughIds from 'pages/services/walkthroughIds';
import path from '@/routes/constants';

export default function OCCCard({ service }) {
    const classes = classNames({
        flash: service.changes === 'added',
    });

    const navigate = useNavigate();
    const [cardStatus, setCardStatus] = useState({
        color: 'green',
        isAnimated: false,
        hasStripes: false,
        status: '...',
        to: null,
        toText: null,
    });
    const { requiredScope } = useSelector((state) => state.login);

    const [data, setData] = useState({});
    const { ufTpmEditServiceButton, ufTpmVerifyServiceButton } =
        walkthroughIds.dashboardServices;
    const handleGetData = () => {
        // getService(service?.id)
        //     .then((res) => {
        //         setData(res.data);
        //     })
        //     .catch((e) => {
        //         console.log('error get acs', {e});
        //     });
    };
    useEffect(() => {
        // setCardStatus({
        //     color: 'green',
        //     isAnimated: false,
        //     hasStripes: false,
        //     status: 'OK',
        // });
    }, []);

    useEffect(() => {
        handleGetData();
    }, [service?.changes]);

    return (
        <DashboardServiceCard
            className={classes}
            dataWalkthroughId={service.name || service.serviceType}>
            <DashboardServiceCardOcStatusbar status={cardStatus} />

            <DashboardServiceCardHeader>
                <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                    Operator Connect Conferencing
                </Text>

                {/* <DashboardServiceCardHeaderActionButton>
                    {requiredScope >= 40 && (
                        <Link
                            className="bg-transparent !no-underline hover:text-inherit"
                            to={`${path.SERVICES_OCC}/${service.id}/edit`}
                            state={service}>
                            <MenuItem
                                icon={<EditIcon />}
                                // onClick={() => {
                                //     navigate(
                                //         `/operator-connect-conferencing/edit/${service.id}`,
                                //         { state: service },
                                //     );
                                // }}
                                data-walkthroughid={ufTpmEditServiceButton}>
                                <Text marginBottom={'0'}>Edit Service</Text>
                            </MenuItem>
                        </Link>
                    )}
                    <MenuItem
                        icon={<EditIcon />}
                        onClick={() => {
                            navigate(
                                `/teams-phone-mobile/activation/${service.id}`,
                            );
                        }}
                        data-walkthroughid={ufTpmVerifyServiceButton}>
                        <Text marginBottom={'0'}>Verify Numbers</Text>
                    </MenuItem>
                </DashboardServiceCardHeaderActionButton> */}
            </DashboardServiceCardHeader>
            <DashboardServiceCardContent>
                <Box>
                    <Image
                        src={occLogo}
                        sx={{
                            maxWidth: '50px',
                            margin: '0 auto 15px auto',
                        }}
                        className="animate-pulse-slow"
                    />

                    <Box className="flex flex-col">
                        {/* Status */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">Status</Text>
                            <Text>
                                {service?.statusDisplay?.statusDisplayDetail}
                            </Text>
                        </Box>

                        {/* Available Numbers // TODO: change with real data later */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">
                                Available Numbers
                            </Text>
                            <Text className="text-left">12</Text>
                        </Box>

                        {/* Active Numbers  */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">Active Numbers</Text>
                            <Text className="text-left">0</Text>
                        </Box>
                    </Box>
                </Box>
            </DashboardServiceCardContent>
        </DashboardServiceCard>
    );
}
