import { Icon } from '@chakra-ui/react';
import { EditRounded } from '@material-ui/icons';
import { LogoutRounded } from '@mui/icons-material';
import { FaRegTrashAlt } from 'react-icons/fa';

import { RefreshIcon } from '@/components/v4/MaterialTable';

const tableActions = ({
    tableRef,
    refetch,
    useTableActions: { handleEditBtn, handleDeleteVg, handleLeaveVg },
}) => [
    {
        icon: RefreshIcon,
        tooltip: 'Refresh Visibility Groups',
        isFreeAction: true,
        onClick: () => {
            tableRef.current &&
                tableRef.current.dataManager.columns.forEach((item) => {
                    tableRef.current.onFilterChange(item.tableData.id, '');
                });
            refetch();
        },
        // hidden: !refreshFn,
    },
    (rowData) => ({
        icon: () => <EditRounded fontSize="small" />,
        tooltip: 'Edit Visibility Group',
        disabled: rowData.visibilityGroupMembers?.length === 0,
        isFreeAction: false,
        onClick: (_e, rowData) => {
            handleEditBtn(rowData);
        },
    }),
    (rowData) => ({
        icon: () => <Icon as={FaRegTrashAlt} boxSize={5} />,
        tooltip: 'Delete Visibility Group',
        isFreeAction: false,
        disabled: rowData.visibilityGroupMembers?.length === 0,
        onClick: (_e, rowData) => {
            handleDeleteVg(rowData);
        },
    }),
    {
        icon: () => <LogoutRounded fontSize="small" />,
        tooltip: 'Leave Visibility Group',
        isFreeAction: false,
        onClick: (_e, rowData) => {
            handleLeaveVg(rowData);
        },
    },
];

export default tableActions;
