import React, { useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';

import config from '../../../config.json';
import {TabView, TableAndModal} from '../../../components';
import TrunkEdit from './edit/TrunkTypeEdit';

/**
 * Trunk Type CRUD component for The Trunk Types menu of the GA tab in TCAP
 */
const TrunkTypes = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // columns for default table
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Header Value',
            field: 'headerValue',
        },
        {
            title: 'Teams Enabled',
            field: 'teamsEnabled',
            type: "boolean"
        },
        {
            title: 'SIP Enabled',
            field: 'sipEnabled',
            type: "boolean"
        },
        {
            title: 'Shared',
            field: 'shared',
            type: "boolean"
        },
    ];

    // Retruns the table and modal for Trunk Types
    return (
        <TableAndModal
            tableRef={tableRef}
            newPage
            tableFetch={config.trunkTypes.tableFetch}
            modalFetch={config.trunkTypes.modalFetch + modalData?.id}
            modalAdd={config.trunkTypes.modalAdd}
            modalUpdate={config.trunkTypes.modalUpdate}
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title={config.trunkTypes.tableTitle}
            defaultColumns={defaultColumns}
            add
            rowActions={{editable: true}}
            groupable
            filterable
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name.length < 1) &&
                        'Name is required',
                    headerValue:
                        (!modalState?.headerValue ||
                            modalState?.headerValue.indexOf(' ') >= 0) &&
                        'Header value is required and cannot have spaces',
                    minChannels:
                        ((parseInt(modalState?.minChannels) < 0 || modalState?.minChannels?.length < 1) &&
                        'Minimum channels is required'),
                    incSize:
                        ((!modalState?.incSize || modalState?.incSize.length < 1) &&
                        'Increment size is required') 
                        || 
                        (modalState?.minChannels % modalState?.incSize != 0 && 
                        'Increment size must be divisible by minimum channels'),
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(newErrorState.name || newErrorState.headerValue || newErrorState.minChannels || newErrorState.incSize);
            }}
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalMode + ' Trunk Type'}
            modalContent={
                <TabView
                    centered
                    tabLabels={config.trunkTypes.tabs}
                    contents={[<TrunkEdit />]}
                    insideModal={true}
                />
            }
        />
    );
};

export default TrunkTypes;
