import { ChakraProvider, Image, Tooltip } from '@chakra-ui/react';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { TableAndModal } from 'components/index';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import walkthroughIds from '../walkthroughIds';

export default function ServiceCategories({}) {
    const tableRef = useRef();
    const navigate = useNavigate();

    const { theme } = useDeepMerge();

    const { ufAddServiceCategory, ufTableServiceCategoryEdit } =
        walkthroughIds.serviceCategories;

    // default columns
    const defaultColumns = [
        {
            title: 'Image',
            field: 'image',
            width: '5%',
            sorting: false,
            grouping: false,
            filtering: false,
            render: (rowData) => (
                <ChakraProvider resetCSS={false} theme={theme}>
                    <Tooltip hasArrow label={rowData.image}>
                        <Image
                            boxSize={5}
                            objectFit={'contain'}
                            // development and teams dev, continue with teamsdev, else can be local backend environment
                            src={
                                process.env.NODE_ENV === 'development'
                                    ? import.meta.env.VITE_APP_IMAGE_BASE +
                                      '/images/' +
                                      rowData.image
                                    : window.location.origin +
                                      '/images/' +
                                      rowData.image
                            }
                        />
                    </Tooltip>
                </ChakraProvider>
            ),
        },
        {
            title: 'Type',
            field: 'type',
            width: '5%',
            lookup: {
                0: 'Standard',
                1: 'Location',
            },
        },
        {
            title: 'ID',
            field: 'id',
            defaultSort: 'asc',
        },
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Description',
            field: 'description',
            hiddenByColumnsButton: true,
            hidden: true,
        },
    ];

    return (
        <>
            <h1 className="mb-2 text-[2.5rem] font-medium">
                Service Categories
            </h1>
            <TableAndModal
                tableRef={tableRef}
                tableFetch={'/ServiceCategories'}
                size={'md'}
                title="Service Categories"
                noTitle
                defaultColumns={defaultColumns}
                additionalActions={[
                    (rowData) => ({
                        icon: () => (
                            <EditRoundedIcon
                                fontSize="small"
                                data-walkthroughid={`${ufTableServiceCategoryEdit}/${rowData?.id}`}
                            />
                        ),
                        tooltip: 'Edit Service Category',
                        position: 'row',
                        onClick: () => {
                            navigate(`edit/${rowData.id}`);
                        },
                    }),
                ]}
                titleProps={
                    <Button
                        data-walkthroughid={ufAddServiceCategory}
                        className="btn btn-primary btn-sm"
                        style={{ marginBottom: '1%' }}
                        onClick={() => navigate('/service-categories/add')}>
                        {' '}
                        <AddRoundedIcon
                            fontSize="small"
                            style={{
                                marginBottom: '2%',
                            }}
                        />{' '}
                        Service Category
                    </Button>
                }
                groupable
                filterable
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
            />
        </>
    );
}
