import { axios } from '@/services/axios';

import { getCurrentStoreState } from '@tcap/data-router/utils/helpers';

const get = async (currentPartner) => {
    try {
        const res = await axios.get(`ApprovalCodes/${currentPartner}`, {
            headers: {
                'X-RequestScope': 40,
            },
        });

        return res.data;
    } catch (error) {
        console.error('Error fetching approval codes: ', error);
    }
};

export const getApprovalCodesQuery = (currentPartner) => ({
    queryKey: ['approvalCodes', currentPartner],
    queryFn: async () => get(currentPartner),
});

const approvalCodeLoader = (queryClient) => async () => {
    const currentState = getCurrentStoreState();
    const { loggedIn, requiredScope } = currentState.login;
    const { currentPartner } = currentState.navigationLists;
    const { queryKey, queryFn } = getApprovalCodesQuery(currentPartner);
    const approvalCodes =
        loggedIn && requiredScope >= 40
            ? await queryClient.ensureQueryData({
                  queryKey,
                  queryFn,
                  staleTime: 1000 * 60 * 2,
              })
            : null;
    return { approvalCodes };
};

export { approvalCodeLoader };
