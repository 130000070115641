import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap';
import { classNames } from 'utils/classNames';
import { useSelector } from 'react-redux';

// theme usage with material ui
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
    content: {
        padding: '5%',
    },
}));

// Restore to default confirmation modal
const RestoreDefaultModal = ({ handleClose, show, handleSubmit, content }) => {
    const classes = useStyles();

    const { darkMode } = useSelector((state) => state.settings);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Restore To Default </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.root}>
                    <div className={classes.content}>{content}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="bg-white border !border-blue-500 !text-blue-500"
                    variant="secondary"
                    onClick={handleClose}>
                    No
                </Button>
                <Button
                    className={classNames(
                        !darkMode ? 'text-white' : null,
                        '!bg-blue-600 ml-3',
                    )}
                    variant="primary"
                    onClick={handleSubmit}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RestoreDefaultModal;
