import {axios} from '../../services/axios';

export default async function getMsLoginLink(
    companyId,
    location = window.location.href,
) {
    return axios
        .get(`/company/${companyId}/msloginlink?domain=${location}`)
        .then((res) => res)
        .catch((e) => e);
}
