// external imports
import React from 'react';
import {Typography, styled} from '@mui/material';

const StyledType = styled(Typography)(({theme}) => ({}));

/*
 * Will be the admin tab for service profiles
 * Most likely will be a tab to delete/reassing these service profiles
 * Waiting on information as to what it should do though
 */
const ServiceAdmin = (props) => {
    return (
        <>
            <StyledType> ServiceAdmin </StyledType>
        </>
    );
};

export default ServiceAdmin;
