export const initialState = {
    number: '',
    status: 'IDLE',
    error: '',
    touched: false,
};

export const path = {
    /** @type {'/sms-authorization'} */
    SMS_AUTHORIZATION: '/sms-authorization',
    /** @type {'/associated-accounts'} */
    ASSOCIATED_ACCOUNTS: '/associated-accounts',
};

export const QUEUE_LIMIT = 30 * 60; // 30 minutes
export const delay = 20 * 1000;

export const trialEndInstructionBody = [
    'Your trial has expired and calling services have been disabled.',
    'Please contact your provider.',
];

export const noAssociatedInstructionBody = [
    'Please Enter your Mobile Phone Number above to verify with the Operator if TPM is supported.',
    'Please note, this may take up to 15 minutes after submitting your number.',
];

export const associatedInstructionBody = [
    'There is already an Active Mobile Account associated with your organisation.',
    'If the Mobile Phone Number you are looking to enable for Teams Phone Mobile belongs to this account, click Back to activate.',
    'If you would like to add a new account, please continue to enter the Mobile Phone number above to start the verification process.',
];

export const PHONE_INVALID_FORMAT =
    'Phone number contains invalid characters. Please ensure you are using E.164 format.';

export const operatorInstruction = [
    'The Mobile Operator is processing your request.',
    'This may take up to 15 minutes. This screen shall auto refresh,',
    'You may navigate away from this screen and come back to it later if required.',
    'Resubmitting your number for validation does not restart the process.',
];

export const keywords = [
    'Mobile Phone Number',
    'TPM',
    '15 minutes',
    'Back',
    'Teams Phone Mobile',
    'Active Mobile Account',
    'has expired',
    'disabled',
];
