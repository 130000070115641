import { Navigate, Outlet, useParams } from 'react-router-dom';

import { useAssociatedAccounts } from 'hooks/useCustomQuery';

const NumberManagement = () => {
    const { id } = useParams();
    useAssociatedAccounts(id);
    return id ? <Outlet /> : <Navigate to="/numbers" />;
};

export default NumberManagement;
