import React from 'react';
import {Box} from '@chakra-ui/react';
import style from './styles';

export default function DashboardServiceCardHeader({children, ...props}) {
    return (
        <Box sx={style.headerContainer} {...props}>
            {children}
        </Box>
    );
}
