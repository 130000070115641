import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    ListItem,
    UnorderedList,
    Text,
    AlertTitle,
} from '@chakra-ui/react';
import { DownloadIcon, AttachmentIcon } from '@chakra-ui/icons';

import Button from '../../../../components/v4/Button';
import FormLabel from '../../../../components/v4/FormLabel';
import Heading from '../../../../components/v4/Heading';
// import Text from '../../../../components/v4/Text';
import walkthroughIds from '../walkthroughIds';
import { useSelector } from 'react-redux';
import { Alert, AlertIcon } from 'components/v4';
import {
    CSV_LIMIT_REACHED_DESCRIPTION_ERROR,
    CSV_LIMIT_REACHED_ERROR,
} from 'hooks/useCSVNumbersUpdate/constants';

export const CSVPrompt = ({ sentence }) => {
    const boldPhrases = [
        // below is for numbers csv update
        'For Operator Connect, please ensure the correct Calling Profile for the region/country is assigned. Failure to do so may result in issue making calls.',
        'You are responsible for the changes you are about to make through this data import including any cost incurred as a result of number activation. Please consult your contract for all billing terms.',
        'Please provide the correct address description for importing.',
        // below is for ra/users csv update
        'You are responsible for the changes you are about to make through this data import.',
    ];

    const isBoldSentence = boldPhrases.includes(sentence);

    return (
        <>
            {isBoldSentence ? (
                <span className="font-bold">{sentence}</span>
            ) : (
                sentence
            )}
        </>
    );
};

export default function CSVTemplateView({
    handleUploadUsersData,
    downloadCSVText,
    header,
    step1Text,
    step2Text,
    instructionsHeader,
    instructionsBody,
    handleDownloadTemplate,
    loading,
    csvLimit,
}) {
    const navigate = useNavigate();
    const { darkMode } = useSelector((state) => state.settings);

    // file upload
    const inputRef = useRef(null);

    const handleBackButton = () => {
        navigate(-1);
    };

    return (
        <Box display={'flex'} justifyContent="center">
            <Box
                width={400}
                display={'flex'}
                flexDirection={'column'}
                gap={'1px'}
                fontFamily={'Helvetica'}
                justifyContent={'center'}
                boxShadow="base"
                p="6"
                bg={darkMode ? 'dark.tcap' : 'white'}
                rounded="md">
                <Heading size="xl">{header}</Heading>
                <Box mt={'10px'}>
                    <Text size="md"> {step1Text} </Text>
                    <Button
                        isLoading={loading}
                        rightIcon={<DownloadIcon />}
                        sx={{ marginTop: 2, width: '100%' }}
                        size="sm"
                        onClick={handleDownloadTemplate}
                        data-walkthroughid={walkthroughIds.csv.ufDownloadBtn}>
                        {downloadCSVText}
                    </Button>
                </Box>

                {/* <Divider orientation="horizontal" /> */}

                <Box mt={'25px'}>
                    <Text size="md"> {step2Text} </Text>
                    <Button
                        size="sm"
                        as={FormLabel}
                        sx={{ marginTop: 2, width: '100%' }}
                        data-walkthroughid={walkthroughIds.csv.ufUploadBtn}
                        onClick={() => {
                            if (inputRef.current.value)
                                inputRef.current.value = '';
                        }}
                        className="hover:cursor-pointer">
                        Choose File <AttachmentIcon ml={1} />
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            id="image-input"
                            accept=".csv"
                            ref={inputRef}
                            onChange={handleUploadUsersData}
                        />
                    </Button>
                </Box>

                {csvLimit && (
                    <Alert status="error">
                        <AlertIcon />
                        <Box flexDirection="column">
                            <AlertTitle>{CSV_LIMIT_REACHED_ERROR}</AlertTitle>
                            {CSV_LIMIT_REACHED_DESCRIPTION_ERROR}
                        </Box>
                    </Alert>
                )}

                <Box mt={'10px'} mb={'10px'}>
                    <Text size="lg" fontWeight="bold">
                        {' '}
                        {instructionsHeader}{' '}
                    </Text>
                    <UnorderedList>
                        {instructionsBody.map((v, i) => (
                            <ListItem key={i}>
                                <CSVPrompt sentence={v} />
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
                <Button
                    variant="outline"
                    sx={{ marginTop: 2, width: '100%', bgColor: 'white' }}
                    size="sm"
                    _hover={{ opacity: 0.8 }}
                    onClick={handleBackButton}
                    data-walkthroughid={walkthroughIds.csv.ufCloseBtn}>
                    Close
                </Button>
            </Box>
        </Box>
    );
}
