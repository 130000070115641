import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableAndModal from '../../../../components/tables/TableAndModal';
import config from '../../../../config.json';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../../components/presentational/TabPanel';
import Details from './view/Details';
import History from './view/History';
import {Button, Col, Row} from 'react-bootstrap';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

export const DEFAULT_COLUMNS = [
    {
        title: 'Tenant ID',
        field: 'tenantID',
    },
    {
        title: 'Status',
        field: 'status',
    },
    {
        title: 'Company Name',
        field: 'defaultContact.companyName',
    },
    {
        title: 'Consented Countries',
        field: 'consentedCountriesString',
    },
    {
        title: 'Contact Name',
        field: 'defaultContact.fullName',
    },
    {
        title: 'Email',
        field: 'defaultContact.email',
    },
    {
        title: 'Phone',
        field: 'defaultContact.phoneNumber',
    },
    {
        title: 'Last Modified On',
        field: 'lastModifiedOn',
        type: 'datetime',
        hiddenByColumnsButton: true,
        hidden: true,
    },
    {
        title: 'Company Size',
        field: 'defaultContact.companySize',
        hiddenByColumnsButton: true,
        hidden: true,
    },
];

function useConsentTable({isGAView}) {
    // child from table state
    const {child} = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    const tableActions = [
        {
            icon: ({disabled}) => {
                if (disabled) {
                    return;
                }
                return <VisibilityRoundedIcon fontSize="small" />;
            },
            tooltip: 'View Operator Consents',
            position: 'row',
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        mode: 'Edit',
                        data: rowData,
                        hash: '',
                    },
                });
            },
        },
    ];

    const dispatch = useDispatch();
    const {currentWholesaler} = useSelector((state) => {
        return {...state.navigationLists};
    });
    const {data: modalData} = useSelector((state) => {
        return {
            ...state.modal,
        };
    });
    const [tab, setTab] = useState(0);
    const onTabChange = (e, v) => {
        if (v !== tab) {
            setTab(v);
        }
    };
    const handleCloseModal = () => {
        dispatch({
            type: 'RESET_MODAL',
        });
    };

    return {
        tableFetchEndpoint: isGAView
            ? `/OperatorConnectOperatorConsents/Wholesaler/${currentWholesaler}`
            : `/OperatorConnectOperatorConsents/${child?.id}`,
        modalData,
        onTabChange,
        tabLabels: ['Consent Details', 'Consent History'],
        tab,
        handleCloseModal,
        dispatch,
        tableActions,
    };
}
/*
 * Component for the Consent Page
 */
export default function ConsentTable(props) {
    const {
        tableFetchEndpoint,
        modalData,
        tabLabels,
        tab,
        onTabChange,
        handleCloseModal,
        dispatch,
        tableActions,
    } = useConsentTable(props);

    const tableRef = useRef();

    if (modalData) {
        return (
            <React.Fragment>
                <Tabs value={tab} onChange={onTabChange}>
                    {tabLabels.map((v) => (
                        <Tab label={v} key={v} />
                    ))}
                </Tabs>
                <TabPanel id="full-child-width-tabpanel-" value={tab} index={0}>
                    <Details data={modalData} />
                </TabPanel>
                <TabPanel id="full-child-width-tabpanel-" value={tab} index={1}>
                    <History data={modalData} columns={DEFAULT_COLUMNS} />
                </TabPanel>
                <Row>
                    <Col>
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={handleCloseModal}>
                            Back
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={tableFetchEndpoint}
            title="Consents"
            defaultColumns={DEFAULT_COLUMNS}
            groupable
            additionalActions={tableActions}
            onTableSucceed={(data) => {
                return data.map((v) => {
                    const consentedCountries =
                        v.consentedCountriesList ?? v.consentedCountries ?? [];
                    const consentedCountriesString = Array.isArray(
                        consentedCountries,
                    )
                        ? consentedCountries.join(', ')
                        : consentedCountries;

                    const defaultContact = v?.contacts?.find(
                        (x) => x.isDefault,
                    ) ?? {
                        companyName: '',
                        fullName: '',
                        email: '',
                        phoneNumber: '',
                        companySize: '',
                    };

                    return {
                        ...v,
                        defaultContact,
                        consentedCountriesString,
                    };
                });
            }}
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
        />
    );
}
