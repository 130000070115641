import { axios } from '@/services/axios';

/**
 * @typedef {('company' | 'wholesaler' | 'partner')} EntityType
 * @param {EntityType} type - company or wholesaler or partner
 * @param {String} currentCompany - current company id
 */
export const getNotification = async (type, currentCompany) => {
    return axios.get(`/notify/${type}/${currentCompany}`);
};
