import { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button, Tabs, Tab, Stack } from '@mui/material';
import { TextField } from '@material-ui/core';
import LoadingFieldDropdownWithPlaceholder from 'components/presentational/LoadingFieldDropdownWithPlaceholder';

import walkthroughIds from '@/pages/organisations/walkthroughIds';
import { useSelector } from 'react-redux';

export const CompanyAdd = () => {
    const { general } = walkthroughIds.companies;
    const {
        currentCompany,
        currentPartner,
        wholesalersLoading,
        companiesAndPartnersLoading,
    } = useSelector((state) => state.navigationLists);

    const [navStatus, setNavStatus] = useState('idle');

    const navigate = useNavigate();

    // TODO: setState for defaultPlanID

    const setState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload,
        });
    };

    const handleBackButton = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (wholesalersLoading || companiesAndPartnersLoading) {
            setNavStatus('navigating');
            navigate(-1);
        }
    }, [currentCompany, wholesalersLoading, companiesAndPartnersLoading]);

    return (
        <div className="mx-auto w-full min-w-[36rem] max-w-4xl px-2 py-16 sm:px-0">
            <Stack key={currentCompany}>
                <TabContext value={0}>
                    <Tabs
                        value={0}
                        centered
                        indicatorColor="primary"
                        textColor="primary">
                        <Tab label="General" key="General" />
                    </Tabs>
                    <TabPanel value={0}>
                        <TextField
                            fullWidth
                            placeholder="Company Name"
                            size="small"
                            label="Company Name"
                            className="mb-3"
                            // error={Boolean(modalStateError?.name)}
                            // helperText={modalStateError?.name}
                            // value={state?.name}
                            onChange={(e) => {
                                setState({ name: e.target.value });
                                setState({ entityName: e.target.value });
                            }}
                            data-walkthroughid={general.ufCompanyNameInput}
                        />
                        {/* Default Plan */}
                        <LoadingFieldDropdownWithPlaceholder
                            searchable
                            fieldName="Default Plan"
                            dropDownValue="id"
                            // onError={onModalError}
                            displayField="name"
                            // onChange={(v) => {
                            //     dispatch({
                            //         type: 'CHANGE_MODAL_STATE',
                            //         payload: {
                            //             defaultPlanID: v,
                            //         },
                            //     });
                            // }}
                            // fieldValue={state?.defaultPlanID}
                            toggleLimit={5}
                            hasDependency
                            dependency={currentPartner}
                            fieldFetch={`/partner/${currentPartner}/plans`}
                            noEmptyOption
                            placeholder={'Please select a default plan'}
                            dataWalkthroughid={general.ufDefaultPlanSelect}
                        />
                        <div
                            className="my-3 flex justify-center gap-2"
                            style={{
                                border: 'none',
                                textAlign: 'center',
                                justifyContent: 'center',
                                marginBottom: 10,
                            }}>
                            <div>
                                <Button
                                    className="min-w-[5rem] !bg-gray-400 !text-base !capitalize hover:!bg-gray-500"
                                    variant="contained"
                                    // color="secondary"
                                    onClick={handleBackButton}>
                                    Back
                                </Button>
                            </div>
                            {/* <div style={{ marginLeft: 10, display: 'inline' }}> */}
                            <div className="disabled:cursor-not-allowed">
                                <Button
                                    className="min-w-[5rem] !text-base !capitalize disabled:!pointer-events-none disabled:!bg-blue-600 disabled:!text-white disabled:opacity-70"
                                    variant="contained"
                                    color="primary"
                                    // disabled
                                    type="submit"
                                    // onClick={handleEditTrunk}
                                >
                                    Create
                                </Button>
                            </div>
                            {/* </div> */}
                            <div className="disabled:cursor-not-allowed">
                                <Button
                                    className="min-w-[5rem] !text-base !capitalize disabled:!pointer-events-none disabled:!bg-blue-500 disabled:!text-white disabled:opacity-70"
                                    variant="contained"
                                    // disabled
                                    color="info">
                                    Create As Trial
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Stack>
        </div>
    );
};
