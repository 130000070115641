export default {
    adminsGeneral: {
        ufFirstName: 'accounts/admins/input/firstName',
        ufLastName: 'accounts/admins/input/lastName',
        ufIsActive: 'accounts/admins/checkbox/isActive',
        ufPermissionSet: 'accounts/admins/input',
        ufMfaSelect: 'accounts/admins/input/mfa',
        ufEmail: 'accounts/admins/input/email',
        ufResetMfaButton: 'accounts/admins/button/resetMfa',
        ufResetPassword: 'accounts/admins/button/resetPassword',
        ufBackBtn: 'accounts/admins/button/back',
        ufSaveBtn: 'accounts/admins/button/save',
        ufBypassMinPasswordAge: 'accounts/admins/button/bypassMinPasswordAge',
        ufCloseBtn: 'accounts/admins/modal/close',
        ufYesBtn: 'accounts/admins/modal/yes',
        ufOverrideOverstampList: 'accounts/admins/checkbox/overrideOverstamp',
        ufApiDocReader: 'accounts/admins/checkbox/apiDocReader',
        ufAdminDelete: 'accounts/admins/button/delete',
    },
    adminsAdmin: {
        ufOverrideOverstampList: 'accounts/admins/checkbox/overrideOverstamp',
        ufApiDocReader: 'accounts/admins/checkbox/apiDocReader',
        ufAdminDelete: 'accounts/admins/button/delete',
    },
    ufTableEditBtn: 'accounts/admins/button/edit',
    ufAddAdminUser: 'accounts/admins/button/add',
    ufTabButton: 'accounts/admins/tab/',
    ufSSO: 'accounts/admins/button/sso',
    adminSSO: {
        ufDisableSSOBtn: 'accounts/admins/sso/button/disable',
        ufEnableSSOSwitch: 'accounts/admins/sso/switch/enable',
        ufEnforceSSOBtn: 'accounts/admins/sso/checkbox/enforce',
        ufEnforceSSOSwitch: 'accounts/admins/sso/switch/enforce',
        ufSaveBtn: 'accounts/admins/sso/button/save',
        ufCloseBtn: 'accounts/admins/sso/button/close',
        ufProviderBtn: 'accounts/admins/sso/button/provider',
        ufDeactivateBtn: 'accounts/admins/sso/button/deactivate',
        ufVerifyBtn: 'accounts/admins/sso/button/verify/',
        ufActiveBtn: 'accounts/admins/sso/button/active/',
        ufEditBtn: 'accounts/admins/sso/button/edit/',
        ufTenantInput: 'accounts/admins/sso/input/tenant',
        ufOptionBtn: 'accounts/admins/sso/button/option',
        ufEmailInput: 'accounts/admins/sso/input/email',
        ufLookupBtn: 'accounts/admins/sso/button/lookup',
        ufBackBtn: 'accounts/admins/sso/button/back',
        ufSubmitBtn: 'accounts/admins/sso/button/submit',
        ufLookupSearchBtn: 'accounts/admins/sso/lookup/button/search',
        ufEmailLookupInput: 'accounts/admins/sso/lookup/input/email',
        ufLookupCloseBtn: 'accounts/admins/sso/lookup/button/close',
        ufLookupAddBtn: 'accounts/admins/sso/lookup/button/add',
        ufProviderSelect: 'accounts/admins/sso/select/provider',
    },
    adminPasswordPolicy: {
        ufPasswordPolicySwitch: 'accounts/admins/passwordpolicy/switch/enable',
        ufNumberOfDaysInput:
            'accounts/admins/passwordpolicy/input/numberOfDays',
        ufPasswordReuseLimitInput:
            'accounts/admins/passwordpolicy/input/reuseLimit',
        ufMinPasswordAgeInput:
            'accounts/admins/passwordpolicy/input/minPasswordAge',
        ufSaveBtn: 'accounts/admins/passwordpolicy/button/save',
    },
    ufTableApiUserEditBtn: 'accounts/admins/apiUsers/button/edit',
    ufAddApiUser: 'accounts/admins/apiUsers/button/add',
    ufadminApiUserTabButton: 'accounts/admins/apiUsers/tab/',
    adminApiUsers: {
        ufBack: 'accounts/admins/apiUsers/button/back',
        ufCreate: 'accounts/admins/apiUsers/button/create',
        ufSave: 'accounts/admins/apiUsers/button/save',
        ufClose: 'accounts/admins/apiUsers/button/close',
    },
    adminApiUsersGeneral: {
        ufName: 'accounts/admins/apiUsers/input/name',
        ufIsActive: 'accounts/admins/apiUsers/checkbox/isActive',
        ufPermission: 'accounts/admins/apiUsers/input/permission',
        ufExpiration: 'accounts/admins/apiUsers/input/expiration',
        ufTime: 'accounts/admins/apiUsers/input/time',
    },
    adminApiUsersAdmin: {
        ufDelete: 'accounts/admins/apiUsers/button/delete',
        ufModalDelete: 'accounts/admins/apiUsers/modal/button/delete',
        ufReset: 'accounts/admins/apiUsers/button/reset',
        ufModalReset: 'accounts/admins/apiUsers/modal/button/reset',
    },
};
