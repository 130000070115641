export default {
    plans: {
        ufNameInput: '/plans/input/name',
        ufActiveCheckbox: '/plans/checkbox/active',
        ufDescriptionInput: '/plans/input/description',
        ufWholesalerSelect: '/plans/select/wholesaler',
        ufPartnerSelect: '/plans/select/partner',
        ufInternationalRateSelect: '/plans/select/internationalRate',
        ufInternationalDiscountInput: '/plans/input/internationalDiscount',
        ufBillCheckbox: '/plans/checkbox/bill',
        ufCountryInput: '/plans/input/country',
        ufPrefixInput: '/plans/input/prefix',
        ufCompanySetupFeeInput: '/plans/input/companySetupFee',
        ufSimplePortingFeeInput: '/plans/input/simplePortingFee',
        ufComplexPortingFeeInput: '/plans/input/complexPortingFee',
        ufOtherPortingFeeInput: '/plans/input/otherPortingFee',
        ufSetupFeeUserTd: '/plans/td/setupFeeUser',
        ufSetupFeeTrunkTd: '/plans/td/setupFeeTrunk',
        ufSetupFeeChannelsTd: '/plans/td/setupFeeChannels',
        ufSetupFeeResponseGroupTd: '/plans/td/setupFeeResponseGroup',
        ufRateUserTd: '/plans/td/rateUser',
        ufRateTrunkTd: '/plans/td/rateTrunk',
        ufRateChannelsTd: '/plans/td/rateChannels',
        ufRateResponseGroupTd: '/plans/td/rateResponseGroup',
        ufBillUserCheckbox: '/plans/checkbox/billUser',
        ufBillTrunkCheckbox: '/plans/checkbox/billTrunk',
        ufBillChannelsCheckbox: '/plans/checkbox/billChannels',
        ufBillResponseGroupCheckbox: '/plans/checkbox/billResponseGroup',
    },
    callingRates: {
        ufNameInput: '/plans/calling-rates/input/name',
        ufPriorityInput: '/plans/calling-rates/input/priority',
        ufPrefixInput: '/plans/calling-rates/input/prefix',
        ufFlagFallInRateInput: '/plans/calling-rates/input/flagFallInRate',
        ufFlagFallOutRateInput: '/plans/calling-rates/input/flagFallOutRate',
        ufInboundRateInput: '/plans/calling-rates/input/inboundRate',
        ufOutboundRateInput: '/plans/calling-rates/input/outboundRate',
    },
    callingInclusions: {
        ufNameInput: '/plans/calling-inclusions/input/name',
        ufPriorityInput: '/plans/calling-inclusions/input/priority',
        ufInclusionAmountInput:
            '/plans/calling-inclusions/input/inclusionAmount',
        ufInclusionTypeSelect: '/plans/calling-inclusions/select/inclusionType',
        ufAppliesToSelect: '/plans/calling-inclusions/select/appliesTo',
    },
    numberBlockRates: {
        ufPatternSelect: '/plans/number-block-rates/select/pattern',
        ufPrefixInput: '/plans/number-block-rates/input/prefix',
        ufBlockSizeSelect: '/plans/number-block-rates/select/blockSize',
        ufBillCheckbox: '/plans/number-block-rates/checkbox/bill',
        ufSetupFeeInput: '/plans/number-block-rates/input/setupFee',
        ufRateInput: '/plans/number-block-rates/input/rate',
        ufDeleteBtn: '/plans/number-block-rates/button/delete',
        ufSaveBtn: '/plans/number-block-rates/button/save',
    },
    deleteModal: {
        ufDeleteModal: '/plans/modal/deleteModal',
        ufDeleteBtn: '/plans/modal/button/delete',
        ufCancelBtn: '/plans/modal/button/cancel',
    },
    itemCodeMappings: {
        ufAccountCode: '/plans/input/accountCode',
        ufItemCode: '/plans/input/itemCode',
        ufTrackingName1: '/plans/input/trackingName1',
        ufTrackingOption1: '/plans/input/trackingOption1',
    },
};
