export default {
    invoices: {
        ufViewInvoiceBtn: '/invoices/button/viewInvoice',
        ufReloadBtn: '/invoices/button/reload',
    },
    invoiceLines: {
        ufInvoiceTotalInput: '/invoices/input/invoiceTotal',
        ufUpdateStatusBtn: '/invoices/button/updateStatus',
        ufAddInvoiceLineBtn: '/invoices/button/addInvoiceLine',
        ufRefreshIconBtn: 'table/toolbar/button/refreshBtn',
        ufDeleteForeverBtn: 'table/button/deleteForeverBtn',
        ufModalUpdateStatusBtn: 'invoices/modal/button/updateBtn',
        ufModalFromInput: 'invoices/modal/input/from',
        ufModalToInput: 'invoices/modal/input/to',
    },
    invoiceLineEdit: {
      ufQuantityInput: '/invoices/input/quantity',
      ufUnitPriceInput: '/invoices/input/unitPrice',
      ufDescriptionInput: '/invoices/input/description',
      ufModalItemCode: '/invoices/modal/input/itemCode',
    }
};
