import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Heading from 'components/v4/Heading';
import Button from 'components/v4/Button';
import deleteApprovalCode from 'API/ApprovalCode/deleteApprovalCode';

import Grid from 'components/v4/Grid';
import Text from 'components/v4/Text';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import { DeleteBox } from 'components/index';

export default function ApprovalCodeDelete({
    rowData,
    setApprovalCodesAction,
    setSuccessAction,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    async function del() {
        setIsLoading(true);
        setError(null);
        setSuccessAction(null);

        // Hidden fields
        const hiddenFields = rowData.id;

        // Create approval code
        deleteApprovalCode(hiddenFields)
            .then((response) => {
                if (response.status === 200) {
                    setSuccessAction('Delete');
                    setApprovalCodesAction('Get');
                    setTimeout(() => {
                        setSuccessAction(null);
                    }, 6000);
                } else {
                    setError(response.message);
                }
            })
            .catch((error) => {
                if (error?.resposne?.data?.message) {
                    setError(error.resposne.data.message);
                } else {
                    setError(
                        'Something went wrong when deleting this approval code.',
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Box sx={{ maxW: '4xl', mx: 'auto' }}>
            <DeleteBox
                isLoading={isLoading}
                onClick={del}
                buttonText={isLoading ? 'Deleting...' : 'Delete'}
                warningText={
                    'Are you sure you want to delete this approval code?'
                }
            />

            {/* Error */}
            {error && (
                <Alert status="error" sx={{ margin: '25px 0' }}>
                    <AlertIcon />
                    <AlertDescription>
                        Something went wrong when trying to delete this approval
                        code.
                    </AlertDescription>
                </Alert>
            )}
        </Box>
    );
}
