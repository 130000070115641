//reducer for tabs
//which tab you on in a table/modal

const initViewState = {
    breadcrumb: 'all',
    tab: 0,
    modalTab: 0,
};

export const viewReducer = (view = initViewState, action) => {
    switch (action.type) {
        case 'RESET_TABLE_MULTICHILD':
            return {...view, breadcrumb: action.payload};
        case 'CHANGE_BREADCRUMB_VIEW':
            return {...view, breadcrumb: action.payload};
        case 'SWITCH_CHILD_TABLE':
            return {...view, breadcrumb: action.payload};
        case 'CHANGE_TAB_VIEW':
            return {...view, tab: action.payload};
        case 'CHANGE_MODAL_TAB_VIEW':
            return {...view, modalTab: action.payload};
        case `REDIRECT`:
            return {...initViewState};
        case 'RESET_MODAL':
            return {...view, modalTab: 0};
        default:
            return view;
    }
};
