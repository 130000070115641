export default {
    MicrosoftAuthCard: {
        backgroundColor: '#FFF',
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        flexDirection: 'column',
        maxWidth: '300px',
        minWidth: '200px',
        boxShadow: 'base',
        padding: '15px',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: 'md',
            transition: 'all 0.5s',
        },
    },
    MicrosoftAuthCardImage: {maxWidth: '160px', mx: 'auto', mb: '10px'},
};
