import { useContext, useDebugValue } from 'react';

import { VisibilityGroupsContext } from './VisibilityGroupsContext';

export const useVisibilityGroup = () => {
    const context = useContext(VisibilityGroupsContext);
    useDebugValue(context, `is context wrapped: ${!!context}`);
    if (context === undefined) {
        throw new Error(
            'useVisibilityGroup must be used within a VisibilityGroupProvider',
        );
    }
    return context;
};
