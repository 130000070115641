import { motion } from 'framer-motion';
import { useLayoutContext } from 'context/LayoutContext';

export const AnimatedTextWord = ({ text }) => {
    const words = text.split(' ');
    const { isMenuCollapse } = useLayoutContext();

    const container = {
        hidden: { opacity: 0 },

        visible: (i = 1) => ({
            opacity: 1,
            transition: {
                staggerChildren: 0.36,
                delayChildren: 0.04 * i,
            },
        }),
    };

    const child = {
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: 'spring',
                damping: 12,
                stiffness: 100,
            },
        },
        hidden: {
            opacity: 0,
            x: -20,
            transition: {
                type: 'spring',
                damping: 12,
                stiffness: 100,
            },
        },
    };

    return (
        <motion.div
            style={{
                overflow: 'hidden',
                display: 'flex',
                marginInlineStart: 0,
            }}
            variants={container}
            initial={isMenuCollapse ? 'visible' : 'hidden'}
            animate={isMenuCollapse ? 'hidden' : 'visible'}
        >
            {words.map((word, i) => (
                <motion.span
                    key={i}
                    variants={child}
                    style={{
                        marginRight: '5px',
                        fontSize: '14px',
                    }}
                    className={isMenuCollapse ? 'hidden' : 'inherit'}
                >
                    {word}
                </motion.span>
            ))}
        </motion.div>
    );
};
