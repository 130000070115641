import { useState, useRef, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { initialState } from '../constants';
import { LoadingIndicator } from '@/components/v4';
import walkthroughIds from '../../walkthroughIds';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useResetMfa,
    useGetAdminUserById,
    useEditAdminUser,
    useDeleteAdminUser,
    useForgotPassword,
    useGetSSO,
    useGetAdminPermission,
} from './queries';
import FormBase from '@/components/forms/new/FormBase';
import DeleteAdminModal from 'components/modal/DeleteAdminModal';
import AdminUsersFirstName from './formFields/AdminUsersFirstName';
import AdminUsersLastName from './formFields/AdminUsersLastName';
import AdminUsersEmail from './formFields/AdminUsersEmail';
import AdminUsersManageAccess from './formFields/AdminUsersManageAccess';
import AdminUsersPermissionSetDropdown from './formFields/AdminUsersPermissionSetDropdown';
import AdminUsersResetMFA from './formFields/AdminUsersResetMFA';
import AdminUsersGlobalSwitches from './formFields/AdminUsersAPIDocReaderSwitch';

const AdminsEdit = () => {
    const { ufBackBtn, ufSaveBtn } = walkthroughIds.adminsGeneral;
    const { darkMode } = useSelector((state) => state.settings);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { adminId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = useRef(null);

    const {
        requiredScope: accountScope,
        token,
        email,
        ...rest
    } = useSelector((state) => state.login);

    const { currentCompany, currentCompanyData } = useSelector(
        (state) => state.navigationLists,
    );

    const [adminUser, setAdminUser] = useState({
        ...initialState,
        companyId: currentCompany,
    });

    const [oldAdmin, setOldAdmin] = useState({
        ...initialState,
        companyId: currentCompany,
    });

    const [disableMFA, setDisableMFA] = useState(false);

    const [isEditingThemself, setIsEditingThemself] = useState(false);
    const [ssoEnforced, setSSOEnforced] = useState(false);

    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false);
    const handleIsLoadingResetPasswordTrue = () => {
        setIsLoadingResetPassword(true);
    };
    const handleIsLoadingResetPasswordFalse = () => {
        setIsLoadingResetPassword(false);
    };

    const [succeed, setSucceed] = useState(false);
    const handleSetSucceedTrue = () => {
        setSucceed(true);
    };

    useEffect(() => {
        setIsEditingThemself(rest?.user === adminUser?.email);
        setAdminUser((prev) => ({
            ...prev,
            mfaEnabled: adminUser?.mfaEnabled,
            mfatotpConfirmed: adminUser?.mfatotpConfirmed,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest?.user]);

    useEffect(() => {
        if (!(isFetching || isRefetching)) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentCompanyData?.mfaEnforced) {
            setAdminUser((prev) => ({
                ...prev,
                mfaEnabled: true,
            }));
            setDisableMFA(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompanyData?.mfaEnforced]);

    const { mutate: resetMFA } = useResetMfa(
        adminId,
        isEditingThemself,
        dispatch,
        navigate,
    );

    const { mutate: resetPassword } = useForgotPassword(
        adminUser,
        handleIsLoadingResetPasswordTrue,
        handleIsLoadingResetPasswordFalse,
        handleSetSucceedTrue,
    );

    useGetSSO(currentCompany, setSSOEnforced);

    const {
        data: adminData,
        status: adminDataStatus,
        isFetching,
        isRefetching,
        refetch,
    } = useGetAdminUserById(adminId, token, setAdminUser, setOldAdmin);

    const { mutate: editAdminUser } = useEditAdminUser(
        adminUser,
        accountScope,
        oldAdmin,
        email,
        dispatch,
        navigate,
    );

    const { mutate: deleteAdminUser } = useDeleteAdminUser(
        adminId,
        token,
        accountScope,
        dispatch,
        navigate,
    );

    const handlFormSwitchChange = (e) => {
        const { name, checked } = e.target;
        setAdminUser((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const { status: permissionStatus } = useGetAdminPermission(
        currentCompany,
        accountScope,
    );

    if (permissionStatus === 'loading' || adminDataStatus === 'loading')
        return <LoadingIndicator />;

    if (adminId && adminData?.companyID !== currentCompany) {
        navigate('/admins');
    }

    const breadCrumbTrail = [
        { label: 'Admin Accounts', path: '/ui/admins' },
        { label: 'Admins Edit', path: `/ui/admins/${adminId}/edit` },
    ];
    const header = 'User Details';

    const finalFormSubmit = (value) => {
        const finalSubmit = {
            ...value,
            permissionSetID: adminUser?.permissionSetID,
            mfaEnabled: adminUser?.mfaEnabled,
            canOverrideOverStamping: adminUser?.canOverrideOverStamping,
            apiDocReader: adminUser?.apiDocReader,
            isActive: adminUser?.isActive,
        };

        setAdminUser(finalSubmit);

        if (adminId) {
            editAdminUser();
            return;
        }
    };

    const formFields = (errors, touched, values) => {
        const fields = [
            <AdminUsersFirstName
                key="firstName"
                errors={errors}
                touched={touched}
                values={values}
            />,
            <AdminUsersLastName key="lastName" darkMode={darkMode} />,
            <AdminUsersEmail
                key="email"
                errors={errors}
                touched={touched}
                darkMode={darkMode}
            />,
            <AdminUsersManageAccess
                key="manageAccess"
                adminUser={adminUser}
                handlFormSwitchChange={handlFormSwitchChange}
                darkMode={darkMode}
                ssoEnforced={ssoEnforced}
                isLoadingResetPassword={isLoadingResetPassword}
                succeed={succeed}
                resetPassword={resetPassword}
                onOpen={onOpen}
                adminId={adminId}
            />,
            <AdminUsersPermissionSetDropdown
                key={'permissionSetID'}
                permissionSetID={adminUser?.permissionSetID}
                currentCompany={currentCompany}
                accountScope={accountScope}
                setAdminUser={setAdminUser}
                errors={errors}
                touched={touched}
                darkMode={darkMode}
                values={values}
            />,
            <AdminUsersResetMFA
                key={'resetMFA'}
                adminUser={adminUser}
                handlFormSwitchChange={handlFormSwitchChange}
                ssoEnforced={ssoEnforced}
                resetMFA={resetMFA}
                darkMode={darkMode}
                disableMFA={disableMFA}
            />,
            <AdminUsersGlobalSwitches
                key={'globalSwitches'}
                accountScope={accountScope}
                adminUser={adminUser}
                handlFormSwitchChange={handlFormSwitchChange}
                darkMode={darkMode}
            />,
        ];

        return fields;
    };

    return (
        <>
            <FormBase
                breadCrumbTrail={breadCrumbTrail}
                header={header}
                initialValues={oldAdmin}
                finalFormSubmit={finalFormSubmit}
                backLink="/admins"
                ufBackBtn={ufBackBtn}
                ufSaveBtn={ufSaveBtn}>
                {({ errors, touched, values }) =>
                    formFields(errors, touched, values)
                }
            </FormBase>
            <DeleteAdminModal
                isOpen={isOpen}
                onClose={onClose}
                finalRef={finalRef}
                deleteOnClick={deleteAdminUser}
                adminUser={adminUser}
            />
        </>
    );
};

export default AdminsEdit;
