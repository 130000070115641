import React from 'react';
import TextField from '@material-ui/core/TextField';
import {Row, Col} from 'react-bootstrap';
import {addSpacing} from '../../../../../utils/utils';
import MaterialTable from '../../../../../components/v4/MaterialTable';
import Divider from '@material-ui/core/Divider';
const CONSENT_COLUMNS = {
    id: '',
    msid: '',
    tenantID: '',
    tenantOrigin: '',
    operatorID: '',
    status: '',
};
const CONTACT_COLUMNS = [
    {
        title: 'Full Name',
        field: 'fullName',
        defaultSort: 'asc',
    },
    {
        title: 'Email',
        field: 'email',
    },
    {
        title: 'Phone',
        field: 'phoneNumber',
    },
    {
        title: 'Company Name',
        field: 'companyName',
    },
    {
        title: 'Company Size',
        field: 'companySize',
    },
    {
        title: 'Default',
        field: 'isDefault',
        type: 'boolean',
    },
];

export default function Details({data}) {
    return (
        <div style={{padding: 10}}>
            {Object.keys(CONSENT_COLUMNS).map((v, i) => {
                return (
                    <Row key={i}>
                        <Col>
                            <TextField
                                fullWidth
                                value={data[v]}
                                disabled
                                label={addSpacing(v)}
                            />
                        </Col>
                    </Row>
                );
            })}
            <Divider />
            <MaterialTable
                title="Contacts"
                data={data.contacts?.filter((v) => v.isDefault)}
                columns={CONTACT_COLUMNS}
            />
        </div>
    );
}
