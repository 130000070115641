import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControl } from '@chakra-ui/react';
import Modal from '../../Modal';
import ModalOverlay from '../../ModalOverlay';
import ModalHeader from '../../ModalHeader';
import ModalCloseButton from '../../ModalCloseButton';
import ModalBody from '../../ModalBody';
import ModalContent from '../../ModalContent';
import ModalFooter from '../../ModalFooter';
import Button from '../../Button';
import Input from '../../Input';
import FormLabel from '../../FormLabel';
import Alert from '../../Alert';
import AlertIcon from '../../AlertIcon';
import { axios } from '../../../../services/axios';

//APIs
import createMicrosoftAppAuth from '../../../../API/AppAuth/createMicrosoftAppAuth';

export default function ServiceDashboardTenantId({ ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [tenantId, setTenantId] = useState('');
    const [domain, setDomain] = useState('');
    const currentCompany = useSelector(
        (state) => state.navigationLists.currentCompany,
    );

    const { requiredScope: accountScope } = useSelector((state) => state.login);
    const queryClient = useQueryClient();

    async function onTenantIdSubmit() {
        setIsLoading(true);
        await createMicrosoftAppAuth(
            {
                companyId: currentCompany,
                tenantId,
            },
            accountScope,
        )
            .then((response) => {
                if (response.status !== 200) {
                    return setError(response.response.data.message);
                }
                setSuccess('Succesfully submited Tenant ID.');
                props.setMsServiceAuth(response.data);
                queryClient.invalidateQueries([
                    'services',
                    `company: ${currentCompany}`,
                ]);
            })
            .catch((error) => {
                setError(error.response.data.message);
            })
            .finally(() => setIsLoading(false));
    }

    function reset() {
        props.onClose();
        setError(null);
        setSuccess(null);
    }

    function onDomainLookup() {
        setIsLoading(true);
        setError(null);
        setSuccess(null);
        axios(
            `https://login.microsoftonline.com/${domain}/.well-known/openid-configuration`,
        )
            .then((response) => {
                const id = response.data.authorization_endpoint
                    .replace('https://login.microsoftonline.com/', '')
                    .replace('/oauth2/authorize', '');
                setTenantId(id);
            })
            .catch((error) => {
                if (error.response.data.error === 'invalid_tenant') {
                    setError(
                        `Tenant '${domain}' not found. Check to make sure you have the correct tenant ID and are signing into the correct cloud. Check with your subscription administrator, this may happen if there are no active subscriptions for the tenant.`,
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Modal
            {...props}
            autoFocus={false}
            isCentered
            closeOnOverlayClick
            onClose={reset}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Submit Tenant ID</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Lookup Domain</FormLabel>
                        <Input onChange={(e) => setDomain(e.target.value)} />
                    </FormControl>
                    <Button
                        size="sm"
                        ml="auto"
                        mt="10px"
                        display="block"
                        onClick={onDomainLookup}
                        disabled={isLoading}>
                        Lookup
                    </Button>

                    <FormControl>
                        <FormLabel>Tenant ID</FormLabel>
                        <Input
                            value={tenantId}
                            onChange={(e) => setTenantId(e.target.value)}
                        />
                    </FormControl>
                    {error && (
                        <Alert
                            status="error"
                            sx={{ whiteSpace: 'pre-line' }}
                            mt="15px">
                            <AlertIcon />
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert
                            status="success"
                            sx={{ whiteSpace: 'pre-line' }}
                            mt="15px">
                            <AlertIcon />
                            {success}
                        </Alert>
                    )}
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button
                        disabled={isLoading || !tenantId.length}
                        colorScheme="blue"
                        mr={3}
                        onClick={onTenantIdSubmit}>
                        Submit
                    </Button>
                    <Button
                        disabled={isLoading}
                        colorScheme="gray"
                        onClick={reset}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
