import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { axios } from 'services/axios';
import { Box, Tooltip, forwardRef } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import Button from 'components/v4/Button';
import Grid from 'components/v4/Grid';
import Input from 'components/v4/Input';
import FormLabel from 'components/v4/FormLabel';
import updateServiceProfiles from 'API/ServiceProfiles/updateServiceProfiles';
import { Select as _MultiSelect, Select as _Select } from 'chakra-react-select';
import { multiSelectDataMapper, toSentenceCase } from 'utils/utils';
import getServiceProfiles from 'API/getServiceProfiles';
import style from './styles';
import FooterContainer from './FooterContainer';
import Heading from 'components/v4/Heading';
import Alert from 'components/v4/Alert';
import AlertDescription from 'components/v4/AlertDescription';
import AlertIcon from 'components/v4/AlertIcon';
import createServiceProfile from 'API/ServiceProfiles/createServiceProfile';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import getDRServiceProfileRegions from 'API/ServiceProfiles/getDRServiceProfileRegions';
import getDRServiceProfileDialPlans from 'API/ServiceProfiles/getDRServiceProfileDialPlans';
import getDRServiceProfileVoicePolicies from 'API/ServiceProfiles/getDRServiceProfileVoicePolicies';
import useFieldValidation from 'hooks/useFieldValidation';
import FieldValidation from 'components/v4/FieldValidation';

import { validationSchemaDR } from './schema';

import walkthroughIds from '../../walkthroughIds.js';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import { Container } from 'components/v4/Container/Container';
import config from '@/config.json';

const MultiSelect = forwardRef((props, ref) => (
    <_MultiSelect selectedOptionColor="brand" ref={ref} {...props} />
));

const Select = forwardRef((props, ref) => (
    <_Select selectedOptionColor="brand" ref={ref} {...props} />
));

/*
 * Direct Routing Component for Edit/Add
 */
const DirectRouting = ({
    mode,
    data,
    level,
    handleBackButton,
    visibleScopeOptions,
}) => {
    const [voicePolicyEnum, setVoicePolicyEnum] = useState([]);
    const [regionsEnum, setRegionsEnum] = useState([]);
    const [dialPlansEnum, setDialPlansEnum] = useState([]);
    const {
        currentWholesaler: wholesalerID,
        currentPartner: partnerID,
        wholesalers,
        companiesAndPartners,
    } = useSelector((state) => state.navigationLists);
    const { requiredScope } = useSelector((state) => state.login);

    const { ...ufElements } = walkthroughIds.serviceProfiles;
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };

    const DEFAULT_DR_PAYLOAD = {
        name: 'Direct Routing Wholesaler Profile',
        partnerID: level === 'partner' ? partnerID : undefined,
        wholesalerID: level === 'partner' ? undefined : wholesalerID,
        voicePolicies: [],
        dialPlans: [],
        regions: [],
        visibleScope: level === 'partner' ? 20 : 60,
    };

    useEffect(() => {
        if (mode !== 'EDIT' && level !== 'partner' && wholesalers?.length > 0) {
            const wsName = wholesalers.find(
                ({ id, name }) => id === wholesalerID,
            )?.name;
            handleChangeState({
                name: `${wsName} - Direct Routing Wholesaler Profile`,
            });
        } else if (
            mode !== 'EDIT' &&
            level === 'partner' &&
            wholesalers?.length > 0
        ) {
            const partnerName = companiesAndPartners.find(
                ({ id, name }) => id === partnerID,
            )?.name;
            handleChangeState({
                name: `${partnerName} - Direct Routing Partner Profile`,
            });
        }
    }, [mode, companiesAndPartners, wholesalers, level]);

    const [modalState, setModalState] = useState(data || DEFAULT_DR_PAYLOAD);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaDR,
    );

    /*
     * A function to get the current scope and append it for API calls
     */
    const scope = () => {
        if (level === 'partner') {
            return `Partner/${partnerID}`;
        } else {
            return `Wholesaler/${wholesalerID}`;
        }
    };
    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    const handleGetVoicePolicies = () => {
        getDRServiceProfileVoicePolicies({
            scope: scope(),
            regions: modalState.regions,
        })
            .then((res) => {
                setVoicePolicyEnum(res.data.map(multiSelectDataMapper));
            })
            .catch((e) => {
                setError(e);
            });
    };

    const handleGetDropdown = () => {
        setIsLoading(true);
        const fetchSPData = mode === 'EDIT' && data;
        Promise.all([
            fetchSPData
                ? getServiceProfiles({ level, id: data?.id })
                : setTimeout(() => {
                      Promise.resolve();
                  }, 1),
            getDRServiceProfileRegions({ scope: scope() }),
            getDRServiceProfileDialPlans({ scope: scope() }),
        ])
            .then(([spResult, regionsResult, dialPlansResult]) => {
                setRegionsEnum(regionsResult.data.map(multiSelectDataMapper));
                setDialPlansEnum(
                    dialPlansResult.data.map(multiSelectDataMapper),
                );
                if (fetchSPData) {
                    const regions = spResult.data.regions.map(
                        multiSelectDataMapper,
                    );
                    const dialPlans = spResult.data.dialPlans.map(
                        multiSelectDataMapper,
                    );
                    const voicePolicies = spResult.data.voicePolicies.map(
                        multiSelectDataMapper,
                    );

                    setModalState({
                        ...spResult.data,
                        regions,
                        dialPlans,
                        voicePolicies,
                    });
                }
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            });
    };

    const handleSubmit = () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }

        setIsLoading(true);
        let result = {};
        const params = {
            type: 'DirectRouting',
            payload: modalState,
            level,
            scope: requiredScope,
        };

        if (mode === 'EDIT') {
            result = updateServiceProfiles(params);
        } else {
            result = createServiceProfile(params);
        }
        result
            .then((res) => handleBackButton())
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (modalState.regions && modalState.regions.length > 0) {
            handleGetVoicePolicies();
        }
    }, [modalState.regions]);

    useEffect(() => {
        handleGetDropdown();
    }, [mode, data, level]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Container>
            <Heading fontSize="3xl" as="h2" mb={'1rem'}>
                {`${toSentenceCase(mode)} ${modalState.name || data?.name}`}
            </Heading>

            {error && (
                <Alert status="error" sx={{ marginBottom: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Service name</FormLabel>
                <Input
                    value={modalState.name}
                    onChange={(e) =>
                        handleChangeState({ name: e.target.value })
                    }
                    sx={style.inputField}
                    data-walkthroughid={ufElements.ufServiceNameInput}
                />
                <FieldValidation errors={validationErrors?.name} />
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>
                    Visible Scope &nbsp;
                    <Tooltip
                        label="Determine visibility and service creation level"
                        hasArrow
                        placement="top-end">
                        <InfoIcon />
                    </Tooltip>
                </FormLabel>
                {modalState.visibleScope <= requiredScope ? (
                    <Select
                        defaultValue={visibleScopeOptions.find(
                            (option) =>
                                option.value === modalState.visibleScope,
                        )}
                        options={visibleScopeOptions}
                        chakraStyles={chakraStyles}
                        onChange={(selectedOption) => {
                            handleChangeState({
                                visibleScope: selectedOption.value,
                            });
                        }}
                        selectedOptionColor="brand"
                    />
                ) : (
                    <Input
                        isDisabled
                        value={config.scope.json[modalState.visibleScope]}
                    />
                )}
            </Box>

            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufDialPlansSelect}>
                <FormLabel sx={style.label}>Dial Plans</FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Dial Plans -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({
                            dialPlans: selectedOptions,
                        });
                    }}
                    options={dialPlansEnum}
                    defaultValue={modalState.dialPlans}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.dialPlans} />
            </Box>
            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufRegionsSelect}>
                <FormLabel sx={style.label}>Regions</FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Regions -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({
                            regions: selectedOptions,
                        });
                    }}
                    options={regionsEnum}
                    defaultValue={modalState.regions}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.regions} />
            </Box>
            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufVoicePoliciesSelect}>
                <FormLabel sx={style.label}> Voice Policies </FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Voice Policies -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({
                            voicePolicies: selectedOptions,
                        });
                    }}
                    options={voicePolicyEnum}
                    defaultValue={modalState.voicePolicies}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.voicePolicies} />
            </Box>

            <Box mb={4} aria-label="spacing box" />

            <FooterContainer>
                <Button
                    variant="outline"
                    w={{ base: '100%', md: '80px' }}
                    sx={{ background: 'white' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufElements.ufCloseButton}>
                    Close
                </Button>
                <Button
                    w={{ base: '100%', md: '80px' }}
                    onClick={handleSubmit}
                    data-walkthroughid={ufElements.ufUpdateButton}>
                    {mode === 'ADD' ? 'Add' : 'Update'}
                </Button>
            </FooterContainer>
        </Container>
    );
};

export default DirectRouting;
