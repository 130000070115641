export default {
    security: {
        ufTabButton: 'settingsSidebar/security/tab/',
    },
    settingsSSO: {
        ufDisableSSOBtn: 'accounts/admins/sso/button/disable',
        ufEnableSSOSwitch: 'accounts/admins/sso/switch/enable',
        ufEnforceSSOBtn: 'accounts/admins/sso/checkbox/enforce',
        ufEnforceSSOSwitch: 'accounts/admins/sso/switch/enforce',
        ufSaveBtn: 'accounts/admins/sso/button/save',
        ufCloseBtn: 'accounts/admins/sso/button/close',
        ufProviderBtn: 'accounts/admins/sso/button/provider',
        ufDeactivateBtn: 'accounts/admins/sso/button/deactivate',
        ufVerifyBtn: 'accounts/admins/sso/button/verify/',
        ufActiveBtn: 'accounts/admins/sso/button/active/',
        ufEditBtn: 'accounts/admins/sso/button/edit/',
        ufTenantInput: 'accounts/admins/sso/input/tenant',
        ufOptionBtn: 'accounts/admins/sso/button/option',
        ufEmailInput: 'accounts/admins/sso/input/email',
        ufLookupBtn: 'accounts/admins/sso/button/lookup',
        ufBackBtn: 'accounts/admins/sso/button/back',
        ufSubmitBtn: 'accounts/admins/sso/button/submit',
        ufLookupSearchBtn: 'accounts/admins/sso/lookup/button/search',
        ufEmailLookupInput: 'accounts/admins/sso/lookup/input/email',
        ufLookupCloseBtn: 'accounts/admins/sso/lookup/button/close',
        ufLookupAddBtn: 'accounts/admins/sso/lookup/button/add',
        ufProviderSelect: 'accounts/admins/sso/select/provider',
    },
};
