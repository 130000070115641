import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Link } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import TableAndModal, {
    modalTitle,
    onModalError,
} from '../../../components/tables/TableAndModal';
import { TriggersEdit, History } from '..';
import { LoadingFieldDropdown, ModalCopy } from '../../../components';
import config from '../../../config.json';
import { axios } from '../../../services/axios';
import { addSpacing } from '../../../utils/utils';

import walkthroughIds from './walkthroughIds';

// default columns for the table
const defaultColumns = [
    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Description',
        field: 'description',
        type: 'string',
        width: '30%',
    },
    {
        title: 'Type',
        field: 'triggerType',
    },
    {
        title: 'Entity',
        field: 'entity',
    },

    {
        title: 'Action',
        field: 'actionName',
    },

    {
        title: 'Event',
        field: 'eventName',
        type: 'string',
    },

    {
        title: 'History Count',
        field: 'historyCount',
        width: '5%',
    },
    {
        title: 'Last Fired At',
        field: 'lastFiredAt',
        type: 'datetime',
    },
    {
        title: 'Enabled',
        field: 'enabled',
        width: '5%',
        type: 'boolean',
    },
];

/*
 * CRUD base component for the Triggers page of the sidebar
 */
const Triggers = () => {
    const dispatch = useDispatch();
  const tableRef = useRef();

    //scope from state
    const { currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // child and parent from table state
    const {
        child,
        parent,
        state: tableState,
    } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // navigation lists from state
    const {
        companiesAndPartners,
        wholesalers,
        currentCompany,
        currentPartner,
        currentWholesaler,
    } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // modal information from state
    const { modalData, modalState, modalMode, modalStateError } = useSelector(
        (state) => {
            return {
                modalLoading: state.modal.loading,
                modalData: state.modal.data,
                modalState: state.modal.state,
                modalMode: state.modal.mode,
                modalStateError: state.modal.stateError,
                modalUploading: state.modal.uploading,
                modalLoadFields: state.modal.loadFields,
                modalShow: state.modal.show,
            };
        },
    );

    const { deleteMode, addMode } = walkthroughIds;

    // Fetch function based on scope for the TableAndModal
    const tableFetch = () => {
        switch (currentScope) {
            case 20:
                return '/triggers/list?companyID=' + currentCompany;
            case 40:
                return '/triggers/list?partnerID=' + currentPartner;
            case 60:
                return '/triggers/list?wholesalerID=' + currentWholesaler;
            default:
                return '/triggers/list';
        }
    };

    //
    useEffect(() => {
        if (
            !child &&
            !tableState?.modified &&
            tableState?.data?.length > 0
        ) {
            const newTableData = tableState.data.map(
                ({
                    eventName,
                    ...rest
                }) => ({
                    ...rest,
                    eventName: addSpacing(eventName),
                }),
            );
            dispatch({
                type: 'CHANGE_TABLE_STATE',
                payload: {
                    columns: defaultColumns,
                    data: newTableData,
                    modified: true,
                },
            });
            return;
        }
    }, [
        tableState?.data,
        tableState?.modified,
        child,
    ]);

    // function to determine modal content of  the TableAndModal Component
    const modalContent = () => {
        switch (modalMode) {
            case 'Delete':
                return (
                    <>
                        <p>
                            Are you sure you would like to delete this trigger?
                        </p>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    value={modalState?.name}
                                    label="Trigger Name"
                                    fullWidth
                                    disabled
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                name: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        deleteMode.ufTriggerName
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    value={modalState?.description}
                                    label="Trigger Description"
                                    fullWidth
                                    disabled
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                description: e.target.value,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        deleteMode.ufTriggerDescription
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                );
            case 'Copy':
                return (
                    <ModalCopy
                        modalData={modalData}
                        modalState={modalState}
                        modalStateError={modalStateError}
                        setModalState={(payload) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload,
                            });
                        }}
                    />
                );
            default:
                return <TriggersEdit />;
        }
    };

    // View trigger history
    if (child) {
        return (
            <>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        color="inherit"
                        href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                                type: 'RESET_CHILD_TABLE',
                            });
                        }}>
                        All Triggers
                    </Link>
                    <div style={{ minWidth: 500 }}>
                        <LoadingFieldDropdown
                            dropDownValue="id"
                            groupBy={(option) => option?.eventName}
                            onDisplayDropdown={(value) => {
                                if (typeof value === 'string') {
                                    const { eventName, triggerType, actionName } =
                                        parent?.find((v) => v.id === value);
                                    return (
                                        eventName +
                                        ' - ' +
                                        triggerType +
                                        ' - ' +
                                        actionName
                                    );
                                }
                                return (
                                    value?.eventName +
                                    ' - ' +
                                    value?.triggerType +
                                    ' - ' +
                                    value?.actionName
                                );
                            }}
                            staticEnum={parent}
                            searchable
                            onChange={(v) => {
                                dispatch({
                                    type: 'CHANGE_CHILD_TABLE',
                                    payload: { id: v },
                                });
                            }}
                            noEmptyOption
                            fieldValue={child.id}
                        />
                    </div>
                </Breadcrumbs>
                <History tableFetch={tableFetch()} />
            </>
        );
    }

    // If there isnt a child, return the TableAndModal component
    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                add={{ buttonText: 'Triggers' }}
                title="Triggers"
                tableFetch={tableFetch()}
                modalTitle={modalTitle(
                    modalData,
                    'name',
                    ' Trigger ',
                    modalMode,
                )}
                onModalCopy={() => {
                    axios
                        .post('/trigger', {
                            ...modalState,
                            name:
                                modalState?.copyName ?? modalState?.name ?? '',
                        })
                        .then((res) => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Edit',
                                    data: res.data,
                                },
                            });
                            dispatch({
                                type: 'CHANGE_MODAL_HASH',
                            });
                        })
                        .catch((e) => {
                            if (typeof onModalError !== 'undefined') {
                                onModalError(e);
                            }
                        });
                }}
                newPage={modalMode === 'Edit' || modalMode === 'Add'}
                dynamicScope
                defaultColumns={defaultColumns}
                modalContent={modalContent()}
                modalAdd="/trigger"
                modalUpdate="/trigger"
                modalValidate={() => {
                    var newErrorState;
                    if (modalMode === 'Copy') {
                        newErrorState = {
                            copyName:
                                (!modalState?.copyName ||
                                    modalState?.copyName?.length < 1 ||
                                    modalState?.copyName?.length > 100) &&
                                'Field is required and maximum field length is 100 characters.',
                        };
                        dispatch({
                            type: 'CHANGE_MODAL_STATE_ERROR',
                            payload: newErrorState,
                        });
                        return !newErrorState.copyName;
                    }
                    newErrorState = {
                        name:
                            (!modalState?.name ||
                                modalState?.name?.length < 1 ||
                                modalState?.name?.length > 100) &&
                            'Field is required and maximum length is 100 characters.',
                        description:
                            modalState?.description?.length > 200 &&
                            'Maximum field length is 200 characters.',
                        triggerType:
                            (!modalState?.triggerType ||
                                modalState?.triggerType?.length < 1) &&
                            'Field is required.',
                        companyID:
                            modalState?.triggerType === 'Company' &&
                            (!modalState?.companyID ||
                                modalState?.companyID?.length < 1) &&
                            'Field is required.',
                        partnerID:
                            modalState?.triggerType === 'Partner' &&
                            (!modalState?.partnerID ||
                                modalState?.partnerID?.length < 1) &&
                            'Field is required.',
                        wholesalerID:
                            modalState?.triggerType === 'Wholesaler' &&
                            (!modalState?.wholesalerID ||
                                modalState?.wholesalerID?.length < 1) &&
                            'Field is required.',
                        eventID:
                            (!modalState?.eventID ||
                                modalState?.eventID?.length < 1) &&
                            'Field is required.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });

                    return !(
                        newErrorState.name ||
                        newErrorState.description ||
                        newErrorState.triggerType ||
                        newErrorState.companyID ||
                        newErrorState.partnerID ||
                        newErrorState.wholesalerID ||
                        newErrorState.eventID
                    );
                }}
                modalDelete={'/trigger/' + modalData?.id}
                modalFetch={'/trigger/' + modalData?.id}
                rowActions={{
                    editable: true,
                    childTable: {
                        name: 'Trigger History',
                        doNotOverrideOnModalSucceed: true,
                        hidden: (rowData) => rowData.historyCount > 0,
                    },
                    copiable: true,
                }}
                modalButtons={
                    modalMode === 'Add' && (
                        <>
                            <Button
                            className='min-w-[5rem]'
                                variant="primary"
                                onClick={() => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            enabled: false,
                                        },
                                    });
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={addMode.ufAddBtn}>
                                Add
                            </Button>
                            <Button
                                variant="primary"
                                style={{ marginLeft: 24 }}
                                onClick={() => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            enabled: true,
                                        },
                                    });
                                    dispatch({
                                        type: 'ON_MODAL_SUBMIT',
                                        payload: true,
                                    });
                                }}
                                data-walkthroughid={addMode.ufAddEnableBtn}>
                                Add and Enable
                            </Button>
                        </>
                    )
                }
                modalUpload={(freshData, state) => {
                    switch (modalMode) {
                        case 'Delete':
                            return { ...state };
                        default:
                            return {
                                ...state,
                                action: {
                                    ...state?.action,
                                    emailAddress:
                                        state?.action?.emailAddress.join(','),
                                    headers:
                                        state?.actionHeaders
                                            ?.filter(
                                                (v) =>
                                                    v.key.length > 0 ||
                                                    v.value.length > 0,
                                            )
                                            .reduce((res, current) => {
                                                res[current.key] =
                                                    current.value;
                                                return res;
                                            }, {}) ?? state?.action?.headers,
                                },
                            };
                    }
                }}
                modalInitState={{
                    action: { actionType: 'Email' },
                    triggerType: config.triggers.type[currentScope / 20 - 1],
                    companyID: currentCompany,
                    partnerID: currentPartner,
                    wholesalerID: currentWholesaler,
                }}
            />
        </>
    );
};

export default Triggers;
