import { makeStyles } from '@material-ui/core/styles';
import {
    ModalCloseButton,
    Code,
    Link,
    Heading,
    Divider,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    Text,
    Button,
} from '@/components/v4';
import walkthroughIds from '../../users/walkthroughIds';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
    content: {
        paddingInline: '5%',
    },
}));

const useTroubleshoot = () => {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRARedirect = (e) => {
        e.preventDefault();
        navigate('/virtualuserguide');
    };
    const handleAACQRedirect = (e) => {
        e.preventDefault();
        navigate('/guide');
    };

    return {
        issueHeader: `Can't see all your user accounts?`,
        modalHeader: `Help`,
        issueBody: `TCAP requires the MSOL User's UserType to be 'Member'.\nThis can be done via PowerShell by:`,
        issueCode: `Get-MsolUser -All | Where-Object UserType -eq $Null | Set-MsolUser -UserType 'Member'`,
        issueLink:
            'https://docs.microsoft.com/en-us/powershell/module/msonline/set-msoluser?view=azureadps-1.0',
        clickHereText: `Click here`,
        issueLinkText: ' for more references.',
        aacqHeader: 'Step by Step Guide to Auto Attendant and Queues',
        raHeader: ' Step by Step Guide to licensing a resource account',
        handleRARedirect,
        handleAACQRedirect,
        viewFullGuideText: ' to view our full guide.',
    };
};

/*
 * Pop up when you click the '?' button on any of the accounts pages - this links to some guides on setting up TCAP
 */
export default function TroubleshootModal({ isOpen, onClose }) {
    const classes = useStyles();
    const {
        issueHeader,
        modalHeader,
        issueBody,
        issueCode,
        issueLink,
        clickHereText,
        issueLinkText,
        aacqHeader,
        raHeader,
        handleRARedirect,
        handleAACQRedirect,
        viewFullGuideText,
    } = useTroubleshoot();
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay backdropBlur="blur(10px)" />
            <ModalContent data-walkthroughid={walkthroughIds.modal.ufModal}>
                <ModalHeader>{modalHeader}</ModalHeader>
                <ModalCloseButton />
                <Divider />
                <ModalBody py={8}>
                    <div className={classes.root}>
                        <div className={classes.content}>
                            <Heading as="h4" fontSize="xl">
                                {issueHeader}
                            </Heading>
                            <Text style={{ whiteSpace: 'pre-line' }}>
                                {issueBody}
                            </Text>
                            <Text>
                                <pre>
                                    <Code>{issueCode}</Code>
                                </pre>
                            </Text>
                            <Text>
                                <Link
                                    _hover={{
                                        color: 'brand.700',
                                        textDecoration: 'none',
                                    }}
                                    color="brand.500"
                                    target="_blank"
                                    href={issueLink}
                                    data-walkthroughid={
                                        walkthroughIds.modal.ufMicrosoftGuide
                                    }>
                                    {clickHereText}
                                </Link>
                                {issueLinkText}
                            </Text>
                            <br />
                            <Heading as="h4" fontSize="xl">
                                {aacqHeader}
                            </Heading>
                            <Text>
                                <Link
                                    _hover={{
                                        color: 'brand.700',
                                        textDecoration: 'none',
                                    }}
                                    color="brand.500"
                                    target="_blank"
                                    href="/guide"
                                    onClick={handleAACQRedirect}
                                    data-walkthroughid={
                                        walkthroughIds.modal.ufAAGuide
                                    }>
                                    {clickHereText}
                                </Link>
                                {viewFullGuideText}
                            </Text>
                            <br />
                            <Heading as="h4" fontSize="xl">
                                {raHeader}
                            </Heading>
                            <Text>
                                <Link
                                    _hover={{
                                        color: 'brand.700',
                                        textDecoration: 'none',
                                    }}
                                    color="brand.500"
                                    target="_blank"
                                    href="/virtualuserguide"
                                    onClick={handleRARedirect}
                                    data-walkthroughid={
                                        walkthroughIds.modal.ufResourceGuide
                                    }>
                                    {clickHereText}
                                </Link>
                                {viewFullGuideText}
                            </Text>
                        </div>
                    </div>
                </ModalBody>
                <Divider />
                <ModalFooter justifyContent={'center'}>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
