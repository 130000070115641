import React, { useState, forwardRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Popover,
    Tooltip,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import { Box, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import toNumber from 'lodash/toNumber';
import { Heading as ChakraHeading } from 'components/v4';

import BillingNoteTable from './BillingNoteTable';
import {
    FloatSurcharge,
    FloatSurchargePercentage,
    NonNegativeInteger,
} from '../../../components/maintenance/NumberFormatCustom';
import { LoadingFieldDropdown, MultiSelect } from '../../../components';
import { onModalError } from '../../../components/tables/TableAndModal';
import config from '../../../config.json';
import RestoreDefaultModal from './RestoreDefaultModal';
import ChangeBillingDay from '../companies/edit/ChangeBillingDay';
import walkthroughIds from '../walkthroughIds';
import { InvoiceFormatFields } from './billing/InvoiceFormatFields';
import { invoiceFormatList } from './constants';

export const COMPANY = 'COMPANY';
export const PARTNER = 'PARTNER';
export const WHOLESALER = 'WHOLESALER';

const Heading = forwardRef((props, ref) => (
    <ChakraHeading
        as="h3"
        size="lg"
        fontWeight="500"
        marginBottom=".5rem"
        ref={ref}
        {...props}
    />
));

Heading.displayName = 'Heading';

// theme from material ui
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
        fontWeight: 'bold',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    buttoncol: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
    },
}));

/*
 * Billing form tab when editing a company
 * Renders a standard form, CDR List and a Billing Note Table
 */
const BillingForm = (props) => {
    const { mode } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [globalCursorPosition, setGlobalCursorPosition] = useState({}); //cursor tracker.
    const [confirmation, setConfirmation] = useState({
        show: false,
        content: '',
        changedStates: {},
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const isCompany = mode === COMPANY;
    const isPartner = mode === PARTNER;
    const isWholesaler = mode === WHOLESALER;

    const billingDayDropdown = React.useMemo(() => {
        return Array.from(Array(29).keys()).map((v) => {
            if (v === 0) {
                return {
                    label: 'Company Creation Date',
                    value: v,
                };
            }
            return {
                label: v,
                value: v,
            };
        });
    }, []);
    const [openModal, setOpenModal] = useState(false);
    const [expanded, setExpanded] = useState({
        RGS: false,
        'Number Block': false,
        CDR: false,
        User: false,
    });

    // modal data from state
    const {
        data: modalData,
        state,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // scope data from state
    const { currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // navigation from state
    const { currentWholesaler } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { billing } = walkthroughIds.companies;

    const handleOpenBillingDayModal = () => setOpenModal(true);
    const handleCloseBillingDayModal = () => setOpenModal(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const id = open ? 'surcharge-popover' : undefined;
    //change handler
    const handleChange = (panel) => (event) => {
        if (event.defaultPrevented) {
            return;
        }
        setExpanded({ ...expanded, [panel]: !expanded[panel] });
    };

    // const to determine the billingState for use in queries
    const billingState = isCompany
        ? state.companyBillingSettings
        : isWholesaler
          ? state.wholesalerBillingSettings
          : state.partnerBillingSettings;

    // function to set the billing state in the store
    const setBillingState = (payload) => {
        switch (mode) {
            case WHOLESALER:
                return dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        ...state,
                        wholesalerBillingSettings: {
                            ...state.wholesalerBillingSettings,
                            ...payload,
                        },
                    },
                });
            case COMPANY:
                return dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        ...state,
                        companyBillingSettings: {
                            ...state.companyBillingSettings,
                            ...payload,
                        },
                    },
                });
            case PARTNER:
                return dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        ...state,
                        partnerBillingSettings: {
                            ...state.partnerBillingSettings,
                            ...payload,
                        },
                    },
                });
            default:
                break;
        }
    };

    // function to determine what is rendered in the plans field
    const plansField = () => {
        switch (mode) {
            case COMPANY:
                return (
                    <>
                        <Grid item xs={12}>
                            <MultiSelect
                                onError={onModalError}
                                label="Plans"
                                placeholder="+"
                                dependency={modalData?.id}
                                fieldFetch={
                                    '/company/' +
                                    modalData?.id +
                                    '/plans/available'
                                }
                                fixedOptions={[{ id: state?.defaultPlanID }]}
                                setState={(v) =>
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            plans: v,
                                        },
                                    })
                                }
                                value={state?.plans}
                                data-walkthroughid={billing.ufPlansSelect}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingFieldDropdown
                                searchable
                                fieldName="Default Plan"
                                dropDownValue="id"
                                onError={onModalError}
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            defaultPlanID: v,
                                        },
                                    });
                                }}
                                fieldValue={state?.defaultPlanID}
                                staticEnum={state?.plans ?? []}
                                noEmptyOption
                                dataWalkthroughid={billing.ufDefaultPlanSelect}
                            />
                        </Grid>
                    </>
                );
            case PARTNER:
                return (
                    <>
                        <Grid item xs={12}>
                            <LoadingFieldDropdown
                                searchable
                                fieldName="Default Partner Plan"
                                dropDownValue="id"
                                onError={onModalError}
                                displayField="name"
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            defaultPartnerPlanID: v,
                                        },
                                    });
                                }}
                                helperText={
                                    'If there is no Default Partner Plan set, this partner will not be billed.'
                                }
                                fieldValue={state?.defaultPartnerPlanID}
                                toggleLimit={5}
                                fieldFetch={
                                    '/partner/' +
                                    modalData?.id +
                                    '/plans/available'
                                }
                                dependency={currentWholesaler}
                                noEmptyOption
                                dataWalkthroughid={
                                    billing.ufDefaultPartnerPlanSelect
                                }
                            />
                        </Grid>
                    </>
                );
            case WHOLESALER:
                return (
                    <>
                        <Grid item xs={12}>
                            <LoadingFieldDropdown
                                searchable
                                fieldName="Default Plan"
                                dropDownValue="id"
                                onError={onModalError}
                                displayField="name"
                                helperText={
                                    'If there is no Default Plan set, this wholesaler will not be billed.'
                                }
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            defaultPlanID: v,
                                        },
                                    });
                                }}
                                fieldValue={state?.defaultPlanID}
                                toggleLimit={5}
                                noEmptyOption
                                fieldFetch={`/wholesaler/${modalData?.id}/plans/available`}
                                dataWalkthroughid={billing.ufDefaultPlanSelect}
                            />
                        </Grid>
                    </>
                );
            default:
                return null;
        }
    };

    // Form that is rendered for this entire commponent
    return (
        <Form>
            <Box
                sx={{
                    flexGrow: 1,
                    marginTop: 2,
                    marginBottom: 4,
                    marginLeft: '8%',
                    marginRight: '8%',
                }}>
                <ChangeBillingDay
                    handleCloseModal={handleCloseBillingDayModal}
                    openModal={openModal}
                    billingDayDropdown={billingDayDropdown.filter(
                        ({ value }) => value > 0,
                    )}
                    currentBillingDay={billingState?.billingDay}
                    companyID={isCompany ? state?.id : undefined}
                    partnerID={isPartner ? state?.id : undefined}
                    wholesalerID={isWholesaler ? state?.id : undefined}
                    mode={mode}
                    handleRefreshModal={(data) => {
                        const newBillingDay =
                            data[`${mode?.toLowerCase()}BillingSettings`]
                                .billingDay;
                        setBillingState({
                            billingDay: newBillingDay,
                        });
                    }}
                />
                <Heading>Billing Settings</Heading>
                <Grid container spacing={2}>
                    {plansField()}
                    {!isWholesaler && (
                        <Grid item xs={4}>
                            <LoadingFieldDropdown
                                style={{ marginBottom: 10 }}
                                fieldName="Bill To"
                                staticEnum={
                                    isCompany
                                        ? ['Company', 'Partner']
                                        : ['Partner', 'Wholesaler']
                                }
                                noEmptyOption
                                onChange={(v) => {
                                    setBillingState({
                                        billTo: v,
                                    });
                                }}
                                toggleLimit={3}
                                fieldValue={billingState?.billTo}
                                dataWalkthroughid={billing.ufBillToSelect}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <TextField
                            label="Billing Day"
                            size="small"
                            type="number"
                            value={billingState?.billingDay}
                            fullWidth
                            disabled
                            onChange={(e) => {
                                setBillingState({
                                    billingDay: e.target.value,
                                });
                            }}
                            data-walkthroughid={billing.ufBillingDayInput}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ marginTop: 5 }}>
                            <Button
                                onClick={handleOpenBillingDayModal}
                                data-walkthroughid={
                                    billing.ufChangeBillingDayButton
                                }>
                                {' '}
                                Change Billing Day{' '}
                            </Button>
                        </div>
                    </Grid>
                    {isPartner && (
                        <Grid item xs={6}>
                            <LoadingFieldDropdown
                                fieldName="Default Companies Billing Day"
                                dropDownValue="value"
                                displayField="label"
                                staticEnum={billingDayDropdown}
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            partnerBranding: {
                                                ...state?.partnerBranding,
                                                defaultBillingDay: v,
                                            },
                                        },
                                    });
                                }}
                                fieldValue={
                                    state?.partnerBranding?.defaultBillingDay
                                }
                                noEmptyOption
                                dataWalkthroughid={
                                    billing.ufDefaultCompaniesBillingDaySelect
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ marginLeft: 1 }} direction="row">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={billingState?.leadInProrata}
                                    onChange={() =>
                                        setBillingState({
                                            leadInProrata:
                                                !billingState.leadInProrata,
                                        })
                                    }
                                    data-walkthroughid={
                                        billing.ufLeadInProRataCheckbox
                                    }
                                />
                            }
                            label="Lead In Prorata"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={billingState?.processBilling}
                                    onChange={() =>
                                        setBillingState({
                                            processBilling:
                                                !billingState?.processBilling,
                                        })
                                    }
                                    data-walkthroughid={
                                        billing.ufProcessBillingCheckbox
                                    }
                                />
                            }
                            label="Process Billing"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={billingState?.omitNumberBlocks}
                                    onChange={() => {
                                        setBillingState({
                                            omitNumberBlocks:
                                                !billingState?.omitNumberBlocks,
                                        });
                                        if (!billingState?.omitNumberBlocks) {
                                            toast.info(
                                                'Stop generating invoices (only for this entity)',
                                                {
                                                    toastId:
                                                        new Date()
                                                            .getTime()
                                                            .toString() +
                                                        Math.random(),
                                                },
                                            );
                                        }
                                    }}
                                    data-walkthroughid={
                                        billing.ufOmitNumberBlocksCheckbox
                                    }
                                />
                            }
                            label="Omit Number Blocks"
                        />
                        {isWholesaler && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            billingState?.alwaysBillGlobalNumberBlocks
                                        }
                                        onChange={() => {
                                            setBillingState({
                                                alwaysBillGlobalNumberBlocks:
                                                    !billingState?.alwaysBillGlobalNumberBlocks,
                                            });
                                        }}
                                        data-walkthroughid={
                                            billing.ufAlwaysBillGlobalNumberBlocksCheckbox
                                        }
                                    />
                                }
                                label="Always Bill Global Number Blocks"
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            placeholder={
                                billingState?.surchargeType === 1
                                    ? 'eg: 15.4'
                                    : ''
                            }
                            size="small"
                            label="Surcharge"
                            value={billingState?.surcharge}
                            onChange={(e) => {
                                setBillingState({
                                    surcharge: e.target.value,
                                });
                            }}
                            data-walkthroughid={billing.ufSurchargeInput}
                            InputProps={{
                                inputComponent:
                                    billingState?.surchargeType === 1
                                        ? FloatSurchargePercentage
                                        : FloatSurcharge,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {billingState?.surchargeType === 0
                                            ? '$'
                                            : '%'}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {billingState?.surchargeType === 1 && (
                                            <>
                                                <Tooltip title="The value should be entered as an actual percentage value, eg: 15.4">
                                                    <IconButton
                                                        aria-describedby={id}
                                                        onClick={handleClick}>
                                                        <HelpRoundedIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}>
                                                    <Typography className="p-2">
                                                        The value should be
                                                        entered as an actual
                                                        percentage value, eg:
                                                        15.4
                                                    </Typography>
                                                </Popover>
                                            </>
                                        )}
                                    </>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className="flex-row items-center">
                            <FormLabel
                                className="mr-4"
                                id="surcharge-group-label">
                                Surcharge Type
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="surcharge-group-label"
                                name="surchargeType"
                                value={billingState?.surchargeType}
                                className="flex-row"
                                onChange={(e) => {
                                    setBillingState({
                                        surchargeType: toNumber(e.target.value),
                                    });
                                }}>
                                <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="Fixed"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Percentage"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {isCompany && (
                        <Grid item xs={12}>
                            <LoadingFieldDropdown
                                fieldName="Allow Plans Per User"
                                dropDownValue="value"
                                onError={onModalError}
                                displayField="label"
                                onChange={(v) => {
                                    setBillingState({
                                        perUserPlan: v,
                                    });
                                }}
                                fieldValue={billingState?.perUserPlan}
                                staticEnum={[
                                    { value: 0, label: 'Disabled' },
                                    { value: 1, label: 'Enabled' },
                                    { value: 2, label: 'Partner Only' },
                                ]}
                                noEmptyOption
                                dataWalkthroughid={
                                    billing.ufAllowPlansPerUserSelect
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            placeholder="Tracking Name 2"
                            size="small"
                            label="Tracking Name 2"
                            value={billingState?.trackingName2}
                            onChange={(e) => {
                                setBillingState({
                                    trackingName2: e.target.value,
                                });
                            }}
                            data-walkthroughid={billing.ufTrackingName2}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            placeholder="Tracking Option 2"
                            size="small"
                            label="Tracking Option 2"
                            value={billingState?.trackingOption2}
                            onChange={(e) => {
                                setBillingState({
                                    trackingOption2: e.target.value,
                                });
                            }}
                            data-walkthroughid={billing.ufTrackingOption2}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            label="Contracted Minimum User Count"
                            InputProps={{
                                inputComponent: NonNegativeInteger,
                            }}
                            error={Boolean(modalStateError?.minBillableUsers)}
                            helperText={modalStateError?.minBillableUsers}
                            value={billingState?.minBillableUsers}
                            fullWidth
                            onChange={(e) => {
                                setBillingState({
                                    minBillableUsers: e.target.value,
                                });
                            }}
                            data-walkthroughid={
                                billing.ufContractedMinUserCount
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            label="Contracted Minimum Resource Account Count"
                            InputProps={{
                                inputComponent: NonNegativeInteger,
                            }}
                            error={Boolean(
                                modalStateError?.minBillableResourceAccounts,
                            )}
                            helperText={
                                modalStateError?.minBillableResourceAccounts
                            }
                            value={billingState?.minBillableResourceAccounts}
                            fullWidth
                            onChange={(e) => {
                                setBillingState({
                                    minBillableResourceAccounts: e.target.value,
                                });
                            }}
                            data-walkthroughid={
                                billing.ufContractedMinResourceAccountCount
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Heading> Invoice Export </Heading>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginLeft: 2 }}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={billingState?.billOneLine}
                                        onChange={() =>
                                            setBillingState({
                                                billOneLine:
                                                    !billingState.billOneLine,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufSingleItemInvoiceCheckbox
                                        }
                                    />
                                }
                                label="Single Item Invoice"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={billingState?.billShowCDR}
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowCDR:
                                                    !billingState.billShowCDR,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufCdrDetailsToggle
                                        }
                                    />
                                }
                                label="CDR Details"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={billingState?.billShowRGS}
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowRGS:
                                                    !billingState.billShowRGS,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufRgsDetailsToggle
                                        }
                                    />
                                }
                                label="RGS Details"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={billingState?.billShowCompany}
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowCompany:
                                                    !billingState.billShowCompany,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufCompanyDetailsToggle
                                        }
                                    />
                                }
                                label="Company Details"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={billingState?.billShowZero}
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowZero:
                                                    !billingState.billShowZero,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufZeroItemsToggle
                                        }
                                    />
                                }
                                label="$0 Items"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={billingState?.billShowUsers}
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowUsers:
                                                    !billingState.billShowUsers,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufUserDetailsToggle
                                        }
                                    />
                                }
                                label="User Details"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={billingState?.billShowTrunk}
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowTrunk:
                                                    !billingState.billShowTrunk,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufTrunkDetailsToggle
                                        }
                                    />
                                }
                                label="Trunk Details"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={billingState?.billOneLine}
                                        checked={
                                            billingState?.billShowNumberBlock
                                        }
                                        icon={<VisibilityOffRoundedIcon />}
                                        checkedIcon={<VisibilityRoundedIcon />}
                                        onChange={() =>
                                            setBillingState({
                                                billShowNumberBlock:
                                                    !billingState.billShowNumberBlock,
                                            })
                                        }
                                        data-walkthroughid={
                                            billing.ufNumberBlockDetailsToggle
                                        }
                                    />
                                }
                                label="Number Block Details"
                            />
                        </Grid>
                    </Grid>
                    {currentScope > 20 && (
                        <Grid item xs={12}>
                            <BillingNoteTable
                                mode={mode}
                                setBillingState={setBillingState}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Heading> Invoice Format </Heading>
                    </Grid>
                    <Grid item xs={12}>
                        {invoiceFormatList?.map(({ title, contents }) => (
                            <Accordion
                                key={title}
                                expanded={expanded[title]}
                                onChange={handleChange(title)}
                                data-walkthroughid={`${billing.ufInvoiceFormatAccordion}/${title}`}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id={title}>
                                    <Typography className={classes.heading}>
                                        {title}
                                        <IconButton
                                            placement="right"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setConfirmation({
                                                    show: true,
                                                    content: `Are you sure you would like to restore ${title} fields to default?`,
                                                    changedStates:
                                                        contents.reduce(
                                                            (
                                                                result,
                                                                { format },
                                                            ) => {
                                                                result[
                                                                    format.value
                                                                ] =
                                                                    billingState?.[
                                                                        format
                                                                            .defaultValue
                                                                    ] ??
                                                                    config
                                                                        .billing
                                                                        .defaults[
                                                                        format
                                                                            .value
                                                                    ];
                                                                return result;
                                                            },

                                                            {},
                                                        ),
                                                });
                                            }}
                                            tooltip="Restore Default"
                                            Icon={
                                                <SettingsBackupRestoreRoundedIcon
                                                    data-walkthroughid={
                                                        billing.ufInvoiceFormatResetButton
                                                    }
                                                />
                                            }
                                        />
                                    </Typography>
                                </AccordionSummary>
                                {contents?.map((item) => (
                                    <InvoiceFormatFields
                                        key={item.format.label}
                                        globalCursorPosition={
                                            globalCursorPosition
                                        }
                                        billingState={billingState}
                                        setBillingState={setBillingState}
                                        setGlobalCursorPosition={
                                            setGlobalCursorPosition
                                        }
                                        {...item}
                                    />
                                ))}
                            </Accordion>
                        ))}
                    </Grid>
                    <RestoreDefaultModal
                        handleSubmit={() => {
                            setBillingState(confirmation.changedStates);
                            setConfirmation({
                                show: false,
                                content: '',
                                changedStates: {},
                            });
                        }}
                        handleClose={() => {
                            setConfirmation({
                                show: false,
                                content: '',
                                changedStates: {},
                            });
                        }}
                        {...confirmation}
                    />
                </Grid>
            </Box>
        </Form>
    );
};

export default BillingForm;
