import useDetectFileType from '../../../hooks/useDetectFileType';
import Image from '../../v4/Image';
export default function ImageLoader({image, imageProps, objectProps}) {
    const fileType = useDetectFileType(image);

    return (
        <>
            {fileType === 'png' && <Image {...imageProps} src={image} />}
            {fileType === 'svg' && <object {...objectProps} data={image} />}
        </>
    );
}
