import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { axios } from '../../../../services/axios';
import {
    multiSelectDataMapper,
    query,
    toSentenceCase,
} from '../../../../utils/utils';
import { Box, Tooltip, forwardRef } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import Button from '../../../../components/v4/Button';
import Grid from '../../../../components/v4/Grid';
import Input from '../../../../components/v4/Input';
import FormLabel from '../../../../components/v4/FormLabel';
import updateServiceProfiles from '../../../../API/ServiceProfiles/updateServiceProfiles';
import createServiceProfile from '../../../../API/ServiceProfiles/createServiceProfile';

import { Select as _MultiSelect, Select as _Select } from 'chakra-react-select';
// import Select from '../../../../components/v4/Select';
import getServiceProfiles from '../../../../API/getServiceProfiles';
import style from './styles';
import FooterContainer from './FooterContainer';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';
import Heading from '../../../../components/v4/Heading';
import LoadingIndicator from '../../../../components/v4/LoadingIndicator';
import { validationSchemaOC } from './schema';
import useFieldValidation from '../../../../hooks/useFieldValidation';
import FieldValidation from '../../../../components/v4/FieldValidation';

import walkthroughIds from '../../walkthroughIds';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import config from '@/config.json';

const MultiSelect = forwardRef((props, ref) => (
    <_MultiSelect selectedOptionColor="brand" ref={ref} {...props} />
));

const Select = forwardRef((props, ref) => (
    <_Select selectedOptionColor="brand" ref={ref} {...props} />
));

const OperatorConnect = ({
    mode,
    data,
    handleBackButton,
    level,
    visibleScopeOptions,
}) => {
    const {
        currentWholesaler: wholesalerID,
        currentPartner: partnerID,
        companiesAndPartners,
        wholesalers,
    } = useSelector((state) => state.navigationLists);

    const { requiredScope } = useSelector((state) => state.login);

    const { darkMode } = useSelector((state) => state.settings);

    const [callingProfiles, setCallingProfiles] = useState([]);
    const [regionsEnum, setRegionsEnum] = useState([]);
    const [voicePolicyEnum, setVoicePolicyEnum] = useState([]);
    const [operatorsEnum, setOperatorsEnum] = useState([]);
    const [dialPlansEnum, setDialPlansEnum] = useState([]);

    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const DEFAULT_OC_PAYLOAD = {
        name: `Operator Connect Wholesaler Profile`,
        authPolicy: 2,
        serviceType: 'OperatorConnect',
        callingProfiles: [],
        operatorProfiles: [],
        regions: [],
        voicePolicies: [],
        dialPlans: [],
        partnerID: level === 'partner' ? partnerID : undefined,
        wholesalerID: level === 'partner' ? undefined : wholesalerID,
        visibleScope: level === 'partner' ? 20 : 60,
    };

    const { ...ufElements } = walkthroughIds.serviceProfiles;
    const chakraSelectDarkStyle = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDarkStyle,
    };

    useEffect(() => {
        if (mode !== 'EDIT' && level !== 'partner' && wholesalers?.length > 0) {
            const wsName = wholesalers.find(
                ({ id, name }) => id === wholesalerID,
            )?.name;
            handleChangeState({
                name: `${wsName} - Operator Connect Wholesaler Profile`,
            });
        } else if (
            mode !== 'EDIT' &&
            level === 'partner' &&
            wholesalers?.length > 0
        ) {
            const partnerName = companiesAndPartners.find(
                ({ id, name }) => id === partnerID,
            )?.name;
            handleChangeState({
                name: `${partnerName} - Operator Connect Partner Profile`,
            });
        }
    }, [mode, companiesAndPartners, wholesalers, level]);

    const [modalState, setModalState] = useState(data || DEFAULT_OC_PAYLOAD);

    const authPolicyDropdown = [
        { value: 0, label: 'Disabled' },
        { value: 1, label: 'Enabled' },
        { value: 2, label: 'Required' },
    ];

    const { validationErrors, validatefn } = useFieldValidation(
        modalState,
        validationSchemaOC,
    );

    // function to set modal state with a given payload
    const handleChangeState = (payload) => {
        setModalState((prev) => ({
            ...prev,
            ...payload,
        }));
    };

    /*
     * A function to get the current scope and append it for API calls
     */
    const scope = () => {
        if (level === 'partner') {
            return `Partner/${partnerID}`;
        } else {
            return `Wholesaler/${wholesalerID}`;
        }
    };

    /*
     * Makes sure that operator profiles has been defined and if it has ->
     * sets the CallingProilfes list as the result of a api call with Scope and Query
     * is recalled whenever operator profiles is changed as the drop down list changes with that information
     */
    useEffect(() => {
        if (modalState.operatorProfiles?.length > 0) {
            axios
                .get(
                    `/ServiceProfiles/OperatorConnect/${scope()}/CallingProfiles/Available?OperatorProfileIDs=${query(
                        modalState.operatorProfiles,
                    )}`,
                )
                .then((res) => {
                    const data = res.data.map(multiSelectDataMapper);
                    setCallingProfiles(data);
                })
                .catch((e) => {
                    setError(e);
                });
        }
    }, [modalState.operatorProfiles?.length]);

    /*
     * if calling profiles and operator profiles are defined ->
     * peform a get request with those two as query parameters
     * recalled whenever either change due to the nature of the dropdowns
     */
    useEffect(() => {
        if (
            modalState.callingProfiles?.length > 0 &&
            modalState.operatorProfiles?.length > 0
        ) {
            axios
                .get(
                    `/ServiceProfiles/OperatorConnect/${scope()}/Regions/Available?OperatorProfileIDs=${query(
                        modalState.operatorProfiles,
                    )}&CallingProfileIDs=${query(modalState.callingProfiles)}`,
                )
                .then((res) => {
                    setRegionsEnum(res.data.map(multiSelectDataMapper));
                })
                .catch((e) => {
                    setError(e);
                });
        }
    }, [modalState.operatorProfiles, modalState.callingProfiles]);

    /*
     * if calling profiles, operator profiles and regions are defined ->
     * peform a get request with those three as query parameters
     * recalled whenever any change due to the nature of the dropdowns
     */
    useEffect(() => {
        if (
            modalState.callingProfiles?.length > 0 &&
            modalState.operatorProfiles?.length > 0 &&
            modalState.regions?.length > 0
        ) {
            axios
                .get(
                    `/ServiceProfiles/OperatorConnect/${scope()}/VoicePolicies/Available?OperatorProfileIDs=${query(
                        modalState.operatorProfiles,
                    )}&CallingProfileIDs=${query(
                        modalState.callingProfiles,
                    )}&RegionIDs=${query(modalState.regions)}`,
                )
                .then((res) => {
                    setVoicePolicyEnum(res.data.map(multiSelectDataMapper));
                })
                .catch((e) => {
                    setError(e);
                });
        }
    }, [
        modalState.operatorProfiles,
        modalState.callingProfiles,
        modalState.regions,
    ]);

    const handleGetDropdownData = async () => {
        setIsLoading(true);
        const fetchSPData = mode === 'EDIT' && data;
        try {
            const opResult = await axios.get(
                `/serviceprofiles/operatorconnect/${scope()}/operatorprofiles/available`,
            );
            setOperatorsEnum(opResult.data.map(multiSelectDataMapper));
            const dpResult = await axios.get(
                `/serviceprofiles/operatorconnect/${scope()}/dialplans/available`,
            );
            setDialPlansEnum(dpResult.data.map(multiSelectDataMapper));

            if (fetchSPData) {
                const spResult = await getServiceProfiles({
                    level,
                    id: data?.id,
                });
                const callingProfiles = spResult.data.callingProfiles?.map(
                    multiSelectDataMapper,
                );
                const operatorProfiles = spResult.data.operatorProfiles?.map(
                    multiSelectDataMapper,
                );
                const regions = spResult.data.regions?.map(
                    multiSelectDataMapper,
                );
                const voicePolicies = spResult.data.voicePolicies?.map(
                    multiSelectDataMapper,
                );
                const dialPlans = spResult.data.dialPlans?.map(
                    multiSelectDataMapper,
                );

                setModalState({
                    ...spResult.data,
                    callingProfiles,
                    operatorProfiles,
                    regions,
                    dialPlans,
                    voicePolicies,
                });
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetDropdownData();
    }, []);
    const handleSubmit = async () => {
        setError(null);
        //validate
        if (!validatefn()) {
            setError({
                response: {
                    data: {
                        message:
                            'There are some validation errors in the form. Please check and try again.',
                    },
                },
            });
            return;
        }

        setIsLoading(true);
        try {
            if (mode === 'EDIT') {
                const result = await updateServiceProfiles({
                    type: 'OperatorConnect',
                    payload: modalState,
                    level,
                    scope: requiredScope,
                });
                handleBackButton();
            } else {
                const result = await createServiceProfile({
                    type: 'OperatorConnect',
                    payload: modalState,
                    level,
                    scope: requiredScope,
                });
                handleBackButton();
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ maxWidth: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading fontSize="3xl" as="h2" sx={{ marginBottom: '10px' }}>
                {`${toSentenceCase(mode)} ${modalState.name || data?.name}`}
            </Heading>
            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}
            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>Service name</FormLabel>
                <Input
                    value={modalState.name}
                    onChange={(e) =>
                        handleChangeState({ name: e.target.value })
                    }
                    sx={style.inputField}
                    data-walkthroughid={ufElements.ufServiceNameInput}
                />
                <FieldValidation errors={validationErrors?.name} />
            </Box>

            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}>
                    Visible Scope &nbsp;
                    <Tooltip
                        label="Determine visibility and service creation level"
                        hasArrow
                        placement="top-end">
                        <InfoIcon />
                    </Tooltip>
                </FormLabel>
                {modalState.visibleScope <= requiredScope ? (
                    <Select
                        defaultValue={visibleScopeOptions.find((option) => {
                            return option.value === modalState.visibleScope;
                        })}
                        options={visibleScopeOptions}
                        chakraStyles={chakraStyles}
                        onChange={(selectedOption) => {
                            handleChangeState({
                                visibleScope: selectedOption.value,
                            });
                        }}
                        selectedOptionColor="brand"
                    />
                ) : (
                    <Input
                        isDisabled
                        value={config.scope.json[modalState.visibleScope]}
                    />
                )}
            </Box>

            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufOperatorsSelect}>
                <FormLabel sx={style.label}> Operators </FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Operators -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({
                            operatorProfiles: selectedOptions,
                        });
                    }}
                    options={operatorsEnum}
                    defaultValue={modalState.operatorProfiles}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.operatorProfiles} />
            </Box>
            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufCallingProfilesSelect}>
                <FormLabel sx={style.label}> Calling Profiles </FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Calling Profiles -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({
                            callingProfiles: selectedOptions,
                        });
                    }}
                    options={callingProfiles}
                    defaultValue={modalState.callingProfiles}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.callingProfiles} />
            </Box>

            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufRegionsSelect}>
                <FormLabel sx={style.label}> Regions </FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Regions -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({
                            regions: selectedOptions,
                        });
                    }}
                    options={regionsEnum}
                    defaultValue={modalState.regions}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.regions} />
            </Box>

            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufVoicePoliciesSelect}>
                <FormLabel sx={style.label}> Voice Policies </FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Voice Policies -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({ voicePolicies: selectedOptions });
                    }}
                    options={voicePolicyEnum}
                    defaultValue={modalState.voicePolicies}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.voicePolicies} />
            </Box>
            <Box
                sx={style.fieldContainer}
                data-walkthroughid={ufElements.ufDialPlansSelect}>
                <FormLabel sx={style.label}> Dial Plans </FormLabel>
                <MultiSelect
                    isMulti
                    placeholder="- Select Dial Plans -"
                    closeMenuOnSelect={false}
                    tagVariant="solid"
                    onChange={(selectedOptions) => {
                        handleChangeState({ dialPlans: selectedOptions });
                    }}
                    options={dialPlansEnum}
                    defaultValue={modalState.dialPlans}
                    chakraStyles={chakraStyles}
                />
                <FieldValidation errors={validationErrors?.dialPlans} />
            </Box>
            <Box sx={style.fieldContainer}>
                <FormLabel sx={style.label}> Auth Policy </FormLabel>
                <Select
                    placeholder="Select Auth Policy"
                    value={authPolicyDropdown[modalState.authPolicy]}
                    onChange={(e) => handleChangeState({ authPolicy: e.value })}
                    options={[
                        {
                            value: null,
                            label: 'Select Auth Policy',
                        },
                        ...authPolicyDropdown,
                    ]}
                    data-walkthroughid={ufElements.ufAuthPolicySelect}
                    chakraStyles={chakraStyles}
                />
            </Box>
            <FooterContainer>
                <Button
                    variant="outline"
                    sx={{ background: 'white' }}
                    w={{ base: 'full', md: '80px' }}
                    onClick={handleBackButton}
                    data-walkthroughid={ufElements.ufCloseButton}>
                    Close
                </Button>
                <Button
                    w={{ base: 'full', md: '80px' }}
                    data-walkthroughid={ufElements.ufUpdateButton}
                    onClick={handleSubmit}>
                    {mode === 'ADD' ? 'Add' : 'Update'}
                </Button>
            </FooterContainer>
        </Box>
    );
};

export default OperatorConnect;
