import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Link,
    useNavigate,
    useSearchParams,
    useLoaderData,
} from 'react-router-dom';

import { Box, chakra, forwardRef } from '@chakra-ui/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';

import useDetectFileType from '../../../../hooks/useDetectFileType';

import {
    Alert as ChakraAlert,
    AlertIcon,
    Button,
    AlertDescription as ChakraAlertDescription,
    FormLabel,
    Image,
    Input,
} from '@/components/v4';
import FieldValidation from '@/components/v4/FieldValidation';
import LoginSSO from '@/pages/auth/new/LoginSSO';
import walkthroughId from '@/pages/auth/new/walkthroughId';
import { buttonVariants, exit, inputVariants } from '../constants.js';

const Alert = forwardRef((props, ref) => (
    <ChakraAlert {...props} ref={ref} variant="inline-accent" />
));

const AlertDescription = forwardRef((props, ref) => (
    <ChakraAlertDescription {...props} ref={ref} className="text-black" />
));

const ChakraMotionBox = chakra(motion.div, {
    shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

export default function LoginNormal({
    // User/pass
    email,
    setEmail,
    password,
    setPassword,
    // Validaiton
    validationErrors,
    loginError,
    setLoginError,
    // Login handler
    onFirstLogin,
    changePasswordSucceed,

    loading,
}) {
    const [loginActionMessage, setLoginActionMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const message = useLoaderData();

    const setEmailHandler = (e) => setEmail(e.target.value);
    const setPasswordHandler = (e) => setPassword(e.target.value);

    const [imgPath, setImgPath] = useState(null);

    const { imagePathLightMode } = useSelector((state) => {
        return { ...state.login };
    });

    // useEffect(() => {
    //     if (imagePathLightMode) {
    //         setImgPath(imagePathLightMode);
    //     }
    // }, [imagePathLightMode]);
    //
    // const imageFileType = useDetectFileType(
    //     import.meta.env.VITE_APP_IMAGE_BASE + imgPath,
    // );

    useEffect(() => {
        const action = searchParams.get('action');
        if (!action) return;
        setLoginActionMessage(null);
        switch (action) {
            case 'registered':
                setLoginActionMessage('Successfully registered to TCAP.');
                break;
            case 'activated':
                setLoginActionMessage(
                    'Successfully activated your TCAP account.',
                );
                break;
            case 'mfaTokenReset':
                setLoginActionMessage(
                    'Successfully removed your MFA token. Login to run the MFA setup again.',
                );
            case 'mfaEnabled':
                setLoginActionMessage(
                    'Successfully enabled MFA. Please re-login.',
                );
            case 'mfaDisabled':
                setLoginActionMessage(
                    'Successfully disabled MFA. Please re-login.',
                );
                break;
        }
    }, []);

    useEffect(() => {
        if (!email || !password) {
            setLoginError({...loginError, isError: false});
        }
    }, [email, password]);

  useEffect(() => {
        if (!imgPath || !imagePathLightMode) {
            fetch(
                `${import.meta.env.VITE_APP_API_URL}/theme/${
                    window.location.host
                }`,
                {
                    headers: {
                        'x-requestscope': '20',
                    },
                },
            ).then(async (response) => {
                const data = await response.json();
                dispatch({
                    type: 'SET_SBC_DOMAIN',
                    payload: data.sbcDomain,
                });
                dispatch({
                    type: 'CHANGE_IMAGE_PATH_LIGHT_MODE',
                    payload: data.lightLogo,
                });
                dispatch({
                    type: 'CHANGE_IMAGE_PATH_DARK_MODE',
                    payload: data.darkLogo,
                });

                dispatch({
                    type: 'HEADER_FAVICON_SUCCESS',
                    payload: data.favicon,
                });
                dispatch({
                    type: 'HEADER_TITLE_SUCCESS',
                    payload: data.titleName,
                });
                dispatch({
                    type: 'SET_SUPPORT_URL',
                    payload: data.extLinkSupport,
                });
                setImgPath(data.lightLogo);
            });
        }
    }, [imgPath, imagePathLightMode]);

    const imageFileType = useDetectFileType(
        import.meta.env.VITE_APP_IMAGE_BASE + imgPath,
    );

    return (
        <Form
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
            onSubmit={onFirstLogin}
            method="POST"
            replace>
            {changePasswordSucceed &&
                changePasswordSucceed.toLowerCase() === 'success' && (
                    <>
                        <Alert status="success" mb={5}>
                            <AlertIcon />
                            <AlertDescription>
                                {'Password reset successful, please log in.'}
                            </AlertDescription>
                        </Alert>
                    </>
                )}
            {imageFileType === 'png' && (
                <Image
                    alt="Domain Logo"
                    style={{
                        display: 'block',
                        maxWidth: '300px',
                        maxHeight: '150px',
                        margin: '0 auto',
                    }}
                    src={import.meta.env.VITE_APP_IMAGE_BASE + imgPath}
                />
            )}
            {imageFileType === 'svg' && (
                <object
                    alt="Domain Logo"
                    style={{
                        display: 'block',
                        maxWidth: '300px',
                        maxHeight: '150px',
                        margin: '0 auto',
                    }}
                    data={import.meta.env.VITE_APP_IMAGE_BASE + imgPath}
                    type="image/svg+xml">
                    {' '}
                    Domain Logo{' '}
                </object>
            )}
            <Box sx={{ margin: '0 0 20px 0' }}></Box>
            <AnimatePresence>
                <ChakraMotionBox
                    key="motion-box"
                    initial="hidden"
                    whileInView="visible"
                    animate="visible"
                    exit={exit}>
                    <motion.div variants={inputVariants}>
                        <FormLabel
                            sx={{ margin: 0 }}
                            for="login-username"
                            className="!text-black">
                            EMAIL
                        </FormLabel>
                        <Input
                            variant="flushed"
                            borderColor="gray"
                            className="!bg-white !text-gray-700"
                            size="sm"
                            id="login-username"
                            autoComplete="login-username"
                            name="email"
                            value={email}
                            onChange={setEmailHandler}
                            data-walkthroughid={walkthroughId.ufEmailInput}
                        />
                        <FieldValidation errors={validationErrors?.email} />
                        <Box sx={{ margin: '10px 0' }}></Box>
                        <FormLabel
                            sx={{ margin: 0 }}
                            for="login-password"
                            className="!text-black">
                            PASSWORD
                        </FormLabel>
                        <Input
                            borderColor="gray"
                            variant="flushed"
                            className="!bg-white !text-gray-700"
                            size="sm"
                            type={'password'}
                            id="login-password"
                            autoComplete="login-password"
                            name="password"
                            value={password}
                            onChange={setPasswordHandler}
                            data-walkthroughid={walkthroughId.ufPasswordInput}
                        />
                        <FieldValidation errors={validationErrors?.password} />
                    </motion.div>
                    <Box
                        sx={{
                            margin: '10px 0',
                            pb:
                                loginError?.isError || message || loginActionMessage
                                    ? '5px'
                                    : 0,
                        }}
                        aria-label="filler box alert"></Box>
                    <motion.div
                        initial={{
                            height: 0,
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                            height: 'auto',
                            transition: {
                                delay: 1,
                                duration: 0.6,
                            },
                        }}
                        exit={exit}>
                        {!loginError?.isError && message && (
                            <>
                                <Alert
                                    status="warning"
                                    sx={{ overflow: 'visible' }}>
                                    <AlertIcon />
                                    <AlertDescription>
                                        {message}
                                    </AlertDescription>
                                </Alert>
                                <Box
                                    sx={{
                                        margin: '0 0 10px 0',
                                        display: 'flex',
                                    }}
                                />
                            </>
                        )}
                        {searchParams.get('action') && (
                            <>
                                <Alert
                                    status="success"
                                    sx={{ overflow: 'visible' }}>
                                    <AlertIcon />
                                    <AlertDescription>
                                        {loginActionMessage}
                                    </AlertDescription>
                                </Alert>
                                <Box
                                    sx={{
                                        margin: '0 0 10px 0',
                                        display: 'flex',
                                    }}
                                />
                            </>
                        )}
                        {loginError?.isError && (
                            <>
                                <Alert status="error">
                                    <AlertIcon />
                                    <AlertDescription>
                                        Wrong email and/or password. Please try
                                        again with a different email and/or
                                        password or check that you are not
                                        required to use SSO.
                                    </AlertDescription>
                                </Alert>
                                <Box
                                    sx={{
                                        margin: '0 0 10px 0',
                                        display: 'flex',
                                    }}
                                />
                            </>
                        )}
                    </motion.div>
                    <motion.div variants={buttonVariants}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '15px 0 20px',
                            }}>
                            <Button
                                minW="110px"
                                type="submit"
                                colorScheme='messenger'
                                isDisabled={loading}
                                isLoading={loading}
                                data-walkthroughid={
                                    walkthroughId.ufLoginButton
                                }>
                                LOGIN
                            </Button>
                            <Link
                                to="/register"
                                style={{ textDecoration: 'none' }}>
                                <Button
                                    variant="outline"
                                    className="secondary"
                                    colorScheme='messenger'
                                    isDisabled={loading}
                                    data-walkthroughid={
                                        walkthroughId.ufRegisterButton
                                    }>
                                    REGISTER
                                </Button>
                            </Link>
                        </Box>
                        <Box textAlign="right">
                            <Link to="/forgot-password">
                                <Button
                                    variant="link"
                                    className="secondary"
                                    colorScheme='messenger'
                                    isDisabled={loading}
                                    data-walkthroughid={
                                        walkthroughId.ufForgotPasswordButton
                                    }>
                                    Forgot my password
                                </Button>
                            </Link>
                        </Box>
                        <div className="relative">
                            <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true">
                                <div className="w-full border-t border-gray-200" />
                            </div>
                            <div className="relative my-[10px] flex justify-center font-medium leading-6">
                                <span className="bg-white px-2 text-base text-[var(--chakra-colors-light-text-weak)]">
                                    or
                                </span>
                            </div>
                        </div>
                        <LoginSSO />
                        {/* NOTE: for Q4 2024 when have more providers  */}
                        {/* <button */}
                        {/*     type="button" */}
                        {/*     className="h-10 w-full rounded-md bg-lime-500 px-4 text-base font-semibold capitalize text-white hover:bg-lime-600" */}
                        {/*     onClick={() => { */}
                        {/*         navigate('/sso'); */}
                        {/*     }}> */}
                        {/*     Login with SSO */}
                        {/* </button> */}
                    </motion.div>
                </ChakraMotionBox>
            </AnimatePresence>
        </Form>
    );
}
