export const whitelist = [
    'csv',
    'csvnumberupdate',
    'not-found',
    'guide',
    'guides',
    'services',
    'settings',
    'virtualuserguide',
];
