import { axios } from '@/services/axios';

export const saveTableColumnOrder = async (rest, columns) => {
    const lastUpdate = Date.now();

    const data = {
        settings: {
            ...rest,
            tableColumnOrder: columns,
            lastUpdate,
        },
    };
    const jsonString = `"${JSON.stringify(data).replaceAll('"', '\\"')}"`;
    const res = await axios.post('/adminuser/uisettings', jsonString, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
};
