import { axios } from '@/services/axios';
import {
    Box,
    ChakraProvider,
    Container,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Button, Heading } from 'components/v4';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import EditGeneral from './EditGeneral';
import EditAdmin from './EditAdmin';
import walkthroughIds from '../walkthroughIds';

export default function EditServiceCategory() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // get service category id from path
    const { serviceCategoryId } = useParams();

    const { theme } = useDeepMerge();

    const { ufBack, ufTab, ufSave } = walkthroughIds.serviceCategories;

    const initialState = {
        id: '',
        name: '',
        description: '',
        image: '',
        type: '0',
    };
    const [serviceCategory, setServiceCategory] = useState(initialState);
    const initialErrorState = {
        id: { isError: false, message: '' },
        name: { isError: false, message: '' },
        description: { isError: false, message: '' },
        image: { isError: false, message: '' },
    };
    const [errorState, setErrorState] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    const { data: serviceCategoryData, isFetching: serviceCategoryLoading } =
        useQuery({
            queryKey: ['serviceCategory', { serviceCategoryId }],
            queryFn: async () => {
                const res = await axios.get(
                    `/servicecategory/${serviceCategoryId}`,
                );
                return res.data;
            },
            enabled: Boolean(serviceCategoryId),
            onSuccess: (data) => {
                setServiceCategory({
                    ...data,
                    description: data?.description || '',
                    // convert from int to string
                    type: data?.type.toString(),
                });
            },
            onError: (err) => {
                toast.error(err?.response?.data?.message);
            },
            refetchOnWindowFocus: false,
        });

    const handleChange = (key, e) => {
        setServiceCategory((prevState) => ({
            ...prevState,
            [key]: e.target.value,
        }));

        // remove error
        handleError(key, false, '');
    };

    const handleError = (key, isError, message) => {
        setErrorState((prevState) => ({
            ...prevState,
            [key]: { isError: isError, message: message },
        }));
    };

    const handleEdit = async () => {
        try {
            setIsDisabled(true);
            // validation check (id, name, image)
            let hasError = false;
            if (isEmpty(serviceCategory.name)) {
                handleError('name', true, 'Name is required.');
                hasError = true;
            }

            // do not proceed if has errors
            if (hasError) {
                setIsDisabled(false);
                return;
            }

            var formData = new FormData();
            formData.append('id', serviceCategory.id);
            formData.append('name', serviceCategory.name);
            formData.append('description', serviceCategory.description);
            formData.append('image', serviceCategory.image);
            formData.append('type', serviceCategory.type);
            await axios.put('/servicecategory', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Service category updated successfully!');
            dispatch({
                type: 'RESET_TABLE',
            });
            navigate('/service-categories');
        } catch (e) {
            toast.error(e?.response?.data?.message);
        } finally {
            setIsDisabled(false);
        }
    };

    const handleBack = () => {
        navigate('/service-categories');
    };

    return (
        <ChakraProvider resetCSS theme={theme}>
            <Container>
                <Heading
                    as={'h4'}
                    lineHeight={'1.5'}
                    fontSize={'1.5rem'}
                    fontWeight={500}>
                    Edit Service Category
                </Heading>
                <Tabs>
                    <TabList justifyContent="center" borderBottom={0}>
                        {['General', 'Admin'].map((t, index) => (
                            <Tab
                                data-walkthroughid={ufTab + t}
                                isDisabled={serviceCategoryLoading}
                                sx={customTabStylesCombineTheme}
                                onMouseDown={(e) =>
                                    e.button === 2 && e.preventDefault()
                                }
                                key={t}
                                _selected={selectedTabStylesCombineTheme}>
                                {t}
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        <TabPanel px={0}>
                            <EditGeneral
                                serviceCategory={serviceCategory}
                                setServiceCategory={setServiceCategory}
                                isDisabled={isDisabled}
                                errorState={errorState}
                                handleChange={handleChange}
                                handleError={handleError}
                                serviceCategoryLoading={serviceCategoryLoading}
                            />
                        </TabPanel>
                        <TabPanel px={0}>
                            <EditAdmin
                                serviceCategoryLoading={serviceCategoryLoading}
                                serviceCategory={serviceCategory}
                            />
                        </TabPanel>
                    </TabPanels>

                    <Box display="flex" className="mt-3 justify-center">
                        <Button
                            variant="outline"
                            bg="white"
                            data-walkthroughid={ufBack}
                            isDisabled={isDisabled}
                            onClick={() => {
                                handleBack();
                            }}>
                            Back
                        </Button>

                        <Button
                            ml={4}
                            isLoading={isDisabled}
                            data-walkthroughid={ufSave}
                            onClick={() => handleEdit()}>
                            Save
                        </Button>
                    </Box>
                </Tabs>
            </Container>
        </ChakraProvider>
    );
}
