import { Grid, TextField } from '@material-ui/core';

export default function AddCustom(props) {
    const { state, stateError, dispatch, ufCustomAttribute } = props;
    return (
        <Grid container spacing={2}>
            {['attribute1', 'attribute2', 'attribute3'].map((v, idx) => (
                <Grid item xs={12} className="pb-2" key={`${v}-${idx}`}>
                    <TextField
                        label={`Custom Attribute ${idx + 1}`}
                        size="small"
                        placeholder={`Custom Attribute ${idx + 1}`}
                        value={state?.[v] ?? ''}
                        error={Boolean(stateError?.[v])}
                        helperText={stateError?.[v]}
                        fullWidth
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    [v]: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={`${ufCustomAttribute}/${idx}`}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
