import {CircularProgress} from '@chakra-ui/react';

export default function LoadingIndicator(props) {
    return (
        <CircularProgress
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10rem',
                transform: 'scale(2)',
            }}
            isIndeterminate
            color="gray.300"
            {...props}
        />
    );
}
