import { InfoIcon } from '@chakra-ui/icons';
import {
    Badge,
    Box,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Tooltip
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import { isEqual } from 'lodash';
import { useServices } from '../ServicesContext';
import style from './styles';
import useServicesAttributesValidation from './useServicesAttributesValidation';

export default function TextAttribute({
    attribute,
    index,
    serviceIndex,
    requiredScope,
    isBusy,
}) {
    const validation = JSON.parse(attribute.definedAttributeValidationType);
    const { attributesErrors, services } = useServices();
    const error = attributesErrors.find(
        (obj) => obj.id === index && obj.serviceIndex === serviceIndex,
    );

    const updateAttribute = services[serviceIndex].attributes[index];

    const {
        removeAttributeError,
        handleAttributeChange,
    } = useServicesAttributesValidation();

    return (
        <Box sx={style.fieldContainer}>
            <FormControl isRequired={validation.Required} isInvalid={error}>
                <FormLabel sx={style.label}>
                    {attribute.name}{' '}
                    {attribute.toolTip && (
                        <Tooltip
                            label={attribute.toolTip}
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </FormLabel>
                <Box display="flex" flexDirection={'row'}>
                    <Box w={'100%'} mr={1}>
                        <Input
                            type="text"
                            placeholder="Please enter a value"
                            maxLength={
                                validation?.MaxLength > 0
                                    ? validation.MaxLength
                                    : null
                            }
                            value={updateAttribute?.value}
                            onChange={(e) => {
                                removeAttributeError(index, serviceIndex);
                                handleAttributeChange(
                                    e.target.value,
                                    index,
                                    serviceIndex,
                                );
                            }}
                            isDisabled={
                                requiredScope < attribute.updateScope || isBusy
                            }
                        />
                    </Box>
                    {!isEqual(updateAttribute?.value, attribute.value) && (
                        <Box alignContent={'center'} ml={1}>
                            <Badge colorScheme="orange">Unsaved</Badge>
                        </Box>
                    )}
                </Box>

                {error ? (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : attribute?.description ? (
                    <FormHelperText>{attribute?.description}</FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    );
}
