import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { axios } from '../../../../services/axios';
import { useSelector } from 'react-redux';
import { multiSelectDataMapper } from '../../../../utils/utils';
import Heading from '../../../../components/v4/Heading';
import Alert from '../../../../components/v4/Alert';
import AlertDescription from '../../../../components/v4/AlertDescription';
import AlertIcon from '../../../../components/v4/AlertIcon';
import FooterContainer from '../edit/FooterContainer';
import Button from '../../../../components/v4/Button';
import { LoadingIndicator } from 'components/v4';

import walkthroughIds from '../../walkthroughIds';
import { Select } from 'chakra-react-select';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';

export default function SelectProfile({
    handleSelectServiceProfileType,
    level,
    handleBackButton,
}) {
    const { currentWholesaler: wholesalerID, currentPartner: partnerID } =
        useSelector((state) => state.navigationLists);
    const { requiredScope } = useSelector((state) => state.login);
    const [dropdown, setDropdown] = useState([]);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { serviceProfiles } = walkthroughIds;

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const scope = () => {
        if (level === 'partner') {
            return `Partner/${partnerID}`;
        } else {
            return `Wholesaler/${wholesalerID}`;
        }
    };

    const handleGetServiceProfileTypes = async () => {
        setIsLoading(true);
        try {
            const result = await axios.get(
                `/ServiceProfiles/${scope()}/ServiceTypes/Available`,
                {
                    headers: {
                        'X-RequestScope': requiredScope,
                    },
                },
            );
            setDropdown(result.data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetServiceProfileTypes();
    }, []);

    if (isLoading) {
        return <LoadingIndicator />;
    }
    return (
        <Box maxW="2xl" minW="xl" mx="auto">
            <Heading as="h2" size="md">
                {`Please select a Service Profile Type to add`}
            </Heading>
            {error && (
                <Alert
                    status="error"
                    sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}

            <Select
                sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                placeholder={
                    dropdown.length
                        ? 'Select a Service Profile Type'
                        : 'No Options'
                }
                options={dropdown}
                getOptionValue={(e) => e.id}
                getOptionLabel={(e) => e.displayName}
                onChange={(e) => {
                    handleSelectServiceProfileType(e.id, e.displayName);
                }}
                chakraStyles={{
                    ...defaultChakraSelectStyle,
                    ...chakraSelectDark,
                }}
                data-walkthroughid={serviceProfiles.ufSelectProfileType}
                selectedOptionColor="brand"
            />
            <FooterContainer>
                <Button
                    onClick={handleBackButton}
                    data-walkthroughid={serviceProfiles.ufCloseButton}>
                    Close
                </Button>
            </FooterContainer>
        </Box>
    );
}
