import {
    Box,
    Heading,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
} from '@chakra-ui/react';

import config from '@/config.json';
import { customTabStyles, selectedTabStyles } from '@/constants';
import { axios } from '@/services/axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import General from './General';

import { Button } from 'components/v4';
import { useDispatch, useSelector } from 'react-redux';
import APIKeyModal from '../APIKeyModal';
import moment from 'moment';
import walkthroughIds from '../../walkthroughIds';
import { toast } from 'react-toastify';

export default function ApiUserCreate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const date = new Date();

    const { currentCompany } = useSelector((state) => state.navigationLists);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [apiKey, setApiKey] = useState('');

    const [apiUser, setApiUser] = useState({
        name: '',
        isActive: true,
        permissionSetID: '',
        expirationDate: new Date(moment().add(1, 'day')),
        expirationTime: '',
    });
    const [errors, setErrors] = useState({
        name: { isError: false, message: '' },
        permission: { isError: false, message: '' },
        expirationTime: { isError: false, message: '' },
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleAddAPI = async () => {
        setIsLoading(true);
        let hasError = false;

        if (!apiUser.name || apiUser.name.length < 1) {
            setErrors({
                ...errors,
                name: {
                    isError: true,
                    message: 'Field is required.',
                },
            });
            hasError = true;
        }
        if (!apiUser.permissionSetID) {
            setErrors((prevState) => ({
                ...prevState,
                permission: {
                    isError: true,
                    message: 'Field is required.',
                },
            }));
            hasError = true;
        }
        if (
            moment(apiUser.expirationDate).format('DD-MM-yyyy') <=
                moment(date).format('DD-MM-yyyy') &&
            apiUser?.expirationTime &&
            apiUser?.expirationTime <
                moment(apiUser.expirationDate).format('HH:mm')
        ) {
            setErrors((prevState) => ({
                ...prevState,
                expirationTime: {
                    isError: true,
                    message: 'Time cannot be less than now.',
                },
            }));
            hasError = true;
        }

        if (hasError) {
            setIsLoading(false);
            return;
        }
        setErrors({
            name: { isError: false, message: '' },
            permission: { isError: false, message: '' },
            expirationTime: { isError: false, message: '' },
        });

        let selectedExpiration;
        if (apiUser?.expirationTime) {
            const selectedDate = moment(apiUser.expirationDate);
            selectedDate.set({
                hour: apiUser?.expirationTime.split(':')[0],
                minute: apiUser?.expirationTime.split(':')[1],
                second: 0,
            });
            selectedExpiration = moment(selectedDate);
        } else {
            const resetTime = moment(apiUser.expirationDate).set({
                hour: 0,
                minute: 0,
                second: 0,
            });
            selectedExpiration = moment(resetTime);
        }
        axios
            .post(config.apiUsers.modalAdd, {
                ...apiUser,
                expiration: selectedExpiration,
                companyID: currentCompany,
            })
            .then((res) => {
                toast.success('API User created successfully!');
                dispatch({ type: 'RESET_TABLE' });
                setApiKey(res.data.apiKey);
                onOpen();
            })
            .catch((e) => {
                toast.error(e?.response?.data?.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleBack = () => {
        navigate('/admins', { state: { index: 1 } });
    };

    return (
        <>
            <Heading fontSize="3xl" as="h2" mb={2} pl={4}>
                <span className="text-2xl font-semibold">Add API User</span>
            </Heading>
            <Box
                sx={{
                    padding: '10px 0',
                    maxWidth: '2xl',
                    minWidth: 'xl',
                    margin: '0 auto',
                }}
                className="admin-general-temp">
                <Box
                    px={{ base: '4', md: '6' }}
                    pt={{ base: '5', md: '6' }}
                    pb={0}>
                    <Tabs>
                        <TabList justifyContent="center" borderBottom={0}>
                            {['General'].map((t) => (
                                <Tab
                                    sx={customTabStyles}
                                    key={t}
                                    _selected={selectedTabStyles}>
                                    {t}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel px={0}>
                                <General
                                    apiUser={apiUser}
                                    setApiUser={setApiUser}
                                    errors={errors}
                                    setErrors={setErrors}
                                    isLoading={isLoading}
                                />
                            </TabPanel>
                        </TabPanels>

                        <Stack
                            spacing="6"
                            px={{ base: '4', md: '6' }}
                            pt={{ base: '5', md: '6' }}
                            pb="2px"
                            sx={{
                                justifyContent: 'center',
                                width: '170px',
                                margin: '0 auto',
                            }}
                            direction={{ base: 'column', md: 'row' }}>
                            <Box>
                                <Button
                                    variant="outline"
                                    className="min-w-[5rem] disabled:cursor-not-allowed"
                                    isDisabled={isLoading}
                                    data-walkthroughid={
                                        walkthroughIds.adminApiUsers.ufBack
                                    }
                                    onClick={() => handleBack()}>
                                    Back
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    className="min-w-[5rem] disabled:cursor-not-allowed"
                                    isLoading={isLoading}
                                    data-walkthroughid={
                                        walkthroughIds.adminApiUsers.ufCreate
                                    }
                                    onClick={() => handleAddAPI()}
                                    type="submit">
                                    Create
                                </Button>
                            </Box>
                        </Stack>
                    </Tabs>
                </Box>
            </Box>

            <APIKeyModal
                apiKey={apiKey}
                setApiKey={setApiKey}
                onClose={onClose}
                isOpen={isOpen}
                handleBack={handleBack}
            />
        </>
    );
}
