import styles from './styles';
import { Progress } from '@chakra-ui/react';

export default function DashboardServiceCardStatusbar({ ...props }) {
    const { color, animation } = props.cardStatus || {
        color: 'gray',
        animation: false,
    };
    return (
        <Progress
            {...props}
            value={100}
            sx={styles.verticalBar}
            colorScheme={color}
            hasStripe={animation}
            isAnimated={animation}
        />
    );
}
