export const NotAllocated = 'Not Allocated';
export const NOT_ALLOCATED = '- Not Allocated -';
export const MS_DIRECT_ROUTING = 'MS Direct Routing';
export const resourceAccEPoint = '/ResourceAccount';
export const userAccEPoint = '/User';

export const raTabList = ['General', 'Custom', 'Admin'];
export const userTabList = ['General', 'Custom', 'Services'];
export const managedByOptions = [
    { value: 0, label: 'TCAP' },
    { value: 1, label: 'Other' },
];
export const PlanMode = {
    WRITABLE: 'WRITABLE',
    HIDDEN: 'HIDDEN',
    READ_ONLY: 'READ_ONLY',
};
export const ACTIONS = {
    CHANGE_DIAL_PLAN_ID: 'CHANGE_DIAL_PLAN_ID',
    CHANGE_VOICE_POLICY_ID: 'CHANGE_VOICE_POLICY_ID',
    CHANGE_NUMBER_ID: 'CHANGE_NUMBER_ID',
    CHANGE_MANAGED_BY: 'CHANGE_MANAGED_BY',
    CHANGE_USER_PLAN_MODE: 'CHANGE_USER_PLAN_MODE',
    CHANGE_CUSTOM_ATTRIBUTE: 'CHANGE_CUSTOM_ATTRIBUTE',
    SET_DR_DIAL_PLANS: 'SET_DR_DIAL_PLANS',
    SET_DR_VOICE_POLICIES: 'SET_DR_VOICE_POLICIES',
    SET_NUMBER: 'SET_NUMBER',
    SET_NUMBER_LIST: 'SET_NUMBER_LIST',
    SET_USER_PLAN_MODE: 'SET_USER_PLAN_MODE',
    SUBMIT_FORM: 'SUBMIT_FORM',
    SUBMIT_FORM_SUCCESS: 'SUBMIT_FORM_SUCCESS',
    SUBMIT_FORM_FAILURE: 'SUBMIT_FORM_FAILURE',
    RESET_FORM_STATUS: 'RESET_FORM_STATUS',
    VALIDATE_VOICE_POLICY: 'VALIDATE_VOICE_POLICY',
    VALIDATE_DIAL_PLAN: 'VALIDATE_DIAL_PLAN',
    INIT: 'INIT',
};

export const alphabetFilterField = 'displayName';
export const initialStates = {
    name: '',
    displayName: '',
    timezone: '',
    language: '',
    template: '',
};

export const muiSelectStyles = {
    '.select-change .MuiSelect-root': {
        backgroundColor: 'transparent',
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
};
