import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Container, Modal} from 'react-bootstrap';
import Error from 'components/maintenance/Error';

const LoadingModal = ({show, error, onHide}) => {
    return (
        <Modal show={show} onHide={onHide} size={'lg'} animation={false}>
            <Modal.Header closeButton style={{border: 'none'}}>
                <Modal.Title>{'Submitting Your Requests'} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid className="p-12">
                    {error ? (
                        <Error error={error} style={{marginBottom: 10}} />
                    ) : (
                        <CircularProgress
                            style={{marginTop: '2.5%', marginLeft: '50%'}}
                        />
                    )}
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;
