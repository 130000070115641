import React, { useRef, useState } from 'react';
import { TableAndModal } from '../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import config from '../../../../config.json';
import UsersGeneralForm from '../../../accounts/users/edit/General';
import { renderTelephoneNumber } from '../../../../utils/utils';
import { Message } from 'pages/telephony/numbers/edit/Index';
import { Tooltip, Box } from '@mui/material';
import LoadingModal from 'pages/telephony/numbers/edit/LoadingModal';
import { axios } from 'services/axios';
import walkthroughIds from '../walkthroughIds';
import {
    ChakraProvider,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import chakratheme from '@/chakratheme';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import ChakraButton from 'components/v4/Button';
import { useSingleToast } from 'hooks/useSingleToast';
import { useDashboardServices, useDeactivate } from 'hooks/useCustomQuery';

/*
 *This component is used for de-allocating the numbers from a user
 *Once this has happened the entire number block can be deleted
 *The number block cannot be deleted until all numbers are deallocated
 */
const DeleteNumberBlocksTable = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const { modalData, modalState, modalMode } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });
    const {
        state: tableState,
        loading: tableLoading,
        child,
    } = useSelector((state) => {
        return { ...state.table };
    });

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    const { data: services } = useDashboardServices({ currentCompany });

    const [showLoading, setShowLoading] = useState(false);
    const [actionedErrors, setActionedErrors] = useState(false);
    const buttonCtrRef = useRef(0);

    const { successToast, errorToast } = useSingleToast();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isDisassociateOpen,
        onOpen: onDisassociateOpen,
        onClose: onDisassociateClose,
    } = useDisclosure();

    const {
        isOpen: isSingleDisassociateOpen,
        onOpen: onSingleDisassociateOpen,
        onClose: onSingleDisassociateClose,
    } = useDisclosure();

    const [isBusy, setIsBusy] = useState(false);

    const { mutateAsync: deactivateNumbers } = useDeactivate();
    const [actionMode, setActionMode] = useState(null);
    const [operatorNumber, setOperatorNumber] = useState(null);
    const DEALLOCATE = 'DEALLOCATE';
    const DISASSOCIATE = 'DISASSOCIATE';

    const handleSubmitDn = async () => {
        try {
            setIsBusy(true);
            await deactivateNumbers({
                serviceID: services?.find(
                    (s) => s.name === 'Teams Phone Mobile',
                )?.id,
                phoneNumbers: [operatorNumber?.telephoneNumber],
            });
            const allData = tableState.data.map((v) => {
                if (operatorNumber.id === v.id) {
                    return {
                        ...v,
                        operatorConnectUsageID: null,
                    };
                }
                return v; // Return the original object if condition is not met
            });
            var newTableData = [...tableState.data].map((old) => {
                const newData = allData.find((v) => v.id === old.id);
                return newData || old;
            });
            dispatch({
                type: 'CHANGE_TABLE_STATE',
                payload: {
                    ...tableState,
                    data: newTableData,
                },
            });

            onSingleDisassociateClose();
            setOperatorNumber(null);
        } finally {
            setIsBusy(false);
        }
    };

    // return table and modal used for deleted number blocks
    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                onTableSucceed={(data) => {
                    setShowLoading(false);
                    setActionedErrors(false);
                    buttonCtrRef.current = 0;
                    return data.map(({ status, isEnabled, ...rest }) => ({
                        status:
                            !isEnabled && status === 0 ? 'Disabled' : status,
                        isEnabled,
                        ...rest,
                    }));
                }}
                tableFetch={'/numbers/' + child?.id}
                modalButtons={
                    modalMode === 'Edit' && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                dispatch({
                                    type: 'ON_MODAL_SUBMIT',
                                    payload: true,
                                });
                            }}
                            data-walkthroughid={
                                walkthroughIds.ufDeallocateButton
                            }>
                            Deallocate
                        </Button>
                    )
                }
                modalTitle={`Deallocate +${modalData?.telephoneNumber} from ${modalState?.displayName === undefined ? '' : modalState.displayName}`}
                titleProps={
                    <>
                        {requiredScope >= 40 &&
                            !tableLoading &&
                            tableState?.data.some((a) => a.userID != null) && (
                                <Tooltip title="Deallocate all Users from Number Block">
                                    <Button
                                        className="btn  btn-sm"
                                        style={{
                                            backgroundColor: '#DC3545',
                                            borderColor: '#DC3545',
                                            color: 'white',
                                            marginRight: '4px',
                                        }}
                                        variant="primary"
                                        onClick={() => {
                                            setActionedErrors(false);
                                            setActionMode(DEALLOCATE);
                                            onOpen();
                                        }}
                                        data-walkthroughid={
                                            walkthroughIds.ufDeallocateAllButton
                                        }>
                                        Deallocate All
                                    </Button>
                                </Tooltip>
                            )}

                        {requiredScope >= 40 &&
                            !tableLoading &&
                            tableState?.data.some(
                                (a) => a.operatorConnectUsageID === 'OCMobile',
                            ) && (
                                <Tooltip title="Disassociate all Operator Numbers from your company prior to deletion">
                                    <Button
                                        className="btn  btn-sm"
                                        style={{
                                            backgroundColor: '#DC3545',
                                            borderColor: '#DC3545',
                                            color: 'white',
                                            marginLeft: '4px',
                                        }}
                                        variant="primary"
                                        onClick={() => {
                                            setActionedErrors(false);
                                            setActionMode(DISASSOCIATE);
                                            onDisassociateOpen();
                                        }}
                                        data-walkthroughid={
                                            walkthroughIds.ufDisassociateAllButton
                                        }>
                                        Disassociate All
                                    </Button>
                                </Tooltip>
                            )}
                    </>
                }
                // eslint-disable-next-line no-unused-vars
                overrideOnRowSelected={(e, path, dataClicked) => {
                    const isAdding = e.target.checked;
                    const newVal = isAdding
                        ? buttonCtrRef.current + 1
                        : buttonCtrRef.current - 1;
                    // eslint-disable-next-line no-unused-vars
                    const newPos = newVal > 0 ? 'left' : 'right';
                    buttonCtrRef.current = newVal;
                }}
                modalUpload={(freshData, state) => {
                    return {
                        ...state,
                        numberID: null,
                        planID: null,
                        dialPlanID: null,
                        voicePolicyID: null,
                    };
                }}
                modalUpdate={
                    modalData?.usedOnResourceAccount
                        ? '/resourceaccount/'
                        : '/user/'
                }
                modalFetch={
                    modalData?.usedOnResourceAccount
                        ? `/resourceaccount/${modalData?.userID}`
                        : `/user/${modalData?.userID}`
                }
                additionalActions={[
                    {
                        icon: () => {
                            return (
                                <Button
                                    className="btn  btn-sm"
                                    style={{ marginBottom: '4px' }}
                                    variant="primary">
                                    Actioned {`(${buttonCtrRef.current})`}
                                </Button>
                            );
                        },
                        tooltip: 'Done',
                        position: 'toolbarOnSelect',
                        onClick: (event, data) => {
                            setShowLoading(true);
                            setActionedErrors(false);

                            const actionedResponses = data.map((v) =>
                                axios.post(`/Number/${v.id}/Actioned`),
                            );
                            Promise.all(actionedResponses)
                                .then((res) => {
                                    const allData = res.map((v) => ({
                                        ...v.data,
                                        status:
                                            !v.data.isEnabled &&
                                            v.data.status === 0
                                                ? 'Disabled'
                                                : config.numbers.statusJSON[
                                                      v.data.status
                                                  ],
                                    }));
                                    setTimeout(() => {
                                        setShowLoading(false);
                                    });
                                    var newTableData = [...tableState.data].map(
                                        (old) => {
                                            const newData = allData.find(
                                                (v) => v.id === old.id,
                                            );
                                            return newData || old;
                                        },
                                    );
                                    buttonCtrRef.current = 0;
                                    dispatch({
                                        type: 'CHANGE_TABLE_STATE',
                                        payload: {
                                            ...tableState,
                                            data: newTableData,
                                        },
                                    });
                                })
                                .catch((e) => {
                                    console.error('Failed!', { e });

                                    setTimeout(() => {
                                        setActionedErrors(e);
                                    });
                                });
                        },
                    },
                    (rowData) => ({
                        icon: ({ disabled }) => {
                            if (disabled) {
                                return;
                            }
                            return (
                                <div
                                    style={{
                                        backgroundColor: '#DC3545',
                                        color: 'white',
                                    }}
                                    className="btn  btn-sm"
                                    data-walkthroughid={
                                        walkthroughIds.ufDeallocateButton
                                    }>
                                    Deallocate
                                </div>
                            );
                        },
                        tooltip: 'Deallocate Number',
                        position: 'row',
                        onClick: (e, rowData) => {
                            dispatch({
                                type: 'OPEN_MODAL',
                                payload: {
                                    mode: 'Edit',
                                    data: rowData,
                                },
                            });
                        },
                        hidden: !rowData?.userID,
                    }),
                    (rowData) => ({
                        icon: ({ disabled }) => {
                            if (disabled) {
                                return;
                            }
                            return (
                                <div
                                    style={{
                                        backgroundColor: '#DC3545',
                                        color: 'white',
                                    }}
                                    className="btn  btn-sm"
                                    data-walkthroughid={
                                        walkthroughIds.ufDisassociateButton
                                    }>
                                    Disassociate
                                </div>
                            );
                        },
                        tooltip:
                            'Disassociate Operator Number from your company prior to deletion',
                        position: 'row',
                        onClick: () => {
                            setOperatorNumber(rowData);
                            onSingleDisassociateOpen();
                        },
                        hidden: rowData?.operatorConnectUsageID !== 'OCMobile',
                    }),
                ]}
                modalContent={<UsersGeneralForm />}
                title="Number Blocks"
                defaultColumns={React.useMemo(() => {
                    return [
                        {
                            title: 'Telephone Number',
                            field: 'telephoneNumber',
                            render: (rowData) =>
                                renderTelephoneNumber(
                                    rowData,
                                    'telephoneNumber',
                                ),
                        },
                        {
                            title: 'Wholesaler',
                            field: 'wholesaler',
                            hidden: requiredScope < 80,
                        },
                        {
                            title: 'Partner',
                            field: 'partner',
                        },
                        {
                            title: 'Company',
                            field: 'company',
                        },
                        {
                            title: 'User',
                            field: 'user',
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: (rowData) => (
                                <Box display="flex">
                                    {config.numbers.statusJSON[rowData.status]}{' '}
                                    &nbsp;
                                    {rowData?.engineMessage &&
                                        rowData?.engineMessage?.length > 0 && (
                                            <Message
                                                title={rowData.engineMessage}
                                            />
                                        )}
                                </Box>
                            ),
                            lookup: config.numbers.statusJSON,
                        },
                        {
                            title: 'Trunk',
                            field: 'trunk',
                        },
                    ];
                }, [])}
                selectionProps={(rowData) => ({
                    hidden: rowData.status !== 7 || rowData.overStampNumber,
                    color: 'primary',
                })}
                showSelectAllCheckbox={false}
                selection
                groupable
                filterable
                noTitle
                maxBodyHeight={window.innerHeight * config.tabView.tableHeight}
            />

            <ChakraProvider resetCSS={false} theme={chakratheme}>
                <Modal
                    isCentered
                    isOpen={isOpen || isDisassociateOpen}
                    onClose={onClose || onDisassociateClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            {actionMode == DEALLOCATE
                                ? 'Deallocate all Users'
                                : 'Disassociate all Numbers'}
                        </ModalHeader>
                        <ModalBody>
                            {actionMode == DEALLOCATE
                                ? 'Are you sure you wish to deallocate all users from number block?'
                                : 'Are you sure you wish to disassociate all operator numbers from company?'}
                        </ModalBody>
                        <ModalFooter justifyContent="flex-start">
                            <ChakraButton
                                colorScheme="red"
                                isLoading={isBusy}
                                onClick={async () => {
                                    if (actionMode == DEALLOCATE) {
                                        setIsBusy(true);
                                        axios
                                            .post(
                                                `/NumberBlock/${child.id}/DeallocateFromUsers`,
                                            )
                                            .then((res) => {
                                                const allData = res.data.map(
                                                    (v) => ({
                                                        ...v,
                                                        user: v.user,
                                                        userID: v.userID,
                                                        status:
                                                            !v.isEnabled &&
                                                            v.status === 0
                                                                ? 'Disabled'
                                                                : v.status,
                                                    }),
                                                );

                                                var newTableData = [
                                                    ...tableState.data,
                                                ].map((old) => {
                                                    const newData =
                                                        allData.find(
                                                            (v) =>
                                                                v.id === old.id,
                                                        );
                                                    return newData || old;
                                                });
                                                dispatch({
                                                    type: 'CHANGE_TABLE_STATE',
                                                    payload: {
                                                        ...tableState,
                                                        data: newTableData,
                                                    },
                                                });
                                                onClose();
                                                successToast(
                                                    'Successfully deallocated all users from number block.',
                                                );
                                            })
                                            .catch((e) => {
                                                errorToast(
                                                    e?.response?.data
                                                        ?.message ||
                                                        'Unable to deallocate all users from number block. Please try again later',
                                                );
                                            })
                                            .finally(() => {
                                                setIsBusy(false);
                                            });
                                    } else {
                                        try {
                                            setIsBusy(true);

                                            var numbersToDisassociate = [];

                                            tableState.data.map((number) => {
                                                if (
                                                    number.operatorConnectUsageID ===
                                                    'OCMobile'
                                                ) {
                                                    numbersToDisassociate.push(
                                                        number.telephoneNumber,
                                                    );
                                                }
                                            });

                                            await deactivateNumbers({
                                                serviceID: services?.find(
                                                    (s) =>
                                                        s.name ===
                                                        'Teams Phone Mobile',
                                                )?.id,
                                                phoneNumbers:
                                                    numbersToDisassociate,
                                            });

                                            const allData = tableState.data.map(
                                                (v) => ({
                                                    ...v,
                                                    operatorConnectUsageID:
                                                        null,
                                                    // status:
                                                    //     !v.isEnabled &&
                                                    //     v.status === 0
                                                    //         ? 'Disabled'
                                                    //         : 4,
                                                }),
                                            );

                                            var newTableData = [
                                                ...tableState.data,
                                            ].map((old) => {
                                                const newData = allData.find(
                                                    (v) => v.id === old.id,
                                                );
                                                return newData || old;
                                            });
                                            dispatch({
                                                type: 'CHANGE_TABLE_STATE',
                                                payload: {
                                                    ...tableState,
                                                    data: newTableData,
                                                },
                                            });
                                            onDisassociateClose();
                                        } finally {
                                            setIsBusy(false);
                                        }
                                    }
                                }}
                                data-walkthroughid={
                                    walkthroughIds.ufDeallocateButton
                                }>
                                {actionMode == DEALLOCATE
                                    ? 'Deallocate'
                                    : 'Disassociate'}
                            </ChakraButton>
                            <ChakraButton
                                colorScheme="messenger"
                                bg="white"
                                variant="ghost"
                                isDisabled={isBusy}
                                minW="128.4px"
                                marginLeft="30px"
                                onClick={
                                    actionMode == DEALLOCATE
                                        ? onClose
                                        : onDisassociateClose
                                }>
                                Close
                            </ChakraButton>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal
                    isOpen={isSingleDisassociateOpen}
                    onClose={onSingleDisassociateClose}>
                    <ModalOverlay />
                    <ModalCloseButton />
                    <ModalContent>
                        <ModalHeader>Disassociate Number</ModalHeader>
                        <ModalBody>
                            <span>
                                Are you sure you would like to disassociate
                                operator number? This cannot be undone.
                            </span>
                        </ModalBody>
                        <ModalFooter justifyContent="flex-start">
                            <ChakraButton
                                colorScheme="red"
                                isLoading={isBusy}
                                onClick={handleSubmitDn}>
                                Disassociate
                            </ChakraButton>
                            <ChakraButton
                                colorScheme="messenger"
                                bg="white"
                                minW="128.4px"
                                marginLeft="30px"
                                isDisabled={isBusy}
                                variant="ghost"
                                onClick={onSingleDisassociateClose}>
                                Close
                            </ChakraButton>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>

            <LoadingModal
                show={showLoading}
                error={actionedErrors}
                onHide={() => {
                    if (actionedErrors) {
                        setShowLoading(false);
                        setActionedErrors(false);
                    }
                }}
            />
        </>
    );
};

export default DeleteNumberBlocksTable;
