/* eslint-disable no-unused-vars */
import { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
    Heading,
    Box,
    Stack,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';

// API
import { getUserAcc } from '@/API/Users/getUserAcc';
import { getAssignedPlans } from '@/API/Company/getAssignedPlans';
import { getNumber } from '@/API/Number/getNumber';
import getService from '@/API/Service/getService';

import { axios } from '@/services/axios';
import {
    reducerFn,
    reducerValidationFn,
} from '@/pages/accounts/resourceAccounts/edit/resourceAccReducer';
import { updateForNoneTcap } from '@/pages/accounts/users/new/index';

import CustomAttributes from 'components/v4/CustomAttributes/Index';
import { Button } from 'components/v4/';
import { ErrorPage } from 'components/index';
import { UpdateUserToast } from '@/pages/accounts/resourceAccounts/edit/UpdateUserToast';
import General from './General';
import { LoadingSpinnerWithMessage } from '@/lib/loading-screen';

import {
    MS_DIRECT_ROUTING,
    userAccEPoint,
    PlanMode,
    ACTIONS,
    userTabList,
} from '@/pages/accounts/resourceAccounts/constants';
import {
    chakraSelectDarkMode,
    customTabStyles,
    defaultChakraSelectStyle,
    selectedTabStyles,
} from '@/constants';
import Services from '../services/Services';
import { ServicesProvider } from '../services/ServicesContext';

import walkthroughIds from '@/pages/accounts/users/walkthroughIds';
import config from '@/config.json';

export default function UserEdit() {
    const navigate = useNavigate();
    const { id } = useParams();

    const location = useLocation();
    const { rowData } = location?.state || {};
    const isEnable = rowData?.isLicensed;
    const [tabIndex, setTabIndex] = useState(0);

    const [state, dispatch] = useReducer(reducerFn, {
        numberID: '',
    });
    const [validation, setValidation] = useReducer(reducerValidationFn, {
        voicePolicyError: null,
        dialPlanError: null,
    });
    // NOTE: pass this state to v4 custom attributes tab
    const [attributes, setAttributes] = useState({
        attribute1: '',
        attribute2: '',
        attribute3: '',
    });
    const isAttributeError = Object.entries(attributes).some(
        (att) => att[1]?.length > 100,
    );
    const [numberList, setNumberList] = useState([]);
    const [assignedTrunkType, setAssignedTrunkType] = useState(null);

    const { requiredScope } = useSelector((state) => state.login);
    const { currentCompany, currentCompanyData: companyData } = useSelector(
        (state) => state.navigationLists,
    );
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const chakraStyles = {
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    const enabledPlanMode =
        companyData?.companyBillingSettings?.perUserPlan === 1;
    const partnerOnlyPlanMode =
        companyData?.companyBillingSettings?.perUserPlan === 2 &&
        requiredScope > 20;
    const disabledPlanMode =
        companyData?.companyBillingSettings?.perUserPlan === 0 ||
        (companyData?.companyBillingSettings?.perUserPlan === 2 &&
            requiredScope === 20);
    const { tabs } = walkthroughIds;
    function handleManagedBy(v) {
        dispatch({
            type: ACTIONS.CHANGE_MANAGED_BY,
            managedBy: v.value,
        });
    }

    function handleSetNumber(number, isDrNumber) {
        dispatch({
            type: ACTIONS.SET_NUMBER,
            payload: {
                number,
                isDrNumber,
            },
        });
    }

    function handleChangeNumber(v, numberList) {
        const number = numberList.find((item) => item.id === v.id);
        dispatch({
            type: ACTIONS.CHANGE_NUMBER_ID,
            numberID: v.id,
            isDrNumber: number.trunkType === MS_DIRECT_ROUTING,
        });
        setValidation({
            type: ACTIONS.VALIDATE_VOICE_POLICY,
            voicePolicyID: state.isDrNumber ? state.voicePolicyID : null,
        });
        setValidation({
            type: ACTIONS.VALIDATE_DIAL_PLAN,
            dialPlanID: state.isDrNumber ? state.dialPlanID : null,
        });
    }

    function handleChangePlan(v) {
        dispatch({
            type: ACTIONS.CHANGE_USER_PLAN_MODE,
            planID: v.id,
        });
    }

    function handleSetUserPlanMode(planMode) {
        dispatch({
            type: ACTIONS.SET_USER_PLAN_MODE,
            userPlanMode: planMode,
        });
    }

    function handleChangeVoicePolicy(v) {
        dispatch({
            type: ACTIONS.CHANGE_VOICE_POLICY_ID,
            voicePolicyID: v.id,
        });
        setValidation({
            type: ACTIONS.VALIDATE_VOICE_POLICY,
            voicePolicyID: v.id,
        });
    }

    function handleChangeDialPlan(v) {
        dispatch({
            type: ACTIONS.CHANGE_DIAL_PLAN_ID,
            dialPlanID: v.id,
        });
        setValidation({
            type: ACTIONS.VALIDATE_DIAL_PLAN,
            dialPlanID: v.id,
        });
    }
    function handleChangeAttribute(e) {
        const { name, value } = e.target;
        setAttributes((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    function handleDrVoicePolicies(res) {
        dispatch({
            type: ACTIONS.SET_DR_VOICE_POLICIES,
            payload: res.data.voicePolicies,
        });
    }

    function handleDrDialPlans(res) {
        dispatch({
            type: ACTIONS.SET_DR_DIAL_PLANS,
            payload: res.data.dialPlans,
        });
    }

    const {
        data: userData,
        isLoading,
        error,
        isFetching: isUserFetching,
    } = useQuery({
        queryKey: ['user', id],
        queryFn: async () => {
            const data = await getUserAcc(id);
            return data;
        },
        onSuccess: (data) => {
            dispatch({
                type: ACTIONS.INIT,
                payload: data,
            });
            // NOTE: need to set this as not part of reducerFn
            setAttributes({
                attribute1: data.attribute1,
                attribute2: data.attribute2,
                attribute3: data.attribute3,
            });

            if (companyData && state?.userPlanMode === undefined) {
                const { companyBillingSettings } = companyData;
                const { perUserPlan } = companyBillingSettings || {};

                const notHiddenPlanMode =
                    perUserPlan === 1 ? PlanMode.WRITABLE : PlanMode.READ_ONLY;
                const newPlanMode =
                    perUserPlan === 0 ? PlanMode.HIDDEN : notHiddenPlanMode;
                handleSetUserPlanMode(newPlanMode);
            }
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        staleTime: 0,
    });

    const { data: userPlans } = useQuery({
        queryKey: ['userPlans', currentCompany],
        queryFn: async () => {
            const data = await getAssignedPlans(currentCompany);
            return data;
        },
        enabled: isEnable && (enabledPlanMode || partnerOnlyPlanMode),
        refetchOnWindowFocus: false,
    });

    const { data: availableNumbers, isFetching } = useQuery({
        queryKey: ['availableNumbers', currentCompany],
        queryFn: async () => {
            const res = await axios.get(
                `/company/${currentCompany}/numbers/available`,
            );
            if (res.status !== 200) throw new Error(res.statusText);
            return res.data;
        },
        enabled:
            isEnable &&
            ((Boolean(state?.numberID) && assignedTrunkType !== null) ||
                (!state?.number && assignedTrunkType === null)),
        onSuccess: (data) => {
            const userNumber = {
                id: userData?.numberID,
                telephoneNumber:
                    assignedTrunkType === null || assignedTrunkType === ''
                        ? userData?.telephoneNumber
                        : `+${userData?.telephoneNumber} - ${assignedTrunkType}`,
                numberID: userData?.numberID,
                trunkType: assignedTrunkType,
            };
            const allNumbers = data
                ?.map((number) => ({
                    ...number,
                    telephoneNumber: `+${number.telephoneNumber} - ${number.trunkType == '' ? 'No Trunk Assigned' : number.trunkType}`,
                }))
                .concat({
                    id: null,
                    telephoneNumber: config.notAllocated.dropdown,
                })
                ?.sort((a, b) =>
                    a.telephoneNumber?.localeCompare(b.telephoneNumber),
                )
                .concat(userData?.telephoneNumber ? userNumber : []);

            setNumberList(allNumbers);
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const { data: fetchedNumber } = useQuery({
        queryKey: ['number', state?.numberID],
        queryFn: async () => {
            const data = await getNumber(state.numberID);
            return data;
        },
        onSuccess: (data) => {
            const newNumberIsDR = data.trunkType === MS_DIRECT_ROUTING;
            handleSetNumber(data, newNumberIsDR);
        },
        enabled: Boolean(state?.numberID),
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const { data: serviceData, status: serviceStatus } = useQuery({
        queryKey: ['service', fetchedNumber?.serviceID],
        queryFn: async () => {
            try {
                const res = await getService(fetchedNumber?.serviceID);
                return res;
            } catch (error) {
                throw new Error(`Error fetching service: ${error}`);
            }
        },
        onSuccess: (res) => {
            handleDrVoicePolicies(res);
            handleDrDialPlans(res);
        },
        enabled:
            Boolean(state?.numberID) &&
            Boolean(fetchedNumber?.serviceID) &&
            state?.isDrNumber,
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    useEffect(() => {
        if (state?.number && assignedTrunkType === null) {
            setAssignedTrunkType(state.number.trunkType);
        }
    }, [state?.number]);

    async function updateUserData(data) {
        userData?.managedBy !== 0 &&
            updateForNoneTcap(userAccEPoint, data, userData, state, dispatch);
        return userData?.managedBy !== 0
            ? null
            : await axios
                  .put(`${userAccEPoint}/`, {
                      ...data,
                  })
                  .then(
                      (_res) => dispatch({ type: ACTIONS.SUBMIT_FORM_SUCCESS }),
                      (rej) => {
                          console.error(rej);

                          dispatch({
                              type: ACTIONS.SUBMIT_FORM_FAILURE,
                              message:
                                  rej?.response?.data.message ||
                                  'There was an error when updating, please try again later.',
                          });
                      },
                  )
                  .finally(() => dispatch({ type: ACTIONS.RESET_FORM_STATUS }));
    }
    async function onSubmit(data) {
        const formData = {
            managedBy: data.managedBy,
            planID: data.planID,
            numberID: data.numberID,
            voicePolicyID: data.voicePolicyID,
            dialPlanID: data.dialPlanID,
            attribute1: attributes.attribute1,
            attribute2: attributes.attribute2,
            attribute3: attributes.attribute3,
        };
        const submitData = {
            ...userData,
            ...formData,
        };
        dispatch({
            type: ACTIONS.SUBMIT_FORM,
            payload: submitData,
        });
        await updateUserData(submitData);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(state);
    };

    const generalProps = {
        state,
        validation,
        userPlans,
        numberList,
        userData,
        assignedTrunkType,
        handleChangeNumber,
        handleManagedBy,
        handleChangePlan,
        handleChangeVoicePolicy,
        handleChangeDialPlan,
        serviceStatus,
        chakraStyles,
        enabledPlanMode,
        partnerOnlyPlanMode,
        disabledPlanMode,
        companyData,
        isLoading,
    };

    if (isLoading || isFetching || isUserFetching) {
        return <LoadingSpinnerWithMessage message="Loading User" />;
    }

    if (error) {
        return <ErrorPage error={error} />;
    }

    return (
        <>
            <UpdateUserToast state={state} />
            <Heading as="h3" fontSize="1.75rem" fontWeight={500}>
                Edit User Account: {`${userData?.displayName}`}
            </Heading>
            <Box
                sx={{
                    padding: '10px 0',
                    maxWidth: '2xl',
                    minWidth: 'xl',
                    margin: '0 auto',
                }}>
                <Box
                    as="form"
                    px={{ base: '4', md: '6' }}
                    py={{ base: '5', md: '6' }}
                    onSubmit={handleSubmit}>
                    <Tabs
                        tabIndex={tabIndex}
                        onChange={(index) => {
                            setTabIndex(index);
                        }}>
                        <TabList justifyContent="center" borderBottom={0}>
                            {userTabList
                                .filter(
                                    (tab) =>
                                        tab !== 'Services' ||
                                        companyData?.vendorService,
                                )
                                .map((tab) => (
                                    <Tab
                                        data-walkthroughid={
                                            '/accounts/users/tab/' + tab
                                        }
                                        key={tab}
                                        sx={customTabStyles}
                                        _selected={selectedTabStyles}>
                                        {tab}
                                    </Tab>
                                ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel
                                px={0}
                                data-walkthroughid={tabs.ufGeneralTab}>
                                <General {...generalProps} />
                            </TabPanel>
                            <TabPanel
                                px={0}
                                data-walkthroughid={tabs.ufCustomTab}>
                                <CustomAttributes
                                    onChange={handleChangeAttribute}
                                    state={attributes}
                                />
                            </TabPanel>
                            {tabIndex == 2 && (
                                <TabPanel px={0}>
                                    <ServicesProvider>
                                        <Services userID={id} />
                                    </ServicesProvider>
                                </TabPanel>
                            )}
                        </TabPanels>

                        <Stack
                            spacing="6"
                            px={{ base: '4', md: '6' }}
                            pt={{ base: '5', md: '6' }}
                            pb="2px"
                            sx={{
                                justifyContent: 'center',
                                width: '170px',
                                margin: '0 auto',
                            }}
                            direction={{ base: 'column', md: 'row' }}>
                            <Box>
                                <Button
                                    className="secondary h-10 min-w-[5rem] font-semibold"
                                    variant="outline"
                                    onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            {tabIndex != 2 && (
                                <Box>
                                    <Button
                                        type="submit"
                                        disabled={isAttributeError}
                                        className="h-10 min-w-[5rem] font-semibold disabled:cursor-not-allowed">
                                        {state.formStatus === 'pending'
                                            ? 'Saving...'
                                            : 'Save'}
                                    </Button>
                                </Box>
                            )}
                        </Stack>
                    </Tabs>
                </Box>
            </Box>
        </>
    );
}
