import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { Input } from 'components/v4';
import style from '../../edit/styles';
import { useDefinedAttributes } from '../DefinedAttributesContext';
import useDefinedAttributeValidation from './useDefinedAttributeValidation';

export default function IntAttribute({
    attribute,
    index,
    requiredScope,
}) {
    const validation = JSON.parse(attribute.definedAttributeValidationType);
    const { attributesErrors } = useDefinedAttributes();
    const error = attributesErrors.find((obj) => obj.id === index);

    const {
        handleAttributeChange,
        handleAttributeError,
        removeAttributeError,
    } = useDefinedAttributeValidation();

    return (
        <Box sx={style.fieldContainer}>
            <FormControl isRequired={validation?.Required} isInvalid={error}>
                <FormLabel sx={style.label}>
                    {attribute.name}{' '}
                    {attribute.toolTip && (
                        <Tooltip
                            label={attribute.toolTip}
                            hasArrow
                            placement="top-end">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </FormLabel>
                <Input
                    onKeyDown={(evt) =>
                        ['e', 'E'].includes(evt.key) && evt.preventDefault()
                    }
                    type="number"
                    placeholder="Please enter a value"
                    value={attribute.value}
                    onChange={(e) => {
                        removeAttributeError(index);
                        if (
                            (parseInt(validation.Minimum) > 0 &&
                                parseInt(validation.Maximum) > 0 &&
                                parseInt(e.target.value) >
                                    validation.Maximum) ||
                            parseInt(e.target.value) < validation.Minimum
                        ) {
                            handleAttributeError(
                                index,
                                `Only numeric values between ${validation.Minimum} and ${validation.Maximum} are allowed.`,
                            );
                        }

                        handleAttributeChange(e.target.value, index);
                    }}
                    isDisabled={requiredScope < attribute.updateScope}
                />

                {error ? (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : attribute?.description ? (
                    <FormHelperText>{attribute?.description}</FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    );
}
