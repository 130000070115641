import { Box } from '@chakra-ui/react';

/**
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {import('react').CSSProperties} props.className
 * @returns
 */

function TpmWrapper({ children, className = '' }) {
    return <Box className={className}>{children}</Box>;
}

export default TpmWrapper;
