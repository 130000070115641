import { axios } from '@/services/axios';
import {
    Box,
    HStack,
    Skeleton,
    SkeletonCircle,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Heading,
} from 'components/v4';
import ConfirmationModal from 'components/v4/Cards/ServiceProfileCard/ConfirmationModal';
import { isEmpty } from 'lodash';
import FooterContainer from 'pages/services/serviceprofiles/edit/FooterContainer';
import { useDefinedAttributes } from 'pages/services/serviceprofiles/vendorDefined/DefinedAttributesContext';
import DefinedAttributes from 'pages/services/serviceprofiles/vendorDefined/attributes/definedAttributes';
import useDefinedAttributeValidation from 'pages/services/serviceprofiles/vendorDefined/attributes/useDefinedAttributeValidation';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { classNames } from 'utils/classNames';

export default function AddVendorDefined({ serviceWizard }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { requiredScope } = useSelector((state) => state.login);

    const { darkMode } = useSelector((state) => state.settings);
    const handleBackButton = () => serviceWizard.reset();

    const [error, setError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );

    const { dispatch, attributesList, attributesErrors } =
        useDefinedAttributes();

    const { handleAttributeError, removeAttributeError } =
        useDefinedAttributeValidation();

    // to be completed
    const DEFAULT_VENDOR_DEFINED = {
        name: '',
        serviceDefinitionID: '',
    };

    const [data, setData] = useState(DEFAULT_VENDOR_DEFINED);

    // get service definition for vendor defined service
    const { data: vendorDefinedData, isFetching: vendorDefinedLoading } =
        useQuery({
            queryKey: [
                'vendorDefined',
                serviceWizard.selectedServiceProfile.id,
            ],
            queryFn: async () => {
                const res = await axios.get(
                    `service/vendordefined/company/${companyID}/Template/${serviceWizard.selectedServiceProfile.serviceDefinition.id}`,
                );
                return res.data;
            },
            enabled: Boolean(
                serviceWizard.selectedServiceProfile?.serviceDefinition?.id,
            ),
            onSuccess: async (data) => {
                // data for the service type and attributes list
                setData((prevState) => ({
                    ...prevState,
                    ...data,
                }));
                if (data.attributeList?.length > 0) {
                    for (let att of data.attributeList) {
                        // attribute value is empty
                        if (isEmpty(att.value)) {
                            // default value is empty, set value to be empty string
                            att.value = isEmpty(att.defaultValue)
                                ? null
                                : att.defaultValue;
                        }
                    }
                }
                dispatch({
                    type: 'UPDATE_ATT_LIST',
                    payload: data.attributeList,
                });
            },
            onError: (err) => {
                toast.error(err?.response?.data?.message);
                handleBackButton();
            },
            refetchOnWindowFocus: false,
        });

    const handleValidation = () => {
        setError(null);
        let hasError = false;

        // check for any attributes errors
        if (attributesErrors.length > 0) {
            hasError = true;
        }

        // check for required attributes
        if (attributesList?.length > 0) {
            attributesList.forEach((att, index) => {
                const validation = JSON.parse(
                    att.definedAttributeValidationType,
                );

                // if required, input cannot be empty
                if (validation.Required && isEmpty(att.value)) {
                    handleAttributeError(index, 'Field is required.');
                    hasError = true;
                } else {
                    removeAttributeError(index);
                }
            });
        }
        if (hasError) return;

        // validation passes, required confirmation to add service
        onOpen();
    };

    const handleSubmit = async (e) => {
        // close add service confirmation modal
        e.preventDefault();
        onClose();

        setIsLoading(true);
        try {
            await axios.post(`service/vendordefined`, {
                name: data.name,
                companyID: companyID,
                serviceDefinitionID: data.serviceDefinitionID,
                attributeList: attributesList,
            });

            toast.success(`Successfully created a Vendor Defined Service!`);
            // go back to dashboard services
            handleBackButton();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: '2xl', minWidth: 'xl', margin: 'auto' }}>
            <Heading fontSize="3xl" as="h2" mb={'10px'}>
                Add{' '}
                {serviceWizard.selectedServiceProfile?.serviceDefinition
                    ?.name || 'Vendor Defined Service'}
            </Heading>

            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. please try again later.'}
                    </AlertDescription>
                </Alert>
            )}

            {vendorDefinedLoading ? (
                <Box>
                    {[...Array(3)].map((_, index) => (
                        <Stack key={index} mb={3}>
                            <HStack>
                                <Skeleton h={5} w={'25%'} />
                                <SkeletonCircle h={5} w={5} />
                            </HStack>

                            <Skeleton h={10} borderRadius={4} />
                            <Skeleton h={5} w={'15%'} />
                        </Stack>
                    ))}
                </Box>
            ) : attributesList?.length > 0 ? (
                <DefinedAttributes requiredScope={requiredScope} />
            ) : (
                <Alert status="info">
                    <AlertIcon />
                    No information is required at this time! Click 'Add' to
                    continue.
                </Alert>
            )}

            <FooterContainer>
                <Button
                    variant="outline"
                    w={{ base: '100%', md: '80px' }}
                    className={classNames(
                        darkMode ? 'bg-white hover:opacity-70' : null,
                    )}
                    onClick={() => serviceWizard.setFlow('CreateService')}>
                    Back
                </Button>
                <Button
                    ml="1rem"
                    isLoading={isLoading}
                    onClick={() => handleValidation()}
                    w={{ base: '100%', md: '80px' }}
                    type="submit">
                    Add
                </Button>
            </FooterContainer>

            <ConfirmationModal
                serviceName={'Vendor Defined Service'}
                isOpen={isOpen}
                onClose={onClose}
                vendorDefined={
                    serviceWizard.selectedServiceProfile?.serviceDefinition
                        ?.name
                }
                handleSubmit={handleSubmit}
            />
        </Box>
    );
}
