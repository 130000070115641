import {
    Box,
    ChakraProvider,
    Stack,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { PARTNER } from './BillingForm';

import { FormControl, FormLabel, Input } from 'components/v4';

import walkthroughIds from '../walkthroughIds';
import chakratheme, {
    floatingLabelStyles as floatingStyle,
} from '@/chakratheme';
/*
 * Component for custom attributes in regards to parters and wholesalers
 */
const CustomFieldsForm = ({ mode, disabled = false }) => {
    const dispatch = useDispatch();

    // state access
    const { state, stateError } = useSelector((state) => {
        return { ...state.modal };
    });
    const isPartner = mode === PARTNER;

    const { darkMode } = useSelector((state) => state.settings);
    const floatingLabelStyles = floatingStyle(darkMode);

    // return the custom fields form

    return (
        <ChakraProvider resetCSS theme={chakratheme}>
            <Box
                mt="2%"
                mx="auto"
                px="15px"
                maxW={[640, 768, 1280, 1536]}
                sx={{
                    fontFamily: 'var(--chakra-fonts-body)',
                }}>
                <Stack spacing={'25px'}>
                    {isPartner
                        ? ['pAttribute1', 'pAttribute2', 'pAttribute3'].map(
                              (v, idx) => (
                                  <FormControl
                                      isInvalid={Boolean(stateError?.[v])}
                                      walkthroughId={`${
                                          walkthroughIds.custom
                                              .ufCustomAttribute
                                      }/${idx + 1}`}
                                      sx={floatingLabelStyles}>
                                      <Input
                                          placeholder=" "
                                          value={state?.[v] ?? ''}
                                          isDisabled={disabled}
                                          onChange={(e) => {
                                              dispatch({
                                                  type: 'CHANGE_MODAL_STATE',
                                                  payload: {
                                                      [v]: e.target.value,
                                                  },
                                              });
                                          }}
                                          data-peer
                                      />
                                      <FormLabel
                                          htmlFor={`Custom Attribute ${
                                              idx + 1
                                          }`}
                                          variant="floating">
                                          {`Custom Attribute ${idx + 1}`}
                                      </FormLabel>
                                      {Boolean(stateError?.[v]) ? (
                                          <FormErrorMessage>
                                              {stateError?.[v]}
                                          </FormErrorMessage>
                                      ) : (
                                          <FormHelperText>
                                              {stateError?.[v]}
                                          </FormHelperText>
                                      )}
                                  </FormControl>
                              ),
                          )
                        : ['attribute1', 'attribute2', 'attribute3'].map(
                              (v, idx) => (
                                  <FormControl
                                      isInvalid={Boolean(stateError?.[v])}
                                      walkthroughId={`${
                                          walkthroughIds.custom
                                              .ufCustomAttribute
                                      }/${idx + 1}`}
                                      sx={floatingLabelStyles}>
                                      <Input
                                          placeholder=" "
                                          value={state?.[v] ?? ''}
                                          isDisabled={disabled}
                                          onChange={(e) => {
                                              dispatch({
                                                  type: 'CHANGE_MODAL_STATE',
                                                  payload: {
                                                      [v]: e.target.value,
                                                  },
                                              });
                                          }}
                                          data-peer
                                      />
                                      <FormLabel
                                          htmlFor={`Custom Attribute ${
                                              idx + 1
                                          }`}
                                          variant="floating">
                                          {`Custom Attribute ${idx + 1}`}
                                      </FormLabel>
                                      {Boolean(stateError?.[v]) ? (
                                          <FormErrorMessage>
                                              {stateError?.[v]}
                                          </FormErrorMessage>
                                      ) : (
                                          <FormHelperText>
                                              {stateError?.[v]}
                                          </FormHelperText>
                                      )}
                                  </FormControl>
                              ),
                          )}
                </Stack>
            </Box>
        </ChakraProvider>
    );
};

export default CustomFieldsForm;
