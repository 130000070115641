import {
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
} from 'components/v4';
import {
    Box,
    FormErrorMessage,
    FormHelperText,
    IconButton,
    InputGroup,
    InputRightElement,
    Modal,
    ModalOverlay,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import ChakraButton from 'components/v4/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDarkToggleBtnStyles } from 'pages/telephony/numberBlocks/split-join/constants';
import { ChipList } from 'components/v4/ChipInput/ChipList';
import ChipInput from 'components/v4/ChipInput/ChipInput';
import { QuestionIcon } from '@chakra-ui/icons';
import { axios } from '@/services/axios';
import { toast } from 'react-toastify';
import { providersOption } from './constant';
import { useTenant } from './useTenant';
import { useEmail } from './useEmail';
import TenantLookup from './TenantLookup';
import walkthroughIds from '../../../accounts/admins/walkthroughIds';

export default function AddProvider({ isOpen, onClose, ssoProviders }) {
    const {
        isOpen: isOpenLookup,
        onOpen: onOpenLookup,
        onClose: onCloseLookup,
    } = useDisclosure();

    const { darkMode } = useSelector((state) => state.settings);
    const darkToggleBtnStyle = getDarkToggleBtnStyles(darkMode);

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const {
        ufProviderSelect,
        ufTenantInput,
        ufEmailInput,
        ufLookupBtn,
        ufOptionBtn,
        ufBackBtn,
        ufSubmitBtn,
    } = walkthroughIds.adminSSO;

    const initialMSProvider = {
        isEnabled: false,
        isVerified: false,
        isBoth: '0',
        emailDomains: [],
        tenantIds: [],
    };

    const initialProviderState = {
        provider: '',
    };

    const [providerState, setProviderState] = useState(initialProviderState);
    const [showProviderForm, setShowProviderForm] = useState(null);

    const [errorProvider, setErrorProvider] = useState({
        isError: false,
        message: '',
    });

    const {
        tenants,
        setTenants,
        tenantInput,
        tenantError,
        setTenantError,
        addTenants,
        handleTenantChange,
        handleTenantKeyDown,
        handleTenantPaste,
        handleTenantCloseClick,
    } = useTenant(setProviderState);

    const {
        emails,
        setEmails,
        emailInput,
        emailError,
        setEmailError,
        handleEmailChange,
        handleEmailKeyDown,
        handleEmailPaste,
        handleEmailCloseClick,
    } = useEmail(setProviderState);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const isOrCondition =
            providerState?.isBoth === '0' &&
            (tenants.length > 0 || emails?.length > 0);
        const isAndCondition =
            providerState?.isBoth === '1' &&
            tenants.length > 0 &&
            emails.length > 0;
        setIsDisabled(!(isOrCondition || isAndCondition));
    }, [tenants, emails, providerState]);

    const handleProviderChange = (item) => {
        setErrorProvider({ isError: false, message: '' });
        if (item.target.value == 0) {
            setTenants([]);
            setEmails([]);
            setProviderState({
                ...initialMSProvider,
                provider: item.target.value,
            });
        } else {
            setProviderState(initialProviderState);
        }
        setShowProviderForm(item.target.value);
    };

    // api call for adding a new sso provider
    const handleAddProvider = async () => {
        // add more provider option as it becomes available
        if (providerState.provider == 0) {
            setIsLoading(true);
            var request = {
                ...providerState,
                isBoth: providerState.isBoth == '1' ? true : false,
                companyId: currentCompany,
                tenantIds: tenants,
                emailDomains: emails,
            };
            try {
                await axios.post('/sso/provider', request);
                toast.success('Provider has been added successfully!');
                onClose();
            } catch (e) {
                toast.error(e?.response?.data?.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleOpenLookup = () => {
        setTenantError({ isError: false, message: '', duplicates: '' });
        onOpenLookup();
    };

    return (
        <Modal motionPreset="slideInTop" isOpen={isOpen} autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Provider</ModalHeader>
                <ModalBody>
                    <FormControl isRequired mb={2}>
                        <FormLabel>Providers</FormLabel>
                        <Select
                            data-walkthroughid={ufProviderSelect}
                            isDisabled={
                                providersOption.filter(
                                    (prov) =>
                                        !ssoProviders?.includes(prov.value),
                                ).length == 0
                            }
                            value={providerState.provider}
                            placeholder={
                                providersOption.filter(
                                    (prov) =>
                                        !ssoProviders?.includes(prov.value),
                                ).length == 0
                                    ? 'No options available'
                                    : 'Select provider'
                            }
                            options={providersOption.filter(
                                (prov) => !ssoProviders?.includes(prov.value),
                            )}
                            onChange={(item) => handleProviderChange(item)}
                        />
                        {errorProvider.isError && (
                            <FormErrorMessage>
                                {errorProvider.message}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    {showProviderForm == 0 && (
                        <>
                            <FormControl
                                isRequired={providerState?.isBoth == 1}
                                mb={2}
                                isInvalid={tenantError.isError}>
                                <FormLabel>Tenant IDs</FormLabel>
                                <ChipList
                                    inputs={tenants}
                                    onCloseClick={handleTenantCloseClick}
                                />
                                <InputGroup
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}>
                                    <Input
                                        data-walkthroughid={ufTenantInput}
                                        placeholder="Enter your Tenant IDs"
                                        onPaste={handleTenantPaste}
                                        onKeyDown={handleTenantKeyDown}
                                        onChange={handleTenantChange}
                                        value={tenantInput.trim()}
                                    />

                                    {isHovered && (
                                        <InputRightElement>
                                            <Tooltip
                                                label={
                                                    'Click me! Lookup your Tenant ID'
                                                }
                                                hasArrow
                                                placement="bottom">
                                                <IconButton
                                                    data-walkthroughid={
                                                        ufLookupBtn
                                                    }
                                                    color={
                                                        darkMode
                                                            ? 'white'
                                                            : 'inherit'
                                                    }
                                                    bg={'none'}
                                                    _active={{ bg: 'none' }}
                                                    _hover={{
                                                        bg: 'none',
                                                        color: darkMode
                                                            ? 'white'
                                                            : 'inherit',
                                                    }}
                                                    onClick={() => {
                                                        handleOpenLookup();
                                                    }}
                                                    className="btn"
                                                    icon={<QuestionIcon />}
                                                />
                                            </Tooltip>
                                        </InputRightElement>
                                    )}
                                </InputGroup>
                                {!tenantError.isError ? (
                                    <FormHelperText>
                                        eg. 6b29fc40-ca47-1067-b31d-00dd010662da
                                    </FormHelperText>
                                ) : tenantError.message ? (
                                    <FormErrorMessage>
                                        {tenantError.message}
                                    </FormErrorMessage>
                                ) : (
                                    <FormErrorMessage>
                                        {tenantError.duplicates}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl
                                mb={2}
                                sx={darkToggleBtnStyle}
                                isRequired>
                                <FormLabel>Option</FormLabel>
                                <ToggleButtonGroup
                                    data-walkthroughid={ufOptionBtn}
                                    className="toggleButton"
                                    color="primary"
                                    exclusive
                                    value={providerState?.isBoth}
                                    aria-label="Platform"
                                    style={{
                                        height: '48px',
                                        alignItems: 'center',
                                    }}
                                    onChange={(e) => {
                                        setProviderState((prevState) => ({
                                            ...prevState,
                                            isBoth: e.target.value,
                                        }));
                                    }}>
                                    <ToggleButton
                                        size="medium"
                                        style={{
                                            width: '60px',
                                        }}
                                        value="0">
                                        OR
                                    </ToggleButton>
                                    <ToggleButton
                                        disabled={
                                            !emails.length > 0 ||
                                            !tenants.length > 0
                                        }
                                        size="medium"
                                        style={{
                                            width: '60px',
                                            // backgroundColor:
                                            //     (!emails?.length > 0 ||
                                            //     !tenants?.length > 0)
                                            //         ? "#6c757d"
                                            //         : null,
                                        }}
                                        value="1">
                                        AND
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {providerState?.isBoth && (
                                    <FormHelperText fontWeight={'bold'}>
                                        You have selected{' '}
                                        {providerState?.isBoth == 0
                                            ? 'OR'
                                            : 'AND'}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                isRequired={providerState?.isBoth == 1}
                                mb={2}
                                isInvalid={emailError.isError}>
                                <FormLabel>Email Domains</FormLabel>
                                <ChipList
                                    inputs={emails}
                                    onCloseClick={handleEmailCloseClick}
                                />
                                <ChipInput
                                    data-walkthroughid={ufEmailInput}
                                    placeholder="Enter your Email Domains"
                                    onPaste={handleEmailPaste}
                                    onKeyDown={handleEmailKeyDown}
                                    onChange={handleEmailChange}
                                    value={emailInput.trim()}
                                />
                                {!emailError.isError ? (
                                    <FormHelperText>
                                        eg. yourdomain.com
                                    </FormHelperText>
                                ) : emailError ? (
                                    <FormErrorMessage>
                                        {emailError.message}
                                    </FormErrorMessage>
                                ) : (
                                    <FormErrorMessage>
                                        {emailError.duplicates}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Box
                        style={{
                            border: 'none',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}>
                        <ChakraButton
                            data-walkthroughid={ufBackBtn}
                            variant="outline"
                            className="btn btn-secondary btn-sm disabled:cursor-not-allowed"
                            onClick={() => {
                                setProviderState(initialProviderState);
                                setTenants([]);
                                setEmails([]);
                                setEmailError({
                                    isError: false,
                                    message: '',
                                    duplicates: '',
                                });
                                setTenantError({
                                    isError: false,
                                    message: '',
                                    duplicates: '',
                                });
                                onClose();
                            }}>
                            Close
                        </ChakraButton>
                    </Box>
                    <Box
                        style={{
                            border: 'none',
                            textAlign: 'center',
                            justifyContent: 'center',
                            marginLeft: 10,
                        }}>
                        <ChakraButton
                            data-walkthroughid={ufSubmitBtn}
                            type="submit"
                            isLoading={isLoading}
                            isDisabled={isDisabled}
                            className="btn-sm disabled:cursor-not-allowed"
                            onClick={() => handleAddProvider()}>
                            Submit
                        </ChakraButton>
                    </Box>
                </ModalFooter>
            </ModalContent>

            <TenantLookup
                isOpenLookup={isOpenLookup}
                tenants={tenants}
                addTenants={addTenants}
                setTenantError={setTenantError}
                onCloseLookup={onCloseLookup}
            />
        </Modal>
    );
}
