import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FunctionsRoundedIcon from '@material-ui/icons/FunctionsRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import { Chip, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import { CircularProgress } from '@material-ui/core';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useNavigate } from 'react-router-dom';
import {
    ChakraProvider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import TableAndModal, {
    modalTitle,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';
import { axios } from '../../../services/axios';
import { isNumeric, renderTelephoneNumber } from '../../../utils/utils';
// import { TabPanel } from '../../../components';
import { CustomFieldsForm } from '../../organisations';
import { AutoAttendant, AutoAttendantImage, Troubleshoot } from '../index';
import General from './edit/General';
import { Alert, AlertTitle } from '@mui/material';

import getAppAuthByCompanyId from '@/API/AppAuth/getAppAuthByCompanyId';
import getAppAuthById from '@/API/AppAuth/getAppAuthById';
import walkthroughIds from './walkthroughIds';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { customTabStyles, selectedTabStyles } from '@/constants';
import AdminRA from './edit/AdminRA';
// row validation format
const validate = (rowData, field) => {
    if (rowData.isLicensed) {
        return rowData[field];
    }
    return null;
};

// determine if a row is lisenced
export const licensed = (state) => {
    if (state?.data?.length > 0) {
        return state.data.filter((rowData) => {
            return rowData.isLicensed === true;
        }).length;
    }
    return 0;
};

//  check is a row has a telephone number
export const numAssigned = (state) => {
    if (state?.data?.length > 0) {
        return state.data.filter((rowData) => {
            //checks for strings that are parse-able to numbers
            return isNumeric(rowData.telephoneNumber);
        }).length;
    }
    return 0;
};

// check the total active users of a row
export const totalActiveUsers = (state) => {
    if (state?.data?.length > 0) {
        return state.data.filter((rowData) => {
            return rowData.isActive === true;
        }).length;
    }
    return 0;
};

/*
 * User and Resrouce Accounts Component from Sidebar
 */
const Users = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tableRef = useRef();
    const [syncState, setSyncState] = useState('');
    const [viewGuide, setViewGuide] = useState('');
    const [hasNoUsers, setHasNoUsers] = useState(false);

    const { pathname } = useLocation();
    // resource account has additional tab to convert RA to user account
    const tabList = pathname?.includes('/resource-accounts')
        ? ['General', 'Custom', 'Admin']
        : ['General', 'Custom'];

    const { ufSyncBtn, ufBuilderBtn, ufCsvUpdateBtn, ufHelperBtn, tabs } =
        walkthroughIds;

    useEffect(() => {
        setHasNoUsers(false);
    }, [pathname]);

    const { requiredScope } = useSelector((state) => state.login);
    const { theme } = useDeepMerge();

    // all modal states
    const { modalData, modalState, modalMode } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });
    // the tab view from state
    const { modalTab } = useSelector((state) => {
        return { ...state.view };
    });

    // table state
    const {
        indexing,
        state: tableState,
        loading: tableLoading,
        error,
    } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // current company data from state
    const { currentCompany, currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    //  use Effect to index the table
    useEffect(() => {
        if (indexing) {
            dispatch({
                type: 'CHANGE_TABLE_INDEX',
                payload: [
                    ...new Set(
                        tableState.data.map((v) => {
                            if (
                                v.displayName[0].toUpperCase() < 'A' ||
                                v.displayName[0].toUpperCase() > 'Z'
                            ) {
                                return '#';
                            }
                            return v.displayName[0].toUpperCase();
                        }),
                    ),
                ].sort((a, b) => {
                    return a.localeCompare(b);
                }),
            });
            dispatch({
                type: 'CHANGE_TABLE_ORIGINAL_DATA',
                payload: tableState.data,
            });
        }
    }, [indexing]);

    const modalTitleUser = () => {
        if (modalState && pathname?.includes('/users')) {
            const name = modalTitle(
                modalData,
                'displayName',
                ' User ',
                modalMode,
            );
            return name;
        }
        if (modalMode === 'Add') {
            return 'Builder';
        }
        if (modalState && pathname?.includes('/resource-accounts')) {
            const raName = modalTitle(
                modalData,
                'displayName',
                ' Resource Account ',
                modalMode,
            );
            return raName;
        }
        return '';
    };
    // function to determin what modal content to show based on the modal mode
    const modalContent = () => {
        switch (modalMode) {
            case 'Add':
                return <AutoAttendant />;
            default:
                return (
                    <ChakraProvider resetCSS theme={theme}>
                        <Tabs>
                            <TabList justifyContent="center" borderBottom={0}>
                                {tabList.map((tab, index) => (
                                    <Tab
                                        sx={customTabStyles}
                                        key={tab}
                                        _selected={selectedTabStyles}
                                    >
                                        {tab}
                                    </Tab>
                                ))}
                            </TabList>
                            <TabPanels>
                                <TabPanel
                                    data-walkthroughid={tabs.ufGeneralTab}
                                >
                                    <General />
                                </TabPanel>
                                <TabPanel data-walkthroughid={tabs.ufCustomTab}>
                                    <CustomFieldsForm />
                                </TabPanel>
                                <TabPanel data-walkthroughid={tabs.ufAdminTab}>
                                    <AdminRA RaID={modalData?.id} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        {/* <Tabs
              value={modalTab}
              onChange={(e, i) => {
                dispatch({
                  type: 'CHANGE_MODAL_TAB_VIEW',
                  payload: i,
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
              aria-label="full width tabs example"
            >
              <Tab
                label="General"
                data-walkthroughid={tabs.ufGeneralTab}
              />
              <Tab
                label="Custom"
                data-walkthroughid={tabs.ufCustomTab}
              />
            </Tabs>
            <TabPanel value={modalTab} index={0}>
              <General />
            </TabPanel>
            <TabPanel value={modalTab} index={1}>
              <CustomFieldsForm />
            </TabPanel> */}
                    </ChakraProvider>
                );
        }
    };

    // function to determine the modal footer
    const modalFooter = () => {
        switch (modalMode) {
            case 'Add':
                return <AutoAttendantImage />;
            default:
                return null;
        }
    };

    // default columns for the table
    const defaultColumns = [
        {
            title: 'Display Name',
            field: 'displayName',
            defaultSort: 'asc',
        },
        {
            title: 'Active',
            field: 'isActive',
            width: '5%',
            type: 'boolean',
        },
        {
            title: 'Status',
            field: 'status',
            width: '5%',
            lookup: {
                // OK
                0: (
                    <Tooltip title="OK">
                        <FiberManualRecordRoundedIcon
                            style={{ color: 'green', fontSize: 'small' }}
                        />
                    </Tooltip>
                ),
                //UPDATING
                4: (
                    <Tooltip title="Updating">
                        <FiberManualRecordRoundedIcon
                            style={{ color: '#c1c92a', fontSize: 'small' }}
                        />
                    </Tooltip>
                ),
                //SYNC ERROR
                20: (
                    <Tooltip title="Syncing Error">
                        <FiberManualRecordRoundedIcon
                            style={{ color: 'red', fontSize: 'small' }}
                        />
                    </Tooltip>
                ),
            },
            lookupDescription: {
                0: 'OK',
                4: 'Updating',
                20: 'Syncing Error',
            },
        },

        {
            title: 'UPN',
            field: 'o365UserPrincipalName',
        },
        {
            title: 'Telephone Number',
            field: 'telephoneNumber',
            render: (rowData) =>
                renderTelephoneNumber(rowData, 'telephoneNumber'),
        },
        {
            title: 'Dial Plan',
            field: 'dialPlan',
            render: (rowData) => validate(rowData, 'dialPlan'),
            hidden: !pathname?.includes('/users'),
        },
        {
            title: 'Voice Policy',
            field: 'voicePolicy',
            render: (rowData) => {
                if (rowData.isLicensed) {
                    return rowData['voicePolicy'];
                }
                return <NotInterestedRoundedIcon fontSize="small" />;
            },
        },
        {
            title: 'Usage Location',
            field: 'usageLocation',
        },
        {
            title: 'Managed By',
            field: 'managedBy',
            lookup: config.users.managedByJSON,
        },
        {
            title: 'Plan',
            field: 'plan',
            hiddenByColumnsButton: true,
            hidden: true,
        },
        ...config.table.custom,
    ];

    // component for what the sync button does based on the state of it
    const syncButton = () => {
        switch (syncState) {
            case 'succeed':
                return {
                    color: 'outline-warning',
                    text: (
                        <>
                            <HourglassEmptyRoundedIcon fontSize="small" /> Sync
                            Pending
                        </>
                    ),
                };
            case 'error':
                return {
                    color: 'outline-danger',
                    text: (
                        <>
                            <ErrorOutlineRoundedIcon fontSize="small" /> Sync
                            Failed
                        </>
                    ),
                };
            case '':
                return {
                    color: 'outline-primary',
                    text: (
                        <>
                            <SyncRoundedIcon fontSize="small" /> Sync Users
                        </>
                    ),
                };
            case 'loading':
                return {
                    color: 'outline-primary',
                    text: (
                        <>
                            <CircularProgress
                                style={{ width: 15, height: 15 }}
                            />
                            Syncing
                        </>
                    ),
                };
            default:
                return { color: 'outline-primary', text: <> </> };
        }
    };

    // swtich case for troubleshooting, if its not trouble shooting return a table and modal
    switch (viewGuide) {
        default:
            return (
                <>
                    <Troubleshoot
                        show={viewGuide === 'Troubleshoot'}
                        handleClose={() => {
                            setViewGuide('');
                        }}
                    />
                    {hasNoUsers && (
                        <Alert
                            severity="info"
                            variant="filled"
                            style={{
                                // margin: '15px 8px 15px 24px',
                                margin: '15px 0',
                            }}
                        >
                            <AlertTitle style={{ margin: 0 }}>
                                No services syncing users
                            </AlertTitle>
                        </Alert>
                    )}
                    {tableLoading ? (
                        <>
                            <div style={{ padding: 15, display: 'inline' }}>
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    icon={<Skeleton variant="circle" />}
                                    label={'Licensed: ? Users'}
                                />
                            </div>
                            <div style={{ padding: 15, display: 'inline' }}>
                                <Chip
                                    icon={<Skeleton variant="circle" />}
                                    variant="outlined"
                                    size="small"
                                    label={'Numbers assigned: ? Users'}
                                />
                            </div>
                            <div style={{ padding: 15, display: 'inline' }}>
                                <Chip
                                    icon={<Skeleton variant="circle" />}
                                    variant="outlined"
                                    size="small"
                                    label={'Total: ? Users'}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {error ? null : (
                                <>
                                    <div
                                        style={{
                                            padding: 15,
                                            display: 'inline',
                                        }}
                                    >
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label={
                                                'Licensed: ' +
                                                licensed(tableState) +
                                                ' Users'
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            padding: 15,
                                            display: 'inline',
                                        }}
                                    >
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label={
                                                'Numbers assigned: ' +
                                                numAssigned(tableState) +
                                                ' Users'
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            padding: 15,
                                            display: 'inline',
                                        }}
                                    >
                                        <Chip
                                            icon={<FunctionsRoundedIcon />}
                                            variant="outlined"
                                            size="small"
                                            label={
                                                totalActiveUsers(tableState) +
                                                ' Users'
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <TableAndModal
                        tableRef={tableRef}
                        tableFetch={
                            pathname?.includes('/users')
                                ? config.users.tableFetch + currentCompany
                                : '/resourceaccounts/' + currentCompany
                        }
                        modalFetch={
                            pathname?.includes('/users')
                                ? config.users.modalFetch + modalData?.id
                                : '/resourceaccount/' + modalData?.id
                        }
                        onModalSucceed={
                            modalMode === 'Add' &&
                            function () {
                                dispatch({
                                    type: 'CHANGE_CURRENT_SCOPE',
                                    payload: 20,
                                });
                                navigate('/jobs');
                            }
                        }
                        modalUpdate={
                            modalData?.managedBy !== 0
                                ? null
                                : pathname?.includes('/users')
                                  ? '/user/'
                                  : '/resourceaccount/'
                        }
                        modalAdd={'/resourceaccount/aa'}
                        modalUpload={(freshData, state) => {
                            return {
                                ...freshData,
                                ...state,
                            };
                        }}
                        onModalUpdate={
                            modalData?.managedBy !== 0 &&
                            function () {
                                if (
                                    modalData?.managedBy !== 0 &&
                                    modalState?.managedBy === 0
                                ) {
                                    return Promise.all([
                                        axios.put(
                                            `${
                                                pathname?.includes('/users')
                                                    ? '/user/'
                                                    : '/resourceaccount/'
                                            }`,
                                            { ...modalState },
                                        ),
                                        axios.put(
                                            `${
                                                pathname?.includes('/users')
                                                    ? '/user/'
                                                    : '/ResourceAccount/'
                                            }${modalData?.id}/Attributes`,
                                            { ...modalState },
                                        ),
                                    ]);
                                }
                                return axios.put(
                                    `${
                                        pathname?.includes('/users')
                                            ? '/user/'
                                            : '/resourceaccount/'
                                    }${modalData?.id}/Attributes`,
                                    { ...modalState },
                                );
                            }
                        }
                        rowActions={{
                            conditionalEditable: {
                                disabled: (rowData) =>
                                    rowData.status !== 0 &&
                                    rowData.status !== 20,
                            },
                        }}
                        modalValidate={() => {
                            var newErrorState = null;
                            switch (modalMode) {
                                case 'Add':
                                    newErrorState = {
                                        displayName:
                                            (!modalState?.displayName ||
                                                modalState?.displayName
                                                    ?.length < 1) &&
                                            'Field is required.',
                                        timezone:
                                            (!modalState?.timezone ||
                                                modalState?.timezone?.length <
                                                    1) &&
                                            'Field is required.',
                                        language:
                                            (!modalState?.language ||
                                                modalState?.language?.length <
                                                    1) &&
                                            'Field is required.',
                                        name:
                                            !(
                                                modalState?.name &&
                                                modalState?.name?.length > 0 &&
                                                /^[0-9a-zA-Z-_.]+$/.test(
                                                    modalState?.name,
                                                )
                                            ) &&
                                            'Field is required and can only contain  upper and lower case letters, numbers, periods, underscores, or hyphens.',
                                    };
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE_ERROR',
                                        payload: newErrorState,
                                    });
                                    return !(
                                        newErrorState.displayName ||
                                        newErrorState.timezone ||
                                        newErrorState.language ||
                                        newErrorState.name
                                    );
                                default:
                                    // Base fields to validate no matter what
                                    newErrorState = {
                                        attribute1:
                                            modalState?.attribute1?.length >
                                                100 &&
                                            'Maximum field length is 100 characters. ',
                                        attribute2:
                                            modalState?.attribute2?.length >
                                                100 &&
                                            'Maximum field length is 100 characters. ',
                                        attribute3:
                                            modalState?.attribute3?.length >
                                                100 &&
                                            'Maximum field length is 100 characters. ',
                                    };

                                    // Add DR validation if the number is DR
                                    if (
                                        modalState.isDrNumber &&
                                        pathname.includes('/users')
                                    ) {
                                        newErrorState = {
                                            ...newErrorState,
                                            ...{
                                                voicePolicyID:
                                                    !modalState?.voicePolicyID &&
                                                    'Field is required.',
                                                dialPlanID:
                                                    !modalState?.dialPlanID &&
                                                    'Field is required.',
                                            },
                                        };
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE_ERROR',
                                            payload: newErrorState,
                                        });

                                        return !(
                                            newErrorState.attribute1 ||
                                            newErrorState.attribute2 ||
                                            newErrorState.attribute3 ||
                                            newErrorState.dialPlanID ||
                                            newErrorState.voicePolicyID
                                        );
                                    }

                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE_ERROR',
                                        payload: newErrorState,
                                    });
                                    return !(
                                        newErrorState.attribute1 ||
                                        newErrorState.attribute2 ||
                                        newErrorState.attribute3
                                    );
                            }
                        }}
                        title={
                            pathname?.includes('/users')
                                ? config.users.tableTitle
                                : 'Resource Accounts'
                        }
                        defaultColumns={defaultColumns}
                        groupable
                        alphabetFilterField={'displayName'}
                        filterable
                        maxBodyHeight={
                            window.innerHeight * config.plainView.tableHeight
                        }
                        onTableSucceed={(data) => {
                            if (!data.length) {
                                // no app auth auth with user sync = true
                                // check all auths, if any have userSync, were good
                                getAppAuthByCompanyId(currentCompany).then(
                                    (res) => {
                                        let hasMcAuthType = false;
                                        let mcAuthTypeId = '';

                                        res.data.map((companyAuth) => {
                                            if (
                                                companyAuth.appAuthTypeID ===
                                                'Microsoft'
                                            ) {
                                                hasMcAuthType = true;
                                                mcAuthTypeId = companyAuth.id;
                                            }
                                        });

                                        if (hasMcAuthType) {
                                            let hasUserSynced = false;
                                            getAppAuthById(mcAuthTypeId).then(
                                                (response) => {
                                                    response.data.map(
                                                        (auth) => {
                                                            if (auth.userSync) {
                                                                hasUserSynced = true;
                                                            }
                                                        },
                                                    );
                                                },
                                            );

                                            if (hasUserSynced) {
                                                setHasNoUsers(true);
                                            }
                                        }
                                    },
                                );
                            }
                            return data.map(
                                ({
                                    telephoneNumber,
                                    voicePolicy,
                                    dialPlan,
                                    plan,
                                    isLicensed,
                                    ...rest
                                }) => {
                                    return {
                                        telephoneNumber: isLicensed
                                            ? telephoneNumber?.length > 0
                                                ? telephoneNumber
                                                : config.notAllocated.table
                                            : telephoneNumber,
                                        voicePolicy: isLicensed
                                            ? voicePolicy?.length > 0
                                                ? voicePolicy
                                                : config.notAllocated.table
                                            : '',
                                        dialPlan: isLicensed
                                            ? dialPlan?.length > 0
                                                ? dialPlan
                                                : config.notAllocated.table
                                            : '',
                                        plan: plan ?? 'Use Default',
                                        isLicensed,
                                        ...rest,
                                    };
                                },
                            );
                        }}
                        modalTitle={modalTitleUser()}
                        titleProps={
                            <>
                                {pathname?.includes('/users') && (
                                    <Button
                                        data-walkthroughid={ufCsvUpdateBtn}
                                        className="btn  btn-sm"
                                        variant="primary"
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            navigate('/csvusers');
                                        }}
                                    >
                                        {'CSV Update'}
                                    </Button>
                                )}
                                {(currentCompanyData?.status === 0 ||
                                    (currentCompanyData?.status > 11 &&
                                        currentCompanyData?.status < 20)) && (
                                    <Button
                                        className="btn btn-sm"
                                        data-walkthroughid={ufSyncBtn}
                                        variant={syncButton().color}
                                        onClick={() => {
                                            if (syncState === '') {
                                                setSyncState('loading');
                                                axios
                                                    .get(
                                                        '/notify/company/' +
                                                            currentCompany,
                                                    )
                                                    .then((res) => {
                                                        setSyncState('succeed');
                                                    })
                                                    .catch((e) => {
                                                        setSyncState('error');
                                                    });
                                            }
                                        }}
                                    >
                                        {syncButton().text}
                                    </Button>
                                )}

                                {!pathname?.includes('/users') && (
                                    <>
                                        <Button
                                            variant="primary"
                                            style={{ marginLeft: 10 }}
                                            size="sm"
                                            onClick={() => {
                                                dispatch({
                                                    type: 'OPEN_MODAL',
                                                    payload: {
                                                        state: {
                                                            companyID:
                                                                currentCompany,
                                                            template: 0,
                                                        },
                                                        loading: false,
                                                        mode: 'Add',
                                                    },
                                                });
                                            }}
                                            data-walkthroughid={ufBuilderBtn}
                                        >
                                            <AddRoundedIcon
                                                fontSize="small"
                                                style={{
                                                    marginBottom: '2%',
                                                    fontSize: 15,
                                                }}
                                            />
                                            Builder
                                        </Button>
                                    </>
                                )}
                                <Button
                                    variant="primary"
                                    className="w-8 rounded-full"
                                    onClick={() => setViewGuide('Troubleshoot')}
                                    style={{ marginLeft: 10 }}
                                    size="sm"
                                    data-walkthroughid={ufHelperBtn}
                                >
                                    ?
                                </Button>
                            </>
                        }
                        newPage
                        modalContent={modalContent()}
                        modalFooter={modalFooter()}
                    />
                </>
            );
    }
};

export default Users;
