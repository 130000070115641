import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { FormHelperText, InputLabel } from '@material-ui/core';

import { TextEditor } from '../../../../components';
import walkthroughIds from '../walkthroughIds';

//headers form when webhook is selected in edit triggers
const ActionHeaders = ({ setCursorPosition }) => {
    const dispatch = useDispatch();

    // modal State
    const { state: modalState } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });
    const { editMode } = walkthroughIds;

    // get the action headers from the state
    const getTableState = () => {
        return modalState?.actionHeaders;
    };

    // header rendering
    return (
        <>
            <InputLabel> Headers </InputLabel>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th className="bootstrapTableRow heading">Key</th>
                        <th className="bootstrapTableRow heading">Value</th>
                        <th className="bootstrapTableRow heading"></th>
                    </tr>
                </thead>
                <tbody>
                    {modalState?.actionHeaders
                        ?.sort((left, right) => {
                            return left?.id - right?.id; //assume index is sorted by (key ASC)
                        })
                        .map(({ key, value, id }, index) => (
                            <tr key={id}>
                                <td className="bootstrapTableRow">
                                    <TextEditor
                                        id={`webHookHeaderKey-${id}`}
                                        field="key"
                                        setCursorPosition={setCursorPosition}
                                        textfield={{
                                            value: key,
                                            defaultValue: key,
                                            rows: false,
                                            rowsMax: false,
                                            multiline: false,
                                            onChange: (e) => {
                                                const newPair = {
                                                    key: e.target.value,
                                                    id,
                                                    value,
                                                };
                                                var newTable = [
                                                    ...getTableState(),
                                                ];
                                                newTable.splice(
                                                    index,
                                                    1,
                                                    newPair,
                                                );
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        actionHeaders: newTable,
                                                    },
                                                });
                                            },
                                            onFocus: (e) => {
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        activeRowID: id,
                                                        activeRowField: 'key',
                                                        value,
                                                        activeRowIndex: index,
                                                    },
                                                });
                                                if (
                                                    index ===
                                                    getTableState().length - 1
                                                ) {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            actionHeaders: [
                                                                ...getTableState(),
                                                                {
                                                                    id:
                                                                        Number(
                                                                            id,
                                                                        ) + 1,
                                                                    key: '',
                                                                    value: '',
                                                                },
                                                            ],
                                                        },
                                                    });
                                                }
                                            },
                                        }}
                                        dataWalkthroughId={
                                            index === 0
                                                ? editMode.ufHeadersKeyInput
                                                : null
                                        }
                                    />
                                </td>
                                <td className="bootstrapTableRow">
                                    <TextEditor
                                        id={`webHookHeaderValue-${id}`}
                                        field="value"
                                        setCursorPosition={setCursorPosition}
                                        textfield={{
                                            value: value,
                                            defaultValue: value,
                                            rows: false,
                                            rowsMax: false,
                                            multiline: false,
                                            onChange: (e) => {
                                                const newPair = {
                                                    value: e.target.value,
                                                    id,
                                                    key,
                                                };
                                                var newTable = [
                                                    ...getTableState(),
                                                ];
                                                newTable.splice(
                                                    index,
                                                    1,
                                                    newPair,
                                                );
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        actionHeaders: newTable,
                                                    },
                                                });
                                            },
                                            onFocus: (e) => {
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        activeRowID: id,
                                                        activeRowField: 'value',
                                                        activeRowIndex: index,
                                                        key,
                                                    },
                                                });
                                                if (
                                                    index ===
                                                    getTableState().length - 1
                                                ) {
                                                    dispatch({
                                                        type: 'CHANGE_MODAL_STATE',
                                                        payload: {
                                                            actionHeaders: [
                                                                ...getTableState(),
                                                                {
                                                                    id:
                                                                        Number(
                                                                            id,
                                                                        ) + 1,
                                                                    key: '',
                                                                    value: '',
                                                                },
                                                            ],
                                                        },
                                                    });
                                                }
                                            },
                                        }}
                                        dataWalkthroughId={
                                            index === 0
                                                ? editMode.ufHeadersValueInput
                                                : null
                                        }
                                    />
                                </td>
                                <td className="bootstrapTableRow">
                                    {getTableState().length > 1 && (
                                        <IconButton
                                            onClick={() => {
                                                var newTable = [
                                                    ...getTableState(),
                                                ];
                                                newTable.splice(index, 1);
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        actionHeaders: newTable,
                                                    },
                                                });
                                            }}
                                            data-walkthroughid={
                                                editMode.ufClearHeadersBtn
                                            }>
                                            <ClearRoundedIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <FormHelperText>
                The body will be submitted with header: "Content-Type:
                application/json".
            </FormHelperText>
        </>
    );
};

export default ActionHeaders;
