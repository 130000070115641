import React from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';

/*
 * component to display a error message if it exists
 * else display a generic error
 */
const Error = (props) => {
    const {error, style} = props;

    return (
        <Alert severity="error" style={style}>
            <AlertTitle>
                {error?.name || error?.response?.status || 'Error'}
            </AlertTitle>
            {error?.response?.data?.message || error?.message || (
                <>
                    Sorry we are having difficulties processing your request at
                    this time. <br />
                    Please try again later. <br />
                    If the problem persists please contact your administrator.
                </>
            )}
        </Alert>
    );
};

export default Error;
