import { axios } from '@/services/axios';
import { Box, HStack, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Heading,
} from 'components/v4';
import { Container } from 'components/v4/Container/Container';
import { isEmpty } from 'lodash';
import FooterContainer from 'pages/services/serviceprofiles/edit/FooterContainer';
import { useDefinedAttributes } from 'pages/services/serviceprofiles/vendorDefined/DefinedAttributesContext';
import DefinedAttributes from 'pages/services/serviceprofiles/vendorDefined/attributes/definedAttributes';
import useDefinedAttributeValidation from 'pages/services/serviceprofiles/vendorDefined/attributes/useDefinedAttributeValidation';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { classNames } from 'utils/classNames';

// WORK IN PROGRESS
// COME BACK HERE ONCE SERVICE PROFILE CARD UI IS DISPLAYED ON DASHBOARD SERVICES
export default function EditVendorDefined({}) {
    const { id: serviceId } = useParams();
    const { requiredScope } = useSelector((state) => state.login);
    const navigate = useNavigate();

    const { darkMode } = useSelector((state) => state.settings);

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleBackButton = () => navigate('/dashboard-services');

    const { dispatch, attributesList, attributesErrors } =
        useDefinedAttributes();

    const { handleAttributeError, removeAttributeError } =
        useDefinedAttributeValidation();

    // get company service
    const { data: serviceData, isFetching: serviceLoading } = useQuery({
        queryKey: ['service', serviceId],
        queryFn: async () => {
            const res = await axios.get(`service/${serviceId}`);
            return res.data;
        },
        enabled: Boolean(serviceId),
        onSuccess: (data) => {
            // attributes list data
            dispatch({
                type: 'UPDATE_ATT_LIST',
                payload: data.attributeList,
            });
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
            handleBackButton();
        },
        refetchOnWindowFocus: false,
    });

    const handleSubmit = async (e) => {
        setError(null);
        let hasError = false;

        // check for any attributes errors
        if (attributesErrors.length > 0) {
            hasError = true;
        }

        // check for required attributes
        if (attributesList?.length > 0) {
            attributesList.forEach((att, index) => {
                const validation = JSON.parse(
                    att.definedAttributeValidationType,
                );

                // if required, input cannot be empty
                if (validation.Required && isEmpty(att.value)) {
                    handleAttributeError(index, 'Field is required.');
                    hasError = true;
                } else {
                    removeAttributeError(index);
                }
            });
        }
        if (hasError) return;

        setIsLoading(true);
        try {
            await axios.put(`service/vendordefined`, {
                id: serviceData.id,
                name: serviceData.name,
                serviceDefinitionID: serviceData.serviceDefinitionID,
                attributeList: attributesList,
            });

            toast.success(`Successfully updated a Vendor Defined Service!`);
            // navigate to dashboard services
            handleBackButton();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Heading fontSize="3xl" as="h2" mb={'10px'}>
                <Box display={'flex'} flexDirection={'row'}>
                    Editing
                    {serviceLoading ? (
                        <Skeleton mt={1} w={200} h={8} ml={1} />
                    ) : (
                        <> {serviceData?.name || 'Vendor Defined'}</>
                    )}
                </Box>
            </Heading>

            {error && (
                <>
                    <Box sx={{ margin: '20px 0' }} />
                    <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>
                            {error?.response?.data?.message ||
                                'An error occured. please try again later.'}
                        </AlertDescription>
                    </Alert>
                </>
            )}

            {/* Defined attributes list */}

            <Box padding={'10px 0'}>
                {serviceLoading ? (
                    <Box>
                        {[...Array(3)].map((_, index) => (
                            <Stack key={index} mb={3}>
                                <HStack>
                                    <Skeleton h={5} w={'25%'} />
                                    <SkeletonCircle h={5} w={5} />
                                </HStack>

                                <Skeleton h={10} borderRadius={4} />
                                <Skeleton h={5} w={'15%'} />
                            </Stack>
                        ))}
                    </Box>
                ) : attributesList?.length > 0 ? (
                    <DefinedAttributes requiredScope={requiredScope} />
                ) : (
                    <Alert status="info">
                        <AlertIcon />
                        No information is required at this time!
                    </Alert>
                )}

                <FooterContainer>
                    <Button
                        variant="outline"
                        data-walkthroughid={'/service-profiles/button/close'}
                        w={{ base: '100%', md: '80px' }}
                        className={classNames(
                            darkMode ? 'bg-white hover:opacity-70' : null,
                        )}
                        onClick={() => handleBackButton()}>
                        Close
                    </Button>
                    <Button
                        ml="1rem"
                        isLoading={isLoading}
                        isDisabled={attributesList?.length == 0}
                        data-walkthroughid={'/service-profiles/button/update'}
                        onClick={() => {
                            handleSubmit();
                        }}
                        w={{ base: '100%', md: '80px' }}>
                        Update
                    </Button>
                </FooterContainer>
            </Box>
        </Container>
    );
}
