export function findObjectsWithSameNumber(arr) {
    const numberMap = {};
    const duplicates = [];

    arr?.forEach((obj) => {
        const { number } = obj;
        if (numberMap[number]) {
            duplicates.push(obj);
        } else {
            numberMap[number] = [obj];
        }
    });

    return duplicates;
}

export function calculateLastNumber(number, blockSize) {
    if (!number) return '';
    const lastNumber = Number(number) + Number(blockSize - 1);
    if (isNaN(lastNumber)) return null;
    return lastNumber.toString();
}

export function validateBlockSizeRanges(items) {
    for (let i = 0; i < items.length - 1; i++) {
        for (let j = i + 1; j < items.length; j++) {
            const itemA = items[i];
            const itemB = items[j];

            const rangeA = {
                start: parseInt(itemA.number),
                end: parseInt(itemA.number) + parseInt(itemA.blockSize) - 1,
            };
            const rangeB = {
                start: parseInt(itemB.number),
                end: parseInt(itemB.number) + parseInt(itemB.blockSize) - 1,
            };

            if (
                (rangeA.start <= rangeB.end && rangeA.end >= rangeB.start) ||
                (rangeB.start <= rangeA.end && rangeB.end >= rangeA.start)
            ) {
                return true;
            }
        }
    }
    return false;
}
