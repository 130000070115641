import { memo } from 'react';

import { useLayoutContext } from 'context/LayoutContext';

import { SidebarItem } from './index';

const SidebarItemList = memo(({ parentRoute, requiredScope, colorMode }) => {
    const { isMenuCollapse } = useLayoutContext();
    const filteredItemRoutes = parentRoute.children.filter(
        (route) => requiredScope >= route.scope,
    );
    return filteredItemRoutes.map((item, index) => {
        const props = {
            item,
            index,
            parentPath: parentRoute?.parentPath,
            isMenuCollapse,
            colorMode,
        };
        return <SidebarItem {...props} key={item.path} />;
    });
});

SidebarItemList.displayName = 'SidebarItemList';
export default SidebarItemList;
