import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

import Error from '../maintenance/Error';
import {axios} from '../../services/axios';

//Company auth microsoft 365 link
const CompanyAuth = ({viewCurrentCompany, onClick}) => {
    const dispatch = useDispatch();
    const {script} = useSelector((state) => {
        return {script: {...state.script}};
    });
    const {currentCompany} = useSelector((state) => {
        return {...state.navigationLists};
    });

    useEffect(() => {
        viewCurrentCompany &&
            dispatch({type: 'CHANGE_SCRIPT_LOADING', payload: true});
    }, [currentCompany]);

    useEffect(() => {
        if (script?.loading) {
            axios
                .get(
                    viewCurrentCompany
                        ? '/company/' +
                              currentCompany +
                              '/msloginlink?domain=' +
                              window.location.href
                        : '/company/' +
                              script?.rowData?.id +
                              '/msloginlink?domain=' +
                              window.location.href,
                )
                .then((res) => {
                    window.location.href = res.data;
                    dispatch({type: 'CHANGE_SCRIPT_LOADING', payload: false});
                })
                .catch((e) => {
                    dispatch({type: 'CHANGE_SCRIPT_ERROR', payload: e});
                });
        }
    }, [script?.loading, currentCompany, script?.rowData]);
    if (script?.error) {
        return (
            <>
                <Button variant="secondary" onClick={onClick}>
                    Back
                </Button>
                <br />
                <Error style={{marginTop: 10}} error={script?.error} />
            </>
        );
    }
    return <CircularProgress style={{marginTop: '25%', marginLeft: '50%'}} />;
};

export default CompanyAuth;
