import React, { useRef } from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    IconButton,
} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import TableAndModal, {
    modalTitle,
} from '../../../components/tables/TableAndModal';
import config from '../../../config.json';

/**
 * Special Numbers page of the GA tab in TCAP
 * Handles CRUD
 */
const SpecialNumbers = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // default columsn for table and modal
    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
        },
        {
            title: 'Pattern',
            field: 'pattern',
        },
        {
            title: 'Active',
            field: 'isActive',
            type: 'boolean',
            width: '5%',
        },
    ];

    // returns the table and modal with appropriate end points
    return (
        <TableAndModal
            tableRef={tableRef}
            tableFetch={'/specialnumbers/'}
            modalFetch={'/specialnumber/' + modalData?.id}
            modalAdd="/specialnumber/"
            modalUpdate="/specialnumber/"
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            title="Special Numbers"
            defaultColumns={defaultColumns}
            add
            groupable
            rowActions={{
                editable: true,
            }}
            filterable
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            modalTitle={modalTitle(
                modalData,
                'name',
                ' Special Number ',
                modalMode,
            )}
            modalValidate={() => {
                const newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                    pattern:
                        (!modalState?.pattern ||
                            modalState?.pattern?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !(newErrorState.name || newErrorState.pattern);
            }}
            modalInitState={{
                isActive: true,
            }}
            modalContent={
                <>
                    <Row style={{minHeight: 60}}>
                        <Col>
                            <TextField
                                fullWidth
                                label="Name"
                                type="text"
                                value={modalState?.name}
                                error={Boolean(modalStateError?.name)}
                                helperText={modalStateError?.name}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            name: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                label="Pattern"
                                type="text"
                                value={modalState?.pattern}
                                error={Boolean(modalStateError?.pattern)}
                                helperText={modalStateError?.pattern}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => {
                                                window.alert(
                                                    'pattern example:\n(Using Perl Regular Expression)\n^ - Start of line\n$ - End of line\n[] - list of possibilities\n() - for grouping\n{[min],[max]} - length (quantity) range\n^61[234][0-9]{8}$ = matches a number that start with 612, 613, 614, and is followed by 8 additional numbers exactly',
                                                );
                                            }}>
                                            <HelpRoundedIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            pattern: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>
                        <Col>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={modalState?.isActive}
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    isActive:
                                                        !modalState?.isActive,
                                                },
                                            });
                                        }}
                                    />
                                }
                                label="Active"
                            />{' '}
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default SpecialNumbers;
