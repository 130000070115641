const lightTheme = {
    '--light-scrollbar-track': '#fafafa',
    '--light-scrollbar-thumb': '#c7c7c7',
    '--light-scrollbar-thumb-hover': '#a6a6a6',
};

const darkTheme = {
    '--dark-scrollbar-track': '#2c2c2c',
    '--dark-scrollbar-thumb': '#6b6b6b',
    '--dark-scrollbar-thumb-hover': '#515151',
};

export { lightTheme, darkTheme };
