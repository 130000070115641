import {Text} from '@chakra-ui/react';

export default function FieldValidation({errors = [], ...props}) {
    if (!errors) return null;
    return errors.map((err) => (
        <Text key={err} color="red" {...props}>
            {err}
        </Text>
    ));
}
