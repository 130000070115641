import { EditIcon } from '@chakra-ui/icons';
import { Box, Icon, useBoolean } from '@chakra-ui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { MdPhonelinkRing, MdDialpad } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MenuItem, Text } from 'components/v4';
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import DashboardServiceCardStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardStatusbar';
import { ServiceDashboardItemLastHealthCheck } from '../DirectRouting';

import { useServicesDashboard } from 'context/ServicesDashboardContext';

// Util
import { dateStringToDate } from 'utils/utils';

import { useServiceDashboard } from 'hooks/useCustomQuery/useServiceDashboard';
import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';

import path from '@/routes/constants';
import walkthroughIds from 'pages/services/walkthroughIds';
import { OPERATOR_CONNECT } from '../constants';
import TpmStatus from './TpmStatus';
import ManageNumbers from './ManageNumbers';

export default function TPMDashboard({
    service,
    ocService,
    serviceWizard,
    companyData,
}) {
    const classes = classNames({
        flash: service.changes === 'added',
    });

    const isTrialExpired =
        companyData?.isTrial && Date.parse(companyData?.trialEnd) < Date.now();

    const tpmPath = `${path.NUMBERS_TPM}`;
    const navigate = useNavigate();
    const { handleNavigateLocation: navigateLocation } = useNavigateLocation();

    const serviceStatus = useServicesDashboard();
    const cardStatus = serviceStatus[OPERATOR_CONNECT];

    const [data, setData] = useState(service);
    const [hover, setHover] = useBoolean();
    const { requiredScope } = useSelector((state) => state.login);
    const { darkMode } = useSelector((state) => state.settings);

    const { ufTpmEditServiceButton, ufTpmManageAccountButton } =
        walkthroughIds.dashboardServices;

    const { data: serviceObj } = useServiceDashboard({
        serviceId: ocService.id,
        serviceType: OPERATOR_CONNECT,
        enabled: !!ocService.id,
    });

    const companyAppAuth = serviceWizard?.companyAppAuth.find((appAuth) =>
        ocService.appAuthType?.includes(appAuth.appAuthTypeID),
    );

    useEffect(() => {
        if (companyAppAuth) {
            //set MS Service Auth & Service Data.
            setData({
                ...serviceWizard.msServiceAuth,
                ...serviceObj,
                hasAppAuthData: true,
                lastProcessed:
                    serviceWizard.msServiceAuth?.lastProcessedTS &&
                    dateStringToDate(
                        serviceWizard.msServiceAuth?.lastProcessedTS,
                        'datetime',
                    ),
            });
        }
    }, [companyAppAuth, service, serviceObj, serviceWizard.msServiceAuth]);

    useEffect(() => {
        const alert = document.querySelector('.chakra-alert');

        if (!alert || !hover || !isTrialExpired) return;

        const classList = [
            'animate-pulse-slow',
            darkMode
                ? '!bg-[var(--chakra-colors-blue-900)]'
                : '!bg-[var(--chakra-colors-blue-200)]',
        ];
        alert.classList.add(...classList);

        return () => alert.classList.remove(...classList);
    }, [hover, isTrialExpired, darkMode]);

    return (
        <DashboardServiceCard
            className={classes}
            dataWalkthroughId={service.name}>
            <DashboardServiceCardStatusbar
                cardStatus={{
                    color: isTrialExpired ? 'red' : cardStatus?.color,
                    animation: false,
                }}
            />

            <DashboardServiceCardHeader>
                <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                    Teams Phone Mobile
                </Text>

                <DashboardServiceCardHeaderActionButton
                    dataWalkthroughId={service.serviceType}>
                    {requiredScope >= 40 && (
                        <MenuItem
                            icon={<EditIcon />}
                            onClick={() => {
                                navigate(`/services/tpm/${service.id}/edit`);
                            }}
                            data-walkthroughid={ufTpmEditServiceButton}>
                            <Text marginBottom={'0'}>Edit Service</Text>
                        </MenuItem>
                    )}
                    <MenuItem
                        icon={<MdDialpad />}
                        onClick={() => {
                            navigateLocation(
                                `/${tpmPath}/${service.id}/activation`,
                            );
                        }}
                        onPointerEnter={setHover.on}
                        onPointerLeave={setHover.off}
                        isDisabled={isTrialExpired}
                        data-walkthroughid={ufTpmManageAccountButton}>
                        <Text marginBottom={'0'}>Number Management</Text>
                    </MenuItem>
                </DashboardServiceCardHeaderActionButton>
            </DashboardServiceCardHeader>
            <DashboardServiceCardContent>
                <Box>
                    {/* // TODO: change this with TPM logo later */}
                    <Box className="flex items-center justify-center">
                        <Icon
                            className="mb-[15px]"
                            as={MdPhonelinkRing}
                            fontSize={50}
                            color={darkMode ? '#7c84ec' : '#4e57c0'}
                        />
                    </Box>

                    <Box className="flex flex-col">
                        {/* Status */}
                        <Box className="mb-[15px] flex justify-between">
                            <Text className="font-medium">Status</Text>
                            <TpmStatus
                                isTrialExpired={isTrialExpired}
                                cardStatus={cardStatus}
                            />
                        </Box>

                        {/* Manage Numbers */}
                        <ManageNumbers
                            cardStatus={cardStatus}
                            isTrialExpired={isTrialExpired}
                            darkMode={darkMode}
                            numbersPath={path.NUMBERS}
                            tpmId={service?.id}
                        />

                        {/* Last Health Check */}
                        <ServiceDashboardItemLastHealthCheck data={data} />
                    </Box>
                </Box>
            </DashboardServiceCardContent>
        </DashboardServiceCard>
    );
}
