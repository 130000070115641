import {Box} from '@chakra-ui/react';

const FooterContainer = ({children, ...props}) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            marginInline: 'auto',
            marginTop: '2rem'
        }}
        {...props}>
        {children}
    </Box>
);

export default FooterContainer;
