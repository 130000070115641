import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

export default function Reload() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const paths = pathname.split('/');
        if (paths.length >= 3) {
            navigate(`/${paths[2]}`);
        }
    }, [pathname]);

    return null;
}
