import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, IconButton, Link as ChakraLink, Tooltip } from '@chakra-ui/react';

import { useServicesDashboard } from 'context/ServicesDashboardContext';
import { classNames } from 'utils/classNames';
import { getTruncatedText, isTruncated } from '../Vendor/helper';

import Text from 'components/v4/Text';
import { IconComponent } from '../IconComponent';

export default function AppAuthStatus({ serviceObj, handleActions }) {
    const { AppAuth: cardStatus } = useServicesDashboard();
    const { handleResetServiceAdmin, handleGrantAccess } = handleActions;

    const handleClick =
        cardStatus?.actionIconTooltip === 'Reset Service Admin'
            ? handleResetServiceAdmin
            : cardStatus?.actionIconTooltip === 'Grant Access'
              ? handleGrantAccess
              : null;

    const { darkMode } = useSelector((state) => state.settings);

    if (!serviceObj) return <Text>...</Text>;
    if (cardStatus?.tooltipIcon) {
        return (
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                className="truncate">
                <Tooltip
                    hasArrow
                    label={getTruncatedText(cardStatus.statusLabel)}
                    placement="bottom-end">
                    <span
                        className={classNames(
                            isTruncated(cardStatus.statusLabel)
                                ? 'truncate'
                                : '',
                            'ml-20',
                            darkMode ? 'text-white' : 'text-black',
                        )}>
                        {cardStatus?.statusLabel}
                    </span>
                </Tooltip>

                <span className="mx-1 inline-flex flex-shrink-0 self-center">
                    <Tooltip
                        label={
                            cardStatus?.tooltip ||
                            cardStatus?.statusDisplay?.statusDisplayExtended
                        }
                        hasArrow
                        placement="bottom">
                        <IconButton
                            icon={
                                <IconComponent
                                    icon={cardStatus?.tooltipIcon}
                                    color={darkMode ? 'white' : 'black'}
                                    className={
                                        cardStatus?.tooltipIcon === 'Spinner'
                                            ? 'animate-spin'
                                            : ''
                                    }
                                />
                            }
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        />
                    </Tooltip>
                </span>

                {cardStatus?.actionIcon === 'Settings' && (
                    <span className="my-auto inline-flex flex-shrink-0 self-center">
                        <Tooltip
                            label={cardStatus?.actionIconTooltip}
                            hasArrow
                            placement="bottom">
                            <IconButton
                                icon={
                                    <IconComponent
                                        icon="Settings"
                                        color={darkMode ? 'white' : 'black'}
                                    />
                                }
                                size="xs"
                                bg="transparent"
                                _hover={{
                                    bg: 'transparent',
                                }}
                                _focus={{
                                    bg: 'transparent',
                                }}
                                _active={{ bg: 'transparent' }}
                                onClick={handleClick}
                            />
                        </Tooltip>
                    </span>
                )}
                {cardStatus?.actionIcon === 'ExternalLink' && (
                    <span className="my-auto inline-flex flex-shrink-0 self-center">
                        <Tooltip
                            label={cardStatus?.actionIconTooltip}
                            hasArrow
                            placement="bottom">
                            <ChakraLink
                                as={Link}
                                isExternal
                                to={cardStatus?.link}>
                                <IconButton
                                    icon={
                                        <IconComponent
                                            icon={cardStatus?.actionIcon}
                                            color={darkMode ? 'white' : 'black'}
                                        />
                                    }
                                    size="xs"
                                    bg="transparent"
                                    _hover={{ bg: 'transparent' }}
                                    _active={{ bg: 'transparent' }}
                                    _focus={{ bg: 'transparent' }}
                                    onClick={handleClick}
                                />
                            </ChakraLink>
                        </Tooltip>
                    </span>
                )}
            </Box>
        );
    }

    return <Text>{cardStatus?.statusLabel}</Text>;
}
