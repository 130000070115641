import {useEffect, useState} from 'react';
import {axios} from '../services/axios';

const useAddProvisionedServiceProfile = (companyID) => {
    const [hasAddedProvisionedService, setHasProvisionedAddedService] =
        useState(false);
    const [error, setError] = useState(null);
    // Adding the provisioned service
    const addProvisionedServiceHandler = () => {
        setError(null);
        const endpoint = `/Service/OperatorConnect/Magic/${companyID}`;
        axios
            .post(endpoint, {})
            .then((res) => {
                if (res.status === 200) {
                    setHasProvisionedAddedService(true);
                }
            })
            .catch((e) => {
                setError(e);
            });
    };

    // Fire after succesfully adding a service profile
    useEffect(() => {
        if (hasAddedProvisionedService) {
            setError(null);
            const endpoint = `/Company/${companyID}/MSLoginLink?domain=${window.location.href}`;
            axios
                .get(endpoint)
                .then((res) => {
                    // Redirect to auth URL if all is good
                    if (res.status === 200) {
                        window.location.href = res.data;
                    }
                })
                .catch((e) => {
                    setError(e);
                });
        }
    }, [hasAddedProvisionedService]);

    return {addProvisionedServiceHandler, error};
};

export default useAddProvisionedServiceProfile;
