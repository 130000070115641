import { Box, Heading, UnorderedList, ListItem } from '@chakra-ui/react';

export function ErrorMessage({ duplicates, hasOverlapBlocksize }) {
    return (
        <Box>
            <Heading fontSize="1rem">Error! </Heading>
            <UnorderedList>
                {hasOverlapBlocksize && (
                    <ListItem className="list-inside">
                        There&apos;s an overlap in the block size.
                    </ListItem>
                )}
                {duplicates &&
                    duplicates.map((obj) => (
                        <ListItem className="list-inside" key={obj.id}>{`Row ${
                            obj.id + 1
                        } has entered a duplicate number: ${
                            obj.number
                        }`}</ListItem>
                    ))}
            </UnorderedList>
        </Box>
    );
}
