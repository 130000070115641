export default {
    DashboardServiceAddServiceCard: {
        // backgroundColor: '#FFF',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        maxWidth: '400px',
        minWidth: '200px',
        minHeight: '245px',
        boxShadow: 'base',
        '&:hover': {
            boxShadow: 'md',
            transition: 'all 0.5s',
            cursor: 'pointer',
        },
    },
    DashboardServiceAddServiceCardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        maxHeight: '95px',
        margin: '0 auto',
    },
    DashboardServiceAddServiceCardButton: {},
    DashboardServiceAddServiceCardTitle: {
        fontSize: '2xl',
        fontWeight: '600',
    },
};
