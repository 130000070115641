import { MTableCell } from 'material-table';
import TableCell from '@material-ui/core/TableCell';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { IconButton } from '@material-ui/core';
import { dateStringToDate } from '../../utils/utils';
import Tooltip from '@material-ui/core/Tooltip';

/*
 * Overrides Material table cell for usage in this project
 */
export default class CustomTableCell extends MTableCell {
    getMyStyle = () => {
        const defaultStyle = this.getStyle();
        // eslint-disable-next-line no-unused-vars
        const { maxWidth, minWidth, ...rest } = defaultStyle;
        return {
            ...rest,
            width: Math.max(defaultStyle.width, this.props.columnDef.width),
        };
    };

    getCustomRenderValue = () => {
        switch (this.props.columnDef.type) {
            case 'time':
                return dateStringToDate(this.props.value, 'time');
            case 'datetime':
                return dateStringToDate(this.props.value, 'datetime');
            case 'date':
                return dateStringToDate(this.props.value, 'date');

            default:
                return this.getRenderValue();
        }
    };
    //overriding MTableCell so that children props are rendered later
    render() {
        const {
            columnDef,
            rowData,
            cellEditable,
            onCellEditStarted,
            size,
            ...cellProps
        } = this.props;
        const { field, type, icon, lookup } = columnDef;
        const cellAlignment =
            columnDef.align !== undefined
                ? columnDef.align
                : ['numeric', 'currency'].indexOf(this.props.columnDef.type) !==
                    -1
                  ? 'right'
                  : 'left';

        let renderValue = this.getCustomRenderValue();
        var displayIcon = null;
        if (icon) {
            displayIcon = icon(rowData);
        }
        if (cellEditable) {
            renderValue = (
                <div
                    style={{
                        borderBottom: '1px dashed grey',
                        cursor: 'pointer',
                        width: 'max-content',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCellEditStarted(
                            this.props.rowData,
                            this.props.columnDef,
                        );
                    }}>
                    {renderValue}
                </div>
            );
        }
        const isGroupHeader = !rowData;
        const isGroupHeaderIcon =
            isGroupHeader && typeof this.props.value === 'object';
        const additionalText = isGroupHeaderIcon
            ? this.props.value?.props?.title || ''
            : '';
        return (
            <TableCell
                size={size}
                {...cellProps}
                style={this.getStyle()}
                align={cellAlignment}
                onClick={this.handleClickCell}>
                {this.props.children}
                {renderValue}
                <div style={{ marginLeft: 10, display: 'inline' }}>
                    {additionalText}
                </div>
                {displayIcon &&
                    type !== 'boolean' &&
                    type !== 'loading' &&
                    !lookup &&
                    rowData &&
                    (Number(renderValue) || //might wanna change to checking types instead.
                        renderValue?.length > 0) && (
                        <IconButton
                            // className="hoverButton"
                            placement="right"
                            {...displayIcon}
                        />
                    )}
                {type !== 'boolean' &&
                    type !== 'loading' &&
                    !lookup &&
                    rowData &&
                    (Number(renderValue) || //might wanna change to checking types instead.
                        renderValue?.length > 0) && (
                        <Tooltip title={'Copy To Clipboard'}>
                            <IconButton
                                className="hoverButton"
                                onClick={() => {
                                    const value = field.includes('.')
                                        ? field.split('.')
                                        : [field];
                                    const textToCopy =
                                        value.length > 1
                                            ? rowData[value[0]][value[1]]
                                            : rowData[field];

                                    navigator.clipboard.writeText(textToCopy);
                                }}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </TableCell>
        );
    }
}
