import { createBrowserRouter } from 'react-router-dom';

import { queryClient } from '@tcap/data-router/utils/queryClient';

import { ErrorPage } from '@tcap/data-router/component';
import { loginLoader, protectedLoader } from '@tcap/data-router/loaders';
import Fof from '@/pages/fof';
import ProtectedNoMatch from 'components/v4/ProtectedNoMatch';

import { AuthLayout, ProtectedLayout } from '@tcap/data-router/layout';

import authenticatedRoutes from '@/config/authenticatedRoutes';
import UNAUTHENTICATED from '@/config/unauthenticatedRoutes';

export const router = createBrowserRouter(
    [
        {
            Component: AuthLayout,
            id: 'auth',
            children: [
                {
                    index: true,
                    element: UNAUTHENTICATED[0].element,
                    loader: loginLoader,
                },
                ...UNAUTHENTICATED.slice(1),
            ],
        },
        {
            id: 'root',
            path: '/',
            errorElement: <ErrorPage />,
            children: [
                {
                    Component: ProtectedLayout,
                    loader: protectedLoader, // NOTE: keep this until we can migrate every loader function, to protect against unauthenticated access
                    shouldRevalidate: ({ currentUrl, nextUrl }) => {
                        currentUrl.pathname === nextUrl.pathname;
                    },
                    children: [
                        ...authenticatedRoutes(queryClient), // NOTE: this order matters for toast message
                        {
                            path: '*',
                            element: <Fof />,
                        },
                    ],
                },
                {
                    path: '*',
                    element: <ProtectedNoMatch />,
                },
            ],
        },
    ],
    { basename: '/ui/' },
);
