import { callMsToken } from 'pages/auth/new/sso/config';

export async function callSsoApi(
    accessToken,
    dispatch,
    setLoginData,
    setFormState,
    setErrorMessage,
) {
    try {
        setFormState('pending');
        let tcapLoginData = await callMsToken(accessToken);
        setLoginData(tcapLoginData);

        if (!tcapLoginData.token) {
            setErrorMessage(tcapLoginData);
            setFormState('idle');
            return;
        }
        setFormState('success');
        dispatch({
            type: 'LOGIN_SUCCESS',
            payload: {
                ...tcapLoginData,
                email: tcapLoginData?.user,
                loadAccessList: true,
            },
        });
    } catch (requestErr) {
        console.log(
            'Error sending data to the backend: ',
            requestErr.message,
        );
        setErrorMessage('Unable to login.');
        setFormState('idle');
        sessionStorage.clear();
    }
}
