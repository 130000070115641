import Text from '../../../../../components/v4/Text';
import Heading from '../../../../../components/v4/Heading';
import {Box} from '@chakra-ui/react';

export default function ServiceWizardSyncUsersHeader({serviceWizard}) {
    return (
        <>
            {/* Enabled selective sync */}
            {serviceWizard.company.selectiveSyncPolicy === 1 && (
                <>
                    <Heading as="h2">Selectively Sync Users?</Heading>

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                        }}
                    />

                    <Text width={'800px'}>
                        If you choose yes, we will sync a subset of users from
                        your tenancy, otherwise we will automatically sync all
                        users.
                    </Text>
                </>
            )}
            {/* Enabled selective sync */}
            {serviceWizard.company?.selectiveSyncPolicy === 2 && (
                <>
                    <Heading as="h2">Selectively Sync Users</Heading>

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                        }}
                    />

                    <Text width={'800px'}>
                        Your company is required to sync a subset of users from
                        your tenancy.
                    </Text>
                </>
            )}
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '15px',
                    marginTop: '15px',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    height: '1px',
                    width: '100%',
                    background: 'gray.300',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '15px',
                    marginTop: '15px',
                }}
            />
        </>
    );
}
