import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import differenceBy from 'lodash/differenceBy';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import intersectionBy from 'lodash/intersectionBy';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import delay from 'lodash/delay';

import { axios } from '@/services/axios';
import { downloadCSV } from 'utils/utils';
import { isEmpty, startsWith } from 'lodash';
import appConfig from '../../../../config.json';

export const IGNORED_MESSAGE = 'Ignored, no changes detected.';
export const SUCCESS_MESSAGE = 'Success.';
export const CSV_TYPE = 'text/csv';
export const CSV_TYPE_FX = 'application/vnd.ms-excel';
export const CSV_TYPE_ERROR = 'File must be .csv type';
let VERIFICATION_CHECKS = [
    {
        label: 'Received CSV',
        check: false,
        current: true,
        completionCounter: 0,
        total: 1,
    },
    {
        label: 'Fetching Company Data',
        check: false,
        current: false,
        completionCounter: 0,
        total: 4,
    },
    {
        label: 'Converting CSV',
        check: false,
        current: false,
        completionCounter: 0,
        total: 3,
    },
    {
        label: 'Verifying Users List and Status',
        check: false,
        current: false,
        completionCounter: 0,
        total: 5,
    },
    {
        label: 'Verifying Numbers List and Status',
        check: false,
        current: false,
        completionCounter: 0,
        total: 5,
    },
    {
        label: 'Verifying Voice Policy List',
        check: false,
        current: false,
        completionCounter: 0,
        total: 2,
    },
    {
        label: 'Verifying Plan List',
        check: false,
        current: false,
        completionCounter: 0,
        total: 1,
    },
];

const DR_TRUNK_TYPE = 'MS Direct Routing';
const DR_SERVICE_TYPE = 'DirectRouting';
const DEFAULT_JSON_DATA = {
    errors: [],
    ignored: false,
    attributesUpdateOnly: false,
};
const USER_DNE_ERROR = 'This user does not exist in your company.';
const USER_NOT_LICENSED_ERROR = 'This user is not licensed.';
const NUMBER_DNE_ERROR = 'This number does not exist in your company.';
// eslint-disable-next-line no-unused-vars
const DIALPLAN_DNE_ERROR = 'This dial plan does not exist in your company.';
const VP_DNE_ERROR = 'This voice policy does not exist in your company.';
const USER_NOT_OK_ERROR = 'This user is not in OK status.';
const NUMBER_NOT_OK_ERROR = 'This number is not in OK status.';
const NO_DATA_FOUND_ERROR = 'We didn\'t detect any changes.';
const UPN_IS_INVALID_ERROR = 'This UPN does not exist in your TCAP system.';
const MANAGED_BY_INVALID_ERROR =
    'Cannot change Telephone Number / Voice Policy for this user, since they are not managed by TCAP.';
const CALL_VALUE_INCONSISTENT_ERROR =
    'Cannot partially update calling values. Please set all values, or none.';
const UNLICENSED_USERS_INVALID_CHANGES_ERROR =
    'This user is unlicensed. You should not change their Telephone Number / Voice Policy / Plan. Please license them first.';
const NUMBER_IS_NOT_DR_ERROR =
    'The number you are changing to is not using a Direct Routing Trunk, hence they do not need a Voice Policy. Please remove their Voice Policies values to proceed.';
const PLAN_NAME_ERROR =
    'Invalid plan name. Please make sure it is spelled correctly.';
const PLAN_PREFIX_ERROR = 'Prefix on plan does not match number.';
const PLAN_NUMBER_ERROR =
    'Cannot set a plan against a user without a number allocated.';
const PLAN_PERMISSION_ERROR =
    'Plans are only configurable by a partner. Please contact your partner.';
const FIELDS_TO_COMPARE = [
    //fields that can be updated from CSV importer
    'telephoneNumber',
    'voicePolicy',
    'managedBy',
    'plan',
    'attribute1',
    'attribute2',
    'attribute3',
];
const FIELDS_SHOULD_NOT_CHANGE_MANAGED_BY_OTHERS = [
    //these fields shouldnt change if you are managed by others, so can only change : attribute1, attribute2, attribute 3, managedBy
    'telephoneNumber',
    'plan',
    'voicePolicy',
];
const FIELDS_TO_COMPARE_UNLICENSED_USERS = [
    //these ARE THE ONLY FIELDS THAT CAN CHANGE if you are unlicensed
    'attribute1',
    'attribute2',
    'attribute3',
    'managedBy',
];
const FIELDS_NON_ATTRIBUTES = [
    'telephoneNumber',
    'voicePolicy',
    'managedBy',
    'plan',
];
const FIELDS_TO_EXPORT = {
    o365UserPrincipalName: 'UPN',
    displayName: 'Display Name',
    telephoneNumber: 'Telephone Number',
    voicePolicy: 'Voice Policy',
    usageLocation: 'Usage Location',
    managedBy: 'Managed By (TCAP/Other)',
    plan: 'Plan',
    attribute1: 'Attribute 1',
    attribute2: 'Attribute 2',
    attribute3: 'Attribute 3',
    errors: 'Errors',
};

const DEFAULT_COMPANY_DATA = {
    availableUsers: [],
    allNumbers: [],
    availableNumbers: [],
    allNumbersLookup: {},
    availableDPVPByService: {},
    availableNumbersLookup: {},
    availablePlansLookup: {},
    availableVoicePoliciesLookup: {},
    availableUsersLookup: {},
};
const INVALID_DATA_FILENAME = 'InvalidData.csv';

export const DEFAULT_VERIFICATION_RESULT = {
    passed: {
        columns: [],
        data: [],
    },
    failed: {
        columns: [],
        data: [],
    },
};

export default function useCSVRa() {
    const [verificationChecks, setVerificationChecks] =
        useState(VERIFICATION_CHECKS);
    const [uploadData, setUploadData] = useState(null);
    const [dataJSON, setDataJSON] = useState(null);
    const [companyData, setCompanyData] = useState(DEFAULT_COMPANY_DATA);
    const [payloads, setPayloads] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [csvLimit, setCSVLimit] = useState(false);
    const [verificationResult, setVerificationResult] = useState(
        DEFAULT_VERIFICATION_RESULT,
    );
    const { currentCompany, currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const { requiredScope } = useSelector((state) => state.login);
    /**
     * for UI Validation progress checks, sets the next index to be in progress.
     * @param {*} i index in VERIFICATION_CHECKS that needs to be updated.
     */
    const changeVerificationChecks = (i) => {
        delay(
            (index) => {
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks[index].check = true;
                    if (index < newVerificationChecks.length - 1) {
                        newVerificationChecks[index + 1].current = true;
                    }
                    return newVerificationChecks;
                });
            },
            750,
            i,
        );
    };

    /**
     * for UI Validation progress checks, increments current index's completionCounter.
     * @param {*} i index in VERIFICATION_CHECKS that needs to be updated.
     */
    const changeVerificationProgresses = (i) => {
        delay(
            (index) => {
                setVerificationChecks((prev) => {
                    const newVerificationChecks = [...prev];
                    newVerificationChecks[index].completionCounter += 1;
                    return newVerificationChecks;
                });
            },
            750,
            i,
        );
    };
    //file change handler to Upload CSV
    const handleUploadUsersData = async (e) => {
        setVerificationChecks(VERIFICATION_CHECKS);
        if (
            e.target.files[0].type === CSV_TYPE ||
            e.target.files[0].type === CSV_TYPE_FX
        ) {
            const data = e.target.files[0];

            const { data: newDataJSON } = await convertCSVDataToJSON(data);

            // check for csv limits
            if (newDataJSON.length > appConfig.csvLimit) {
                setCSVLimit(true);
                handleReset();
                return;
            }

            if (csvLimit) setCSVLimit(false);

            //save data
            setLoading(true);
            setUploadData(newDataJSON);
            changeVerificationProgresses(0);
            changeVerificationChecks(0);

            //fetch data
            fetchCompanyData();
        } else {
            window.alert(CSV_TYPE_ERROR);
        }
    };
    //reset the whole UI states
    const handleReset = () => {
        setUploadData(null);
        setDataJSON(null);
        setCompanyData(DEFAULT_COMPANY_DATA);
        setVerificationResult(DEFAULT_VERIFICATION_RESULT);
        setPayloads([]);
        setLoading(false);
        setVerificationChecks((prev) => {
            const newVerificationChecks = [...prev];
            newVerificationChecks.forEach((v, index) => {
                v.check = false;
                v.current = index === 0; // NOTE: restart at Received CSV check
                v.completionCounter = 0;
            });
            return newVerificationChecks;
        });
    };
    //fetch relevant company's data for validations
    const fetchCompanyData = async () => {
        const availableUsersResponse = await axios.get(
            `/resourceaccounts/${currentCompany}`,
        );
        changeVerificationProgresses(1);
        const allNumbersResponse = await axios.get(
            `/company/${currentCompany}/numbers`,
        );
        const availableNumbersResponse = await axios.get(
            `/company/${currentCompany}/numbers/available`,
        );
        var availablePlansResponse = [];
        if (currentCompanyData?.companyBillingSettings?.perUserPlan != 0) {
            // don't use current company data plans (SPA data is lost)
            availablePlansResponse = (
                await axios.get(`/company/${currentCompany}/plans`)
            ).data;
        }
        changeVerificationProgresses(1);
        const availableServicesResponses = await axios.get(
            `/Services/Company/${currentCompany}`,
        );
        const individualServicesResponses = [];
        availableServicesResponses.data.forEach(async (v) => {
            if (v.serviceType === DR_SERVICE_TYPE) {
                individualServicesResponses.push(axios.get(`/service/${v.id}`));
            }
        });
        const servicesResponses = await Promise.all(
            individualServicesResponses,
        );
        // DP VP lookup in format {name : {id}, name : {id} }
        let availableVoicePoliciesLookup = {};

        const allDRVPandDP = servicesResponses.map((v) => {
            const voicePolicies = keyBy(v.data.voicePolicies, 'name');
            availableVoicePoliciesLookup = {
                ...availableVoicePoliciesLookup,
                ...voicePolicies,
            };
            return {
                id: v.data.id,
                serviceType: v.data.serviceType,
                voicePolicies,
            };
        });
        const availableDPVPByService = keyBy(allDRVPandDP, 'id');

        //TODO: get all trunks from a company
        //TODO: extract their VP And DP
        setCompanyData({
            availableUsers: availableUsersResponse.data,
            availableNumbers: availableNumbersResponse.data,
            allNumbers: allNumbersResponse.data,
            availableDPVPByService,
            availableUsersLookup: keyBy(
                availableUsersResponse.data,
                'o365UserPrincipalName',
            ),
            availableNumbersLookup: keyBy(
                availableNumbersResponse.data,
                'telephoneNumber',
            ),
            // name is plan name
            availablePlansLookup: keyBy(availablePlansResponse, 'name'),
            allNumbersLookup: keyBy(allNumbersResponse.data, 'telephoneNumber'),
            availableVoicePoliciesLookup,
        });
        changeVerificationProgresses(1);
        changeVerificationChecks(1);
    };

    const usersUnlicensedFilter = (v) =>
        !companyData.availableUsersLookup[v.o365UserPrincipalName]?.isLicensed;
    const usersLicensedFilter = (v) => {
        return companyData.availableUsersLookup[v.o365UserPrincipalName]
            ?.isLicensed;
    };

    const usersNotOKFilter = (v) => v.status !== 0;
    const numbersNotOKFilter = (v) => v.status !== 0;
    const numbersNeedAssignmentFilter = (v) =>
        v.telephoneNumber?.length > 0 && usersLicensedFilter(v);
    const stringNotEmptyFilter = (v) => v && v.length > 0;
    const noErrorsFilter = (v) => v.errors.length < 1;
    const haveErrorsFilter = (v) => v.errors.length > 0;
    const dataIsUnchangedFilter = (originalData, uploadedData) =>
        isEqual(originalData, uploadedData);
    const callValuesConsistentFilter = (v) =>
        (v.telephoneNumber && v.voicePolicy) ||
        (!v.telephoneNumber && !v.voicePolicy);
    const licensedUserIsChangedFilter = (user) => {
        const userOriginalData = pick(
            companyData.availableUsersLookup[user.o365UserPrincipalName] || {},
            FIELDS_TO_COMPARE,
        );
        const userUploadedData = pick(user, FIELDS_TO_COMPARE);
        return !dataIsUnchangedFilter(userOriginalData, userUploadedData);
    };
    const unlicensedUserIsChangedFilter = (user) => {
        const userOriginalData = pick(
            companyData.availableUsersLookup[user.o365UserPrincipalName] || {},
            FIELDS_TO_COMPARE_UNLICENSED_USERS,
        );
        const userUploadedData = pick(user, FIELDS_TO_COMPARE_UNLICENSED_USERS);
        return !dataIsUnchangedFilter(userOriginalData, userUploadedData);
    };
    const upnIsNotEmptyFilter = (user) => user.o365UserPrincipalName;

    const upnIsValidFilter = (user) =>
        Boolean(companyData.availableUsersLookup[user.o365UserPrincipalName]);
    /**
     * filter JSON data to cut down number of checks needed.
     * finds all licensed users that is changed in CSV.
     * @param {*} data CSV data converted into JSON
     * @returns all licensed users that has changes
     */
    const filterDataJSON = (data) => {
        return data.filter(
            (v) =>
                upnIsValidFilter(v) &&
                usersLicensedFilter(v) &&
                licensedUserIsChangedFilter(v),
        );
    };
    //convert CSV columns into friendlier backend's JSON field
    const convertCSVHeaders = (data) => {
        return data
            .map((v) => {
                const transformedObject = {
                    ...DEFAULT_JSON_DATA,
                    o365UserPrincipalName: v['UPN'],
                    displayName: v['Display Name'],
                    active: v['Active'],
                    telephoneNumber: v['Telephone Number']
                        ? String(v['Telephone Number'])
                        : '',
                    voicePolicy: v['Voice Policy'],
                    usageLocation: v['Usage Location'],
                    managedBy:
                        v['Managed By (TCAP/Other)'] &&
                        v['Managed By (TCAP/Other)'].length > 0
                            ? v['Managed By (TCAP/Other)'].toUpperCase() ===
                              'TCAP'
                                ? 0
                                : 1
                            : undefined,
                    plan: v['Plan'],
                    attribute1: v['Attribute 1'],
                    attribute2: v['Attribute 2'],
                    attribute3: v['Attribute 3'],
                };

                const originalData =
                    companyData.availableUsersLookup[
                        transformedObject.o365UserPrincipalName
                    ];
                transformedObject.isTelephoneNumberChanged =
                    originalData &&
                    !isEqual(
                        originalData.telephoneNumber,
                        transformedObject.telephoneNumber,
                    );
                const isNewTelephoneNumberUsingDR =
                    transformedObject.telephoneNumber &&
                    companyData.availableNumbersLookup[
                        transformedObject.telephoneNumber
                    ]?.trunkType === DR_TRUNK_TYPE;
                const isOldTelephoneNumberUsingDR =
                    originalData &&
                    originalData.telephoneNumber &&
                    companyData.allNumbersLookup[originalData.telephoneNumber]
                        ?.trunkType === DR_TRUNK_TYPE;
                transformedObject.isDRNumber =
                    transformedObject.isTelephoneNumberChanged
                        ? isNewTelephoneNumberUsingDR
                        : isOldTelephoneNumberUsingDR;
                const isVPChanged =
                    stringNotEmptyFilter(transformedObject.voicePolicy) &&
                    !isEqual(
                        originalData?.voicePolicy,
                        transformedObject.voicePolicy,
                    );
                transformedObject.isDPOrVPChanged =
                    transformedObject.isDRNumber && isVPChanged;

                // check if a plan has been changed, else ignore
                let isPlanChanged = false;
                if (
                    currentCompanyData?.companyBillingSettings?.perUserPlan != 0
                ) {
                    // company default plan is null
                    var currentPlan = isEqual(originalData?.plan, null)
                        ? 'Company Default Plan'
                        : originalData?.plan;
                    isPlanChanged = !isEqual(
                        currentPlan,
                        transformedObject.plan,
                    );
                }
                transformedObject.isPlanChanged = isPlanChanged;

                const userOriginalData = pick(
                    originalData,
                    FIELDS_NON_ATTRIBUTES,
                );
                const userUploadedData = pick(
                    transformedObject,
                    FIELDS_NON_ATTRIBUTES,
                );

                const licensedUsersAttributesUpdatesOnly =
                    originalData?.isLicensed &&
                    dataIsUnchangedFilter(userOriginalData, userUploadedData);
                const changeManagedByToTCAP =
                    transformedObject.managedBy === 0 &&
                    originalData?.managedBy === 1;

                transformedObject.attributesUpdateOnly =
                    licensedUsersAttributesUpdatesOnly ||
                    (originalData &&
                        originalData?.managedBy !== 0 &&
                        !changeManagedByToTCAP);

                return transformedObject;
            })
            .filter(upnIsNotEmptyFilter);
    };

    /**
     * after uploading data, process CSV into JSON & filter them.
     * @param {*} dataCSV CSV uploaded by user.
     */
    const processCSVData = async (dataCSV) => {
        changeVerificationProgresses(2);
        //convert CSV columns into friendlier backend's JSON field
        const convertedDataJSON = convertCSVHeaders(dataCSV);
        changeVerificationProgresses(2);

        //filter JSON data to cut down number of checks needed
        const filteredDataJSON = filterDataJSON(convertedDataJSON);
        changeVerificationProgresses(2);
        setDataJSON(filteredDataJSON);
        changeVerificationProgresses(2);
        changeVerificationChecks(2);

        //case 1: no data to be checked
        if (filteredDataJSON.length < 1) {
            const {
                unlicensedUsersChanged,
                ignored,
                upnIsInvalid,
                unlicensedUsersInvalid,
            } = findUnlicensedUsersAndIgnoredUsers({
                data: [],
                originalDataJSON: convertedDataJSON,
            });

            //case 1a: no unlicensed users to be checked as well -> do nothing.
            if (unlicensedUsersChanged.length < 1) {
                changeVerificationChecks(3);
                changeVerificationChecks(4);
                changeVerificationChecks(5);
                changeVerificationChecks(6);
                setError(NO_DATA_FOUND_ERROR);
                return;
            }
            //case 1b: some unlicensed users are  changed, but there's no licensed users changed.

            //report this to UI state
            const { allSucceedData } = reportResult({
                data: [],
                unlicensedUsersChanged,
                ignored,
                upnIsInvalid,
                unlicensedUsersInvalid,
            });
            //transform JSON to payload for BE api calls
            transformDataToPayload({ data: allSucceedData });
            changeVerificationChecks(3);
            changeVerificationChecks(4);
            changeVerificationChecks(5);
            changeVerificationChecks(6);
            return;
        }
        //case 2: some licensed users are changed

        //validate licensed user's data
        const { dataWithErrors } = await verifyCSVData({
            data: filteredDataJSON,
        });

        const {
            unlicensedUsersChanged,
            ignored,
            upnIsInvalid,
            unlicensedUsersInvalid,
        } = findUnlicensedUsersAndIgnoredUsers({
            data: dataWithErrors,
            originalDataJSON: convertedDataJSON,
        });
        //report these to UI states
        const { allSucceedData } = reportResult({
            data: dataWithErrors,
            unlicensedUsersChanged,
            ignored,
            upnIsInvalid,
            unlicensedUsersInvalid,
        });
        //transform necessary data into payload for BE api calls
        transformDataToPayload({ data: allSucceedData });
    };

    useEffect(() => {
        //after fetching Company Data, start processing & validating CSV data.
        if (!isEqual(companyData, DEFAULT_COMPANY_DATA)) {
            processCSVData(uploadData);
        }
    }, [companyData]);

    /**
     * aggregates uploaded CSV user data with user's data from BE for API call payloads.
     * @param {*} param0
     */
    const transformDataToPayload = async ({ data }) => {
        const {
            availableNumbersLookup,
            availableVoicePoliciesLookup,
            availableUsersLookup,
            availablePlansLookup,
        } = companyData;

        const newPayload = data.filter(noErrorsFilter).map((v) => {
            const userAPIData =
                availableUsersLookup[v.o365UserPrincipalName] || {};
            const userData = {
                ...userAPIData,
                ...v,
            };

            if (usersUnlicensedFilter(userData)) {
                return userData;
            }
            const numberID = v.isTelephoneNumberChanged
                ? (
                      numbersNeedAssignmentFilter(userData) &&
                      availableNumbersLookup[userData.telephoneNumber]
                  )?.id
                : userAPIData.numberID;

            let planID = null;
            if (currentCompanyData?.companyBillingSettings?.perUserPlan != 0) {
                planID = isEmpty(v.plan)
                    ? v.plan
                    : v.plan === 'Company Default Plan'
                      ? null
                      : availablePlansLookup[v.plan]?.id;
            }
            return {
                ...userData,
                voicePolicyID: (
                    stringNotEmptyFilter(userData.voicePolicy) &&
                    availableVoicePoliciesLookup[userData.voicePolicy]
                )?.id,
                numberID,
                planID,
            };
        });
        setPayloads(newPayload);
    };

    /**
     * validates / verifies licensed users against company's data from BE.
     * @param {*} param0
     * @returns users data with relevant errors.
     */
    const verifyCSVData = async ({ data }) => {
        const {
            availableUsers,
            availableNumbers,
            availableDPVPByService,
            availableUsersLookup,
            availableNumbersLookup,
            allNumbersLookup,
            availablePlansLookup,
        } = companyData;
        //find who's managed by TCAP / others
        const [managedByOthers, managedByTCAP] = partition(
            data,
            (v) => v.attributesUpdateOnly,
        );
        /* for users managed by TCAP: */
        const { usersNotInCompany, usersNotLicensed, usersNotOK } = verifyUsers(
            {
                data: managedByTCAP,
                availableUsers,
            },
        );
        changeVerificationChecks(3);
        const { numbersNotOK, numbersNotInCompany, callValuesInconsistent } =
            verifyNumbers({
                data: managedByTCAP,
                availableNumbers,
            });
        changeVerificationChecks(4);
        //TODO CHANGEDP AND VP
        const { voicePoliciesNotInCompany, doNotNeedVP } = await verifyDPVP({
            data: managedByTCAP,
            availableDPVPByService,
            availableNumbersLookup,
            allNumbersLookup,
            availableUsersLookup,
        });
        changeVerificationChecks(5);

        /* for users managed by others: */
        const { managedByOthersInvalid } = verifyManagedBy({
            data: managedByOthers,
            availableUsersLookup,
        });

        // check for user plans
        changeVerificationProgresses(6);
        const {
            invalidUserPlan,
            invalidPrefix,
            invalidNumberAllocated,
            invalidPlanPermissions,
        } = verifyPlans({
            data: managedByTCAP,
            availablePlansLookup,
        });
        changeVerificationChecks(6);

        const dataWithErrors = flagDataWithErrors({
            usersNotInCompany,
            usersNotLicensed,
            usersNotOK,
            numbersNotOK,
            numbersNotInCompany,
            voicePoliciesNotInCompany,
            callValuesInconsistent,
            managedByOthersInvalid,
            doNotNeedVP,
            invalidUserPlan,
            invalidPrefix,
            invalidNumberAllocated,
            invalidPlanPermissions,
            data,
        });
        return { dataWithErrors };
    };

    /**
     * lists unlicensed users with changes, and those who aren't changed at all (ignored).
     * @param {*} param0
     * @returns {unlicensedUsersChanged, ignored, upnIsInvalid}
     */
    const findUnlicensedUsersAndIgnoredUsers = ({ data, originalDataJSON }) => {
        let ignored = differenceBy(
            originalDataJSON,
            data,
            'o365UserPrincipalName',
        );

        const upnIsInvalid = ignored
            .filter((v) => !upnIsValidFilter(v))
            .map((v) => ({
                ...v,
                errors: [UPN_IS_INVALID_ERROR],
            }));

        const unlicensedUsersInvalid = ignored
            .filter((userData) => {
                if (upnIsValidFilter(userData)) {
                    const usersAPIData = pick(
                        companyData.availableUsersLookup[
                            userData.o365UserPrincipalName
                        ],
                        FIELDS_SHOULD_NOT_CHANGE_MANAGED_BY_OTHERS,
                    );
                    const userDataPicked = pick(
                        userData,
                        FIELDS_SHOULD_NOT_CHANGE_MANAGED_BY_OTHERS,
                    );
                    const userDataUnchanged = dataIsUnchangedFilter(
                        usersAPIData,
                        userDataPicked,
                    );
                    return !userDataUnchanged;
                }
            })
            .map((v) => ({
                ...v,
                errors: [UNLICENSED_USERS_INVALID_CHANGES_ERROR],
            }));

        ignored = differenceBy(
            ignored,
            [...upnIsInvalid, ...unlicensedUsersInvalid],
            'o365UserPrincipalName',
        );

        const unlicensedUsersChanged = ignored.filter(
            (v) =>
                upnIsValidFilter(v) &&
                usersUnlicensedFilter(v) &&
                unlicensedUserIsChangedFilter(v),
        );
        ignored = differenceBy(
            ignored,
            unlicensedUsersChanged,
            'o365UserPrincipalName',
        ).map((v) => ({
            ...v,
            ignored: true,
        }));
        return {
            unlicensedUsersChanged,
            ignored,
            upnIsInvalid,
            unlicensedUsersInvalid,
        };
    };

    /**
     * aggregates data with 0 errors with unlicensedUsersChanged into allSucceedData,
     * aggregates all data with errors & sort them by UPN,
     * aggregates all ignored users with allSucceedData & sort them by (verificationStatus, UPN)
     * @param {data, unlicensedUsersChanged, ignored} param0
     * @returns {allSucceedData}
     */
    const reportResult = ({
        data,
        unlicensedUsersChanged,
        ignored,
        upnIsInvalid,
        unlicensedUsersInvalid,
    }) => {
        const failed = concat(
            upnIsInvalid,
            data.filter(haveErrorsFilter),
            unlicensedUsersInvalid,
        );
        const success = concat(
            data.filter(noErrorsFilter),
            unlicensedUsersChanged,
        );
        setVerificationResult((prev) => {
            const newVerificationResult = { ...prev };
            newVerificationResult.passed.data = orderBy(
                concat(ignored, success).map((v) => ({
                    ...v,
                    verificationStatus: v.ignored
                        ? IGNORED_MESSAGE
                        : SUCCESS_MESSAGE,
                })),
                ['verificationStatus', 'o365UserPrincipalName'],
                ['desc', 'asc'],
            );
            newVerificationResult.failed.data = orderBy(
                failed,
                ['o365UserPrincipalName'],
                ['asc'],
            );
            return newVerificationResult;
        });
        return { allSucceedData: success };
    };

    /**
     * flags each user data with relevant errors.
     * @param {*} param0 all errors + data
     * @returns data with errors field filled.
     */
    const flagDataWithErrors = ({
        usersNotInCompany,
        usersNotLicensed,
        usersNotOK,
        numbersNotOK,
        numbersNotInCompany,
        voicePoliciesNotInCompany,
        callValuesInconsistent,
        managedByOthersInvalid,
        doNotNeedVP,
        invalidUserPlan = [],
        invalidPrefix = [],
        invalidNumberAllocated = [],
        invalidPlanPermissions = [],
        data,
    }) => {
        //give error message to every errors & concat them
        const errors = concat(
            usersNotInCompany.map((v) => ({ ...v, error: USER_DNE_ERROR })),
            usersNotLicensed.map((v) => ({
                ...v,
                error: USER_NOT_LICENSED_ERROR,
            })),
            usersNotOK.map((v) => ({ ...v, error: USER_NOT_OK_ERROR })),
            numbersNotOK.map((v) => ({ ...v, error: NUMBER_NOT_OK_ERROR })),
            numbersNotInCompany.map((v) => ({ ...v, error: NUMBER_DNE_ERROR })),
            voicePoliciesNotInCompany.map((v) => ({
                ...v,
                error: VP_DNE_ERROR,
            })),
            callValuesInconsistent.map((v) => ({
                ...v,
                error: CALL_VALUE_INCONSISTENT_ERROR,
            })),
            managedByOthersInvalid.map((v) => ({
                ...v,
                error: MANAGED_BY_INVALID_ERROR,
            })),
            doNotNeedVP.map((v) => ({
                ...v,
                error: NUMBER_IS_NOT_DR_ERROR,
            })),
            invalidUserPlan.map((v) => ({
                ...v,
                error: PLAN_NAME_ERROR,
            })),
            invalidPrefix.map((v) => ({
                ...v,
                error: PLAN_PREFIX_ERROR,
            })),
            invalidNumberAllocated.map((v) => ({
                ...v,
                error: PLAN_NUMBER_ERROR,
            })),
            invalidPlanPermissions.map((v) => ({
                ...v,
                error: PLAN_PERMISSION_ERROR,
            })),
        );
        //temp lookup for user data
        const dataJSONTmp = {};

        //make a lookup of errors using UPN as key
        //TODO: change to lodash groupby
        errors.forEach(({ o365UserPrincipalName, error }) => {
            (
                dataJSONTmp[o365UserPrincipalName] ||
                (dataJSONTmp[o365UserPrincipalName] = [])
            ).push(error);
        });

        //append errors from temp lookup to each user data
        const newDataJSON = data.map((v) => ({
            ...v,
            errors: dataJSONTmp[v.o365UserPrincipalName]
                ? dataJSONTmp[v.o365UserPrincipalName]
                : [],
        }));

        setDataJSON(newDataJSON);

        return newDataJSON;
    };

    /**
     * Validate user per plan if allowed
     * @param {*} data users managed by TCAP.
     */
    const verifyPlans = ({ data, availablePlansLookup }) => {
        let invalidUserPlan = [],
            invalidPrefix = [],
            invalidNumberAllocated = [],
            invalidPlanPermissions = [];
        if (currentCompanyData?.companyBillingSettings?.perUserPlan != 0) {
            data.filter((userData) => {
                // plan is changed and per user plan allows  and telephone number is not empty
                if (
                    userData.isPlanChanged &&
                    currentCompanyData.companyBillingSettings.perUserPlan ==
                        2 &&
                    requiredScope < 40 &&
                    !isEmpty(userData.telephoneNumber)
                ) {
                    invalidPlanPermissions.push(userData);
                    return;
                } else {
                    var userPlan = isEqual(
                        userData.plan,
                        'Company Default Plan',
                    )
                        ? availablePlansLookup[currentCompanyData.defaultPlan]
                        : availablePlansLookup[userData.plan];

                    if (!isEmpty(userPlan)) {
                        var validNumberAllocated = !isEmpty(
                            userData.telephoneNumber,
                        );
                        if (!validNumberAllocated) {
                            invalidNumberAllocated.push(userData);
                            return;
                        }
                        var validPrefix = startsWith(
                            userData.telephoneNumber,
                            userPlan.countryPrefix,
                        );
                        if (!validPrefix) {
                            invalidPrefix.push(userData);
                            return;
                        }
                    } else {
                        if (!isEmpty(userData.telephoneNumber)) {
                            invalidUserPlan.push(userData);
                        }
                    }
                }
            });
        }
        return {
            invalidUserPlan,
            invalidPrefix,
            invalidNumberAllocated,
            invalidPlanPermissions,
        };
    };

    /**
     * validate user is not trying to change their Telephone Number / Dial Plan / Voice Policy / Plan.
     * @param {*} param0 users managed by others.
     * @returns invalid users that are managed by others.
     */
    const verifyManagedBy = ({ data, availableUsersLookup }) => {
        const managedByOthersInvalid = data.filter((userData) => {
            const usersAPIData = pick(
                availableUsersLookup[userData.o365UserPrincipalName],
                FIELDS_SHOULD_NOT_CHANGE_MANAGED_BY_OTHERS,
            );
            const userDataPicked = pick(
                userData,
                FIELDS_SHOULD_NOT_CHANGE_MANAGED_BY_OTHERS,
            );

            const userDataUnchanged = dataIsUnchangedFilter(
                usersAPIData,
                userDataPicked,
            );
            return !userDataUnchanged;
        });
        return { managedByOthersInvalid };
    };

    /**
     * validates user is : in OK status, is a legit current Company's user, is licensed.
     * @param {*} param0 CSV data & BE user's data to be checked against.
     * @returns users that don't pass validations.
     */
    const verifyUsers = ({ data, availableUsers }) => {
        const availableUsersNotLicensed = availableUsers.filter(
            usersUnlicensedFilter,
        );
        changeVerificationProgresses(3);
        const availableUsersNotOK = availableUsers.filter(usersNotOKFilter);
        changeVerificationProgresses(3);
        const usersNotLicensed = intersectionBy(
            data,
            availableUsersNotLicensed,
            'o365UserPrincipalName',
        );
        changeVerificationProgresses(3);
        const usersNotInCompany = differenceBy(
            data,
            availableUsers,
            'o365UserPrincipalName',
        );
        changeVerificationProgresses(3);
        const usersNotOK = intersectionBy(
            data,
            availableUsersNotOK,
            'o365UserPrincipalName',
        );
        changeVerificationProgresses(3);
        return { usersNotInCompany, usersNotLicensed, usersNotOK };
    };

    /**
     * validates number to be used is : in OK status, is a legit current Company's number, is consistent with call values.
     * @param {*} param0 CSV data & BE number's data to be checked against.
     * @returns numbers that don't pass validations.
     */
    const verifyNumbers = ({ data, availableNumbers }) => {
        const availableNumbersNotOK =
            availableNumbers.filter(numbersNotOKFilter);
        changeVerificationProgresses(4);
        const numbersNeedAssignment = data.filter(numbersNeedAssignmentFilter);
        const numbersActuallyChanged = numbersNeedAssignment.filter(
            (v) => v.isTelephoneNumberChanged,
        );
        changeVerificationProgresses(4);
        const callValuesInconsistent = data.filter(
            (v) =>
                v.isDRNumber && //needs to be number with DR Trunk Type
                !callValuesConsistentFilter(v), //call values arent consistent
        );
        changeVerificationProgresses(4);
        const numbersNotOK = intersectionBy(
            numbersNeedAssignment,
            availableNumbersNotOK,
            'telephoneNumber',
        );
        changeVerificationProgresses(4);
        const numbersNotInCompany = differenceBy(
            numbersActuallyChanged,
            availableNumbers,
            'telephoneNumber',
        );
        changeVerificationProgresses(4);

        return { numbersNotOK, numbersNotInCompany, callValuesInconsistent };
    };

    /**
     * validates VoicePolicies to be used is : a legit current Company's VoicePolicies from DR service.
     * @param {*} param0 CSV data & BE VoicePolicies data to be checked against.
     * @returns VoicePolicies that don't pass validations.
     */

    const verifyDPVP = async ({
        data,
        availableDPVPByService,
        availableNumbersLookup,
        // eslint-disable-next-line no-unused-vars
        allNumbersLookup,
        availableUsersLookup,
    }) => {
        const voicePoliciesNeedUpdate = data.filter((v) =>
            stringNotEmptyFilter(v.voicePolicy),
        );
        const doNotNeedVP = voicePoliciesNeedUpdate.filter(
            (v) => !v.isDRNumber,
        );

        let voicePoliciesNotInCompany = [];

        const [hasNewNumbers, hasOldNumbers] = partition(
            data.filter((v) => v.isDPOrVPChanged),
            (v) => v.isTelephoneNumberChanged,
        );

        for (const v of hasNewNumbers) {
            const serviceID =
                availableNumbersLookup[v.telephoneNumber]?.serviceID || '';
            const serviceData = serviceID && availableDPVPByService[serviceID];
            const vpExists =
                serviceData && serviceData.voicePolicies[v.voicePolicy];
            if (!vpExists) {
                voicePoliciesNotInCompany.push(v);
            }
        }
        try {
            const results = await verifyDPVPOldNumbers({
                hasOldNumbers,
                availableUsersLookup,
            });
            voicePoliciesNotInCompany = voicePoliciesNotInCompany.concat(
                results.voicePoliciesNotInCompany,
            );
        } catch (e) {
            window.alert(
                'you have some errors in verifying Voice Policies' + `${e}`,
            );
        } finally {
            changeVerificationProgresses(5);
            changeVerificationProgresses(5);
            // eslint-disable-next-line no-unsafe-finally
            return {
                voicePoliciesNotInCompany,
                doNotNeedVP,
            };
        }
    };

    const fetchAllOriginalUserNumbers = async (allOriginalUsers) => {
        let oldNumbersLookup = {},
            oldNumbers = [];
        try {
            for await (const numberRes of allOriginalUsers.map((v) =>
                axios.get(`/number/${v.numberID}`),
            )) {
                oldNumbersLookup[numberRes.data.id] = numberRes.data;
                oldNumbers.push(numberRes.data);
            }
            return Promise.resolve({
                oldNumbersLookup,
                oldNumbers,
            });
        } catch (e) {
            console.log('error fetching numbers data', { e });
            Promise.reject(e);
        }
    };
    const fetchOldDPVP = async (oldNumbers) => {
        const allServices = oldNumbers.map((v) => v.serviceID);
        let VPDPLookup = {};
        try {
            for await (const serviceRes of allServices.map((v) =>
                axios.get(`/service/${v}`),
            )) {
                VPDPLookup[serviceRes.data.id] = serviceRes.data;
            }
            return Promise.resolve(VPDPLookup);
        } catch (e) {
            console.log('error fetching numbers data', { e });
            Promise.reject(e);
        }
    };

    const verifyDPVPOldNumbers = async ({
        hasOldNumbers,
        availableUsersLookup,
    }) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const allOriginalUsers = hasOldNumbers.map(
                (v) => availableUsersLookup[v.o365UserPrincipalName],
            );

            try {
                const voicePoliciesNotInCompany = [];
                const { oldNumbersLookup, oldNumbers } =
                    await fetchAllOriginalUserNumbers(allOriginalUsers);
                const availableDPVPByService = await fetchOldDPVP(oldNumbers);
                for (const v of hasOldNumbers) {
                    const originalData =
                        availableUsersLookup[v.o365UserPrincipalName];
                    const serviceID =
                        originalData.telephoneNumber &&
                        oldNumbersLookup[originalData.numberID]?.serviceID;
                    const serviceData =
                        serviceID && availableDPVPByService[serviceID];
                    const vpExists =
                        serviceData &&
                        serviceData.voicePolicies.find(
                            (existingVP) => existingVP.name === v.voicePolicy,
                        );

                    if (!vpExists) {
                        voicePoliciesNotInCompany.push(v);
                    }
                }
                resolve({
                    voicePoliciesNotInCompany,
                });
            } catch (e) {
                console.log('catch reject', { e });
                reject(e);
            }
        });
    };

    /**
     * use papaparse to convert CSV -> JSON (with webworker).
     * @param {*} data CSV data to be converted to JSON.
     * @returns JSON data
     */
    const convertCSVDataToJSON = async (data) => {
        return new Promise((resolve) => {
            const papaparseConfig = {
                dynamicTyping: true, //for numbers type
                header: true, //CSV has headers
                worker: true, //use worker thread
                complete: function (results) {
                    return resolve(results);
                },
            };
            Papa.parse(data, papaparseConfig);
        });
    };

    /**
     * converts invalid JSON data back to CSV with papaparse.
     */
    const handleExportInvalidData = () => {
        const invalidData = transformJSONToCSV(
            dataJSON.filter(haveErrorsFilter),
        );
        const res = Papa.unparse(invalidData);
        downloadCSV(res, INVALID_DATA_FILENAME);
    };

    /**
     * transforms BE JSON fields into more human readable CSV heading columns.
     * @param {*} data JSON data to be transformed.
     * @returns JSON data with CSV heading.
     */
    const transformJSONToCSV = (data) => {
        return data.map((user) => {
            return Object.keys(FIELDS_TO_EXPORT).reduce((acc, key) => {
                const value = user[key];
                const newKey = FIELDS_TO_EXPORT[key];
                acc[newKey] = value;
                if (key === 'managedBy') {
                    acc[newKey] = value === 0 ? 'TCAP' : 'Other';
                }
                return acc;
            }, {});
        });
    };

    return {
        handleUploadUsersData,
        uploadData,
        verificationChecks,
        handleExportInvalidData,
        loading,
        payloads,
        verificationResult,
        handleReset,
        error,
        csvLimit,
    };
}
