import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, AppBar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import Worker from '@/utils/background-tasks';
import { useThemeHelper } from '@tcap/data-router/hooks/';
import { DashboardMenuProvider } from '@/context/DashboardMenuContext';
import { LayoutProvider } from '@/context/LayoutContext';

import '@/App.css';
import '@/console.js';

const Sidebar = lazy(() => import('@/components/sidebar/Sidebar'));
const Navbar = lazy(() => import('@/pages/navbar/Navbar'));

function ProtectedLayout() {
    const dispatch = useDispatch();
    const {
        classes,
        mixedMode: lightMode,
        lightModeTheme,
        theme,
    } = useThemeHelper();

    const {
        token,
        reLogin,
        showMFA,
        defaultPartner,
        defaultCompany,
        defaultWholesaler,
    } = useSelector((state) => state.login);
    const { currentWholesaler } = useSelector((state) => state.navigationLists);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const wholesalerID = queryParams.get('wholesalerID');
    const partnerID = queryParams.get('partnerID');
    const companyID = queryParams.get('companyID');

    useEffect(() => {
        if (
            (defaultPartner || defaultCompany || defaultWholesaler) &&
            !currentWholesaler
        ) {
            window.history.replaceState(null, '', '/ui' + location.pathname);
            //if current stuffs arent set yet, set them to default.
            dispatch({
                type: 'CHANGE_CURRENT_PARTNER',
                payload: partnerID || defaultPartner,
            });
            dispatch({
                type: 'CHANGE_CURRENT_COMPANY',
                payload: companyID || defaultCompany,
            });
            dispatch({
                type: 'CHANGE_CURRENT_WHOLESALER',
                payload: wholesalerID || defaultWholesaler,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWholesaler]);

    if (!token || reLogin || showMFA) {
        return <Navigate to="/" replace />;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<></>}>
                <div className={classes.root}>
                    <LayoutProvider>
                        <ThemeProvider theme={lightMode && lightModeTheme}>
                            <AppBar position="fixed" className={classes.appBar}>
                                <Worker />
                                <Navbar />
                            </AppBar>
                            <Drawer
                                className={`${classes.drawer}`}
                                variant="permanent"
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                style={{
                                    transition: 'width 0.4s ease-in-out',
                                }}>
                                <Sidebar />
                            </Drawer>
                        </ThemeProvider>
                        <main className={classes.content}>
                            <DashboardMenuProvider>
                                <Outlet />
                            </DashboardMenuProvider>
                        </main>
                    </LayoutProvider>
                </div>
            </Suspense>
        </ThemeProvider>
    );
}

export default ProtectedLayout;
