import { useNavigate, useLocation } from 'react-router-dom';

export default function useEditPath() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleEditBtn = (rowData) => {
        navigate(`${pathname}/${rowData?.id}/edit`, {
            state: { rowData },
        });
    };

    return {
        handleEditBtn,
    };
}
