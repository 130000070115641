//external imports
import {useEffect, useState} from 'react';

import {useToast} from '@chakra-ui/react';

export default function StateToasts({type}) {
    const toast = useToast();
    useEffect(() => {
        if (type === 'Create') {
            toast({
                title: 'Successfully created a new approval code.',
                status: 'success',
                duration: 6000,
                isClosable: true,
                position: 'bottom',
            });
        }
        if (type === 'Edit') {
            toast({
                title: 'Successfully updated approval code.',
                status: 'success',
                duration: 6000,
                isClosable: true,
                position: 'bottom',
            });
        }
        if (type === 'Delete') {
            toast({
                title: 'Successfully deleted approval code.',
                status: 'success',
                duration: 6000,
                isClosable: true,
                position: 'bottom',
            });
        }
    }, [type]);

    return null;
}
